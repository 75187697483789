import { gql } from '@apollo/client';

export const GET_PRODUCT_IDS = gql`
  query ($id: ID!, $sku: String!) {
    node(id: $id) {
      id
      ... on Company {
        shops(limits: 999) {
          nodes {
            id
            name
            products(limits: 1, filter: { sku: { operator: IN, value: [$sku] } }) {
              nodes {
                sku
              }
            }
          }
        }
      }
    }
  }
`;
