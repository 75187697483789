import React, { Fragment } from 'react';
import { translate } from '../../shared/translate';
import Taber from '../../components/Taber';
import ProductAnalyst from './ProductAnalyst';
import CollectionAnalyst from './CollectionAnalyst';
import PromotionCodeAnalyst from './PromotionCodeAnalyst';
import DiscountAnalyst from './DiscountAnalyst';
import CouponAnalyst from './CouponAnalyst';
import MemberTierAnalyst from './MemberTierAnalyst';
import CompanyShopSelector from './CompanyShopSelector';
import { isProduction, JSONParseSafely } from '../../shared';
import useQueryParams from '../../hooks/useQueryParams';
import { object2QueryString } from '../../components/QueryParamsWrapper';
import SalespersonAnalyst from './SalespersonAnalyst';
import OrderAnalyst from './OrderAnalyst';

const tabs = [
  {
    name: translate.product_analyst,
    link: '/sale_performance_and_data/product_sales',
    component: ProductAnalyst,
    enableSelectShop: true,
  },
  {
    name: translate.collection_analyst,
    link: '/sale_performance_and_data/collection_sales',
    component: CollectionAnalyst,
    enableSelectShop: true,
  },
  {
    name: translate.promotion_code_analyst,
    link: '/sale_performance_and_data/promotion_code_sales',
    component: PromotionCodeAnalyst,
  },
  {
    name: translate.coupon_analyst,
    link: '/sale_performance_and_data/coupon_sales',
    component: CouponAnalyst,
  },
  {
    name: translate.discount_analyst,
    link: '/sale_performance_and_data/discount_sales',
    component: DiscountAnalyst,
  },
  {
    name: translate.member_tier_analyst,
    link: '/sale_performance_and_data/member_tier_sales',
    component: MemberTierAnalyst,
  },
  {
    name: translate.salesperson_analyst,
    link: '/sale_performance_and_data/salesperson_sales',
    component: SalespersonAnalyst,
  },
  {
    name: translate.all_orders,
    link: '/sale_performance_and_data/order_sales',
    component: OrderAnalyst,
    enableSelectShop: true,
  },
].filter(Boolean);

export default (props) => {
  const { history, location: { pathname } = {} } = props;

  const tab = Math.max(
    tabs.findIndex((tab) => new RegExp(tab.link).test(pathname)),
    0,
  );

  const [query, setQuery] = useQueryParams(),
    shop = JSONParseSafely(query?.shop);

  const Module = tabs[tab]?.component || Fragment;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          history.replace({
            pathname: tabs[tab]?.link,
            search: object2QueryString({
              ...query,
              cursor: undefined,
            }),
          });
        }}
        extra={
          !!tabs[tab]?.enableSelectShop && (
            <CompanyShopSelector
              value={shop}
              onChange={(shop) => {
                setQuery((query) => ({
                  ...query,
                  shop: shop
                    ? JSON.stringify({
                        id: shop?.id,
                        name: shop?.name,
                      })
                    : undefined,
                }));
              }}
            />
          )
        }
        tabs={tabs}
      />
      <Module {...props} />
    </div>
  );
};
