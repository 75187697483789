import React from 'react';
import { translate } from 'shared/translate';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { GET_QUERY, BULK_ACTION_QUERY } from './query';
import { getCommonColumns, parseConnection } from 'shared';
import { runBulkAction } from '../list_Product/ListView';
import ListCustomDisplaySwitcher from './ListCustomPageDisplaySwitcher';
import ListCustomActiveSwitcher from './ListCustomPageActiveSwitcher';
import { Icon } from '../../components/IconRender';
import { IconButton, Tooltip } from '@material-ui/core';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/custom_pages/new',
    getRowLink: ({ id, name }) => ({ pathname: `/custom_pages/${id}`, state: { title: name } }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        sortBy: 'name.keyword',
        width: 250,
      },
      {
        title: translate.preview,
        noLink: true,
        width: 50,
        align: 'center',
        render: ({ group, href, rewriteUri, shop }) => {
          const pathname = (rewriteUri || (href === 'index' ? '/' : `/c/${group}/${href}`)).replace(/^\//, '');
          const link = `https://${shop?.customDomain || shop?.hostname}/${pathname}`;

          return (
            <Tooltip title={link}>
              <a href={link} target={'_blank'}>
                <IconButton>
                  <Icon icon={'faExternalLink'} />
                </IconButton>
              </a>
            </Tooltip>
          );
        },
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        type: 'number',
        width: 1,
        sortBy: 'sortIndex',
      },
      {
        title: translate.visible,
        align: 'center',
        width: 120,
        filter: 'display',
        sortBy: 'display',
        render: (data) => {
          return <ListCustomDisplaySwitcher type={'pageDisplay'} data={data} />;
        },
      },
      {
        title: translate.availability,
        align: 'center',
        width: 120,
        filter: 'active',
        sortBy: 'active',
        render: (data) => {
          return <ListCustomActiveSwitcher type={'pageActive'} data={data} />;
        },
      },
      ...getCommonColumns(),
    ],
    menus: [
      {
        name: translate.delete,
        enable: () => this.getSelectionCount() > 0,
        onClick: async () => {
          await runBulkAction({
            confirm: translate.formatString(translate['confirm_delete_n_records'], this.getSelectionCount()),
            mutation: BULK_ACTION_QUERY,
            action: 'DELETE',
            filter: await this.getSelectionFilter(),
            loadingMessage: translate.delete,
            successMessage: translate.delete_success,
          });
        },
      },
    ],
    selectionMode: true,
    hasQSearch: true,
    qFields: ['name', 'href'],
  };
  getData = ({ node } = {}) => {
    const { pages, ...shop } = node || {};
    const { nodes, totalCount, nextCursor } = parseConnection(pages);
    return {
      totalCount,
      nextCursor,
      nodes: nodes.map((node) => ({
        ...node,
        shop,
      })),
    };
  };
  getExtraFetchVariables() {
    return {
      id: localStorage.getItem('shopId'),
    };
  }
  getFilter(...args) {
    return {
      ...super.getFilter(...args),
      group: {
        operator: 'IN',
        value: ['pages'],
      },
    };
  }
}
