import React from 'react';
import { Menu, MenuItem, Typography, Box, Grid } from '@material-ui/core';
import { translate } from '../../../../../shared/translate';
import { Link } from 'react-router-dom';
import MessageItem from './MessageItem';
import ProcessItem from './ProcessItem';
import { Icon } from '../../../../../components/IconRender';
import { parseChannelCustomer } from '../../../../../components/ChannelMessage/ChannelCustomerDetail';

export default ({ anchor, onClose, messages: _messages = [] }) => {
  const messages = _messages?.filter((message) => !!message?.id);

  return (
    <Menu anchorEl={anchor} keepMounted open={!!anchor} onClose={onClose}>
      {messages.map((item, i) => {
        const { process } = item || {};
        const customer = parseChannelCustomer(item?.channel) ?? {
          name: 'Guest',
          picture: '',
        };
        if (!!process) return <ProcessItem key={i} message={item} onClick={onClose} />;
        return <MessageItem key={i} message={{ ...item, customer }} onClick={onClose} />;
      })}
      {messages.length === 0 ? (
        <MenuItem disabled={true}>
          <Typography align={'right'} component={'p'}>
            {translate.no_new_message}
          </Typography>
        </MenuItem>
      ) : (
        <Box pr={1} mt={1}>
          <Link to={'/messages'}>
            <Typography>
              <Grid container spacing={1} justify={'flex-end'} alignItems={'center'}>
                <Grid item>
                  <Typography>{translate.all_messages}</Typography>
                </Grid>
                <Grid item>
                  <Icon
                    icon={'faChevronRight'}
                    style={{
                      fontSize: 12,
                    }}
                  />
                </Grid>
              </Grid>
            </Typography>
          </Link>
        </Box>
      )}
    </Menu>
  );
};
