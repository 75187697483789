import React from 'react';
import { Grid, Card, CardHeader, CardContent, Typography, Box, Badge, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: '#fff',
  },
  cardHeader: {
    borderBottom: '1px solid #ddd',
  },
  title: {
    fontWeight: 'bold',
  },
  digitBox: {
    flexGrow: 1,
  },
}));

export default ({ loading, title, amount1, label1, amount2, label2 }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<Badge color="secondary" variant="dot" />}
          title={
            <Typography variant={'h6'} color={'secondary'} className={classes.title}>
              {title}
            </Typography>
          }
          disableTypography={true}
        />
        <CardContent>
          <Box display={'flex'} textAlign={'center'}>
            <div className={classes.digitBox} />
            <Box className={classes.digitBox}>
              <Typography variant={'h3'}>{loading ? <Skeleton /> : amount1}</Typography>
              <Typography color={'textSecondary'}>{label1}</Typography>
            </Box>
            <div className={classes.digitBox} />
            {!!label2 && (
              <>
                <Box className={classes.digitBox}>
                  <Typography variant={'h3'}>{loading ? <Skeleton /> : amount2}</Typography>
                  <Typography color={'textSecondary'}>{label2}</Typography>
                </Box>
                <div className={classes.digitBox} />
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
