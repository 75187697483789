import React from 'react';
import { Grid } from '@material-ui/core';
import FormPageMaker from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import ReportDetail from '../ReportDetail';
import { JSONParseSafely, PriceFormat, translateNonMemberLabel } from '../../../shared';
import LineChartCard from '../LineChartCard';
import PieChartCard from '../PieChartCard';
import SalesRecordsList from './SalesRecordsList';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
    },
    hasQSearch: true,
    disableToolBar: true,
    tabs: [
      {
        name: translate.collection_analyst,
        disabledBackground: true,
        noPadding: true,
        cards: [
          {
            render: ({ values, loading, ...props }) => {
              const {
                name,
                amount,
                count,
                avgPrice,
                avgCount,
                pageView,
                conversionRate,
                memberAmount,
                nonMemberAmount,
                salesTrends = [],
                memberLevels = [],
                memberLevelAmounts = [],
                salesPoints = [],
                countries = [],
              } = values || {};
              const cols = [
                { label: translate.sale_amount, value: PriceFormat(amount) || 0 },
                { label: translate.sold_count, value: count || 0 },
                { label: translate.page_views, value: pageView || 0 },
                { label: translate.conversion_rate, value: (conversionRate || 0) * 100 + '%' },
                { label: translate.average_price, value: PriceFormat(avgPrice || 0) },
                { label: translate.average_sold_count, value: avgCount || 0 },
                { label: translate.member_sale_amount, value: PriceFormat(memberAmount || 0) },
                { label: translate.nonmember_sale_amount, value: PriceFormat(nonMemberAmount || 0) },
              ];
              const datasets = salesTrends.map(({ name, data = [] }) => ({
                label: name,
                data: data.map(({ value, name: _name }) => ({ x: _name, y: value })),
              }));
              return (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReportDetail cols={cols} label={name} loading={loading} />
                  </Grid>
                  <Grid item md={12}>
                    <LineChartCard loading={loading} label={translate.sale_trend} datasets={datasets} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PieChartCard
                      label={translate.member_level_distribution}
                      loading={loading}
                      value={translateNonMemberLabel(memberLevels)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PieChartCard
                      label={translate.member_level_consumption}
                      loading={loading}
                      value={translateNonMemberLabel(memberLevelAmounts)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PieChartCard label={translate.sales_points_distribution} loading={loading} value={salesPoints} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PieChartCard label={translate.regions_distribution} loading={loading} value={countries} />
                  </Grid>
                </Grid>
              );
            },
          },
        ],
      },
      {
        name: translate.sales_records,
        noPadding: true,
        disabledBackground: true,
        cards: [
          {
            render: () => {
              return <SalesRecordsList {...this.state} {...this.props} />;
            },
          },
        ],
      },
    ],
  };
  disableToolBar() {
    return false;
  }

  getExtraFetchVariables() {
    const { shop, startedAt, startedThru } = this.getQueryParams();
    const { id: code } = this.state;

    return {
      startedAt,
      startedThru,
      shopId: JSONParseSafely(shop)?.id,
      id: localStorage.getItem('companyId'),
      code,
    };
  }

  getInitialData = ({ node }) => {
    const {
      name,
      amount,
      count,
      orderCount,
      avgPrice,
      avgCount,
      pageView,
      hitRate,
      conversionRate,
      memberAmount,
      nonMemberAmount,
      salesTrends,
      memberLevels,
      memberLevelAmounts,
      salesPoints,
      countries,
      salesRecords = {},
    } = ((node || {}).report || {}).collectionSales || {};

    return {
      name,
      amount,
      count,
      orderCount,
      avgPrice,
      avgCount,
      pageView,
      hitRate,
      conversionRate,
      memberAmount,
      nonMemberAmount,
      salesTrends,
      memberLevels,
      memberLevelAmounts,
      salesPoints,
      countries,
      salesRecords: salesRecords.nodes,
    };
  };
}
