import React from 'react';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { Box, Grid, Typography } from '@material-ui/core';
import Dnd from '../../components/DND';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import { translate } from '../../shared/translate';
import { ReactComponent as IconDragVertical } from '../../assets/icon/drag-vertical.svg';
import { Icon } from '../../components/IconRender';
import TrashButton from '../../components/TrashButton';

const WelcomeMessagesCard = ({ disabled, values, setFieldValue }) => (
  <>
    <StyledConfigList>
      {values.welcomeMessages.length > 0 && (
        <Grid item xs={12}>
          <Dnd
            direction={'column'}
            items={values.welcomeMessages}
            onChange={(v) => setFieldValue('welcomeMessages', v)}
            renderItem={(message, i, { nonDraggableProps }) => (
              <Box mx={1.5}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Icon type={'svg'} icon={IconDragVertical} style={{ cursor: 'pointer' }} />
                  </Grid>
                  <Grid item xs {...nonDraggableProps}>
                    <Grid container alignItems={'center'}>
                      <Grid item xs={true}>
                        <InputField
                          key={i}
                          value={message}
                          disabled={disabled}
                          onChange={(e) =>
                            setFieldValue(
                              'welcomeMessages',
                              values.welcomeMessages.map((str, j) => (i === j ? e.target.value : str)),
                            )
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TrashButton
                          onClick={() => {
                            setFieldValue(
                              'welcomeMessages',
                              values.welcomeMessages.filter((_, j) => j !== i),
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <AddButton
          title={translate.add}
          onClick={() => setFieldValue('welcomeMessages', values.welcomeMessages.concat(''))}
        />
      </Grid>
    </StyledConfigList>
    <Typography variant={'caption'}>{translate.welcome_messages_remark}</Typography>
  </>
);

export default WelcomeMessagesCard;
