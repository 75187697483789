import React from 'react';
import { Box, Card, Grid } from '@material-ui/core';
import { ReactComponent as IconDragVertical } from '../../assets/icon/drag-vertical.svg';
import GateSettingField from './GateSettingField';
import Dnd from '../../components/DND';
import { Icon } from '../../components/IconRender';
import AddButton from '../../components/CustomFormField/AddButton';

const GateSettingArrayField = ({ disabled, value = [], onChange }) => {
  return (
    <Dnd
      direction={'column'}
      items={value}
      spacing={3}
      disabled={disabled}
      onChange={(value) => {
        onChange(value);
      }}
      renderItem={(item, i, { nonDraggableProps }) => {
        return (
          <Card>
            <Grid container alignItems={'center'}>
              <Grid
                item
                style={{
                  cursor: 'pointer',
                }}
              >
                <Box px={2}>
                  <Icon icon={IconDragVertical} type={'svg'} />
                </Box>
              </Grid>
              <Grid item xs {...nonDraggableProps}>
                <GateSettingField
                  value={item}
                  onChange={(item) => {
                    if (item) {
                      onChange(value.map((_item, j) => (i === j ? item : _item)));
                    } else {
                      onChange(value.filter((__, j) => j !== i));
                    }
                  }}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Card>
        );
      }}
    >
      <Box
        style={{
          textAlign: 'center',
        }}
      >
        <AddButton
          disabled={disabled}
          onClick={() => {
            onChange(value.concat({ day: '', startedAt: '', startedThru: '', point: 0, unit: 60 }));
          }}
        />
      </Box>
    </Dnd>
  );
};

export default GateSettingArrayField;
