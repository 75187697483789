import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import ConnectionCardHeader from '../../../components/ConnectionPageMaker/ConnectionCardHeader';
import { translate } from '../../../shared/translate';
import { GET_QUERY } from './query';
import { theme } from '../../../theme';
import FormHeader from '../../item_SalePerformance/FormHeader';
import StatusBadge from '../../../components/StatusBadge';
import { StaffAttendanceStatus } from '../../../shared/omniwe-types';
import ExportButton from '../ExportButton';
import { gql } from '@apollo/client';

class AttendanceRecordList extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_attendance_records,
    stickyHeader: true,
    fields: [
      {
        title: translate.clock_in_time || '打卡時間',
        fieldName: 'createdAt',
        type: 'datetime',
      },
      {
        title: translate.shop_name || '商店名稱',
        value: (row) => row.shop.name,
      },
      {
        title: translate.employee_timestamp_type || '打卡類形',
        fieldName: 'direction',
        type: 'options',
        options: Object.keys(StaffAttendanceStatus).map((key) => ({
          id: StaffAttendanceStatus[key]?.label,
          label: <StatusBadge statusObject={StaffAttendanceStatus} status={key} />,
          value: key,
        })),
      },
    ],
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    const { startedAt, startedThru } = this.getQueryParams();
    return {
      id,
      createdAt: startedAt,
      createdThru: startedThru,
    };
  }

  getFilter(...args) {
    const { startedAt, startedThru } = this.getQueryParams();
    const superFilter = super.getFilter(...args);

    return {
      ...superFilter,
      createdAt: {
        operator: 'BETWEEN',
        value: [startedAt, startedThru],
      },
    };
  }

  getQuery() {
    return GET_QUERY;
  }

  getData(data) {
    return data.node.attendances;
  }

  renderTable(...args) {
    return (
      <>
        <Divider style={{ height: 2, backgroundColor: '#999' }} />
        {super.renderTable(...args)}
      </>
    );
  }

  renderCardHeader(context) {
    const { id } = this.props;
    return (
      <>
        <ConnectionCardHeader style={{ paddingBottom: 0, marginBottom: -theme.spacing(1) }}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <Typography variant={'h6'} color={'secondary'}>
                {translate.all_attendance_records}
              </Typography>
            </Grid>
            <Grid item>
              <ExportButton
                disabled={!this.nodes.length}
                mutation={gql`
                  mutation ($shopId: ID!, $filter: AttendanceFilterInput) {
                    attendancesExport(shopId: $shopId, filter: $filter) {
                      id
                      updatedAt
                      createdAt
                      status
                      output
                    }
                  }
                `}
                filter={{
                  staffId: {
                    operator: 'IN',
                    value: id,
                  },
                }}
              />
            </Grid>
          </Grid>
        </ConnectionCardHeader>
        {super.renderCardHeader(context)}
      </>
    );
  }
  renderExtras() {
    return <FormHeader />;
  }
}

export default AttendanceRecordList;
