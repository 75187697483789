import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $startedAt: AWSDateTime, $startedThru: AWSDateTime, $cursor: Int, $limits: Int, $shopId: ID) {
    node(id: $id) {
      id
      ... on Company {
        reportOrders(
          startedAt: $startedAt
          startedThru: $startedThru
          cursor: $cursor
          limits: $limits
          shopId: $shopId
        ) @client
      }
    }
  }
`;
