import React from 'react';
import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import { DeliveryMethodProvider } from '../../../shared/omniwe-types';

class ShippingZoneCard extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
      },
      {
        title: translate.delivery_method,
        fieldName: 'provider',
        type: 'option',
        options: Object.keys(DeliveryMethodProvider).map((value) => ({
          label: DeliveryMethodProvider[value].label,
          value,
        })),
      },
      {
        title: translate.availability,
        width: 100,
        render: (zone) => {
          const { loading, isSubmitting, values: { shippingZones } = {}, setFieldValue } = this.props;
          const { id } = zone || {};
          const selected = (shippingZones || []).find((zone) => zone.id === id);
          return (
            <CheckboxField
              disabled={loading || isSubmitting}
              checked={!!selected}
              onChange={() => {
                if (!selected) {
                  shippingZones.push(zone);
                } else {
                  shippingZones.splice(shippingZones.indexOf(selected), 1);
                }
                setFieldValue('shippingZones', shippingZones);
              }}
            />
          );
        },
      },
    ],
    limits: 9999,
  };
  getData({ node } = {}) {
    const { shippingZones } = node || {};
    return shippingZones;
  }
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId'), sortBy: [{ field: 'sortIndex', order: 'ASC' }] };
  }
  getFilter(...args) {
    return {
      ...super.getFilter(...args),
      active: {
        operator: 'IN',
        value: ['true'],
      },
    };
  }
  getFetchPolicy() {
    return 'cache-first';
  }

  renderWrapper(...args) {
    return <div style={{ width: '100%' }}>{this.renderContent(...args)}</div>;
  }
}

export default ShippingZoneCard;
