import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { PriceFormat } from 'shared';
import ActiveSwitcher from './ActiveSwitcher';
import { Typography } from '@material-ui/core';
import { client } from '../../../shared/apollo';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { toast } from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import confirmation from '../../../shared/confirmation';
import MenuExport from './MenuExport';
import MenuDelete from './MenuDelete';
import MenuImport from './MenuImport';
import MenuPriceAdjustment from './MenuPriceAdjustment';
import MenuActivate from './MenuActivate';
import MenuDeactivate from './MenuDeactivate';
import MenuHashTagsSet from './MenuHashTagsSet';
import MenuCollectionSet from './MenuCollectionSet';
import MenuQuantitySet from './MenuQuantitySet';
import EllipsisTypography from '../../../components/EllipsisTypography';
import MenuBarcodeGenerator from './MenuBarcodeGenerator';
import DisplaySwitcher from './DisplaySwitcher';

export const runBulkAction = async ({ mutation, confirm, filter, data, action, loadingMessage, successMessage }) => {
  if (!!confirm && !(await confirmation(confirm))) return;
  try {
    ActivityIndicator.show(loadingMessage);
    await client.mutate({
      mutation,
      variables: {
        companyId: localStorage.getItem('companyId'),
        shopId: localStorage.getItem('shopId'),
        action,
        filter,
        data,
      },
    });
    toast.success(successMessage);
  } catch (e) {
    toast.error(errorParser(e));
  } finally {
    ActivityIndicator.hide();
  }
};

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    createUrl: '/products/new',
    createLabel: translate.new_product,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id, name }) => ({ pathname: '/products/' + id, state: { title: name } }),
    fields: [
      {
        title: translate.product_sku,
        fieldName: 'sku',
        width: 150,
        sortBy: 'sku.keyword',
      },
      {
        title: translate.image,
        fieldName: 'medias',
        type: 'medias',
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: translate.menu_product_collections,
        // filter: 'collectionIds',
        filter: 'ancestorCollectionIds',
        filterType: 'collection',
        filterChannel: 'q',
        render: ({ collectionPaths = [] }) => (
          <>
            {collectionPaths.map((path, i) => (
              <EllipsisTypography key={i}>{path}</EllipsisTypography>
            ))}
          </>
        ),
        width: 250,
        sortBy: 'collectionPaths.keyword',
      },
      {
        title: translate.online_day_time,
        fieldName: ({ publishAt }) => (publishAt ? 'publishAt' : 'createdAt'),
        type: 'datetime',
        filter: 'publishAt',
        filterChannel: 'q',
        sortBy: 'publishAt',
      },
      {
        title: translate.offline_day_time,
        value: ({ publishThru }) => publishThru || translate.good_til_cancel,
        type: ({ publishThru }) => (!!publishThru ? 'datetime' : undefined),
        sortBy: 'publishThru',
      },
      {
        title: translate.price,
        render: ({ basePrice, maxPrice }) => {
          if (basePrice !== maxPrice)
            return <Typography>{`${PriceFormat(basePrice)} - ${PriceFormat(maxPrice)}`}</Typography>;
          return <Typography>{PriceFormat(basePrice)}</Typography>;
        },
        type: 'price',
        filter: 'basePrice',
        filterChannel: 'q',
        sortBy: 'basePrice',
      },
      {
        title: translate.quantity,
        align: 'right',
        filter: 'variationQuantity',
        filterType: 'number',
        render: ({ variations: _variations }) => {
          const variations = _variations || [];
          const { quantity, ignoreStock } = variations.reduce(
            (reducer, variation) => {
              const { quantity, ignoreStock } = variation || {};
              return {
                quantity: reducer.quantity + (+quantity || 0),
                ignoreStock: !!ignoreStock || reducer.ignoreStock,
              };
            },
            { quantity: 0, ignoreStock: false },
          );
          const isInfiniteStock = !!ignoreStock,
            hasMultipleVariations = variations.length > 1;
          return <Typography>{`${isInfiniteStock ? '∞' : quantity}${hasMultipleVariations ? '*' : ''}`}</Typography>;
        },
      },
      {
        column: false,
        title: translate.cost,
        filter: 'variationCost',
        filterType: 'number',
      },
      {
        column: false,
        title: translate.tags,
        filter: 'hashtags',
        filterType: 'hashTagsArray',
        filterChannel: 'q',
      },
      {
        title: translate.activation,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} data={data} onCompleted={this.refetch} />;
        },
        noLink: true,
        filter: 'active',
        type: 'bool',
        filterChannel: 'q',
      },
      {
        title: translate.visible,
        width: 100,
        render: (data) => {
          const { id, display } = data || {};
          return <DisplaySwitcher id={id} display={display ?? true} data={data} onCompleted={this.refetch} />;
        },
        noLink: true,
        filter: 'display',
        type: 'bool',
        filterChannel: 'q',
      },
    ],
    qFields: [
      'sku',
      'name',
      'subtitle',
      'collectionPaths',
      'variationSkus',
      'barcodes',
      'variationBarcodes',
      'hashtags',
      'variationHashtags',
      'stockLocations',
      'variationStockLocations',
    ],
    menus: [
      MenuDelete,
      MenuExport,
      MenuImport,
      MenuPriceAdjustment,
      MenuActivate,
      MenuDeactivate,
      MenuHashTagsSet,
      MenuCollectionSet,
      MenuQuantitySet,
      MenuBarcodeGenerator,
    ],
    selectionMode: true,
    hasQSearch: true,
  };

  toggleModal = (modalName) => {
    const name = `modal${modalName}Open`;
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  getData = ({ node } = {}) => {
    const { products } = node || {};
    return products;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
