import React from 'react';
import { Icon } from '../../../../../components/IconRender';
import { translate } from '../../../../../shared/translate';
import { ReactComponent as IconForm } from '../../../../../assets/icon/form.svg';
import { ReactComponent as IconFormO } from '../../../../../assets/icon/form-o.svg';
import SelectComboForm from '../../../../../components/SelectCombo/SelectComboForm';
import HighlightFormRow from '../../HighlightFormRow';
import { Typography } from '@material-ui/core';

export default {
  label: translate.form,
  defaultHeight: 0,
  icon: <Icon icon={IconFormO} type={'svg'} viewBox={'0 0 250 250'} />,
  preview: function PreviewFC({ data, height, setEditData }) {
    return (
      <div
        style={{
          minHeight: 100,
          height: height || 'initial',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setEditData(data)}
      >
        <Icon fontSize={'large'} icon={IconForm} type={'svg'} viewBox={'0 0 250 250'} />
        <Typography>{data?.formName}</Typography>
      </div>
    );
  },
  wideEditor: true,
  edit: ({ value, onChange }) => {
    const { formId, formName } = value || {};
    return (
      <>
        <HighlightFormRow highlight={value.formId !== undefined} title={translate.form}>
          <SelectComboForm
            value={
              formId
                ? {
                    id: formId,
                    name: formName,
                  }
                : undefined
            }
            onChange={(form) =>
              onChange({
                ...value,
                formId: form?.id,
                formName: form?.name,
              })
            }
          />
        </HighlightFormRow>
      </>
    );
  },
};
