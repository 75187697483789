import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { NoData } from '../../../components/CursorBaseList';
import OrderItemRow from './OrderItemRow';
import { Skeleton } from '@material-ui/lab';
import BundleProductOrderItem from './BundleProductOrderItem';
import _ from 'lodash';
import ServiceBundleOrderItem from './ServiceBundleOrderItem';

const OrderItemsCard = ({ values: { id: dnId, order, items: _items } = {}, setFieldValue, isSubmitting, loading }) => {
  const disabled = !!dnId || isSubmitting || loading;

  const items = _.sortBy(_items || [], ({ orderItem }) => orderItem?.sku?.toUpperCase());

  return (
    <Paper variant={'outlined'}>
      <TableContainer>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell width={'*'}>
                <Typography variant={'h6'}>{translate.items}</Typography>
              </TableCell>
              {!dnId && (
                <>
                  <TableCell width={100} align={'right'}>
                    <Typography variant={'h6'} align={'center'}>
                      {translate.quantity}
                    </Typography>
                  </TableCell>
                  <TableCell width={100} align={'right'}>
                    <Typography variant={'h6'} align={'center'}>
                      {translate.shipped_out}
                    </Typography>
                  </TableCell>
                </>
              )}
              <TableCell width={150}>
                <Typography variant={'h6'} align={'center'}>
                  {translate.delivery_amount}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading && (
              <>
                <TableRow>
                  <TableCell colSpan={999}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={999}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={999}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </>
            )}
            {!loading && items.length === 0 && (
              <TableRow>
                <TableCell colSpan={999}>
                  <NoData />
                </TableCell>
              </TableRow>
            )}
            {(items || []).map((item, i) => {
              const { orderItem, quantity, shippedQuantity } = item || {};
              const commonProps = {
                key: `_${i}`,
                orderItem,
                shippedQuantity,
                value: quantity,
                editable: !dnId,
                onChange: (q) => {
                  item.quantity = q;
                  setFieldValue('items', items);
                },
                disabled,
              };

              if (orderItem?.bundleProduct) return <BundleProductOrderItem {...commonProps} />;
              if (orderItem?.serviceBundle) return <ServiceBundleOrderItem {...commonProps} />;
              return <OrderItemRow {...commonProps} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OrderItemsCard;
