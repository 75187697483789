import React, { useRef, useState, useEffect } from 'react';
import { Box, Drawer, Grid, List } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import { drawerCollapsedWidth, drawerWidth } from './index';
import OmniweMenu from './OmniweMenu';
import FHeader from './_header';
import useBreakPoint from '../../../components/useBreakPoint';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerCollapsed: {
    width: drawerCollapsedWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#2a2b31',
  },
  drawerPaperCollapsed: {
    width: drawerCollapsedWidth,
    backgroundColor: '#2a2b31',
  },
  skeletonOnDarkBackground: {
    backgroundColor: '#565966',
  },
}));

const OmniweDrawer = (props) => {
  const { title, logo, identity, picture, loading, toggle, onToggleChange = (_) => _ } = props;
  const classes = useStyles();
  const headerHeight = toggle ? 200 : 50;
  const container = useRef();
  const [ani, setAni] = useState(0);
  const breakPoint = useBreakPoint(),
    isMobile = ['xs', 'sm'].includes(breakPoint);

  useEffect(() => {
    if (isMobile) {
      setAni(1);
      return; // Return early to prevent adding and removing the scroll listener
    }

    const handleScroll = () => {
      const newScrollTop = container.current.scrollTop;
      newScrollTop === 0 ? setAni(Math.min(newScrollTop, headerHeight) / headerHeight) : setAni(1);
    };

    container.current.addEventListener('scroll', handleScroll);
    return () => {
      container.current.removeEventListener('scroll', handleScroll);
    };
  }, [headerHeight, breakPoint]);
  return (
    <div style={{ position: 'relative' }}>
      <FHeader
        title={title}
        logo={logo}
        toggle={toggle}
        picture={picture}
        description={identity}
        loading={loading}
        onToggleClick={() => {
          onToggleChange(!toggle);
        }}
        withAnimation={!isMobile}
        ani={ani}
      />
      <Drawer
        className={toggle ? classes.drawer : classes.drawerCollapsed}
        variant={'permanent'}
        classes={{
          paper: toggle ? classes.drawerPaper : classes.drawerPaperCollapsed,
        }}
        PaperProps={{
          ref: container,
          style: {
            paddingTop: isMobile ? '150px' : headerHeight,
          },
        }}
        anchor="left"
      >
        {loading ? (
          <Grid container direction="column">
            {Array(8)
              .fill(undefined)
              .map((__, i) => (
                <Grid key={i} item>
                  <Box p={1}>
                    <Skeleton className={classes.skeletonOnDarkBackground} variant="rect" height={toggle ? 40 : 20} />
                  </Box>
                </Grid>
              ))}
          </Grid>
        ) : (
          <List>
            <OmniweMenu {...props} />
          </List>
        )}
      </Drawer>
    </div>
  );
};

export default OmniweDrawer;
