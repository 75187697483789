import React, { forwardRef } from 'react';

export const PopoverWrapper = ({ children, box, visible }, ref) => {
  const windowWidth = window?.innerWidth ?? 1000,
    windowHeight = window?.innerHeight ?? 1000;

  const boxX = box?.x ?? 0,
    boxY = box?.y ?? 0;
  const isRight = boxX < windowWidth / 2;
  const isBottom = boxY < windowHeight / 2;

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        minWidth: 250,
        maxWidth: 350,
        width: '30vw',
        transition: 'all 0.25s',
        zIndex: 1101,
        transform: 'translateX(0)',
        ...(visible
          ? {
              top: boxY,
              left: boxX,
              transform: `translate(${isRight ? '300px' : '-100%'}, ${isBottom ? '0' : 'calc(-100% + 50px)'} )`,
            }
          : {
              left: '100vw',
              top: '50vh',
            }),
      }}
    >
      {children}
    </div>
  );
};

export default forwardRef(PopoverWrapper);
