import React from 'react';
import { Avatar, Badge, withStyles } from '@material-ui/core';

const BiggerBadge = withStyles({
  badge: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: '2px solid white',
    fontWeight: '800',
  },
})(Badge);

const ChannelAvatar = ({ read, customer }) => {
  const avatar = (
    <Avatar
      imgProps={{
        style: { objectFit: customer?.picture ? 'cover' : 'contain' },
      }}
      src={customer?.picture || require('../../../../assets/src_omniwe-logo.svg')}
    />
  );

  if (read) return avatar;
  else
    return (
      <BiggerBadge
        color={'secondary'}
        invisible={false}
        badgeContent={1}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        overlap={'circular'}
      >
        {avatar}
      </BiggerBadge>
    );
};

export default ChannelAvatar;
