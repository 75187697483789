import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@material-ui/core';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../FormPageMaker/Fields/InputField';
import { addValue2Metadata, convertMetaObject } from '../../../shared';
import SettingCommon from './SettingCommon';
import SystemButton from '../../SystemButton';
import { Icon } from '../../IconRender';

const SettingTEXT = (props) => {
  const { disabled, value, onChange = (_) => _ } = props;
  const [open, setOpen] = useState(false);
  const { metadata } = value ?? {};
  const { minLength, maxLength } = convertMetaObject(metadata);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SettingCommon {...props} />
      </Grid>
      <Grid item xs={12}>
        <SystemButton
          variant={'text'}
          onClick={() => setOpen(!open)}
          color={'secondary'}
          startIcon={<Icon icon={open ? 'faChevronUp' : 'faChevronDown'} />}
        >
          {translate.constraints}
        </SystemButton>
        <Collapse in={open}>
          <Box p={2} mt={2} bgcolor={'#F5F5F5'}>
            <Grid container spacing={2}>
              <FormRow title={translate.min_length} md={3}>
                <InputField
                  disabled={disabled}
                  type={'number'}
                  inputProps={{
                    step: 1,
                  }}
                  value={minLength}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      metadata: addValue2Metadata('minLength', e.target.value, metadata),
                    })
                  }
                />
              </FormRow>
              <FormRow title={translate.max_length} md={3}>
                <InputField
                  disabled={disabled}
                  type={'number'}
                  inputProps={{
                    step: 1,
                  }}
                  value={maxLength}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      metadata: addValue2Metadata('maxLength', e.target.value, metadata),
                    })
                  }
                />
              </FormRow>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default SettingTEXT;
