import React from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { NumberFormat } from '../../../shared';
import { translate } from '../../../shared/translate';

const ViewRecord = ({ shopForm }) => {
  const history = useHistory();

  return (
    <a
      href={`/forms/${shopForm?.id}/records`}
      onClick={(e) => {
        e.preventDefault();
        history.push(`/forms/${shopForm?.id}/records`, { name: shopForm?.name });
      }}
    >
      <Typography>
        {translate.submitted}: {NumberFormat(shopForm?.records?.totalCount)}
      </Typography>
    </a>
  );
};

export default ViewRecord;
