import { gql } from '@apollo/client';

export const FRAGMENT_ATTRIBUTE = gql`
  fragment FRAGMENT_ATTRIBUTE on ShopAttribute {
    id
    updatedAt
    createdAt
    active
    key
    name
    type
    sortIndex
    typename
    system
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_ATTRIBUTE}
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_ATTRIBUTE
    }
  }
`;

export const CREATE_QUERY = gql`
  mutation ($input: ShopAttributeCreateInput!) {
    shopAttributeCreate(input: $input) {
      ...FRAGMENT_ATTRIBUTE
    }
  }
  ${FRAGMENT_ATTRIBUTE}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ShopAttributeUpdateInput!) {
    shopAttributeUpdate(id: $id, input: $input) {
      ...FRAGMENT_ATTRIBUTE
    }
  }
  ${FRAGMENT_ATTRIBUTE}
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    shopAttributeDelete(id: $id) {
      id
    }
  }
`;
