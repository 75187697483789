import React, { memo, useEffect, useState } from 'react';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';
import VariationCombinationDisplay from '../../../../components/VariationCombinationDisplay';
import { Box, Collapse, Grid, IconButton, InputAdornment, TableCell, TableRow, withStyles } from '@material-ui/core';
import CheckboxField from '../../../../components/FormPageMaker/Fields/CheckboxField';
import StockField from './StockField';
import VariantRowOpen from './VariantRowOpen';
import HashTagsArrayField from '../../../../components/FormPageMaker/Fields/HashTagsArrayField';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';

const StyledTableCell = withStyles((theme) => ({
  sizeSmall: {
    padding: 6,
  },
  root: {
    borderBottom: 'none',
    paddingTop: 10,
  },
}))(TableCell);

const VariantRow = ({ disabled, identity, value: variation, onChange = (_) => _ }) => {
  const {
    combination = [],
    barcodes = [],
    suggestedRetailPrice = 1,
    unitPrice = 1,
    quantity = 1,
    ignoreStock = false,
    sku = '',
    active,
    // display: _display,  TODO: ShopProduct.display
    lowStock,
  } = variation || {};
  const { remind = false } = lowStock || {};
  const [open, setOpen] = useState(false);
  // const display = _display ?? true;  TODO: ShopProduct.display

  // TODO: ShopProduct.display --> 暫時hide了display，data上入true
  useEffect(() => {
    variation.display = true;
    onChange(variation);
  }, []);

  return (
    <>
      <TableRow key={identity} style={{ verticalAlign: 'top' }}>
        <StyledTableCell>
          <InputField
            value={sku}
            onChange={(e) => {
              variation.sku = e.target.value.replace(/\s+/g, '_');
              onChange(variation);
            }}
            onBlur={(e) => {
              if (e.target.value === sku) return;
              variation.sku = e.target.value.replace(/\s+/g, '_');
              onChange(variation);
            }}
            required={true}
            disabled={disabled}
            maxLength={50}
          />
        </StyledTableCell>
        <StyledTableCell>
          <HashTagsArrayField
            isOneRow={true}
            hideHash={true}
            value={barcodes.filter((_) => _) || []}
            onChange={(v) => {
              variation.barcodes = (v || []).filter((_) => _);
              onChange(variation);
            }}
            disabled={disabled}
            maxLength={50}
          />
        </StyledTableCell>
        <StyledTableCell>
          <VariationCombinationDisplay
            style={{ paddingTop: 6 }}
            typographyStyle={{ fontSize: '0.98em' }}
            combination={combination}
          />
        </StyledTableCell>
        <StyledTableCell>
          <InputField
            type="number"
            // eslint-disable-next-line
            inputProps={{
              min: 0,
              step: 0.01,
            }}
            value={suggestedRetailPrice}
            onChange={(e) => {
              variation.suggestedRetailPrice = e.target.value;
              onChange(variation);
            }}
            disabled={disabled}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </StyledTableCell>
        <StyledTableCell>
          <InputField
            type="number"
            // eslint-disable-next-line
            inputProps={{
              min: 0,
              step: 0.01,
            }}
            value={unitPrice}
            onChange={(e) => {
              variation.unitPrice = e.target.value;
              onChange(variation);
            }}
            required={true}
            disabled={disabled}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </StyledTableCell>
        <StyledTableCell>
          <StockField
            disabled={disabled}
            quantity={quantity}
            onQuantityChange={(q) => {
              variation.quantity = q;
              onChange(variation);
            }}
            ignoreStock={ignoreStock}
            onIgnoreStockChange={(v) => {
              variation.ignoreStock = v;
              onChange(variation);
            }}
          />
        </StyledTableCell>
        <StyledTableCell>
          <SwitchField
            disabled={disabled}
            checked={remind}
            onChange={() => {
              variation.lowStock.remind = !remind;
              onChange(variation);
            }}
          />
        </StyledTableCell>

        <StyledTableCell>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box ml={0.5}>
                <CheckboxField
                  checked={active}
                  disabled={disabled}
                  onChange={() => {
                    variation.active = !active;
                    onChange(variation);
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                <span class="MuiIconButton-label" style={{ transform: open ? 'rotate(180deg)' : undefined }}>
                  <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M7 10l5 5 5-5z"></path>
                  </svg>
                </span>
              </IconButton>
            </Grid>
          </Grid>
        </StyledTableCell>

        {/* TODO: ShopProduct.display
        <StyledTableCell>
          <Box ml={0.5}>
            <CheckboxField
              checked={active}
              disabled={disabled}
              onChange={() => {
                variation.active = !active;
                onChange(variation);
              }}
            />
          </Box>
        </StyledTableCell>

        <StyledTableCell>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box ml={0.5}>
                <CheckboxField
                  checked={display}
                  disabled={disabled}
                  onChange={() => {
                    variation.display = !display;
                    onChange(variation);
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                <span class="MuiIconButton-label" style={{ transform: open ? 'rotate(180deg)' : undefined }}>
                  <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M7 10l5 5 5-5z"></path>
                  </svg>
                </span>
              </IconButton>
            </Grid>
          </Grid>
        </StyledTableCell> */}
      </TableRow>
      {open && (
        <TableRow>
          {/* colSpan={9} */}
          <StyledTableCell colSpan={8} style={{ width: '100%' }}>
            <Collapse in={open}>
              <VariantRowOpen variation={variation} onChange={onChange} disabled={disabled} />
            </Collapse>
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(VariantRow);
