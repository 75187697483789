import { gql } from '@apollo/client';

export const FRAGMENT_SHOP_FORM = gql`
  fragment FRAGMENT_SHOP_FORM on ShopForm {
    id
    updatedAt
    createdAt
    name
    autoApprove
    active
    validAt
    validThru
    records(limits: 1) {
      totalCount
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ShopFormFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        forms(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_SHOP_FORM
          }
        }
      }
    }
  }
  ${FRAGMENT_SHOP_FORM}
`;
