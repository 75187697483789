import { Grid } from '@material-ui/core';
import React from 'react';
import SystemButton from '../../components/SystemButton';

const RatioSelectField = ({ disabled, value, onChange }) => {
  const ratios = [
    { label: '1:1', value: '1/1' },
    { label: '3:2', value: '3/2' },
    { label: '4:3', value: '4/3' },
    { label: '16:9', value: '16/9' },
  ];
  return (
    <Grid container>
      {ratios.map(({ label, value: _value }, i) => {
        const selected = value === _value;
        return (
          <Grid item>
            <SystemButton
              onClick={() => onChange(_value)}
              style={{
                borderRadius: 0,
                borderTopLeftRadius: !i ? 9 : 0,
                borderBottomLeftRadius: !i ? 9 : 0,
                borderTopRightRadius: i === ratios?.length - 1 ? 9 : 0,
                borderBottomRightRadius: i === ratios?.length - 1 ? 9 : 0,
                backgroundColor: !selected && '#eee',
                color: !selected && '#bbb',
                margin: selected ? '0' : '0 -1px',
                paddingLeft: 18,
                paddingRight: 18,
              }}
              variant="outlined"
              disabled={disabled}
              key={i}
            >
              {label}
            </SystemButton>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RatioSelectField;
