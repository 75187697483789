import React from 'react';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { useHistory, useLocation } from 'react-router-dom';
import { UserCouponStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';
import UseButton from './UseButton';
import { checkPermissionV2 } from '../../../components/PermissionMask';
import { Box } from '@material-ui/core';
import DeactivateButton from './DeactivateButton';

export default ({ values: { id } = {} }) => {
  const history = useHistory(),
    location = useLocation();
  return !!id && <OrderCard id={id} history={history} location={location} />;
};

class OrderCard extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.coupon_name,
        value: ({ coupon }) => coupon.name,
      },
      {
        title: translate.coupon_identifier,
        fieldName: 'handle',
      },
      {
        title: translate.use_location,
        width: 150,
        value: ({ coupon }) => (coupon.resolveCode ? translate.store_use : translate.online_shop_use),
      },
      {
        title: translate.validity_period,
        width: 180,
        fieldName: 'validThru',
        type: 'datetime',
        sortBy: 'validThru',
      },
      {
        title: translate.status,
        align: 'center',
        width: 60,
        fieldName: 'status',
        type: 'option',
        options: Object.keys(UserCouponStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={UserCouponStatus} />,
          value: key,
        })),
        filter: 'status',
        sortBy: 'status',
      },
      checkPermissionV2('useCustomerCoupons') && {
        title: translate.operation,
        width: 180,
        render: ({ id, status }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }} style={{ gap: 8 }}>
            <UseButton id={id} disabled={status !== 'ACTIVE'} onCompleted={this.refetch} />
            <DeactivateButton id={id} disabled={status !== 'ACTIVE'} onCompleted={this.refetch} />
          </Box>
        ),
        noLink: true,
      },
    ].filter((_) => _),
    limits: 10,
  };

  getData = ({ node } = {}) => {
    const { coupons } = node || {};
    return coupons;
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }
}
