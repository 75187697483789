import React from 'react';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { InvoiceCreditNoteStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';
import { OrderCard } from '../../../components/OrderCard';

class CreditNoteCard extends ConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    limits: 5,
    refreshable: true,
    fields: [
      {
        title: translate.refund_at,
        fieldName: 'createdAt',
        type: 'datetime',
        width: 180,
      },
      {
        title: translate.remark,
        fieldName: 'remark',
      },
      {
        title: translate.refunded,
        fieldName: 'total',
        type: 'price',
        width: 100,
      },
      {
        title: translate.status,
        width: 100,
        fieldName: 'status',
        type: 'option',
        options: Object.keys(InvoiceCreditNoteStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={InvoiceCreditNoteStatus} />,
          value: key,
        })),
      },
    ],
  };

  getExtraQueryOptions() {
    const { values: { orderId } = {} } = this.props;
    return {
      skip: !orderId,
    };
  }

  getExtraFetchVariables() {
    const { values: { orderId } = {} } = this.props;
    return {
      id: orderId,
    };
  }

  getData({ node } = {}) {
    const { creditNotes } = node || {};
    return creditNotes;
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps?.values?.totalRefund !== this.props.values?.totalRefund) {
      this.refetch();
    }
  }

  renderWrapper(...args) {
    return <OrderCard style={{ padding: 0, overflow: 'hidden' }}>{this.renderContent(...args)}</OrderCard>;
  }
}

export default CreditNoteCard;
