import React, { useEffect, useState } from 'react';
import { Box, Collapse, Grid, InputAdornment } from '@material-ui/core';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../FormPageMaker/Fields/InputField';
import { addValue2Metadata, convertMetaObject } from '../../../shared';
import SelectCombo from '../../SelectCombo';
import SettingCommon from './SettingCommon';
import SystemButton from '../../SystemButton';
import { Icon } from '../../IconRender';
import { useDebounce } from 'react-use';

const SettingIMAGE = (props) => {
  const { disabled, value, onChange = (_) => _ } = props;
  const [open, setOpen] = useState(false);
  const { metadata } = value ?? {};
  const { maxFileSize = '', accept = '' } = convertMetaObject(metadata);
  const [fileTypes, setFileTypes] = useState([]);

  useEffect(() => {
    setFileTypes(accept.split(',').filter((_) => _));
  }, [accept]);
  useDebounce(
    () => {
      const _accept = fileTypes.join(',');
      if (_accept !== accept)
        onChange({
          ...value,
          metadata: addValue2Metadata('accept', _accept, metadata),
        });
    },
    100,
    [fileTypes],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SettingCommon {...props} />
      </Grid>
      <Grid item xs={12}>
        <SystemButton
          variant={'text'}
          disabled={disabled}
          onClick={() => setOpen(!open)}
          color={'secondary'}
          startIcon={<Icon icon={open ? 'faChevronUp' : 'faChevronDown'} />}
        >
          {translate.constraints}
        </SystemButton>
        <Collapse in={open}>
          <Box p={2} mt={2} bgcolor={'#F5F5F5'}>
            <Grid container spacing={2}>
              <FormRow dense title={translate.file_max_size} md={6}>
                <InputField
                  disabled={disabled}
                  type={'number'}
                  inputProps={{
                    step: 0.1,
                    min: 0,
                    max: 10,
                  }}
                  value={maxFileSize}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      metadata: addValue2Metadata('maxFileSize', e.target.value, metadata),
                    })
                  }
                  InputProps={{
                    endAdornment: <InputAdornment position={'end'}>MB</InputAdornment>,
                  }}
                />
              </FormRow>
              <FormRow dense title={translate.only_allow_specific_file_type} md={6}>
                <SelectCombo
                  disabled={disabled}
                  multiple={true}
                  value={fileTypes}
                  onChange={setFileTypes}
                  getOptionSelected={(a, b) => a === b}
                  getOptionLabel={(opt) => opt?.replace(/^\./, '')?.toUpperCase() ?? ''}
                  options={['.jpg', '.png', '.gif', '.tiff']}
                />
              </FormRow>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default SettingIMAGE;
