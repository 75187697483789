import React from 'react';
import Dnd, { DragHandleIcon } from '../../../components/DND';
import { Grid } from '@material-ui/core';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import TextareaField from '../../../components/FormPageMaker/Fields/TextareaField';
import AddButton from '../../../components/CustomFormField/AddButton';
import { getRandomString } from '../../../shared';

const ConfigField = ({ value = {}, onChange = (_) => _, disabled, loading }) => {
  const items = Object.entries(value);

  return (
    <Dnd
      disabled={disabled}
      spacing={2}
      direction={'column'}
      items={items}
      onChange={(items) => {
        onChange(Object.fromEntries(items));
      }}
      renderItem={([k, v], i, { nonDraggableProps }) => {
        return (
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item>
              <DragHandleIcon />
            </Grid>
            <Grid item xs {...nonDraggableProps}>
              <InputField
                disabled={disabled}
                value={k}
                onChange={(e) => {
                  onChange(Object.fromEntries(items.map((item, j) => (i === j ? [e?.target?.value, v] : item))));
                }}
                InputProps={{
                  style: {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    marginBottom: -1,
                  },
                }}
              />
              <TextareaField
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
                disabled={disabled}
                value={v}
                onChange={(v) => {
                  onChange({
                    ...value,
                    [k]: v,
                  });
                }}
              />
            </Grid>
          </Grid>
        );
      }}
    >
      <AddButton
        disabled={disabled || loading}
        onClick={() => {
          onChange({
            ...value,
            [getRandomString()]: '',
          });
        }}
      />
    </Dnd>
  );
};

export default ConfigField;
