import React from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '../../../components/IconRender';

const SignalDisplay = ({ active }) => {
  return (
    <IconButton color={active ? 'primary' : 'secondary'} size={'small'}>
      <Icon icon={'faCircle'} type={'pro-solid'} />
    </IconButton>
  );
};

export default SignalDisplay;
