import React from 'react';
import useBreakPoint from '../../../components/useBreakPoint';
import TabletView from './TabletView';
import MobileView from './MobileView';

const CustomMessages = (props) => {
  const breakPoint = useBreakPoint();
  const isMobile = ['xs', 'sm'].includes(breakPoint);

  if (isMobile) return <MobileView {...props} />;
  else return <TabletView {...props} />;
};

export default CustomMessages;
