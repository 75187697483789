import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      id
      ... on ShopOrder {
        creditNotes(limits: $limits, cursor: $cursor) {
          totalCount
          nextCursor
          nodes {
            id
            status
            total
            remark
            createdAt
          }
        }
      }
    }
  }
`;
