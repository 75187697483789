import React from 'react';
import { Icon } from '../../../../../components/IconRender';
import { translate } from '../../../../../shared/translate';
import HighlightFormRow from '../../HighlightFormRow';
import SelectField from '../../../../../components/FormPageMaker/Fields/SelectField';
import { Box, Grid, Typography } from '@material-ui/core';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';

export default {
  defaultHeight: 0,
  label: translate.table_of_contents,
  icon: <Icon icon={'faSitemap'} style={{ color: '#97a1aa' }} />,
  preview: function PreviewFC({ data, setEditData, height }) {
    const { title } = data || {};
    return (
      <Grid container onClick={() => setEditData(data)} style={{ cursor: 'pointer' }}>
        {!!title && (
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        )}
        <PreviewContents width1={100} width2={20} number={1} />
        <PreviewContents width1={40} width2={64} number={2} heading={false} />
        <PreviewContents width1={64} width2={40} number={3} heading={false} />
        <PreviewContents width1={60} width2={60} number={4} />
        <PreviewContents width1={84} width2={20} number={5} heading={false} />
      </Grid>
    );
  },
  edit: ({ value, onChange }) => {
    const { title, headingTags = 'h1', subheadingTags = 'none' } = value || {};

    const update = (k, v) => {
      onChange({
        ...value,
        [k]: v,
      });
    };

    return (
      <>
        <HighlightFormRow highlight={value.title !== undefined} title={translate.title}>
          <InputField value={title} onChange={(e) => update('title', e.target.value)} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.headingTags !== undefined} title={translate.heading_tags}>
          <SelectField
            value={headingTags}
            onChange={(e) => update('headingTags', e.target.value)}
            options={[
              { label: 'h1', value: 'h1' },
              { label: 'h2', value: 'h2' },
              { label: 'h3', value: 'h3' },
              { label: 'h4', value: 'h4' },
              { label: 'h5', value: 'h5' },
              { label: 'h6', value: 'h6' },
            ]}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.subheadingTags !== undefined} title={translate.subheading_tags}>
          <SelectField
            value={subheadingTags}
            onChange={(e) => update('subheadingTags', e.target.value)}
            options={[
              { label: translate.not_applicable, value: 'none' },
              { label: 'h2', value: 'h2' },
              { label: 'h3', value: 'h3' },
              { label: 'h4', value: 'h4' },
              { label: 'h5', value: 'h5' },
              { label: 'h6', value: 'h6' },
            ]}
          />
        </HighlightFormRow>
      </>
    );
  },
};

const PreviewContents = ({ width1, width2, number, heading = true }) => {
  return (
    <Grid item xs={12}>
      <Box width={200} style={{ display: 'flex', alignItems: 'center' }}>
        <Box height={3} width={width1} ml={heading ? 0 : 2} style={{ backgroundColor: 'grey' }} />
        <Box height={3} width={width2} ml={1} style={{ border: '2px dashed lightgrey' }} />
        <Typography variant="body2" style={{ marginLeft: 5 }}>
          {number}
        </Typography>
      </Box>
    </Grid>
  );
};
