import React from 'react';
import { ReactComponent as icon } from '../../../../../assets/icon/design menu bar_type.svg';
import { Icon } from '../../../../../components/IconRender';
import { translate } from '../../../../../shared/translate';
import { makeStyles } from '@material-ui/core';
import HTMLField from '../../../../../components/FormPageMaker/Fields/HTMLField';

const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: 100,
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'auto',
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default {
  label: translate.html,
  defaultHeight: 0,
  icon: <Icon icon={icon} type={'svg'} viewBox={'0 0 34.863 40'} />,
  preview: function PreviewFC({ data, height, setEditData }) {
    const { html } = data || {};
    const classes = useStyles();
    return (
      <div
        className={classes.wrapper}
        style={{
          height: height || 'initial',
        }}
        onClick={() => setEditData(data)}
      >
        <div style={{ pointerEvents: 'none' }} dangerouslySetInnerHTML={{ __html: html }} className={`html-wrapper`} />
      </div>
    );
  },
  wideEditor: true,
  edit: ({ value, onChange }) => {
    const { html } = value || {};
    return (
      <>
        <HTMLField
          value={html}
          onChange={(c) => {
            onChange({
              ...value,
              html: c,
            });
          }}
        />
      </>
    );
  },
};
