import React from 'react';
import ImportModal from './ImportModal';

const MenuImportAll = {
  render: function () {
    return (
      <ImportModal
        onClick={() => {
          this.setState({ menuAnchorEl: undefined });
        }}
      />
    );
  },
};

export default MenuImportAll;
