import React from 'react';
import FormRow from '../../FormRow';
import HashTagsArrayField from '../../FormPageMaker/Fields/HashTagsArrayField';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {};
  return (
    <FormRow title={title}>
      <HashTagsArrayField
        value={value || []}
        onChange={(tags) => {
          onFilterChange({
            value: tags && tags.length > 0 ? tags : undefined,
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
