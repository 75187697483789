import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import StatusBadge from 'components/StatusBadge';
import { ReceivePurchaseStatus } from '../../../shared/omniwe-types';
import { checkPermissionV2 } from '../../../components/PermissionMask';
import MenuExport from './MenuExport';
import PrintOptionsButton from '../../../components/PrintOptionsButton';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    createUrl: checkPermissionV2('createReceivePurchase') ? '/receive_purchase/new' : undefined,
    gql: {
      get: GET_QUERY,
    },
    hasQSearch: true,
    qFields: ['referenceNo'],
    getRowLink: ({ id }) => ({ pathname: '/receive_purchase/' + id }),
    fields: [
      {
        title: translate.created_at,
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        filterChannel: 'q',
        sortBy: 'createdAt',
      },
      {
        title: translate.operator,
        value: (row) => row?.staff?.name,
      },
      {
        title: translate.reference_no,
        fieldName: 'referenceNo',
        type: 'text',
        filter: 'referenceNo',
        filterChannel: 'q',
        sortBy: 'referenceNo',
      },
      {
        title: translate.to_warehouse,
        value: ({ warehouse } = {}) => (warehouse || {}).name,
        filterType: 'warehouse',
        filter: 'warehouseId',
        filterChannel: 'q',
      },
      {
        title: translate.quantity,
        value: ({ totalQuantity }) => totalQuantity,
        type: 'number',
        width: 100,
      },
      {
        title: translate.status,
        align: 'center',
        width: 120,
        fieldName: 'status',
        filter: 'status',
        filterChannel: 'q',
        type: 'option',
        options: Object.keys(ReceivePurchaseStatus).map((key) => {
          return {
            label: <StatusBadge status={key} statusObject={ReceivePurchaseStatus} />,
            value: key,
          };
        }),
      },
      {
        title: translate.other_actions,
        fieldName: 'action',
        width: 1,
        noLink: true,
        render: ({ id }) => (
          <PrintOptionsButton
            id={id}
            partialHref={'print_receive_purchase'}
            orderedBy={translate.by_created_time || '按建立時間順序排序'}
          />
        ),
      },
    ],
    menus: [MenuExport],
    selectionMode: true,
  };

  getData = ({ node } = {}) => {
    const { receivePurchases } = node || {};
    return receivePurchases;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
}
