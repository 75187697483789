import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../components/FormPageMaker/Fields/SwitchField';

const STATUS_SET = gql`
  mutation ($id: ID, $input: NewsletterSubscriptionSetInput!) {
    newsletterSubscribe(id: $id, input: $input) {
      id
      active
    }
  }
`;

export default ({ id, active, data = {}, onCompleted }) => {
  const { contact } = data || {};
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    if (active !== isActive) {
      setIsActive(active);
    }
  }, [active]);

  return (
    <SwitchField
      checked={isActive}
      value={isActive}
      disabled={loading}
      onChange={async (e) => {
        try {
          setIsActive(!active);
          setLoading(true);
          await client.mutate({
            mutation: STATUS_SET,
            variables: {
              id: id || undefined,
              input: {
                active: !active,
                contact: contact || undefined,
                shopId: localStorage.getItem('shopId'),
              },
            },
          });
          onCompleted();
        } catch (e) {
          toast.error(errorParser(e));
          setIsActive(active);
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
