import React from 'react';
import { translate } from '../../../shared/translate';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import CurrentStockField from './CurrentStockField';
import RefreshButton from '../../../components/RefreshButton';
import SelectComboVariation from '../../../components/SelectCombo/SelectComboVariation';
import TrashButton from '../../../components/TrashButton';
import { ItemsTable as ReceivePurchaseItemsTable } from '../../item_ReceivePurchase/ItemsCard/ItemsTable';
import { sleep } from '../../../shared';

class ItemsTable extends ReceivePurchaseItemsTable {
  state = {
    ...this.state,
    fields: [
      {
        title: `${translate.product} *`,
        width: 350,
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, disabled } = this.props;
          const { variation, add, sku, __row } = item || {};
          return (
            <SelectComboVariation
              disablePortal={false}
              id={!!add && 'add'}
              value={!add ? variation : null}
              placeholder={variation?.sku ?? sku}
              showSubtitle={false}
              key={items?.length}
              onChange={(variation) => {
                if (!!add) {
                  onChange(
                    (items || [])?.concat({
                      variation,
                      sku: variation?.sku,
                      quantity: 1,
                    }),
                  );
                  sleep(0).then(this.goToLastPage);
                  return;
                }
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          variation: variation ? variation : undefined,
                          sku: variation ? variation?.sku : undefined,
                        }
                      : item,
                  ),
                );
              }}
              disabled={disabled}
            />
          );
        },
      },

      !this.props.seal && {
        title: (
          <Grid container justifyContent={'center'} spacing={1} alignItems={'center'}>
            <Grid item>{translate.stocktake_quantity}</Grid>
            <Grid item>
              <RefreshButton
                onClick={() => {
                  if (!this.props.fromWarehouseId) return;
                  this.setState({ refreshStockKey: this.state.refreshStockKey + 1 });
                }}
                size={'small'}
              />
            </Grid>
          </Grid>
        ),
        width: 120,
        render: (item) => {
          const { add, __row } = item ?? {};
          const { cursor = 0, refreshStockKey } = this.state;
          const { items, onChange } = this.props;
          if (!add)
            return (
              <CurrentStockField
                {...this.props}
                refreshStockKey={refreshStockKey}
                item={item}
                onChange={(e) => {
                  onChange(
                    items.map((item, i) =>
                      i === cursor + __row
                        ? {
                            ...item,
                            currentStock: e,
                          }
                        : item,
                    ),
                  );
                }}
              />
            );
        },
      },
      {
        title: `${translate.transfer_quantity} *`,
        width: 120,
        align: 'center',
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, disabled } = this.props;
          const { sku, quantity, currentStock, __row } = item || {};
          return (
            <InputField
              type={'number'}
              // eslint-disable-next-line
              inputProps={{
                max: currentStock,
                min: 0,
                step: 1,
                style: { textAlign: 'center' },
              }}
              disabled={!sku || disabled}
              value={quantity}
              onChange={(e) => {
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          quantity: e.target.value,
                        }
                      : item,
                  ),
                );
              }}
              InputProps={{
                style: {
                  backgroundColor: quantity > currentStock ? 'rgba(233, 30, 99, 0.2)' : null,
                },
              }}
              required={!item?.add}
            />
          );
        },
      },
      {
        title: () => {
          const { status } = this.props;
          if (status === 'COMPLETED') return <Typography variant={'h6'}>{translate.received_quantity}</Typography>;
          return <Typography variant={'h6'}>{translate.will_received_quantity || '預計接收數量'}</Typography>;
        },
        width: 120,
        align: 'center',
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, status, isSubmitting } = this.props;
          const { receivedQuantity, __row } = item || {};
          const show = !!~['IN_TRANSIT', 'COMPLETED'].indexOf(status);
          return (
            <InputField
              type={show && 'number'}
              inputProps={{
                min: 0,
                step: 1,
                style: { textAlign: 'center' },
              }}
              disabled={status !== 'IN_TRANSIT' || isSubmitting}
              value={show ? receivedQuantity : '-'}
              InputProps={{
                style: {
                  backgroundColor: !show && '#eee',
                },
              }}
              onChange={(e) => {
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          receivedQuantity: e.target.value,
                        }
                      : item,
                  ),
                );
              }}
            />
          );
        },
      },
      {
        title: `${translate.stocktake_diff}`,
        width: 120,
        align: 'center',
        render: (item) => {
          const { status } = this.props || {};
          const { quantity, receivedQuantity } = item || {};
          const show = !!~['COMPLETED'].indexOf(status);
          return (
            <InputField
              type={show && 'number'}
              disabled={true}
              inputProps={{
                style: { textAlign: 'center' },
              }}
              value={show ? quantity - receivedQuantity : '-'}
              required={!item?.add}
              InputProps={{
                style: {
                  backgroundColor: '#eee',
                },
              }}
            />
          );
        },
      },
      !this.props.seal && {
        title: '',
        width: 76,
        align: 'center',
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { add, __row } = item ?? {};
          const { disabled, items, onChange } = this.props;
          if (!!add) return <></>;
          return (
            <TrashButton disabled={disabled} onClick={() => onChange(items.filter((__, i) => i !== cursor + __row))} />
          );
        },
      },
    ].filter(Boolean),
  };
}
ItemsTable.propTypes = {
  fromWarehouseId: PropTypes.string,
  toWarehouseId: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
ItemsTable.defaultProps = {
  items: [],
  onChange: (_) => _,
};

export default ItemsTable;
