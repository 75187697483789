import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { translate } from '../../translate';

const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      ... on CompanyCoupon {
        excludedCoupons(limits: $limits, cursor: $cursor) {
          nextCursor
          totalCount
          nodes {
            id
            name
            active
          }
        }
      }
    }
  }
`;

export default async function (source) {
  return await infinityFetch({
    query: GET_QUERY,
    variables: {
      id: source?.id,
    },
    getConnection: (data) => data?.node?.excludedCoupons,
  });
}
