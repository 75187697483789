import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!) {
    me {
      ... on User {
        id
        email
      }
    }
    node(id: $id) {
      id
      ... on CompanyShop {
        contactEmails(limits: 9999) {
          nodes {
            id
            email
            verified
            type
          }
        }
        name
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation ($id: ID!, $input: CompanyShopInput!) {
    companyShopSet(id: $id, input: $input) {
      id
      contactEmails(limits: 9999) {
        nodes {
          id
          email
          verified
          type
        }
      }
      name
    }
  }
`;

export const PROFILE_UPDATE = gql`
  mutation ($input: ProfileUpdateInput!, $credential: UserCredentialUpdateInput) {
    profileUpdate(input: $input, credential: $credential) {
      ... on User {
        id
        email
        metadata
      }
    }
  }
`;

export const CONETACT_EMAIL_RESEND_VERIFY = gql`
  mutation ($id: ID!) {
    shopContactEmailResendVerify(id: $id)
  }
`;
