import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import s3Upload from 'shared/s3Upload';
import { Box, ButtonBase, CircularProgress, IconButton, withStyles } from '@material-ui/core';
import Media from 'components/Media';
import Dropzone from 'react-dropzone';
import ImageFieldModal from 'components/FormPageMaker/Fields/ImageFieldModal';
import { Icon } from 'components/IconRender';
import OverlayLoading from '../../OverlayLoading';
import LSkeleton from '../../LSkeleton';
import { translate } from '../../../shared/translate';
import uuid from 'react-uuid';

class ImageField extends Component {
  state = {
    inputId: uuid(),
    url: undefined,
    loading: false,
    modalOpen: this.props.autoModalOpen,
  };
  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  };

  onFilesSelect = async (files) => {
    const { onChange } = this.props;
    try {
      let file = files[0];
      const url = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsDataURL(file);
      });
      this.setState({ url, loading: true });

      const s3Path = await s3Upload(file);
      this.setState({ url: s3Path });
      onChange(s3Path);
    } catch (err) {
      toast.error(errorParser(err));
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
        classes,
        value,
        className,
        disabled,
        required,
        preview,
        onChange,
        style,
        wrapperStyle,
        actionStyle,
        mediaStyle,
        children,
        hideRemoveButton,
        loading: _loading,
        ...props
      } = this.props,
      { url, loading, modalOpen, inputId } = this.state;
    const previewLink = url || value;

    if (_loading) return <LSkeleton variant={'rect'} width={150} height={150} />;

    if (preview)
      return (
        <Box display={'flex'} style={style}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} style={wrapperStyle}>
            <Dropzone onDrop={this.onFilesSelect} maxFiles={1} accept={['image/*', 'video/*']}>
              {({ getRootProps, getInputProps }) => (
                <ButtonBase
                  className={`${className} ${classes.actionButton} ${
                    disabled || (loading && classes.actionButtonDisabled)
                  }`}
                  type={`button`}
                  style={actionStyle}
                  {...getRootProps()}
                  onClick={
                    disabled
                      ? () => {
                          !!previewLink && window.open(previewLink, '_blank');
                        }
                      : this.toggleModal
                  }
                  disabled={(disabled && !previewLink) || loading}
                >
                  <Media
                    disabled={true}
                    src={
                      previewLink ||
                      (disabled || loading ? require('assets/gray.jpg') : require('assets/image_placeholder.jpg'))
                    }
                    style={mediaStyle}
                  />
                  <input
                    {...props}
                    id={inputId}
                    className={classes.input}
                    type={'file'}
                    {...getInputProps()}
                    accept="image/*"
                    disabled={disabled}
                    required={required}
                  />
                  {loading && <OverlayLoading />}
                </ButtonBase>
              )}
            </Dropzone>
            {!disabled && !hideRemoveButton && previewLink && (
              <IconButton
                type={'button'}
                size={'small'}
                disabled={disabled}
                className={classes.removeButton}
                onClick={() => {
                  this.setState({ url: '' });
                  onChange('');
                }}
              >
                <Icon icon={'Close'} type={'material'} />
              </IconButton>
            )}
            <ImageFieldModal open={!!modalOpen} onClose={this.toggleModal} image={url || value} onChange={onChange}>
              {children}
            </ImageFieldModal>
          </Box>
        </Box>
      );
    else
      return (
        <Box
          className={className}
          position={'relative'}
          my={2}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <label
            className={classes.inputLabel}
            for={inputId}
            style={{
              cursor: 'pointer',
            }}
          >
            {translate.select_file || '選擇檔案'}
          </label>
          <input
            id={inputId}
            className={classes.input}
            {...props}
            type={'file'}
            disabled={disabled || loading}
            required={required}
            accept="image/*"
            onChange={async (e) => {
              const { onChange } = this.props;
              e.preventDefault();
              if (!e) {
                onChange(undefined);
                return;
              }
              await this.onFilesSelect(e.target.files);
            }}
          />
          {loading && <CircularProgress style={{ marginLeft: 16 }} size={15} />}
        </Box>
      );
  }
}
export default withStyles((theme) => ({
  inputLabel: {
    marginBottom: 0,
    backgroundColor: '#FFFFFF !important',
    boxShadow: 'none !important',
    color: '#000 !important',
    border: '1px solid #D0D0D0 !important',
    padding: '6px 12px',
    borderRadius: '10px !important',
    '&:hover': {
      backgroundColor: '#F5F4F4 !important',
      boxshadow: 'none !important',
      color: '#000 !important',
    },
  },
  fileInput: {
    visibility: 'hidden',
    display: 'contents',
  },
  input: {
    visibility: 'hidden',
    display: 'contents',
  },
  actionButton: {
    width: 150,
    height: 150,
    borderRadius: '1.5rem',
    overflow: 'hidden',
    border: '1px solid #665858',
  },
  actionButtonDisabled: {
    border: '1px solid #ddd',
  },
  removeButton: {
    backgroundColor: '#ddd',
    marginTop: theme.spacing(0.5),
  },
}))(ImageField);
ImageField.propTypes = {
  hideRemoveButton: PropTypes.bool,
  autoModalOpen: PropTypes.bool,
  preview: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.any,
  wrapperStyle: PropTypes.any,
  actionStyle: PropTypes.any,
  mediaStyle: PropTypes.any,
  loading: PropTypes.bool,
};
ImageField.defaultProps = {
  preview: true,
  onChange: (_) => _,
  className: '',
  style: {},
  wrapperStyle: {},
  actionStyle: {},
  mediaStyle: {},
};
