import _ from 'lodash';

export const fixVariationOrder = (variations = [], combinations = []) => {
  return _.sortBy(variations, ({ combination }) => {
    return (combinations || []).reduce((reducer, { name, options = [] }, i) => {
      const option = ((combination || []).find((com) => com.name === name) || {}).option;
      const index = options.findIndex((opt) => opt.name === option);
      return reducer * 10 + i + index;
    }, 0);
  });
};
