import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import ActivityIcon from './ActivityIcon';
import { Skeleton } from '@material-ui/lab';

const ActivityItem = ({ loading, item: { createdAt, createdBy, event } = {} }) => (
  <Grid container alignItems={'center'}>
    <Grid item>
      <Box px={5}>
        {loading ? <Skeleton variant={'circle'} width={50} height={50} /> : <ActivityIcon createdAt={createdAt} />}
      </Box>
    </Grid>
    <Grid item xs={true}>
      <Box>
        <Typography
          width={'25%'}
          style={{
            fontWeight: 'bold',
          }}
        >
          {loading ? <Skeleton /> : event}
        </Typography>
        {/* <Typography width={'50%'}>
          {loading ? <Skeleton /> : `${(createdBy || {}).name || ''} ${moment(createdAt).format('YYYY-MM-DD HH:mm')}`}
        </Typography> */}
      </Box>
    </Grid>
  </Grid>
);

export default ActivityItem;
