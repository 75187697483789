import React from 'react';
import { translate } from '../../../../../shared/translate';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import ImageField from '../../../../../components/FormPageMaker/Fields/ImageField';
import CheckboxField from '../../../../../components/FormPageMaker/Fields/CheckboxField';
import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Icon } from '../../../../../components/IconRender';
import HighlightFormRow from '../../HighlightFormRow';
import ColorField from '../../../../../components/FormPageMaker/Fields/ColorField';

export default ({ value, onChange = (_) => _ }) => {
  const {
    backgroundColor,
    backgroundImage,
    hasContainer = false,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    spacing = 0,
    visible = true,
    borderColor,
  } = value || {};
  const update = (k, v) => {
    const obj = {
      ...value,
      [k]: v,
    };
    if (v === undefined) delete obj[k];
    onChange(obj);
  };
  return (
    <>
      <HighlightFormRow highlight={value.visible !== undefined} title={translate.visible}>
        <CheckboxField checked={visible} onChange={(e) => update('visible', e.target.checked)} />
      </HighlightFormRow>
      <HighlightFormRow highlight={value.backgroundColor !== undefined} title={translate.background_color}>
        <Grid container spacing={1} alignItems={'center'}>
          {!!backgroundColor && (
            <Grid item>
              <IconButton edge={'end'} color={'secondary'} onClick={() => update('backgroundColor', undefined)}>
                <Icon type={'material'} icon={'Cancel'} />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <ColorField value={backgroundColor} onChange={(c) => update('backgroundColor', c)} />
          </Grid>
        </Grid>
      </HighlightFormRow>
      <HighlightFormRow highlight={value.backgroundImage !== undefined} title={translate.background_image}>
        <ImageField value={backgroundImage} onChange={(v) => update('backgroundImage', v)} />
      </HighlightFormRow>
      <HighlightFormRow highlight={value.borderColor !== undefined} title={translate.border_color}>
        <Grid container spacing={1} alignItems={'center'}>
          {!!borderColor && (
            <Grid item>
              <IconButton edge={'end'} color={'secondary'} onClick={() => update('borderColor', undefined)}>
                <Icon type={'material'} icon={'Cancel'} />
              </IconButton>
            </Grid>
          )}
          <Grid item xs>
            <ColorField value={borderColor} onChange={(c) => update('borderColor', c)} />
          </Grid>
        </Grid>
      </HighlightFormRow>
      <HighlightFormRow highlight={value.hasContainer !== undefined} title={translate.use_smaller_container}>
        <CheckboxField checked={hasContainer} onChange={(e) => update('hasContainer', e.target.checked)} />
      </HighlightFormRow>
      <HighlightFormRow highlight={value.spacing !== undefined} title={translate.column_separator}>
        <InputField
          type={'number'}
          value={spacing}
          onBlur={(e) => update('spacing', +e.target.value)}
          onChange={(e) => update('spacing', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
            min: 0,
          }}
        />
      </HighlightFormRow>
      <HighlightFormRow
        highlight={value.marginTop !== undefined || value.marginBottom !== undefined}
        title={translate.vertical_margin}
      >
        <InputField
          type={'number'}
          value={marginTop}
          onBlur={(e) => update('marginTop', +e.target.value)}
          onChange={(e) => update('marginTop', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
          }}
          // eslint-disable-next-line
          InputProps={{
            startAdornment: <InputAdornment position={'start'}>{translate.top_margin}</InputAdornment>,
          }}
        />
        <InputField
          type={'number'}
          value={marginBottom}
          onBlur={(e) => update('marginBottom', +e.target.value)}
          onChange={(e) => update('marginBottom', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
          }}
          // eslint-disable-next-line
          InputProps={{
            startAdornment: <InputAdornment position={'start'}>{translate.bottom_margin}</InputAdornment>,
          }}
        />
      </HighlightFormRow>
      <HighlightFormRow
        highlight={value.marginLeft !== undefined || value.marginRight !== undefined}
        title={translate.horizontal_margin}
      >
        <InputField
          type={'number'}
          value={marginLeft}
          onBlur={(e) => update('marginLeft', +e.target.value)}
          onChange={(e) => update('marginLeft', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
            min: 0,
          }}
          // eslint-disable-next-line
          InputProps={{
            startAdornment: <InputAdornment position={'start'}>{translate.left_margin}</InputAdornment>,
          }}
        />
        <InputField
          type={'number'}
          value={marginRight}
          onBlur={(e) => update('marginRight', +e.target.value)}
          onChange={(e) => update('marginRight', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
            min: 0,
          }}
          // eslint-disable-next-line
          InputProps={{
            startAdornment: <InputAdornment position={'start'}>{translate.right_margin}</InputAdornment>,
          }}
        />
      </HighlightFormRow>
    </>
  );
};
