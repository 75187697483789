import React from 'react';
import { GET_COLLECTIONS } from './query';
import { parseConnection } from '../../../shared';
import SelectComboCollection from '../../../components/SelectCombo/SelectComboCollection';
import { Query } from '@apollo/client/react/components';
import _ from 'lodash';

const TriggerFieldCollectionCodes = ({ disabled, trigger = {}, onChange = (_) => _ }) => {
  const { triggerIds = [] } = trigger || {};

  return (
    <Query
      query={GET_COLLECTIONS}
      variables={{ id: localStorage.getItem('shopId'), codes: triggerIds }}
      skip={(triggerIds || []).length === 0}
      onCompleted={(data) => {
        const collections = parseCollections(data);
        onChange({
          ...trigger,
          triggerIdName: _.uniq(collections.map((col) => col.name)).join('/'),
        });
      }}
    >
      {({ loading, data }) => {
        const _collections = parseCollections(data);
        const collections = _collections.concat(
          (triggerIds || [])
            .filter((triggerId) => !_collections.find((col) => col.id === triggerId || col.code === triggerId))
            .map((triggerId) => ({
              id: triggerId,
            })),
        );
        // const { name } = node || {};
        return (
          <SelectComboCollection
            copyableType={'ShopCollection'}
            idKey={'code'}
            multiple={true}
            required={true}
            disabled={disabled}
            value={collections}
            onChange={(collections) => {
              trigger.triggerIds = collections.map((col) => col.code ?? col.id);
              trigger.triggerIdName = collections.map((col) => col.name).join('/');
              onChange(trigger);
            }}
          />
        );
      }}
    </Query>
  );

  function parseCollections({ node } = {}) {
    const collections = parseConnection((node || {}).collections).nodes;
    const collections2 = parseConnection((node || {}).collections2).nodes;

    if (collections2.length > 0) return _.unionBy(collections, collections2, 'id');
    return collections;
  }
};

export default TriggerFieldCollectionCodes;
