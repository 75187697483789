import React from 'react';
import { Box, InputAdornment } from '@material-ui/core';
import { translate as t } from 'shared/translate';
import SelectField from 'components/FormPageMaker/Fields/SelectField';
import InputField from 'components/FormPageMaker/Fields/InputField';
import { GET_PRODUCTS } from './query';
import { Query } from '@apollo/client/react/components';
import SelectComboProduct from '../../../components/SelectCombo/SelectComboProduct';
import { parseConnection } from '../../../shared';
import { GET_SHIPPING_ZONE } from '../TargetCard/query';
import SelectComboShippingZone from '../../../components/SelectCombo/SelectComboShippingZone';
import CollectionCodesActionField from './CollectionCodesActionField';
import HashTagsArrayField from '../../../components/FormPageMaker/Fields/HashTagsArrayField';
import { translate } from '../../../shared/translate';

export default ({ action, onChange, disabled, excludeModes = [] }) => {
  const {
    actionType,
    actionValueType,
    actionValue,
    actionId,
    actionIds,
    /* this is the imaginary field to help SINGLE_COLLECTION and SINGLE_PRODUCT */
    _repeat,
  } = action || {};
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box flex={1} mr={2}>
        <SelectField
          value={actionType}
          required={true}
          disabled={disabled}
          onChange={(e) => {
            action.actionType = e.target.value;
            action.actionId = undefined;
            action.actionIds = undefined;
            action.actionValueType = 'PERCENTAGE';
            onChange(action);
          }}
          options={[
            { label: t.designated_collection, value: 'COLLECTION' },
            { label: t.designated_product, value: 'PRODUCT' },
            {
              label: t.designated_product_hashtags,
              value: 'PRODUCT_TAG',
            },
            { label: t.discount_for_order, value: 'ORDER' },
            { label: t.discount_for_free_ship, value: 'FREE_SHIP' },
            {
              label: t.member_points,
              value: 'MEMBER_POINTS',
            },
          ].map((opt) => ({
            ...opt,
            disabled: !!~excludeModes.indexOf(opt.value),
          }))}
        />
      </Box>
      {actionType === 'COLLECTION' && (
        <Box flex={1} mr={2}>
          <CollectionCodesActionField disabled={disabled} action={action} onChange={onChange} />
        </Box>
      )}
      {actionType === 'PRODUCT' && (
        <Box flex={1} mr={2}>
          <Query
            query={GET_PRODUCTS}
            variables={{ id: localStorage.getItem('shopId'), skus: actionIds }}
            skip={(actionIds || []).length === 0}
            onCompleted={({ node } = {}) => {
              const products = parseConnection((node || {}).products).nodes;
              action.actionIdName = products.map((product) => product.name).join('/');
              onChange(action);
            }}
          >
            {({ loading, data: { node } = {} }) => {
              const _products = parseConnection((node || {}).products).nodes;
              const products = _products.concat(
                (actionIds || [])
                  .filter((actionId) => !_products.find((col) => col.id === actionId || col.sku === actionId))
                  .map((actionId) => ({
                    sku: actionId,
                  })),
              );
              return (
                <SelectComboProduct
                  copyableType={'ShopProduct'}
                  idKey={'sku'}
                  multiple={true}
                  required={true}
                  loading={loading}
                  placeholder={t.product}
                  disabled={disabled}
                  value={products}
                  onChange={(products) => {
                    action.actionIds = products.map((product) => product.sku ?? product.id);
                    action.actionIdName = products.map((product) => product.name).join('/');
                    onChange(action);
                  }}
                />
              );
            }}
          </Query>
        </Box>
      )}
      {actionType === 'PRODUCT_TAG' && (
        <Box flex={1} mr={2}>
          <HashTagsArrayField
            value={actionIds || []}
            onChange={(v) => {
              action.actionIds = (v || []).filter((_) => _);
              onChange(action);
            }}
            placeholder={translate.input_without || `輸入內容無需加 #`}
            disabled={disabled}
          />
        </Box>
      )}
      {actionType === 'FREE_SHIP' && (
        <Box flex={1}>
          <Query
            query={GET_SHIPPING_ZONE}
            variables={{ id: actionId }}
            skip={!actionId}
            onCompleted={({ node } = {}) => {
              const { name } = node || {};
              action.actionIdName = name;
              onChange(action);
            }}
          >
            {({ loading, data: { node } = {} }) => {
              const { name } = node || {};
              return (
                <SelectComboShippingZone
                  copyableType={'ShopShippingZone'}
                  required={true}
                  placeholder={t.shipping_method}
                  disabled={disabled}
                  value={{ id: actionId, name: loading ? t.loading : name }}
                  onChange={({ id, name } = {}) => {
                    action.actionId = id;
                    action.actionIdName = name;
                    onChange(action);
                  }}
                />
              );
            }}
          </Query>
        </Box>
      )}
      {actionType === 'MEMBER_POINTS' && (
        <>
          <Box flex={1} mr={2}>
            <InputField
              type={'number'}
              value={actionValue}
              required={true}
              // eslint-disable-next-line
              inputProps={{
                step: 1,
                min: 0,
              }}
              disabled={disabled}
              onChange={(e) => {
                action.actionValue = e.target.value;
                onChange(action);
              }}
            />
          </Box>
          <Box flex={1}>
            <SelectField
              required={true}
              value={actionValueType}
              options={[
                { label: (t.extra_fixed_points || '').replace(/{0\}/gi, ''), value: 'AMOUNT' },
                { label: (t.n_percent_points || '').replace(/{0\}/gi, ''), value: 'PERCENTAGE' },
              ]}
              onChange={(e) => {
                action.actionValueType = e.target.value;
                onChange(action);
              }}
            />
          </Box>
        </>
      )}
      {!!~['COLLECTION', 'PRODUCT', 'PRODUCT_TAG'].indexOf(actionType) && (
        <>
          <Box flex={1} mr={2}>
            <InputField
              value={_repeat}
              disabled={disabled}
              placeholder={t.all_products}
              onChange={(e) => {
                action._repeat = e.target.value;
                onChange(action);
              }}
              type={'number'}
              // eslint-disable-next-line
              inputProps={{
                step: 1,
                max: 10,
              }}
              // eslint-disable-next-line
              InputProps={{
                startAdornment: <InputAdornment position={'start'}>{t.quantity}</InputAdornment>,
                endAdornment: !!_repeat && <InputAdornment position={'end'}>{t.unit_item}</InputAdornment>,
              }}
            />
          </Box>
        </>
      )}
      {!!~['COLLECTION', 'PRODUCT', 'ORDER', 'PRODUCT_TAG'].indexOf(actionType) && (
        <>
          <Box flex={1} mr={2}>
            <SelectField
              value={actionValueType}
              required={true}
              disabled={disabled}
              onChange={(e) => {
                action.actionValueType = e.target.value;
                onChange(action);
              }}
              options={[
                {
                  label: t.discount_off,
                  value: 'PERCENTAGE',
                },
                {
                  label: t.discount_fixed_amount,
                  value: 'AMOUNT',
                },
                // {
                //   label: t.discount_gift,
                //   value: 'GIFT',
                // },
              ]}
            />
          </Box>
          <Box flex={1}>
            <InputField
              type={'number'}
              value={actionValue}
              required={true}
              disabled={disabled}
              onChange={(e) => {
                action.actionValue = e.target.value;
                onChange(action);
              }}
              // eslint-disable-next-line
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    {
                      {
                        AMOUNT: '$',
                      }[actionValueType]
                    }
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position={'end'}>
                    {
                      {
                        PERCENTAGE: '%OFF',
                        GIFT: t.unit_item,
                      }[actionValueType]
                    }
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
