import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { parseConnection } from '../../shared';
import _ from 'lodash';
import SelectCombo from './index';

const SEARCH_QUERY = gql`
  query ($id: ID!, $cursor: Int, $sortBy: [SorterInput!], $query: String) {
    node(id: $id) {
      id
      ... on Company {
        warehouses(limits: 99999, cursor: $cursor, sortBy: $sortBy, query: $query) {
          nextCursor
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

const SelectComboWarehouse = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  const { loading, data, fetchMore } = useQuery(SEARCH_QUERY, {
      variables: {
        id: localStorage.getItem('companyId'),
        ...props?.variables,
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);

  function getConnection(data) {
    const { node } = data || {};
    return parseConnection((node || {}).warehouses);
  }

  return (
    <>
      <SelectCombo
        onOpen={setOpen}
        getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
        options={nodes}
        getOptionLabel={(option) => option?.name ?? ''}
        loading={loading}
        onScrollBottom={async () => {
          if (!!nextCursor && !loading)
            await fetchMore({
              variables: {
                cursor: nextCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                const fetchMoreConnection = getConnection(fetchMoreResult),
                  { nodes: fetchMoreNodes } = fetchMoreConnection,
                  { nodes: prevNodes } = getConnection(prevResult);
                fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

                return {
                  node: {
                    ...(fetchMoreResult.node || {}),
                    warehouses: fetchMoreConnection,
                  },
                };
              },
            });
        }}
        {...props}
      />
    </>
  );
};

export default SelectComboWarehouse;
