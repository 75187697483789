import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const InnerSelectField = ({ value, options, disabled, placeholder, onChange = (v) => {}, style }) => {
  const [anchorEl, setAnchorEl] = useState(false);

  const option = options?.find((opt) => opt?.value === value);
  return (
    <>
      <Avatar
        variant={'rounded'}
        style={{
          width: 30,
          height: 20,
          fontSize: '1rem',
          border: '1px solid #ddd',
          cursor: disabled ? undefined : 'pointer',
          backgroundColor: 'transparent',
          color: '#555',
          ...style,
        }}
        onClick={(e) => {
          if (disabled) return;

          setAnchorEl(e?.target);
        }}
      >
        {option?.icon || option?.label || placeholder || '?'}
      </Avatar>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {options.map((opt, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              onChange(opt?.value);
              setAnchorEl(null);
            }}
          >
            {opt?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default InnerSelectField;
