import React from 'react';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/icon/openai-svgrepo-com.svg')} alt="openai" />,
    label: 'OpenAI',
    value: 'OPENAI',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'API Key',
            type: 'text',
            name: 'secret',
            required: true,
            maxLength: 255,
            placeholder: 'sk-xxxxxxxxxxxxxx',
            afterChanged: (v) => {
              actions.setFieldValue('identity', 'OPENAI');
              actions.setFieldValue('metadata.apiKey', v);
            },
          },
        ]}
      />
    );
  },
  type: 'AUTHENTICATION',
};
