import { gql } from '@apollo/client';

export const FRAGMENT_SHOP_FORM_RECORD = gql`
  fragment FRAGMENT_SHOP_FORM_RECORD on ShopFormRecord {
    id
    updatedAt
    createdAt
    active
    form {
      id
      metadataFields {
        key
        name
        type
        required
        values
        unique
        metadata {
          key
          value
        }
      }
    }
    customer {
      id
      picture
      email
      metadata {
        key
        value
      }
    }
    status
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_SHOP_FORM_RECORD
    }
  }
  ${FRAGMENT_SHOP_FORM_RECORD}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $fields: [MetadataInput!]!) {
    shopFormRecordUpdate(id: $id, fields: $fields) {
      ...FRAGMENT_SHOP_FORM_RECORD
    }
  }
  ${FRAGMENT_SHOP_FORM_RECORD}
`;

export const APPROVE_QUERY = gql`
  mutation ($id: ID!) {
    shopFormRecordApprove(id: $id) {
      id
      status
    }
  }
`;

export const REJECT_QUERY = gql`
  mutation ($id: ID!) {
    shopFormRecordReject(id: $id) {
      id
      status
    }
  }
`;
