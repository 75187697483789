import React from 'react';
import { translate } from '../../shared/translate';
import Taber from '../../components/Taber';
import { useHistory } from 'react-router-dom';

export const merchantSettingTabs = [
  {
    name: translate.merchant_setting,
    _link: '/merchant',
  },
  {
    name: translate.referenceNo_setting,
    _link: '/merchant/reference_no_setting',
  },
];

const MerchantTaber = ({ currentTab = 0 }) => {
  const history = useHistory();
  return (
    <Taber
      currentTab={currentTab}
      tabs={merchantSettingTabs.map((tab, i) => ({
        ...tab,
        onClick: i === currentTab ? undefined : () => history.push(tab?._link),
      }))}
    />
  );
};

export default MerchantTaber;
