import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import CursorBaseList from '../../../CursorBaseList';
import { GET_ORDERS } from './query';
import moment from 'moment';
import { Link } from 'react-router-dom';
import StatusBadge from '../../../StatusBadge';

const ChannelCustomerDetailOrders = ({ customerId }) => {
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <CursorBaseList
          query={GET_ORDERS}
          variables={{
            id: customerId,
            filter: { active: { operator: 'IN', value: ['true'] } },
          }}
          getConnection={(data) => data?.node?.orders}
          renderItem={(order, i) => {
            return (
              <Grid key={i} item xs={12}>
                <Link to={`/orders/${order?.id}`} target={'_blank'}>
                  <Card>
                    <Box p={1} pl={2}>
                      <Grid container justify={'space-between'}>
                        <Grid item>
                          <Typography variant={'h6'}>{order?.referenceNo}</Typography>
                        </Grid>
                        <Grid item>
                          <StatusBadge status={order?.status} />
                        </Grid>
                      </Grid>
                      <Typography variant={'body2'}>{moment(order?.createdAt).format('D MMM YYYY LT')}</Typography>
                    </Box>
                  </Card>
                </Link>
              </Grid>
            );
          }}
        />
      </Grid>
    </Box>
  );
};

export default ChannelCustomerDetailOrders;
