import React from 'react';
import { translate } from 'shared/translate';
import ConnectionPageMaker from '../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { getCommonColumns } from '../../shared';
import { modules } from '../item_Credential';
import { Grid, Typography } from '@material-ui/core';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/credentials/new',
    getRowLink: ({ id, name }) => ({ pathname: `/credentials/${id}`, state: { title: name } }),
    fields: [
      {
        title: translate['credential_platform'],
        value: ({ platform } = {}) => {
          const module = modules.find((module) => module?.platform?.value === platform);
          if (module)
            return (
              <Grid container alignItems={'center'} spacing={1}>
                {module.platform?.icon && <Grid item>{module.platform?.icon}</Grid>}
                <Grid item>
                  <Typography>{module.platform?.label}</Typography>
                </Grid>
              </Grid>
            );

          return <Typography color={'error'}>⚠️ {platform}</Typography>;
        },
      },
      {
        title: translate['type'],
        fieldName: 'type',
        align: 'center',
      },
      ...getCommonColumns({
        createdAt: {
          filter: null,
          sortBy: null,
        },
        updatedAt: {
          filter: null,
          sortBy: null,
        },
      }),

      {
        title: translate['activate_checkbox'],
        fieldName: 'active',
        type: 'bool',
        align: 'center',
      },
    ],
    selectedItem: undefined,
  };

  getExtraFetchVariables() {
    return {
      id: localStorage.getItem('shopId'),
    };
  }
  getData = ({ node } = {}) => {
    const { credentials = [] } = node || {};
    return {
      nodes: credentials,
      totalCount: credentials.length,
      nextCursor: null,
    };
  };
  renderPagination() {
    return null;
  }
}
