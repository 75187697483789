import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import { FRAGMENT_SERVICE_LOCATION_SLOT } from '../../../routes/item_ServiceLocation/ScheduleCalendar/query';

const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ServiceLocationSlotFilterInput) {
    node(id: $id) {
      ... on ShopServiceLocation {
        id
        slots(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_SERVICE_LOCATION_SLOT
          }
        }
      }
    }
  }
  ${FRAGMENT_SERVICE_LOCATION_SLOT}
`;

export default async function (source, { id, cursor, limits, sortBy, filter }) {
  return await infinityFetch({
    fetchPolicy: 'network-only',
    query: GET_QUERY,
    variables: {
      id,
      cursor,
      limits,
      sortBy,
      filter,
    },
    getConnection: (data) => data?.node?.slots,
  });
}
