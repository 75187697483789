import { gql } from '@apollo/client';

export const FRAGMENT_TABLE = gql`
  fragment FRAGMENT_TABLE on ShopTable {
    id
    createdAt
    updatedAt
    name
    sortIndex
    status
    venue {
      id
      name
    }
    shop {
      id
      name
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $query: String, $filter: TableFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        tables(cursor: $cursor, limits: $limits, sortBy: $sortBy, query: $query, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_TABLE
          }
        }
      }
    }
  }
  ${FRAGMENT_TABLE}
`;
