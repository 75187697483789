import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputField from './InputField';
import { translate } from '../../../shared/translate';
import LSkeleton from '../../LSkeleton';
import MobileField from './MobileField';
import AddressLinesField from './AddressLinesField';
import CountryField from './CountryField';

export default ({ loading, required, value: _value, onChange = (_) => _, disabled, className = '' }) => {
  const value = _value || {};

  if (loading)
    return (
      <div>
        <LSkeleton height={30} />
        <LSkeleton height={30} />
        <LSkeleton height={30} />
        <LSkeleton height={30} />
      </div>
    );

  return (
    <table style={{ width: '100%' }} cellPadding={3}>
      <tr>
        <td style={{ verticalAlign: 'top', whiteSpace: 'pre' }}>
          <Typography>{translate.address_name}</Typography>
        </td>
        <td>
          <InputField
            disabled={disabled}
            value={value?.name || ''}
            required={required}
            onChange={(e) =>
              onChange({
                ...value,
                name: e.target.value,
              })
            }
          />
        </td>
      </tr>
      <tr>
        <td style={{ verticalAlign: 'top', whiteSpace: 'pre' }}>
          <Typography>{translate.contact_person}</Typography>
        </td>
        <td>
          <InputField
            disabled={disabled}
            value={value?.person || ''}
            required={required}
            onChange={(e) =>
              onChange({
                ...value,
                person: e.target.value,
              })
            }
          />
        </td>
      </tr>
      <tr>
        <td style={{ verticalAlign: 'top', whiteSpace: 'pre' }}>
          <Typography>{translate.contact_phone_no}</Typography>
        </td>
        <td>
          <MobileField
            disabled={disabled}
            value={value?.tel || ''}
            required={required}
            onChange={(e) =>
              onChange({
                ...value,
                tel: e.target.value,
              })
            }
          />
        </td>
      </tr>
      <tr>
        <td style={{ verticalAlign: 'top', whiteSpace: 'pre' }}>
          <Typography>{translate.email}</Typography>
        </td>
        <td>
          <InputField
            type={'email'}
            disabled={disabled}
            value={value?.email || ''}
            required={required}
            onChange={(e) =>
              onChange({
                ...value,
                email: e.target.value,
              })
            }
          />
        </td>
      </tr>
      <tr>
        <td style={{ verticalAlign: 'top', whiteSpace: 'pre' }}>
          <Typography>{translate.address}</Typography>
        </td>
        <td>
          <AddressLinesField
            descriptionMode={'placeholder'}
            disabled={disabled}
            required={required}
            value={value?.lines}
            onChange={(v) =>
              onChange({
                ...value,
                lines: v,
              })
            }
          />
        </td>
      </tr>
      <tr>
        <td style={{ verticalAlign: 'top', whiteSpace: 'pre' }}>
          <Typography>{translate.country}</Typography>
        </td>
        <td>
          <CountryField
            disabled={disabled}
            required={required}
            value={value?.country}
            onChange={(v) =>
              onChange({
                ...value,
                country: v,
              })
            }
          />
        </td>
      </tr>
    </table>
  );

  return (
    <Grid container spacing={1} className={className}>
      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <InputField
          name={'address-line1'}
          disabled={disabled}
          value={value[0] || ''}
          required={required}
          onChange={(e) => onChange([e.target.value, value[1] || '', value[2] || ''])}
        />
        <Typography variant={'body2'}>{translate.address_lines_remark1}</Typography>
      </Grid>
      <Grid item>
        <InputField
          name={'address-line2'}
          disabled={disabled}
          value={value[1] || ''}
          onChange={(e) => onChange([value[0] || '', e.target.value, value[2] || ''])}
        />
        <Typography variant={'body2'}>{translate.address_lines_remark2}</Typography>
      </Grid>
      <Grid item>
        <InputField
          name={'address-line3'}
          disabled={disabled}
          value={value[2] || ''}
          onChange={(e) => onChange([value[0] || '', value[1] || '', e.target.value])}
        />
        <Typography variant={'body2'}>{translate.address_lines_remark3}</Typography>
      </Grid>
    </Grid>
  );
};
