import React, { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { client } from '../../../shared/apollo';
import { GET_ORDER_DATA } from './query';
import { CircularProgress, Grid } from '@material-ui/core';
import SelectComboOrder from '../../../components/SelectCombo/SelectComboOrder';
import ActivityIndicator from '../../../components/ActivityIndicator';
import errorParser from '../../../shared/errorParser';
import { translate } from '../../../shared/translate';
import toast from '../../../shared/toast';
import { parseConnection } from '../../../shared';

export function getFormReturnItems(preInitializedReturnNoteItems = [], orderItems = []) {
  return parseConnection(preInitializedReturnNoteItems).nodes.reduce((reducer, item) => {
    const targetItem = reducer.find((o) => {
      if (o.active) return false;
      if (o.orderItem?.id !== (item?.orderItemId ?? item?.orderItem?.id)) return false;

      if (item?.bundleProductOptionId) {
        if (o?.bundleProductOptionId !== item?.bundleProductOptionId) return false;
      }
      return true;
    });

    if (targetItem) {
      targetItem.active = true;
      Object.assign(targetItem, item);
    }
    return reducer;
  }, expandOrderItems(parseConnection(orderItems).nodes));
}
export function expandOrderItems(items) {
  return items?.reduce((reducer, item) => {
    if (item?.addOnProduct) {
    } else if (item?.bundleProduct) {
      for (let i = 0; i < item?.quantity; i++) {
        for (const option of item?.bundleProduct?.options ?? []) {
          reducer.push({
            active: false,
            orderItem: item,
            bundleProductOptionId: option?.id,
          });
        }
      }
    } else {
      for (let i = 0; i < item?.quantity; i++) {
        reducer.push({
          active: false,
          orderItem: item,
        });
      }
    }

    return reducer;
  }, []);
}

const OrderSelectField = ({ values: { id, order } = {}, setFieldValue, loading, isSubmitting }) => {
  const [fetching, setFetching] = useState(false);
  if (loading) return <Skeleton height={30} />;

  return (
    <Grid container spacing={3} alignItems={'center'}>
      <Grid item xs>
        <SelectComboOrder
          filterType={'COMPLETED'}
          required={true}
          disabled={!!id || isSubmitting}
          value={order}
          onChange={(order) => {
            if (!!order) {
              (async () => {
                try {
                  setFetching(true);
                  ActivityIndicator.show(translate.load_order_default_data);
                  const { data: { node } = {} } = await client.query({
                    fetchPolicy: 'no-cache',
                    query: GET_ORDER_DATA,
                    variables: { id: order.id },
                  });
                  setFieldValue('order', node);
                  setFieldValue('items', getFormReturnItems(node?.returnNoteItems, node?.items));
                } catch (e) {
                  toast.error(errorParser(e));
                } finally {
                  setFetching(false);
                  ActivityIndicator.hide();
                }
              })();
            } else {
              setFieldValue('order', undefined);
              setFieldValue('items', []);
            }
          }}
        />
      </Grid>
      {fetching && (
        <Grid item>
          <CircularProgress size={15} />
        </Grid>
      )}
    </Grid>
  );
};

export default OrderSelectField;
