import { gql } from '@apollo/client';

const FRAGMENT_SHOP_SERVICE_LOCATION = gql`
  fragment FRAGMENT_SHOP_SERVICE_LOCATION on ShopServiceLocation {
    id
    updatedAt
    createdAt
    medias {
      src
    }
    address {
      name
      person
      tel
      email
      lines
      district
      country
    }
    metadata {
      key
      value
    }
    name
    sortIndex
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_SHOP_SERVICE_LOCATION
    }
  }
  ${FRAGMENT_SHOP_SERVICE_LOCATION}
`;

export const CREATE_QUERY = gql`
  mutation ($input: ServiceLocationCreateInput!) {
    result: serviceLocationCreate(input: $input) {
      ...FRAGMENT_SHOP_SERVICE_LOCATION
    }
  }
  ${FRAGMENT_SHOP_SERVICE_LOCATION}
`;

export const POST_QUERY = gql`
  mutation ($id: ID!, $input: ServiceLocationUpdateInput!) {
    result: serviceLocationUpdate(id: $id, input: $input) {
      ...FRAGMENT_SHOP_SERVICE_LOCATION
    }
  }
  ${FRAGMENT_SHOP_SERVICE_LOCATION}
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    result: serviceLocationDelete(id: $id) {
      id
    }
  }
`;
