import React, { useEffect, useState } from 'react';
import { Button, Box, withStyles } from '@material-ui/core';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import OverlayLoading from '../OverlayLoading';

const { REACT_APP_WEBHOOK_URL } = process.env;
const styles = (theme) => ({
  RemoveBackgroundStepContainer: {
    padding: '10px 20px',
    height: '100%',
  },
  stepTitleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepTitle: {
    color: '#1F4C6D',
    fontSize: '18px',
    fontWeight: '500',
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  stepperPrimaryBtn: {
    background: '#5B7D96',
    boxShadow: '2px 2px 2px 0px rgb(135 135 135 / 40%) inset',
    backdropFilter: 'blur(5px)',
    // -webkit-backdrop-filter: blur(5px);
    border: '1px solid rgba(255, 255, 255, 0.20)',
    //   border: 'none',
    borderRadius: '30px',
    color: '#fff',
    padding: '7px 14px',
    fontSize: '16px',
    width: '160px',
    '&:hover': {
      background: '#3b5569',
    },
  },
  stepperSecondaryBtn: {
    background: '#ddd',
    // -webkit-backdrop-filter: blur(5px);
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '2px 2px 2px 0px rgb(227 227 227 / 40%) inset',
    //   border: 'none',
    borderRadius: '30px',
    color: '#fff',
    padding: '7px 14px',
    fontSize: '16px',
    width: '160px',
  },
});

const MagicMediaRemoveBackground = ({ classes, value, onChange, handleClose, handleGoBack }) => {
  const [processing, setProcessing] = useState();
  const [newSrc, setNewSrc] = useState('');
  const { src } = value || {};

  useEffect(() => {
    (async () => {
      try {
        setProcessing(true);
        const res = await fetch(`${REACT_APP_WEBHOOK_URL}/photoroom/segment?imageUrl=${src}`, {
          method: 'POST',
          headers: {
            authorization: localStorage.getItem('token'),
          },
        });
        const { url } = await res.json();
        setNewSrc(url);
      } catch (e) {
        toast.error(errorParser(e));
        handleGoBack();
      } finally {
        setProcessing(false);
      }
    })();
  }, []);

  return (
    <>
      <Box
        className={classes.RemoveBackgroundStepContainer}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          style={{
            width: '80%',
            flexGrow: 1,
            backgroundImage: `url(${require('../../assets/transparent-grid.png')})`,
          }}
          mb={3}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              backgroundImage: `url(${newSrc || src})`,
              backgroundPositionX: 'center',
              backgroundPositionY: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            {processing && <OverlayLoading />}
          </Box>
        </Box>
        <Box className={classes.btnGroup}>
          <Button className={classes.stepperSecondaryBtn} onClick={handleGoBack}>
            返回
          </Button>
          <Button
            disabled={processing || !newSrc}
            className={classes.stepperPrimaryBtn}
            onClick={() => {
              onChange({ ...value, src: newSrc, optimizedSrc: undefined });
              handleClose();
            }}
          >
            確定使用
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(MagicMediaRemoveBackground);
