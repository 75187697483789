import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { GET_QUERY, POST_QUERY } from './query';
import { client } from '../../shared/apollo';
import { convertMetaArray, convertMetaObject } from '../../shared';
import PlatformsCard, { platforms } from './PlatformsCard';
import WelcomeMessagesCard from './WelcomeMessagesCard';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
    },
    tabs: [
      {
        name: translate.links_and_other_settings || '網站內容設定',
        cards: [
          {
            id: '#' + translate.platforms_link,
            name: translate.platforms_link,
            fields: [
              {
                md: 12,
                id: '#' + translate.platforms_link,
                render: PlatformsCard,
              },
            ],
          },
          {
            name: translate.other_website_content,
            fields: [
              {
                id: '#' + translate.business_address,
                label: translate.business_address,
                type: 'textarea',
                name: 'address',
                md: 6,
                description: translate.website_setting_hint || '建議最多輸入 5 行，以確保資訊清晰簡潔',
              },
              {
                id: '#' + translate.business_hours,
                label: translate.business_hours,
                type: 'textarea',
                name: 'business_hours',
                md: 6,
                description: translate.website_setting_hint || '建議最多輸入 5 行，以確保資訊清晰簡潔',
              },
              {
                id: '#' + translate.website_introduction,
                label: translate.website_introduction,
                type: 'textarea',
                name: 'introduction',
                md: 6,
                description: translate.website_setting_hint || '建議最多輸入 5 行，以確保資訊清晰簡潔',
              },
              {
                id: '#' + translate.qr_code_welcome_msg,
                label: translate.qr_code_welcome_msg,
                type: 'textarea',
                name: 'metadata.qrCodeWelcomeMsg',
                md: 6,
                description:
                  translate.qr_code_welcome_msg_placeholder ||
                  '只適用於餐飲模式。客戶掃描 QR 碼後，此自訂訊息將呈現於顧客端',
              },
              {
                id: '#' + translate.website_main_color,
                label: translate.website_main_color || '網站主配色',
                type: 'color',
                name: 'primaryColor',
                md: 'auto',
              },
              {
                id: '#' + translate.website_secondary_color,
                label: translate.website_secondary_color || '網站次配色',
                type: 'color',
                name: 'secondaryColor',
                md: 'auto',
              },
              {
                md: 12,
                id: '#' + translate.welcome_messages,
                label: translate.welcome_messages,
                render: WelcomeMessagesCard,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          name: values.name,
          welcomeMessages: values.welcomeMessages || [],
          metadata: convertMetaArray({
            ...values.metadata,
            ...platforms.reduce((reducer, { key }) => {
              reducer[key] = values.platforms?.find((p) => p?.key === key)?.value || undefined;
              return reducer;
            }, {}),
            address: values.address,
            business_hours: values.business_hours,
            introduction: values.introduction,
            primaryColor: values.primaryColor,
            secondaryColor: values.secondaryColor,
          }),
        },
      },
    });
  };

  getInitialData({ node, me }) {
    const { name, metadata, welcomeMessages } = node || {};
    const { address, business_hours, introduction, primaryColor, secondaryColor, ...metadataObj } =
      convertMetaObject(metadata);

    return {
      name,
      welcomeMessages: welcomeMessages || [],
      metadata: metadataObj,
      platforms: platforms.map(({ key }) => ({ key, value: metadataObj[key] || '' })).filter((m) => !!m?.value),
      address: address,
      business_hours: business_hours,
      introduction: introduction,
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
    };
  }
}
