import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboProduct from '../../SelectCombo/SelectComboProduct';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, sku, name] = str.match(/^(.*?)∆(.*?)∆(.*)$/);
      return { id, sku, name };
    });

  const [products, setProducts] = useState(values);

  return (
    <FormRow title={title}>
      <SelectComboProduct
        multiple
        disablePortal={false}
        value={products}
        onChange={(products) => {
          setProducts(products);
          onFilterChange({
            value: (products || []).map((product) => `${product.id}∆${product.sku}∆${product.name}`),
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
