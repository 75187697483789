import React, { useState } from 'react';
import SystemButton from '../../../components/SystemButton';
import { translate } from '../../../shared/translate';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { USER_DEACTIVATE_COUPON } from './query';
import { client } from '../../../shared/apollo';
import confirmation from '../../../shared/confirmation';

const DeactivateButton = ({ id, disabled, onCompleted = (_) => _ }) => {
  const [loading, setLoading] = useState(false);

  return (
    <SystemButton
      color="secondary"
      loading={loading}
      disabled={loading || disabled}
      onClick={async () => {
        try {
          if (await confirmation(translate.deactivate_customer_coupon_confirmation)) {
            setLoading(true);
            await client.mutate({
              mutation: USER_DEACTIVATE_COUPON,
              variables: {
                couponId: id,
              },
            });
            onCompleted();
          }
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.deactivate}
    </SystemButton>
  );
};

export default DeactivateButton;
