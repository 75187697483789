import { Backdrop, Box, CircularProgress, Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AIButton from '../../components/AIButton';
import { translate } from 'shared/translate';
import { Formik } from 'formik';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import useBreakPoint from '../../components/useBreakPoint';
import StockField from '../item_Product/StockField';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import AIResult from './AIResult';
import useBedrockProducts from '../../hooks/useBedrockProducts';
import SystemButton from '../../components/SystemButton';
import { Icon } from '../../components/IconRender';

const useStyles = makeStyles((theme) => ({
  textInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: '#fff',
      height: 47,
    },
  },
}));

const AIForm = ({ aiDescription }) => {
  const classes = useStyles();
  const breakPoint = useBreakPoint();
  const isUnderMD = ['xs', 'sm'].includes(breakPoint);

  const [{ items, loading, error }, invoke] = useBedrockProducts();
  const [input, setInput] = useState();
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    if (!!error) toast.error(errorParser(error));
  }, [error]);

  return (
    <>
      <style>
        {`
          .MuiCircularProgress-colorPrimary {
            color: #fff !important;
          }
        `}
      </style>
      <Formik
        onSubmit={async ({ description, sku, unitPrice, quantity, ignoreStock }, { setSubmitting }) => {
          try {
            await invoke({
              input: description,
              language: (localStorage.getItem('lang') || 'zh-hk') === 'zh-hk' ? '繁體' : '英文',
            });
            setInput({ sku, unitPrice, quantity, ignoreStock });
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setSubmitting(false);
          }
        }}
        initialValues={{ description: aiDescription }}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          const { description, sku, unitPrice, quantity, ignoreStock } = values;
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Box py={5} px={isUnderMD ? 5 : 10}>
                  <Backdrop open={loading} style={{ zIndex: 999 }}>
                    <Box style={{ display: 'flex', flexFlow: 'column', height: '100vh' }}>
                      <Box style={{ flex: 5, alignContent: 'center' }}>
                        <CircularProgress color="primary" size={70} />
                        <Typography
                          variant={'h6'}
                          component={'p'}
                          style={{ color: '#fff', paddingTop: 10 }}
                          align={'center'}
                        >
                          {translate.generating}...
                        </Typography>
                      </Box>
                      <Box style={{ flex: 1 }}>
                        <SystemButton
                          variant="outlined"
                          style={{ color: '#fff', border: '1px solid #fff' }}
                          startIcon={<Icon icon={'faStopCircle'} style={{ fontSize: '1.2rem' }} />}
                          onClick={() => {
                            setIsCancel(true);
                            window.location.reload();
                          }}
                        >
                          {translate.cancel}
                        </SystemButton>
                      </Box>
                    </Box>
                  </Backdrop>
                  <Grid container spacing={3}>
                    <Grid item xs="12">
                      <InputField
                        required={true}
                        value={description}
                        onChange={(e) => setFieldValue('description', e.target.value)}
                        variant={'outlined'}
                        className={classes.textInput}
                        placeholder={translate.pls_enter_product_details}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs="12" md="4">
                      <AiInputField
                        label={translate.product_sku}
                        value={sku}
                        onChange={(e) => setFieldValue('sku', e.target.value)}
                        helperText={translate.sku_tips}
                        maxLength={50}
                        toolTipColor={'#fff'}
                      />
                    </Grid>
                    <Grid item xs="12" md="4">
                      <AiInputField
                        label={translate.price}
                        value={unitPrice}
                        onChange={(e) => setFieldValue('unitPrice', e.target.value)}
                        helperText={translate.set_as_free_if_blank}
                        type={'number'}
                        placeholder={'0.00'}
                        inputProps={{
                          min: 0,
                          step: 0.01,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span style={{ color: '#fff' }}>$</span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs="12" md="4">
                      <Typography style={{ color: '#fff', marginBottom: 5 }}>{translate.stock}</Typography>
                      <StockField
                        required={false}
                        disabled={isSubmitting}
                        value={{
                          quantity,
                          ignoreStock,
                        }}
                        onChange={(update) => {
                          setFieldValue('quantity', update.quantity);
                          setFieldValue('ignoreStock', update.ignoreStock);
                        }}
                        helperText={<HelperText>{translate.set_as_unlimited_stock_if_blank}</HelperText>}
                        className={classes.textInput}
                        mr={0}
                        iconColor={'#fff'}
                      />
                    </Grid>
                    <Grid item xs="12" style={{ textAlign: 'right' }}>
                      <AIButton disabled={isSubmitting} style={{ zIndex: 10 }}>
                        {translate.generate_product}
                      </AIButton>
                    </Grid>
                  </Grid>
                </Box>
              </form>
              {!!items?.length && !isCancel && (
                <Box py={5} px={isUnderMD ? 5 : 10} style={{ borderTop: '1px solid #D7D7D733' }}>
                  <AIResult data={items} input={input} />
                </Box>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AIForm;

const HelperText = ({ children }) => (
  <Typography variant={'body2'} style={{ color: '#fff', marginLeft: -14, marginRight: -14 }}>
    {children}
  </Typography>
);

const AiInputField = ({
  label,
  value,
  onChange,
  helperText,
  type,
  maxLength,
  inputProps,
  InputProps,
  placeholder,
  toolTipColor,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography style={{ color: '#fff', marginBottom: 5 }}>{label}</Typography>
      <InputField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        variant={'outlined'}
        className={classes.textInput}
        toolTipColor={toolTipColor}
        style={{ width: '100%' }}
        type={type}
        maxLength={maxLength}
        helperText={<HelperText>{helperText}</HelperText>}
        inputProps={{
          ...inputProps,
          style: {
            height: 10,
          },
        }}
        InputProps={InputProps}
      />
    </>
  );
};
