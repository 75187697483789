import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import HTMLField from '../../../components/FormPageMaker/Fields/HTMLField';
import ChatGptButtonGroup from './ChatGptButtonGroup';
import { checkPermissionV2 } from '../../../components/PermissionMask';

const ChatGptCard = (props) => {
  const { values, setFieldValue, disabled, loading, isSubmitting } = props;
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <HTMLField
        loading={loading}
        value={values?.description}
        disabled={isSubmitting || disabled}
        onChange={(v) => {
          setFieldValue('description', v);
        }}
      />
      {checkPermissionV2('chatGPT') && !loading && !disabled && (
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setHover(!hover)}
          style={{
            position: 'absolute',
            bottom: theme.spacing(3),
            right: theme.spacing(3),
            transition: 'opacity 0.25s',
            opacity: hover ? 1 : 0.25,
          }}
        >
          <ChatGptButtonGroup
            product={values}
            onChange={(v) => {
              setFieldValue('description', v);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ChatGptCard;
