import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query (
    $id: ID!
    $startedAt: AWSDateTime!
    $startedThru: AWSDateTime!
    $cursor: Int
    $limits: Int
    $query: String
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        report {
          totalPromotionCodeSales(startedAt: $startedAt, startedThru: $startedThru) {
            totalCount
            totalOrder
            totalAmount
            totalAvgPrice
            totalAvgCount
            totalRedeemCount
            totalUsageCount
            totalDiscountAmount
          }
          topPromotionCodeSales(
            startedAt: $startedAt
            startedThru: $startedThru
            cursor: $cursor
            limits: $limits
            sortBy: $sortBy
            query: $query
          ) {
            nextCursor
            totalCount
            nodes {
              id
              name
              count
              orderCount
              amount
              avgPrice
              avgCount
              redeemCount
              usageCount
              discountAmount
            }
          }
        }
      }
    }
  }
`;
