import React, { useState } from 'react';
import { MODIFIER_PRODUCT_REMOVE_QUERY } from '../query';
import { Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { client } from '../../../shared/apollo';
import errorParser from '../../../shared/errorParser';
import toast from '../../../shared/toast';
import { sleep } from '../../../shared';
import SystemButton from '../../../components/SystemButton';

const RemoveFromModifierButton = ({ currModifierId, list, disabled }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      ActivityIndicator.show(translate.loading);
      const result = await list.infinityFetchAllSelected();
      if (result.nodes.length > 0) {
        await client.mutate({
          mutation: MODIFIER_PRODUCT_REMOVE_QUERY,
          variables: {
            id: currModifierId,
            productIds: result.nodes.map(({ id }) => id),
          },
        });
      }
      await sleep(1000);
      list.refetch();
      list.resetSelectionData();
      toast.success(translate.product_removed_from_modifier_set);
    } catch (error) {
      toast.error(errorParser(error));
    } finally {
      setLoading(false);
      ActivityIndicator.hide();
    }
  };

  return (
    <SystemButton disabled={disabled} variant={'contained'} onClick={handleDelete} color={'error'}>
      <Typography variant="caption1" color={'inherit'}>
        {translate.product_modifiers_remove || '移除產品'}
      </Typography>
    </SystemButton>
  );
};

export default RemoveFromModifierButton;
