import React, { useState } from 'react';
import CreateButton from '../../../components/ConnectionPageMaker/CreateButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { Formik } from 'formik';
import SystemButton from '../../../components/SystemButton';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import { Icon } from '../../../components/IconRender';
import { ReactComponent as IconTips } from '../../../assets/icon/tips.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icon/arrowRight.svg';
import { binColor, redTextColor, tooltipPlacement } from '../../../theme';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { USER_COUPON_SUBMIT } from './query';
import confirmation from '../../../shared/confirmation';

const CreateUserCouponButton = ({ id, userCouponsTotal, onCompleted }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <>
      <CreateButton onClick={() => setOpen(true)}>{translate.add_sub_coupons || '新增子優惠券'}</CreateButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            overflow: 'hidden',
            padding: theme.spacing(1),
            minWidth: '500px',
          },
        }}
      >
        <Formik
          initialValues={{
            count: undefined,
          }}
          onSubmit={async ({ count }, { setSubmitting }) => {
            try {
              if (+userCouponsTotal + +count > 10000)
                throw new Error(translate.cannot_exceed_ten_thousand || '子優惠券總數量不能超過10,000');
              if (
                await confirmation(
                  null,
                  undefined,
                  count,
                  <DialogContent style={{ textAlign: 'center' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {translate.confirm_adding_sub_coupons || '確認增加子優惠券？'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2} style={{ textAlign: 'center' }}>
                          <Grid item xs>
                            <Grid container spacing={1} style={{ flexDirection: 'column' }}>
                              <Grid item>
                                <Typography>{translate.existing_quantity || '現有數量'}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{userCouponsTotal}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs alignContent="center">
                            <Icon fontSize="small" icon={ArrowRightIcon} type={'svg'} viewBox={'0 0 15 14'} />
                          </Grid>
                          <Grid item xs>
                            <Grid container spacing={1} style={{ flexDirection: 'column' }}>
                              <Grid item>
                                <Typography>{translate.new_quantity || '新數量'}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography style={{ color: redTextColor }}>{+userCouponsTotal + +count}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>,
                  true,
                  translate.back,
                )
              ) {
                setSubmitting(true);
                await client.mutate({
                  mutation: USER_COUPON_SUBMIT,
                  variables: {
                    id,
                    count,
                  },
                });
                toast.success(translate.create_success);
                setSubmitting(false);
                setOpen(false);
                onCompleted();
              }
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ textAlign: 'center' }}>
                <DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item>
                          <Typography variant="h6">{translate.add_sub_coupons || '新增子優惠券'}</Typography>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={
                              <Typography variant="body2">
                                {translate.enter_numbers_only || '請輸入要生成的子碼數量：1-500。僅限數字'}
                              </Typography>
                            }
                            placement={tooltipPlacement}
                          >
                            <span>
                              <Icon
                                type={'svg'}
                                icon={IconTips}
                                viewBox="0 0 17 17"
                                style={{
                                  color: 'transparent',
                                  verticalAlign: 'text-bottom',
                                  fontSize: '1.3rem',
                                }}
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <Grid container spacing={1} style={{ flexDirection: 'column' }}>
                            <Grid item>
                              <Typography>{translate.original_quantity || '原來數量'}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>{userCouponsTotal}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs>
                          <Grid container spacing={1} style={{ flexDirection: 'column' }}>
                            <Grid item>
                              <Typography>{translate.total_quantity || '總數量'}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>{+userCouponsTotal + (+values?.count || 0)}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs style={{ textAlign: 'right', alignContent: 'end' }}>
                          <Grid container style={{ flexDirection: 'column' }}>
                            <Grid item>
                              <InputField
                                type={'number'}
                                style={{ width: 90 }}
                                required={true}
                                // eslint-disable-next-line
                                inputProps={{
                                  max: 500,
                                  min: 1,
                                  step: 1,
                                }}
                                InputProps={{
                                  style: { borderRadius: '5px 0 0 5px' },
                                  startAdornment: (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        backgroundColor: binColor,
                                        height: 30,
                                        width: 60,
                                        marginLeft: -13,
                                        paddingLeft: 7,
                                        alignItems: 'center',
                                        borderRadius: '4px 0 0 4px',
                                        marginRight: 12,
                                      }}
                                    >
                                      <InputAdornment position="start">
                                        <Typography style={{ color: 'white', fontSize: 20 }}>+</Typography>
                                      </InputAdornment>
                                    </div>
                                  ),
                                }}
                                value={values?.count}
                                onChange={(e) => setFieldValue('count', e.target.value)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid container justifyContent={'flex-end'} alignItems={'center'} spacing={3}>
                  <Grid item>
                    <SystemButton variant={'outlined'} onClick={() => setOpen(false)}>
                      {translate.cancel}
                    </SystemButton>
                  </Grid>
                  <Grid item>
                    <SystemButton color={'omniewRed'} type={'submit'} loading={isSubmitting}>
                      {translate.confirm}
                    </SystemButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateUserCouponButton;
