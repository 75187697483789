import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';

const DISPLAY_SET = gql`
  mutation ($id: ID, $input: PageInput!) {
    pageSet(id: $id, input: $input) {
      id
      display
    }
  }
`;

export default ({ type, data }) => {
  const { id, display, name, href, active } = data || {};
  const [displayChecked, setState_displayChecked] = useState(display);
  const [loading, setLoading] = useState(false);
  const shopId = localStorage.getItem('shopId');
  return (
    <SwitchField
      checked={displayChecked}
      disabled={loading}
      onChange={async (e) => {
        try {
          setLoading(true);
          await client.mutate({
            mutation: DISPLAY_SET,
            variables: {
              id,
              input: {
                display: !displayChecked,
                name,
                shopId: shopId,
                href,
                active,
              },
            },
          });
          setState_displayChecked(!displayChecked);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
