import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';
import useQueryParams from '../../hooks/useQueryParams';
import toast from '../../shared/toast';
import { isStaging } from '../../shared';

export default (props) => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;

  const tabs = [
    {
      name: translate.all_orders,
      filter: {
        active: { operator: 'IN', value: ['true'] },
      },
    },
    {
      name: translate.payment_pending || '等待付款',
      filter: {
        active: { operator: 'IN', value: ['true'] },
        status: { operator: 'NIN', value: ['CANCELLED'] },
        paymentStatus: { operator: 'IN', value: ['PENDING', 'PROCESSING'] },
      },
    },
    {
      name: translate.awaiting_confirmation,
      filter: {
        active: { operator: 'IN', value: ['true'] },
        status: { operator: 'IN', value: ['PENDING'] },
        paymentStatus: { operator: 'IN', value: ['COMPLETED'] },
      },
    },
    {
      name: translate.awaiting_shipment || '等待發貨',
      filter: {
        active: { operator: 'IN', value: ['true'] },
        status: { operator: 'IN', value: ['PROCESSING'] },
        shippingStatus: { operator: 'IN', value: ['PENDING'] },
      },
    },
    {
      name: translate.shipped_and_in_transit || '等待收貨',
      filter: {
        active: { operator: 'IN', value: ['true'] },
        status: { operator: 'IN', value: ['PROCESSING'] },
        shippingStatus: { operator: 'IN', value: ['PROCESSING', 'PARTIAL'] },
      },
    },
    {
      name: translate.completed,
      filter: {
        active: { operator: 'IN', value: ['true'] },
        status: { operator: 'IN', value: ['COMPLETED'] },
      },
    },
    {
      name: translate.status_cancelled,
      filter: {
        active: { operator: 'IN', value: ['true'] },
        status: { operator: 'IN', value: ['CANCELLED'] },
      },
    },
  ];

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery({
            tab,
          });
        }}
        tabs={tabs}
      />
      <Box>
        <ListView {...props} key={tab} filter={tabs[tab]?.filter ?? {}} allowStatusFilter={tab === 0} />
      </Box>
      {false && (
        <div
          onClick={() => {
            toast.info('您有一個新預訂！', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }}
          style={{
            zIndex: 1,
            position: 'fixed',
            width: 50,
            height: 50,
            bottom: 0,
            right: 0,
          }}
        />
      )}
    </div>
  );
};
