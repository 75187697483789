import { Card, CardContent, Container } from '@material-ui/core';
import React from 'react';
import { JSONParseSafely } from '../../shared';
import OrderItem from './OrderItem';
import _ from 'lodash';

const PrintPackingList = () => {
  const nodes = JSONParseSafely(localStorage.getItem('cache_print_packing_list'), []);
  const { fontSize = '', sort = '' } = JSON.parse(
    '{"' + window.location.search.substring(1).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
  );

  const allItems = nodes.map((node, i) => {
    let transformedItems = [];
    node.items.forEach((item) => {
      if (item.orderItem.bundleProduct) {
        item.orderItem.bundleProduct.options.forEach((option) => {
          const createdAt = node.order.checkout.items.find(
            (orderI) => orderI?.bundleProduct?.bundleProduct?.id === item?.orderItem?.bundleProduct?.bundleProduct?.id,
          )?.createdAt;

          transformedItems.push({
            createdAt,
            combination: option.variation.combination,
            description: option.product.name,
            remark: option?.remark || item.orderItem.remark,
            sku: option.variation.sku,
            barcode: option.variation.barcode || option.variation.sku || option.variation.id,
            quantity: item.quantity,
          });
        });
      }

      if (item.orderItem.productVariation || item.orderItem.addOnProduct) {
        transformedItems.push({
          createdAt: node.order.checkout.items.find(
            (orderI) =>
              (orderI?.productVariation?.id || orderI?.addOnProduct?.id) ===
              (item?.orderItem?.productVariation?.id || item?.orderItem?.addOnProduct?.id),
          )?.createdAt,
          combination: item?.orderItem?.productVariation?.combination || null,
          description: item.orderItem.description,
          remark: item.orderItem.remark,
          sku: item?.orderItem?.productVariation?.sku || item?.orderItem?.addOnProduct?.sku,
          barcode:
            item?.orderItem?.addOnProduct?.sku ||
            item.orderItem.productVariation.barcode ||
            item.orderItem.productVariation.sku ||
            item.orderItem.productVariation.id,
          quantity: item.quantity,
        });
      }
    });

    const sortedNodes = {
      ...node,
      order: {
        ...node.order,
        checkout: _.sortBy(transformedItems, (item) => {
          if (sort === 'sku') {
            return item?.sku?.toUpperCase();
          } else {
            return item.createdAt;
          }
        }),
      },
    };

    return sortedNodes;
  });

  return (
    <>
      <style>{`html, body, #root { height: auto; }
      @media print {
        html,
        body {
          font-size: ${fontSize};
        }
        `}</style>
      <Container style={{ minWidth: 1024 }}>
        {allItems.map((item, i) => {
          return (
            <Card key={i} square>
              <CardContent>
                <OrderItem data={item} />
                {/* don't print page break after the last item */}
                {allItems.length !== i + 1 && <div style={{ pageBreakAfter: 'always' }} />}
              </CardContent>
            </Card>
          );
        })}
      </Container>
    </>
  );
};

export default PrintPackingList;
