import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { GET_QUERY, SUBMIT_QUERY } from './query';
import { translate } from '../../shared/translate';
import OrderItemsCard from './OrderItemsCard';
import { client } from '../../shared/apollo';
import { parseAddress } from '../../shared';
import StatusBadge from '../../components/StatusBadge';
import { Box, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import TextareaField from '../../components/FormPageMaker/Fields/TextareaField';
import OrderSelectField from './OrderSelectField';
import SelectComboShippingZone from '../../components/SelectCombo/SelectComboShippingZone';
import DeliveryNoteConfirmButton from './DeliveryNoteConfirmButton';
import DeliveryNoteCompleteButton from './DeliveryNoteCompleteButton';
import { DeliveryNoteStatus } from '../../shared/omniwe-types';
import { Icon } from '../../components/IconRender';
import SFPrintButton from './SFPrintButton';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
    },
    tabs: [
      {
        name: translate.delivery_notes || '送貨單',
        cards: [
          {
            name: translate.delivery_info,
            fields: [
              {
                label: translate.status,
                render: ({ values: { status } = {}, loading }) => (
                  <Box mt={1}>
                    {loading ? (
                      <Skeleton height={30} />
                    ) : (
                      <StatusBadge status={status} statusObject={DeliveryNoteStatus} />
                    )}
                  </Box>
                ),
                md: 6,
              },
              {
                label: translate.orders,
                render: OrderSelectField,
                required: true,
                md: 6,
              },
              {
                label: translate.delivery_method,
                render: ({ values: { id, shippingZone } = {}, setFieldValue, loading, isSubmitting }) =>
                  loading ? (
                    <Skeleton height={30} />
                  ) : (
                    <SelectComboShippingZone
                      required={true}
                      disabled={!!id || isSubmitting}
                      value={shippingZone}
                      onChange={(zone) => setFieldValue('shippingZone', zone)}
                    />
                  ),
                required: true,
                md: 6,
              },
              {
                label: translate.tracking_number,
                name: 'trackingNumber',
                type: 'text',
                maxLength: 255,
                disabled: ({ values }) => ['COMPLETED', 'CANCELLED'].includes(values?.status),
                InputProps: ({ values: { trackingNumber } = {} }) => ({
                  endAdornment: !!trackingNumber && (
                    <InputAdornment position={'end'} disableTypography>
                      <Tooltip title={translate.check_delivery_status} placement={'top'} arrow={true}>
                        <a
                          href={`https://t.17track.net/en#nums=${trackingNumber}`}
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          <IconButton>
                            <Icon icon={'faExternalLink'} />
                          </IconButton>
                        </a>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }),
                md: 6,
              },
              {
                label: translate.delivery_items,
                render: OrderItemsCard,
              },
            ],
          },
          {
            name: translate.shipping_address,
            fields: [
              {
                label: ({ values: { shippingZone } }) =>
                  /PICKUP/.test((shippingZone || {}).provider) ? translate.pick_up_point : translate.shipping_address,
                render: ({ values: { id, address }, setFieldValue, loading, isSubmitting }) => {
                  // console.log('address', address)
                  const { lines } = address || {};
                  return loading ? (
                    <Skeleton height={30} />
                  ) : (
                    <TextareaField
                      value={(lines || []).join('\n')}
                      disabled={!!id || isSubmitting}
                      onChange={(v) => {
                        setFieldValue('address', {
                          ...(address || {}),
                          lines: (v || '').split('\n'),
                        });
                      }}
                    />
                  );
                },
              },
              {
                display: ({ values: { shippingZone } }) => !/PICKUP/.test((shippingZone || {}).provider),
                label: translate.country,
                type: 'country',
                name: 'address.country',
                required: true,
                disabled: ({ values: { id } }) => !!id,
                md: 6,
              },
              {
                display: ({ values: { shippingZone } }) => !/PICKUP/.test((shippingZone || {}).provider),
                label: translate.contact_person,
                type: 'text',
                name: 'address.person',
                required: true,
                disabled: ({ values: { id } }) => !!id,
                md: 6,
              },
              {
                label: translate.customer_email,
                type: 'email',
                name: 'address.email',
                required: true,
                disabled: ({ values: { id } }) => !!id,
                md: 6,
              },
              {
                label: translate.contact_number,
                type: 'mobile',
                name: 'address.tel',
                required: true,
                disabled: ({ values: { id } }) => !!id,
                md: 6,
              },
            ],
          },
        ],
      },
    ],
    // enableReinitialize: true,
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input:
          values.status === 'PENDING'
            ? {
                orderId: values.order.id,
                shippingZoneId: values.shippingZone.id,
                trackingNumber: values.trackingNumber,
                shippingAddress: parseAddress(values.address),
                items: values.items
                  .map(
                    (item) =>
                      !!item.orderItem.id &&
                      item.quantity > 0 && {
                        orderItemId: item.orderItem.id,
                        quantity: item.quantity,
                      },
                  )
                  .filter((_) => _),
              }
            : {
                orderId: values.order.id,
                shippingZoneId: values.shippingZone.id,
                trackingNumber: values.trackingNumber,
              },
      },
    });
    return true;
  };

  getInitialData({ node }) {
    const { location } = this.props,
      { id } = this.state,
      { order: presetOrder } = (location || {}).state || {};
    const {
      order = presetOrder,
      items = [],
      status = 'PENDING',
      shippingProvider,
      address,
      trackingNumber = '',
    } = node || {};

    return {
      id,
      order,
      items,
      status,
      trackingNumber,
      shippingZone: shippingProvider,
      address: {
        ...(address || {}),
      },
    };
  }

  renderExtraButtons(actions) {
    const { values: { id, status, shippingZone } = {} } = actions || {};
    return (
      <>
        {!!id && shippingZone?.provider === 'SF_EXPRESS' && <SFPrintButton {...actions} />}
        {!!id && status === 'PENDING' && <DeliveryNoteConfirmButton {...actions} />}
        {!!id && status === 'PROCESSING' && <DeliveryNoteCompleteButton {...actions} />}
      </>
    );
  }

  allowSubmit = ({ values: { status } = {} }) => {
    const {
      gql: { submit },
    } = this.state;
    return !!submit && !!~['PENDING', 'PROCESSING'].indexOf(status);
  };
}
