import React from 'react';
import { translate } from '../../../../shared/translate';
import ProductsSelectionForm from '../ProductsSelectionForm';
import _ from 'lodash';
import ProductField from './ProductField';
import { GET_QUERY } from './query';
import uuid from 'react-uuid';
import { infinityFetch } from '../../../../shared';
import errorParser from '../../../../shared/errorParser';
import toast from '../../../../shared/toast';
import ActivityIndicator from '../../../../components/ActivityIndicator';

const ProductSelection = ({ onClose, onComplete }) => {
  return (
    <ProductsSelectionForm
      label={translate.search_by_name_sku_tags_subheadings || '按產品名稱／產品編號／標籤／副標題搜尋'}
      currentTab={0}
      tabContent={({ values, setValues }) => {
        return <ProductField values={values} onChange={(v) => setValues(v)} />;
      }}
      onClose={onClose}
      onComplete={async (values) => {
        try {
          const { mode, data } = values?.selectData || {};

          const res = await infinityFetch({
            query: GET_QUERY,
            variables: {
              id: localStorage.getItem('shopId'),
              filter:
                mode === 'EXCLUDE' && !data.length
                  ? {}
                  : {
                      id: {
                        operator: mode === 'INCLUDE' ? 'IN' : 'NIN',
                        value: ['dummy'].concat(data),
                      },
                    },
            },
            getConnection: (data) => data?.node?.products,
            onProgress: (downloaded, total) => {
              if (total > downloaded) ActivityIndicator.show(`${translate.loading} ${downloaded} / ${total}`);
              else ActivityIndicator.show(`${translate.loading}`);
            },
          });

          onComplete(
            res.nodes.map((node) => ({
              id: uuid(),
              price: 0,
              product: {
                id: node.id,
                name: node.name,
                sku: node.sku,
              },
            })),
          );
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          ActivityIndicator.hide();
        }
      }}
    />
  );
};

export default ProductSelection;
