import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, useTheme, CircularProgress } from '@material-ui/core';
import { translate } from 'shared/translate';
import { useApolloClient } from '@apollo/client';
import { parseConnection } from '../shared';
import OnScreenTriggerer from './OnScreenTriggerer';

export default ({
  autoCalculateIncrementalNextCursor,
  query,
  getConnection = (_) => _,
  renderItem = (_) => false,
  renderItems = (items, renderItem) => items.map(renderItem),
  widgetUtilColor = 'black',
  renderSkeleton = () => <Loading widgetUtilColor={widgetUtilColor} />,
  renderNoMore = () => null,
  renderLoading = () => <Loading widgetUtilColor={widgetUtilColor} />,
  renderEmpty = () => <NoData widgetUtilColor={widgetUtilColor} />,
  renderLoadMoreButton = ({ onClick }) => <LoadMoreButton widgetUtilColor={widgetUtilColor} onClick={onClick} />,
  variables = {},
  queryProps = {},
}) => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [connection, setConnection] = useState({ totalCount: 0, nextCursor: null, nodes: [] });
  const limits = variables?.limits ?? 25;

  async function fetch(cursor) {
    if (loading || queryProps?.skip) return;
    try {
      setLoading(true);
      const { data } = await client.query({
        query,
        variables: {
          ...variables,
          cursor,
          limits,
        },
        ...queryProps,
      });

      setConnection((prevConnection) => {
        const connection = parseConnection(getConnection(data));
        console.log('cursor', cursor, connection);

        if (!!cursor) {
          return {
            totalCount: connection?.totalCount || prevConnection?.totalCount,
            nextCursor: connection?.nextCursor,
            nodes: prevConnection.nodes.concat(connection?.nodes),
          };
        }
        return connection;
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }
  async function fetchMore() {
    const nextCursor =
      autoCalculateIncrementalNextCursor && !connection?.nextCursor
        ? connection.nodes.length < connection.totalCount
          ? connection.nodes.length
          : null
        : connection?.nextCursor;

    fetch(nextCursor);
  }

  useEffect(() => {
    fetch(null);
  }, [variables, query, queryProps?.skip]);

  const isLoadingInitially = !!loading && connection?.nodes?.length === 0;
  const isLoadingMore = !!loading && connection?.nodes?.length > 0;

  return (
    <>
      {isLoadingInitially ? (
        renderSkeleton()
      ) : (
        <>
          {connection.totalCount === 0 && renderEmpty()}
          {connection.totalCount > 0 && renderItems(connection.nodes, renderItem, connection.totalCount)}
          {connection.totalCount > 0 &&
            (!connection.nextCursor ? (
              renderNoMore()
            ) : (
              <>
                {isLoadingMore ? (
                  renderLoading()
                ) : (
                  <>
                    <OnScreenTriggerer onTrigger={fetchMore} />
                    {renderLoadMoreButton({
                      onClick: fetchMore,
                      disabled: loading,
                    })}
                  </>
                )}
              </>
            ))}
        </>
      )}
    </>
  );
};

export const NoData = ({ widgetUtilColor, ...props }) => (
  <Box py={2} width={'100%'} {...props}>
    <Typography
      variant={'subtitle1'}
      style={{
        display: 'block',
        fontStyle: 'italic',
        width: '100%',
        textAlign: 'center',
        color: widgetUtilColor,
      }}
    >
      {translate.no_data}
    </Typography>
  </Box>
);

export const LoadMoreButton = ({ widgetUtilColor, onClick = (_) => _ }) => {
  const theme = useTheme();
  return (
    <Button
      style={{ width: '100%', color: widgetUtilColor, paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}
      onClick={onClick}
    >
      <Typography color={'inherit'}>{translate.load_more}</Typography>
    </Button>
  );
};

export const Loading = ({ widgetUtilColor }) => {
  return (
    <Box py={2} width={'100%'} display={'flex'} justifyContent={'center'} color={widgetUtilColor}>
      <CircularProgress size={20} />
    </Box>
  );
};
