import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import ActiveSwitcher from './ActiveSwitcher';
import { checkPermissionV2 } from '../../../components/PermissionMask';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    createUrl: checkPermissionV2('createWarehouse') ? '/warehouses/new' : undefined,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id, name }) => ({ pathname: '/warehouses/' + id, state: { title: name } }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name',
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        type: 'number',
        width: 1,
        sortBy: 'sortIndex',
      },
      {
        title: translate.valid_status,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} data={data} onCompleted={this.refetch} />;
        },
        sortBy: 'active',
      },
    ],
    menus: [],
    selectionMode: false,
  };

  getData = ({ node } = {}) => {
    const { warehouses } = node || {};
    return warehouses;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
}
