import React, { useEffect, useState } from 'react';
import InputField from '../../../FormPageMaker/Fields/InputField';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { convertMetaObject } from '../../../../shared';
import moment from 'moment/moment';

const TimeField = ({ disabled, value, onChange, defaultValue, field = {} }) => {
  const [initialize, setInitialize] = useState(!!value);

  const { metadata, name, required } = field ?? {};
  const { remarks } = convertMetaObject(metadata);

  useEffect(() => {
    if (value || defaultValue) setInitialize(true);
  }, [value, defaultValue]);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <div
        style={{ position: 'relative' }}
        onClick={() => {
          if (!initialize) {
            setInitialize(true);
            onChange(value || defaultValue || moment().format('HH:mm'));
          }
        }}
      >
        <InputField
          id="time"
          type="time"
          value={value}
          onChange={(e) => onChange(e?.target?.value)}
          required={required}
          disabled={disabled}
        />
        {!initialize && (
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
            <InputField placeholder={'--:--'} />
          </div>
        )}
      </div>
    </CustomFieldWrapper>
  );
};

export default TimeField;
