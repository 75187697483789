import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import DateRangeField from '../../components/FormPageMaker/Fields/DateRangeField';
import { translate } from '../../shared/translate';
import SnapButton from './SnapButton';
import useQueryParams from '../../hooks/useQueryParams';

const FormHeader = () => {
  const [query, setQuery] = useQueryParams();
  const dayMode = query?.dayMode,
    startedAt = query?.startedAt,
    startedThru = query?.startedThru;

  useEffect(() => {
    if (!startedAt && !startedThru && !dayMode) {
      setQuery((query) => ({
        ...query,
        startedAt: moment().subtract(7, 'days').startOf('day').toISOString(),
        startedThru: moment().endOf('day').toISOString(),
        dayMode: '1',
      }));
    }
  }, [startedAt, startedThru, dayMode]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item>
          <SnapButton
            active={dayMode === '0'}
            onClick={() => {
              setQuery((query) => ({
                ...query,
                startedAt: moment().startOf('day').toISOString(),
                startedThru: moment().endOf('day').toISOString(),
                dayMode: '0',
              }));
            }}
          >
            {translate.today}
          </SnapButton>
        </Grid>
        <Grid item>
          <SnapButton
            active={dayMode === '1'}
            onClick={() => {
              setQuery((query) => ({
                ...query,
                startedAt: moment().subtract(7, 'days').startOf('day').toISOString(),
                startedThru: moment().endOf('day').toISOString(),
                dayMode: '1',
              }));
            }}
          >
            {translate.last_7_days}
          </SnapButton>
        </Grid>
        <Grid item>
          <SnapButton
            active={dayMode === '2'}
            onClick={() => {
              setQuery((query) => ({
                ...query,
                startedAt: moment().startOf('month').startOf('day').toISOString(),
                startedThru: moment().endOf('day').toISOString(),
                dayMode: '2',
              }));
            }}
          >
            {translate.this_month}
          </SnapButton>
        </Grid>
        <Grid item>
          <DateRangeField
            cusStyle={{
              width: '80px',
            }}
            fromValue={startedAt}
            toValue={startedThru}
            onFromValueChange={(startedAt) => {
              setQuery((query) => ({
                ...query,
                startedAt,
                dayMode: '3',
              }));
            }}
            onToValueChange={(startedThru) => {
              setQuery((query) => ({
                ...query,
                startedThru,
                dayMode: '3',
              }));
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FormHeader;
