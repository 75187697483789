import { gql } from '@apollo/client';

export const FRAGMENT_BUNDLE_PRODUCT = gql`
  fragment FRAGMENT_BUNDLE_PRODUCT on ShopBundleProduct {
    id
    name
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
    }
    active
    display
    createdAt
    updatedAt
    publishAt
    publishThru
    unitPrice
    sku
    collections {
      nodes {
        id
        name
      }
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        bundleProducts(query: $query, cursor: $cursor, limits: $limits, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_BUNDLE_PRODUCT
          }
        }
      }
    }
  }
  ${FRAGMENT_BUNDLE_PRODUCT}
`;
