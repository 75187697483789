import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { parseConnection } from '../../shared';
import _ from 'lodash';
import SelectCombo from './index';

const SEARCH_QUERY = gql`
  query ($id: ID!, $filter: PageFilterInput, $cursor: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        pages(limits: 10, filter: $filter, cursor: $cursor, sortBy: $sortBy) {
          nextCursor
          nodes {
            id
            name
            active
          }
        }
      }
    }
  }
`;

const SelectComboArticle = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { loading, data, fetchMore } = useQuery(SEARCH_QUERY, {
      variables: {
        id: localStorage.getItem('shopId'),
        filter: {
          name: search ? { operator: 'LIKE', value: [`%${search}%`] } : undefined,
          // group: { operator: 'IN', value: ['articles'] },
        },
        sortBy: [{ field: 'updatedAt', order: 'DESC' }],
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);

  function getConnection(data) {
    const { node } = data || {};
    return parseConnection((node || {}).pages);
  }

  return (
    <SelectCombo
      onOpen={setOpen}
      getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
      onSearchChange={setSearch}
      getOptionLabel={(option) => {
        return option?.name ?? '';
      }}
      options={nodes}
      loading={loading}
      onScrollBottom={async () => {
        if (!!nextCursor && !loading)
          await fetchMore({
            variables: {
              cursor: nextCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              const fetchMoreConnection = getConnection(fetchMoreResult),
                { nodes: fetchMoreNodes } = fetchMoreConnection,
                { nodes: prevNodes } = getConnection(prevResult);
              fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

              return {
                node: {
                  ...(fetchMoreResult.node || {}),
                  pages: fetchMoreConnection,
                },
              };
            },
          });
      }}
      {...props}
    />
  );
};

export default SelectComboArticle;
