import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography, Box, withStyles } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { Icon } from '../../../components/IconRender';
import { ReactComponent as chatgptRe } from '../../../assets/chatgpt-regen.svg';
import useAI from '../../../components/useAI';
import { getPrompts } from './ChatGptButtonGroup';

const styles = (theme) => ({
  RequirementStepBox: {
    padding: '10px 20px',
    height: '100%',
  },
  stepTitleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepTitle: {
    color: '#1F4C6D',
    fontSize: '18px',
    fontWeight: '500',
  },
  textarea: {
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(6),
  },
  generatorBtnBox: {
    marginLeft: '50px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  chatgptIcon: {
    position: 'absolute',
    top: -7,
    left: -33,
    zIndex: 1,
  },
  chatgptIconSvg: {
    width: 50,
    height: 50,
    borderRadius: 25,
    boxShadow: `0 0 5px 0px rgba(0,0,0,0.5)`,
  },
  chatgptIconSvgProcessing: {
    width: 50,
    height: 50,
    borderRadius: 25,
    boxShadow: `0 0 5px 0px rgba(0,0,0,0.5)`,
    animation: 'rotate 3s infinite ease-in-out',
  },
  generatorBtn: {
    background: 'rgb(91, 125, 150)',
    backgroundImage: 'linear-gradient(90deg, rgba(91, 125, 150, 1) 0%, rgba(0, 224, 202, 1) 80%)',
    border: 'none',
    color: '#fff',
    padding: '7px 12px',
    paddingLeft: '25px',
    boxShadow: `0 0 5px 0px rgba(0,0,0,0.5)`,
  },
});

const ChatpGptReadyStep = ({ disabled, classes, option = {}, value, onChange }) => {
  const handler = useRef('');
  const [processing, setProcessing] = useState();

  const { request } = useAI({
    onDelta: (delta, stop) => {
      handler.current = (handler.current || '') + delta;
      if (handler.current.length % 10 === 0 || stop) onChange(handler.current);

      if (stop) setProcessing(false);
    },
  });

  async function startAIRequest() {
    setProcessing(true);
    handler.current = '';
    await request([getPrompts(option, option?.product)]);
  }

  useEffect(() => {
    startAIRequest();
  }, []);

  return (
    <>
      <Box className={classes.RequirementStepBox}>
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: 10,
            overflow: 'hidden',
            borderColor: '#ddd',
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <div className={classes.textarea} dangerouslySetInnerHTML={{ __html: value }} />
          </div>
          <Box
            style={{
              position: 'absolute',
              bottom: 16,
              right: 16,
              zIndex: 1,
            }}
          >
            <div className={classes.generatorBtnBox}>
              <div className={classes.chatgptIcon}>
                <Icon
                  className={processing ? classes.chatgptIconSvgProcessing : classes.chatgptIconSvg}
                  type={'svg'}
                  icon={chatgptRe}
                  viewBox={'0 0 40 40'}
                />
              </div>
              <Button
                className={classes.generatorBtn}
                disabled={processing}
                variant={'outlined'}
                type={'button'}
                onClick={() => {
                  startAIRequest();
                }}
              >
                <Typography color={'inherit'}>{translate.regenerator || '重新生成'}</Typography>
              </Button>
            </div>
          </Box>
        </div>
      </Box>
      <style>
        {`
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
        `}
      </style>
    </>
  );
};

export default withStyles(styles)(ChatpGptReadyStep);
