import { object2QueryString, queryString2Object } from '../components/QueryParamsWrapper';
import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();
  const object = queryString2Object(location?.search);

  return [
    object,
    (obj) => {
      if (typeof obj === 'function') {
        history.replace({
          pathname: location.pathname,
          search: object2QueryString(obj(queryString2Object(window.location.search))),
        });
      } else {
        history.replace({
          pathname: location.pathname,
          search: object2QueryString(obj),
        });
      }
    },
  ];
};

export default useQueryParams;
