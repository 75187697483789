import React, { useState } from 'react';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import FormToolbar from '../../../../components/FormToolbar';
import { Formik } from 'formik';
import FormCard from '../../../../components/FormPageMaker/Cards/FormCard';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import { actionWrapper } from '../../../../shared';
import SelectComboMemberTier from '../../../../components/SelectCombo/SelectComboMemberTier';

const CUSTOMERS_MEMBER_TIER_SET = gql`
  mutation ($shopId: ID!, $input: UserMemberTierSetInput!, $filter: CustomerFilterInput, $query: String) {
    customersMemberTierSet(shopId: $shopId, input: $input, filter: $filter, query: $query) {
      id
    }
  }
`;

export const MenuMemberTierSet = ({ scope, mutation = CUSTOMERS_MEMBER_TIER_SET, ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <MenuItem disabled={scope.getSelectionCount() === 0} onClick={() => setVisible(true)}>
        {translate.update_member_tier}
      </MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Formik
          initialValues={{ memberTier: undefined, startedThru: undefined }}
          onSubmit={async (values) => {
            setVisible(false);
            await actionWrapper(
              async () => {
                await client.mutate({
                  mutation,
                  variables: {
                    shopId: localStorage.getItem('shopId'),
                    filter: scope.getSelectionFilter(),
                    query: scope.getQ(),
                    input: {
                      memberTierId: (values.memberTier || {}).id,
                      startedThru: values.startedThru,
                    },
                    action: values.points > 0 ? 'ADD' : 'DEDUCT',
                    points: values.points,
                  },
                });
              },
              { messages: { loading: translate.update, success: translate.update_success } },
            );
          }}
        >
          {(actions) => (
            <form onSubmit={actions.handleSubmit}>
              <DialogContent>
                <FormCard
                  actions={actions}
                  fields={[
                    {
                      label: translate.member_tier,
                      required: true,
                      render: ({ values, isSubmitting, setFieldValue }) => (
                        <SelectComboMemberTier
                          required
                          disabled={isSubmitting}
                          value={values.memberTier}
                          onChange={(v) => setFieldValue('memberTier', v)}
                        />
                      ),
                    },
                    {
                      label: translate.expiry_date,
                      type: 'date',
                      name: 'startedThru',
                    },
                  ]}
                />
              </DialogContent>
              <FormToolbar
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
                submitBtnType={'submit'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default {
  render: (args) => <MenuMemberTierSet {...args} />,
};
