import React, { useState } from 'react';
import { AssignmentReturn } from '@material-ui/icons';
import SystemButton from '../../../components/SystemButton';
import { translate } from '../../../shared/translate';
import { Dialog, DialogContent, DialogTitle, InputAdornment } from '@material-ui/core';
import FormToolbar from '../../../components/FormToolbar';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import FormRow from '../../../components/FormRow';
import { Formik } from 'formik';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { useApolloClient } from '@apollo/client';
import { ORDER_REFUND } from './query';

const OrderRefundButton = ({ order, disabled, onOrderRefunded = () => {} }) => {
  const client = useApolloClient();
  const [open, setOpen] = useState(false);

  const total = order?.total,
    totalRefund = order?.totalRefund,
    totalPaid = order?.totalPaid;

  const refundableAmount = +(total - totalRefund).toFixed(2);

  return (
    <>
      <SystemButton
        disabled={disabled}
        startIcon={<AssignmentReturn />}
        color={'omniewRed'}
        onClick={() => setOpen(true)}
      >
        {translate.cash_refund || '現金退款'}
      </SystemButton>
      <Dialog open={open}>
        <DialogTitle>{translate.cash_refund || '現金退款'}</DialogTitle>
        <Formik
          key={refundableAmount}
          onSubmit={async ({ amount }, { setSubmitting }) => {
            try {
              setSubmitting(true);
              if (amount > 0) {
                await client.mutate({
                  mutation: ORDER_REFUND,
                  variables: {
                    id: order?.id,
                    amount,
                  },
                });

                onOrderRefunded();
              }
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              setOpen(false);
              setSubmitting(false);
            }
          }}
          initialValues={{ amount: refundableAmount }}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormRow title={translate.paid_amount} required={true}>
                  <InputField
                    // eslint-disable-next-line
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    disabled={true}
                    value={totalPaid}
                  />
                </FormRow>
                <FormRow title={translate.refunded_amount} required={true}>
                  <InputField
                    // eslint-disable-next-line
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    disabled={true}
                    value={totalRefund}
                  />
                </FormRow>
                <FormRow title={translate.cash_refund || '現金退款'} required={true}>
                  <InputField
                    // eslint-disable-next-line
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    inputProps={{
                      step: 0.01,
                      min: 0,
                      max: refundableAmount,
                    }}
                    disabled={isSubmitting}
                    value={values.amount}
                    type={'number'}
                    onChange={(e) => setFieldValue('amount', e?.target.value)}
                  />
                </FormRow>
              </DialogContent>
              <FormToolbar
                loading={isSubmitting}
                disabled={isSubmitting}
                submitBtnType={'submit'}
                onCancel={() => setOpen(false)}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default OrderRefundButton;
