import React, { useState } from 'react';
import { Icon } from '../../components/IconRender';
import { Dialog, DialogContent, Grid, IconButton } from '@material-ui/core';
import FormCard from '../../components/FormPageMaker/Cards/FormCard';
import { Formik } from 'formik';
import { translate } from '../../shared/translate';
import FormToolbar from '../../components/FormToolbar';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { client } from '../../shared/apollo';
import { ORDER_UPDATE } from './query';
import { parseAddress } from '../../shared';

const AddressesUpdateButton = ({ order: { id, shippingAddress, billingAddress } = {} }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <IconButton onClick={() => setVisible(true)}>
        <Icon icon={'faPencil'} />
      </IconButton>
      <Dialog open={visible} fullWidth maxWidth={'lg'}>
        <Formik
          onSubmit={async (values) => {
            try {
              await client.mutate({
                mutation: ORDER_UPDATE,
                variables: {
                  id,
                  input: {
                    shippingAddress: parseAddress(values.shippingAddress),
                    billingAddress: parseAddress(values.billingAddress),
                  },
                },
              });
              setVisible(false);
              toast.success(translate.update_success);
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
            }
          }}
          initialValues={{ shippingAddress, billingAddress }}
        >
          {(actions) => (
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                actions.handleSubmit(e);
              }}
            >
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormCard
                      name={translate.shipping_address}
                      style={{ border: '1px solid #ccc', borderRadius: 8 }}
                      actions={actions}
                      fields={[
                        {
                          label: translate.customer_fullname,
                          type: 'text',
                          name: 'shippingAddress.person',
                        },
                        {
                          label: translate._email,
                          type: 'email',
                          name: 'shippingAddress.email',
                        },
                        {
                          label: translate.phone,
                          type: 'mobile',
                          name: 'shippingAddress.tel',
                        },
                        {
                          label: translate.address,
                          type: 'lines',
                          name: 'shippingAddress.lines',
                        },
                        {
                          label: translate.country,
                          type: 'country',
                          name: 'shippingAddress.country',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormCard
                      name={translate.billing_address}
                      style={{ border: '1px solid #ccc', borderRadius: 8 }}
                      actions={actions}
                      fields={[
                        {
                          label: translate.customer_fullname,
                          type: 'text',
                          name: 'billingAddress.person',
                        },
                        {
                          label: translate._email,
                          type: 'email',
                          name: 'billingAddress.email',
                        },
                        {
                          label: translate.phone,
                          type: 'mobile',
                          name: 'billingAddress.tel',
                        },
                        {
                          label: translate.address,
                          type: 'lines',
                          name: 'billingAddress.lines',
                        },
                        {
                          label: translate.country,
                          type: 'country',
                          name: 'billingAddress.country',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <FormToolbar
                submitBtnType={'submit'}
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddressesUpdateButton;
