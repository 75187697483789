import { Grid, ListItem, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';
import TrashButton from '../../../../components/TrashButton';

const TableItem = ({ value, onChange, index }) => {
  const theme = useTheme();
  const { name, metadata } = value,
    { active } = metadata || {};
  const [isDeleteState, setState_isDeleteState] = useState(0);

  return (
    <>
      {isDeleteState === 0 ? (
        <ListItem
          style={{
            display: 'flex',
            padding: theme.spacing(1),
            backgroundColor: !!((index + 1) % 2) ? '#f0f0f0' : '#f5f4f4',
          }}
        >
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={true}>
              <InputField
                fullWidth
                placeholder={'Name'}
                value={name}
                onChange={(e) => onChange({ ...value, name: e.target.value })}
                variant="outlined"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item>
              <SwitchField
                checked={!!+active}
                onChange={(e) => {
                  onChange({ ...value, metadata: { ...metadata, active: e.target.checked ? 1 : 0 } });
                }}
              />
            </Grid>
            <Grid item>
              <TrashButton
                onClick={(e) => {
                  metadata.isDelete = 1;
                  setState_isDeleteState(1);
                  onChange({ ...value, metadata: { ...metadata, active: e.target.checked ? 1 : 0 } });
                }}
              />
            </Grid>
          </Grid>
        </ListItem>
      ) : null}
    </>
  );
};

export default TableItem;
