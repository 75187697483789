import React from 'react';
import { Pagination } from '@material-ui/lab';

export default ({ offset: { skip = 0, limit = 20 } = {}, count = 0, setOffset, disabled }) => {
  const current = skip / limit + 1;
  const total = Math.ceil(count / limit);
  if (total <= 1) return <div />;
  return (
    <Pagination
      count={total}
      page={current}
      color={'secondary'}
      size={'small'}
      siblingCount={3}
      disabled={disabled}
      onChange={(__, i) => {
        setOffset({ skip: (i - 1) * limit, limit });
      }}
    />
  );
};
