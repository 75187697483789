import React from 'react';
import './style.css';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default ({ size = 1, loading, color = 'white', ...props }) => {
  const { src, circularColor } = {
    white: {
      src: require(`../../assets/OmniweWLogoF.png`),
      circularColor: 'white',
    },
    grey: {
      src: require(`../../assets/OmniweWLogoFGrey.png`),
      circularColor: '#757575',
    },
  }[color];

  return (
    <Box
      position={'relative'}
      style={{
        transform: `scale(${size})`,
      }}
      {...props}
    >
      <img src={loading ? src : require('../../assets/HomePage-Menu-Logo@2x.png')} alt={'omniwe'} />
      {loading && (
        <Box
          position={'absolute'}
          top={'50%'}
          left={'36%'}
          className={'omniwe-logo-circle'}
          style={{
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={72} thickness={1.5} style={{ color: circularColor }} />
        </Box>
      )}
    </Box>
  );
};
