import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!, $query: String, $cursor: Int, $limits: Int, $filter: OrderFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Customer {
        orders(query: $query, cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            id
            referenceNo
            total
            totalRefund
            status
            paymentStatus
            shippingStatus
            createdAt
          }
        }
      }
    }
  }
`;
