import React from 'react';
import { Typography, makeStyles, Grid, Card, CardContent, Tooltip } from '@material-ui/core';
import useBreakPoint from './useBreakPoint';
import { Icon } from './IconRender';
import { binColor, tooltipPlacement } from '../theme';
import { ReactComponent as IconTips } from '../assets/icon/tips.svg';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginBottom: theme.spacing(2),
  },
  formDenseGroup: {},
  label: {
    fontWeight: 'bold',
  },
  invisibleLabel: {
    opacity: 0,
  },
}));

export const FormRow = ({
  required,
  title,
  tooltip = '',
  remarks = '',
  className = '',
  xs = 12,
  md = 12,
  spacing,
  block,
  children,
  dense,
  titleStyle,
  direction = 'column',
  formRowTheme,
  alignItems,
  variant = 'h6',
  style,
  ...props
}) => {
  const classes = useStyles();

  const core = (
    <Grid container direction={direction} spacing={spacing} alignItems={alignItems}>
      {!!title && (
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography
                    variant={variant}
                    style={titleStyle}
                    className={`${classes.label} ${/^_$/.test(title) ? classes.invisibleLabel : ''}`}
                  >
                    {title} {!!required && <Asterisk />}{' '}
                    {!!tooltip && (
                      <Tooltip title={tooltip} placement={tooltipPlacement}>
                        <span>
                          <Icon
                            type={'svg'}
                            icon={IconTips}
                            viewBox="0 0 17 17"
                            style={{
                              color: 'transparent',
                              verticalAlign: 'text-bottom',
                              fontSize: '1.3rem',
                            }}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {!!remarks && (
              <Grid item>
                <small className="text-muted">{remarks}</small>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={true}>
        {children}
      </Grid>
    </Grid>
  );

  const content = (
    <Grid
      id={`${title}`}
      item
      xs={xs}
      md={md}
      className={`${dense ? classes.formDenseGroup : classes.formGroup} ${className}`}
      style={block ? {} : style}
      {...props}
    >
      {{
        card: <CardWrapper>{core}</CardWrapper>,
      }[formRowTheme] || core}
    </Grid>
  );

  if (block)
    return (
      <Grid item xs={12} style={style}>
        <Grid container>{content}</Grid>
      </Grid>
    );
  return content;
};
export default FormRow;

const CardWrapper = ({ children }) => {
  const breakpoint = useBreakPoint();
  const isMobileView = !!~['xs'].indexOf(breakpoint);
  return (
    <Card
      style={{
        marginBottom: -12,
        padding: isMobileView ? '10px' : '0 60px',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 6px 16px 0 lightGrey',
        position: 'relative',
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export const Asterisk = () => <span style={{ color: binColor }}>{'*'}</span>;
