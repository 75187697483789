import { gql } from '@apollo/client';
import { FRAGMENT_MEMBER_METADATA_FIELD } from '../item_CustomerFieldsSetting/query';

export const FRAGMENT_CUSTOMER = gql`
  fragment FRAGMENT_CUSTOMER on Customer {
    createdAt
    updatedAt
    email
    phoneNumber
    isEmailVerified
    isPhoneNumberVerified
    picture
    lastLogin
    hashtags
    memberTier {
      name
      level
      startedAt
      startedThru
    }
    addresses {
      nodes {
        id
        person
        tel
        email
        lines
        country
        isDefault
      }
    }
    report {
      turnover
      volume
    }
    metadata {
      key
      value
    }
    blocked
    previousMemberTier {
      id
      name
      startedAt
      startedThru
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_CUSTOMER}
  query ($id: ID!, $shopId: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_CUSTOMER
      ... on Customer {
        giftPoints
      }
    }
    shop: node(id: $shopId) {
      id
      ... on CompanyShop {
        memberMetadataFields {
          ...FRAGMENT_MEMBER_METADATA_FIELD
        }
      }
    }
  }
  ${FRAGMENT_MEMBER_METADATA_FIELD}
`;

export const PREPARE_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        hostname
        customDomain
        memberMetadataFields {
          ...FRAGMENT_MEMBER_METADATA_FIELD
        }
      }
    }
  }
  ${FRAGMENT_MEMBER_METADATA_FIELD}
`;

export const CREATE_QUERY = gql`
  ${FRAGMENT_CUSTOMER}
  mutation ($credential: CustomerCredentialInput!, $input: CustomerProfileInput!) {
    customerRegister(credential: $credential, input: $input) {
      id
      ...FRAGMENT_CUSTOMER
    }
  }
`;

export const UPDATE_QUERY = gql`
  ${FRAGMENT_CUSTOMER}
  mutation ($id: ID!, $shopId: ID!, $credential: UserCredentialUpdateInput, $input: CustomerProfileInput!) {
    customerUpdate(id: $id, shopId: $shopId, credential: $credential, input: $input) {
      id
      ...FRAGMENT_CUSTOMER
      ... on Customer {
        giftPoints
      }
    }
  }
`;
