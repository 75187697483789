import React from 'react';
import { translate } from '../../../shared/translate';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/uprise.png')} alt="uprise payment" />,
    label: 'Uprise Payment',
    value: 'UPRISE_PAYMENT',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: translate.stripe_app_id,
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
            placeholder: 'pk_xxxxxxxxxxx',
          },
          {
            label: translate.stripe_secret,
            type: 'text',
            name: 'secret',
            required: true,
            maxLength: 255,
            inputProps: {
              type: 'password',
            },
            placeholder: 'sk_xxxxxxxxxxx',
          },
        ]}
      />
    );
  },
  type: 'PAYMENT',
};
