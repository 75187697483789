import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import VariationCombinationDisplay from '../../components/VariationCombinationDisplay';

const BundleProductItemRow = ({ item }) => {
  const { bundleProduct } = item || {};
  const { bundleProduct: _bundleProduct, options } = bundleProduct || {},
    { name, sku: bundleProductSku } = _bundleProduct || {};

  return (
    <>
      <Typography style={{ fontWeight: 'bold' }}>{`${name}`}</Typography>
      <Typography style={{ fontSize: '1rem' }}>{`SKU： ${bundleProductSku}`}</Typography>
      <Grid container direction="column" style={{ paddingLeft: 8 }}>
        {options?.map(({ variation, product, label, remark }, i) => {
          const { name: productName, printDescription } = product || {};
          const { combination, sku } = variation || {};

          return (
            <Grid item>
              <Grid container direction={'column'}>
                <Grid item>
                  <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>{`${label}:${productName}`}</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: '1rem' }}>{`SKU： ${sku}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <VariationCombinationDisplay
                    combination={combination}
                    remark={remark}
                    direction={'column'}
                    parseColorCode={false}
                  />
                </Grid>
                {!!printDescription && (
                  <Grid item>
                    <Typography style={{ whiteSpace: 'pre-line', fontSize: '1rem' }}>{printDescription}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default BundleProductItemRow;
