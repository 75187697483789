import React from 'react';
import { translate } from '../../shared/translate';
import Taber from '../../components/Taber';
import ListArticle from './ListArticle';

export default (props) => {
  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.all_articles || '所有文章',
          },
        ]}
      />
      <ListArticle {...props} />
    </div>
  );
};
