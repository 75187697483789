import React from 'react';
import Router from './routes';
import ActivityIndicator from './components/ActivityIndicator';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import Toaster from 'components/Toaster';
import { client } from 'shared/apollo';
import { ApolloProvider } from '@apollo/client';
import ConfirmModal from './components/ConfirmModal';
import ErrorBoundary from './components/ErrorBoundary';
import UserAgentChecker from './components/UserAgentChecker';
import { theme } from './theme';
import * as Sentry from '@sentry/browser';
import ReleaseNoteModal from './components/ReleaseNoteModal';

const { REACT_APP_SENTRY_DSN = '', REACT_APP_VERSION = 'dev' } = process.env || {};

Sentry.init({
  release: REACT_APP_VERSION,
  dsn: REACT_APP_SENTRY_DSN,
  beforeSend: (event) => {
    try {
      if (window.location.hostname === 'localhost') return null;
    } catch (e) {}
    return event;
  },
});

export default () => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <Router />
        <Toaster ref={(ref) => (global.toaster = ref)} />
        <ActivityIndicator />
        <ConfirmModal ref={(ref) => (global.confirmModal = ref)} />
        <UserAgentChecker />
        <ReleaseNoteModal />
      </ApolloProvider>
    </ThemeProvider>
  </ErrorBoundary>
);
