import React from 'react';
import { Box, Card, Grid, withStyles } from '@material-ui/core';
import ChannelList from './ChannelList';
import { theme } from '../../../theme';
import ChannelCustomerDetail from '../../../components/ChannelMessage/ChannelCustomerDetail';
import ChannelMessage from '../../../components/ChannelMessage';

const StyledCard = withStyles({
  root: {
    height: '100%',
    overflowY: 'auto',
    borderRadius: theme.spacing(2),
  },
})(Card);

const TabletView = ({ match: { params = {} } = {}, history }) => {
  const channelId = params.id;

  return (
    <Grid
      style={{
        height: '100%',
      }}
      container
      spacing={2}
    >
      <Grid item md={3} style={{ height: '100%' }}>
        <StyledCard>
          <ChannelList
            channel={{ id: channelId }}
            onChannelClick={async (channel) => {
              const { id } = channel || {};
              history.push(`/messages/${id}`);
            }}
          />
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={true} style={{ height: '100%' }}>
        {!!channelId ? (
          <StyledCard>
            <ChannelMessage key={channelId} id={channelId} />
          </StyledCard>
        ) : (
          <Box display={'flex'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
            <img width={'75%'} src={require('../../../assets/message.png')} alt={'message-padding'} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={3} style={{ height: '100%' }}>
        {!!channelId && (
          <StyledCard>
            <ChannelCustomerDetail channelId={channelId} />
          </StyledCard>
        )}
      </Grid>
    </Grid>
  );
};

export default TabletView;
