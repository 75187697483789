import { gql } from '@apollo/client';

export const FRAGMENT_DISCOUNT = gql`
  fragment CompanyDiscount on CompanyDiscount {
    name
    description
    remark
    sortIndex
    discardSubsequent
    metadata {
      key
      value
    }
    triggers {
      triggerType
      triggerId
      triggerIds
      triggerValueType
      triggerValue
    }
    actions {
      actionType
      actionId
      actionIds
      actionValueType
      actionValue
    }
    publishAt
    publishThru
    membersOnly
    active
    repeat
    excludedDiscounts(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) @client
    excludedCoupons(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) @client
    excludedProductIds
  }
`;
export const GET_QUERY = gql`
  ${FRAGMENT_DISCOUNT}
  query ($id: ID!) {
    node(id: $id) {
      id
      updatedAt
      createdAt
      ...CompanyDiscount
    }
  }
`;

export const DISCOUNT_SUBMIT = gql`
  ${FRAGMENT_DISCOUNT}
  mutation ($id: ID, $input: DiscountInput!) {
    companyDiscountSet(id: $id, input: $input) {
      id
      ...CompanyDiscount
    }
  }
`;

export const DISCOUNT_REMOVE = gql`
  mutation ($id: ID!) {
    companyDiscountUnset(id: $id) {
      id
    }
  }
`;
