import React, { useState, Fragment } from 'react';
import FilterDatetime from './FilterDatetime';
import FilterText from './FilterText';
import FilterBool from './FilterBool';
import FilterProductCollection from './FilterProductCollection';
import { translate as t } from 'shared/translate';
import FilterOption from 'components/ConnectionPageMaker/Filter/FilterOption';
import FilterNumeric from 'components/ConnectionPageMaker/Filter/FilterNumeric';
import FilterRange from 'components/ConnectionPageMaker/Filter/FilterRange';
import { Menu, Button, Box, Grid, Typography, useTheme, Hidden, IconButton } from '@material-ui/core';
import { Icon } from '../../IconRender';
import FilterShop from './FilterShop';
import FilterWarehouse from './FilterWarehouse';
import FilterHashTagsArray from './FilterHashTagsArray';
import FilterCountry from './FilterCountry';
import FilterOrder from './FilterOrder';
import FilterShippingMethod from './FilterShippingMethod';
import FilterPaymentMethod from './FilterPaymentMethod';
import FilterOptions from './FilterOptions';
import FilterReverseBool from './FilterReverseBool';
import FilterProducts from './FilterProducts';
import FilterServiceBundle from './FilterServiceBundle';
import FilterServiceLocation from './FilterServiceLocation';
import FilterStaff from './FilterStaff';

export default ({ fields = [], filter: _filter = {}, onFilterChange = (_) => _ }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [filter, setFilter] = useState(_filter);
  const hasFilter = fields.reduce((reducer, field) => {
    const { filter: filterKey, hasFilter } = field;
    if (reducer) return reducer;

    if (hasFilter) {
      if (typeof hasFilter === 'function') return hasFilter({ ...field, filter, setFilter });
      return !!hasFilter;
    }
    return filter[filterKey];
  }, false);

  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onDoneClick = () => {
    onFilterChange(filter);
    handleClose();
  };
  const onResetClick = () => {
    setFilter({});
    onFilterChange({});
    handleClose();
  };

  return (
    <Fragment>
      <div
        style={{
          paddingRight: '16px',
        }}
      >
        <Hidden lgUp>
          <IconButton onClick={(e) => handleClick(e)} color={hasFilter ? 'secondary' : undefined}>
            <Icon icon={'faFilter'} />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Button
            variant={'outlined'}
            onClick={handleClick}
            startIcon={
              <Icon
                icon={'faEmpty'}
                style={{
                  fontSize: 14,
                }}
              />
            }
            endIcon={
              <Icon
                icon={'faFilter'}
                style={{
                  fontSize: 14,
                  color: hasFilter ? theme.palette.secondary.main : '#c5c5c5',
                }}
              />
            }
          >
            <Typography color={'textSecondary'} style={{ textTransform: 'none' }}>
              {t.filter_condition}
            </Typography>
          </Button>
        </Hidden>
        <Menu anchorEl={anchorEl} variant={'menu'} open={Boolean(anchorEl)} onClose={handleClose}>
          <Box p={3}>
            {fields.map((field, i) => {
              const { type, filterType, title, filter: filterKey, options = [], filterInputProps } = field;

              const onFilterChange = (update) => {
                filter[filterKey] = update;
                setFilter({
                  ...filter,
                });
              };

              if (typeof filterKey === 'function') {
                return <Fragment key={i}>{filterKey({ ...field, filter, setFilter })}</Fragment>;
              }
              const filterProps = {
                title,
                filter: {
                  ...filter[filterKey],
                  value: filter[filterKey]?.value?.map((f) => {
                    if (f === null) return 'admin∆admin';
                    return f;
                  }),
                },
                onFilterChange,
                filterInputProps:
                  !!filterInputProps && typeof filterInputProps === 'function'
                    ? filterInputProps(filter[filterKey])
                    : filterInputProps,
              };

              return (
                <Fragment key={i}>
                  {{
                    order: <FilterOrder {...filterProps} />,
                    products: <FilterProducts {...filterProps} />,
                    collection: <FilterProductCollection {...filterProps} />,
                    serviceBundle: <FilterServiceBundle {...filterProps} />,
                    serviceLocation: <FilterServiceLocation {...filterProps} />,
                    warehouse: <FilterWarehouse {...filterProps} />,
                    staff: <FilterStaff {...filterProps} />,
                    shop: <FilterShop {...filterProps} />,
                    country: <FilterCountry {...filterProps} />,
                    number: <FilterNumeric {...filterProps} />,
                    price: <FilterNumeric {...filterProps} />,
                    datetime: <FilterDatetime {...filterProps} />,
                    text: <FilterText {...filterProps} />,
                    link: <FilterText {...filterProps} />,
                    reverseBool: <FilterReverseBool {...filterProps} />,
                    bool: <FilterBool {...filterProps} />,
                    hashTagsArray: <FilterHashTagsArray {...filterProps} />,
                    option: <FilterOption options={options} {...filterProps} />,
                    options: <FilterOptions options={options} {...filterProps} />,
                    range: <FilterRange {...filterProps} />,
                    shippingProvider: <FilterShippingMethod {...filterProps} />,
                    paymentProvider: <FilterPaymentMethod {...filterProps} />,
                  }[filterType || type] || undefined}
                </Fragment>
              );
            })}
          </Box>
          <Box p={3}>
            <Grid container spacing={2} justify={'flex-end'}>
              <Grid item>
                <Button type={'button'} disableElevation size={'small'} onClick={onResetClick}>
                  {t.clear}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type={'button'}
                  variant={'contained'}
                  disableElevation
                  color={'secondary'}
                  size={'small'}
                  onClick={onDoneClick}
                >
                  {t.done}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Menu>
      </div>
    </Fragment>
  );
};
