import React, { useState } from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import HighlightFormRow from '../../HighlightFormRow';
import HTMLField from '../../../../../components/FormPageMaker/Fields/HTMLField';

export default {
  label: translate.accordion,
  defaultHeight: 0,
  icon: <Icon icon={'faArrowsV'} />,
  preview: function PreviewFC({ data, setEditData, height }) {
    const { title, content } = data;
    const [expand, setExpand] = useState(false);

    return (
      <Box
        height={height === 0 ? undefined : height}
        display={'flex'}
        flexDirection={'column'}
        style={{ overflow: 'auto', cursor: 'pointer' }}
        onClick={() => setEditData(data)}
        onMouseOver={() => setExpand(true)}
        onMouseOut={() => setExpand(false)}
      >
        <Accordion variant={'outlined'} expanded={expand}>
          <AccordionSummary>
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={`htmlWrapper`} dangerouslySetInnerHTML={{ __html: content }} />
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  },
  wideEditor: true,
  edit: ({ value, onChange }) => {
    const { title, content } = value || {};

    const update = (k, v) => {
      onChange({
        ...value,
        [k]: v,
      });
    };

    return (
      <>
        <HighlightFormRow highlight={value.title !== undefined} title={translate.title}>
          <InputField value={title} onChange={(e) => update('title', e.target.value)} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.content !== undefined} title={translate.content}>
          <HTMLField value={content} onChange={(c) => update('content', c)} />
        </HighlightFormRow>
      </>
    );
  },
};
