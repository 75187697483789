import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyCoupon {
        orders(cursor: $cursor, limits: $limits, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            id
            referenceNo
            total
            status
            paymentStatus
            shippingStatus
            createdAt
          }
        }
      }
    }
  }
`;
