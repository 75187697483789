import React from 'react';
import { translate } from 'shared/translate';
import { Grid, ButtonBase, IconButton, Typography, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Icon } from 'components/IconRender';
import { useHistory } from 'react-router-dom';
import LSkeleton from '../../../components/LSkeleton';
import useBreakPoint from '../../../components/useBreakPoint';

const FHeader = ({
  onToggleClick,
  toggle,
  logo,
  title = translate['shop_deselected'],
  description,
  loading,
  withAnimation = true,
  ani = 0,
  ...props
}) => {
  const history = useHistory();
  const theme = useTheme();
  const active = history?.location?.pathname === '/shops';

  const breakPoint = useBreakPoint(),
    isXS = breakPoint === 'xs',
    isSM = breakPoint === 'sm';

  if (!toggle)
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#2a2b31',
          zIndex: 1201,
          width: '100%',
          height: 50,
          padding: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        {...props}
      >
        <IconButton style={{ color: 'white' }} onClick={onToggleClick}>
          <Icon icon={'faBars'} />
        </IconButton>
      </div>
    );

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1201,
        top: isXS || isSM ? '-45px' : '0px',
        left: 0,
        width: '100%',
        height: 200 - ani * 60,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#2a2b31',
      }}
      {...props}
    >
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <img className="h-8" src={require('../../../assets/icon/Backend-LeftBar-Logo.svg')} alt="omniwe-logo" />
        </Grid>
        <Grid item>
          <IconButton style={{ color: 'white' }} onClick={onToggleClick}>
            <Icon icon={'faBars'} />
          </IconButton>
        </Grid>
      </Grid>
      <div style={{ position: 'relative', flex: 1, width: '100%' }}>
        <ButtonBase
          href={'/shops'}
          onClick={(e) => {
            if (!e.metaKey) {
              e.preventDefault();
              history.push('/shops?active=IN%5E*true');
            }
          }}
          style={{
            borderRadius: '50%',
            overflow: 'hidden',
            marginBottom: '1rem',
            border: active ? `3px solid ${theme.palette.secondary.main}` : '3px solid rgba(255,255,255,0.25)',
            position: 'absolute',
            top: 0,
            left: `${50 - 50 * ani}%`,
            width: 100,
            height: 100,
            zIndex: 1,
            transform: `translateX(-${50 - 50 * ani}%) scale(${1 - 0.25 * ani})`,
            transitionDuration: withAnimation ? '300ms' : '0ms',
          }}
        >
          {loading ? (
            <Skeleton
              variant={'circle'}
              width={100}
              height={100}
              style={{
                backgroundColor: '#565966',
              }}
            />
          ) : (
            <>
              <div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                <img
                  src={logo || require('../../../assets/src_omniwe-logo.svg')}
                  style={
                    logo
                      ? {
                          width: 'auto',
                          maxWidth: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          transform: 'scale(0.9)',
                        }
                      : {
                          width: 'auto',
                          maxWidth: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          filter: 'grayscale(1)',
                          transform: 'scale(0.8)',
                          opacity: '0.2',
                        }
                  }
                  alt=""
                />
              </div>
            </>
          )}
        </ButtonBase>

        <Typography
          variant={'h5'}
          component={'span'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: '#FFFFFF',
            position: 'absolute',
            left: `${50 - ani * 10}%`,
            top: `${70 - 40 * ani}%`,
            transform: `translateX(-${50 - 50 * ani}%)`,
            maxWidth: `${100 - ani * 40}%`,
            zIndex: 2,
            textShadow: `0 0 4px #2a2b31`,
            transitionDuration: withAnimation ? '300ms' : '0ms',
          }}
        >
          {loading ? <LSkeleton style={{ backgroundColor: '#565966', width: 100 }} /> : title}
        </Typography>

        <Typography
          variant={'body2'}
          component={'span'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: '#FFFFFF',
            position: 'absolute',
            left: `${50 - ani * 10}%`,
            top: `${82 - 32 * ani}%`,
            transform: `translateX(-${50 - 50 * ani}%)`,
            maxWidth: `${100 - ani * 40}%`,
            zIndex: 2,
            textShadow: `0 0 4px #2a2b31`,
            transitionDuration: withAnimation ? '300ms' : '0ms',
          }}
        >
          {loading ? <LSkeleton style={{ backgroundColor: '#565966', width: 100 }} /> : description}
        </Typography>
      </div>
    </div>
  );
};

export default FHeader;
