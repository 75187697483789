import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import FormRow from '../../FormRow';
import Activation from '../../Activation';

const useStyles = makeStyles((theme) => ({
  iconButtonInactive: {
    opacity: 0.25,
  },
  iconButtonActive: {
    opacity: 1,
  },
}));

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const classes = useStyles();
  const { value } = filter || {},
    [input] = value || [];
  const active = input === 'false',
    inactive = input === 'true';

  return (
    <FormRow title={title}>
      <Box display={'flex'}>
        <IconButton
          className={active ? classes.iconButtonActive : classes.iconButtonInactive}
          color={active ? 'primary' : 'default'}
          onClick={() => {
            onFilterChange(active ? {} : { operator: 'IN', value: ['false'] });
          }}
          edge={'start'}
        >
          <Activation active={true} />
        </IconButton>
        <IconButton
          className={inactive ? classes.iconButtonActive : classes.iconButtonInactive}
          color={inactive ? 'primary' : 'inherit'}
          onClick={() => {
            onFilterChange(inactive ? {} : { operator: 'IN', value: ['true'] });
          }}
        >
          <Activation active={false} />
        </IconButton>
      </Box>
    </FormRow>
  );
};
