import React from 'react';
import { translate } from '../../../shared/translate';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/sf.png')} alt="sf" />,
    label: 'SF Express',
    value: 'SF_EXPRESS',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: translate.sf_identity || '順豐顧客編碼',
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
            placeholder: 'XXXXXXXXXXXX',
          },
          {
            label: translate.sf_secret || '順豐校驗碼',
            type: 'text',
            name: 'secret',
            required: true,
            maxLength: 255,
            inputProps: {
              type: 'password',
            },
            placeholder: 'XXXXXXXXXXXXXXXXXXXXXXXXXXX',
          },
          {
            label: translate.sf_monthly_card || '月結單號',
            type: 'text',
            name: 'metadata.monthlyCard',
            maxLength: 255,
            placeholder: 'XXXXXXXXXX',
          },
        ]}
      />
    );
  },
  type: 'SHIPMENT',
};
