import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        name
        company {
          id
          memberPointResetDates
          memberPointResetGracefulPeriod
        }
        allowMemberPointCheckout
        memberPointUsePerUnit
        memberPointUseUnitPoint
        memberPointReleasePolicy
        memberPointReleaseDelay
      }
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation(
    $shopId: ID!
    $shopUpdateInput: CompanyShopInput!
    $companyId: ID!
    $companyUpdateInput: CompanyUpdateInput!
  ) {
    companyUpdate(id: $companyId, input: $companyUpdateInput) {
      id
      memberPointResetDates
      memberPointResetGracefulPeriod
    }
    companyShopSet(id: $shopId, input: $shopUpdateInput) {
      id
      name
      allowMemberPointCheckout
      memberPointUsePerUnit
      memberPointUseUnitPoint
      memberPointReleasePolicy
      memberPointReleaseDelay
    }
  }
`;
