import { gql } from '@apollo/client';
import { FRAGMENT_MEMBER_METADATA_FIELD } from '../item_CustomerFieldsSetting/query';

export const FRAGMENT_SHOP_FORM = gql`
  fragment FRAGMENT_SHOP_FORM on ShopForm {
    id
    name
    updatedAt
    createdAt
    active
    validAt
    validThru
    metadataFields {
      ...FRAGMENT_MEMBER_METADATA_FIELD
    }
    autoApprove
    records(limits: 1) {
      totalCount
    }
  }
  ${FRAGMENT_MEMBER_METADATA_FIELD}
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_SHOP_FORM
    }
  }
  ${FRAGMENT_SHOP_FORM}
`;

export const CREATE_QUERY = gql`
  mutation ($input: ShopFormCreateInput!) {
    shopFormCreate(input: $input) {
      ...FRAGMENT_SHOP_FORM
    }
  }
  ${FRAGMENT_SHOP_FORM}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ShopFormUpdateInput!) {
    shopFormUpdate(id: $id, input: $input) {
      ...FRAGMENT_SHOP_FORM
    }
  }
  ${FRAGMENT_SHOP_FORM}
`;

export const DELETE_QUERY = gql`
  mutation ($id: ID!) {
    shopFormDelete(id: $id) {
      ...FRAGMENT_SHOP_FORM
    }
  }
  ${FRAGMENT_SHOP_FORM}
`;

export const EXPORT_QUERY = gql`
  mutation ($id: ID!) {
    shopFormRecordsExport(id: $id) {
      id
    }
  }
`;
