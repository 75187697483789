import React from 'react';
import { Typography } from '@material-ui/core';
import { binColor } from '../../../theme';

const CustomFieldWrapper = ({ labelProps, remarksProps, name, remarks, required, children }) => {
  return (
    <div>
      {!!name && (
        <Typography
          component={'p'}
          variant="h6"
          {...labelProps}
          style={{
            marginBottom: 5,
            ...labelProps?.style,
          }}
        >
          {name}
          {required && <span style={{ color: binColor }}> *</span>}
        </Typography>
      )}
      {children}
      {!!remarks && (
        <Typography component={'p'} variant="caption" {...remarksProps}>
          {remarks}
        </Typography>
      )}
    </div>
  );
};

export default CustomFieldWrapper;
