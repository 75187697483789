import FormPageMaker from 'components/FormPageMaker';
import { GET_QUERY } from './query';
import ItemsCard from './ItemsCard';
import InvoiceCard from './InvoiceCard';
import GroupCard from './GroupCard';
import { translate } from '../../shared/translate';
import RemarkCard from './RemarkCard';
import OrderActivitiesCard from './OrderActivitiesCard';
import CreditNoteCard from './CreditNoteCard';
import { checkPermissionV2 } from '../../components/PermissionMask';
import { parseConnection } from '../../shared';
import DeliveryNoteCard from './DeliveryNoteCard';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
    },
    tabs: [
      { name: translate.order_details, cards: [GroupCard, RemarkCard, ItemsCard] },
      { name: translate.order_activities, cards: [OrderActivitiesCard] },
      { name: translate.delivery_status, cards: [DeliveryNoteCard], noPadding: true },
      { name: translate.payment_status, cards: [InvoiceCard, CreditNoteCard] },
    ],
    enableReinitialize: true,
    formDisabled: !checkPermissionV2('updateOrder'),
  };

  getInitialData({ node } = {}) {
    const {
      id,
      shop,
      checkout,
      referenceNo,
      shipment,
      user,
      status,
      billingAddress,
      shippingAddress,
      items,
      total,
      subtotal,
      channel,
      totalPaid,
      totalRefund,
      shopDiscount,
      couponDiscount,
      deliveryNotes,
      paymentStatus,
      shippingStatus,
      createdAt,
      remark,
      internalRemark,
      remarkMedias,
      internalRemarkMedias,
      cancelReason,
      totalAdjustments = [],
      shippingProvider,
      comments,
      deductibleMemberPoints,
      customer,
      staff,
    } = node || {};
    return {
      id,
      customer,
      orderId: id,
      channelId: (channel || {}).id,
      comments: comments || [],
      checkout,
      referenceNo,
      shipment,
      user,
      cancelReason,
      staff,
      salespersonId: checkout?.salespersonId,
      status,
      shopDiscount,
      couponDiscount,
      billingAddress: billingAddress || {},
      shippingAddress: shippingAddress || {},
      items: items || [],
      total,
      subtotal,
      totalPaid,
      totalRefund,
      deliveryNotes: parseConnection(deliveryNotes).nodes,
      paymentStatus,
      shippingStatus,
      createdAt,
      remark,
      internalRemark,
      remarkMedias: remarkMedias || [],
      internalRemarkMedias: internalRemarkMedias || [],
      shop,
      totalAdjustments,
      shippingProvider,
      deductibleMemberPoints,
    };
  }
}
