import { Button, Grid, InputAdornment, Tooltip } from '@material-ui/core';
import { CheckCircle, HighlightOff, HelpOutline } from '@material-ui/icons';
import React from 'react';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import { translate } from '../../../shared/translate';
import { client } from '../../../shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import useCountdown from '../../../hooks/useCountdown';
import { CONETACT_EMAIL_RESEND_VERIFY } from '../query';
import TrashButton from '../../../components/TrashButton';

const EmailList = ({ emails = [], disabled, onChange }) => {
  const [countdown, resetCountdown] = useCountdown(60, { startOnMounted: false });

  const handleResendVerification = async (id) => {
    try {
      resetCountdown();
      await client.mutate({
        mutation: CONETACT_EMAIL_RESEND_VERIFY,
        variables: {
          id,
        },
      });
    } catch (e) {
      toast.error(errorParser(e));
    }
  };

  return (
    <>
      {emails.map((email, i) => (
        <Grid container spacing={1} alignItems={'center'} style={{ padding: '8px 0' }}>
          <Grid item xs={12} md={7}>
            <InputField
              size={'small'}
              disabled={true}
              value={email.email}
              // eslint-disable-next-line
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <TrashButton
                      size={'small'}
                      edge="end"
                      disabled={disabled}
                      onClick={() => onChange(emails.filter((_, j) => i !== j))}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {email.verified === true && (
              <Tooltip title={translate.verified}>
                <CheckCircle color={'primary'} />
              </Tooltip>
            )}
            {email.verified === false && (
              <Tooltip title={translate.unverified}>
                <HighlightOff color={'error'} />
              </Tooltip>
            )}
            {email.verified === undefined && (
              <Tooltip title={translate.wait_for_verify || '等待驗證'}>
                <HelpOutline color={'warning'} />
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            {email.verified === false && (
              <Button
                disabled={countdown > 0}
                onClick={() => handleResendVerification(email.id)}
                className={'warning-btn'}
              >
                {countdown > 0
                  ? `${translate.resendVerification || '重發驗證碼'} (${countdown})`
                  : translate.resendVerification || '重發驗證碼'}
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default EmailList;
