import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { translate } from 'shared/translate';
import ImageField from './ImageField';
import FormRow from 'components/FormRow';
import { Formik } from 'formik';
import FormToolbar from 'components/FormToolbar';
import ImageLinkField from './ImageLinkField';

export default ({ open, onClose = (_) => _, image, onChange = (_) => _, children }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Formik onSubmit={() => {}} initialValues={{ image }} enableReinitialize={true}>
        {({ values: { image } = {}, setFieldValue }) => (
          <>
            <DialogContent>
              <FormRow title={translate.input_link_or_upload}>
                <ImageLinkField
                  value={image}
                  onChange={(image) => {
                    setFieldValue('image', image);
                  }}
                  placeholder={`image`}
                />
                <ImageField
                  preview={false}
                  value={image}
                  onChange={(image) => {
                    setFieldValue('image', image);
                  }}
                />
              </FormRow>
              {children}
            </DialogContent>
            <FormToolbar
              onSaveDisabled={!image}
              submitBtnType={'button'}
              onSave={() => {
                !!image && onChange(image);
                onClose();
              }}
              onCancel={onClose}
            />
          </>
        )}
      </Formik>
    </Dialog>
  );
};
