import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { translate } from '../../translate';

const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int, $filter: ProductUnionFilterInput, $query: String) {
    node(id: $id) {
      ... on CompanyShop {
        productUnions(limits: $limits, cursor: $cursor, filter: $filter, query: $query) {
          nextCursor
          totalCount
          nodes {
            ... on ShopProduct {
              id
              active
              sku
              name
            }
            ... on ShopBundleProduct {
              id
              active
              sku
              name
            }
          }
        }
      }
    }
  }
`;

export default async function (source, { showProgress, ...variables } = {}) {
  try {
    return await infinityFetch({
      query: GET_QUERY,
      variables: {
        id: source?.id,
        ...variables,
      },
      getConnection: (data) => data?.node?.productUnions,
      onProgress: (downloaded, total) => {
        if (total > 0 && showProgress) ActivityIndicator.show(translate.loading + ` ${downloaded}/${total}`);
      },
    });
  } catch (e) {
    throw e;
  } finally {
    if (showProgress) ActivityIndicator.hide();
  }
}
