import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query (
    $id: ID!
    $playerId: String!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: ChannelFilterInput
  ) {
    me {
      ... on User {
        id
        email
      }
    }
    node(id: $id) {
      id
      ... on CompanyShop {
        channels(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          totalCount
          nextCursor
          nodes {
            id
            type
            code
            pin
            updatedAt
            players {
              id
              name
              picture
              email
            }
            metadata {
              key
              value
            }
            messages(limits: 1, sortBy: [{ field: "createdAt", order: DESC }]) {
              nodes {
                id
                createdAt
                type
                content
                read(playerId: $playerId)
              }
            }
          }
        }
      }
    }
  }
`;
