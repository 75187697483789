import React, { useState } from 'react';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import FormToolbar from '../../../../components/FormToolbar';
import { Formik } from 'formik';
import FormCard from '../../../../components/FormPageMaker/Cards/FormCard';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import { actionWrapper } from '../../../../shared';

const PRODUCTS_HASHTAGS_SET = gql`
  mutation (
    $shopId: ID!
    $hashtags: [String!]!
    $filter: ProductFilterInput
    $operator: BatchAssignmentOperator!
    $query: String
  ) {
    productsHashtagsSet(shopId: $shopId, hashtags: $hashtags, operator: $operator, filter: $filter, query: $query) {
      id
    }
  }
`;

export const MenuHashTagsSet = ({ scope, mutation = PRODUCTS_HASHTAGS_SET, ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <MenuItem disabled={scope.getSelectionCount() === 0} onClick={() => setVisible(true)}>
        {translate.add_or_remove_hashtags}
      </MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Formik
          initialValues={{ operator: 'SET', hashtags: [] }}
          onSubmit={async (values) => {
            setVisible(false);
            await actionWrapper(
              async () => {
                const hashtags = values.hashtags.filter((_) => _);

                if (hashtags.length > 0)
                  await client.mutate({
                    mutation,
                    variables: {
                      shopId: localStorage.getItem('shopId'),
                      filter: scope.getSelectionFilter(),
                      query: scope.getQ(),
                      operator: values.operator,
                      hashtags,
                    },
                  });
              },
              { messages: { loading: translate.update, success: translate.update_success } },
            );
          }}
        >
          {(actions) => (
            <form onSubmit={actions.handleSubmit}>
              <DialogContent>
                <FormCard
                  actions={actions}
                  fields={[
                    {
                      label: translate.adjust_type,
                      name: 'operator',
                      type: 'select',
                      options: [
                        { label: translate.add_hashtags, value: 'SET' },
                        { label: translate.remove_hashtags, value: 'UNSET' },
                      ],
                      required: true,
                    },
                    {
                      label: translate.hash_tag,
                      name: 'hashtags',
                      type: 'hashTagsArray',
                      required: true,
                    },
                  ]}
                />
              </DialogContent>
              <FormToolbar
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
                submitBtnType={'submit'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default {
  render: (args) => <MenuHashTagsSet {...args} />,
};
