import React from 'react';
import { textColor } from '../../theme';
import { Icon } from '../IconRender';
import { Button, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { translate } from '../../shared/translate';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    backgroundColor: '#FAFAFA',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    color: textColor,
    '&:hover': {
      backgroundColor: 'F9F9f9',
    },
  },
}));

const AddButton = ({ title, loading, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.addBtn}
      fullWidth
      startIcon={loading ? <CircularProgress size={20} /> : <Icon icon={'faPlus'} />}
      {...props}
    >
      <Typography style={{ color: textColor, fontWeight: 'bold' }}>{title || translate.add}</Typography>
    </Button>
  );
};

export default AddButton;
