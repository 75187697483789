import React, { useState } from 'react';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import FormRow from '../../components/FormRow';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { InputAdornment, IconButton, CardContent, Card, Typography, Box } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { UPDATE_QUERY } from './query';
import { client } from '../../shared/apollo';
import { Icon } from '../../components/IconRender';

export default ({ data: { id, type = 'google_analytics', context: _context } = {} }) => {
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState(_context);

  return (
    <Card elevation={2} style={{ height: '100%' }}>
      <CardContent>
        <img style={{ height: 50 }} src={require('../../assets/google-analytics.jpg')} alt={''} />
      </CardContent>
      <CardContent>
        <FormRow>
          <InputField
            disabled={loading}
            required={true}
            value={context}
            onChange={(e) => {
              setContext(e.target.value);
            }}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>Google Analytics {translate.tracking_number}：</InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  disabled={loading}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await client.mutate({
                        mutation: UPDATE_QUERY,
                        variables: {
                          id,
                          input: {
                            shopId: localStorage.getItem('shopId'),
                            type,
                            context,
                            active: true,
                          },
                        },
                      });
                    } catch (e) {
                      toast.error(errorParser(e));
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  <Icon icon={'faArrowCircleRight'} />
                </IconButton>
              ),
            }}
          />
        </FormRow>
        <Typography color={'textSecondary'} gutterBottom>
          {translate.google_analytics_description1}
        </Typography>
        <Box display={'flex'}>
          <Typography variant={'caption'} color={'textSecondary'}>
            <Icon icon={'faExclamationCircle'} style={{ fontSize: 12 }} />
            <span
              style={{
                marginLeft: 5,
                verticalAlign: 'text-bottom',
              }}
            >
              {translate.google_analytics_description2}
            </span>
          </Typography>
          <Typography variant={'caption'} color={'textSecondary'}>
            {translate.click_to_register}
          </Typography>
          <a href={'https://google.com'} target={'_blank'} rel="noopener noreferrer">
            <Typography variant={'caption'} color={'textSecondary'}>
              Google Analytics
            </Typography>
          </a>
          <Typography variant={'caption'} color={'textSecondary'}>
            {translate.account}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
