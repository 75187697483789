import React, { useState } from 'react';
import { Button, Box, withStyles, Grid, Card, CardActionArea, CardMedia, Tooltip } from '@material-ui/core';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import OverlayLoading from '../OverlayLoading';
const { REACT_APP_WEBHOOK_URL } = process.env;
const styles = (theme) => ({
  NewBackgroundStepContainer: {
    padding: '10px 20px',
    height: '100%',
  },
  stepTitleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepTitle: {
    color: '#1F4C6D',
    fontSize: '18px',
    fontWeight: '500',
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginTop: '20px',
  },
  stepperPrimaryBtn: {
    background: '#5B7D96',
    boxShadow: '2px 2px 2px 0px rgb(135 135 135 / 40%) inset',
    backdropFilter: 'blur(5px)',
    // -webkit-backdrop-filter: blur(5px);
    border: '1px solid rgba(255, 255, 255, 0.20)',
    //   border: 'none',
    borderRadius: '30px',
    color: '#fff',
    padding: '7px 14px',
    fontSize: '16px',
    width: '160px',
    '&:hover': {
      background: '#3b5569',
    },
  },
  stepperSecondaryBtn: {
    background: '#ddd',
    // -webkit-backdrop-filter: blur(5px);
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '2px 2px 2px 0px rgb(227 227 227 / 40%) inset',
    //   border: 'none',
    borderRadius: '30px',
    color: '#fff',
    padding: '7px 14px',
    fontSize: '16px',
    width: '160px',
  },
  NewBgBtn: {
    background: '#ddd',
    // -webkit-backdrop-filter: blur(5px);
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '2px 2px 2px 0px rgb(227 227 227 / 40%) inset',
    //   border: 'none',
    borderRadius: '30px',
    color: '#fff',
    padding: '7px 14px',
    fontSize: '16px',
    width: '160px',
  },
});

const photoRoomTemplates = [
  {
    prompt: 'random beautiful outdoor background such as beach, garden, park, city view, etc.',
    image: require('../../assets/photoroom-outdoor.png'),
    tooltips: 'Outdoor',
  },
  {
    prompt: 'Light wood countertop',
    image: require('../../assets/photoroom-wood.jpg'),
    tooltips: 'Wood',
  },
  {
    prompt: 'fashion abstract elegant',
    image: require('../../assets/photoroom-fashion.png'),
    tooltips: 'Fashion',
  },
  {
    prompt: 'restaurant, cafe, bar, pub, etc.',
    image: require('../../assets/photoroom-restaurant.png'),
    tooltips: 'Restaurant',
  },
  // {
  //   templateId: '2c7e8a06-e788-4996-b63d-399246d660ce',
  //   image: require('../../assets/photoroom-template2.jpg'),
  // },
  // {
  //   templateId: '45d0087a-6c36-4d4f-b7d9-fefb51702ff6',
  //   image: require('../../assets/photoroom-template3.jpg'),
  // },
  // {
  //   templateId: '3cc28567-5f54-433c-a0c8-a5817b801e57',
  //   image: require('../../assets/photoroom-template4.jpg'),
  // },
  // {
  //   templateId: '580b6142-6fb6-4470-bf9a-6a83d9e84cfc',
  //   image: require('../../assets/photoroom-template5.jpg'),
  // },
  // {
  //   templateId: '9aebd6b7-9c39-4690-aaa2-cd0b97811299',
  //   image: require('../../assets/photoroom-template6.jpg'),
  // },
];

const MagicMediaNewBackground = ({ classes, value, onChange, handleGoBack, handleClose }) => {
  const [newSrc, setNewSrc] = useState('');
  const [processing, setProcessing] = useState();
  const { src } = value || {};

  async function applyTemplate(prompt = '') {
    try {
      setProcessing(true);
      const res = await fetch(
        `${REACT_APP_WEBHOOK_URL}/photoroom/instant-backgrounds?imageUrl=${src}&prompt=${prompt}`,
        {
          method: 'POST',
          headers: {
            authorization: localStorage.getItem('token'),
          },
        },
      );
      const { url } = await res.json();
      setNewSrc(url);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setProcessing(false);
    }
  }

  return (
    <>
      <Box
        className={classes.NewBackgroundStepContainer}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box flex={1} position={'relative'} width={'80%'}>
          <Grid
            container
            spacing={2}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              flexGrow: 1,
            }}
          >
            <Grid item style={{ height: '100%' }}>
              <Box display={'flex'} flexDirection={'column'} style={{ height: '100%', overflow: 'auto' }}>
                {photoRoomTemplates.map(({ image, prompt, tooltips }, i) => (
                  <Tooltip key={i} title={tooltips}>
                    <Card elevation={0} style={{ marginTop: i === 0 ? 0 : 16 }}>
                      <CardActionArea
                        onClick={() => {
                          if (processing) return;
                          applyTemplate(prompt);
                        }}
                      >
                        <CardMedia
                          style={{
                            borderRadius: 4,
                            overflow: 'hidden',
                            width: 60,
                            height: 60,
                            objectFit: 'cover',
                          }}
                          image={image}
                        />
                      </CardActionArea>
                    </Card>
                  </Tooltip>
                ))}
              </Box>
            </Grid>
            <Grid item xs={true}>
              <Card
                elevation={0}
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#EEEEEE',
                  padding: 16,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${newSrc || src})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'center',
                    backgroundSize: 'contain',
                    position: 'relative',
                  }}
                >
                  {processing && <OverlayLoading />}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.btnGroup}>
          <Button className={classes.stepperSecondaryBtn} onClick={handleGoBack}>
            返回
          </Button>
          <Button
            disabled={processing || !newSrc}
            className={classes.stepperPrimaryBtn}
            onClick={() => {
              onChange({
                ...value,
                src: newSrc,
                optimizedSrc: undefined,
              });
              handleClose();
            }}
          >
            確定使用
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(MagicMediaNewBackground);
