import React from 'react';
import { translate } from 'shared/translate';
import AddressBody from 'components/AddressBody';
import { OrderCard, OrderCardContent, OrderCardHeader } from '../../components/OrderCard';
import FormRow from '../../components/FormRow';
import { Box, Grid } from '@material-ui/core';
import AddressesUpdateButton from './AddressesUpdateButton';
import ResendConfirmationEmailButton from './ResendConfirmationEmailButton';
import { parseCustomerName } from '../../shared';

export default ({
  loading,
  isSubmitting,
  values: { customer, orderId, shippingAddress, billingAddress, shippingProvider, shop, checkout } = {},
  className,
  minHeight,
}) => {
  const isInSitu = shippingProvider === null || (shippingProvider || {}).provider === 'IN_SITU';
  const isPickup = /PICKUP/.test((shippingProvider || {}).provider);
  const hasAddressUpdateButton = !loading && !isInSitu && !isPickup;

  return (
    <Box mb={3}>
      <OrderCard className={className} style={{ minHeight: minHeight }}>
        <OrderCardHeader
          title={
            <>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {translate.billing_and_shipping_info}
                {hasAddressUpdateButton && (
                  <AddressesUpdateButton
                    order={{
                      id: orderId,
                      shippingAddress,
                      billingAddress,
                    }}
                  />
                )}
                <div style={{ flex: 1 }} />
                <ResendConfirmationEmailButton id={orderId} disabled={loading || isSubmitting} />
              </Box>
            </>
          }
        />
        <OrderCardContent>
          <Grid container spacing={4}>
            {isInSitu ? (
              <>
                <FormRow
                  title={translate.store_info}
                  xs={12}
                  md={6}
                  titleStyle={{
                    paddingBottom: '10px',
                  }}
                >
                  <AddressBody span={4} loading={loading} data={{ ...(shop || {}).address, person: null }} />
                </FormRow>
                <FormRow
                  title={translate.order_customer}
                  xs={12}
                  md={6}
                  titleStyle={{
                    paddingBottom: '10px',
                  }}
                >
                  <AddressBody
                    span={4}
                    loading={loading}
                    data={{
                      person: parseCustomerName(customer) ?? '',
                      email: customer?.email ?? '',
                      tel: customer?.phoneNumber ?? '',
                      lines: null,
                      country: null,
                    }}
                  />
                </FormRow>
              </>
            ) : isPickup ? (
              <>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column">
                    <FormRow title={translate.contact_method} xs={12}>
                      <AddressBody
                        span={4}
                        loading={loading}
                        data={{
                          ...(shippingAddress || {}),
                          lines: null,
                          country: null,
                        }}
                      />
                    </FormRow>
                    <FormRow title={translate.pick_up_point} xs={12}>
                      <AddressBody
                        span={4}
                        loading={loading}
                        data={{
                          ...checkout?.pickUpAddress,
                          person: null,
                        }}
                      />
                    </FormRow>
                  </Grid>
                </Grid>
                <FormRow title={translate.billing_address} xs={12} md={6}>
                  <AddressBody span={4} loading={loading} data={billingAddress || {}} />
                </FormRow>
              </>
            ) : (
              <>
                <FormRow title={translate.shipping_address} xs={12} md={6}>
                  <AddressBody span={4} loading={loading} data={shippingAddress || {}} />
                </FormRow>
                <FormRow title={translate.billing_address} xs={12} md={6}>
                  <AddressBody span={4} loading={loading} data={billingAddress || {}} />
                </FormRow>
              </>
            )}
          </Grid>
        </OrderCardContent>
      </OrderCard>
    </Box>
  );
};
