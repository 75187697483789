import React from 'react';
import { Grid } from '@material-ui/core';
import useBreakPoint from '../../components/useBreakPoint';

const SplitCard = ({ configCard, previewCard }) => {
  const breakPoint = useBreakPoint(),
    isMobile = ['xs', 'sm'].includes(breakPoint);
  return (
    <Grid
      container
      spacing={3}
      style={{
        height: isMobile ? undefined : `calc(100vh - 380px)`,
        minHeight: 800,
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        xl={true}
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        {configCard}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        xl={'auto'}
        style={{
          // minWidth: 600,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            width: '100%',
            overflow: 'auto',
          }}
        >
          {previewCard}
        </div>
      </Grid>
    </Grid>
  );
};

export default SplitCard;
