import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';

const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      ... on CompanyStockTransfer {
        itemsV2(limits: $limits, cursor: $cursor) {
          nextCursor
          totalCount
          nodes {
            id
            sku
            quantity
            receivedQuantity
            productVariation {
              id
              sku
              combination {
                name
                option
              }
              product {
                id
                sku
                name
                medias {
                  #id
                  src
                  optimizedSrc(width: 256, height: 256)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default async function (source) {
  return await infinityFetch({
    fetchPolicy: 'network-only',
    query: GET_QUERY,
    variables: {
      id: source?.id,
    },
    getConnection: (data) => data?.node?.itemsV2,
  });
}
