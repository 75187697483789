import React, { Fragment, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import AddClickRegion from '../../AddClickRegion';
import BodyComponents from '../index';
import { translate } from '../../../../../shared/translate';
import { useMeasure } from 'react-use';
import { Icon } from '../../../../../components/IconRender';

export default ({ data, setEditData, height, onChange = (_) => _ }) => {
  const {
    numberOfRows = 2,
    numberOfColumns = 2,
    items = [],
    spacing = 0,
    rowRatios = [],
    columnRatios = [],
  } = data || {};

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height }}>
      {Array(numberOfRows)
        .fill(undefined)
        .map((__, i) => (
          <Fragment key={i}>
            {i > 0 && <div style={{ height: spacing }} />}
            <div style={{ flex: ['a', 'A'].includes(rowRatios[i]) ? undefined : rowRatios[i] ?? 1, minHeight: 50 }}>
              <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                {Array(numberOfColumns)
                  .fill(undefined)
                  .map((__, j) => (
                    <Fragment key={j}>
                      {j > 0 && <div style={{ width: spacing }} />}
                      <div
                        style={{
                          flex: ['a', 'A'].includes(columnRatios[j]) ? undefined : columnRatios[j] ?? 1,
                          minWidth: 50,
                        }}
                      >
                        {(() => {
                          const item = items?.[i * numberOfColumns + j];
                          if (item)
                            return (
                              <CellWrapper
                                onConfigClick={() => setEditData(data)}
                                onRemoveClick={() => {
                                  onChange({
                                    ...data,
                                    items: Array(numberOfRows * numberOfColumns)
                                      .fill(undefined)
                                      .map((__, k) => {
                                        if (k === i * numberOfColumns + j) return null;
                                        return items?.[k];
                                      }),
                                  });
                                }}
                              >
                                {(height) => {
                                  return (
                                    <BodyComponents
                                      data={item}
                                      height={height}
                                      setEditData={setEditData}
                                      onChange={(update) => {
                                        onChange({
                                          ...data,
                                          items: Array(numberOfRows * numberOfColumns)
                                            .fill(undefined)
                                            .map((__, k) => {
                                              if (k === i * numberOfColumns + j) return update;
                                              return items?.[k];
                                            }),
                                        });
                                      }}
                                    />
                                  );
                                }}
                              </CellWrapper>
                            );
                          return (
                            <AddClickRegion
                              style={{ height: '100%' }}
                              col
                              expand
                              onComponentAdd={(config) => {
                                onChange({
                                  ...data,
                                  items: Array(numberOfRows * numberOfColumns)
                                    .fill(undefined)
                                    .map((__, k) => {
                                      if (k === i * numberOfColumns + j) return config;
                                      return items?.[k];
                                    }),
                                });
                                setEditData(config);
                              }}
                            />
                          );
                        })()}
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          </Fragment>
        ))}
    </div>
  );
};

function CellWrapper({ children, onConfigClick, onRemoveClick }) {
  const [ref, { height }] = useMeasure();
  const [hover, setHover] = useState(false);
  return (
    <div
      ref={ref}
      style={{ height: '100%', position: 'relative', textAlign: 'center' }}
      onMouseOver={() => !hover && setHover(true)}
      onMouseOut={() => hover && setHover(false)}
    >
      {children(height)}
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          zIndex: 1,
          transition: 'all 0.25s',
          opacity: hover ? 1 : 0,
        }}
      >
        <Tooltip title={'Grid setting'}>
          <IconButton color={'primary'} size={'small'} onClick={onConfigClick} disabled={!onConfigClick}>
            <Icon icon={'faCog'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate.delete}>
          <IconButton color={'secondary'} size={'small'} onClick={onRemoveClick} disabled={!onRemoveClick}>
            <Icon type={'material'} icon={'Cancel'} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
