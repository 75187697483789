import React, { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import toast from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { isProduction } from '../../../../shared';

const { REACT_APP_WEBHOOK_URL } = process.env;

const ShipAnyButton = ({ id }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Tooltip title={`ShipAny`}>
      <IconButton
        disabled={loading}
        onClick={async () => {
          try {
            setLoading(true);
            const res = await fetch(`${REACT_APP_WEBHOOK_URL}/print_waybill?id=${id}`, {
              method: 'GET',
              headers: {
                authorization: localStorage.getItem('token'),
              },
            });

            if (/^2/.test(res.status) || /^3/.test(res.status)) {
              const { url, externalId } = await res.json();
              window.open(
                isProduction()
                  ? `https://portal.shipany.io/order/detail/${externalId}`
                  : `https://portal-demo1.shipany.io/order/detail/${externalId}`,
                '_blank',
              );
            } else if (/^4/.test(res.status)) {
              const { message } = await res.json();
              throw new Error(message);
            } else if (/^5/.test(res.status)) {
              const { message } = await res.json();
              throw new Error(message);
            }
          } catch (e) {
            console.log(e, REACT_APP_WEBHOOK_URL);
            toast.error(errorParser(e));
          } finally {
            setLoading(false);
          }
        }}
      >
        {loading ? (
          <CircularProgress style={{ width: 21, height: 21 }} />
        ) : (
          <Avatar
            style={{
              width: 21,
              height: 21,
            }}
            src={require('../../../../assets/shipany.png')}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ShipAnyButton;
