import React from 'react';
import { Grid, Typography, Box, Tooltip } from '@material-ui/core';
import PaginationMultipleApproach from 'components/PaginationMultipleApproach';
import { translate } from 'shared/translate';
import RefreshButton from '../RefreshButton';

export default ({
  extra,
  loading,
  totalCount = 0,
  totalCountDisplay = totalCount,
  cursor,
  limits = 20,
  onRefreshClick,
  onCursorChange = (_) => _,
}) => {
  const isExceed1000 = totalCount > 10000;

  return (
    <Grid direction={'row'} container alignItems={'center'}>
      <Box flexGrow={1} />
      <PaginationMultipleApproach
        count={totalCount >= 10000 ? 10000 : totalCount}
        offset={{ skip: cursor, limit: limits }}
        setOffset={({ skip }) => {
          onCursorChange(skip);
        }}
        disabled={loading}
      />
      <Box flexGrow={1} display={'flex'} justifyContent={'flex-end'} mr={2}>
        {extra}
        {totalCount > 0 && (
          <>
            {isExceed1000 ? (
              <Tooltip title={totalCountDisplay}>
                <Typography variant={'caption'}>{translate['total']}: 10000+</Typography>
              </Tooltip>
            ) : (
              <Typography variant={'caption'}>
                {translate['total']}: {totalCountDisplay}
              </Typography>
            )}
          </>
        )}
      </Box>
      {!!onRefreshClick && <RefreshButton loading={loading} onClick={onRefreshClick} />}
    </Grid>
  );
};
