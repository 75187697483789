import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $sku: String!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!, $shopId: ID) {
    node(id: $id) {
      id
      ... on Company {
        report {
          productSales(sku: $sku, startedAt: $startedAt, startedThru: $startedThru, shopId: $shopId) {
            id
            name
            sku
            amount
            count
            orderCount
            avgPrice
            avgCount
            pageView
            hitRate
            conversionRate
            memberAmount
            nonMemberAmount

            salesTrends {
              name
              data {
                name
                value
              }
            }
            memberLevels {
              name
              value
            }
            memberLevelAmounts {
              name
              value
            }
            salesPoints {
              name
              value
            }
            countries {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_SALE_RECORD_QUERY = gql`
  query (
    $id: ID!
    $sku: String!
    $startedAt: AWSDateTime!
    $startedThru: AWSDateTime!
    $cursor: Int
    $limits: Int
    $shopId: ID
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        report {
          productSales(sku: $sku, startedAt: $startedAt, startedThru: $startedThru, shopId: $shopId) {
            id
            salesRecords(cursor: $cursor, limits: $limits, sortBy: $sortBy) {
              nextCursor
              totalCount
              nodes {
                createdAt
                shopName
                variationSku
                referenceNo
                quantity
                amount
              }
            }
          }
        }
      }
    }
  }
`;
