import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, GET_QUERY, UPDATE_QUERY } from './query';
import { JSONParseSafely, parseConnection } from '../../shared';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import RatioSelectField from './RatioSelectField';

class item_PopupAdv extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      create: CREATE_QUERY,
      submit: UPDATE_QUERY,
    },
    tabs: [
      {
        name: translate.popup_adv_xs_setting,
        cards: [
          {
            fields: [
              {
                label: translate.adventist_size,
                render: ({ values: { xsPopup } = {}, setFieldValue, isSubmitting }) => {
                  return (
                    <RatioSelectField
                      disabled={isSubmitting}
                      value={xsPopup?.ratio}
                      onChange={(e) => setFieldValue('xsPopup', { ...xsPopup, ratio: e })}
                    />
                  );
                },
              },
              {
                label: translate.adventist_image,
                type: 'image',
                mediaStyle: ({ values: { xsPopup } }) => ({
                  aspectRatio: xsPopup.ratio,
                  width: '100%',
                  height: '100%',
                }),
                actionStyle: ({ values: { xsPopup } }) => ({
                  aspectRatio: xsPopup.ratio,
                  minWidth: '100px',
                  minHeight: '100px',
                  maxWidth: '100px',
                  maxHeight: '100px',
                  borderRadius: '1rem',
                }),
                name: 'xsPopup.media',
              },
              {
                label: translate.activate_checkbox,
                type: 'switch',
                name: 'xsPopup.active',
              },
              {
                label: translate.share_href,
                type: 'text',
                name: 'href',
                placeholder: '/about',
              },
              {
                label: translate.share_valid_thru,
                name: 'validThru',
                type: 'datetime-local',
                hasPermanent: true,
              },
            ],
          },
        ],
      },
      {
        name: translate.popup_adv_md_setting,
        cards: [
          {
            fields: [
              {
                label: translate.adventist_size,
                render: ({ values: { mdPopup } = {}, setFieldValue, isSubmitting }) => {
                  return (
                    <RatioSelectField
                      disabled={isSubmitting}
                      value={mdPopup?.ratio}
                      onChange={(e) => setFieldValue('mdPopup', { ...mdPopup, ratio: e })}
                    />
                  );
                },
              },
              {
                label: translate.adventist_image,
                type: 'image',
                mediaStyle: ({ values: { mdPopup } }) => ({
                  aspectRatio: mdPopup.ratio,
                  width: '100%',
                  height: '100%',
                }),
                actionStyle: ({ values: { mdPopup } }) => ({
                  aspectRatio: mdPopup.ratio,
                  minWidth: '100px',
                  minHeight: '100px',
                  maxWidth: '100px',
                  maxHeight: '100px',
                  borderRadius: '1rem',
                }),
                name: 'mdPopup.media',
              },
              {
                label: translate.activate_checkbox,
                type: 'switch',
                name: 'mdPopup.active',
              },
              {
                label: translate.share_href,
                type: 'text',
                name: 'href',
                placeholder: '/about',
              },
              {
                label: translate.share_valid_thru,
                type: 'datetime-local',
                name: 'validThru',
                hasPermanent: true,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async ({ id, validAt, href, validThru, active, xsPopup, mdPopup }) => {
    const {
      gql: { submit, create },
    } = this.state;
    if (!!id)
      await client.mutate({
        mutation: submit,
        variables: {
          id,
          input: {
            active,
            validAt,
            href,
            validThru,
            content: JSON.stringify({ xsPopup, mdPopup }),
          },
        },
      });
    else
      await client.mutate({
        mutation: create,
        variables: {
          input: {
            shopId: localStorage.getItem('shopId'),
            active,
            validAt,
            href,
            validThru,
            content: JSON.stringify({ xsPopup, mdPopup }),
          },
        },
      });
    return true;
  };

  getInitialData({ node } = {}) {
    const { nodes: popups } = parseConnection((node || {}).popups),
      [popup] = popups || [],
      { id, updatedAt, href, path = '', content = '', active = true, validThru } = popup || {};
    const { xsPopup = { ratio: '1/1', active: true, media: '' }, mdPopup = { ratio: '1/1', active: true, media: '' } } =
      JSONParseSafely(content);

    return {
      validThru,
      id,
      updatedAt,
      href,
      path,
      xsPopup,
      mdPopup,
      active,
    };
  }

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}

export default item_PopupAdv;
