import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../SplitCard';
import EmailWelcome from '../EmailWelcome';
import defaultSuggestions from '../defaultSuggestions';

export default class EmailBlock extends EmailWelcome {
  emailType = 'APPOINTMENT_CONFIRMATION';
  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[...defaultSuggestions]}
              allows={['isTitle', 'isText', 'isImage', 'isDivider', 'isButton', 'isLink']}
              {...props}
            />
          }
          previewCard={<PreviewCard extraData={appointmentData} {...props} />}
        />
      ),
    ],
  };
}

export const appointmentData = {
  locale_zhHK: true,
  locale_zhCN: false,
  locale_en: false,
  date: {
    year: 2024,
    month: 7,
    date: 11,
    day: 11,
    hour: 11,
    minute: 23,
    second: 15,
  },
  shop: {
    email: 'test30.omniwe.site@omniwe.com',
    name: '[UAT] 智健醫療 HC Healthcare Limited',
    logo: 'https://assets.omniwe.com/uf6J3a7rYD45kHbm0dTJj.jpeg',
    url: 'https://test30.omniwe.site',
  },
  startDate: '11/07/2024',
  startTime: '11:23:15',
  location: {
    id: 'af132e67-6038-4f8f-b3f1-bf59811d5809',
    name: '診所',
    active: true,
    medias: [
      {
        id: '7a02f35-1ef2-bcd8-4227-4de0dd1f5722',
        src: 'https://assets.omniwe.com/1edp9qplZhuTq3IrDq08o.jpeg',
      },
    ],
    shopId: '3Watjco7KszCpph8K9QCnpGB',
    address: {
      tel: '+85235654483',
      email: 'demo@omniwe.com',
      lines: ['大嶼山陰澳打水灣欣澳道', '', ''],
      person: 'Frankie',
      country: 'HKG',
    },
    metadata: [],
    companyId: 'ENQNkfqvazshoUa7mesYC',
    createdAt: '2024-06-07T05:36:27.794Z',
    sortIndex: 0,
    updatedAt: '2024-06-07T05:36:27.794Z',
    __typename: 'ShopServiceLocation',
    durationMins: 60,
  },
  serviceBundle: {
    id: '01J0NC2WVECW4W1NSTQ7FQ4WKH',
    sku: '202406181750_vpCzB9Dx',
    name: '自費復必泰二價疫苗',
    active: true,
    medias: [
      {
        id: '55fb8b2-e72b-28c4-c01b-fbd7b16fbe24',
        src: 'https://pfst.cf2.poecdn.net/base/image/048521484db226fb35211863554d6d960f6b672ed410733be4a6833edbaf5cca?w=1024&h=1024&pmaid=100944054',
      },
    ],
    shopId: '6-5HJPPMezzS5xwXkA-x2-T-',
    barcode: null,
    hashtags: [],
    subtitle: ' 新冠疫苗',
    companyId: '0sGZt63EjixjnWnzYHTTe',
    createdAt: '2024-06-18T09:50:40.969Z',
    modifiers: [],
    publishAt: '2024-06-18T09:50:40.000Z',
    unitPrice: 100,
    updatedAt: '2024-06-19T07:11:16.000Z',
    __typename: 'ShopServiceBundle',
    ignoreStock: true,
    publishThru: null,
    primarySortIndex: 0,
    validationStrategy: 'SERIES',
    suggestedRetailPrice: 0,
    slotRequiredAtCheckout: false,
  },
  orderItem: {
    __typename: 'OrderItem',
    sortIndex: 0,
    id: '4f9b274c-64c2-44b1-8d0b-c36f009079dc',
    createdAt: '2024-07-05T07:42:05.027Z',
    updatedAt: '2024-07-05T07:42:06.000Z',
    active: true,
    orderId: '9debeac4-73a6-4e27-bbe1-b5d1eb1ea778',
    checkoutItemId: 'db2e07a5-257a-4a9b-973a-752a5db513d5',
    productVariationId: null,
    productId: null,
    addOnProductId: null,
    shopGiftId: null,
    serviceBundleId: '01J0NC2WVECW4W1NSTQ7FQ4WKH',
    quantity: 1,
    unitPrice: 100,
    remark: '第二劑',
    sku: '202406181750_vpCzB9Dx',
    additionalSkus: [],
    description: '自費復必泰二價疫苗',
    media: {
      src: 'https://pfst.cf2.poecdn.net/base/image/048521484db226fb35211863554d6d960f6b672ed410733be4a6833edbaf5cca?w=1024&h=1024&pmaid=100944054',
    },
    metadata: null,
    metadataFields: [],
    bundleProductId: null,
    discountedAmount: 0,
  },
  appointment: {
    __typename: 'ShopAppointment',
    remark: null,
    status: 'CONFIRMED',
    attendanceStatus: 'PENDING',
    customerId: null,
    customer: null,
    contactAddress: {
      tel: '+85265949374',
      email: 'frankie@omniwe.com',
      lines: [],
      person: 'Frankie Lei',
      country: 'HKG',
    },
    serviceSortIndex: 1,
    serviceBundleId: '01J0NC2WVECW4W1NSTQ7FQ4WKH',
    serviceBundle: {
      id: '01J0NC2WVECW4W1NSTQ7FQ4WKH',
      sku: '202406181750_vpCzB9Dx',
      name: '自費復必泰二價疫苗',
      active: true,
      medias: [
        {
          id: '55fb8b2-e72b-28c4-c01b-fbd7b16fbe24',
          src: 'https://pfst.cf2.poecdn.net/base/image/048521484db226fb35211863554d6d960f6b672ed410733be4a6833edbaf5cca?w=1024&h=1024&pmaid=100944054',
        },
      ],
      shopId: '6-5HJPPMezzS5xwXkA-x2-T-',
      barcode: null,
      hashtags: [],
      subtitle: ' 新冠疫苗',
      companyId: '0sGZt63EjixjnWnzYHTTe',
      createdAt: '2024-06-18T09:50:40.969Z',
      modifiers: [],
      publishAt: '2024-06-18T09:50:40.000Z',
      unitPrice: 100,
      updatedAt: '2024-06-19T07:11:16.000Z',
      __typename: 'ShopServiceBundle',
      ignoreStock: true,
      publishThru: null,
      primarySortIndex: 0,
      validationStrategy: 'SERIES',
      suggestedRetailPrice: 0,
      slotRequiredAtCheckout: false,
    },
    metadata: null,
    id: '01J20XFPAA8A4N8PCAKDAJ2968',
    createdAt: '05/07/2024 15:42:05',
    createdBy: {
      __typename: 'User',
      id: '00000000-0000-0000-0000-000000000000',
      email: 'no-reply@omniwe.com',
      name: 'OmniWe',
    },
    updatedAt: '05/07/2024 15:42:05',
    updatedBy: null,
    active: true,
    companyId: '0sGZt63EjixjnWnzYHTTe',
    shopId: '6-5HJPPMezzS5xwXkA-x2-T-',
    referenceNo: 'AP1720165325130',
    startedAt: {
      date: '11/07/2024',
      time: '11:23:15',
    },
    startedThru: {
      date: '11/07/2024',
      time: '11:23:15',
    },
    attendedAt: null,
    serviceId: '01J0QN9XBDJT7YQ9MJW5JB9JAV',
    service: {
      id: '01J0QN9XBDJT7YQ9MJW5JB9JAV',
      code: '485ff7aee7429b4114865f506f65378e',
      name: '第二針',
      active: true,
      medias: [],
      shopId: '6-5HJPPMezzS5xwXkA-x2-T-',
      metadata: [
        {
          key: 'mustHaveContactAddress',
          value: 'true',
        },
      ],
      companyId: '0sGZt63EjixjnWnzYHTTe',
      createdAt: '2024-06-19T07:10:15.404Z',
      sortIndex: 1,
      updatedAt: '2024-06-19T07:11:16.645Z',
      __typename: 'ShopService',
      validSince: {
        days: 0,
        hours: 0,
        weeks: 0,
        years: 0,
        months: 3,
        minutes: 0,
        seconds: 0,
      },
      validUntil: {
        days: 0,
        hours: 0,
        weeks: 0,
        years: 0,
        months: 3,
        minutes: 0,
        seconds: 0,
      },
      autoConfirm: true,
      outboundSkus: [],
      availableUntil: {
        days: 0,
        hours: 1,
        weeks: 0,
        years: 0,
        months: 0,
        minutes: 0,
        seconds: 0,
      },
      serviceBundleId: '01J0NC2WVECW4W1NSTQ7FQ4WKH',
      productVariations: [],
      serviceLocationName: '診所',
    },
    serviceLocationId: null,
    serviceLocation: null,
    orderId: '9debeac4-73a6-4e27-bbe1-b5d1eb1ea778',
    orderItemId: '4f9b274c-64c2-44b1-8d0b-c36f009079dc',
    deliveryNoteId: null,
    deliveryNoteItemId: null,
    checkoutServiceId: 'd546648e-fe0d-4654-851d-5fc00f4efbad',
    orderItem: {
      __typename: 'OrderItem',
      sortIndex: 0,
      id: '4f9b274c-64c2-44b1-8d0b-c36f009079dc',
      createdAt: '2024-07-05T07:42:05.027Z',
      updatedAt: '2024-07-05T07:42:06.000Z',
      active: true,
      orderId: '9debeac4-73a6-4e27-bbe1-b5d1eb1ea778',
      checkoutItemId: 'db2e07a5-257a-4a9b-973a-752a5db513d5',
      productVariationId: null,
      productId: null,
      addOnProductId: null,
      shopGiftId: null,
      serviceBundleId: '01J0NC2WVECW4W1NSTQ7FQ4WKH',
      quantity: 1,
      unitPrice: 100,
      remark: '',
      sku: '202406181750_vpCzB9Dx',
      additionalSkus: [],
      description: '自費復必泰二價疫苗',
      media: {
        src: 'https://pfst.cf2.poecdn.net/base/image/048521484db226fb35211863554d6d960f6b672ed410733be4a6833edbaf5cca?w=1024&h=1024&pmaid=100944054',
      },
      metadata: null,
      metadataFields: [],
      bundleProductId: null,
      discountedAmount: 0,
    },
  },
  customer: {
    name: 'Frankie Lei',
    email: 'frankie@omniwe.com',
    phoneNumber: '+85265949374',
  },
  order: {
    __typename: 'ShopOrder',
    cashier: null,
    id: '9debeac4-73a6-4e27-bbe1-b5d1eb1ea778',
    createdAt: '05/07/2024 15:42:04',
    createdBy: {
      __typename: 'User',
      id: '00000000-0000-0000-0000-000000000000',
      email: 'no-reply@omniwe.com',
      name: 'OmniWe',
    },
    updatedAt: '05/07/2024 16:13:58',
    updatedBy: {
      __typename: 'User',
      id: 'auth0|5e25457ee8a6430e93a3cdeb',
      email: 'admin@omniwe.com',
      name: 'Admin',
      picture:
        'https://s.gravatar.com/avatar/ae1c46677d4d9d00969e245645c4c8d5?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fad.png',
    },
    active: true,
    shopId: '6-5HJPPMezzS5xwXkA-x2-T-',
    checkoutId: '2455ea89-c5f2-4c74-a0e8-92766cb935e0',
    completedAt: null,
    customerId: null,
    customer: null,
    status: 'PROCESSING',
    kitchenStatus: 'PENDING',
    shippingAddress: {
      person: 'Frankie Lei',
      tel: '+85265949374',
      email: 'frankie@omniwe.com',
      country: 'HKG',
    },
    billingAddress: {
      country: 'HKG',
    },
    paymentProvider: 'MANUAL',
    paymentProviders: [
      {
        id: '6a4da82f-5e5f-45ea-89cb-f1cf59bc7e46',
        name: 'omniwe',
      },
      {
        id: 'ec7599e3-0176-4818-8286-7026097a6f21',
        name: '現金',
      },
    ],
    shippingProvider: null,
    paymentStatus: 'COMPLETED',
    shippingStatus: 'PENDING',
    shippingFee: 0,
    taxFee: 0,
    subtotal: 200,
    totalAdjustment: 0,
    total: 200,
    referenceNo: 'PO0000007',
    currency: 'HKD',
    shopDiscount: 0,
    couponDiscount: 0,
    cancelReason: null,
    remark: '',
    internalRemark: null,
    remarkMedias: null,
    internalRemarkMedias: null,
    memberPoints: 0,
    memberPointDiscount: 0,
    deviceId: null,
    search: null,
    salespersonId: null,
    staffId: null,
    pickUpAddress: null,
    metadata: [
      {
        key: 'orderConfirmationEmail',
        value: 'true',
      },
    ],
    deductibleMemberPoints: 0,
    isPreOrder: false,
  },
  qrCodeUrl: '',
};
