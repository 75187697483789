import React from 'react';
import InputField from 'components/FormPageMaker/Fields/InputField';
import { translate } from 'shared/translate';
import { Box, InputAdornment } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '../../components/IconRender';

export default ({
  loading,
  value,
  onChange,
  disabled,
  className,
  iconColor = undefined,
  helperText,
  mr = 2,
  required,
}) => {
  const { quantity, ignoreStock } = value || {};
  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Box flex={1} mr={mr}>
        {loading ? (
          <Skeleton height={30} />
        ) : (
          <InputField
            required={required === true || required === false ? required : !ignoreStock}
            className={className}
            value={ignoreStock ? '' : quantity}
            disabled={disabled || ignoreStock}
            placeholder={ignoreStock ? translate.infinite : ''}
            helperText={helperText}
            type={'number'}
            onChange={(e) => {
              onChange({
                quantity: e.target.value,
                ignoreStock,
              });
            }}
            // eslint-disable-next-line
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'} disableTypography>
                  <Tooltip title={translate.infinite_stock}>
                    <IconButton
                      disabled={disabled}
                      size={'small'}
                      color={ignoreStock ? 'primary' : undefined}
                      onClick={() => {
                        const _ignoreStock = !ignoreStock;
                        onChange({
                          quantity,
                          ignoreStock: _ignoreStock,
                        });
                      }}
                    >
                      <Icon icon={'faInfinity'} style={{ color: iconColor }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        )}
        {/* <Typography variant={'body2'}>{translate.stock_description || '請輸入數量'}</Typography> */}
      </Box>
    </Box>
  );
};
