import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query (
    $id: ID!
    $cursor: Int
    $limits: Int
    $filter: AttendanceFilterInput
    $sortBy: [SorterInput!]
    $createdAt: AWSDateTime
    $createdThru: AWSDateTime
  ) {
    node(id: $id) {
      id
      ... on CompanyStaff {
        attendSecondsByDay(createdAt: $createdAt, createdThru: $createdThru) {
          createdAt
          createdThru
          seconds
        }
        attendances(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          totalCount
          nextCursor
          nodes {
            id
            updatedAt
            createdAt
            direction
            shop {
              id
              name
            }
          }
        }
      }
    }
  }
`;
