import { gql } from '@apollo/client';

export const FRAGMENT_STOCKTAKE = gql`
  fragment FRAGMENT_STOCKTAKE on CompanyStocktake {
    createdAt
    updatedAt
    status
    remark
    warehouse {
      id
      name
    }
    referenceNo
    deviceId
    staff {
      id
      name
    }
    metadata {
      key
      value
    }
  }
`;

export const GET_ITEMS_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $query: String) {
    node(id: $id) {
      ... on CompanyStocktake {
        id
        items(limits: $limits, cursor: $cursor, sortBy: [{ field: "sku.keyword", order: ASC }], query: $query) {
          totalCount
          nextCursor
          nodes {
            id
            sku
            quantityDiff
            quantity
            currentStock
            productVariation {
              id
              sku
              combination {
                option
                name
              }
              product {
                id
                name
                sku
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_STOCKTAKE
    }
  }
  ${FRAGMENT_STOCKTAKE}
`;

export const SUBMIT_QUERY = gql`
  mutation ($id: ID, $input: StocktakeInput!) {
    result: stocktakeSet(id: $id, input: $input) {
      id
      ...FRAGMENT_STOCKTAKE
    }
  }
  ${FRAGMENT_STOCKTAKE}
`;

export const COMPLETE_QUERY = gql`
  mutation ($id: ID!, $shopId: ID!, $replace: Boolean) {
    stocktakeComplete(id: $id, shopId: $shopId, replace: $replace) {
      id
      updatedAt
      createdAt
      status
      output
    }
  }
`;

export const CANCEL_QUERY = gql`
  mutation ($id: ID!) {
    stocktakeCancel(id: $id) {
      id
      status
    }
  }
`;

export const MODIFY_ITEM_QUERY = gql`
  mutation MODIFY_ITEM_QUERY($id: ID!, $input: [StocktakeItemInput!]!) {
    stocktakeItemsSet(id: $id, input: $input) {
      id
    }
  }
`;
