import { Grid, InputAdornment, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import SelectComboProduct from '../../../components/SelectCombo/SelectComboProduct';
import { translate } from '../../../shared/translate';
import TrashButton from '../../../components/TrashButton';
import { binColor } from '../../../theme';

const BundleProductItem = ({ disabledDelete, value, disabled, onChange }) => {
  const { product, price } = value || {};
  const ref = useRef();

  return (
    <Grid container style={{ padding: 12, backgroundColor: '#eee', alignItems: 'center' }} spacing={1}>
      <Grid item xs={6}>
        <Typography variant="body1">
          {translate.product_name} <span style={{ color: binColor }}>{' *'}</span>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{translate.additional_price}</Typography>
      </Grid>
      <Grid item xs={6} ref={ref}>
        <SelectComboProduct
          value={product}
          disabled={disabled}
          required
          onChange={(e) => {
            onChange({ ...value, product: e });
          }}
        />
      </Grid>
      <Grid item xs={true}>
        <InputField
          type={'number'}
          value={price}
          disabled={disabled}
          required
          onChange={(e) => {
            onChange({ ...value, price: e?.target?.value });
          }}
          inputProps={{
            min: 0,
            step: 0.01,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <TrashButton disabled={disabledDelete || disabled} onClick={() => onChange()} />
      </Grid>
    </Grid>
  );
};

export default BundleProductItem;
