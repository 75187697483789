import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on ShopProduct {
        medias {
          #id
          src
          optimizedSrc(width: 256, height: 256)
        }
        subtitle
        name
        basePrice
        maxPrice
      }
    }
  }
`;
