import { gql } from '@apollo/client';

export const GET_MEMBER_METADATA_FIELDS = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        memberMetadataFields {
          key
        }
      }
    }
  }
`;

export const GET_MEMBER_METADATA_CHART_QUERY = (memberMetadataKeys) => {
  return gql`
    query ($id: ID!, $memberTierId: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!,) {
      node(id: $id) {
        id
        ... on Company {
          report {
            memberTierSales(id: $memberTierId, startedAt: $startedAt, startedThru: $startedThru,) {
              id
              ${memberMetadataKeys.map(
                (key, i) =>
                  `${'memberTierTopHits' + i}: memberTierTopHits(key:"${key}"){
                 name
                 value
                }`,
              )}
            }
          }
        }
      } 
    }
  `;
};

export const GET_QUERY = gql`
  query ($id: ID!, $memberTierId: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
    node(id: $id) {
      id
      ... on Company {
        report {
          memberTierSales(id: $memberTierId, startedAt: $startedAt, startedThru: $startedThru) {
            id
            name
            amount
            count
            orderCount
            avgPrice
            avgCount
            avgAmount
            newMemberCount
            memberCountChange
            memberCount
            salesTrends {
              name
              data {
                name
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SALE_RECORD_QUERY = gql`
  query (
    $id: ID!
    $memberTierId: ID!
    $startedAt: AWSDateTime!
    $startedThru: AWSDateTime!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        report {
          memberTierSales(id: $memberTierId, startedAt: $startedAt, startedThru: $startedThru) {
            id
            salesRecords(cursor: $cursor, limits: $limits, sortBy: $sortBy) {
              nextCursor
              totalCount
              nodes {
                createdAt
                shopName
                referenceNo
                count
                amount
              }
            }
          }
        }
      }
    }
  }
`;
