import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import StatusBadge from 'components/StatusBadge';
import { StockAdjustmentStatus } from '../../../shared/omniwe-types';
import MenuExport from './MenuExport';
import PrintOptionsButton from '../../../components/PrintOptionsButton';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/stock_out/new',
    getRowLink: ({ id }) => ({ pathname: '/stock_out/' + id }),
    fields: [
      {
        title: translate.created_at,
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
      },
      {
        title: translate.reference_no,
        fieldName: 'referenceNo',
        filterType: 'text',
        filter: 'referenceNo',
      },
      {
        title: translate.from_warehouse,
        value: ({ warehouse } = {}) => (warehouse || {}).name,
        filterType: 'warehouse',
        filter: 'warehouseId',
      },
      {
        title: translate.quantity,
        value: ({ totalQuantity }) => totalQuantity,
        type: 'number',
        width: 100,
      },
      {
        width: 120,
        title: translate.status,
        align: 'center',
        fieldName: 'status',
        type: 'option',
        options: Object.keys(StockAdjustmentStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={StockAdjustmentStatus} />,
          value: key,
        })),
        filter: 'status',
      },
      {
        title: translate.other_actions,
        fieldName: 'action',
        width: 1,
        noLink: true,
        render: ({ id }) => (
          <PrintOptionsButton
            id={id}
            partialHref={'print_stock_out'}
            orderedBy={translate.by_created_time || '按建立時間順序排序'}
          />
        ),
      },
    ],
    menus: [MenuExport],
    selectionMode: true,
  };

  getData = ({ node } = {}) => {
    const { stockAdjustments } = node || {};
    return stockAdjustments;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
}
