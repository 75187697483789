import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DraggableList from 'components/DraggableList';
import s3Upload from 'shared/s3Upload';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, IconButton } from '@material-ui/core';
import { Icon } from 'components/IconRender';
import Media from 'components/Media';
import Dropzone from 'react-dropzone';
import ImageFieldModal from 'components/FormPageMaker/Fields/ImageFieldModal';
import Loading from '../../Loading';
import LSkeleton from '../../LSkeleton';

export default class ImagesField extends Component {
  state = {
    queue: [],
    modalOpen: false,
  };

  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  };
  removeImage(index) {
    const { images, onChange } = this.props;
    images.splice(index, 1);
    onChange(images);
  }

  onFilesSelect = async (files) => {
    const { images, onChange } = this.props;

    const newFiles = files;
    if (newFiles.length < 1) return;
    try {
      const queue = await Promise.all(
        [...newFiles].map((newFile, i) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = (e) => {
              reject(e);
            };
            reader.readAsDataURL(newFile);
          });
        }),
      );
      this.setState({ queue });

      const newImages = await Promise.all(
        [...newFiles]
          .filter((_) => _)
          .map((file) => {
            return s3Upload(file);
          }),
      );
      onChange(images.concat(newImages));
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      this.setState({ queue: [] });
    }
  };

  render() {
    const { disabled, className = '', images = [], onChange, loading } = this.props,
      { queue, modalOpen } = this.state;

    if (loading)
      return (
        <Grid container direction={'row'} spacing={2}>
          {Array(3)
            .fill(undefined)
            .map((__, i) => (
              <Grid item key={i}>
                <LSkeleton variant={'rect'} width={100} height={100} />
              </Grid>
            ))}
        </Grid>
      );

    return (
      <div className={`py-2${className}`}>
        <div className={'d-flex flex-wrap'} style={{ margin: '-0.5rem' }}>
          <DraggableList
            className={'d-flex flex-wrap'}
            items={(images || []).map((url) => ({ url, id: url }))}
            onChange={(images) => {
              onChange(images.map(({ url }) => url));
            }}
            direction={'horizontal'}
            disabled={disabled}
            itemRender={({ item: { url }, index }) => {
              return (
                <ImagePreview
                  border={index === 0}
                  loading={false}
                  disabled={disabled}
                  image={url}
                  onRemoveClick={() => this.removeImage(index)}
                />
              );
            }}
          />
          {queue.map(
            (url, i) =>
              !!url && (
                <ImagePreview
                  key={i}
                  loading={true}
                  disabled={true}
                  image={url}
                  onRemoveClick={() => this.removeImage(i)}
                />
              ),
          )}
          <>
            <Dropzone onDrop={this.onFilesSelect} accept={['image/*', 'video/*']}>
              {({ getRootProps, getInputProps }) => (
                <Button
                  className="d-flex justify-content-center align-items-center m-2"
                  variant="outlined"
                  style={{ borderRadius: '1.5rem', width: 105, height: 105 }}
                  {...getRootProps()}
                  disabled={disabled}
                  onClick={this.toggleModal}
                >
                  <input
                    style={{ visibility: 'hidden', display: 'contents' }}
                    type="file"
                    multiple
                    {...getInputProps()}
                    disabled={disabled}
                  />
                  <Icon icon="faPlus" />
                </Button>
              )}
            </Dropzone>
            <ImageFieldModal
              open={modalOpen}
              onClose={this.toggleModal}
              onChange={(image) => {
                onChange(images.concat(image));
              }}
            />
          </>
        </div>
      </div>
    );
  }
}
export const ImagePreview = ({ loading, border, image, onRemoveClick, disabled }) => (
  <div className="text-center position-relative" style={{ width: 120, padding: '0.5rem' }}>
    <div
      className="img-thumbnail"
      style={{ borderRadius: '1.5rem', border: border ? '5px solid #60d0b9' : '1px solid #ddd' }}
    >
      <div className="embed-responsive embed-responsive-1by1" style={{ borderRadius: '1.25rem' }}>
        <div className="embed-responsive-item">
          <div className={'position-relative w-100 h-100'}>
            {image ? (
              <Media disabled={true} src={image} resizeMode={'cover'} className={'w-100 h-100'} />
            ) : (
              <div className={'h-100 d-flex justify-content-center align-items-center text-muted'}>
                <FontAwesomeIcon icon={faImage} size="4x" />
              </div>
            )}
            {loading && (
              <div
                className={'position-absolute w-100 h-100 d-flex align-items-center justify-content-center'}
                style={{ top: 0, left: 0, background: 'rgba(0,0,0,0.25)' }}
              >
                <Loading color={'text-white'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="d-flex justify-content-center">
      <IconButton
        type="button"
        color={'secondary'}
        disabled={disabled}
        style={{ width: 32, height: 32 }}
        onClick={onRemoveClick}
      >
        <Icon icon={'faTimes'} />
      </IconButton>
    </div>
  </div>
);
ImagesField.propTypes = {
  showUploadButton: PropTypes.bool,
  images: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
ImagesField.defaultProps = {
  showUploadButton: true,
};
