export const defaultSuggestions = [
  { label: '#id', value: '{{id}}' },
  { label: '#shop.name', value: '{{shop.name}}' },
  { label: '#referenceNo', value: '{{referenceNo}}' },
  { label: '#referenceNoLong', value: '{{referenceNoLong}}' },
  { label: '#count', value: '{{count}}' },
  { label: '#average', value: '{{average}}' },
  { label: '#remark', value: '{{remark}}' },
  { label: '#reason', value: '{{reason}}' },
  { label: '#createdAt', value: '{{createdAt}}' },
  { label: '#operator', value: '{{operator}}' },
  { label: '#salesman', value: '{{salesman}}' },
  { label: '#deviceId', value: '{{deviceId}}' },
  { label: '#table.name', value: '{{table.name}}' },
  { label: '#table.customers', value: '{{table.customers}}' },
  { label: '#table.previous', value: '{{table.previous}}' },
  {
    label: '#customer.name',
    value: '{{customer.name}}',
  },
  {
    label: '#customer.points',
    value: '{{customer.points}}',
  },
  {
    label: '#date.year',
    value: '{{date.year}}',
  },
  {
    label: '#date.month',
    value: '{{date.month}}',
  },
  {
    label: '#date.day',
    value: '{{date.day}}',
  },
  {
    label: '#date.date',
    value: '{{date.date}}',
  },
  {
    label: '#date.hour',
    value: '{{date.hour}}',
  },
  {
    label: '#date.minute',
    value: '{{date.minute}}',
  },
  {
    label: '#date.second',
    value: '{{date.second}}',
  },
  {
    label: '#customerPanelUrl',
    value: '{{customerPanelUrl}}',
  },
];
