import { client } from 'shared/apollo';
import { translate } from 'shared/translate';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, DELETE_TABLE, GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import TableSettingCard from './TableSettingCard';
import React from 'react';
import _ from 'lodash';
import { convertMetaArray, convertMetaObject } from '../../shared';
import ActivityIndicator from '../../components/ActivityIndicator';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: !!this.state.id ? POST_QUERY : CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    // tableSettingCardKey: '',
    tabs: [
      {
        name: translate.settings_venue,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                // maxLength: 25,
                name: 'name',
                required: true,
                md: 6,
                // block: true,
              },
              {
                label: translate.sort_index,
                type: 'number',
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                inputProps: {
                  step: 1,
                },
                md: 6,
              },
              {
                label: translate.landing_page_background_image,
                name: 'metadata.landingPageBackgroundImage',
                type: 'image',
                mediaStyle: () => ({
                  width: '100px',
                  height: '100px',
                }),
                actionStyle: () => ({
                  width: '100px',
                  height: '100px',
                  minWidth: '100px',
                  minHeight: '100px',
                  maxWidth: '100px',
                  maxHeight: '100px',
                  borderRadius: '1rem',
                }),
              },
              {
                label: translate.venue_hide_product_image,
                name: 'metadata.hideProductImage',
                type: 'checkbox',
                md: 'auto',
              },
              {
                label: translate.venue_use_simple_floorplan || '使用簡易樓面',
                name: 'metadata.useSimpleFloorPlan',
                type: 'checkbox',
                md: 'auto',
              },
              {
                label: translate.table_setting,
                render: ({ values, setFieldValue, ...props }) => {
                  const { media, tables } = values || {};
                  return (
                    <TableSettingCard
                      // key={this.state.tableSettingCardKey}
                      venueName={values.name}
                      media={media}
                      value={tables}
                      onChange={(e) => {
                        setFieldValue('tables', e);
                      }}
                      onChangeImage={(e) => {
                        setFieldValue('media', e);
                      }}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };

  // the submit function show delete progress
  submit = async ({ id, name, sortIndex, media, tables, metadata }) => {
    const {
      gql: { submit },
    } = this.state;

    const input = {
      shopId: !id ? localStorage.getItem('shopId') : undefined,
      name,
      sortIndex,
      media: { src: media },
      metadata: convertMetaArray(metadata),
      tables: tables.map((table) => ({
        ..._.omit(table, ['__typename', 'selected']),
        shapeData: table.shapeData.map((number) => Math.floor(number)),
        metadata: [...convertMetaArray(table?.metadata)],
      })),
    };

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });

    const deletePromises = [];
    const deleteCount = input.tables.reduce((count, table) => {
      const metadata = table.metadata || [];
      const isDelete = metadata.find((meta) => {
        return meta.key === 'isDelete' && meta.value === '1';
      });
      if (isDelete && isDelete.value === '1') {
        console.log('Table delete is true:', table.name);
        count++;
        const deletePromise = client.mutate({
          mutation: DELETE_TABLE,
          variables: {
            id: table.id,
          },
        });
        deletePromises.push(deletePromise);
      }
      return count;
    }, 0);

    let deletedCount = 0;
    for (const promise of deletePromises) {
      try {
        await promise;
        deletedCount++;
        // update progress
        const progress = Math.round((deletedCount / deleteCount) * 100);
        console.log(`Deleted ${deletedCount} of ${deleteCount} tables (${progress}%)`);
        ActivityIndicator.show(`Deleted ${deletedCount} of ${deleteCount} tables (${progress}%)`);
      } catch (error) {
        console.error(error);
      }
    }

    // return true && result.id;
    return true;
  };

  getFetchPolicy() {
    return 'network-only';
  }
  getInitialData(data) {
    const { node } = data || {},
      { id, name = '', sortIndex = 0, updatedAt, tables, media, metadata: _metadata } = node || {};
    const { nodes = [] } = tables || {};
    const { src } = media || {};
    const metadata = convertMetaObject(_metadata);
    return {
      id,
      name,
      sortIndex,
      updatedAt,
      media: src,
      metadata,
      tables: nodes.map((node) => {
        const metadata = convertMetaObject(node?.metadata);
        return {
          ...node,
          metadata: {
            active: metadata?.active ?? 1,
            ignoreTimeLimited: metadata?.ignoreTimeLimited ?? 1,
            ignoreMinimumConsumption: metadata?.ignoreMinimumConsumption ?? 1,
            ignoreMinimumConsumptionPer: metadata?.ignoreMinimumConsumptionPer ?? 1,
            ...metadata,
            isDelete: false,
          },
          shapeData: !!node.shapeData ? node.shapeData : [0, 0, 100, 100, 0],
          shape: 'POLYGON',
        };
      }),
    };
  }
}
