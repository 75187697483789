import React, { useState } from 'react';
import { Box, Collapse, Grid, Table, TableBody, TableRow, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { PriceFormat } from '../../../shared';
import TableColumnDefinition from './TableColumnDefinition';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import StyledTableCell from './StyledTableCell';
import RowEditUnitPrice from './RowEditUnitPrice';
import { textColor } from '../../../theme';

const RowBundleProductOrderItem = ({ item, orderStatus }) => {
  const [open, setOpen] = useState(false);
  const { sku, bundleProduct: _bundleProduct, unitPrice, quantity, media, id } = item || {},
    { bundleProduct, options } = _bundleProduct || {},
    { sku: bundleSku, name } = bundleProduct;
  const image = (media || {}).src;

  return (
    <>
      <TableRow hover style={{ cursor: 'pointer', backgroundColor: open && '#eee' }}>
        <StyledTableCell
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Grid container spacing={1}>
            <Grid item>
              {open ? <ExpandMore style={{ color: textColor }} /> : <ExpandLess style={{ color: textColor }} />}
            </Grid>
            <Grid item>
              <Typography>{bundleSku || sku}</Typography>
            </Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: 'center' }}>
          <img
            src={image || require('../../../assets/gray.jpg')}
            style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: 30 }}
            alt={`${name} image`}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ whiteSpace: 'pre' }}>{name}</Typography>
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: 'center' }}>
          {orderStatus === 'PENDING' && <RowEditUnitPrice item={item} id={id} />}
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ textAlign: 'right' }}>{PriceFormat(unitPrice - 0)}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ textAlign: 'center' }}>{quantity}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ textAlign: 'left' }}>{PriceFormat((unitPrice - 0) * quantity)}</Typography>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell colSpan={6} style={{ padding: 0 }}>
          <Collapse in={open}>
            <Table>
              <TableColumnDefinition />
              <TableBody>
                {options?.map(({ label, price, variation, product, remark }) => {
                  const { name, medias: productMedias } = product || {},
                    [{ src: productImage } = {}] = productMedias || [],
                    { medias, combination, sku } = variation || {},
                    [{ src: variationImage } = {}] = medias || [];
                  const image = variationImage || productImage;
                  return (
                    <TableRow hover>
                      <StyledTableCell>
                        <Box style={{ paddingLeft: 25 }}>
                          <Typography>{sku}</Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'center' }}>
                        <img
                          src={image || require('../../../assets/gray.jpg')}
                          style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: 30 }}
                          alt={`${name} image`}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography component={'span'} color={'secondary'}>
                          {label + ' : '}
                        </Typography>
                        <Typography component={'span'}>{name}</Typography>
                        <VariationCombinationDisplay combination={combination} remark={remark} />
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default RowBundleProductOrderItem;
