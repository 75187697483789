import React, { Fragment } from 'react';
import ToperCard from './ToperCard';
import TodaySaleCard from './TodaySaleCard';
import Last7DaysSaleCard from './Last7DaysSaleCard';
import Last30DaysSaleCard from './Last30DaysSaleCard';
import NotPaidOrdersCard from './NotPaidOrdersCard';
import MessageUnreadCard from './MessageUnreadCard';
import PendingDeliveryOrdersCard from './PendingDeliveryOrdersCard';
import _ from 'lodash';
import { NumberBriefFormat } from '../../shared';
import { translate } from '../../shared/translate';

const CardContainer = ({
  loading,
  report = {},
  companyReport = {},
  unreadOrderMessageCount,
  unreadGeneralMessageCount,
}) => {
  return (
    <Fragment>
      <TodaySaleCard loading={loading} amount={report.todayAmount} count={report.todayCount} />
      <Last7DaysSaleCard loading={loading} amount={report.last7dayAmount} count={report.last7dayCount} />
      <Last30DaysSaleCard loading={loading} amount={report.last30dayAmount} count={report.last30dayCount} />
      <NotPaidOrdersCard loading={loading} amount={report.unpaidOrderAmount} count={report.unpaidOrderCount} />
      <MessageUnreadCard
        loading={loading}
        orderCount={unreadOrderMessageCount}
        generalCount={unreadGeneralMessageCount}
      />
      <PendingDeliveryOrdersCard loading={loading} count={report.pendingOrderCount} />
      <ToperCard
        columns={[
          { label: translate.image, type: 'image', width: 100 },
          { label: translate.product_name, width: '*' },
          { label: translate.sold_count, width: 130, align: 'center' },
          { label: translate.top_ten_price, width: 120, align: 'center' },
        ]}
        rows={_.sortBy(companyReport?.topSalesProducts?.nodes || [], (o) => (o.count - 0 || 0) * -1).map(
          ({ name, count, amount, sku, medias }) => [
            medias?.[0]?.src,
            `${name}\n${sku}`,
            NumberBriefFormat(count),
            NumberBriefFormat(amount),
          ],
        )}
        title={translate.top_ten_best_selling}
      />
      <ToperCard
        columns={[
          { label: translate.image, type: 'image', width: 100 },
          { label: translate.product_name, width: '*' },
          { label: translate.sold_count, width: 130, align: 'center' },
          { label: translate.top_ten_views, width: 120, align: 'center' },
        ]}
        rows={_.sortBy(companyReport?.topViewProducts?.nodes || [], (o) => (o.pageView - 0 || 0) * -1).map(
          ({ name, pageView, count, medias, sku }) => [
            medias?.[0]?.src,
            `${name}\n${sku}`,
            NumberBriefFormat(count),
            NumberBriefFormat(pageView),
          ],
        )}
        title={translate.top_ten_most_viewed}
      />
    </Fragment>
  );
};

export default CardContainer;
