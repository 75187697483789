import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';
import { translate } from '../../shared/translate';

const ManualOrderConfirmField = ({ value, onChange, disabled }) => {
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={true} md={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{translate.manual_order_confirm}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              {translate.manually_change_order_confirmation_status_after_paid ||
                '當買家完成付款動作後，店員需手動更手訂單確認狀態'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SwitchField
          checked={!value}
          disabled={disabled}
          onChange={() => {
            onChange(!value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ManualOrderConfirmField;
