import { gql } from '@apollo/client';

export const FRAGMENT_RECEIVE_PURCHASE_ITEM = gql`
  fragment FRAGMENT_RECEIVE_PURCHASE_ITEM on CompanyReceivePurchaseItem {
    id
    quantity
    cost
    sku
    productVariation {
      id
      sku
      combination {
        name
        option
      }
      product {
        id
        sku
        name
        medias {
          #id
          src
          optimizedSrc(width: 256, height: 256)
        }
      }
    }
  }
`;

export const FRAGMENT_RECEIVE_PURCHASE = gql`
  fragment FRAGMENT_RECEIVE_PURCHASE on CompanyReceivePurchase {
    createdAt
    updatedAt
    status
    referenceNo
    completedAt
    remark
    staff {
      id
      name
    }
    warehouse {
      id
      name
    }
    items @client {
      nodes {
        ...FRAGMENT_RECEIVE_PURCHASE_ITEM
      }
    }
  }
  ${FRAGMENT_RECEIVE_PURCHASE_ITEM}
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_RECEIVE_PURCHASE
    }
  }
  ${FRAGMENT_RECEIVE_PURCHASE}
`;

export const SUBMIT_QUERY = gql`
  mutation ($input: ReceivePurchaseCreateInput!) {
    result: receivePurchaseCreate(input: $input) {
      id
      ...FRAGMENT_RECEIVE_PURCHASE
    }
  }
  ${FRAGMENT_RECEIVE_PURCHASE}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ReceivePurchaseUpdateInput!) {
    result: receivePurchaseUpdate(id: $id, input: $input) {
      id
      ...FRAGMENT_RECEIVE_PURCHASE
    }
  }
  ${FRAGMENT_RECEIVE_PURCHASE}
`;

export const COMPLETE_QUERY = gql`
  mutation ($id: ID!) {
    receivePurchaseComplete(id: $id) {
      id
      completedAt
      status
    }
  }
`;

export const CANCEL_QUERY = gql`
  mutation ($id: ID!) {
    receivePurchaseCancel(id: $id) {
      id
      status
    }
  }
`;

export const VOID_QUERY = gql`
  mutation ($id: ID!) {
    receivePurchaseVoid(id: $id) {
      id
      status
    }
  }
`;

export const IMPORT_QUERY = gql`
  mutation ($id: ID!, $shopId: ID!, $url: AWSURL!) {
    receivePurchaseImport(id: $id, shopId: $shopId, url: $url) {
      id
      status
    }
  }
`;
