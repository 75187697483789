import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import App from './App';
import './css/App.css';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/tailwind.output.css';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
