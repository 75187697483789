import React, { useState } from 'react';
import { translate } from '../../../shared/translate';
import { Table, withStyles, TableBody, TableContainer, TableRow, Box, Typography, Grid } from '@material-ui/core';
import { NumberFormat, PriceFormat } from '../../../shared';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { OrderCard } from '../../../components/OrderCard';
import TableColumnDefinition from './TableColumnDefinition';
import TableHeader from './TableHeader';
import RowSkeleton from './RowSkeleton';
import RowBundleProductOrderItem from './RowBundleProductOrderItem';
import RowOrderItem from './RowOrderItem';
import RowServiceBundleOrderItem from './RowServiceBundleOrderItem';
import StyledTableCell from './StyledTableCell';
import _, { sumBy } from 'lodash';
import TrashButton from '../../../components/TrashButton';
import { client } from '../../../shared/apollo';
import { gql } from '@apollo/client';
import confirmation from '../../../shared/confirmation';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import AddAdjustment from './AddAdjustment';

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #bbb',
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '1rem',
  },
}));

export default ({
  loading,
  values: {
    items: _notSortedItems = [],
    totalAdjustments = [],
    total,
    totalRefund,
    subtotal,
    status: orderStatus,
    id: orderId,
  },
}) => {
  const classes = useStyles();
  const _items = _.sortBy(_notSortedItems || [], (item) => item?.sku?.toUpperCase());

  const items = _items.filter((item) => !(item || {}).addOnProduct);
  const addOnProducts = _items.filter((item) => !!(item || {}).addOnProduct),
    addOnProductsAmount = addOnProducts.reduce((reducer, { quantity, unitPrice }) => reducer + quantity * unitPrice, 0);

  const totalQuantity = sumBy(items, 'quantity') + sumBy(addOnProducts, 'quantity');

  const [deleting, setDeleting] = useState(false);

  return (
    <Box mb={3}>
      <TableContainer component={OrderCard} className={classes.card}>
        <Table>
          <TableColumnDefinition />
          <TableHeader />
          <TableBody>
            {!!loading &&
              Array(3)
                .fill(undefined)
                .map((__, i) => <RowSkeleton key={i} />)}
            {items.map((item, i) => {
              if (item?.bundleProduct) return <RowBundleProductOrderItem key={i} item={item} orderStatus={orderStatus} />;
              else if (item?.serviceBundle) return <RowServiceBundleOrderItem key={i} item={item} orderStatus={orderStatus} />;
              return <RowOrderItem key={i} item={item} orderStatus={orderStatus} />;
            })}
            <StyledTableRow>
              <StyledTableCell colSpan={4} />
              <StyledTableCell>
                <Typography variant={'h6'} style={{ textAlign: 'right' }}>
                  {translate.sub_total}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant={'h6'} style={{ textAlign: 'center' }}>
                  {sumBy(items, 'quantity')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant={'h6'} style={{ textAlign: 'left' }}>
                  {loading ? <Skeleton /> : PriceFormat(subtotal - 0)}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
            {totalAdjustments
              .sort((a, b) => {
                return a.sortIndex - b.sortIndex;
              })
              .map(({ description, amount, id }, i) => (
                <TableRow key={i}>
                  <StyledTableCell colSpan={5} style={{ textAlign: 'right' }}>
                    <Grid container spacing={1} style={{ justifyContent: 'right', alignItems: 'center' }}>
                      {orderStatus === 'PENDING' && (
                        <Grid item>
                          <TrashButton
                            disabled={deleting}
                            onClick={async () => {
                              try {
                                if (await confirmation(`${translate.confirm_to_delete} ${description}`)) {
                                  setDeleting(true);
                                  await client.mutate({
                                    mutation: gql`
                                      mutation ($id: ID!) {
                                        orderAdjustmentRemove(id: $id) {
                                          id
                                          totalAdjustments {
                                            sortIndex
                                            amount
                                            description
                                          }
                                          total
                                          subtotal
                                        }
                                      }
                                    `,
                                    variables: { id },
                                  });
                                  setDeleting(false);
                                  toast.success(translate.update_success);
                                }
                              } catch (e) {
                                toast.error(errorParser(e));
                              } finally {
                                setDeleting(false);
                              }
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <Typography variant={'h6'} style={{ wordBreak: 'break-word' }}>
                          {description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell colSpan={1} />
                  <StyledTableCell>
                    <Typography variant={'h6'} style={{ textAlign: 'left' }}>
                      {PriceFormat(amount)}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ))}
            {orderStatus === 'PENDING' && (
              <TableRow>
                <StyledTableCell colSpan={4} />
                <StyledTableCell colSpan={3}>
                  <AddAdjustment orderId={orderId} loading={loading} />
                </StyledTableCell>
              </TableRow>
            )}
            {addOnProducts.length > 0 && (
              <>
                <TableRow style={{ backgroundColor: '#eee' }}>
                  <StyledTableCell colSpan={2} />
                  <StyledTableCell colSpan={4}>
                    <Typography variant={'h6'}>{translate.addon_products}</Typography>
                  </StyledTableCell>
                  <StyledTableCell colSpan={1} />
                </TableRow>
                {addOnProducts.map(
                  (item, i) =>
                    !!item && (
                      <TableRow key={i} style={{ backgroundColor: '#fafafa' }}>
                        <StyledTableCell colSpan={2} />
                        <StyledTableCell colSpan={3}>
                          <Typography style={{ whiteSpace: 'pre' }}>{item.description}</Typography>
                        </StyledTableCell>
                        <StyledTableCell colSpan={1}>
                          <Typography variant={'h6'} style={{ textAlign: 'center' }}>
                            {loading ? <Skeleton /> : NumberFormat(item.quantity)}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant={'h6'} style={{ textAlign: 'left' }}>
                            {loading ? <Skeleton /> : PriceFormat(item.quantity * item.unitPrice)}
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    ),
                )}
              </>
            )}
            <TableRow>
              <StyledTableCell colSpan={5} style={{ textAlign: 'right' }}>
                <Typography variant={'h6'}>{translate.total}</Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={1}>
                <Typography variant={'h6'} style={{ textAlign: 'center' }}>
                  {totalQuantity}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant={'h6'} style={{ textAlign: 'left' }}>
                  {loading ? <Skeleton /> : PriceFormat(total - 0)}
                </Typography>
              </StyledTableCell>
            </TableRow>
            {totalRefund > 0 && (
              <TableRow>
                <StyledTableCell colSpan={5}>
                  <Typography variant={'h6'} style={{ textAlign: 'right' }}>
                    {translate.total_refunded}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell colSpan={1} />
                <StyledTableCell>
                  <Typography variant={'h6'} style={{ textAlign: 'left' }}>
                    {PriceFormat(totalRefund - 0)}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
