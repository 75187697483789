import React, { useState } from 'react';
import { translate } from '../../../shared/translate';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { EXPORT_QUERY } from './query';
import { ReactComponent as ExportIcon } from '../../../assets/icon/export.svg';
import CommonIconButton from '../../../components/CommonIconButton';

const ExportButton = ({ id }) => {
  const [loading, setLoading] = useState(false);

  return (
    <CommonIconButton
      icon={ExportIcon}
      loading={loading}
      title={translate.export_stock_take_sheet}
      onClick={async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          await client.mutate({
            mutation: EXPORT_QUERY,
            variables: { id, shopId: localStorage.getItem('shopId') },
          });
          toast.success(translate.export_tips);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};

export default ExportButton;
