import { useState } from 'react';
import pLimit from 'p-limit';
const { REACT_APP_WEBHOOK_URL } = process.env;

const throttle = pLimit(4);
const useBedrockImages = () => {
  const [urls, setUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  return [
    { urls, loading, error },
    async ({ input = '', count = 1 } = {}) => {
      try {
        setLoading(true);
        setError(undefined);
        const urls = await getBedrockImages({ inputs: [input], count });
        setUrls(urls);
        return urls;
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
  ];
};

export default useBedrockImages;

export async function getBedrockImages({ inputs = [''], count = 1 } = {}) {
  const token = localStorage.getItem('token');

  const urls = await Promise.all(
    Array(count)
      .fill(undefined)
      .map((__, i) =>
        throttle(async () => {
          const res = await fetch(`${REACT_APP_WEBHOOK_URL}/bedrock/stability.stable-diffusion-xl-v1/invoke`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              text_prompts: [
                {
                  text: `${inputs[i] ?? inputs[0]}`,
                },
              ],
              width: 512,
              height: 512,
              seed: ~~(Math.random() * 10000),
              preset_styles: 'photographic',
            }),
          });
          return (await res.json())?.url;
        }),
      ),
  );

  return urls;
}
