import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query(
    $id: ID!
    $startedAt: AWSDateTime!
    $startedThru: AWSDateTime!
    $cursor: Int
    $limits: Int
    $query: String
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        report {
          totalMemberTierSales(startedAt: $startedAt, startedThru: $startedThru) {
            totalCount
            totalOrder
            totalAmount
            totalAvgPrice
            totalAvgCount
            totalMemberCount
            totalNewMemberCount
          }
          topMemberTierSales(
            startedAt: $startedAt
            startedThru: $startedThru
            cursor: $cursor
            limits: $limits
            sortBy: $sortBy
            query: $query
          ) {
            nextCursor
            totalCount
            nodes {
              id
              name
              amount
              count
              orderCount
              avgPrice
              avgCount
              avgAmount
              newMemberCount
              memberCount
              memberCountChange
            }
          }
        }
      }
    }
  }
`;
