import React from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, InputBase, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  search: {
    padding: '2px 0px 2px 6px',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    height: 32,
    boxShadow: 'none',
    border: '1px solid #ccc',
    width: '100%',
    borderRadius: 20,
  },
  searchIcon: {
    padding: 6,
    color: '#ccc',
  },
  inputRoot: {
    color: 'inherit',
    flex: 1,
    fontSize: 12,
  },
  inputInput: {
    marginLeft: 4,
    flex: 1,
  },
}));

export default {
  defaultHeight: 0,
  label: translate.search,
  icon: <Icon icon={'Search'} type={'material'} style={{ color: '#97a1aa' }} />,
  preview: function PreviewFC({ data, setEditData, height }) {
    const classes = useStyles();
    return (
      <Box height={+height || 'initial'}>
        <Paper className={classes.search}>
          <InputBase
            id="search"
            value={''}
            placeholder="輸入品牌或產品名稱"
            disabled={true}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
          <IconButton type="submit" disabled={true} className={classes.searchIcon} aria-label="search">
            <Icon icon={'Search'} type={'material'} />
          </IconButton>
        </Paper>
      </Box>
    );
  },
};
