import React, { useState } from 'react';
import { MenuItem, Tooltip } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import { actionWrapper } from '../../../../shared';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { GET_DELIVERY_NOTE_IDS } from './query';
import toast from '../../../../shared/toast';
import PrintLabelsDialog from '../../../../components/PrintOptionsButton/PrintLabelsDialog';

const MenuPrintLabel = {
  render: function RenderFC({ enable, i }) {
    const [groupByProviders, setGroupByProviders] = useState([]);
    const [visible, setVisible] = useState(false);
    const onClose = () => setVisible(false);

    return enable ? (
      <>
        <MenuItem
          key={i}
          disabled={!enable}
          onClick={async () => {
            await actionWrapper(async () => {
              const { nodes: deliveryNotes } = await this.infinityFetchAllSelected({
                query: GET_DELIVERY_NOTE_IDS,
                onProgress: (downloaded, total) => {
                  ActivityIndicator.show(`${translate.loading} ${downloaded}/${total}`);
                },
              });
              ActivityIndicator.hide();

              function groupByProvider(deliveryNotes) {
                const filteredNotes = deliveryNotes.filter(
                  (note) => note.provider === 'SF_EXPRESS' || note.provider.startsWith('SHIPANY'),
                );

                const groupedResults = filteredNotes.reduce((acc, curr) => {
                  let key = curr.provider.startsWith('SHIPANY') ? 'SHIPANY' : curr.provider;

                  if (!acc[key]) {
                    acc[key] = [];
                  }

                  acc[key].push(curr);

                  return acc;
                }, {});

                return Object.entries(groupedResults).map(([key, value]) => ({ [key]: value }));
              }

              setGroupByProviders(groupByProvider(deliveryNotes));
              setVisible(true);

              if (!groupByProvider(deliveryNotes)?.length)
                toast.error(
                  translate.error_print_waybill_labels ||
                    '本功能僅適用於已啟用第三方物流公司的用戶，請電郵 findus@omniwe.com 或 WhatsApp +852 9611 6631 與我們聯絡',
                );
            });
          }}
        >
          {translate.print_waybill_labels || '列印運單標籤'} ({this.getSelectionCount()})
        </MenuItem>
        {visible && !!groupByProviders?.length && (
          <PrintLabelsDialog open={visible} onClose={onClose} groupByProviders={groupByProviders} />
        )}
      </>
    ) : (
      <Tooltip
        title={translate.max_limit_bulk_print_waybill_labels || '批量列印運單標籤最多支援 25 張送貨單，請重新選擇'}
      >
        <div style={{ padding: 0 }}>
          <MenuItem key={i} disabled={true}>
            {translate.print_waybill_labels || '列印運單標籤'} ({this.getSelectionCount()})
          </MenuItem>
        </div>
      </Tooltip>
    );
  },
  enable: function () {
    const count = this.getSelectionCount();
    return count > 0 && count <= 25;
  },
};

export default MenuPrintLabel;
