import React from 'react';
import ControlCell from '../../ControlCell';
import BodyComponents from '../index';
import { translate } from '../../../../../shared/translate';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import CheckboxField from '../../../../../components/FormPageMaker/Fields/CheckboxField';
import HighlightFormRow from '../../HighlightFormRow';

export default {
  preview: ({ data, setEditData, onRemoveClick = (_) => _, onLeftClick, onRightClick, onChange = (_) => _ }) => {
    const { item, flex = 1, height = 200, visible = true, padding } = data || {};
    return (
      <>
        <ControlCell
          flex={flex}
          onEditClick={() => setEditData(data)}
          onRemoveClick={onRemoveClick}
          onLeftClick={onLeftClick}
          onRightClick={onRightClick}
          style={{
            opacity: visible ? 1 : 0.5,
          }}
          innerPadding={padding}
        >
          <BodyComponents
            data={item}
            height={height ? height - (padding || 0) * 2 : 0}
            setEditData={setEditData}
            onChange={(update) => {
              onChange({
                ...data,
                item: update,
              });
            }}
          />
        </ControlCell>
      </>
    );
  },
  edit: ({ value, onChange = (_) => _ }) => {
    const { flex = 1, height, visible = true, sortIndex, padding } = value || {};
    const update = (k, v) => {
      onChange({
        ...value,
        [k]: v,
      });
    };
    return (
      <>
        <HighlightFormRow highlight={value.visible !== undefined} title={translate.visible}>
          <CheckboxField checked={visible} onChange={(e) => update('visible', e.target.checked)} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.flex !== undefined} title={translate.layout_col_flex}>
          <InputField
            type={'number'}
            value={flex}
            onBlur={(e) => update('flex', e.target.value - 0)}
            onChange={(e) => update('flex', e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              step: 1,
              min: 0,
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.height !== undefined} title={translate.height}>
          <InputField
            type={'number'}
            value={height}
            placeholder={'Default: 200'}
            onBlur={(e) => update('height', e.target.value - 0)}
            onChange={(e) => update('height', e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              step: 1,
              min: 0,
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.padding !== undefined} title={translate.inner_padding}>
          <InputField
            type={'number'}
            value={padding}
            onBlur={(e) => update('padding', e.target.value - 0)}
            onChange={(e) => update('padding', e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              step: 1,
              min: 0,
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.sortIndex !== undefined} title={translate.sort_index}>
          <InputField
            type={'number'}
            value={sortIndex}
            onBlur={(e) => update('sortIndex', e.target.value - 0)}
            onChange={(e) => update('sortIndex', e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              step: 1,
              min: 0,
            }}
          />
        </HighlightFormRow>
      </>
    );
  },
};
