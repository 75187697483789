import React from 'react';
import { translate } from '../../shared/translate';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import FormRow from '../../components/FormRow';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import MobileField from '../../components/FormPageMaker/Fields/MobileField';
import CountryField from '../../components/FormPageMaker/Fields/CountryField';
import { ReactComponent as IconDragVertical } from '../../assets/icon/drag-vertical.svg';
import Dnd from '../../components/DND';
import { Icon } from '../../components/IconRender';
import TrashButton from '../../components/TrashButton';

export default ({
  title = translate.pick_up_point,
  addButtonText = translate.add_pick_up_point,
  loading,
  values: { provider, addresses = [] } = {},
  setFieldValue,
  isSubmitting,
  disabled,
}) => {
  return (
    !!provider &&
    provider === 'PICKUP' && (
      <>
        <Card elevation={0}>
          <CardContent>
            {!!title && (
              <Typography
                variant={'subtitle1'}
                style={{
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
            )}
            <StyledConfigList spacing={0}>
              <Dnd
                direction={'column'}
                spacing={3}
                disabled={disabled}
                items={addresses}
                onChange={(v) => setFieldValue('addresses', v)}
                renderItem={(_address, i, { nonDraggableProps }) => {
                  const address = _address || {};
                  const update = (key, value) => {
                    address[key] = value;
                    setFieldValue('addresses', addresses);
                  };
                  return (
                    <Box mx={1.5}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Icon type={'svg'} icon={IconDragVertical} style={{ cursor: 'pointer', marginTop: 36 }} />
                        </Grid>
                        <Grid item xs {...nonDraggableProps} key={address.id || i}>
                          <Box
                            mb={5}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                            }}
                          >
                            <Box>
                              <Grid container>
                                <Grid item xs={12} md={4}>
                                  <FormRow
                                    title={translate.name}
                                    style={{
                                      padding: '5px',
                                    }}
                                    dense={true}
                                  >
                                    <InputField
                                      disabled={isSubmitting}
                                      value={address.name}
                                      required={true}
                                      onChange={(e) => update('name', e.target.value)}
                                    />
                                  </FormRow>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormRow
                                    title={translate.contact_number}
                                    style={{
                                      padding: '5px',
                                    }}
                                    dense={true}
                                  >
                                    <MobileField
                                      disabled={isSubmitting}
                                      value={address.tel}
                                      onChange={(v) => update('tel', v)}
                                    />
                                  </FormRow>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormRow
                                    title={translate.email}
                                    style={{
                                      padding: '5px',
                                    }}
                                    dense={true}
                                  >
                                    <InputField
                                      type={'email'}
                                      disabled={isSubmitting}
                                      value={address.email}
                                      onChange={(e) => update('email', e.target.value)}
                                    />
                                  </FormRow>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormRow
                                    title={translate.address}
                                    required={true}
                                    style={{
                                      padding: '5px',
                                    }}
                                    dense={true}
                                  >
                                    <InputField
                                      disabled={isSubmitting}
                                      value={(address.lines || []).join('\n')}
                                      required={true}
                                      onChange={(e) => update('lines', (e.target.value || '').split('\n'))}
                                    />
                                  </FormRow>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormRow
                                    title={translate.city}
                                    style={{
                                      padding: '5px',
                                    }}
                                    dense={true}
                                  >
                                    <InputField
                                      disabled={isSubmitting}
                                      value={address.city}
                                      onChange={(e) => update('city', e.target.value)}
                                    />
                                  </FormRow>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormRow
                                    title={translate.country}
                                    required={true}
                                    style={{
                                      padding: '5px',
                                    }}
                                    dense={true}
                                  >
                                    <CountryField
                                      value={address.country}
                                      required={true}
                                      disabled={isSubmitting}
                                      onChange={(v) => update('country', v)}
                                    />
                                  </FormRow>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box style={{ display: 'flex' }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box
                                    mt={3}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <TrashButton
                                      onClick={() => {
                                        addresses.splice(i, 1);
                                        setFieldValue('addresses', addresses);
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }}
              >
                <AddButton
                  disabled={loading || isSubmitting}
                  onClick={() => {
                    if (!!addresses) {
                      addresses.push({});
                      setFieldValue('addresses', addresses);
                    } else {
                      setFieldValue('addresses', []);
                    }
                  }}
                  title={addButtonText}
                />
              </Dnd>
            </StyledConfigList>
          </CardContent>
        </Card>
      </>
    )
  );
};
