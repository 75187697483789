import React from 'react';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  aTR: {
    display: 'table-cell',
    textDecoration: 'none !important',
    verticalAlign: 'inherit',
    padding: 16,
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.background.default} !important`,
    borderRight: `1px solid ${theme.palette.background.default} !important`,
  },
  clickable: {
    cursor: 'pointer',
  },
}));

const ConnectionTableCell = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      zIndex: 0,
    },
  },
}))(TableCell);

export default ({ link, children, width, className, style, divider, onClick, ...props }) => {
  const classes = useStyles();

  if (link)
    return (
      <Link
        to={link}
        onClick={onClick}
        style={{ ...style, width }}
        className={`${classes.aTR} ${divider ? classes.divider : ''} ${onClick ? classes.clickable : ''} ${className}`}
        {...props}
      >
        {children}
      </Link>
    );
  else
    return (
      <ConnectionTableCell
        width={width}
        style={style}
        onClick={onClick}
        className={`${divider ? classes.divider : ''} ${onClick ? classes.clickable : ''} ${className}`}
        {...props}
      >
        {children}
      </ConnectionTableCell>
    );
};
