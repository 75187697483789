import { gql } from '@apollo/client';

export const FRAGMENT_SERVICE = gql`
  fragment FRAGMENT_SERVICE on ShopService {
    id
    metadata {
      key
      value
    }
    name
    serviceLocationName
    serviceLocations(limits: 99999) {
      nodes {
        id
        name
      }
    }
    availableUntil {
      years
      months
      weeks
      days
      hours
      minutes
      seconds
    }
    metadata {
      key
      value
    }
    code
    sortIndex
    productVariations {
      id
      sku
    }
    validSince {
      years
      months
      weeks
      days
      hours
      minutes
      seconds
    }
    validUntil {
      years
      months
      weeks
      days
      hours
      minutes
      seconds
    }
    daysOfWeek
    durationMins
    intervalMins
    reserveMins
    showStartTimeOnly
    startedAt
    startedThru
  }
`;

const FRAGMENT_SHOP_SERVICE_BUNDLE = gql`
  fragment FRAGMENT_SHOP_SERVICE_BUNDLE on ShopServiceBundle {
    id
    updatedAt
    createdAt
    active
    description
    hashtags
    ignoreStock
    quantity
    medias {
      src
    }
    metadata {
      key
      value
    }
    metaDescription
    metaKeywords
    metaTitle
    rewriteUri
    modifiers {
      name
      min
      max
      options {
        active
        name
        priceAdjustment
        suggestedRetailPrice
        minutesAdjustment
      }
    }
    name
    publishAt
    publishThru
    quantity
    sku
    slotRequiredAtCheckout
    primarySortIndex
    subtitle
    suggestedRetailPrice
    unitPrice
    validationStrategy
    collections(limits: 99999) {
      nodes {
        id
        name
        code
        parents {
          id
          name
        }
      }
    }
    services(limits: 99999) {
      nodes {
        ...FRAGMENT_SERVICE
      }
    }
  }
  ${FRAGMENT_SERVICE}
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_SHOP_SERVICE_BUNDLE
    }
  }
  ${FRAGMENT_SHOP_SERVICE_BUNDLE}
`;

export const CREATE_QUERY = gql`
  mutation ($input: ServiceBundleCreateInput!) {
    result: serviceBundleCreate(input: $input) {
      ...FRAGMENT_SHOP_SERVICE_BUNDLE
    }
  }
  ${FRAGMENT_SHOP_SERVICE_BUNDLE}
`;

export const POST_QUERY = gql`
  mutation ($id: ID!, $input: ServiceBundleUpdateInput!) {
    result: serviceBundleUpdate(id: $id, input: $input) {
      ...FRAGMENT_SHOP_SERVICE_BUNDLE
    }
  }
  ${FRAGMENT_SHOP_SERVICE_BUNDLE}
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    result: serviceBundleDelete(id: $id) {
      id
    }
  }
`;
