import React from 'react';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/Logo_KPay.png')} alt="kpay" />,
    label: 'KPAY 線下支付',
    value: 'KPAY_POS',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'APPID',
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
            afterChanged: (v) => {
              actions.setFieldValue('metadata.loginId', v);
            },
          },
          {
            label: 'APP Secret',
            type: 'text',
            name: 'metadata.loginPWD',
            required: true,
            maxLength: 255,
            inputProps: {
              type: 'password',
            },
            afterChanged: (v) => {
              actions.setFieldValue('secret', v);
            },
          },
        ]}
      />
    );
  },
  type: 'PAYMENT',
};
