import { gql } from '@apollo/client';

const FRAGMENT_PAYMENT_METHOD = gql`
  fragment FRAGMENT_PAYMENT_METHOD on PaymentMethod {
    id
    createdAt
    updatedAt
    active
    description
    enabled
    metadata {
      key
      value
    }
    name
    provider
    credential {
      id
      name
    }
    sortIndex
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_PAYMENT_METHOD
    }
  }
  ${FRAGMENT_PAYMENT_METHOD}
`;

export const CREATE_QUERY = gql`
  mutation ($input: PaymentMethodCreateInput!) {
    paymentMethodCreate(input: $input) {
      ...FRAGMENT_PAYMENT_METHOD
    }
  }
  ${FRAGMENT_PAYMENT_METHOD}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: PaymentMethodUpdateInput!) {
    paymentMethodUpdate(id: $id, input: $input) {
      ...FRAGMENT_PAYMENT_METHOD
    }
  }
  ${FRAGMENT_PAYMENT_METHOD}
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    paymentMethodDelete(id: $id) {
      id
    }
  }
`;
