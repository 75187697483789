import React from 'react';
import { IMPORT_QUERY } from './query';
import CommonImportButton from '../../components/CommonImportButton';
import { translate } from '../../shared/translate';

const StockOutImportButton = ({ id }) => {
  return (
    <CommonImportButton
      id={id}
      query={IMPORT_QUERY}
      name={translate.bulk_stock_out_import}
      tips={[
        translate.bulk_import_product_data_tips_one,
        translate.if_not_exist_will_create_new,
        translate.if_SKU_exist_will_overwrite,
      ]}
      demoFile={'https://omniwe-assets-pro.s3.ap-east-1.amazonaws.com/nn2S0V9DaopKYJdxiuiK8.csv'}
    />
  );
};

export default StockOutImportButton;
