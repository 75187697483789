import { gql } from '@apollo/client';

export const FRAGMENT_CASH_VOUCHER = gql`
  fragment FRAGMENT_CASH_VOUCHER on CompanyCashVoucher {
    id
    createdAt
    updatedAt
    name
    description
    unitPrice
    singleVoucherValue
    numberOfVoucher
    thumbnail {
      src
      optimizedSrc(width: 256, height: 256)
    }
    active
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $query: String) {
    node(id: $id) {
      id
      ... on Company {
        cashVouchers(cursor: $cursor, limits: $limits, sortBy: $sortBy, query: $query) {
          totalCount
          nextCursor
          nodes {
            ...FRAGMENT_CASH_VOUCHER
          }
        }
      }
    }
  }
  ${FRAGMENT_CASH_VOUCHER}
`;
