import { gql } from '@apollo/client';

export const FRAGMENT_SHOP_BUNDLE_PRODUCT = gql`
  fragment ShopBundleProduct on ShopBundleProduct {
    name
    sku
    barcode
    description
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
      alt
    }
    salesChannels
    active
    display
    hashtags
    cost
    subtitle
    metaTitle
    metaDescription
    metaKeywords
    rewriteUri
    publishAt
    weight
    publishThru
    primarySortIndex
    quantity(warehouseId: $warehouseId)
    ignoreStock
    collections(limits: 99999) {
      nodes {
        id
        name
        code
        parents {
          id
          name
        }
      }
    }
    suggestedRetailPrice
    sections {
      label
      description
      options {
        id
        price
        product {
          id
          name
          sku
        }
      }
    }
    unitPrice
    createdAt
    updatedAt
    rewriteUri
    shippingZones {
      id
      name
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOP_BUNDLE_PRODUCT}
  query ($id: ID!, $warehouseId: ID) {
    node(id: $id) {
      id
      ...ShopBundleProduct
      ... on ShopBundleProduct {
        shop {
          id
          company {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_QUERY = gql`
  ${FRAGMENT_SHOP_BUNDLE_PRODUCT}
  mutation bundleProductCreate($input: BundleProductCreateInput!, $warehouseId: ID) {
    result: bundleProductCreate(input: $input) {
      id
      ...ShopBundleProduct
    }
  }
`;

export const UPDATE_QUERY = gql`
  ${FRAGMENT_SHOP_BUNDLE_PRODUCT}
  mutation bundleProductUpdate($id: ID!, $input: BundleProductUpdateInput!, $warehouseId: ID) {
    result: bundleProductUpdate(id: $id, input: $input) {
      id
      ...ShopBundleProduct
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation bundleProductDelete($id: ID!) {
    result: bundleProductDelete(id: $id) {
      id
    }
  }
`;
