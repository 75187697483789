import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import AIButton from '../../components/AIButton';
import { translate } from 'shared/translate';
import useBreakPoint from '../../components/useBreakPoint';
import AIForm from './AIForm';
import InputField from '../../components/FormPageMaker/Fields/InputField';

const useStyles = makeStyles((theme) => ({
  textInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: '#fff',
    },
  },
}));

const AIContainer = () => {
  const classes = useStyles();
  const breakPoint = useBreakPoint();
  const isUnderMD = ['xs', 'sm'].includes(breakPoint);
  const [aiDescription, setAiDescription] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  return (
    <>
      <style>{`
    .containerStyle ${css}
    `}</style>
      {!!isSubmit ? (
        <Box className={'containerStyle'} key={aiDescription} style={{ textAlign: 'left' }}>
          <AIForm aiDescription={aiDescription} />
        </Box>
      ) : (
        <Box py={20} px={isUnderMD ? 5 : 20} className={'containerStyle'} style={{ textAlign: 'center' }}>
          <Box>
            <Typography variant="h2" style={{ fontWeight: 'bold', color: '#fff' }}>
              {translate.ai_generated_product_info}
            </Typography>
          </Box>
          <Box mt={3} mb={6}>
            <Typography variant="h6" style={{ color: '#fff', fontWeight: 'normal' }}>
              {translate.ai_generated_product_description}
            </Typography>
          </Box>
          <Box>
            <form
              onSubmit={() => {
                if (!!aiDescription) setIsSubmit(true);
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs="12" md="true">
                  <InputField
                    required={true}
                    value={aiDescription}
                    onChange={(e) => setAiDescription(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && !!aiDescription) setIsSubmit(true);
                    }}
                    variant={'outlined'}
                    className={classes.textInput}
                    placeholder={translate.pls_enter_product_details}
                    style={{ width: '100%' }}
                    inputProps={{
                      style: {
                        height: 30,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs="12" md="auto">
                  <AIButton style={{ zIndex: 10 }}>{translate.generate_product}</AIButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AIContainer;

const css = `{
  background: -moz-linear-gradient(45deg, #25657f 0%, #30506e 29%, #20687d 66%, #366984 100%);
  background: -webkit-linear-gradient(45deg, #25657f 0%,#30506e 29%,#20687d 66%,#366984 100%);
  background: linear-gradient(45deg, #25657f 0%,#30506e 29%,#20687d 66%,#366984 100%);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
-moz-animation: Gradient 15s ease infinite;
animation: Gradient 15s ease infinite;
/*min-height: calc(100vh - 2rem);*/
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: space-evenly;
overflow: hidden;
position: relative; 
}

.containerStyle::before, 
.containerStyle::after {
content: "";
width: 70vmax;
height: 70vmax;
position: absolute;
background: rgba(255, 255, 255, 0.07);
left: -20vmin;
top: -20vmin;
animation: morph 15s linear infinite alternate, spin 20s linear infinite;
z-index: 1;
will-change: border-radius, transform;
transform-origin: 55% 55%;
pointer-events: none; 
}

.containerStyle::after {
  width: 70vmin;
  height: 70vmin;
  left: auto;
  right: -10vmin;
  top: auto;
  bottom: 0;
  animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
  transform-origin: 20% 20%; 
}

@-webkit-keyframes Gradient {
0% {
  background-position: 0 50%
}
50% {
  background-position: 100% 50%
}
100% {
  background-position: 0 50%
}
}

@-moz-keyframes Gradient {
0% {
  background-position: 0 50%
}
50% {
  background-position: 100% 50%
}
100% {
  background-position: 0 50%
}
}

@keyframes Gradient {
0% {
  background-position: 0 50%
}
50% {
  background-position: 100% 50%
}
100% {
  background-position: 0 50%
}
}

@keyframes morph {
0% {
  border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; }
100% {
  border-radius: 40% 60%; } 
}

@keyframes spin {
to {
  transform: rotate(1turn); 
} 
}
.st0{display:none;}
.st1{display:inline;}
.st2{opacity:0.29;}
.st3{fill:#366984;}
.st4{clip-path:url(#SVGID_2_);fill:#366984;}
.st5{clip-path:url(#SVGID_4_);}
.st6{clip-path:url(#SVGID_6_);}
.st7{clip-path:url(#SVGID_8_);}
.st8{clip-path:url(#SVGID_10_);}
.st9{fill:none;}
.st10{clip-path:url(#SVGID_12_);}
.st11{opacity:0.7;}
.st12{clip-path:url(#SVGID_14_);}
.st13{opacity:0.2;}
.st14{clip-path:url(#SVGID_16_);}
.st15{opacity:0.3;fill:#366984;enable-background:new    ;}`;
