import { gql } from '@apollo/client';
import { client } from '../../apollo';

export default async function (source, { cursor = 0, limits, startedAt, startedThru, shopId }) {
  const id = source?.id;

  // row - each time slot
  const { data: { node: slotNodes } = {} } = await client.query({
    query: gql`
      query ($id: ID!, $shopId: ID, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
        node(id: $id) {
          id
          ... on Company {
            report {
              topShopSales(shopId: $shopId, startedAt: $startedAt, startedThru: $startedThru) {
                nodes {
                  id
                  amount
                  count
                  orderCount
                  avgPrice
                  avgCount
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      id,
      shopId,
      startedAt,
      startedThru,
    },
  });
  const { report: slotReport } = slotNodes || {},
    { topShopSales } = slotReport || {},
    { nodes: topShopSalesNodes } = topShopSales || {};

  // title - sum of time slots
  const { data: { node: totalNode } = {} } = await client.query({
    query: gql`
      query ($id: ID!, $shopId: ID, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
        node(id: $id) {
          id
          ... on Company {
            report {
              totalShopSales(shopId: $shopId, startedAt: $startedAt, startedThru: $startedThru) {
                totalCount
                totalOrder
                totalAmount
                totalAvgPrice
                totalAvgCount
              }
            }
          }
        }
      }
    `,
    variables: {
      id,
      shopId,
      startedAt,
      startedThru,
    },
  });
  const { report } = totalNode || {},
    { totalShopSales } = report || {};

  return {
    slotNodes: topShopSalesNodes,
    totalNode: totalShopSales,
    totalCount: slotNodes.report.topShopSales.nodes.length,
  };
}
