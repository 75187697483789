import { gql } from '@apollo/client';

export const GET_CHANNEL_PLAYERS = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Channel {
        id
        type
        code
        players {
          id
          email
          name
          picture
        }
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Customer {
        id
        picture
        email
        phoneNumber
        hashtags
        metadata {
          key
          value
        }
      }
    }
  }
`;
