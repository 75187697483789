import React from 'react';
import { translate } from 'shared/translate';
import { FormRow } from 'components/FormRow';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import StatusBadge from '../../../components/StatusBadge';
import { OrderCard, OrderCardContent, OrderCardHeader } from '../../../components/OrderCard';
import moment from 'moment';
import CancelButton from './CancelButton';
import ConfirmButton from './ConfirmButton';
import PrintButton from './PrintButton';
import DeliveryNoteButton from './DeliveryNoteButton';
import { InvoiceStatus, ShippingStatus } from '../../../shared/omniwe-types';
import LSkeleton from '../../../components/LSkeleton';
import { NumberFormat } from '../../../shared';
import MemberPointsDeductButton from './MemberPointsDeductButton';
import { Link } from 'react-router-dom';
import EllipsisTypography from '../../../components/EllipsisTypography';

export default (props) => {
  const {
    loading,
    values: {
      customer,
      // shop,
      cancelReason,
      referenceNo,
      status,
      shippingStatus,
      paymentStatus,
      createdAt,
      deductibleMemberPoints,
      deliveryNotes = [],
      cashier,
      staff,
      // shippingProvider,
    },
    minHeight,
  } = props;
  // const { autoConfirm } = shop || {};
  // const isInSitu = (shippingProvider || {}).provider === 'IN_SITU';

  return (
    <OrderCard style={{ minHeight: minHeight }}>
      <OrderCardHeader
        title={translate.order_data}
        action={
          <Grid container spacing={1}>
            {!~['CANCELLED'].indexOf(status) && (
              <Grid item>
                <CancelButton {...props} />
              </Grid>
            )}
            {!!~['PENDING'].indexOf(status) && (
              <Grid item>
                <ConfirmButton {...props} />
              </Grid>
            )}
            <Grid item>
              <PrintButton {...props} />
            </Grid>
            {status !== 'PENDING' ? (
              <Grid item>
                <DeliveryNoteButton {...props} />
              </Grid>
            ) : null}
          </Grid>
        }
      />
      <OrderCardContent>
        <Grid container>
          <FormRow md={6} title={translate.order_status + ':'} direction={'row'} spacing={3} alignItems={'center'}>
            {loading ? <Skeleton width={100} /> : <StatusBadge status={_.upperCase(status)} />}
          </FormRow>
          <FormRow md={6} title={translate.order_number + ':'} direction={'row'} spacing={3} alignItems={'center'}>
            <Typography>{loading ? <Skeleton width={100} /> : referenceNo || '-'}</Typography>
          </FormRow>
          <FormRow
            md={6}
            title={translate.order_transaction_status + ':'}
            direction={'row'}
            spacing={3}
            alignItems={'center'}
          >
            {loading ? (
              <Skeleton width={100} />
            ) : (
              <StatusBadge statusObject={InvoiceStatus} status={_.upperCase(paymentStatus)} />
            )}
          </FormRow>
          <FormRow md={6} title={translate.order_date + ':'} direction={'row'} spacing={3} alignItems={'center'}>
            <Typography>
              {loading ? <Skeleton width={100} /> : createdAt ? moment(createdAt).format('DD/MM/YYYY HH:mm') : '-'}
            </Typography>
          </FormRow>
          <FormRow md={6} title={translate.delivery_status + ':'} direction={'row'} spacing={3} alignItems={'center'}>
            {loading ? (
              <Skeleton width={100} />
            ) : (
              <StatusBadge statusObject={ShippingStatus} status={_.upperCase(shippingStatus)} />
            )}
          </FormRow>
          <FormRow
            md={6}
            title={translate.rewarded_member_points + ':'}
            direction={'row'}
            spacing={3}
            alignItems={'center'}
          >
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item>
                <Typography>{loading ? <LSkeleton width={50} /> : NumberFormat(deductibleMemberPoints)}</Typography>
              </Grid>
              <Grid item>
                <MemberPointsDeductButton {...props} />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow md={6} title={translate.order_customer + ':'} direction={'row'} spacing={3} alignItems={'center'}>
            {loading ? (
              <Skeleton width={100} />
            ) : customer ? (
              <Link to={`/customers/${customer.id}`} target={'_blank'}>
                <EllipsisTypography>
                  {customer.email}
                  {customer.phoneNumber ? ` (${customer.phoneNumber})` : ''}
                </EllipsisTypography>
              </Link>
            ) : (
              <Typography>Guest</Typography>
            )}
          </FormRow>
          {(() => {
            const trackingNumbers = (deliveryNotes || []).map((dn) => dn.trackingNumber).filter((_) => _);
            return (
              <FormRow md={6} title={translate.tracking_number + ':'} direction={'row'} spacing={3}>
                {loading ? (
                  <Skeleton width={100} />
                ) : trackingNumbers.length > 0 ? (
                  <a target={'_blank'} href={`https://t.17track.net/en#nums=${trackingNumbers.join(',')}`}>
                    {trackingNumbers.map((trackingNumber, i) => (
                      <Typography key={i}>{trackingNumber}</Typography>
                    ))}
                  </a>
                ) : (
                  <Typography>-</Typography>
                )}
              </FormRow>
            );
          })()}
          {!!cancelReason && (
            <FormRow md={12} title={translate.cancel_reason + ':'} direction={'row'} spacing={3}>
              <EllipsisTypography>{cancelReason}</EllipsisTypography>
            </FormRow>
          )}
          {!!cashier && (
            <FormRow md={6} title={translate.cashier + ':'} direction={'row'} spacing={3}>
              <Typography
                style={{
                  whiteSpace: 'pre',
                }}
              >
                {cashier}
              </Typography>
            </FormRow>
          )}
          {!!staff && (
            <FormRow md={6} title={translate.operator + ':'} direction={'row'} spacing={3}>
              <Typography
                style={{
                  whiteSpace: 'pre',
                }}
              >
                {staff?.name}
              </Typography>
            </FormRow>
          )}
        </Grid>
        {/*{!!remark && (*/}
        {/*  <FormRow direction={'row'} spacing={3} title={translate.remark + ':'} alignItems={'center'}>*/}
        {/*    <Typography*/}
        {/*      style={{*/}
        {/*        whiteSpace: 'break-spaces',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {remark}*/}
        {/*    </Typography>*/}
        {/*  </FormRow>*/}
        {/*)}*/}
      </OrderCardContent>
    </OrderCard>
  );
};
