import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { parseConnection, parseOSSQuery } from '../../shared';
import _ from 'lodash';
import SelectCombo from './index';

const SEARCH_QUERY = gql`
  query ($id: ID!, $query: String, $limits: Int, $cursor: Int) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        variations(query: $query, limits: $limits, cursor: $cursor) {
          nextCursor
          nodes {
            id
            sku
            product {
              id
              sku
              name
            }
            combination {
              name
              option
            }
            medias {
              #id
              src
              optimizedSrc(width: 256, height: 256)
            }
            active
            barcodes
          }
        }
      }
    }
  }
`;

const SelectComboVariation = ({ productIds, ...props }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { loading, data, fetchMore } = useQuery(SEARCH_QUERY, {
      variables: {
        id: localStorage.getItem('shopId'),
        query: [
          productIds?.length ? `productId:(${productIds.map((id) => `"${id}"`).join(' ')})` : '',
          !!search && parseOSSQuery(search, ['sku', 'name', 'productSkus.keyword', 'barcodes.keyword']),
        ]
          .filter(Boolean)
          .join(' AND '),
        sortBy: [{ field: 'updatedAt', order: 'DESC' }],
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);

  function getConnection(data) {
    return parseConnection(data?.node?.variations);
  }

  return (
    <SelectCombo
      onOpen={setOpen}
      getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
      onSearchChange={setSearch}
      getOptionLabel={(option) => {
        return [
          option?.sku,
          option?.product?.name,
          option?.product?.sku && `(${option?.product?.sku})`,
          ...(option?.combination || [])?.map(({ name, option }) => `${name}: ${option}`),
          ...(option?.barcodes || []),
        ]
          .filter(Boolean)
          .join(' ');
      }}
      options={nodes}
      loading={loading}
      onScrollBottom={async () => {
        if (!!nextCursor && !loading)
          await fetchMore({
            variables: {
              cursor: nextCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              const fetchMoreConnection = getConnection(fetchMoreResult),
                { nodes: fetchMoreNodes } = fetchMoreConnection,
                { nodes: prevNodes } = getConnection(prevResult);
              fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

              return {
                node: {
                  ...(fetchMoreResult.node || {}),
                  variations: fetchMoreConnection,
                },
              };
            },
          });
      }}
      {...props}
    />
  );
};

export default SelectComboVariation;
