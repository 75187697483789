import { gql } from '@apollo/client';

const CompanyShopInfoFragment = gql`
  fragment CompanyShopInfoFragment on CompanyShop {
    id
    hostname
    type
    currency
    timezone
    customDomain
    contactEmails(limits: 9999) {
      nodes {
        id
        email
        verified
        type
      }
    }
    name
    icoMedia {
      src
      optimizedSrc(width: 48, height: 48)
    }
    logoMedia {
      src
      optimizedSrc(width: 256)
    }
    rasterLogoMedia {
      src
      optimizedSrc(width: 256, height: 256)
    }
    locale
    updatedAt
    address {
      person
      tel
      email
      lines
      city
      country
    }
    stockWarehouse {
      id
      name
    }
    reservationWarehouse {
      id
      name
    }
    transferWarehouse {
      id
      name
    }
    returnWarehouse {
      id
      name
    }
    remarks
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ...CompanyShopInfoFragment
    }
  }
  ${CompanyShopInfoFragment}
`;

export const POST_QUERY = gql`
  mutation companyShopSet($id: ID!, $input: CompanyShopInput!) {
    companyShopSet(id: $id, input: $input) {
      ...CompanyShopInfoFragment
    }
  }
  ${CompanyShopInfoFragment}
`;
