import React from 'react';
import { Typography } from '@material-ui/core';

const ServiceBundleItemRow = ({ item }) => {
  const { serviceBundle } = item ?? {};

  return <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>{serviceBundle?.serviceBundle?.name}</Typography>;
};

export default ServiceBundleItemRow;
