import React from 'react';
import _ from 'lodash';
import SelectCombo from './index';
import { translate as t } from '../../shared/translate';

const SelectComboCountry = ({ limitedCountries, filteredCountries = [], ...props }) => {
  const options = Object.keys(t.countries)
    .map((country) => {
      if (!!limitedCountries && !~limitedCountries.indexOf(country)) return null;
      if (~filteredCountries.indexOf(country)) return null;
      return country;
    })
    .filter((_) => _);

  return (
    <SelectCombo
      getOptionSelected={(a, b) => a === b}
      getOptionLabel={(option) => `${option} ${t.countries[option]}`}
      options={_.sortBy(options, (value) => ['SGP', 'TWN', 'CHN', 'MAC', 'HKG'].indexOf(value) * -1)}
      {...props}
    />
  );
};

export default SelectComboCountry;
