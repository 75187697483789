import { gql } from '@apollo/client';
import { FRAGMENT_PRINTABLE_ORDER_ITEM } from '../../../PrintOrder/query';

export const GET_ORDERS = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: OrderFilterInput) {
    node(id: $id) {
      id
      ... on Company {
        id
        orders(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            createdAt
            updatedAt
            shippingProvider {
              id
              name
            }
            shippingAddress {
              name
              person
              tel
              email
              lines
              country
            }
            billingAddress {
              person
              tel
              email
              lines
              country
            }
            referenceNo
            subtotal
            total
            remark
            taxFee
            shippingFee
            shopDiscount
            couponDiscount
            totalPaid
            totalRefund
            shop {
              id
              name
              locale
              logoMedia {
                src
                optimizedSrc(width: 256, height: 256)
              }
              hostname
              customDomain
            }
            totalAdjustments {
              sortIndex
              amount
              description
            }
            items {
              ...FRAGMENT_PRINTABLE_ORDER_ITEM
            }
            deliveryNotes {
              nodes {
                id
                status
                trackingNumber
              }
            }
            invoices {
              nodes {
                id
                status
                paymentMethodV2 {
                  id
                  name
                  provider
                }
                credential {
                  id
                  name
                }
                total
                totalPaid
                totalRefund
                change
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_PRINTABLE_ORDER_ITEM}
`;
