import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { CHECKOUT_QUERY, CHECKOUT_ITEM_CREATE } from './query';
import { client } from '../../shared/apollo';
import Form from './form';
import uuid from 'react-uuid';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      checkout: CHECKOUT_QUERY,
    },
    tabs: [
      {
        name: translate.new_order,
        cards: [Form],
      },
    ],
  };

  submit = async (values, { setFieldValue }) => {
    const items = values.items.filter((item) => {
      if (!item?.quantity) return false;
      switch (item?.itemType) {
        case 'product':
          if (!item?.product) return false;
          else if (!item?.variation) return false;
          return true;
        case 'bundleProduct':
          if (!item?.bundleProduct) return false;
          else if (!item?.sections) return false;
          else if (item?.sections?.length !== item?.bundleProduct?.sections?.length) return false;
          else if (item?.sections?.find((section) => !section?.variation)) return false;
          return true;
      }
    });
    if (items.length === 0) throw new Error('Error: Empty Items');

    const {
        data: { checkoutSet },
      } = await client.mutate({
        mutation: CHECKOUT_QUERY,
        variables: {
          input: {
            shopId: localStorage.getItem('shopId'),
            customerId: values?.customer?.id,
            remark: values.remarks,
          },
        },
      }),
      checkoutId = checkoutSet?.id;

    for (const item of items) {
      switch (item?.itemType) {
        case 'product':
          await client.mutate({
            mutation: CHECKOUT_ITEM_CREATE,
            variables: {
              id: checkoutId,
              input: [
                {
                  quantity: item?.quantity,
                  productVariationId: item.variation.id,
                  description: (item?.product ?? {}).name,
                },
              ],
              operator: 'SET',
            },
          });
          break;
        case 'bundleProduct':
          await client.mutate({
            mutation: CHECKOUT_ITEM_CREATE,
            variables: {
              id: checkoutId,
              input: [
                {
                  quantity: item?.quantity,
                  description: (item?.product ?? {}).name,
                  bundleProduct: {
                    id: item?.bundleProduct?.id,
                    options: item?.sections?.map((section) => ({
                      id: section?.optionId,
                      variationId: section?.variation?.id,
                    })),
                  },
                },
              ],
            },
          });
          break;
      }
    }
    const { hostname, customDomain } = checkoutSet.shop || {};
    setFieldValue('checkoutURL', `https://${customDomain || hostname}/checkout_pickup/${checkoutId}`);
  };

  getInitialData() {
    return {
      customer: undefined,
      items: [],
      remarks: '',
      checkoutURL: '',
    };
  }

  renderFormToolBar() {
    return null;
  }
}
