import React, { useState } from 'react';
import { Tooltip, Typography } from '@material-ui/core';

const EllipsisTypography = ({ title, children, ...props }) => {
  const [ellipsis, setEllipsis] = useState(!!title);

  const toolTipsContent = getToolTipContent();
  if (!toolTipsContent && !title)
    return (
      <Typography className={'ellipsis-typography'} {...props}>
        {children}
      </Typography>
    );

  if (!ellipsis)
    return (
      <Typography
        ref={(ref) => {
          if (ref) {
            if (ref.scrollWidth > ref.clientWidth) {
              setEllipsis(true);
            }
          }
        }}
        className={'ellipsis-typography'}
        {...props}
      >
        {children}
      </Typography>
    );

  return (
    <Tooltip title={toolTipsContent}>
      <Typography className={'ellipsis-typography'} {...props}>
        {children}
      </Typography>
    </Tooltip>
  );

  function getToolTipContent() {
    if (title) return title;
    if (typeof children === 'string') return children;
    if (Array.isArray(children)) {
      if (
        children.reduce((reducer, child) => {
          if (!reducer) return reducer;
          return typeof child === 'string';
        }, true)
      )
        return children.join('');
    }
    return undefined;
  }
};
export default EllipsisTypography;
