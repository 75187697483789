import React, { useState } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import SystemButton from '../../components/SystemButton';
import { APPROVE_QUERY } from './query';
import { toast } from '../../shared/toast';
import { translate } from '../../shared/translate';
import errorParser from '../../shared/errorParser';
import confirmation from '../../shared/confirmation';
import { useHistory } from 'react-router-dom';
import { parseConnection } from '../../shared';

const CHECK_UNIQUE = gql`
  query CheckUnique($formID: ID!, $query: String) {
    node(id: $formID) {
      ... on ShopForm {
        records(limits: 1, query: $query) {
          totalCount
        }
      }
    }
  }
`;

const ApproveButton = ({ id, formID, disabled, uniqueFieldKey, uniqueFieldValue }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const [approveMutation] = useMutation(APPROVE_QUERY);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      if (!(await confirmation(translate.approval_confirmation))) return;

      const { data: { node } = {} } = await client.query({
        fetchPolicy: 'network-only',
        query: CHECK_UNIQUE,
        variables: {
          formID,
          query: `metadataFlatten:"|${uniqueFieldKey}:${uniqueFieldValue}|" AND status:APPROVED`,
        },
      });

      if (parseConnection(node?.records).totalCount >= 1) {
        if (await confirmation(translate.multiple_unique_fields_approval)) {
          await approveMutation({ variables: { id } });
          toast.success(translate.update_success);
          history.goBack();
        }
      } else {
        await approveMutation({ variables: { id } });
        toast.success(translate.update_success);
        history.goBack();
      }
    } catch (error) {
      toast.error(errorParser(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SystemButton size="small" color="success" disabled={disabled} loading={isLoading} onClick={handleClick}>
      {translate.status_approve}
    </SystemButton>
  );
};

export default ApproveButton;
