import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { translate } from '../shared/translate';
import { Icon } from './IconRender';
import { Skeleton } from '@material-ui/lab';
import { textColor } from '../theme';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 12,
    verticalAlign: 'middle',
  },
}));

export default ({
  loading,
  isInSitu,
  data: { person = '', email = '', tel = '', address, lines, country = '' } = {},
}) => {
  return (
    <Grid container spacing={1}>
      {person && (
        <AddressDetail
          icon={'faUser'}
          title={translate.customer_fullname}
          info={!!person ? person.trim() : translate.no_info}
        />
      )}
      {email && (
        <AddressDetail icon={'faEnvelope'} title={translate._email} info={!!email ? email.trim() : translate.no_info} />
      )}
      {tel && <AddressDetail icon={'faPhone'} title={translate.phone} info={!!tel ? tel.trim() : translate.no_info} />}
      {lines && (
        <AddressDetail
          icon={'faMapMarkerAlt'}
          title={translate.address}
          info={(lines ? lines.join('\n') : address || '').trim() || translate.no_info}
          loadingSkeleton={
            <>
              <Skeleton width={100} />
              <Skeleton width={100} />
            </>
          }
        />
      )}
      {country && (
        <AddressDetail
          icon={'faGlobe'}
          title={translate.country}
          info={!!country ? translate.countries[country] : translate.no_info}
        />
      )}
    </Grid>
  );
};

const AddressDetail = ({ loading, icon, title, info, loadingSkeleton = <Skeleton width={100} /> }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid
          md={4}
          item
          alignItems="center"
          style={{
            display: 'flex',
          }}
        >
          <Icon style={{ color: textColor }} icon={icon} className={classes.icon} />
          <Typography variant={'caption'} style={{ marginLeft: 8 }}>
            {title}
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Typography variant={'caption'}>{loading ? loadingSkeleton : info}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
