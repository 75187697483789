import React from 'react';
import { Grid, InputAdornment, Typography, Card, CardContent } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';
import CustomTimeField from '../../components/FormPageMaker/Fields/CustomTimeField';
import DaySelectButtonGroup from './DaySelectButtonGroup';
import SelectComboMemberTier from '../../components/SelectCombo/SelectComboMemberTier';
import { translate } from '../../shared/translate';
import TrashButton from '../../components/TrashButton';

const GateSettingField = ({ disabled, onChange, value = {} }) => {
  return (
    <Card variant={'outlined'} elevation={0}>
      <CardContent>
        <Grid container alignItems={'center'} spacing={3}>
          <Grid item xs>
            <Grid container spacing={2} alignItems={'flex-end'}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={3}>
                    <Typography variant={'h6'}>{translate.membership_level || '會員等級'}</Typography>
                    <SelectComboMemberTier
                      disabled={disabled}
                      value={value?.memberTier}
                      onChange={(v) => {
                        onChange({
                          ...value,
                          memberTier: v,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant={'h6'}>{translate.day_of_the_week || '星期'}</Typography>
                    <DaySelectButtonGroup
                      value={value.day}
                      onChange={(e) => {
                        onChange({
                          ...value,
                          day: e,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant={'h6'}>{translate.start_time || '開始時間'}</Typography>
                <CustomTimeField
                  value={value?.startedAt}
                  disabled={disabled}
                  onChange={(v) =>
                    onChange({
                      ...value,
                      startedAt: v,
                    })
                  }
                />
              </Grid>
              <Grid item>
                <Typography variant={'h6'}>{translate.close_time || '結束時間'}</Typography>
                <CustomTimeField
                  value={value?.startedThru}
                  disabled={disabled}
                  onChange={(v) =>
                    onChange({
                      ...value,
                      startedThru: v,
                    })
                  }
                />
              </Grid>
              <Grid item>
                <Typography variant={'h6'}>{translate.required_points || '所需分數'}</Typography>
                <InputField
                  disabled={disabled}
                  value={value?.point}
                  type={'number'}
                  onChange={(e) => {
                    onChange({
                      ...value,
                      point: e?.target?.value,
                    });
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position={'end'}>{translate.points || '分'}</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant={'h6'}>{translate.unit || '單位'}</Typography>
                <SelectField
                  disabled={disabled}
                  value={value?.unit}
                  placeholder={'unit'}
                  onChange={(e) => {
                    onChange({
                      ...value,
                      unit: e?.target?.value,
                    });
                  }}
                  options={[{ label: translate.per_hour || '每小時', value: 60 }]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TrashButton
              onClick={() => {
                onChange(undefined);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GateSettingField;
