import { gql } from '@apollo/client';
import { FRAGMENT_PRINTABLE_ORDER_ITEM } from '../PrintOrder/query';

export const GET_QUERY = gql`
  query ($id: ID!, $shopId: ID!) {
    shop: node(id: $shopId) {
      id
      ... on CompanyShop {
        name
        locale
        logoMedia {
          src
          optimizedSrc(width: 256, height: 256)
        }
      }
    }
    node(id: $id) {
      id
      ... on OrderDeliveryNote {
        order {
          id
          referenceNo
        }
        shippingProvider {
          id
          name
        }
        address {
          person
          tel
          email
          lines
          country
        }
        referenceNo
        trackingNumber
        metadata {
          key
          value
        }
        status
        deliveryDetails
        items {
          id
          orderItem {
            ...FRAGMENT_PRINTABLE_ORDER_ITEM
          }
          quantity
        }
        order {
          id
          remark
          checkout {
            id
            items {
              id
              createdAt
              productVariation {
                id
              }
              bundleProduct {
                bundleProduct {
                  id
                }
              }
              addOnProduct {
                id
              }
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
  ${FRAGMENT_PRINTABLE_ORDER_ITEM}
`;
