import React from 'react';
import MenuItem from './_menuItem';
import { makeStyles, Collapse } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { escapeRegExp } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'normal',
    width: '75%',
  },
  itemChild: {
    backgroundColor: '#3f404a',
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  expandIcon: {
    color: '#747f8e',
    transition: 'all 0.3s',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  expandMore: {
    transform: 'rotate(45deg)',
  },
  child: {
    '& *': {
      color: '#747f8e',
    },
    '&:hover': {
      '& *': {
        color: '#FFFFFF',
      },
    },
  },
  childSelected: {
    backgroundColor: '#e67772',
    '& *': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#e67772',
    },
  },
  py0: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default ({ name, toggle, open, onClick, icon, children, location: { pathname } = {} }) => {
  const renderChildren = children.filter(({ name, link }) => !!name && !!link);
  const classes = useStyles();

  const SvgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 500" preserveAspectRatio="none">
      <path
        fill="currentColor"
        d="M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z"
      />
    </svg>
  );
  function matchPathname(search, pathname) {
    if (search === '/') {
      return pathname === '/';
    }
    const isExact = !!pathname.match(new RegExp(`^${escapeRegExp(search)}$`));
    const isChildPage = !!pathname.match(new RegExp(`^${escapeRegExp(search)}/`));
    return isExact || isChildPage;
  }
  return (
    <>
      {!!name && renderChildren.length > 0 ? (
        <>
          <MenuItem
            onClick={onClick}
            active={open}
            header={true}
            toggle={toggle}
            name={name}
            icon={icon}
            right={
              <Box width={17} height={17} className={`${open ? classes.expandMore : ''} ${classes.expandIcon}`}>
                <SvgIcon />
              </Box>
            }
          />
          <Collapse in={open} timeout="auto" unmountOnExit className={classes.itemChild}>
            {renderChildren.map((item, i) => {
              const { name, link, includedPathnames = [] } = item;
              if (!name || !link) return null;
              const active =
                matchPathname(link, pathname) ||
                includedPathnames.reduce((reducer, search) => {
                  return reducer || matchPathname(search, pathname);
                }, false);
              return <MenuItem key={i} {...item} toggle={toggle} active={active} nested={true} />;
            })}
          </Collapse>
        </>
      ) : (
        <>
          {renderChildren.map((item, i) => {
            const { name, link, includedPathnames = [], children } = item;
            if (!name || !link) return null;

            const active =
              matchPathname(link, pathname) ||
              includedPathnames.reduce((reducer, search) => {
                return reducer || matchPathname(search, pathname);
              }, false);

            return <MenuItem onClick={onClick} header={true} key={i} {...item} toggle={toggle} active={active} />;
          })}
        </>
      )}
    </>
  );
};
