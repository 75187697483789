import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../../item_EmailTemplate/SplitCard';
import { defaultSuggestions } from '../defaultSuggestion';
import LayoutFront from '../LayoutFront';

export default class LayoutQRCodeOrder extends LayoutFront {
  type = 'QRCODE';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[...defaultSuggestions]}
              allows={['isTitle', 'isText', 'isLV', 'isQRCode', 'isLogo', 'isCut']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              standardWidth={300}
              extraData={{ customerPanelUrl: window.location.origin }}
              extraRenderer={{}}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
