import React from 'react';
import { translate } from '../../../../shared/translate';
import ProductsSelectionForm from '../ProductsSelectionForm';
import _ from 'lodash';
import CollectionField from './CollectionField';
import { GET_QUERY as GET_COLLECTION_QUERY } from './query';
import { infinityFetch } from '../../../../shared';
import errorParser from '../../../../shared/errorParser';
import toast from '../../../../shared/toast';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import uuid from 'react-uuid';

const CollectionSelection = ({ onClose, onComplete }) => {
  return (
    <ProductsSelectionForm
      label={translate.search_by_category_name_code || '按分類名稱／分類代碼搜尋'}
      currentTab={1}
      tabContent={({ values, setValues }) => {
        return <CollectionField values={values} onChange={(v) => setValues(v)} />;
      }}
      onClose={onClose}
      onComplete={async (values) => {
        try {
          const { mode, data = [] } = values?.selectCollectionData || {};

          const res = await infinityFetch({
            query: GET_COLLECTION_QUERY,
            variables: {
              id: localStorage.getItem('shopId'),
              filter:
                mode === 'EXCLUDE'
                  ? {
                      id: {
                        operator: 'NIN',
                        value: ['dummy']
                          .concat(
                            data?.map((d) => {
                              // [true, {id, selectionData: {mode: "EXCLUDE", data: []}] -> false
                              if (!d[0] && !d[1].selectionData.data.length) return d?.[1]?.id;
                            }),
                          )
                          .filter(Boolean),
                      },
                    }
                  : {
                      id: {
                        operator: 'IN',
                        value: ['dummy'].concat(data?.map((d) => d?.[1]?.id)).filter(Boolean),
                      },
                    },
            },
            getConnection: (data) => data?.node?.collections,
            onProgress: (downloaded, total) => {
              if (total > downloaded) ActivityIndicator.show(`${translate.loading} ${downloaded} / ${total}`);
              else ActivityIndicator.show(`${translate.loading}`);
            },
          });

          let result = [];
          switch (mode) {
            case 'EXCLUDE':
              const selectedCollectionDataWithArray = data
                ?.map((d) => {
                  if (!!d[1].selectionData.data.length) return d[1];
                })
                .filter(Boolean);

              result = res.nodes
                .flatMap((node) => {
                  // query時，filter走了這個 -> [true, {id, selectionData: {mode: "EXCLUDE", data: []}]
                  // 在這裡，全部product全要
                  if (!selectedCollectionDataWithArray.map((d) => d.id).includes(node.id)) {
                    return node.products.nodes.map((product) => {
                      return {
                        id: uuid(),
                        price: 0,
                        product: {
                          id: product.id,
                          name: product.name,
                          sku: product.sku,
                        },
                      };
                    });
                  } else {
                    return selectedCollectionDataWithArray.flatMap((selected) => {
                      const productMode = selected.selectionData.mode;
                      const productData = selected.selectionData.data;
                      return node.products.nodes
                        .map((product) => {
                          if (
                            productMode === 'EXCLUDE' &&
                            !productData.includes(product.id) &&
                            node.id === selected.id
                          ) {
                            return {
                              id: uuid(),
                              price: 0,
                              product: {
                                id: product.id,
                                name: product.name,
                                sku: product.sku,
                              },
                            };
                          }

                          if (
                            productMode === 'INCLUDE' &&
                            productData.includes(product.id) &&
                            node.id === selected.id
                          ) {
                            return {
                              id: uuid(),
                              price: 0,
                              product: {
                                id: product.id,
                                name: product.name,
                                sku: product.sku,
                              },
                            };
                          }
                        })
                        .filter(Boolean);
                    });
                  }
                })
                .filter(Boolean);
              break;

            case 'INCLUDE':
              result = values.selectCollectionData.data.flatMap(([_, collection]) => {
                const collectionId = collection.id;
                const productData = collection.selectionData.data;
                const productMode = collection.selectionData.mode;

                if (productMode === 'INCLUDE') {
                  const node = res.nodes.find((node) => node.id === collectionId);
                  if (node) {
                    return node.products.nodes
                      .filter((product) => productData.includes(product.id))
                      .map((product) => ({
                        id: uuid(),
                        price: 0,
                        product: {
                          id: product.id,
                          name: product.name,
                          sku: product.sku,
                        },
                      }));
                  }
                } else if (productMode === 'EXCLUDE') {
                  const node = res.nodes.find((node) => node.id === collectionId);
                  if (node) {
                    return node.products.nodes
                      .filter((product) => !productData.includes(product.id))
                      .map((product) => ({
                        id: uuid(),
                        price: 0,
                        product: {
                          id: product.id,
                          name: product.name,
                          sku: product.sku,
                        },
                      }));
                  }
                }
                return [];
              });
              break;
          }

          onComplete(result);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          ActivityIndicator.hide();
        }
      }}
    />
  );
};

export default CollectionSelection;
