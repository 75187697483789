import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { getCommonColumns } from '../../../shared';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/member_tiers/new',
    getRowLink: ({ id, name }) => ({ pathname: `/member_tiers/${id}`, state: { title: name } }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
        filter: 'name',
      },
      {
        title: translate.level,
        fieldName: 'level',
        type: 'number',
        sortBy: 'level',
        filter: 'level',
      },
      {
        title: translate.default,
        fieldName: 'isDefault',
        type: 'bool',
        filter: 'isDefault',
        sortBy: 'isDefault',
      },
      ...getCommonColumns({
        createdAt: { filter: undefined },
        updatedAt: { filter: undefined },
      }),
    ],
    hasQSearch: true,
    qFields: ['name'],
  };

  getExtraFetchVariables() {
    return {
      id: localStorage.getItem('companyId'),
    };
  }

  getData({ node } = {}) {
    return node.memberTiers;
  }
}
