import React, { useState } from 'react';
import SystemButton from '../../../components/SystemButton';
import { translate } from '../../../shared/translate';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { EXPORT_QUERY } from './query';

const ExportButton = ({ id }) => {
  const [loading, setLoading] = useState(false);
  return (
    <SystemButton
      style={{ width: 'max-content' }}
      loading={loading}
      disabled={loading}
      color={'white'}
      onClick={async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          await client.mutate({
            mutation: EXPORT_QUERY,
            variables: {
              id,
            },
          });
          toast.success(translate.export_tips);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.export_recipient_report}
    </SystemButton>
  );
};

export default ExportButton;
