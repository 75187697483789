import React from 'react';
import { Box, Chip, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const TabCustomerTags = ({ loading, tags = [] }) => {
  return (
    <Box p={2}>
      <Grid container spacing={1}>
        {loading ? (
          <>
            <Grid item>
              <Skeleton variant={'rect'} width={70} height={24} style={{ borderRadius: 99 }} />
            </Grid>
            <Grid item>
              <Skeleton variant={'rect'} width={70} height={24} style={{ borderRadius: 99 }} />
            </Grid>
            <Grid item>
              <Skeleton variant={'rect'} width={70} height={24} style={{ borderRadius: 99 }} />
            </Grid>
          </>
        ) : (
          tags.map((tag, i) => (
            <Grid item key={i}>
              <Chip label={tag} size={'small'} style={{ backgroundColor: '#F6DEDD' }} />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default TabCustomerTags;
