import React, { useState } from 'react';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import FormToolbar from '../../../../components/FormToolbar';
import { Formik } from 'formik';
import FormCard from '../../../../components/FormPageMaker/Cards/FormCard';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import { actionWrapper } from '../../../../shared';
import CollectionsField from '../../../item_Product/CollectionsField';

const PRODUCTS_COLLECTION_SET = gql`
  mutation (
    $shopId: ID!
    $collectionIds: [ID!]!
    $collectionCodes: [String!]!
    $filter: ProductFilterInput
    $operator: BatchAssignmentOperator!
    $query: String
  ) {
    productsCollectionSet(
      shopId: $shopId
      collectionIds: $collectionIds
      collectionCodes: $collectionCodes
      filter: $filter
      operator: $operator
      query: $query
    ) {
      id
    }
  }
`;

export const MenuCollectionSet = ({ scope, ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <MenuItem disabled={scope.getSelectionCount() === 0} onClick={() => setVisible(true)}>
        {translate.setting} {translate.collection}
      </MenuItem>
      <Dialog maxWidth={'sm'} fullWidth open={visible} onClose={() => setVisible(false)}>
        <Formik
          initialValues={{ collections: [] }}
          onSubmit={async (values) => {
            setVisible(false);
            await actionWrapper(
              async () => {
                const collectionIds = values.collections.map((collection) => (collection || {}).id).filter((_) => _);

                if (collectionIds.length > 0)
                  await client.mutate({
                    mutation: PRODUCTS_COLLECTION_SET,
                    variables: {
                      shopId: localStorage.getItem('shopId'),
                      filter: scope.getSelectionFilter(),
                      query: scope.getQ(),
                      operator: 'SET',
                      collectionIds,
                      collectionCodes: [],
                    },
                  });
              },
              { messages: { loading: translate.update, success: translate.update_success } },
            );
          }}
        >
          {(actions) => (
            <form onSubmit={actions.handleSubmit}>
              <DialogContent>
                <FormCard
                  actions={actions}
                  fields={[
                    {
                      label: translate.product_collections || '產品分類',
                      render: (props) => <CollectionsField {...props} />,
                    },
                  ]}
                />
              </DialogContent>
              <FormToolbar
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
                submitBtnType={'submit'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default {
  render: (args) => <MenuCollectionSet {...args} />,
};
