import { gql } from '@apollo/client';

export const LIST_QUERY = gql`
  query ($id: ID!, $limits: Int) {
    node(id: $id) {
      id
      ... on CompanyShop {
        products(
          limits: $limits
          filter: { active: { operator: IN, value: ["true"] } }
          sortBy: [{ field: "createdAt", order: DESC }]
        ) {
          totalCount
          nextCursor
          nodes {
            id
            name
            subtitle
            medias {
              #id
              src
              optimizedSrc(width: 256, height: 256)
            }
            basePrice
            maxPrice
          }
        }
      }
    }
  }
`;
