import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useQueryStringParams() {
  const location = useLocation();

  const searchParams = useMemo(() => {
    const url = window.location.href;
    const index = url.indexOf('?');
    if (index === -1) {
      return new URLSearchParams();
    }
    return new URLSearchParams(url.substring(index));
  }, [location.search]);

  const params = {};
  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  return params;
}

export default useQueryStringParams;
