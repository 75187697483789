import FormPageMaker from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { GET_QUERY, UPDATE_QUERY, CREATE_QUERY, REMOVE_QUERY } from './query';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    isDailyAvaActive: false,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.attribute_setting || '自定參數設定',
        cards: [
          {
            fields: [
              {
                label: translate.field_key,
                type: 'text',
                name: 'key',
                maxLength: 50,
                required: true,
                disabled: !!this.state?.id,
              },
              {
                label: translate.attribute_target || '參數目標',
                type: 'select',
                name: 'typename',
                options: [
                  {
                    label: translate.product || '產品',
                    value: 'ShopProducts',
                  },
                ],
                required: true,
                disabled: !!this.state?.id,
              },
              {
                label: translate.name,
                type: 'text',
                name: 'name',
                maxLength: 100,
                required: true,
              },
              {
                label: translate.type,
                type: 'select',
                name: 'type',
                options: [
                  {
                    label: translate.text,
                    value: 'TEXT',
                  },
                  {
                    label: translate.number,
                    value: 'NUMBER',
                  },
                  {
                    label: translate.color,
                    value: 'COLOR',
                  },
                ],
                required: true,
                disabled: !!this.state?.id,
              },
              {
                label: translate.sort_index,
                type: 'number',
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
              },
              {
                label: translate.activate_checkbox,
                type: 'switch',
                name: 'active',
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;

    if (id)
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input: {
            active: values.active,
            name: values.name,
            sortIndex: values.sortIndex,
          },
        },
      });
    else
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            active: values.active,
            key: values.key,
            name: values.name,
            shopId: localStorage.getItem('shopId'),
            sortIndex: values.sortIndex,
            type: values.type,
            typename: values.typename,
          },
        },
      });
    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {};
    return {
      key: node?.key || '',
      name: node?.name || '',
      active: node?.active ?? true,
      type: node?.type,
      system: node?.system,
      typename: node?.typename,
      sortIndex: node?.sortIndex || 0,
    };
  };

  allowRemove = ({ values }) => {
    return !values?.system;
  };
}
