import React from 'react';
import CommonIconButton from './CommonIconButton';
import { ReactComponent as IconTrash } from '../assets/icon/trash.svg';
import SystemButton from './SystemButton';
import { Icon } from './IconRender';

const TrashButton = ({
  size = 'medium',
  disabled = false,
  onClick = (_) => _,
  loading = false,
  color = 'warning',
  children,
  ...props
}) => {
  // with (default)white text & (default)red backgroundColor
  // color={'omniweDelete'} <-- 這款是 (default)透明背景 ＋ 灰色字體 // 如要改顏色 請用style
  if (!!children)
    return (
      <SystemButton
        disabled={disabled}
        loading={loading}
        color={color}
        onClick={onClick}
        startIcon={
          <Icon
            type={'svg'}
            icon={IconTrash}
            fontSize={'small'}
            viewBox={color === 'omniweDelete' ? '0 -1 15 26' : '0 -5 15 32'}
          />
        }
        {...props}
      >
        {children}
      </SystemButton>
    );

  // without text
  return (
    <CommonIconButton
      color={'secondary'}
      icon={IconTrash}
      disabled={disabled}
      size={size}
      onClick={onClick}
      {...props}
    />
  );
};

export default TrashButton;
