import React, { useState } from 'react';
import { Button, Dialog, DialogContent, MenuItem, Typography } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { client } from '../../../../shared/apollo';
import { PRODUCTS_EXPORT_QUERY, PRODUCTS_EXPORT_SEO_QUERY } from './query';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper } from '../../../../shared';

const ImportModal = ({ state, onClick = (_) => _ }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <MenuItem
        disabled={state.getSelectionCount() === 0}
        onClick={() => {
          onClick();
          setVisible(true);
        }}
      >
        {translate.export} ({state.getSelectionCount()})
      </MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogContent>
          <Typography align="center">
            {translate.formatString(translate['confirm_export_n_records'], state.getSelectionCount())}
          </Typography>
        </DialogContent>

        <DialogContent>
          <div>
            <Button
              fullWidth
              variant={'outlined'}
              size={'large'}
              onClick={async () => {
                if (
                  await confirmation(
                    translate.formatString(translate['confirm_export_n_records'], state.getSelectionCount()),
                  )
                ) {
                  setVisible(false);
                  await actionWrapper(
                    async () => {
                      const { nodes } = await state.infinityFetchAllSelected({
                        onProgress: (downloaded, total) => {
                          if (downloaded < total)
                            ActivityIndicator.show(`${translate.download} ${downloaded} / ${total}`);
                          else ActivityIndicator.hide();
                        },
                      });
                      const filter = {
                        id: {
                          operator: 'IN',
                          value: nodes.map((node) => node.id),
                        },
                      };

                      await client.mutate({
                        mutation: PRODUCTS_EXPORT_QUERY,
                        variables: {
                          shopId: localStorage.getItem('shopId'),
                          filter,
                        },
                      });
                    },
                    {
                      messages: {
                        success: translate.export_tips,
                      },
                    },
                  );
                }
              }}
            >
              {translate.export_product_data || '匯出產品資料'}
            </Button>
          </div>
        </DialogContent>
        <DialogContent>
          <div>
            <Button
              fullWidth
              variant={'outlined'}
              size={'large'}
              onClick={async () => {
                if (
                  await confirmation(
                    translate.formatString(translate['confirm_export_n_records'], state.getSelectionCount()),
                  )
                ) {
                  setVisible(false);
                  await actionWrapper(
                    async () => {
                      let filter = {};
                      if (state.totalCount < 200) {
                        const { nodes } = await state.infinityFetchAllSelected({
                          onProgress: (downloaded, total) => {
                            if (downloaded < total)
                              ActivityIndicator.show(`${translate.download} ${downloaded} / ${total}`);
                            else ActivityIndicator.hide();
                          },
                        });
                        filter = {
                          id: {
                            operator: 'IN',
                            value: nodes.map((node) => node.id),
                          },
                        };
                      } else {
                        filter = state.getSelectionFilter();
                      }
                      await client.mutate({
                        mutation: PRODUCTS_EXPORT_SEO_QUERY,
                        variables: {
                          shopId: localStorage.getItem('shopId'),
                          filter,
                        },
                      });
                    },
                    {
                      messages: {
                        success: translate.export_tips,
                      },
                    },
                  );
                }
              }}
            >
              {translate.export_product_SEO_related_fields || '匯出產品SEO相關欄目'}
            </Button>
          </div>
        </DialogContent>
        <div style={{ height: 8 }} />
      </Dialog>
    </>
  );
};

export default ImportModal;
