import React from 'react';

export const BodyComponentsMap = {
  row: require('./row').default,
  column: require('./column').default,
  grid: require('./grid').default,
  image: require('./image').default,
  html: require('./html').default,
  tableOfContents: require('./tableOfContents').default,
  banner: require('./banner').default,
  product: require('./product').default,
  searchBar: require('./searchBar').default,
  collection: require('./collection').default,
  article: require('./article').default,
  latestProducts: require('./latestProducts').default,
  latestArticles: require('./latestArticles').default,
  taggedArticles: require('./taggedArticles').default,
  separator: require('./separator').default,
  feature: require('./feature').default,
  accordion: require('./accordion').default,
  form: require('./form').default,
  instagram: require('./instagram').default,
  pdf: require('./pdf').default,
};

const BodyComponents = ({ data, setEditData, ...props }) => {
  const { _type } = data || {};
  const { preview: Preview } = BodyComponentsMap[_type] || {};

  return !!Preview && <Preview data={data} setEditData={setEditData} {...props} />;
};

export default BodyComponents;
