import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboShop from '../../SelectCombo/SelectComboShop';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, name] = str.match(/^(.*?)∆(.*)$/);
      return { id, name };
    });
  const [shops, setShops] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboShop
        multiple
        disablePortal={false}
        value={shops}
        onChange={(shops) => {
          setShops(shops);
          onFilterChange({
            value: (shops || []).map((shop) => `${shop.id}∆${shop.name}`),
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
