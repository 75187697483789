import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { AddToModifier_GET_QUERY } from '../query';
import { PriceFormat, sleep } from 'shared';
import { AppBar, IconButton, Toolbar, Typography, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ConnectionTableContainer from '../../../components/ConnectionPageMaker/ConnectionTableContainer';

export default class AddToModifierListView extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    gql: {
      get: AddToModifier_GET_QUERY,
    },
    fields: [
      {
        title: translate.product_sku,
        fieldName: 'sku',
        width: 150,
      },
      {
        title: translate.image,
        fieldName: 'medias',
        type: 'medias',
      },
      {
        title: translate.name,
        fieldName: 'name',
      },
      {
        title: translate.price,
        render: ({ basePrice, maxPrice }) => {
          if (basePrice !== maxPrice)
            return <Typography>{`${PriceFormat(basePrice)} - ${PriceFormat(maxPrice)}`}</Typography>;
          return <Typography>{PriceFormat(basePrice)}</Typography>;
        },
        type: 'price',
        filter: 'basePrice',
        filterChannel: 'q',
      },
      {
        title: translate.quantity,
        align: 'right',
        filter: 'variationQuantity',
        filterType: 'number',
        render: ({ variations: _variations }) => {
          const variations = _variations || [];
          const { quantity, ignoreStock } = variations.reduce(
            (reducer, variation) => {
              const { quantity, ignoreStock } = variation || {};
              return {
                quantity: reducer.quantity + (+quantity || 0),
                ignoreStock: !!ignoreStock || reducer.ignoreStock,
              };
            },
            { quantity: 0, ignoreStock: false },
          );
          const isInfiniteStock = !!ignoreStock,
            hasMultipleVariations = variations.length > 1;
          return <Typography>{`${isInfiniteStock ? '∞' : quantity}${hasMultipleVariations ? '*' : ''}`}</Typography>;
        },
      },
    ],
    hasQSearch: true,
    qFields: ['sku', 'name'],
    selectionMode: true,
    limits: 15,
  };

  toggleModal = (modalName) => {
    const name = `modal${modalName}Open`;
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  getData = ({ node } = {}) => {
    const { products } = node || {};
    return products;
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  renderWrapper(...args) {
    return this.renderContent(...args);
  }

  getQueryParams = () => {
    const { queryParams } = this.state;
    return queryParams ?? {};
  };
  getQueryParam = (key) => {
    const { queryParams } = this.state;
    return queryParams?.[key];
  };
  patchQueryParams = (nextParams) => {
    const { queryParams } = this.state;

    if (typeof nextParams === 'function') {
      nextParams = nextParams(queryParams);
    }
    this.setState({
      queryParams: {
        ...queryParams,
        ...nextParams,
      },
    });
    sleep(0).then(() => {
      this.setState(this.getQueryState());
    });
  };

  renderTableContainer({ nodes, totalCount, nextCursor, loading }) {
    return (
      <ConnectionTableContainer style={{ maxHeight: 'calc(100vh - 75px - 75px - 64px)' }}>
        {this.renderTable({ nodes, totalCount, nextCursor, loading })}
      </ConnectionTableContainer>
    );
  }

  renderExtras() {
    const { handleSave, handleCLose } = this.props;
    const { selectionData } = this.state;
    return (
      <AppBar
        style={{
          backgroundColor: '#2a2b31',
        }}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCLose} aria-label="close">
            <Close />
          </IconButton>
          <Typography
            variant="h6"
            style={{
              color: '#fff',
            }}
          >
            {translate.product_modifiers_add || '添加產品'}
          </Typography>
          <Button
            style={{
              marginLeft: 'auto',
            }}
            autoFocus
            className="warning-btn"
            onClick={handleSave}
            disabled={selectionData?.mode === 'INCLUDE' && !selectionData?.data?.length}
          >
            {translate.save}
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
}
