import React, { useEffect, useState } from 'react';
import { TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { translate } from '../../../shared/translate';
import { withStyles } from '@material-ui/core/styles';
import LSkeleton from '../../LSkeleton';
import { useDebounce } from 'react-use';

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    marginTop: 0,
    boxShadow: 'none',
  },
}))(Tooltip);

export default ({
  loading,
  className = '',
  style = {},
  value: _value = '',
  options = [],
  onChange = (_) => _,
  maxLength,
  inputProps,
  InputProps,
  variant = 'outlined',
  onKeyDown = (_) => _,
  placeholder,
  required,
  toolTipColor,
  ...props
}) => {
  const [value, setValue] = useState(_value);

  useEffect(() => {
    if (value !== _value) setValue(_value);
  }, [_value]);
  useDebounce(
    () => {
      if (value !== _value) onChange({ target: { value } });
    },
    250,
    [value],
  );

  if (loading) return <LSkeleton height={30} />;

  const core =
    options.length > 0 ? (
      <Autocomplete
        className={className}
        required={required}
        style={style}
        fullWidth
        freeSolo
        disableClearable={true}
        size={'small'}
        options={options}
        getOptionLabel={(option) => option || ''}
        value={value}
        onInputChange={(e, newValue) => {
          try {
            if (Number.isInteger(e.target.value)) {
              setValue(newValue);
            } else setValue(newValue);
          } catch (e) {}
        }}
        {...props}
        renderInput={({ inputProps: _inputProps, InputProps: _InputProps, ...props }) => {
          return (
            <TextField
              {...props}
              // eslint-disable-next-line
              InputProps={{
                ..._InputProps,
                ...InputProps,
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  onChange({ target: { value } });
                }
                onKeyDown(e);
              }}
              placeholder={placeholder}
              // eslint-disable-next-line
              inputProps={{ ..._inputProps, maxLength, style: { paddingTop: 3, paddingBottom: 3 }, ...inputProps }}
              margin={'none'}
              variant={variant}
              size={'small'}
              required={required}
            />
          );
        }}
      />
    ) : (
      <TextField
        required={required}
        fullWidth
        margin={'none'}
        variant={variant}
        size={'small'}
        className={className}
        style={style}
        placeholder={placeholder}
        value={value === null ? '' : value}
        onChange={(e) => setValue(e.target.value)}
        // eslint-disable-next-line
        InputProps={InputProps}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            onChange({ target: { value } });
          }
          onKeyDown(e);
        }}
        // eslint-disable-next-line
        inputProps={{
          maxLength,
          ...inputProps,
        }}
        {...props}
      />
    );

  if (!maxLength) return core;
  return (
    <StyledTooltip
      placement={'bottom-end'}
      title={
        <span style={{ color: toolTipColor }}>
          {translate.length_count}: {(value || '').toString().length}/{maxLength}
        </span>
      }
    >
      {core}
    </StyledTooltip>
  );
};
