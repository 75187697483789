import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      id
      ... on ShopOrder {
        invoices(limits: $limits, cursor: $cursor) {
          totalCount
          nextCursor
          nodes {
            meta
            id
            status
            paymentMethodV2 {
              id
              name
              provider
            }
            credential {
              id
              name
              description
            }
            total
            totalRefund
            createdAt
            refundStatus
          }
        }
      }
    }
  }
`;

export const CHECKOUT_PAY_QUERY = gql`
  mutation ($id: ID!, $input: CheckoutPayInput!) {
    checkoutPay(id: $id, input: $input) {
      id
      order {
        id
        status
        paymentStatus
        invoices {
          nodes {
            id
            status
            paymentMethodV2 {
              id
              name
              provider
            }
            credential {
              id
              name
              description
            }
            total
            createdAt
          }
        }
      }
    }
  }
`;
export const CHECKOUT_GET_INVOICE_QUERY = gql`
  query ($id: ID!, $input: CheckoutPaymentInput!) {
    node(id: $id) {
      id
      ... on ShopCheckout {
        payment(input: $input) {
          provider
          credentialId
          invoiceId
          charge
          token
        }
      }
    }
  }
`;
export const INVOICE_REFUND = gql`
  mutation ($id: ID!) {
    invoiceRefund(id: $id) {
      id
      totalRefund
      order {
        id
        totalRefund
      }
    }
  }
`;

export const ORDER_REFUND = gql`
  mutation ($id: ID!, $amount: Float) {
    orderRefund(id: $id, amount: $amount) {
      id
      totalRefund
      creditNotes {
        totalCount
        nextCursor
        nodes {
          id
          status
          total
          remark
          createdAt
        }
      }
    }
  }
`;
