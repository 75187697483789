import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import ActiveSwitcher from './ActiveSwitcher';
import MenuPriceAdjustment from './MenuPriceAdjustment';
import MenuActivate from './MenuActivate';
import MenuDeactivate from './MenuDeactivate';
import MenuDelete from './MenuDelete';
import MenuQuantitySet from './MenuQuantitySet';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    createUrl: '/addon_products/new',
    gql: {
      get: GET_QUERY,
    },
    selectionMode: true,
    getRowLink: ({ id, name }) => ({
      pathname: '/addon_products/' + id,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.product_sku,
        fieldName: 'sku',
        width: 150,
        filter: 'sku',
        sortBy: 'sku.keyword',
      },
      {
        title: translate.image,
        fieldName: 'medias',
        type: 'medias',
        width: 1,
      },
      {
        title: translate.name,
        fieldName: 'name',
        filter: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: translate.cross_selling_price,
        fieldName: 'unitPrice',
        type: 'price',
        filter: 'unitPrice',
        sortBy: 'unitPrice',
        width: 100,
      },
      {
        title: translate.quantity,
        value: ({ quantity, ignoreStock }) => (ignoreStock ? '∞' : quantity - 0 || 0),
        width: 100,
      },
      {
        title: translate.visibility,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} data={data} active={active} onCompleted={this.refetch} />;
        },
        sortBy: 'active',
        noLink: true,
      },
    ],
    menus: [MenuPriceAdjustment, MenuActivate, MenuDeactivate, MenuDelete, MenuQuantitySet],
    hasQSearch: true,
    qFields: ['sku', 'name'],
  };

  getData = ({ node } = {}) => {
    const { addOnProducts } = node || {};
    return addOnProducts;
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId'), warehouseId: localStorage.getItem('stockWarehouseId') };
  }
}
