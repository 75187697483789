import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { UPDATE_QUERY, GET_QUERY } from './query';
import { client } from '../../shared/apollo';
import { convertMetaArray, convertMetaObject, parseAddress } from '../../shared';
import LSkeleton from '../../components/LSkeleton';
import { Box, Card, Grid, InputAdornment, Typography } from '@material-ui/core';
import { AppointmentStatus } from '../../shared/omniwe-types';
import StatusBadge from '../../components/StatusBadge';
import LinkedAppointmentCard from './LinkedAppointmentCard';
import FormCard from '../../components/FormPageMaker/Cards/FormCard';
import SelectComboServiceLocation from '../../components/SelectCombo/SelectComboServiceLocation';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import CustomTimeField from '../../components/FormPageMaker/Fields/CustomTimeField';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
    },
    tabs: [
      {
        name: translate.appointment || '訂閱列表',
        cards: [
          {
            fields: [
              {
                xs: 12,
                md: 4,
                render: (props) => (
                  <FormCard
                    style={{ marginTop: -16 }}
                    actions={props}
                    fields={[
                      {
                        label: '預約編號',
                        type: 'text',
                        name: 'referenceNo',
                        disabled: true,
                      },
                      {
                        label: '預約地點',
                        render: ({ values, loading, setFieldValue, disabled }) => {
                          if (loading) return <Skeleton height={30} />;
                          return (
                            <SelectComboServiceLocation
                              disabled={disabled}
                              value={values.location}
                              onChange={(v) => {
                                setFieldValue('location', v);
                                if (v?.durationMins && values.startedAt) {
                                  setFieldValue(
                                    'startedThru',
                                    moment(values.startedAt).add(v.durationMins, 'minutes').toISOString(),
                                  );
                                }
                              }}
                            />
                          );
                        },
                      },
                      {
                        label: '預約日期',
                        type: 'date',
                        name: 'reservedAt',
                        required: true,
                      },
                      {
                        label: '預約時間',
                        render: ({ values, setFieldValue, disabled }) => {
                          return (
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <CustomTimeField
                                  minuteStep={5}
                                  InputProps={{
                                    startAdornment: <InputAdornment position={'start'}>開始時間</InputAdornment>,
                                  }}
                                  disabled={disabled}
                                  value={values?.startedAt}
                                  onChange={(v) => setFieldValue('startedAt', v)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <CustomTimeField
                                  minuteStep={5}
                                  InputProps={{
                                    startAdornment: <InputAdornment position={'start'}>結束時間</InputAdornment>,
                                  }}
                                  disabled={disabled}
                                  value={values?.startedThru}
                                  onChange={(v) => setFieldValue('startedThru', v)}
                                />
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                      {
                        label: '狀態',
                        name: 'status',
                        type: 'select',
                        render: ({ values, loading }) =>
                          loading ? (
                            <LSkeleton width={100} />
                          ) : (
                            <StatusBadge status={values?.status} statusObject={AppointmentStatus} />
                          ),
                      },
                      {
                        label: '預約項目',
                        render: ({ values, loading }) => {
                          return (
                            <div style={{ display: 'flex' }}>
                              <Card variant={'outlined'} style={{ minWidth: 250 }}>
                                <Box p={2}>
                                  <Typography variant={'h6'}>
                                    {loading ? (
                                      <LSkeleton />
                                    ) : (
                                      `【${values?.serviceBundle?.name}】${values?.service?.name}`
                                    )}
                                  </Typography>
                                  <Typography style={{ whiteSpace: 'pre' }}>
                                    {loading ? <LSkeleton /> : values?.orderItem?.remark}
                                  </Typography>
                                </Box>
                              </Card>
                            </div>
                          );
                        },
                      },
                      {
                        label: '預約人名稱',
                        type: 'text',
                        name: 'contactAddress.person',
                      },
                      {
                        label: '預約人電郵',
                        type: 'email',
                        name: 'contactAddress.email',
                      },
                      {
                        label: '預約人電話',
                        type: 'mobile',
                        name: 'contactAddress.tel',
                      },
                    ]}
                  />
                ),
              },
              {
                xs: 12,
                md: 8,
                render: (props) => (
                  <FormCard
                    style={{ marginTop: -16 }}
                    actions={props}
                    fields={[
                      {
                        label: '關聯預約',
                        render: (props) =>
                          !!props?.values?.order?.id && (
                            <Card variant={'outlined'}>
                              <LinkedAppointmentCard {...props} />
                            </Card>
                          ),
                      },
                      {
                        label: translate.remark,
                        type: 'textarea',
                        name: 'remark',
                      },
                    ]}
                  />
                ),
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const isLocationOrSlotChanged = (() => {
      if (this.initialValues?.location?.id !== values.location?.id) return true;
      if (this.initialValues?.startedAt !== values.startedAt) return true;
      if (this.initialValues?.reservedAt !== values.reservedAt) return true;
      return false;
    })();
    const momentReservedAt = moment(values.reservedAt);

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          ...(isLocationOrSlotChanged
            ? {
                serviceLocationId: values?.location?.id,
                startedAt: moment(values.startedAt)
                  .year(momentReservedAt.year())
                  .month(momentReservedAt.month())
                  .date(momentReservedAt.date())
                  .toISOString(),
                startedThru: moment(values.startedThru)
                  .year(momentReservedAt.year())
                  .month(momentReservedAt.month())
                  .date(momentReservedAt.date())
                  .toISOString(),
              }
            : {}),
          contactAddress: parseAddress(
            {
              country: 'HKG',
              ...values.contactAddress,
            },
            true,
          ),
          metadata: convertMetaArray(values.metadata),
          remark: values.remark,
        },
      },
    });
    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {};
    return {
      ...node,
      metadata: convertMetaObject(node?.metadata),
      reservedAt: node?.startedAt,
    };
  };
}
