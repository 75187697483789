import { gql } from '@apollo/client';

export const SAVE_QUERY = gql`
  mutation($input: [CollectionParentSetInput!]!) {
    collectionParentSet(input: $input) {
      id
      sortIndex
      parentId
    }
  }
`;
