import React from 'react';
import { GET_QUERY } from './query';
import { translate } from 'shared/translate';
import { Box, Container, Typography, Card, CardContent, Grid, Divider } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';
import { NumberFormat, parseMedia } from '../../shared';
import LSkeleton from '../../components/LSkeleton';
import PrintTable from '../../components/PrintTable';
import CommonItemRow from './CommonItemRow';
import BundleProductItemRow from './BundleProductItemRow';
import ServiceBundleItemRow from './ServiceBundleItemRow';
import _ from 'lodash';

export default ({ match: { params: { id } = {} } = {} }) => {
  const { loading, data: { node, shop } = {} } = useQuery(GET_QUERY, {
    variables: { id, shopId: localStorage.getItem('shopId') },
    skip: !id,
  });

  if (!id) return null;

  const { fontSize = '', sort = '' } = JSON.parse(
    '{"' + window.location.search.substring(1).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
  );

  const sortedNode = {
    ...node,
    items: _.sortBy(node?.items, (item) => {
      function getProductItem(item) {
        return item?.productVariation || item?.bundleProduct?.bundleProduct || item?.addOnProduct;
      }

      if (sort === 'sku') {
        return getProductItem(item?.orderItem)?.sku?.toUpperCase();
      } else {
        return node?.order?.checkout?.items?.find(
          (_item) => getProductItem(_item)?.id === getProductItem(item?.orderItem)?.id,
        )?.createdAt;
      }
    }),
  };

  const _loading = loading || !sortedNode;
  const { items = [], referenceNo: dnNo, address, order } = sortedNode || {},
    { referenceNo } = order || {},
    { logoMedia, name } = shop || {},
    { person, tel, lines } = address || {},
    totalQuantity = _.sumBy(items, 'quantity');

  return (
    <>
      <style>{`html, body, #root { 
        height: auto; 
        font-size: ${fontSize} !important;
      }
      @media print {
        html,
        body {
          font-size: ${fontSize} !important;
        }
      `}</style>
      <Container style={{ minWidth: 1024 }}>
        <Card square>
          <CardContent>
            <Grid container>
              <Grid item xs>
                <Typography style={{ fontWeight: 'bold', fontSize: '2.125rem' }}>
                  DN {translate.delivery_notes}
                </Typography>
              </Grid>
              <Grid item>
                {_loading ? (
                  <Skeleton variant={'rect'} height={80} width={80} />
                ) : !!logoMedia ? (
                  <img style={{ height: 80 }} src={parseMedia(logoMedia)} alt={'shop_logo'} />
                ) : (
                  <Typography style={{ fontWeight: 'bold', fontSize: '2.125rem' }}>{name}</Typography>
                )}
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                    {translate.order_details}
                    {': '}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontSize: '1rem' }}>
                    {translate.order_number}
                    {': '}
                    {_loading ? <LSkeleton width={80} /> : `${referenceNo}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontSize: '1rem' }}>
                    {translate.delivery_notes_no}
                    {': '}
                    {_loading ? <LSkeleton width={80} /> : `${dnNo}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontSize: '1rem' }}>
                    {translate.shipping_date}
                    {': '}
                  </Typography>
                </Grid>
              </Grid>
              <Box my={1}>
                <Divider style={{ height: 2 }} />
              </Box>
              <Grid container>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                    {translate.shipping_address}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontSize: '1rem' }}>
                    {translate.contact_person}
                    {': '}
                    {_loading ? <LSkeleton width={80} /> : person || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontSize: '1rem' }}>
                    {`${translate.contact_phone_no}: ${tel || '-'}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align={'left'} style={{ fontSize: '1rem' }}>
                    {translate.shipping_address}
                    {': '}
                    {_loading ? <LSkeleton width={300} /> : `${(lines || ['-']).join('\n')}`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <PrintTable
                loading={loading}
                data={_.filter(items, ({ orderItem: item }) => {
                  if (!!item?.bundleProduct) return item?.bundleProduct?.bundleProduct?.sku;
                  if (!!item?.addOnProduct) return item?.addOnProduct?.sku;
                  return item?.productVariation?.sku;
                })}
                columns={[
                  {
                    title: translate.product_details,
                    render: ({ orderItem: item }) => {
                      if (!!item?.bundleProduct) return <BundleProductItemRow item={item} />;
                      if (!!item?.serviceBundle) return <ServiceBundleItemRow item={item} />;
                      return <CommonItemRow item={item} />;
                    },
                  },
                  {
                    title: translate.quantity,
                    width: 120,
                    align: 'right',
                    value: ({ quantity }) => NumberFormat(quantity),
                  },
                ]}
              />

              <Box textAlign={'right'} padding={2}>
                <Typography style={{ fontSize: '1rem' }}>{totalQuantity}</Typography>
              </Box>
            </Box>
            <Box mb={1} mt={3}>
              <Divider />
            </Box>
            <Grid container direction="column">
              <Grid item>
                <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>{translate.remark}</Typography>
              </Grid>
              {!!node?.order?.remark && (
                <Grid item>
                  <Typography style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}>{node?.order?.remark}</Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
