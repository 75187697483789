import React from 'react';
import SystemButton from '../../components/SystemButton';
import { translate } from '../../shared/translate';
import ActivityIndicator from '../../components/ActivityIndicator';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { VOID_QUERY } from './query';
import { client } from '../../shared/apollo';

const VoidButton = ({ values: { id } = {}, setFieldValue, isSubmitting, setSubmitting }) => {
  return (
    <SystemButton
      color={'error'}
      disabled={isSubmitting}
      onClick={async () => {
        try {
          ActivityIndicator.show();
          setSubmitting(true);
          const { data: { receivePurchaseVoid } = {} } = await client.mutate({
            mutation: VOID_QUERY,
            variables: { id },
          });
          setFieldValue('status', (receivePurchaseVoid || {}).status);
          toast.success(translate.update_success);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          ActivityIndicator.hide();
          setSubmitting(false);
        }
      }}
    >
      {translate.cancel}
    </SystemButton>
  );
};

export default VoidButton;
