import React from 'react';
import { GET_CHANNEL_MESSAGES } from '../../query';
import { Grid, Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { ReactTinyLink } from 'react-tiny-link';
import CursorBaseList from '../../../CursorBaseList';
const extractUrls = require('extract-urls');

export default ({ shopId, channelId }) => {
  return (
    <Box p={2}>
      <Grid container spacing={1}>
        <CursorBaseList
          query={GET_CHANNEL_MESSAGES}
          variables={{
            id: channelId,
            playerId: shopId ?? localStorage.getItem('shopId'),
            filter: { type: { operator: 'IN', value: ['LINK'] } },
            limits: 20,
          }}
          queryProps={{
            skip: !channelId,
          }}
          getConnection={(data) => data?.node?.messages}
          renderItems={(messages) => (
            <>
              <Grid item xs={12}>
                <Typography component={'div'} variant={'caption'}>
                  {moment(messages[0]?.createdAt).format('DD/MM YYYY')}
                </Typography>
              </Grid>
              {messages.map(({ id, type, channelPlayerId, content, createdAt }, i) => {
                return (
                  <>
                    {i > 0 &&
                    moment(messages[i - 1].createdAt).format('yyyyMM') - 0 > moment(createdAt).format('yyyyMM') - 0 ? (
                      <Grid item xs={12}>
                        <Typography component={'div'} variant={'caption'}>
                          {moment(createdAt).format('DD/MM YYYY')}
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12}>
                      <ReactTinyLink
                        cardSize="small"
                        showGraphic={true}
                        maxLine={2}
                        minLine={1}
                        url={decodeURI(extractUrls(content)[0] || '')}
                      />
                    </Grid>
                  </>
                );
              })}
            </>
          )}
        />
      </Grid>
    </Box>
  );
};
