import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: UserCouponFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Customer {
        coupons(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            id
            createdAt
            validThru
            handle
            coupon {
              id
              name
              resolveCode
            }
            status
          }
        }
      }
    }
  }
`;

export const USER_COUPON_APPLY = gql`
  mutation USER_COUPON_APPLY($couponId: ID!) {
    customerCouponVoid(couponId: $couponId) {
      id
      status
    }
  }
`;

export const USER_DEACTIVATE_COUPON = gql`
  mutation USER_DEACTIVATE_COUPON($couponId: ID!) {
    customerCouponCancel(couponId: $couponId) {
      id
      status
    }
  }
`;
