import React from 'react';
import InputField from '../../../FormPageMaker/Fields/InputField';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { convertMetaObject } from '../../../../shared';

const NumberField = ({ disabled, value, onChange, field = {} }) => {
  const { metadata, name, required } = field ?? {};
  const { remarks, step, min, max } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <InputField
        value={value}
        type={'number'}
        onChange={(e) => onChange(e?.target?.value)}
        required={required}
        disabled={disabled}
        inputProps={{
          step,
          min,
          max,
        }}
      />
    </CustomFieldWrapper>
  );
};

export default NumberField;
