import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { getCommonColumns } from 'shared';
import { TableStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id, name }) => ({
      pathname: `/tables/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
      },
      {
        title: translate.venue_name,
        align: 'left',
        width: 150,
        value: ({ venue }) => {
          const { name, id } = venue || {};
          return name || id;
        },
      },
      {
        title: translate.status,
        align: 'center',
        fieldName: 'status',
        type: 'option',
        options: Object.keys(TableStatus).map((key) => ({
          label: <StatusBadge statusObject={TableStatus} status={key} />,
          value: key,
        })),
        width: 200,
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        width: 50,
        type: 'number',
        sortBy: 'sortIndex',
      },
      ...getCommonColumns(),
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    const { tables } = node || {};
    return tables;
  };
}
