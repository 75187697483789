import { translate } from './translate';

export const CompanyShopType = [
  {
    label: translate.food_beverages,
    value: 'FOOD_BEVERAGES',
  },
  {
    label: translate.retail_industry,
    value: 'RETAIL_INDUSTRY',
  },
  {
    label: translate.service,
    value: 'SERVICE',
  },
];
