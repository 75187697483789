import React from 'react';
import { translate } from 'shared/translate';
import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import FormRow from '../../FormRow';
import DateTimeField from '../../FormPageMaker/Fields/DateTimeField';

export default ({ filterInputProps, title, title2, filter, onFilterChange = (_) => _ }) => {
  const { sinceProps, untilProps } = filterInputProps || {};
  const { value } = filter || {},
    [since = '∆', until = '∆'] = value || [];
  const update = (since, until) => {
    onFilterChange({
      operator: 'BETWEEN',
      value: [since || '∆', until || '∆'],
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormRow title={title}>
            <DateTimeField
              type={'datetime-local'}
              onChange={(v) => update(v, until)}
              value={since?.replace('∆', '')}
              // eslint-disable-next-line
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Typography>{translate.start_attr}</Typography>
                  </InputAdornment>
                ),
              }}
              {...sinceProps}
            />
          </FormRow>
        </Grid>
        <Grid item xs={6}>
          <FormRow title={title2 || '　'}>
            <DateTimeField
              type={'datetime-local'}
              onChange={(v) => update(since, v)}
              value={until?.replace('∆', '')}
              // eslint-disable-next-line
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Typography>{translate.end_attr}</Typography>
                  </InputAdornment>
                ),
              }}
              {...untilProps}
            />
          </FormRow>
        </Grid>
      </Grid>
    </Box>
  );
};
