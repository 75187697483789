import { TableCell, withStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    borderBottom: '1px solid #bbb',
  },
  body: {
    borderBottom: 'none',
  },
}))(TableCell);

export default StyledTableCell;
