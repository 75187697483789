import { Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import React from 'react';
import FormRow from '../../components/FormRow';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';
import { translate } from '../../shared/translate';
import FormToolbar from '../../components/FormToolbar';
import { Formik } from 'formik';

const PrintOptionsDialog = ({ open, onClose, onSubmit = (_) => {}, orderedBy = undefined }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: '400px' },
      }}
    >
      <DialogTitle style={{ alignSelf: 'center' }}>{translate.printing_options || '打印選項'}</DialogTitle>
      <Divider style={{ margin: '0 30px' }} />
      <Formik
        onSubmit={async (values) => {
          await onSubmit(values);
          onClose();
        }}
        initialValues={{ fontSize: '14px', sortProducts: 'createdAt' }}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ padding: '10px 30px' }}>
                <FormRow title={translate.font_size || '字體大小'} required={true}>
                  <SelectField
                    value={values.fontSize}
                    disabled={isSubmitting}
                    options={[
                      { label: translate.small || '小', value: '12px' },
                      { label: translate.medium || '中', value: '14px' },
                      { label: translate.large || '大', value: '16px' },
                    ]}
                    onChange={(e) => {
                      setFieldValue('fontSize', e.target.value);
                    }}
                  />
                </FormRow>
                <FormRow title={translate.sort_products || '產品排序'} required={true}>
                  <SelectField
                    value={values.sortProducts}
                    disabled={isSubmitting}
                    options={[
                      { label: translate.by_sku || '按產品編號排序', value: 'sku' },
                      { label: orderedBy || translate.by_cart_sequence || '按購物車順序排序', value: 'createdAt' },
                    ]}
                    onChange={(e) => {
                      setFieldValue('sortProducts', e.target.value);
                    }}
                  />
                </FormRow>
              </DialogContent>
              <FormToolbar loading={isSubmitting} disabled={isSubmitting} submitBtnType={'submit'} onCancel={onClose} />
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default PrintOptionsDialog;
