import React from 'react';
import { Skeleton } from '@material-ui/lab';
import FormPageMaker from '../../components/FormPageMaker';
import LSkeleton from '../../components/LSkeleton';
import SelectComboWarehouse from '../../components/SelectCombo/SelectComboWarehouse';
import StatusBadge from '../../components/StatusBadge';
import { convertMetaObject } from '../../shared';
import { StockTakeStatus } from '../../shared/omniwe-types';
import { translate } from '../../shared/translate';
import StockTakeExportButton from './StockTakeExportButton';
import StockTakeImportButton from './StockTakeImportButton';
import { GET_QUERY, SUBMIT_QUERY } from './query';
import StockTakeCompleteButton from './StockTakeCompleteButton';
import StockTakeRejectButton from './StockTakeRejectButton';
import { CardContent, Divider, Typography } from '@material-ui/core';
import { checkPermissionV2 } from '../../components/PermissionMask';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { client } from '../../shared/apollo';
import StockTakeItemListView from './StockTakeItemListView';

class ItemStockTake extends FormPageMaker {
  state = {
    ...this.state,
    editMode: !!this.state.id,
    gql: {
      prepare: GET_QUERY,
      submit: checkPermissionV2('updateReceivePurchase') ? SUBMIT_QUERY : undefined,
      update: checkPermissionV2('updateReceivePurchase') ? SUBMIT_QUERY : undefined,
    },
    formDisabled: !checkPermissionV2('updateReceivePurchase'),
    tabs: [
      {
        name: translate.stock_take,
        cards: [
          {
            fields: [
              {
                label: translate.stocktake_type,
                render: ({ loading, values: { metadata } }) =>
                  loading ? (
                    <Skeleton width={100} height={30} />
                  ) : (
                    <Typography>
                      {!!+metadata?.replace ? translate.stocktake_replace : translate.stocktake_partial}
                    </Typography>
                  ),
                display: () => !!this.state.id,
                block: true,
              },
              {
                label: translate.status,
                render: ({ loading, values: { status } }) =>
                  loading ? (
                    <Skeleton width={100} height={30} />
                  ) : (
                    <StatusBadge status={status} statusObject={StockTakeStatus} />
                  ),
                display: () => !!this.state.id,
                md: 6,
              },
              {
                label: translate.reference_no,
                disabled: true,
                name: 'referenceNo',
                type: 'text',
                display: () => !!this.state.id,
                md: 6,
              },
              {
                label: translate.warehouse,
                required: true,
                render: ({ loading, values, setFieldValue }) =>
                  loading ? (
                    <LSkeleton height={30} />
                  ) : (
                    <SelectComboWarehouse
                      value={values.warehouse}
                      disabled={true}
                      required
                      onChange={(v) => setFieldValue('warehouse', v)}
                    />
                  ),
              },
              {
                label: translate.operator,
                name: 'staff.name',
                type: 'text',
                disabled: true,
                md: 6,
              },
              {
                md: 12,
                render: ({ loading, values, disabled, setFieldValue }) => {
                  const allowEdit = !values?.id || (this.state.dirty && values?.status === 'PENDING');
                  return (
                    <CardContent style={{ backgroundColor: '#f6f6f6', borderRadius: 10, padding: 30 }}>
                      <Typography variant={'h6'}>{translate.remark}</Typography>
                      <Divider style={{ margin: '10px 0 15px 0' }} />
                      {loading ? (
                        <LSkeleton height={30} />
                      ) : (
                        <InputField
                          variant={allowEdit ? 'outlined' : 'standard'}
                          InputProps={{
                            disableUnderline: allowEdit ? false : true,
                          }}
                          value={values.remark}
                          disabled={disabled || values?.status !== 'PENDING'}
                          onChange={(v) => setFieldValue('remark', v?.target?.value)}
                        />
                      )}
                    </CardContent>
                  );
                },
              },
            ],
          },
        ],
      },
      {
        name: translate.stocktake_items,
        noPadding: true,
        cards: [StockTakeItemListView],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit, update },
    } = this.state;

    await client.mutate({
      mutation: !!this.state.id ? update : submit,
      variables: {
        id: values.id,
        input: {
          remark: values.remark,
          warehouseId: values.warehouse?.id,
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const {
      id,
      warehouse,
      updatedAt,
      deviceId,
      staff,
      items = {},
      status = 'PENDING',
      referenceNo,
      metadata,
      remark,
    } = node || {};
    return {
      id,
      referenceNo,
      warehouse,
      updatedAt,
      staff,
      deviceId,
      items: items.nodes || [],
      status,
      metadata: convertMetaObject(metadata),
      remark,
    };
  }

  allowSubmit(actions) {
    const {
      values: { status },
    } = actions;
    if (!super.allowSubmit(actions)) return false;
    if (status !== 'PENDING') return false;
    return true;
  }

  onCompleted(data) {
    if (data.node.status !== 'PENDING') {
      this.setState({
        editMode: false,
      });
    }
  }

  renderExtraButtons(actions) {
    const { values: { id, status } = {} } = actions || {};
    const disabled = !!~['COMPLETED', 'CANCELLED'].indexOf(status);

    return (
      !!id &&
      !disabled && (
        <>
          {checkPermissionV2('exportStockTake') && <StockTakeExportButton id={id} />}
          {checkPermissionV2('importStockTake') && <StockTakeImportButton id={id} />}
          {checkPermissionV2('completeStockTake') && <StockTakeCompleteButton {...actions} />}
          {checkPermissionV2('rejectStockTake') && <StockTakeRejectButton {...actions} />}
        </>
      )
    );
  }
}

export default ItemStockTake;
