import { gql } from '@apollo/client';
export const FRAGMENT_SHIPPING_ZONE = gql`
  fragment FRAGMENT_SHIPPING_ZONE on ShopShippingZone {
    id
    createdAt
    updatedAt
    name
    provider
    sortIndex
    active
  }
`;

export const GET_QUERY = gql`
  query(
    $id: ID!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: ShopShippingZoneFilterInput
    $query: String
  ) {
    node(id: $id) {
      id
      ... on CompanyShop {
        shippingZones(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_SHIPPING_ZONE
          }
        }
      }
    }
  }
  ${FRAGMENT_SHIPPING_ZONE}
`;

export const BULK_ACTION_QUERY = gql`
  mutation($shopId: ID!, $action: ShopShippingZoneBulkAction!, $data: String, $filter: ShopShippingZoneFilterInput!) {
    shopShippingZoneBulkAction(shopId: $shopId, action: $action, data: $data, filter: $filter)
  }
`;
