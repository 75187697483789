import { gql } from '@apollo/client';

export const PRODUCTS_SEO_IMPORT_QUERY = gql`
  mutation ($shopId: ID!, $url: AWSURL!) {
    productsSeoImport(shopId: $shopId, url: $url) {
      id
      status
      output
    }
  }
`;

export const PRODUCTS_UPDATE_IMPORT_QUERY = gql`
  mutation ($shopId: ID!, $url: AWSURL!) {
    productsUpdateImport(shopId: $shopId, url: $url) {
      id
      status
      output
    }
  }
`;

export const PRODUCTS_IMPORT_QUERY = gql`
  mutation ($shopId: ID!, $url: AWSURL!) {
    productsImport(shopId: $shopId, url: $url) {
      id
      status
      output
    }
  }
`;
