import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { Icon } from './IconRender';
import { binDisabledColor } from '../theme';

const CommonIconButton = ({
  icon,
  href = undefined,
  loading = false,
  disabled = loading,
  loadingSize = 16,
  title = '',
  viewBox = '0 0 22 22',
  ...props
}) => {
  const core = (
    <IconButton
      disabled={disabled}
      {...props}
      style={{
        color: disabled ? binDisabledColor : undefined,
        ...props.style,
      }}
    >
      {loading ? (
        <CircularProgress size={loadingSize} color={'inherit'} />
      ) : (
        <Icon icon={icon} type={'svg'} viewBox={viewBox} />
      )}
    </IconButton>
  );
  const printCore = (
    <IconButton href={href} target={'_blank'} {...props}>
      <Icon icon={icon} type={'svg'} viewBox={viewBox} />
    </IconButton>
  );
  if (title) return <Tooltip title={title}>{!!href ? printCore : core}</Tooltip>;
  return core;
};

export default CommonIconButton;
