import React, { useRef, useState } from 'react';
import SystemButton from './SystemButton';
import { translate } from '../shared/translate';
import toast from '../shared/toast';
import errorParser from '../shared/errorParser';
import { client } from '../shared/apollo';
import s3Upload from '../shared/s3Upload';
import CommonImportModal from './CommonImportModal';

const CommonImportButton = ({ id, query, name, tips, demoFile }) => {
  const importInput = useRef();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  if (!!name)
    return (
      <>
        <SystemButton
          loading={loading}
          disabled={loading}
          color={'white'}
          onClick={async (e) => {
            e.preventDefault();
            setVisible(!visible);
          }}
        >
          {translate.import}
        </SystemButton>
        <CommonImportModal
          visible={visible}
          onOpen={() => setVisible(!visible)}
          query={query}
          id={id}
          name={name}
          tips={tips}
          demoFile={demoFile}
        />
      </>
    );

  return (
    <div>
      <SystemButton
        loading={loading}
        disabled={loading}
        color={'white'}
        onClick={async (e) => {
          e.preventDefault();
          importInput.current.click();
        }}
      >
        導入
      </SystemButton>
      <input
        ref={importInput}
        style={{ visibility: 'hidden', position: 'fixed' }}
        type={'file'}
        onChange={async (evt) => {
          setLoading(true);
          const files = evt.target.files;
          const file = files[0];
          try {
            evt.target.value = null;

            const s3Path = await s3Upload(file);
            await client.mutate({
              mutation: query,
              variables: {
                id,
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
              },
            });
            toast.success(translate.importing_submit);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setLoading(false);
          }
        }}
      />
    </div>
  );
};

export default CommonImportButton;
