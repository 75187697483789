import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';
import { translate } from '../../shared/translate';

const OrderAutoCancelField = ({ value, onChange, disabled }) => {
  const enabled = value?.seconds !== null;

  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={true} md={9}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h6">
              {translate.auto_cancel_overdue_payment_time_orders || '自動取消「超過付款時間」的訂單'}
            </Typography>
          </Grid>
          <Grid item>
            <InputField
              type={'number'}
              disabled={disabled || !enabled}
              style={{ width: 50 }}
              value={enabled ? value?.hours : undefined}
              inputProps={{
                min: 1,
                step: 1,
              }}
              onChange={(e) => {
                onChange({
                  years: 0,
                  months: 0,
                  weeks: 0,
                  days: 0,
                  hours: e.target.value,
                  minutes: 0,
                  seconds: 0,
                });
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6">{translate.in_hours || '小時'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {translate.not_complete_before_deadline_automatically_cancel_order ||
                '若買家未於規定之付款期限內完成付款，則逾期訂單將自動取消'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SwitchField
          checked={enabled}
          disabled={disabled}
          onChange={() => {
            onChange({ ...value, seconds: enabled ? null : 0 });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OrderAutoCancelField;
