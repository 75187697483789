import { gql } from '@apollo/client';

export const FRAGMENT_DISCOUNT = gql`
  fragment FRAGMENT_DISCOUNT on CompanyDiscount {
    id
    createdAt
    updatedAt
    name
    remark
    active
    sortIndex
    sortIndex
    publishAt
    publishThru
    triggers {
      triggerType
      triggerId
      triggerIds
      triggerValueType
      triggerValue
    }
    actions {
      actionType
      actionId
      actionIds
      actionValueType
      actionValue
    }
  }
`;

export const GET_QUERY = gql`
  query($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $query: String, $filter: DiscountFilterInput) {
    node(id: $id) {
      id
      ... on Company {
        id
        discounts(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_DISCOUNT
          }
        }
      }
    }
  }
  ${FRAGMENT_DISCOUNT}
`;
