import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ReactComponent as IconNotEnoughData } from '../../../assets/icon/not enough data.svg';
import { translate } from '../../../shared/translate';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartCard = ({ loading, label, value = [] }) => {
  const colors = [
    '#f0ede9',
    '#e4944c',
    '#727679',
    '#131939',
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b',
  ];

  const labels = value.map(({ name }) => name);

  const datasets = [
    {
      data: value.map(({ value }) => value),
      backgroundColor: value.map((_, i) => (colors || [])[i]),
      borderColor: value.map((_, i) => (colors || [])[i]),
      borderWidth: 1,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant={'h6'} color={'secondary'}>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <IconNotEnoughData width={'100%'} />
            ) : !value.length ? (
              <Typography variant="h6" align="center">
                {translate.no_info || '沒有資料'}
              </Typography>
            ) : (
              <Pie
                data={{
                  labels,
                  datasets,
                }}
                width={400}
                height={400}
                options={{ maintainAspectRatio: false }}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PieChartCard;
