import React, { useEffect, useState } from 'react';
import SystemButton from '../../components/SystemButton';
import { translate } from '../../shared/translate';
import { Box, Dialog, DialogTitle } from '@material-ui/core';
import TextareaField from '../../components/FormPageMaker/Fields/TextareaField';
import FormToolbar from '../../components/FormToolbar';
import YAML from 'yaml';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';

const SourceDataButton = ({ disabled, value, onChange = (_) => {} }) => {
  const [cache, setCache] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) setCache(YAML.stringify(value));
  }, [visible]);

  return (
    <>
      <SystemButton disabled={disabled} color={'info'} onClick={() => setVisible(true)}>
        {translate.page_data}
      </SystemButton>
      <Dialog open={visible} maxWidth={'lg'} fullWidth>
        <DialogTitle>{translate.page_data}</DialogTitle>
        <Box p={3} style={{ overflowY: 'auto' }} height={'50vh'} maxHeight={1000} minHeight={300}>
          <TextareaField value={cache} onChange={(v) => setCache(v)} />
        </Box>
        <FormToolbar
          submitBtnType={'button'}
          onCancel={() => setVisible(false)}
          onSave={() => {
            try {
              const newBody = YAML.parse(cache);
              onChange(newBody);
              setVisible(false);
            } catch (e) {
              toast.error(errorParser(e));
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default SourceDataButton;
