import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';

const STATUS_ACTIVATE = gql`
  mutation ($id: ID!) {
    companyCouponActivate(id: $id) {
      id
      active
    }
  }
`;

const STATUS_DEACTIVATE = gql`
  mutation ($id: ID!) {
    companyCouponDeactivate(id: $id) {
      id
      active
    }
  }
`;

export default ({ id, data, active, onCompleted }) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    if (active !== isActive) {
      setIsActive(active);
    }
  }, [active]);

  return (
    <SwitchField
      checked={isActive}
      value={isActive}
      disabled={loading}
      onChange={async () => {
        try {
          setIsActive(!active);
          setLoading(true);
          if (isActive)
            await client.mutate({
              mutation: STATUS_DEACTIVATE,
              variables: {
                id,
              },
            });
          if (!isActive)
            await client.mutate({
              mutation: STATUS_ACTIVATE,
              variables: {
                id,
              },
            });
          onCompleted();
        } catch (e) {
          toast.error(errorParser(e));
          setIsActive(active);
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
