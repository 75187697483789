import { gql } from '@apollo/client';

export const ORDER_MEMBER_POINTS_DEDUCT = gql`
  mutation($id: ID!, $points: Int) {
    orderMemberPointsDeduct(id: $id, points: $points) {
      id
      deductibleMemberPoints
    }
  }
`;
