import React from 'react';
import { translate } from '../../shared/translate';
import Taber from '../../components/Taber';
import ListCustomPage from './ListCustomPage';

export default (props) => {
  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.all_pages || '所有頁面',
          },
        ]}
      />
      <ListCustomPage {...props} />
    </div>
  );
};
