import React from 'react';
import { Chip, makeStyles, Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import ColorField from './ColorField';
import ColorBlock from '../../ColorBlock';
import Dnd from '../../DND';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: `6.5px ${theme.spacing(1.5)}px`,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#cbcbcb',
    borderRadius: 10,
    background: '#ffffff',
    outline: 'none',
  },
}));

export default ({ value = [], onChange = (_) => _, disabled, className = '' }) => {
  const classes = useStyles();

  return (
    <Box className={`${className} ${classes.wrapper}`}>
      {value.length > 0 && (
        <Box mb={1}>
          <Dnd
            items={value}
            onChange={(value) => onChange(value.filter((_) => _))}
            disabled={disabled}
            renderItem={(id, index) => (
              <Chip
                size={'small'}
                label={
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                      <ColorBlock color={id} />
                    </Grid>
                    <Grid item>{id}</Grid>
                  </Grid>
                }
                onClick={() => {
                  value.splice(index, 1);
                  onChange(value.filter((_) => _));
                }}
                onDelete={() => {
                  value.splice(index, 1);
                  onChange(value.filter((_) => _));
                }}
              />
            )}
          />
        </Box>
      )}
      <ColorField
        disabled={disabled}
        onChange={(color) => {
          onChange(_.uniq([...value, color]));
        }}
      />
    </Box>
  );
};
