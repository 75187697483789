import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputField from './InputField';
import moment from 'moment';
import LSkeleton from '../../LSkeleton';
import CheckboxField from './CheckboxField';
import { translate } from '../../../shared/translate';

const DateTimeField = ({
  loading,
  type = 'date',
  value: _value,
  onChange,
  defaultValue,
  disabled,
  hasPermanent,
  permanentText = translate.permanent,
  ...props
}) => {
  const value = hasPermanent ? _value : _value === null ? undefined : _value;

  const [initialize, setInitialize] = useState(!!value);
  useEffect(() => {
    if (defaultValue !== undefined || value !== undefined) setInitialize(true);
  }, [defaultValue, value]);

  if (loading) return <LSkeleton height={30} />;

  return (
    <div
      style={{ position: 'relative' }}
      onClick={() => {
        if (!initialize) {
          setInitialize(true);
          if (value !== null) onChange(value || defaultValue || moment().toISOString());
          else onChange(null);
        }
      }}
    >
      <InputField
        type={value === null ? 'text' : type}
        value={
          !!value ? moment(value).format(type === 'datetime-local' ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD') : undefined
        }
        placeholder={value === null ? hasPermanent && permanentText : undefined}
        onChange={(e) => {
          if (e.target.value) onChange(moment(e.target.value).toISOString());
        }}
        {...props}
        disabled={disabled || value === null}
        InputProps={{
          ...props?.InputProps,
          endAdornment: !!hasPermanent && !!initialize && (
            <CheckboxField
              style={{ marginLeft: 24, height: 0 }}
              disabled={disabled}
              text={permanentText}
              textVariant="body2"
              checked={value === null}
              onChange={() => {
                onChange(value === null ? moment().add(1, 'month').toISOString() : null);
              }}
            />
          ),
        }}
      />
      {!initialize && (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
          <InputField placeholder={type === 'datetime-local' ? 'YYYY/MM/DD --:--' : 'YYYY/MM/DD'} {...props} />
        </div>
      )}
    </div>
  );
};
export default DateTimeField;

DateTimeField.propTypes = {
  type: PropTypes.oneOf(['date', 'datetime-local']),
};
