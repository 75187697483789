import React, { useState } from 'react';
import { GET_QUERY } from './query';
import { useQuery } from '@apollo/client';
import ActivityChain from '../../../components/ActivityChain';
import { convertMetaObject, NumberFormat, parseConnection } from '../../../shared';
import { translate, translateFormat } from '../../../shared/translate';
import { Box, Typography } from '@material-ui/core';
import OnScreenTriggerer from '../../../components/OnScreenTriggerer';
import _ from 'lodash';

export default ({ values: { id } = {} }) => {
  const [fetchingMore, setFetchingMore] = useState(false);
  const { loading, data, fetchMore } = useQuery(GET_QUERY, { variables: { id, limits: 20 }, skip: !id }),
    { nodes, nextCursor } = getConnection(data);
  return (
    <Box pr={5} pt={5} pb={5}>
      <ActivityChain
        loading={loading}
        items={nodes.map((node) => {
          const metadata = convertMetaObject(node?.metadata);
          const content = (() => {
            switch (metadata?.event) {
              case 'checkout.pay':
              case 'checkout.pay.update':
              case 'order.confirm':
              case 'checkout.create.offline':
              case 'delivery_note.process':
                return translateFormat(translate.point_record_order_confirm, { referenceNo: metadata?.referenceNo });
              case 'coupon.redeem':
                return translateFormat(translate.point_record_coupon_redeem, { name: metadata?.name });
              case 'customers.update.member_point':
              case 'order.update.member_point':
                return translateFormat(translate.point_record_gift_point_return, {
                  referenceNo: metadata?.referenceNo,
                });
              case 'customer.update.member_point':
              default:
                return node?.description || translate.point_record_adjustment;
            }
          })();
          return {
            ...node,
            event: (
              <Typography>
                {translate.point} {node.direction === 'INBOUND' ? '+' : '-'} {NumberFormat(node.points)}
                <br />
                {content}
              </Typography>
            ),
          };
        })}
      />
      <OnScreenTriggerer
        onTrigger={async () => {
          if (nextCursor && !fetchingMore) {
            setFetchingMore(true);
            await fetchMore({
              variables: { cursor: nextCursor },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                setFetchingMore(false);

                const fetchMoreConnection = getConnection(fetchMoreResult),
                  { nodes: fetchMoreNodes } = fetchMoreConnection,
                  { nodes: prevNodes } = getConnection(prevResult);
                fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

                return {
                  node: {
                    ...(fetchMoreResult.node || {}),
                    giftPointsHistory: fetchMoreConnection,
                  },
                };
              },
            });
          }
        }}
      />
    </Box>
  );

  function getConnection({ node } = {}) {
    return parseConnection((node || {}).giftPointsHistory);
  }
};
