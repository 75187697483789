import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { getCommonColumns } from 'shared';
import ActiveSwitcher from '../ListView/ActiveSwitcher';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/users/new',
    getRowLink: ({ id, email }) => ({
      pathname: `/users/${id}`,
      state: { title: email },
    }),
    fields: [
      {
        title: translate._email,
        fieldName: 'email',
        filter: 'email',
        filterType: 'text',
        filterChannel: 'q',
        sortBy: 'email.keyword',
      },
      ...getCommonColumns({
        updatedAt: { column: false, filter: undefined },
      }),
      {
        title: translate.valid_status,
        width: 80,
        render: (data) => {
          const { id, blocked } = data || {};
          return <ActiveSwitcher id={id} blocked={blocked} data={data} />;
        },
        filter: 'blocked',
        filterType: 'reverseBool',
        filterChannel: 'q',
        sortBy: 'blocked',
        noLink: true,
      },
    ],
    hasQSearch: true,
    qFields: ['email'],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
  getData = ({ node } = {}) => {
    const { users } = node || {};
    return users;
  };
  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
