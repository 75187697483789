import { gql, useApolloClient, useQuery } from '@apollo/client';
import { JSONParseSafely, parseConnection } from '../shared';
import { useEffect, useState } from 'react';
import toast from '../shared/toast';
import errorParser from '../shared/errorParser';

const { REACT_APP_ASSETS_URL } = process.env;
export const GET_QUERY = gql`
  query ($id: ID!, $type: String) {
    node(id: $id) {
      id
      ... on CompanyShop {
        receiptTemplates(limits: 1, filter: { type: { operator: IN, value: [$type] } }) {
          nodes {
            id
            configs
          }
        }
      }
    }
  }
`;
const useReceiptTemplateConfig = (type = 'ORDER') => {
  const client = useApolloClient();
  const [config, setConfig] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const { data: { node } = {} } = useQuery(GET_QUERY, {
    variables: { type, id: localStorage.getItem('shopId') },
  });

  useEffect(() => {
    (async () => {
      try {
        const [defaultConfig, config] = await Promise.all([
          (async () => {
            const lang =
              {
                hk: 'zh-HK',
                'zh-hk': 'zh-HK',
                'zh-HK': 'zh-HK',
                cn: 'zh-CN',
                'zh-cn': 'zh-CN',
                'zh-CN': 'zh-CN',
                en: 'en-US',
                'en-us': 'en-US',
                'en-US': 'en-US',
              }[localStorage.getItem('lang')] || 'zh-HK';
            const res = await fetch(`${REACT_APP_ASSETS_URL}/receipt_templates/default/${lang}/${type}.json`);
            return await res.json();
          })(),
          (async () => {
            const { data } = await client.query({
              query: GET_QUERY,
              variables: { type, id: localStorage.getItem('shopId') },
            });
            return JSONParseSafely(parseConnection(data?.node?.receiptTemplates).nodes?.[0]?.configs, null);
          })(),
        ]);
        setConfig(config || defaultConfig);
      } catch (e) {
        toast.error(errorParser(e));
      } finally {
        setLoading(false);
      }
    })();
  }, [type]);

  return { loading, config };
};

export default useReceiptTemplateConfig;
