import React from 'react';
import { translate } from '../../../../shared/translate';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper } from '../../../../shared';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import SystemButton from '../../../../components/SystemButton';

export const APPOINTMENT_ATTEND = gql`
  mutation ($id: ID!) {
    appointmentAttend(id: $id) {
      id
      status
    }
  }
`;

const ActionComplete = ({ appointments = [] }) => {
  return (
    <SystemButton
      color={'success'}
      disabled={appointments.length === 0}
      onClick={async () => {
        if (await confirmation(translate.formatString('是否確認出席 {0} 個預約？', appointments.length))) {
          await actionWrapper(
            async () => {
              for (let i = 0; i < appointments.length; i++) {
                const appointmentId = appointments[i]?.id;

                ActivityIndicator.show(`完成預約 ${i + 1}/${appointments.length}`);

                await client.mutate({
                  mutation: APPOINTMENT_ATTEND,
                  variables: {
                    id: appointmentId,
                  },
                });
              }
            },
            {
              messages: {
                success: '出席成功',
              },
            },
          );
        }
      }}
    >
      出席
    </SystemButton>
  );
};

export default ActionComplete;
