import React from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { Box, Tooltip, Typography } from '@material-ui/core';
import Media from '../../../../../components/Media';
import ComponentImage from '../image';
import SelectField from '../../../../../components/FormPageMaker/Fields/SelectField';
import HighlightFormRow from '../../HighlightFormRow';

export default {
  label: translate.feature,
  icon: <Icon icon={'faAddressCard'} />,
  defaultHeight: 0,
  preview: ({ data, height, setEditData }) => {
    const { src, title, subtitle, content, href, direction = 'row' } = data || {};
    return (
      <Box
        height={height || 'initial'}
        display={'flex'}
        flexDirection={direction}
        alignItems={'center'}
        justifyContent={'center'}
        onClick={() => setEditData(data)}
      >
        {!!href ? (
          <Tooltip title={href}>
            <Media
              src={src}
              style={{ marginBottom: 16, height: 100 }}
              placeholderColor={'transparent'}
              onClick={() => {}}
            />
          </Tooltip>
        ) : (
          <Media
            src={src}
            style={{ marginBottom: 16, height: 100 }}
            placeholderColor={'transparent'}
            onClick={() => {}}
          />
        )}
        <Box>
          {!!title && (
            <Typography
              align={
                {
                  column: 'center',
                  row: 'flex-start',
                }[direction]
              }
              variant={'h4'}
              paragraph
            >
              {title}
            </Typography>
          )}
          {!!subtitle && (
            <Typography
              align={
                {
                  column: 'center',
                  row: 'flex-start',
                }[direction]
              }
              variant={'subtitle1'}
              paragraph
            >
              {subtitle}
            </Typography>
          )}
          {!!content && (
            <Typography
              align={
                {
                  column: 'center',
                  row: 'flex-start',
                }[direction]
              }
              variant={'body2'}
              style={{ whiteSpace: 'pre' }}
            >
              {content}
            </Typography>
          )}
        </Box>
      </Box>
    );
  },
  edit: ({ value, onChange }) => {
    const { direction = 'row' } = value || {};
    return (
      <>
        <ComponentImage.edit value={value} onChange={onChange} />
        <HighlightFormRow highlight={value.direction !== undefined} title={translate.direction || 'direction'}>
          <SelectField
            value={direction}
            onChange={(e) => onChange({ ...value, direction: e.target.value })}
            options={[
              { label: 'Row', value: 'row' },
              { label: 'Column', value: 'column' },
            ]}
          />
        </HighlightFormRow>
      </>
    );
  },
};
