import { Box, Button, Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SelectField from './FormPageMaker/Fields/SelectField';
import { textColor } from '../theme';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderColor: (props) => `transparent transparent ${props.triangleColor || props.color} transparent`,
  },
  movablePlayground: {
    position: 'relative',
    height: theme.spacing(2),
  },
  movablePointer: {
    position: 'absolute',
    opacity: 0,
    height: theme.spacing(2),
    top: 0,
  },
  movablePointerReady: {
    opacity: 1,
  },
  movablePointerAni: {
    transition: 'all 0.5s',
  },
  tabLabel: {
    whiteSpace: 'pre',
    textTransform: 'none',
    color: '#B7BBBF',
    fontWeight: '400',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  tabLabelActive: {
    fontWeight: 'bold',
    color: textColor,
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectInput: {
    paddingLeft: theme.spacing(1),
    color: textColor,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
  },
  paper: {
    position: 'relative',
    backgroundColor: (props) => props.color,
  },
}));

export default ({
  color = '#fff',
  tabs = [],
  currentTab = 0,
  onTabChange = (_) => _,
  className = '',
  extra,
  extraButton,
  triangleColor,
}) => {
  const [refs] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [triangleLeft, setTriangleLeft] = useState(0);
  const classes = useStyles({ color, triangleColor });

  const updateTriangleLeft = (target) => {
    setTriangleLeft(target.offsetLeft + target.clientWidth / 2 - 32 / 2);
  };

  return (
    <Grid container direction={'column'} spacing={3} className={`${classes.wrapper} ${className}`}>
      <Grid item xs={12}>
        <Paper square elevation={0} className={classes.paper}>
          <Box px={1}>
            <Hidden xsDown>
              <Grid container spacing={1} alignItems={'center'}>
                {tabs.map(({ name, label, onClick }, i) => {
                  const active = currentTab === i;

                  return (
                    <Grid key={i} item>
                      <Button
                        ref={(ref) => {
                          refs[i] = ref;
                          if (i === currentTab && !!ref) updateTriangleLeft(ref);
                        }}
                        fullWidth={true}
                        onClick={(e) => {
                          setDirty(true);
                          updateTriangleLeft(e.currentTarget);
                          if (onClick) onClick();
                          else onTabChange(i);
                        }}
                      >
                        <Typography
                          noWrap={true}
                          className={`${classes.tabLabel} ${active ? classes.tabLabelActive : ''}`}
                        >
                          {name || label}
                        </Typography>
                      </Button>
                    </Grid>
                  );
                })}
                {!!extra && (
                  <Grid item xs={true}>
                    {extra}
                  </Grid>
                )}
                {!!extraButton && (
                  <Grid item xs={true} style={{ textAlign: 'right' }}>
                    {extraButton}
                  </Grid>
                )}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Box display={'flex'} alignItems={'center'}>
                <Box flex={1}>
                  <SelectField
                    className={classes.select}
                    value={currentTab}
                    variant={'standard'}
                    size={'medium'}
                    margin={'normal'}
                    // eslint-disable-next-line
                    inputProps={{
                      className: classes.selectInput,
                    }}
                    // eslint-disable-next-line
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const { onClick } = tabs[value] || {};
                      if (onClick) {
                        onClick();
                      } else {
                        onTabChange(value);
                      }
                    }}
                    options={tabs.map(({ name }, i) => ({ label: name, value: i }))}
                  />
                </Box>
                {extra}
                {!!extraButton && (
                  <Box flex={1} textAlign={'center'}>
                    {extraButton}
                  </Box>
                )}
              </Box>
            </Hidden>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Hidden xsDown>
          <div className={classes.movablePlayground}>
            <div
              className={`${classes.movablePointer} ${!!triangleLeft ? classes.movablePointerReady : ''} ${
                dirty ? classes.movablePointerAni : ''
              }`}
              style={{
                left: triangleLeft,
              }}
            >
              <div className={classes.triangle} />
            </div>
          </div>
        </Hidden>
      </Grid>
    </Grid>
  );
};
