import React from 'react';
import { translate } from '../../../shared/translate';
import SystemButton from '../../../components/SystemButton';

export default ({ isSubmitting, loading, values: { id } = {} }) => {
  return (
    <SystemButton
      disabled={isSubmitting || loading}
      size={'medium'}
      variant={'outlined'}
      className="normal-btn"
      onClick={() => {
        window && window.open(`/print_order/${id}`, '_blank');
      }}
    >
      {translate.button_print}
    </SystemButton>
  );
};
