import React from 'react';
import StockOverview from './StockOverview';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import CompanyShopSelector from '../item_SalePerformance/CompanyShopSelector';
import useQueryParams from '../../hooks/useQueryParams';
import { JSONParseSafely } from '../../shared';

export default (props) => {
  const [params, setParams] = useQueryParams(),
    shops = JSONParseSafely(params?.shops, []);
  return (
    <div>
      <Taber
        tabs={[
          {
            name: translate.overview,
          },
        ]}
        extra={
          <CompanyShopSelector
            extraNodes={[]}
            value={shops}
            onChange={(shops) => {
              setParams((obj) => ({
                ...obj,
                shops: JSON.stringify(shops),
              }));
            }}
            style={{
              width: 300,
            }}
            multiple={true}
          />
        }
      />
      <StockOverview key={shops?.length} {...props} />
    </div>
  );
};
