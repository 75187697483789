import { client } from './apollo';
import { gql } from '@apollo/client';

const UPLOAD_URL = gql`
  query ($input: UploadUrlInput!) {
    uploadUrl(input: $input)
  }
`;

export async function requestUploadUrl(mime) {
  const {
    data: { uploadUrl },
  } = await client.query({
    query: UPLOAD_URL,
    variables: {
      input: { mime },
    },
    fetchPolicy: 'no-cache',
  });
  return uploadUrl;
}

export function putFileReadableStream(url, file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve('Image successfully uploaded to S3');
        } else {
          reject('Error while sending the image to S3');
        }
      }
    };
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
}

export default async (file) => {
  const mime = file.type;
  const url = await requestUploadUrl(mime);

  await putFileReadableStream(url, file);

  return url.split('?')[0];
};
