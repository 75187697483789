import { gql } from '@apollo/client';
import { FRAGMENT_PRODUCT } from './ProductSelection/query';

export const GET_PRODUCT_QUERY = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $filter: ProductFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        products(query: $query, cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_PRODUCT
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`;
