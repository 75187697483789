import React from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { GET_QUERY } from './query';
import { useQuery } from '@apollo/client';
import { parseConnection } from '../../../../../shared';
import { Box, InputAdornment } from '@material-ui/core';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import PageBox from '../article/PageBox';
import ComponentSeparator from '../separator';
import HighlightFormRow from '../../HighlightFormRow';
import FlexGrid from '../../../../../components/FlexGrid';
import SelectField from '../../../../../components/FormPageMaker/Fields/SelectField';
import HashTagsArrayField from '../../../../../components/FormPageMaker/Fields/HashTagsArrayField';

export default {
  defaultHeight: 0,
  label: translate.tagged_articles || '已標籤文章',
  icon: <Icon icon={'faTag'} style={{ color: '#97a1aa' }} />,
  preview: function PreviewFC({ data, setEditData, height }) {
    const { limits = 4, itemPerRow = 4, title, spacing = 8, ratio, resizeMode = 'contain', hashtags = [] } = data || {};
    const { loading, data: { node } = {} } = useQuery(GET_QUERY, {
        variables: {
          id: localStorage.getItem('shopId'),
          limits,
          query: `hashtags.keyword:(${hashtags.map((tag) => `"${tag}"`).join(' AND ')})`,
        },
      }),
      { nodes: pages } = parseConnection((node || {}).pages);

    return (
      <Box
        minHeight={50}
        height={+height || 'initial'}
        display={'flex'}
        flexDirection={'column'}
        style={{ overflow: 'auto', cursor: 'pointer' }}
        onClick={() => setEditData(data)}
      >
        {!!title && (
          <div style={{ marginBottom: spacing }}>
            <ComponentSeparator.preview data={{ content: title }} />
          </div>
        )}
        <FlexGrid
          dynamic={!height}
          numRows={Math.ceil(limits / itemPerRow)}
          numColumns={itemPerRow}
          spacing={spacing}
          items={pages}
          renderItem={(page, { height }) => {
            return <PageBox loading={loading} height={height} page={page} resizeMode={resizeMode} ratio={ratio} />;
          }}
        />
      </Box>
    );
  },
  edit: ({ value, onChange }) => {
    const {
      limits = 4,
      itemPerRow = 4,
      title,
      spacing = 8,
      ratio,
      resizeMode = 'contain',
      hashtags = [],
    } = value || {};

    const update = (k, v) => {
      onChange({
        ...value,
        [k]: v,
      });
    };
    return (
      <>
        <HighlightFormRow highlight={value.title !== undefined} title={translate.title}>
          <InputField value={title} onChange={(e) => update('title', e.target.value)} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.hashtags !== undefined} title={translate.hash_tag}>
          <HashTagsArrayField value={hashtags} onChange={(v) => update('hashtags', v)} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.limits !== undefined} title={translate.product_count}>
          <InputField
            type={'number'}
            value={limits}
            onBlur={(e) => update('limits', e.target.value - 0)}
            onChange={(e) => update('limits', e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              step: 1,
              min: 0,
              max: 20,
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.itemPerRow !== undefined} title={translate.item_per_row}>
          <InputField
            type={'number'}
            value={itemPerRow}
            onBlur={(e) => update('itemPerRow', e.target.value - 0)}
            onChange={(e) => update('itemPerRow', e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              step: 1,
              min: 0,
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.spacing !== undefined} title={translate.spacing}>
          <InputField
            type={'number'}
            value={spacing}
            onBlur={(e) => update('spacing', e.target.value - 0)}
            onChange={(e) => update('spacing', e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              step: 1,
              min: 0,
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.resizeMode !== undefined} title={translate.image_resize_mode}>
          <SelectField
            value={resizeMode}
            onChange={(e) => update('resizeMode', e.target.value)}
            options={[
              { label: 'Contain', value: 'contain' },
              { label: 'Cover', value: 'cover' },
            ]}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.ratio !== undefined} title={'Image Ratio'}>
          <InputField
            type={'number'}
            value={ratio}
            onChange={(e) => update('ratio', e.target.value)}
            onBlur={(e) => update('ratio', e.target.value ? +e.target.value : undefined)}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: <InputAdornment position={'start'}>1 :</InputAdornment>,
            }}
          />
        </HighlightFormRow>
      </>
    );
  },
};
