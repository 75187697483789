import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import FormRow from '../../../components/FormRow';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import SelectComboServiceLocation from '../../../components/SelectCombo/SelectComboServiceLocation';
import DurationField from '../../../components/FormPageMaker/Fields/DurationField';
import SelectComboVariation from '../../../components/SelectCombo/SelectComboVariation';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import ServiceStartDateField from './ServiceStartDateField';
import ServiceWeekDateField from './ServiceWeekDateField';
import FiveMinutesField from '../../../components/FormPageMaker/Fields/FiveMinutesField';
import ServiceStartTimeField from './ServiceStartTimeField';

const ServiceField = ({ isFirst, value, onChange, disabled }) => {
  return (
    <Grid container spacing={2}>
      <FormRow title={'預約節目名稱'} md={12} required={true}>
        <InputField
          disabled={disabled}
          value={value?.name}
          onChange={(e) =>
            onChange({
              ...value,
              name: e?.target?.value,
            })
          }
        />
      </FormRow>
      <FormRow title={'地點選項名稱'} md={3} required={true}>
        <InputField
          disabled={disabled}
          value={value?.serviceLocationName}
          onChange={(e) =>
            onChange({
              ...value,
              serviceLocationName: e?.target?.value,
            })
          }
        />
      </FormRow>
      <FormRow title={`可選${value?.serviceLocationName || '地點'}`} md={9}>
        <SelectComboServiceLocation
          multiple={true}
          disabled={disabled}
          disablePortal={false}
          value={value?.serviceLocations ?? []}
          onChange={(locations) => {
            onChange({
              ...value,
              serviceLocations: locations,
            });
          }}
        />
        <CheckboxField
          label={'預約時先選擇地區'}
          disabled={disabled}
          checked={+value?.metadata?.enableDistrictSelect}
          onChange={() => {
            onChange({
              ...value,
              metadata: {
                ...value?.metadata,
                enableDistrictSelect: +value?.metadata?.enableDistrictSelect ? 0 : 1,
              },
            });
          }}
        />
      </FormRow>
      <FormRow md={12} title={'截止預約時間'}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item>
            <Typography>活動開始前</Typography>
          </Grid>
          <Grid item style={{ minWidth: 150 }}>
            <DurationField
              disabledInfinity={true}
              disabled={disabled}
              value={value.availableUntil}
              onChange={(v) => {
                onChange({
                  ...value,
                  availableUntil: v,
                });
              }}
            />
          </Grid>
          <Grid item>
            <Typography>停止預約</Typography>
          </Grid>
        </Grid>
      </FormRow>
      <FormRow md={12} title={'節目時段'} required={true}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item>
            <Typography>每節時段</Typography>
          </Grid>
          <Grid item>
            <InputField
              required={true}
              inputProps={{ step: 5, min: 5 }}
              disabled={disabled}
              value={value.durationMins}
              onChange={(e) => {
                onChange({ ...value, durationMins: e?.target?.value });
              }}
              onBlur={(e) => {
                const v = +e?.target?.value;
                if (v % 5 !== 0) onChange({ ...value, durationMins: Math.ceil(v / 5) * 5 });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
            />
          </Grid>
          <Grid item>
            <Typography>分鐘／其後保留</Typography>
          </Grid>
          <Grid item>
            <FiveMinutesField
              allowZero={true}
              disabled={disabled}
              value={value.reserveMins}
              onChange={(v) =>
                onChange({
                  ...value,
                  reserveMins: v,
                })
              }
            />
          </Grid>
          <Grid item>
            <Typography>分鐘作緩衝</Typography>
          </Grid>
        </Grid>
      </FormRow>
      <FormRow md={12} title={'預約流程'} required={true}>
        <Card variant={'outlined'} square className={'p-3'}>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              <Typography>-</Typography>
            </Grid>
            {isFirst ? (
              <>
                <Grid item>
                  <Typography>購買後</Typography>
                </Grid>
                <Grid item style={{ width: 200 }}>
                  <DurationField
                    disabledInfinity={true}
                    disabled={disabled}
                    value={value.validSince}
                    onChange={(v) => {
                      onChange({
                        ...value,
                        validSince: v,
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>後可開始預約</Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Typography>上一個預約完成後</Typography>
                </Grid>
                <Grid item style={{ width: 200 }}>
                  <DurationField
                    disabledInfinity={true}
                    disabled={disabled}
                    value={value.validSince}
                    onChange={(v) => {
                      onChange({
                        ...value,
                        validSince: v,
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>可開始預約</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              <Typography>-</Typography>
            </Grid>
            <Grid item>
              <Typography>能夠預約</Typography>
            </Grid>
            <Grid item style={{ width: 200 }}>
              <DurationField
                disabledInfinity={true}
                disabled={disabled}
                value={value.validUntil}
                onChange={(v) => {
                  onChange({
                    ...value,
                    validUntil: v,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <Typography>內的時段</Typography>
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              <Typography>-</Typography>
            </Grid>
            <Grid item>
              <Typography>分隔時間段 - 每：</Typography>
            </Grid>
            <Grid item>
              <FiveMinutesField
                required={true}
                style={{
                  width: 150,
                }}
                disabled={disabled}
                value={value?.intervalMins}
                onChange={(v) =>
                  onChange({
                    ...value,
                    intervalMins: v,
                  })
                }
              />
            </Grid>
          </Grid>
          <CheckboxField
            size={'small'}
            disabled={disabled}
            checked={value?.showStartTimeOnly}
            onChange={() => onChange({ ...value, showStartTimeOnly: !value?.showStartTimeOnly })}
            label={'預約時只顯示開始時間'}
          />
          <ServiceStartTimeField
            disabled={disabled}
            value={[value?.timeAt, value?.timeThru]}
            onChange={([timeAt, timeThru]) =>
              onChange({
                ...value,
                timeAt,
                timeThru,
              })
            }
          />
          <ServiceStartDateField
            disabled={disabled}
            value={[value?.startedAt, value?.startedThru]}
            onChange={([startedAt, startedThru]) =>
              onChange({
                ...value,
                startedAt,
                startedThru,
              })
            }
          />
          <ServiceWeekDateField
            disabled={disabled}
            value={value?.daysOfWeek}
            onChange={(daysOfWeek) =>
              onChange({
                ...value,
                daysOfWeek,
              })
            }
          />
        </Card>
      </FormRow>
      <FormRow md={12} title={'連結庫存項目'}>
        <SelectComboVariation
          multiple={true}
          disabled={disabled}
          value={value?.productVariations ?? []}
          onChange={(productVariations) => {
            onChange({ ...value, productVariations });
          }}
        />
      </FormRow>
    </Grid>
  );
};

export default ServiceField;
