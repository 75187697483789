import React from 'react';
import { Icon } from '../../../../components/IconRender';
import { AlignIconWithText } from './text';
import InnerSelectField from '../../InnerSelectField';

const FontSizeField = ({ disabled, value, onChange = (_) => _ }) => {
  return (
    <InnerSelectField
      disabled={disabled}
      placeholder={
        <>
          <Icon icon={'fa1'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
          <Icon icon={'faText'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
        </>
      }
      options={[
        {
          label: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
          value: 1,
          icon: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
        },
        {
          label: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
          value: 2,
          icon: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
        },
        {
          label: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
          value: 3,
          icon: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
        },
      ]}
      value={value}
      onChange={onChange}
    />
  );
};

export default FontSizeField;
