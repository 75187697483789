import React from 'react';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import ImportModal from './ImportModal';
import { GET_QUERY } from './query';
import StatusBadge from '../../../components/StatusBadge';
import { SitemapStatus } from '../../../shared/omniwe-types';
import ActivateButton from './ActivateButton';
import DeleteButton from './DeleteButton';
import EllipsisTypography from '../../../components/EllipsisTypography';

class SitemapList extends ConnectionPageMarker {
  state = {
    ...this.state,
    createUrl: '/sitemap/new',
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.sitemap_name || '網站地圖名稱',
        fieldName: 'name',
      },
      {
        title: translate.upload_date || '上傳日期',
        fieldName: 'createdAt',
        type: 'datetime',
      },
      {
        title: translate.status,
        width: 120,
        align: 'center',
        fieldName: 'active',
        render: ({ active: _active }) => {
          const active = _active ? 'USING' : 'PENDING';
          return (
            <EllipsisTypography>
              <StatusBadge status={active} statusObject={SitemapStatus} />
            </EllipsisTypography>
          );
        },
      },
      {
        width: 100,
        align: 'center',
        render: ({ id, name, active }) => <ActivateButton id={id} name={name} list={this} active={active} />,
        noLink: true,
      },
      {
        width: 80,
        align: 'center',
        render: ({ id, name }) => <DeleteButton id={id} name={name} list={this} />,
        noLink: true,
      },
    ],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }

  getData(data) {
    const { node } = data || {};
    const { sitemaps } = node || {};
    return { ...sitemaps };
  }

  renderCreateButton(data) {
    const { loading } = data || {};
    return (
      <ImportModal
        onClick={() => this.setState({ menuAnchorEl: undefined })}
        list={this}
        dataLength={this.nodes?.length}
        loading={loading}
      />
    );
  }
}

export default SitemapList;
