import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { translate } from 'shared/translate';
import PriceAdjustmentTable from './PriceAdjustmentTable';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import Dnd from '../../../components/DND';
import { Icon } from '../../../components/IconRender';
import ModifierField from './ModifierField';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import TrashButton from '../../../components/TrashButton';

export default ({ loading, values: { modifiers = [], modifierValues = [] }, setFieldValue, disabled }) => {
  const preModifiers = modifierValues?.map((mdfs) => mdfs.modifiers)?.flat() ?? [];
  const hasOptions =
    (modifiers?.length > 0 && !!modifiers?.find((mod) => (mod.options || []).length > 0)) ||
    (preModifiers.length > 0 && !!preModifiers.find((mod) => (mod.options || []).length > 0));

  return (
    <>
      <StyledConfigList spacing={0}>
        <Grid item xs={12}>
          <Dnd
            direction={'column'}
            spacing={3}
            disabled={disabled}
            items={modifiers}
            onChange={(v) => setFieldValue('modifiers', v)}
            renderItem={(modifier, i, { nonDraggableProps }) => {
              return (
                <Box mx={1.5}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Icon type={'svg'} icon={IconDragVertical} style={{ cursor: 'pointer', marginTop: 30 }} />
                    </Grid>
                    <Grid item xs {...nonDraggableProps}>
                      <ModifierField
                        value={modifier}
                        onChange={(mod) => {
                          setFieldValue(
                            'modifiers',
                            modifiers?.map((m, j) => (j === i ? mod : m)),
                          );
                        }}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item>
                      <TrashButton
                        style={{ marginTop: 20 }}
                        disabled={disabled}
                        onClick={() => {
                          setFieldValue(
                            'modifiers',
                            modifiers?.filter((_, j) => j !== i),
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
            header={preModifiers?.map((modifier, j) => {
              return <ModifierField key={`m-${j}`} value={modifier} disabled={true} />;
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <AddButton
            disabled={disabled}
            onClick={() => {
              modifiers.push({ name: '', options: [] });
              setFieldValue('modifiers', modifiers);
            }}
            title={translate.add_custom_options}
          />
        </Grid>
      </StyledConfigList>
      {!!hasOptions && (
        <PriceAdjustmentTable
          disabled={disabled}
          preModifiers={preModifiers}
          modifiers={modifiers}
          onChange={(modifiers) => {
            setFieldValue('modifiers', modifiers);
          }}
        />
      )}
    </>
  );
};
