import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyWarehouse {
        name
        active
        updatedAt
        sortIndex
        shops(limits: 999) {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation ($id: ID, $input: WarehouseSetInput!) {
    result: warehouseSet(id: $id, input: $input) {
      id
      name
      sortIndex
      active
      updatedAt
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    result: warehouseUnset(id: $id) {
      id
    }
  }
`;
