import React from 'react';
import { JSONParseSafely } from '../../../../../shared';
import {
  Avatar,
  Badge,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Icon } from '../../../../../components/IconRender';
import friendlyTime from 'friendly-time';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { translate } from '../../../../../shared/translate';

const ProcessItem = ({ message: _message, onClick }) => {
  const history = useHistory();
  const { createdAt, process, content, channel, read } = _message || {},
    { type: channelType, id: channelId } = channel || {},
    { status, output } = process || {},
    { href, progress, message } = JSONParseSafely(output, {});
  const theme = useTheme();

  const textStyle = {
    fontWeight: read ? undefined : 'bold',
    color: read ? undefined : theme.palette.secondary.main,
  };

  return (
    <MenuItem
      alignItems={'flex-start'}
      onClick={() => {
        if (!!href && status !== 'FAILURE') {
          window.open(href, '_blank');
        } else {
          history.push(
            {
              ORDER: `/messages/${channelId}`,
              GENERAL: `/messages/${channelId}`,
              SYSTEM: `/system_messages`,
            }[channelType],
          );
        }
        if (onClick) onClick();
      }}
    >
      <ListItemAvatar>
        <Badge
          overlap={'circular'}
          variant={'dot'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          invisible={read}
          color="secondary"
        >
          {
            {
              PENDING: (
                <Avatar style={{ backgroundColor: 'transparent' }}>
                  <CircularProgress />
                </Avatar>
              ),
              RUNNING: (
                <Avatar style={{ backgroundColor: 'transparent' }}>
                  {progress ? <CircularProgress variant="determinate" value={+progress * 100} /> : <CircularProgress />}
                </Avatar>
              ),
              SUCCESS: (
                <Avatar style={{ backgroundColor: 'transparent' }}>
                  <Icon icon={'faCheck'} fontSize={'large'} color={'primary'} />
                </Avatar>
              ),
              FAILURE: (
                <Avatar style={{ backgroundColor: 'transparent' }}>
                  <Icon icon={'faTimes'} fontSize={'large'} color={'error'} />
                </Avatar>
              ),
            }[status]
          }
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant={'caption'} style={textStyle}>
              {translate.system_message}
            </Typography>
            <Tooltip title={message || content}>
              <Typography noWrap={true} style={{ maxWidth: 200, ...textStyle }}>
                {message || content}
              </Typography>
            </Tooltip>
          </>
        }
        secondary={
          <Typography variant={'caption'} style={textStyle}>
            {friendlyTime(moment(createdAt).toDate())}
          </Typography>
        }
      />
    </MenuItem>
  );
};

export default ProcessItem;
