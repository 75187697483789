export const confirmation = async (message, title, submitBtnType, content, transparentBackground, cancelText) => {
  try {
    await global.confirmModal.execute(message, title, submitBtnType, content, transparentBackground, cancelText);
    return true;
  } catch (e) {
    return false;
  }
};

export default confirmation;
