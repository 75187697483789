import React from 'react';
import SystemButton from './SystemButton';
import { Icon } from './IconRender';
import { ReactComponent as IconWandMagic } from '../assets/icon/wandMagic.svg';
import { Typography } from '@material-ui/core';
import useBreakPoint from './useBreakPoint';

const AIButton = ({ disabled, onClick, children, width, height = 42, fontSize, startIcon, style, ...props }) => {
  const lang = localStorage.getItem('lang');
  const breakPoint = useBreakPoint();
  const isUnderMD = ['xs', 'sm'].includes(breakPoint);

  return (
    <SystemButton
      disabled={disabled}
      onClick={onClick}
      type={'submit'}
      startIcon={
        startIcon || (
          <Icon
            icon={IconWandMagic}
            type={'svg'}
            viewBox={'0 0 22 22'}
            style={{ color: '#fff', marginLeft: 6, marginRight: -2 }}
          />
        )
      }
      style={{
        height,
        width: width || (isUnderMD ? '100%' : lang === 'zh-hk' ? 120 : 190),
        justifyContent: 'center',
        background: 'linear-gradient(259.32deg, #236FA6 -23.17%, #00E0CA 136.84%)',
        ...style,
      }}
      {...props}
    >
      <Typography style={{ color: '#fff', fontSize }}>{children}</Typography>
    </SystemButton>
  );
};

export default AIButton;
