import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';

const STATUS_SET = gql`
  mutation ($id: ID!, $input: BundleProductUpdateInput!) {
    bundleProductUpdate(id: $id, input: $input) {
      id
      display
    }
  }
`;

export default ({ id, display, data, onCompleted }) => {
  const { name, sku } = data || {};
  const [loading, setLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(display);

  return (
    <SwitchField
      checked={isDisplay}
      value={isDisplay}
      disabled={loading}
      onChange={async (e) => {
        try {
          setIsDisplay(!display);
          setLoading(true);
          await client.mutate({
            mutation: STATUS_SET,
            variables: {
              id: id || undefined,
              input: {
                display: !display,
                sku,
                name,
              },
            },
          });
          onCompleted();
        } catch (e) {
          toast.error(errorParser(e));
          setIsDisplay(display);
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
