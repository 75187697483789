import React from 'react';
import SelectComboCoupon from '../../components/SelectCombo/SelectComboCoupon';

const CouponsField = ({ value: coupons = [], onChange = (_) => _, disabled, ...props }) => {
  return (
    <SelectComboCoupon
      disabled={disabled}
      multiple
      repeatable
      value={coupons}
      onChange={(coupons) => onChange(coupons)}
      {...props}
    />
  );
};

export default CouponsField;
