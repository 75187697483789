import { gql } from '@apollo/client';
import { FRAGMENT_PRINTABLE_ORDER_ITEM } from '../../../PrintOrder/query';

export const GET_ORDER_IDS = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $filter: OrderFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Company {
        id
        orders(query: $query, cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          totalAmount
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const GET_DN = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: DeliveryNotesFilterInput) {
    node(id: $id) {
      id
      ... on Company {
        id
        deliveryNotes(cursor: $cursor, limits: $limits, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            id
            trackingNumber
            address {
              name
              person
              email
              lines
              city
              country
              tel
            }
            shippingProvider {
              provider
            }
            order {
              id
              remark
              referenceNo
              checkout {
                items {
                  id
                  createdAt
                  productVariation {
                    id
                    unitPrice
                    barcode
                    combination {
                      name
                      option
                    }
                    sku
                    product {
                      id
                      name
                      printDescription
                    }
                  }
                  bundleProduct {
                    bundleProduct {
                      id
                      name
                      sku
                    }
                    options {
                      id
                      remark
                      label
                      price
                      variation {
                        id
                        sku
                        barcode
                        combination {
                          name
                          option
                        }
                      }
                      product {
                        id
                        name
                        printDescription
                      }
                    }
                  }
                  description
                  quantity
                  unitPrice
                  remark
                  addOnProduct {
                    id
                    sku
                    unitPrice
                  }
                }
              }
            }
            items {
              id
              orderItem {
                ...FRAGMENT_PRINTABLE_ORDER_ITEM
              }
              quantity
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_PRINTABLE_ORDER_ITEM}
`;
