import React from 'react';
import { translate } from '../../shared/translate';
import Taber from '../../components/Taber';
import ItemCustomerFieldsSettingForm from './form';

const ItemCustomerFieldsSetting = (props) => {
  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.register_field_setting,
          },
        ]}
      />
      <ItemCustomerFieldsSettingForm {...props} />
    </div>
  );
};

export default ItemCustomerFieldsSetting;
