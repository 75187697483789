import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboCollection from '../../SelectCombo/SelectComboCollection';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, name] = str.match(/^(.*?)∆(.*)$/);
      return { id, name };
    });
  const [collections, setCollections] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboCollection
        multiple
        value={collections}
        onChange={(collections) => {
          setCollections(collections);

          const values = (collections || []).map((collection) => {
            const { parents } = collection || {};
            const tree = (parents || []).concat();
            tree.reverse();
            return `${collection.id}∆${tree
              .concat(collection)
              .map((col) => col.name)
              .join(' > ')}`;
          });

          onFilterChange({
            value: values,
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
