import React from 'react';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';

// const ACTIVE_SET = gql`
//   mutation ($id: ID, $input: PageInput!) {
//     pageSet(id: $id, input: $input) {
//       id
//       active
//     }
//   }
// `;

export default ({ value = [], onChange = (_) => _, disabled }) => {
  const toggleValue = (val) => {
    const active = !!~value.indexOf(val);
    if (active) {
      const news = value.filter((v) => v !== val);
      if (news.length > 0) onChange(news);
    } else {
      onChange(value.concat(val));
    }
  };

  return (
    <>
      <SwitchField
        disabled={disabled}
        checked={!!~value.indexOf('PUSH')}
        onChange={() => {
          toggleValue('PUSH');
        }}
      />
    </>
  );
};
