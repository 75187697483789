import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { GET_QUERY, SUBMIT_QUERY } from './query';
import { JSONParseSafely, parseConnection } from '../../shared';
import { translate } from '../../shared/translate';
import MarqueeArrayField from './MarqueeArrayField';
import { client } from '../../shared/apollo';

class item_Marquee extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
    },
    tabs: [
      {
        name: translate.marquee_settings || '跑馬燈設定',
        cards: [
          {
            fields: [
              {
                label: translate.background_color,
                name: 'backgroundColor',
                type: 'color',
              },
              {
                label: translate.activate_checkbox,
                name: 'active',
                type: 'switch',
              },
              {
                label: translate.top_marquee,
                md: 12,
                render: ({ values: { positionTop } = {}, setFieldValue, isSubmitting, loading }) => (
                  <MarqueeArrayField
                    data={positionTop}
                    disabled={isSubmitting || loading}
                    onChange={(arr) => {
                      setFieldValue('positionTop', arr);
                    }}
                  />
                ),
              },
              {
                label: translate.bottom_marquee,
                md: 12,
                render: ({ values: { positionBottom } = {}, setFieldValue, isSubmitting, loading }) => (
                  <MarqueeArrayField
                    data={positionBottom}
                    disabled={isSubmitting || loading}
                    onChange={(arr) => {
                      setFieldValue('positionBottom', arr);
                    }}
                  />
                ),
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async ({ id, backgroundColor, closable, active, positionTop, positionBottom, content, href }) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          shopId: localStorage.getItem('shopId'),
          backgroundColor,
          closable,
          active,
          positionTop: JSON.stringify(positionTop || []),
          positionBottom: JSON.stringify(positionBottom || []),
          content,
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const { nodes: marquees } = parseConnection((node || {}).marquees),
      [marquee] = marquees || [],
      {
        id,
        updatedAt,
        backgroundColor = '#db6d6d',
        closable = true,
        positionTop = '[]',
        positionBottom = '[]',
        content = '',
        active = false,
      } = marquee || {};
    return {
      id,
      updatedAt,
      backgroundColor,
      closable,
      positionTop: JSONParseSafely(positionTop, []),
      positionBottom: JSONParseSafely(positionBottom, []),
      content,
      active,
    };
  }

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}

export default item_Marquee;
