import React from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Icon } from 'components/IconRender';
import { translate } from '../../../shared/translate';

export default ({
  flex = 1,
  innerPadding,
  onRemoveClick,
  onLeftClick,
  onRightClick,
  onConfirmClick,
  onEditClick,
  style,
  children,
}) => {
  return (
    <Box flex={flex} display={'flex'} flexDirection={'column'} alignItems={'center'} style={style}>
      <Box flex={1} style={{ width: '100%', padding: innerPadding }} position={'relative'}>
        {children}
      </Box>
      <Box
        className={'controls'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        mt={1}
        style={{ width: '100%' }}
      >
        <Box flexGrow={1} />
        <Tooltip title={translate.delete}>
          <IconButton color={'secondary'} size={'small'} onClick={onRemoveClick}>
            <Icon type={'material'} icon={'Cancel'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate.move_left}>
          <IconButton color={'primary'} size={'small'} onClick={onLeftClick} disabled={!onLeftClick}>
            <Icon type={'material'} icon={'ArrowBack'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate.move_right}>
          <IconButton color={'primary'} size={'small'} onClick={onRightClick} disabled={!onRightClick}>
            <Icon type={'material'} icon={'ArrowForward'} />
          </IconButton>
        </Tooltip>
        <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
          {!!onConfirmClick && (
            <IconButton color={'primary'} size={'small'} onClick={onConfirmClick} disabled={!onConfirmClick}>
              <Icon type={'material'} icon={'Done'} />
            </IconButton>
          )}
          {!!onEditClick && (
            <Tooltip title={translate.layout_setting}>
              <IconButton color={'primary'} size={'small'} onClick={onEditClick} disabled={!onEditClick}>
                <Icon icon={'faCog'} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};
