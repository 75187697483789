import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import React from 'react';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import FormRow from '../../components/FormRow';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';
import { translate } from '../../shared/translate';

const ReferenceNoSettingCard = ({ value, onChange, title, loading, disabled }) => {
  const { prefix, length } = value || {};
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Grid container spacing={2}>
          <FormRow title={translate.prefix} md={6}>
            <InputField
              value={prefix}
              disabled={disabled}
              loading={loading}
              onChange={(e) => {
                onChange({ ...value, prefix: e.target.value });
              }}
            />
          </FormRow>
          <FormRow title={translate.length} md={6} required>
            <SelectField
              value={length}
              required
              loading={loading}
              disabled={disabled}
              onChange={(e) => {
                onChange({ ...value, length: e.target.value });
              }}
              options={[7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((el) => {
                return {
                  label: `${el}`,
                  value: el,
                };
              })}
            />
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReferenceNoSettingCard;
