import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_REPORT } from './query';
import moment from 'moment';
import _ from 'lodash';
import { parseConnection } from '../../../shared';
import CardContainer from '../CardContainer';

const ShopContainer = ({ shop = {} }) => {
  const shopId = shop.id;

  const { loading, data: { node, companyNode } = {} } = useQuery(GET_REPORT, {
      fetchPolicy: 'cache-and-network',
      variables: {
        query: `companyId.keyword: "${localStorage.getItem('companyId')}"`,
        id: shopId,
        shopId: shopId,
        reportShopId: shopId,
        likeShopId: `%${shopId}%`,
        today: moment().startOf('day').toISOString(),
        last7day: moment().subtract(6, 'day').startOf('day').toISOString(),
        last30day: moment().subtract(29, 'day').startOf('day').toISOString(),
        startedThru: moment().endOf('day').toISOString(),
        companyId: localStorage.getItem('companyId'),
      },
      skip: !shopId,
    }),
    { report = {}, unreadOrderMessages, unreadGeneralMessages } = node || {},
    { report: companyReport = {} } = companyNode || {},
    { totalCount: unreadOrderMessageCount } = parseConnection(unreadOrderMessages),
    { totalCount: unreadGeneralMessageCount } = parseConnection(unreadGeneralMessages);

  return (
    <CardContainer
      loading={loading}
      report={report}
      companyReport={companyReport}
      unreadOrderMessageCount={unreadOrderMessageCount}
      unreadGeneralMessageCount={unreadGeneralMessageCount}
    />
  );
};

export default ShopContainer;
