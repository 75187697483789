import React, { useState } from 'react';
import { MenuItem, Tooltip } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import { actionWrapper, infinityFetch } from '../../../../shared';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { GET_DN, GET_ORDER_IDS } from './query';
import PrintOptionsDialog from '../../../../components/PrintOptionsButton/PrintOptionsDialog';

const MenuPackingList = {
  render: function RenderFC({ enable, i }) {
    const { selectionData } = this.state,
      { mode, data = [] } = selectionData || {};
    const [loading, setLoading] = useState();
    const [visible, setVisible] = useState(false);
    const onClose = () => setVisible(false);

    return enable ? (
      <>
        <MenuItem key={i} disabled={!enable || loading} onClick={() => setVisible(true)}>
          {translate.packing_list} ({this.getSelectionCount()})
        </MenuItem>
        {visible && (
          <PrintOptionsDialog
            open={visible}
            onClose={onClose}
            onSubmit={async ({ fontSize, sortProducts }) => {
              setLoading(true);
              await actionWrapper(async () => {
                const { nodes: orders } = await this.infinityFetchAllSelected({
                  query: GET_ORDER_IDS,
                  onProgress: (downloaded, total) => {
                    ActivityIndicator.show(`${translate.loading} ${downloaded}/${total}`);
                  },
                });

                const { nodes } = await infinityFetch({
                  query: GET_DN,
                  fetchPolicy: 'network-only',
                  variables: {
                    id: localStorage.getItem('companyId'),
                    filter: {
                      orderId: {
                        operator: 'IN',
                        value: orders.map((o) => o?.id).filter(Boolean),
                      },
                    },
                  },
                  getConnection: ({ node } = {}) => {
                    return (node || {}).deliveryNotes;
                  },
                  total: this.getSelectionCount(),
                  onProgress: (downloaded, total) => {
                    ActivityIndicator.show(`${translate.loading} ${downloaded}/${total}`);
                  },
                });

                if (!nodes?.length) {
                  throw new Error(translate.no_printable_data);
                } else {
                  localStorage.setItem('cache_print_packing_list', JSON.stringify(nodes));
                  window.open(`/print_packing_list?fontSize=${fontSize}&sort=${sortProducts}`, '_blank');
                }
              });
              ActivityIndicator.hide();
              setLoading(false);
            }}
          />
        )}
      </>
    ) : (
      <Tooltip title={translate.packing_list_tip}>
        <div style={{ padding: 0 }}>
          <MenuItem key={i} disabled={true}>
            {translate.packing_list} ({this.getSelectionCount()})
          </MenuItem>
        </div>
      </Tooltip>
    );
  },
  enable: function () {
    const count = this.getSelectionCount();
    return count > 0 && count <= 20;
  },
};

export default MenuPackingList;
