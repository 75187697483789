import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { PriceFormat } from '../../../shared';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';

const BundleProductItemContent = ({ item }) => {
  const { bundleProduct } = item ?? {};
  const { bundleProduct: _bundleProduct, options } = bundleProduct || {},
    { name, sku: bundleProductSku } = _bundleProduct || {};

  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: '0.94rem' }}>{`${name}`}</Typography>
      <Typography style={{ fontSize: '0.81rem' }}>{`SKU： ${bundleProductSku}`}</Typography>
      <Grid container direction="column" style={{ paddingLeft: 8, marginTop: 8 }}>
        {options?.map(({ variation, product, label, price, remark }, i) => {
          const { name: productName, printDescription } = product || {};
          const { combination, sku } = variation || {};
          return (
            <Grid item>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 'bold', fontSize: '0.94rem' }}>{`${label}:${productName}${
                    typeof price === 'number' && ` (+${PriceFormat(price)})`
                  }`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: '0.81rem' }}>{`SKU： ${sku}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <VariationCombinationDisplay
                    typographyStyle={{ fontSize: '0.81rem' }}
                    combination={combination}
                    remark={remark}
                    direction={'column'}
                    parseColorCode={false}
                  />
                </Grid>
                {!!printDescription && (
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}>{printDescription}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default BundleProductItemContent;
