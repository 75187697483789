import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';

const OrderItemContent = ({ value }) => {
  const fontWeight = value?.active ? 'bold' : undefined;
  const { description, remark, productVariation } = value?.orderItem || {};
  const { combination = [] } = productVariation || {};

  return (
    <>
      <TableCell width={'*'}>
        <Typography style={{ fontWeight }}>{description}</Typography>
        <VariationCombinationDisplay combination={combination} remark={remark} />
      </TableCell>
      <TableCell width={100}>
        <Typography style={{ fontWeight }}>{productVariation?.sku}</Typography>
      </TableCell>
    </>
  );
};

export default OrderItemContent;
