import { gql } from '@apollo/client';

export const FRAGMENT_addOnProduct = gql`
  fragment FRAGMENT_addOnProduct on ShopAddOnProduct {
    id
    updatedAt
    createdAt
    active
    amount
    name
    sku
    type
    unitPrice
    ignoreStock
    quantity(warehouseId: $warehouseId)
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
    }
  }
`;

export const GET_QUERY = gql`
  query(
    $id: ID!
    $warehouseId: ID!
    $cursor: Int
    $limits: Int
    $filter: AddOnProductFilterInput
    $query: String
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        addOnProducts(cursor: $cursor, limits: $limits, filter: $filter, query: $query, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_addOnProduct
          }
        }
      }
    }
  }
  ${FRAGMENT_addOnProduct}
`;
