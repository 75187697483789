import { Box, Grid, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { theme } from '../../../theme';
import { PriceFormat } from '../../../shared';
import { translate } from '../../../shared/translate';

const MonthlyTargetProgress = ({ percentage, amount, monthlyTarget }) => {
  return (
    <Tooltip
      title={
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'center' }}>
              {PriceFormat(amount)} / {PriceFormat(monthlyTarget)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-evenly">
              <Grid item>
                <Typography>{translate.achieved || '完成量'}</Typography>
              </Grid>
              <Grid item>
                <Typography>{translate.goal || '目標量'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Box style={{ position: 'relative' }}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            width: '100%',
          }}
        >
          <Typography
            style={{
              color: theme.palette.primary.contrastText,
              textAlign: 'center',
            }}
          >
            {percentage}%
          </Typography>
        </Box>

        <LinearProgress
          value={percentage}
          variant="determinate"
          style={{
            height: 20,
            backgroundColor: '#7e858e',
            borderRadius: 6,
          }}
          color={percentage > 50 ? 'primary' : 'secondary'}
        />
      </Box>
    </Tooltip>
  );
};

export default MonthlyTargetProgress;
