import React from 'react';
import moment from 'moment';
import { Typography, Tooltip } from '@material-ui/core';

const { REACT_APP_VERSION = 'dev' } = process.env;

export default ({ policyMark = '©', year = moment().format('YYYY'), name = 'OmniWe Limited' }) => (
  <Tooltip title={REACT_APP_VERSION}>
    <Typography align={'center'} variant={'caption'} style={{ color: 'white' }}>
      Copyright {year} {policyMark} {name}
    </Typography>
  </Tooltip>
);
