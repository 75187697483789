import React from 'react';
import { Typography, Card, Grid, CardHeader, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from './Table';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    height: '100%',
    paddingLeft: 16,
    paddingRight: 16,
  },
  cardHeader: {
    borderBottom: '1px solid #ddd',
  },
  title: {
    fontWeight: 'bold',
  },
});

export default ({ title, rows = [], columns = [], tableRowStyle }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={6}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<Badge color="secondary" variant="dot" />}
          title={
            <Typography variant={'h6'} color={'secondary'} className={classes.title}>
              {title}
            </Typography>
          }
        />
        <Table rows={rows} columns={columns} tableRowStyle={tableRowStyle} />
      </Card>
    </Grid>
  );
};
