import React, { useState } from 'react';
import { Icon } from '../../../components/IconRender';
import { Box, Divider, Grid, IconButton, Menu, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';

const WarehouseSelector = ({ warehouses = [], value, onChange = (values) => {} }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.target)} edge={'end'}>
        <Icon icon={'faGearComplex'} />
      </IconButton>
      <Menu anchorEl={anchorEl} variant={'menu'} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <Box p={2}>
          <Grid container direction={'column'} spacing={1}>
            <Grid item>
              <Typography color={'secondary'} variant={'h6'}>
                {translate.filter_warehouse || '篩選貨倉'}
              </Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Typography color={'textSecondary'}>
                <small>{translate.display_items || '顯示項目'}</small>
              </Typography>
            </Grid>
            <Grid item>
              {warehouses.map((warehouse, i) => {
                const checked = !!value?.find((v) => v?.id === warehouse?.id);
                return (
                  <div key={i}>
                    <CheckboxField
                      checked={checked}
                      label={warehouse?.name}
                      onChange={() => {
                        if (checked) {
                          onChange(value?.filter((v) => v?.id !== warehouse?.id));
                        } else {
                          onChange([...value, warehouse]);
                        }
                      }}
                    />
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
};

export default WarehouseSelector;
