import React from 'react';
import FormPageTemplate from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { JSONParseSafely } from 'shared';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import { Box, Dialog } from '@material-ui/core';
import FormToolbar from 'components/FormToolbar';
import FormRow from 'components/FormRow';
import InputField from 'components/FormPageMaker/Fields/InputField';
import SeoCardSetting from '../item_Product/SeoCardSetting';
import { Skeleton } from '@material-ui/lab';
import HrefField, { SpecialPageHref } from './HrefField';
import PageBodyCard from './PageBodyCard';
import fixBodyFunction from './fixBodyFunction';
import PageDataModalButton from './PageDataModalButton';
import PreviewButton from './PreviewButton';

export default class extends FormPageTemplate {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.custom_page_details,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                name: 'name',
                required: () => {
                  const { toggleDesignPage } = this.state;
                  return !toggleDesignPage;
                },
                maxLength: 25,
                md: 6,
              },
              {
                label: translate.href,
                type: 'text',
                name: 'href',
                required: () => {
                  const { toggleDesignPage } = this.state;
                  return !toggleDesignPage;
                },
                render: ({ values, setFieldValue, loading, isSubmitting }) => {
                  const { toggleDesignPage } = this.state;
                  const { href, useCustomHref } = values;
                  if (loading) return <Skeleton height={30} />;
                  return (
                    // <ItemCustomPageRatioSelectField onChange={(e) => console.log('hello')} />
                    <HrefField
                      disabled={isSubmitting}
                      required={!toggleDesignPage}
                      useCustomHref={useCustomHref}
                      onUseCustomHrefChange={(v) => setFieldValue('useCustomHref', v)}
                      value={href}
                      onChange={(v) => setFieldValue('href', v)}
                    />
                  );
                },
              },
              {
                label: translate.sort_index,
                type: 'number',
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
              },
              {
                label: translate.activation,
                type: 'switch',
                name: 'active',
              },
              {
                label: translate.visible,
                type: 'switch',
                name: 'display',
              },
            ],
          },
        ],
      },
      SeoCardSetting,
      {
        name: translate.page_design,
        cards: [],
        onClick: () => this.toggleDesignPage(),
      },
    ],
    toggleDesignPage: false,
  };

  getFetchPolicy() {
    return 'network-only';
  }

  toggleDesignPage = () => {
    const { toggleDesignPage } = this.state;
    this.setState({ toggleDesignPage: !toggleDesignPage });
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const {
      group,
      name,
      href,
      active,
      display,
      body,
      metaTitle,
      metaDescription,
      metaKeywords,
      rewriteUri,
      hashtags,
      sortIndex,
    } = values;

    const input = {
      shopId: localStorage.getItem('shopId'),
      name,
      href,
      group,
      active,
      display,
      body: JSON.stringify(body || []),
      metaTitle,
      metaDescription,
      metaKeywords,
      hashtags,
      rewriteUri: rewriteUri || null,
      sortIndex,
    };

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });
    return true;
  };
  getInitialData({ node } = {}) {
    const {
      name = '',
      href = '',
      active = true,
      display = true,
      body = '[]',
      updatedAt,
      metaTitle = '',
      metaDescription = '',
      metaKeywords = '',
      rewriteUri = '',
      group = 'pages',
      hashtags,
      sortIndex,
    } = node || {};
    return {
      name,
      href,
      useCustomHref: !SpecialPageHref.find((page) => page.value === href),
      active,
      display,
      body: fixBodyFunction(JSONParseSafely(body, [])),
      metaTitle,
      metaDescription,
      metaKeywords,
      rewriteUri,
      updatedAt,
      group,
      hashtags: hashtags || [],
      sortIndex: sortIndex || 0,
    };
  }

  renderOthers(data, actions) {
    const { toggleDesignPage } = this.state;
    const { handleSubmit } = actions || {};

    return (
      <Dialog
        fullScreen
        disableBackdropClick
        disableEscapeKeyDown
        disableEnforceFocus
        open={toggleDesignPage}
        onClose={this.toggleDesignPage}
      >
        <form
          onSubmit={(e) => {
            this.values = actions.values;
            handleSubmit(e);
          }}
          style={{
            display: 'flex',
            height: '100%',
            backgroundColor: '#2a2b31',
          }}
        >
          <Box width={50} />
          <Box
            display={'flex'}
            flexGrow={1}
            flexDirection={'column'}
            height={'100%'}
            style={{
              background: `url(${require('../../assets/HomePage.png')})`,
            }}
          >
            <Box flex={1} px={3} pb={3} overflow={'auto'}>
              <div style={{ height: '100%', flexWrap: 'nowrap' }}>
                <Box>
                  <PageBodyCard {...actions} />
                </Box>
              </div>
            </Box>
            <FormToolbar
              extra={<PageDataModalButton {...actions} />}
              cancelText={translate.back || '返回'}
              submitBtnType={'submit'}
              onCancel={this.toggleDesignPage}
            />
          </Box>
          <Box flex={1} p={2} maxWidth={200} minWidth={200}>
            {this.renderExtraSimpleForm(actions)}
          </Box>
        </form>
      </Dialog>
    );
  }

  renderExtraSimpleForm = ({ values: { name, useCustomHref, href, body } = {}, setFieldValue, isSubmitting } = {}) => {
    return (
      <>
        <FormRow title={translate.name} required={true} titleStyle={{ color: 'white' }}>
          <InputField
            required={true}
            value={name}
            type={'text'}
            maxLength={50}
            onChange={(e) => setFieldValue('name', e.target.value)}
            disabled={isSubmitting}
          />
        </FormRow>
        <FormRow title={translate.href} required={true} titleStyle={{ color: 'white' }}>
          <HrefField
            orientation="vertical"
            required={true}
            disabled={isSubmitting}
            useCustomHref={useCustomHref}
            onUseCustomHrefChange={(v) => setFieldValue('useCustomHref', v)}
            value={href}
            onChange={(v) => setFieldValue('href', v)}
          />
        </FormRow>
        <PreviewButton previewData={body} />
      </>
    );
  };
}
