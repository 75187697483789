import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { NoData } from '../../../components/CursorBaseList';
import { Skeleton } from '@material-ui/lab';
import ItemRowField from './ItemRowField';
import StyledTableCell from '../../item_Order/ItemsCard/StyledTableCell';
import _ from 'lodash';

const ReturnItemsCard = ({ disabled: _disabled, values: { id, items: _items } = {}, setFieldValue, loading }) => {
  const disabled = !!id || _disabled;
  const items = _.sortBy(_items || [], (item) => {
    function getProductItem(item) {
      return item?.productVariation || item?.bundleProduct?.bundleProduct || item?.addOnProduct;
    }
    return item?.sku?.toUpperCase() || getProductItem(item?.orderItem)?.sku?.toUpperCase();
  });

  return (
    <Paper variant={'outlined'}>
      <TableContainer>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell width={1} />
              <TableCell width={'*'}>
                <Typography variant={'h6'}>{translate.items}</Typography>
              </TableCell>
              <TableCell width={100} align={'right'}>
                <Typography variant={'h6'} align={'left'}>
                  {translate.product_sku || '產品編號'}
                </Typography>
              </TableCell>
              <TableCell width={100} align={'right'}>
                <Typography variant={'h6'} align={'center'}>
                  {translate.quantity || '數量'}
                </Typography>
              </TableCell>
              <TableCell width={150} align={'right'}>
                <Typography variant={'h6'} align={'center'}>
                  {translate.resalable || '可否二次銷售'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading &&
              Array(3)
                .fill(undefined)
                .map((__, i) => (
                  <TableRow key={i}>
                    <TableCell colSpan={999}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
            {!loading &&
              items?.length > 0 &&
              items.map((item, i) => {
                return (
                  <ItemRowField
                    key={i}
                    value={item}
                    disabled={disabled || item?.id}
                    onChange={(v) => {
                      setFieldValue(
                        'items',
                        items.map((_item, j) => (j === i ? v : _item)),
                      );
                    }}
                  />
                );
              })}

            <TableRow>
              <StyledTableCell colSpan={2} />
              <StyledTableCell width={1} align={'left'}>
                <Typography style={{ fontWeight: 'bold' }}>{translate.quantity_returned}</Typography>
              </StyledTableCell>
              <StyledTableCell width={1} align={'center'}>
                <Typography style={{ fontWeight: 'bold' }}>{_.filter(items, { active: true }).length || 0}</Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={1} />
            </TableRow>

            {!loading && !items?.length && (
              <TableRow>
                <TableCell colSpan={999}>
                  <NoData />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ReturnItemsCard;
