import { useEffect } from 'react';
import { client } from '../../../shared/apollo';
import { CHANNEL_READ } from '../../../components/ChannelMessage/query';

const SystemChannelReadTrigger = ({ id, read }) => {
  useEffect(() => {
    if (!!id && !read) {
      client
        .mutate({
          mutation: CHANNEL_READ,
          variables: { channelId: id, channelPlayerId: localStorage.getItem('shopId') },
        })
        .catch();
    }
  }, [id, read]);

  return null;
};

export default SystemChannelReadTrigger;
