import React, { useEffect, useRef, useState } from 'react';
import { Table, TableContainer, TableBody } from '@material-ui/core';
import SKUGenerator from './SKUGenerator';
import InertTableHead from './InertTableHead';
import VariantRow from './VariantRow';
import confirmation from '../../../../shared/confirmation';
import { translate } from '../../../../shared/translate';

export default ({ variations, onChange, disabled }) => {
  const [k, setK] = useState(0);
  const [clickSKU, setClickSKU] = useState(false);
  const [clickBarcode, setClickBarcode] = useState(false);
  const skuGenerator = useRef(undefined);

  useEffect(() => {
    if (clickSKU) {
      (async () => {
        try {
          const { prefix = '', suffix = '', start = 0, length = 5 } = (await skuGenerator.current.execute()) || {};
          variations.forEach((variation, i) => {
            variation.sku = `${prefix}${`${+start + i}`.padStart(length, '0')}${suffix}`;
          });
          onChange(variations);
        } catch (e) {
        } finally {
          setK(k + 1);
          setClickSKU(false);
        }
      })();
    }
    if (clickBarcode) {
      (async () => {
        try {
          if (await confirmation(translate.is_quick_generate_barcode || '確定要快速生成條碼嗎？')) {
            variations.forEach((variation, i) => {
              variation.barcodes = [`${Date.now() + i}`, ...variation.barcodes];
            });
            onChange(variations);
          }
        } catch (e) {
        } finally {
          setK(k + 1);
          setClickBarcode(false);
        }
      })();
    }
  }, [clickSKU, clickBarcode]);

  return (
    <>
      <TableContainer
        style={{
          borderRadius: 10,
          border: '1.5px solid #E1E1E1',
          padding: 20,
        }}
      >
        <Table
          size="small"
          style={{
            width: 'max-content',
            minWidth: '100%',
            overflowY: 'clip',
          }}
        >
          <InertTableHead
            disabled={disabled}
            onSKUClick={() => setClickSKU(true)}
            onBarcodeClick={() => setClickBarcode(true)}
          />
          <TableBody key={k}>
            {variations.map((variation, i) => (
              <VariantRow
                key={i}
                value={variation}
                disabled={disabled}
                onChange={(variation) => {
                  variations[i] = variation;
                  onChange(variations);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SKUGenerator ref={skuGenerator} />
    </>
  );
};
