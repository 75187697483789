import { gql } from '@apollo/client';

const CompanyShopInfoFragment = gql`
  fragment CompanyShopInfoFragment on CompanyShop {
    id
    name
    welcomeMessages
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ...CompanyShopInfoFragment
    }
  }
  ${CompanyShopInfoFragment}
`;

export const POST_QUERY = gql`
  mutation companyShopSet($id: ID!, $input: CompanyShopInput!) {
    companyShopSet(id: $id, input: $input) {
      ...CompanyShopInfoFragment
    }
  }
  ${CompanyShopInfoFragment}
`;
