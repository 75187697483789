import React from 'react';
import { useTheme } from '@material-ui/core';
import useBreakPoint from '../useBreakPoint';

const AccordionWrapper = ({ expanded, padding, disabledBackground, onChange = (_) => _, children }) => {
  const breakpoint = useBreakPoint();
  const theme = useTheme();

  return (
    <div
      onClick={onChange}
      style={{
        backgroundColor: !!disabledBackground ? 'transparent' : '#fff',
        boxShadow: !!disabledBackground ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)',
        ...(expanded
          ? {
              position: 'relative',
              opacity: 1,
            }
          : {
              position: 'fixed',
              pointerEvents: 'none',
              opacity: 0,
              height: 1,
              overflow: 'hidden',
            }),
        padding:
          padding ??
          (!!~['xs', 'sm'].indexOf(breakpoint)
            ? `${theme.spacing(6)}px 0`
            : `${theme.spacing(6)}px ${theme.spacing(9)}px`),
      }}
    >
      {children}
    </div>
  );
};

export default AccordionWrapper;
