import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { parseProductImages, PriceFormat } from 'shared';
import RatioRespondingMedia from '../RatioRespondingMedia';

export default ({ loading, product, children, height, resizeMode = 'contain', ratio }) => {
  const { name, basePrice, subtitle } = product || {},
    [image] = parseProductImages(product);
  return (
    <Box display={'flex'} flexDirection={'column'} height={+height || 'initial'}>
      <RatioRespondingMedia
        ratio={+height ? undefined : ratio || 1}
        resizeMode={resizeMode}
        src={image || require('assets/gray.jpg')}
      >
        {children}
      </RatioRespondingMedia>
      {!!subtitle && (
        <Typography color={'primary'} variant={'body2'}>
          {subtitle}
        </Typography>
      )}
      {loading ? <Skeleton /> : !!name && <Typography>{name}</Typography>}
      {loading ? <Skeleton /> : basePrice !== undefined && <Typography>{PriceFormat(basePrice)}</Typography>}
    </Box>
  );
};
