import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { APPROVE_QUERY, COMPLETE_QUERY, CREATE_QUERY, GET_QUERY, UPDATE_QUERY } from './query';
import { translate } from '../../shared/translate';
import StatusBadge from '../../components/StatusBadge';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import OrderSelectField, { getFormReturnItems } from './OrderSelectField';
import { ReturnNoteStatus } from '../../shared/omniwe-types';
import moment from 'moment';
import SelectComboShop from '../../components/SelectCombo/SelectComboShop';
import { convertMetaArray, convertMetaObject, parseConnection, parseCustomerName } from '../../shared';
import ReturnItemsCard from './ReturnItemsCard';
import { client } from '../../shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: CREATE_QUERY,
    },
    tabs: [
      {
        name: translate.return_note_detail || '退貨單資料',
        cards: [
          {
            fields: [
              {
                label: translate.return_status || '退貨狀態',
                render: ({ values: { status } = {}, loading }) => (
                  <Box mt={1}>
                    {loading ? (
                      <Skeleton height={30} />
                    ) : (
                      <StatusBadge status={status} statusObject={ReturnNoteStatus} />
                    )}
                  </Box>
                ),
                md: 6,
                block: true,
              },
              {
                label: translate.return_number || '退貨單號',
                name: 'referenceNo',
                type: 'span',
                md: 6,
              },
              {
                label: translate.return_time || '退貨時間',
                render: ({ values }) => <Typography>{moment(values?.createdAt).format('YYYY/MM/DD HH:mm')}</Typography>,
                md: 6,
              },
              {
                label: translate.orders,
                render: OrderSelectField,
                required: true,
                md: 6,
              },
              {
                label: translate.customer_fullname,
                value: ({ values }) => parseCustomerName(values?.order?.customer),
                type: 'text',
                disabled: true,
                md: 6,
              },
              {
                label: translate.shop_of_sale || '銷售店鋪',
                name: 'order.shop.name',
                type: 'text',
                disabled: true,
                md: 6,
              },
              {
                label: translate.shop_of_return || '退貨地點',
                required: true,
                render: ({ loading, disabled, values, setFieldValue }) => (
                  <SelectComboShop
                    required={true}
                    loading={loading}
                    disabled={disabled || !!values.id}
                    value={values?.shop}
                    onChange={(shop) => setFieldValue('shop', shop)}
                  />
                ),
                md: 6,
              },
              {
                label: translate.remark,
                name: 'remark',
                type: 'textarea',
                disabled: ({ values }) => values.status !== 'PENDING',
              },
              {
                label: translate.return_items || '退貨項目',
                render: ReturnItemsCard,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;
    if (!!id) {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input: {
            remark: values?.remark,
          },
        },
      });
    } else {
      const newItems = values?.items?.filter((item) => !item?.id && item?.active) ?? [];
      if (!newItems.length) throw new Error('Must have items');

      const { data: { returnNoteCreate } = {} } = await client.mutate({
          mutation: CREATE_QUERY,
          variables: {
            input: {
              metadata: convertMetaArray(values?.metadata),
              orderId: values?.order?.id,
              remark: values?.remark,
              shopId: values?.shop?.id,
              items:
                newItems?.map((item) => ({
                  bundleProductOptionId: item?.bundleProductOptionId,
                  orderItemId: item?.orderItem?.id,
                  reason: item?.reason,
                  resalable: !!item?.resalable,
                })) ?? [],
            },
          },
        }),
        id = returnNoteCreate?.id;

      await client.mutate({
        mutation: APPROVE_QUERY,
        variables: {
          id,
        },
      });

      await client.mutate({
        mutation: COMPLETE_QUERY,
        variables: {
          id,
        },
      });
    }

    return true;
  };

  getInitialData({ node }) {
    const { id } = this.state;
    const {
      active,
      updatedAt,
      createdAt,
      referenceNo,
      status = 'PENDING',
      order,
      remark,
      metadata,
      items,
      shop,
    } = node || {};

    return {
      id,
      active,
      updatedAt,
      createdAt,
      referenceNo,
      order,
      status,
      remark,
      shop,
      metadata: convertMetaObject(metadata),
      items: getFormReturnItems(parseConnection(items), order?.items).filter((item) => !!item?.active),
    };
  }
}
