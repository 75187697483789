import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate, translate as t } from 'shared/translate';
import { GET_QUERY, BULK_ACTION_QUERY } from './query';
import { getCommonColumns } from 'shared';
import { runBulkAction } from '../list_Product/ListView';
import ActiveSwitcher from './ActiveSwitcher';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/delivery_options/new',
    hasQSearch: true,
    qFields: ['name'],
    getRowLink: ({ id, name }) => ({ pathname: '/delivery_options/' + id, state: { title: name } }),
    fields: [
      {
        title: t.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: t.sort_index,
        align: 'center',
        width: 100,
        fieldName: 'sortIndex',
        type: 'number',
        filter: 'sortIndex',
        sortBy: 'sortIndex',
      },
      {
        title: t.availability,
        fieldName: 'active',
        type: 'bool',
        filter: 'active',
        sortBy: 'active',
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} data={data} onCompleted={this.refetch} />;
        },
        noLink: true,
      },
      ...getCommonColumns(),
    ],
    menus: [
      {
        name: translate.delete,
        enable: () => this.getSelectionCount() > 0,
        onClick: async () => {
          await runBulkAction({
            confirm: translate.formatString(translate['confirm_delete_n_records'], this.getSelectionCount()),
            mutation: BULK_ACTION_QUERY,
            filter: this.getSelectionFilter(),
            action: 'DELETE',
            loadingMessage: translate.delete,
            successMessage: translate.delete_success,
          });
        },
      },
    ],
    selectionMode: true,
  };
  getData = ({ node } = {}) => {
    const { shippingZones } = node || {};
    return shippingZones;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
