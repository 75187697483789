import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../components/FormPageMaker/Fields/SwitchField';

const ACTIVATE_QUERY = gql`
  mutation ($id: ID!) {
    paymentMethodActivate(id: $id) {
      id
      active
    }
  }
`;
const DEACTIVATE_QUERY = gql`
  mutation ($id: ID!) {
    paymentMethodDeactivate(id: $id) {
      id
      active
    }
  }
`;

export default ({ id, disabled, active, onCompleted }) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    if (active !== isActive) {
      setIsActive(active);
    }
  }, [active]);

  return (
    <SwitchField
      checked={isActive}
      value={isActive}
      disabled={disabled || loading}
      onChange={async (e) => {
        try {
          setIsActive(!active);
          setLoading(true);
          await client.mutate({
            mutation: active ? DEACTIVATE_QUERY : ACTIVATE_QUERY,
            variables: {
              id,
            },
          });
          onCompleted();
        } catch (e) {
          toast.error(errorParser(e));
          setIsActive(active);
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
