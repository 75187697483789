import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../../shared/translate';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';
import { PriceFormat } from '../../../../shared';
import HashTagsArrayField from '../../../../components/FormPageMaker/Fields/HashTagsArrayField';

const VariantRowOpen = ({ variation, onChange = (_) => _, disabled }) => {
  const { cost = 1, weight = 1, averageCost, stockLocations = [], hashtags = [], lowStock } = variation || {};
  const { remind = false, quantity = 0 } = lowStock || {};

  return (
    <Box p={2} style={{ backgroundColor: '#F6F6F6', borderRadius: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Typography1Title>{translate.cost}</Typography1Title>
              <Box pt={1}>
                <InputField
                  type="number"
                  // eslint-disable-next-line
                  inputProps={{
                    min: 0,
                    step: 0.01,
                  }}
                  value={cost}
                  onChange={(e) => {
                    variation.cost = e.target.value;
                    onChange(variation);
                  }}
                  disabled={disabled}
                  required={true}
                  // eslint-disable-next-line
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography1Title>{translate.average_cost}</Typography1Title>
              <Box pt={'13px'}>
                <Typography>{PriceFormat(averageCost)}</Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography1Title>{translate.weight}</Typography1Title>
              <Box pt={1}>
                <InputField
                  disabled={disabled}
                  type="number"
                  // eslint-disable-next-line
                  inputProps={{
                    min: 0,
                    step: 0.001,
                  }}
                  value={weight}
                  onChange={(e) => {
                    variation.weight = e.target.value;
                    onChange(variation);
                  }}
                  required={true}
                  // eslint-disable-next-line
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography1Title>{translate.low_inventory_alert}</Typography1Title>
              <Box pt={1}>
                <InputField
                  disabled={disabled || !remind}
                  type="number"
                  // eslint-disable-next-line
                  inputProps={{
                    step: 1,
                  }}
                  value={quantity}
                  onChange={(e) => {
                    variation.lowStock.quantity = e.target.value;
                    onChange(variation);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ width: 'min-content' }}>
          <Typography1Title>{translate.shelf_tag || '貨架位置'}</Typography1Title>
          <Box>
            <HashTagsArrayField
              isOneRow={true}
              hideHash={true}
              value={stockLocations || []}
              onChange={(v) => {
                variation.stockLocations = (v || []).filter((_) => _);
                onChange(variation);
              }}
              disabled={disabled}
            />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ width: 'min-content' }}>
          <Typography1Title>{translate.hash_tag || '標籤'}</Typography1Title>
          <Box>
            <HashTagsArrayField
              isOneRow={true}
              value={hashtags || []}
              onChange={(v) => {
                variation.hashtags = (v || []).filter((_) => _);
                onChange(variation);
              }}
              disabled={disabled}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VariantRowOpen;

const Typography1Title = ({ children }) => {
  return <Typography style={{ color: '#000', fontSize: '0.98em' }}>{children}</Typography>;
};
