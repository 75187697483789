import React from 'react';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box, Card } from '@material-ui/core';
import CustomMessages from './CustomMessages';
import SystemMessages from './SystemMessages';

export default (props) => {
  const { history, location: { pathname } = {} } = props;
  const tabs = [
      { name: translate.customer_chat, link: '/messages' },
      {
        name: translate.system_message,
        link: '/system_messages',
      },
    ],
    tab = tabs.findIndex((tab) => new RegExp(tab.link).test(pathname));
  const paneHeight = 'calc(100% - 50px)';

  return (
    <Box height={paneHeight} display={'flex'} flexDirection={'column'}>
      <div>
        <Taber
          currentTab={tab}
          onTabChange={(tab) => {
            history.push((tabs[tab] || {}).link);
          }}
          tabs={tabs}
        />
      </div>
      {
        {
          0: (
            <Card square elevation={0} style={{ flex: 1 }}>
              <Box p={3} height={'100%'}>
                <CustomMessages {...props} />
              </Box>
            </Card>
          ),
          1: <SystemMessages {...props} />,
        }[tab]
      }
    </Box>
  );
};
