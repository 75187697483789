import React, { useState } from 'react';
import { DELETE_QUERY } from './query';
import { useMutation } from '@apollo/client';
import { translate, translateFormat } from '../../../shared/translate';
import { toast } from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import ActivityIndicator from '../../../components/ActivityIndicator';
import confirmation from '../../../shared/confirmation';
import { IconButton } from '@material-ui/core';
import { Icon } from '../../../components/IconRender';
import { sleep } from '../../../shared';

const DeleteButton = ({ id, name, list }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [deleteSitemap] = useMutation(DELETE_QUERY);
  return (
    <IconButton
      color={'secondary'}
      disabled={isSubmitting}
      onClick={async () => {
        try {
          if (await confirmation(translateFormat(translate.is_delete_sitemap, { name }))) {
            ActivityIndicator.show();
            setSubmitting(true);
            await deleteSitemap({
              variables: { id },
            });
            sleep(3000).then(() => list.refetch());
            toast.success(translate.delete_success);
          }
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          ActivityIndicator.hide();
          setSubmitting(false);
          list.refetch();
        }
      }}
    >
      <Icon icon={'faTrashAlt'} />
    </IconButton>
  );
};

export default DeleteButton;
