import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DurationField from '../../components/FormPageMaker/Fields/DurationField';
import { translate } from '../../shared/translate';
import { binColor } from '../../theme';

const OrderAutoCompleteField = ({ value, disabled, onChange }) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant={'h6'}>
        {translate.auto_complete_order || '自動完成訂單'} <span style={{ color: binColor }}>{' *'}</span>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="subtitle2">
            {translate.update_status_when_shipped ||
              '如果你有使用非支援物流方式，系統並無法追蹤物流配送進度。若你已經透過系統更新貨品為"寄出"，將會於更新狀態後'}
          </Typography>
        </Grid>
        <Grid item>
          <DurationField
            value={value}
            onChange={(v) => {
              onChange(v);
            }}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{translate.auto_complete || '由系統自動完成訂單'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default OrderAutoCompleteField;
