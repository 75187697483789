import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import InputField from './InputField';
import { Icon } from '../../IconRender';
import LSkeleton from '../../LSkeleton';

export default ({ loading, disabled, InputProps, ...props }) => {
  const [visible, setVisible] = useState(false),
    toggle = () => setVisible(!visible);

  if (loading) return <LSkeleton height={30} />;
  return (
    <InputField
      type={visible ? 'text' : 'password'}
      disabled={disabled}
      // eslint-disable-next-line
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'}>
            <IconButton size={'small'} disabled={disabled} onClick={toggle}>
              <Icon icon={visible ? 'faEye' : 'faEyeSlash'} />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...props}
    />
  );
};
