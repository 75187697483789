import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const HashWatcher = ({ id, onHashChange = (_) => _, children = (_) => null }) => {
  const history = useHistory(),
    _hash = history.location.hash,
    hash = _hash ? decodeURI(_hash) : '';
  const active = id === hash;

  useEffect(() => {
    onHashChange(hash);
  }, [hash]);

  return children({ hash, active, id, className: active ? 'on-hash' : '' });
};

export default HashWatcher;
