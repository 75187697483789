import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import FormRow from '../../../components/FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import HashTagsField from '../../../components/FormPageMaker/Fields/HashTagsField';

const ModifierField = ({ value = {}, onChange = (_) => _, disabled }) => {
  return (
    <Grid container spacing={1}>
      <FormRow title={translate.variant_remark} required={true} md={3} xs={6}>
        <InputField
          type={'text'}
          value={value.name}
          onChange={(e) => {
            onChange({
              ...value,
              name: e.target.value,
            });
          }}
          required={true}
          disabled={disabled}
          maxLength={25}
        />
      </FormRow>
      <FormRow title={translate.variant_content} required={true} md={6} xs={6}>
        <HashTagsField
          disabled={disabled}
          value={value.options
            .map((opt) => opt.name)
            .filter((_) => _)
            .join('||')}
          separator={'||'}
          onChange={(v = '') => {
            const newOptions = v.split('||').map((name) => ({ name, priceAdjustment: 0 }));

            onChange({
              ...value,
              options: newOptions,
            });
          }}
        />
      </FormRow>
      <FormRow title={translate.selection_opt || '選擇數量'} md={3} xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputField
              type={'number'}
              disabled={disabled}
              value={value.max}
              onChange={(e) => {
                onChange({
                  ...value,
                  max: +e.target.value,
                });
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{translate.maximum}</InputAdornment>,
              }}
              inputProps={{
                step: 1,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              type={'number'}
              disabled={disabled}
              value={value.min}
              onChange={(e) => {
                onChange({
                  ...value,
                  min: +e.target.value,
                });
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{translate.minimum}</InputAdornment>,
              }}
              inputProps={{
                step: 1,
              }}
            />
          </Grid>
        </Grid>
      </FormRow>
    </Grid>
  );
};

export default ModifierField;
