import { withStyles, TableContainer } from '@material-ui/core';

const ConnectionTableContainer = withStyles((theme) => ({
  root: {
    // transform: 'rotateX(180deg)',
    maxWidth: '100vw',
    maxHeight: '62vh',
    position: 'relative',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #eee',
  },
  '&::-webkit-scrollbar': {
    height: 8,
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}))(TableContainer);

export default ConnectionTableContainer;
