import React from 'react';
import LSkeleton from '../../LSkeleton';
import InputField from './InputField';
import { InputAdornment } from '@material-ui/core';
import SelectField from './SelectField';
import { translate } from '../../../shared/translate';

const PeriodField = ({
  loading,
  className = '',
  style = {},
  value = '',
  onChange,
  variant = 'outlined',
  InputProps,
  disabled,
  ...props
}) => {
  const [, count = 1, unit = 'D'] = /^P(\d+)([DMW])$/.exec(value) || [];

  if (loading) return <LSkeleton height={30} />;

  return (
    <InputField
      type={'number'}
      value={count}
      onChange={(e) => {
        onChange(`P${e.target.value}${unit}`);
      }}
      // eslint-disable-next-line
      inputProps={{
        step: 1,
        min: 0,
      }}
      disabled={disabled}
      // eslint-disable-next-line
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position={'end'} disableTypography>
            <SelectField
              value={unit}
              disabled={disabled}
              options={[
                { label: translate.day, value: 'D' },
                { label: translate.week, value: 'W' },
                { label: translate.month, value: 'M' },
              ]}
              required
              onChange={(e) => {
                onChange(`P${count}${e.target.value}`);
              }}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PeriodField;
