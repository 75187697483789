import React, { Fragment, useState } from 'react';
import { Icon } from 'components/IconRender';
import { Button, Typography, Menu, MenuItem } from '@material-ui/core';
import { translate } from 'shared/translate';

export default () => {
  const options = [
    {
      label: '繁體',
      value: 'zh-hk',
    },
    {
      label: 'English',
      value: 'en',
    },
  ];
  const [anchorEl, setAnchorEl] = useState(undefined);
  const handleClose = () => setAnchorEl(undefined);
  const currentLanguage = options.find((opt) => opt.value === translate._language) || {};

  return (
    <Fragment>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon={'KeyboardArrowDown'} type={'material'} />}
      >
        <Typography
          variant="h5"
          style={{
            textTransform: 'none',
          }}
        >
          {currentLanguage.label || '繁體'}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(({ label, value }) => (
          <MenuItem
            key={value}
            onClick={() => {
              localStorage.setItem('lang', value);
              handleClose();
              global.document.location.reload();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
