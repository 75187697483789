import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';

export const DEACTIVATE_STAFF = gql`
  mutation ($id: ID!) {
    staffDeactivate(id: $id) {
      id
      active
    }
  }
`;

export const ACTIVATE_STAFF = gql`
  mutation ($id: ID!) {
    staffActivate(id: $id) {
      id
      active
    }
  }
`;

export default ({ id, active, data = {}, onCompleted }) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    if (active !== isActive) {
      setIsActive(active);
    }
  }, [active]);

  return (
    <SwitchField
      checked={isActive}
      value={isActive}
      disabled={loading}
      onChange={async (e) => {
        try {
          setIsActive(!active);
          setLoading(true);
          await client.mutate({
            mutation: isActive ? DEACTIVATE_STAFF : ACTIVATE_STAFF,
            variables: {
              id: id || undefined,
            },
          });
          onCompleted();
        } catch (e) {
          toast.error(errorParser(e));
          setIsActive(active);
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
