import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../../item_EmailTemplate/SplitCard';
import { defaultSuggestions } from '../defaultSuggestion';
import LayoutFront from '../LayoutFront';
import ItemsTable from '../../PrintOrder/ItemsTable';
import PaymentTable from '../../PrintOrder/PaymentTable';
import { translate } from '../../../shared/translate';

export default class LayoutOrder extends LayoutFront {
  type = 'ORDER';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[
                { label: '#index', value: '{{index}}' },
                { label: '#count', value: '{{count}}' },
                { label: '#subtitle', value: '{{subtitle}}' },
                { label: '#description', value: '{{description}}' },
                ...defaultSuggestions,
              ]}
              allows={['isTitle', 'isText', 'isLV', 'isDivider', 'isQRCode', 'isCut', 'isHTML']}
              disableOptions={['isItems']}
              parseOption={(option) => {
                if (option?.value === 'isPaymentData') return { ...option, render: undefined };
                return option;
              }}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              standardWidth={300}
              extraData={{
                index: 1,
                subtitle: 'Iced only',
                description: 'Oolong Tea with Guava Juice and Konjac Jelly',
                remark: '- less ice\n- less sugar',
              }}
              extraRenderer={{
                isDivider: () => <hr />,
                isItems: (node) => <ItemsTable order={node} />,
                isPaymentData: (node) => <PaymentTable order={node} />,
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
