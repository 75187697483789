import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, InputAdornment, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { Formik } from 'formik';
import FormToolbar from '../../../components/FormToolbar';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { gql } from '@apollo/client';
import AddButton from '../../../components/CustomFormField/AddButton';
import InputField from '../../../components/FormPageMaker/Fields/InputField';

export const AddAdjustment = ({ orderId, loading }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <AddButton loading={loading} onClick={() => setVisible(true)} />
      <Dialog open={visible} onClose={() => setVisible(false)} maxWidth={'xs'}>
        <DialogTitle style={{ alignSelf: 'center' }}>{translate.add_adjustment || '新增調整'}</DialogTitle>
        <Divider style={{ margin: '0 30px' }} />
        <Formik
          initialValues={{ description: '', amount: undefined }}
          onSubmit={async (values) => {
            try {
              await client.mutate({
                mutation: gql`
                  mutation ($orderId: ID!, $input: OrderAdjustmentInput!) {
                    orderAdjustmentAdd(orderId: $orderId, input: $input) {
                      id
                      totalAdjustments {
                        sortIndex
                        amount
                        description
                      }
                    }
                  }
                `,
                variables: {
                  orderId,
                  input: {
                    amount: values.amount,
                    description: values.description,
                  },
                },
              });
              setVisible(false);
              toast.success(translate.update_success);
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
            }
          }}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ padding: '10px 30px' }}>
                <Box p={2} style={{ overflow: 'hidden' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography>{translate.name || '名稱'}</Typography>
                      <InputField
                        type={'text'}
                        required={true}
                        value={values.description}
                        onChange={(e) => setFieldValue('description', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{translate.amount || '金額'}</Typography>
                      <InputField
                        type={'number'}
                        // eslint-disable-next-line
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position={'start'}>
                              <Typography>$</Typography>
                            </InputAdornment>
                          ),
                        }}
                        required={true}
                        value={values.amount}
                        onChange={(e) => setFieldValue('amount', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <FormToolbar
                submitBtnType={'submit'}
                loading={isSubmitting}
                disabled={isSubmitting}
                onCancel={() => setVisible(false)}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddAdjustment;
