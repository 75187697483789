import React from 'react';
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { translate } from 'shared/translate';
import InputField from 'components/FormPageMaker/Fields/InputField';

export default ({ modifiers = [], onChange = (_) => _, disabled }) => {
  return (
    <Box mt={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'caption'}>{translate.variant_content}</Typography>
              </TableCell>
              <TableCell width={150}>
                <Typography variant={'caption'}>{translate.additional_fee}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modifiers.map((mod) => {
              const { name, options = [] } = mod;
              return options.map((option, j) => (
                <TableRow key={`${name}-${j}`}>
                  <TableCell>
                    {name} {option.name}
                  </TableCell>
                  <TableCell>
                    <InputField
                      type="number"
                      // eslint-disable-next-line
                      inputProps={{
                        step: 0.01,
                      }}
                      value={option.priceAdjustment}
                      onChange={(e) => {
                        option.priceAdjustment = e.target.value;
                        onChange(modifiers);
                      }}
                      disabled={disabled}
                      // eslint-disable-next-line
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ));
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
