import React from 'react';
import { Divider } from '@material-ui/core';

const Connector = () => (
  <Divider
    style={{
      height: 80,
      marginTop: -16,
      marginBottom: -16,
      marginLeft: 40 + 25,
    }}
    orientation={'vertical'}
  />
);

export default Connector;
