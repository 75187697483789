import React from 'react';
import { Box } from '@material-ui/core';
import Copyright from '../../../components/Copyright';
import TopBar from './TopBar';
import { Route, Switch } from 'react-router-dom';

const StandardTheme = ({ pages, ...props }) => (
  <Box
    px={{ xs: 0, sm: 1, md: 1, lg: 3 }}
    flex={1}
    display={'flex'}
    minHeight={'100%'}
    flexDirection={'column'}
    overflow={'hidden auto'}
  >
    <Box flex={1} mb={2}>
      <TopBar pages={pages} {...props} />
      <Switch>
        {pages
          .map(
            ({ link, component, exact = true }, i) =>
              !!component && !!link && <Route key={i} path={link} exact={exact} component={component} />,
          )
          .filter((_) => _)}
      </Switch>
    </Box>
    <Copyright />
  </Box>
);

export default StandardTheme;
