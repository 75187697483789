import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyCoupon {
        orders(cursor: $cursor, limits: $limits, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            id
            referenceNo
            total
            status
            paymentStatus
            shippingStatus
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_USER_COUPONS_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: UserCouponFilterInput) {
    node(id: $id) {
      id
      ... on CompanyCoupon {
        userCoupons(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            id
            handle
            status
            createdAt
          }
        }
      }
    }
  }
`;

export const USER_COUPON_SUBMIT = gql`
  mutation ($id: ID!, $count: Int!) {
    userCouponsGenerate(id: $id, count: $count) {
      id
    }
  }
`;
