import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $discountId: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
    node(id: $id) {
      id
      ... on Company {
        report {
          discountSales(id: $discountId, startedAt: $startedAt, startedThru: $startedThru) {
            id
            name
            amount
            count
            orderCount
            avgPrice
            avgCount
            discountAmount
            usageCount

            memberAmount
            nonMemberAmount

            salesTrends {
              name
              data {
                name
                value
              }
            }
            memberLevels {
              name
              value
            }
            memberLevelAmounts {
              name
              value
            }
            salesPoints {
              name
              value
            }
            countries {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_SALE_RECORD_QUERY = gql`
  query (
    $id: ID!
    $discountId: ID!
    $startedAt: AWSDateTime!
    $startedThru: AWSDateTime!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        report {
          discountSales(id: $discountId, startedAt: $startedAt, startedThru: $startedThru) {
            id
            salesRecords(cursor: $cursor, limits: $limits, sortBy: $sortBy) {
              nextCursor
              totalCount
              nodes {
                createdAt
                shopName
                referenceNo
                discountAmount
                count
                amount
              }
            }
          }
        }
      }
    }
  }
`;
