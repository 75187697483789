import { gql } from '@apollo/client';

const FRAGMENT_MESSAGE = gql`
  fragment FRAGMENT_MESSAGE on ChannelMessage {
    id
    createdAt
    type
    content
    sender {
      id
      name
      email
      picture
    }
    read(playerId: $playerId)
    process {
      id
      updatedAt
      createdAt
      status
      output
    }
  }
`;

export const GET_LATEST_MESSAGES = gql`
  query ($id: ID!, $playerId: String!) {
    node(id: $id) {
      ... on CompanyShop {
        systemChannels: channels(
          limits: 1
          filter: { type: { operator: IN, value: ["SYSTEM"] } }
          sortBy: [{ field: "updatedAt", order: DESC }]
        ) {
          nodes {
            id
            type
            code
            messages(limits: 1) {
              nodes {
                ...FRAGMENT_MESSAGE
              }
            }
          }
        }
        otherChannels: channels(
          limits: 4
          filter: { type: { operator: IN, value: ["ORDER", "GENERAL"] } }
          sortBy: [{ field: "updatedAt", order: DESC }]
        ) {
          nodes {
            id
            type
            code
            players {
              id
              name
              picture
              email
            }
            messages(limits: 1) {
              nodes {
                ...FRAGMENT_MESSAGE
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_MESSAGE}
`;

export const CHANNEL_MESSAGE_SUBSCRIPTION = gql`
  subscription ($shopId: ID) {
    channelMessageUpdate(shopId: $shopId) {
      id
      shopId
    }
  }
`;
