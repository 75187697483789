import React from 'react';
import { translate } from '../../../shared/translate';
import BundleProductItemContent from './BundleProductItemContent';
import CommonItemContent from './CommonItemContent';
import { NumberFormat, PriceFormat } from '../../../shared';
import PrintTable from '../../../components/PrintTable';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import StyledTableCell from '../../item_Order/ItemsCard/StyledTableCell';
import _ from 'lodash';
import ServiceBundleItemContent from './ServiceBundleItemContent';

const ItemsTable = ({ loading, order }) => {
  const { items: _items = [], subtotal, total, totalAdjustments = [] } = order || {},
    currency = 'HKD';

  const items = _items.filter((item) => !(item || {}).addOnProduct);
  const addOnProducts = _items.filter((item) => !!(item || {}).addOnProduct);

  const totalQuantity = _.sumBy(items, 'quantity') + _.sumBy(addOnProducts, 'quantity');

  const groupedTotalAdjustments = totalAdjustments.reduce((acc, cur) => {
    const existingAdjustment = acc.find((a) => a.description === cur.description);
    if (existingAdjustment) {
      existingAdjustment.amount += cur.amount;
    } else {
      acc.push({ ...cur });
    }
    return acc;
  }, []);

  return (
    <PrintTable
      loading={loading}
      data={items}
      columns={[
        {
          title: translate.product_details,
          render: (item) => {
            if (!!item?.bundleProduct) return <BundleProductItemContent item={item} />;
            if (!!item?.serviceBundle) return <ServiceBundleItemContent item={item} />;
            return <CommonItemContent item={item} />;
          },
        },
        {
          width: 120,
          align: 'right',
          title: translate.unit_price,
          value: ({ unitPrice }) => PriceFormat(unitPrice, currency),
        },
        {
          width: 120,
          align: 'center',
          title: translate.quantity,
          value: ({ quantity }) => `${quantity}`,
        },
        {
          width: 120,
          align: 'left',
          title: translate.price,
          value: ({ unitPrice, quantity }) => `${PriceFormat(unitPrice * quantity, currency)}`,
        },
      ]}
    >
      {/* sub total */}
      <TableRow style={{ backgroundColor: '#fff' }}>
        <StyledTableCell colSpan={2} align={'right'}>
          <Body1Typography>{translate.sub_total}</Body1Typography>
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          <Body1Typography align={'center'}>{_.sumBy(items, 'quantity')}</Body1Typography>
        </StyledTableCell>
        <StyledTableCell colSpan={1} align={'left'}>
          <Body1Typography>{loading ? <Skeleton width={60} /> : `${PriceFormat(subtotal, currency)}`}</Body1Typography>
        </StyledTableCell>
      </TableRow>

      {groupedTotalAdjustments.map(({ description, amount }, j) => {
        return (
          <TableRow key={j} style={{ backgroundColor: '#fff' }}>
            <StyledTableCell colSpan={2} align={'right'}>
              <Body1Typography style={{ wordBreak: 'break-word' }}>
                {loading ? <Skeleton width={40} /> : description}
              </Body1Typography>
            </StyledTableCell>
            <StyledTableCell colSpan={1} />
            <StyledTableCell colSpan={1} align={'left'}>
              <Body1Typography>
                {loading ? <Skeleton width={70} /> : `${PriceFormat(amount, currency)}`}
              </Body1Typography>
            </StyledTableCell>
          </TableRow>
        );
      })}

      {/* add-on products */}
      {addOnProducts.length > 0 && (
        <TableRow style={{ backgroundColor: '#edeef0' }}>
          <TableCell colSpan={2}>
            <Body1Typography align={'right'}>{translate.addon_products}</Body1Typography>
          </TableCell>
          <TableCell colSpan={3} />
        </TableRow>
      )}
      {addOnProducts.map(({ description, quantity, unitPrice }, i) => {
        return (
          <>
            <TableRow style={{ backgroundColor: '#fff' }}>
              <StyledTableCell colSpan={2}>
                <Body1Typography align={'left'}>{description}</Body1Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={1} align={'center'}>
                <Body1Typography>{NumberFormat(quantity)}</Body1Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={1} align={'left'}>
                <Body1Typography>
                  {loading ? <Skeleton width={90} /> : `${PriceFormat(unitPrice * quantity, currency)}`}
                </Body1Typography>
              </StyledTableCell>
            </TableRow>
          </>
        );
      })}

      {/* total */}
      <TableRow style={{ backgroundColor: '#fff' }}>
        <StyledTableCell colSpan={2} align={'right'}>
          <Body1Typography>{translate.total}</Body1Typography>
        </StyledTableCell>
        <StyledTableCell colSpan={1} align={'center'}>
          <Body1Typography>{totalQuantity}</Body1Typography>
        </StyledTableCell>
        <StyledTableCell colSpan={1} align={'left'}>
          <Body1Typography>{loading ? <Skeleton width={90} /> : `${PriceFormat(total, currency)}`}</Body1Typography>
        </StyledTableCell>
      </TableRow>
    </PrintTable>
  );
};

export default ItemsTable;

export const Body1Typography = ({ children, style, ...props }) => {
  return (
    <Typography {...props} style={{ fontSize: '1rem', ...style }}>
      {children}
    </Typography>
  );
};
