import React from 'react';
import { GET_QUERY } from './query';
import moment from 'moment';
import { translate } from 'shared/translate';
import { NumberFormat, parseMedia } from '../../shared';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Card,
  CardContent,
  Grid,
  Divider,
  Breadcrumbs,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';
import { ReceivePurchaseStatus } from '../../shared/omniwe-types';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: '3em',
    paddingBottom: '4em !important',
  },
  skeleton: {
    display: 'inline-flex',
  },
}));

export default ({ match: { params: { id } = {} } = {} }) => {
  const classes = useStyles();
  const { loading, data: { node, shop } = {} } = useQuery(GET_QUERY, {
    variables: { id, shopId: localStorage.getItem('shopId') },
    skip: !id,
  });

  if (!id) return null;
  const _loading = loading || !node;

  const { fontSize = '', sort = '' } = !!window.location.search
    ? // for list page - print button: choose font size and sort by
      JSON.parse(
        '{"' +
          window.location.search.substring(1).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
          '"}',
      )
    : // for item page - print button: no option
      {};

  const { createdAt, items: { nodes: _items = [] } = {}, status, warehouse, remark } = node || {},
    { logoMedia } = shop || {};
  const items = _.sortBy(_items, (item) => {
    function getProductItem(item) {
      return item?.productVariation || item?.bundleProduct?.bundleProduct || item?.addOnProduct;
    }

    if (sort === 'sku') {
      return getProductItem(item)?.sku?.toUpperCase();
    } else {
      return _items?.find((_item) => getProductItem(_item)?.id === getProductItem(item)?.id)?.createdAt;
    }
  });

  return (
    <>
      <style>
        {`
          html, body, #root { 
            height: auto; 
            font-size: ${fontSize} !important;
          }
          img {
            display: inline-block;
          }
          @media print {
            html,
            body {
              font-size: ${fontSize} !important;
            }
          }
        `}
      </style>
      <Box overflow={'auto'}>
        <Container style={{ minWidth: 1024 }}>
          <Card square>
            <CardContent className={classes.cardContent}>
              <Grid container>
                <Grid item xs />
                {!!logoMedia && (
                  <Grid item>
                    {_loading ? (
                      <Skeleton variant={'rect'} height={80} width={80} />
                    ) : (
                      <img style={{ height: 80 }} src={parseMedia(logoMedia)} alt={'shop_logo'} />
                    )}
                  </Grid>
                )}
                <Grid item xs style={{ textAlign: 'right' }}>
                  <img
                    width={100}
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${id}`}
                    alt={id}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align={'left'} variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
                      {translate.receive_purchase}
                      {': '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align={'left'} variant={'body1'}>
                      {translate.created_at}
                      {': '}
                      {_loading ? (
                        <Skeleton className={classes.skeleton} width={140} />
                      ) : (
                        `${moment(createdAt).format('D MMM YYYY HH:mm')}`
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align={'left'} variant={'body1'}>
                      {translate.status}
                      {': '}
                      {_loading ? (
                        <Skeleton className={classes.skeleton} width={140} />
                      ) : (
                        (ReceivePurchaseStatus[status] || {}).label || status
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Box my={1}>
                  <Divider style={{ height: 2 }} />
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align={'left'} variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
                      {translate.receive_purchase_address}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align={'left'} variant={'body1'}>
                      {translate.warehouse}
                      {': '}
                      {_loading ? <Skeleton className={classes.skeleton} width={80} /> : (warehouse || {}).name || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Box bgcolor={'#edeef0'} px={2}>
                  <Grid container>
                    <Grid item xs>
                      <Typography align={'left'} variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
                        {translate.replenish_items}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography align={'center'} variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
                        {translate.quantity}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {_loading ? (
                  <Box bgcolor={'#f9f9f9'} px={2} py={1}>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <Skeleton width={140} />
                      </Grid>
                      <Grid item xs={1}>
                        <Skeleton />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  items.map(({ productVariation, sku, description, quantity, unitPrice }, i) => {
                    const { product, combination } = productVariation || {},
                      { name } = product || {};
                    return (
                      <Box key={i} bgcolor={'#f9f9f9'} px={2} py={1}>
                        <Grid container>
                          <Grid item xs>
                            <Typography style={{ fontWeight: 'bold' }}>{name}</Typography>
                            <Typography variant={'body2'}>{`SKU： ${sku || ''}`}</Typography>
                            <Breadcrumbs>
                              {(combination || []).map(({ name, option }, j) => (
                                <Typography key={j} variant={'body2'} noWrap>
                                  {name}: {option}
                                </Typography>
                              ))}
                            </Breadcrumbs>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography align={'center'} variant={'body1'}>
                              {quantity}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                )}
                <Box mt={2} px={2}>
                  <Grid container>
                    <Grid item xs={8} />
                    <Grid item xs={2}>
                      <Typography align={'left'} variant={'body1'}>
                        {translate.receive_purchase_quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant={'body1'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {_loading ? (
                          <Skeleton width={90} />
                        ) : (
                          `${NumberFormat(
                            (items || []).reduce((reducer, item) => reducer + +(item || {}).quantity, 0),
                          )}`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box mb={1} mt={3}>
                <Divider />
              </Box>
              <Typography variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
                {translate.remark}
              </Typography>
              <Typography
                style={{
                  whiteSpace: 'break-spaces',
                }}
              >
                {remark || '-'}
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
