import FormPageMaker from '../../components/FormPageMaker';
import { translate, translateFormat } from '../../shared/translate';
import { GET_QUERY, POST_QUERY } from './query';
import { client } from '../../shared/apollo';
import { parseConnection } from '../../shared';
import MerchantEmailCard from './MerchantEmailCard';
import errorParser from '../../shared/errorParser';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
    },
    tabs: [
      {
        name: translate.email_setting,
        cards: [
          {
            id: '#' + translate.merchant_email,
            name: translate.merchant_email,
            fields: [
              {
                render: MerchantEmailCard,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    try {
      const {
        id,
        gql: { submit },
      } = this.state;

      await client.mutate({
        mutation: submit,
        variables: {
          id,
          input: {
            name: values.name,
            contactEmails: values.orderEmails
              .concat(values.billingEmails.concat(values.generalEmails.concat(values.exportEmails)))
              .map((value) => ({ email: (value || {}).email, type: (value || {}).type })),
          },
        },
      });
      return true;
    } catch (e) {
      let errEmail = [];
      if (values.orderEmails?.length > 2) errEmail.push(translate.order_email);
      if (values.billingEmails?.length > 2) errEmail.push(translate.bill_email);
      if (values.generalEmails?.length > 2) errEmail.push(translate.merchant_contact_email);
      if (values.exportEmails?.length > 2) errEmail.push(translate.output_email);
      throw errorParser(e, errEmail.join(' / '));
    }
  };

  getInitialData({ node, me }) {
    const { name, contactEmails: _contactEmails } = node || {};
    const contactEmails = parseConnection(_contactEmails).nodes;

    return {
      orderEmails: contactEmails.filter((o) => o.type === 'ORDER'),
      billingEmails: contactEmails.filter((o) => o.type === 'BILLING'),
      generalEmails: contactEmails.filter((o) => o.type === 'GENERAL'),
      exportEmails: contactEmails.filter((o) => o.type === 'EXPORT'),
      name,
    };
  }
}
