import { gql } from '@apollo/client';

export const FRAGMENT_COLLECTION = gql`
  fragment FRAGMENT_COLLECTION on ShopCollection {
    id
    name
    code
    active
    media {
      src
      optimizedSrc(width: 256, height: 256)
    }
    parents {
      id
      name
      parentId
    }
    sortIndex
    updatedAt
    createdAt
    products: allProducts @client {
      totalCount
      nodes {
        id
        active
        sku
        name
      }
    }
  }
`;

export const GET_QUERY = gql`
  query (
    $id: ID!
    $cursor: Int
    $limits: Int
    $filter: CollectionFilterInput
    $sortBy: [SorterInput!]
    $query: String
  ) {
    node(id: $id) {
      id
      ... on CompanyShop {
        collections(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_COLLECTION
          }
        }
      }
    }
  }
  ${FRAGMENT_COLLECTION}
`;
