import { gql } from '@apollo/client';

export const GET_ORDER_IDS = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $filter: OrderFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Company {
        id
        orders(query: $query, cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          totalAmount
          nodes {
            id
            status
            shippingStatus
            items {
              id
              quantity
            }
          }
        }
      }
    }
  }
`;
