import React from 'react';
import { GET_QUERY } from './query';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { InvoiceStatus, OrderStatus, ShippingStatus } from 'shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';
import { Box, Card, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuExport from './MenuExport';
import MenuPackingList from './MenuPackingList';
import MenuCancel from './MenuCancel';
import MenuConfirm from './MenuConfirm';
import MenuInvoicePrint from './MenuInvoicePrint';
import { cloneObject, omniweEmailTest, parseConnection, PriceFormat } from '../../../shared';
import { checkPermissionV2 } from '../../../components/PermissionMask';
import moment from 'moment';
import ConnectionPagination from '../../../components/ConnectionPageMaker/ConnectionPagination';
import TotalSales from './TotalSales';
import ConnectionTableContainer from '../../../components/ConnectionPageMaker/ConnectionTableContainer';
import MenuPOSDailyReport from './MenuPOSDailyReport';
import PrintOptionsButton from '../../../components/PrintOptionsButton';
import CreateDeliveryNote from './CreateDeliveryNote';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id, referenceNo }) => {
      return { pathname: '/orders/' + id, state: { title: referenceNo } };
    },
    fields: [
      {
        title: translate.date,
        align: 'left',
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
        filterIndex: 0,
        filterInputProps: ({ value = [] } = {}) => {
          const [since, until] = value || [];
          return {
            sinceProps: {
              inputProps: {
                min: !!until && moment(until).subtract(31, 'days').format('YYYY-MM-DDTHH:mm'),
                max: !!until && moment(until).format('YYYY-MM-DDTHH:mm'),
              },
            },
            untilProps: {
              inputProps: {
                min: !!since && moment(since).format('YYYY-MM-DDTHH:mm'),
                max: !!since && moment(since).add(31, 'days').format('YYYY-MM-DDTHH:mm'),
              },
            },
          };
        },
      },
      {
        title: translate.shop_name,
        align: 'left',
        width: 150,
        value: ({ shop }) => {
          const { name, customDomain, hostname, id } = shop;
          return name || customDomain || hostname || id;
        },
        filter: 'shopId',
        filterIndex: 1,
        filterType: 'shop',
        sortBy: 'shopId.keyword',
      },
      {
        title: translate.delivery_country,
        filter: 'shippingAddress.country',
        filterIndex: 8,
        filterType: 'country',
        filterChannel: 'q',
        column: false,
      },
      {
        title: translate.shipping_method,
        filter: 'shippingProviderId.keyword',
        filterIndex: 7,
        filterType: 'shippingProvider',
        filterChannel: 'q',
        column: false,
      },
      {
        title: translate.payment_method,
        filter: 'paymentProviderIds.keyword',
        filterIndex: 5,
        filterType: 'paymentProvider',
        filterChannel: 'q',
        column: false,
      },
      {
        title: translate.order_number,
        fieldName: 'referenceNo',
        sortBy: 'referenceNo.keyword',
      },
      {
        title: translate.contact,
        render: ({ shippingAddress, customer = {} }) => {
          const email =
            customer && customer.email && !omniweEmailTest(customer.email)
              ? customer.email
              : (shippingAddress || {}).email;
          const tel = (shippingAddress || {}).tel;
          const country = translate.countries[(shippingAddress || {}).country];
          const name =
            shippingAddress?.person ||
            (((customer || {}).metadata || []).find(({ key }) => key === 'name') || {}).value;

          return (
            <div>
              {!!name && (
                <Typography>
                  {translate.name}: {name}
                </Typography>
              )}
              {!!email && !omniweEmailTest(email) && (
                <Tooltip title={email}>
                  {customer ? (
                    <Link to={{ pathname: '/customers/' + customer.id, state: { title: customer.email } }}>
                      <Typography noWrap>
                        {translate.contact_email}: {email}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography noWrap>
                      {translate.contact_email}: {email}
                    </Typography>
                  )}
                </Tooltip>
              )}
              {!!tel && (
                <Tooltip title={tel}>
                  <Typography noWrap>
                    {translate.contact_number}: {tel}
                  </Typography>
                </Tooltip>
              )}
              {!!country && (
                <Typography>
                  {translate.country}: {country}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        title: translate.order_amount,
        align: 'right',
        width: 120,
        fieldName: 'total',
        type: 'price',
        filter: 'total',
        filterIndex: 3,
        sortBy: 'total',
      },
      {
        title: translate.refund,
        align: 'right',
        width: 100,
        value: ({ totalRefund }) => (
          <Typography variant={'caption'}>{totalRefund ? PriceFormat(totalRefund) : ''}</Typography>
        ),
      },
      {
        title: translate.order_status,
        align: 'center',
        width: 1,
        fieldName: 'status',
        type: 'options',
        options: Object.keys(OrderStatus).map((key) => ({
          id: OrderStatus[key]?.label,
          label: <StatusBadge status={key} />,
          value: key,
        })),
        filter: this.props?.allowStatusFilter ? 'status' : undefined,
        filterIndex: 2,
        sortBy: 'status.keyword',
      },
      {
        title: translate.payment_status,
        align: 'center',
        width: 1,
        fieldName: 'paymentStatus',
        type: 'options',
        options: Object.keys(InvoiceStatus)
          .map(
            (key) =>
              key !== 'OVERPAID' && {
                id: InvoiceStatus[key]?.label,
                label: <StatusBadge statusObject={InvoiceStatus} status={key} />,
                value: key,
              },
          )
          .filter(Boolean),
        filter: this.props?.allowStatusFilter ? 'paymentStatus' : undefined,
        filterIndex: 4,
        sortBy: 'paymentStatus.keyword',
      },
      {
        title: translate.shipment_status,
        align: 'center',
        width: 1,
        fieldName: 'shippingStatus',
        type: 'options',
        options: Object.keys(ShippingStatus).map((key) => ({
          id: ShippingStatus[key]?.label,
          label: <StatusBadge statusObject={ShippingStatus} status={key} />,
          value: key,
        })),
        filter: this.props?.allowStatusFilter ? 'shippingStatus' : undefined,
        filterIndex: 6,
        sortBy: 'shippingStatus.keyword',
      },
      {
        title: translate.other_actions,
        fieldName: 'action',
        width: 1,
        noLink: true,
        render: (order) => <PrintOptionsButton id={order.id} partialHref={'print_order'} />,
      },
    ],
    selectionMode: true,
    hasQSearch: true,
    qFields: [
      'referenceNo',
      'shippingAddress.person',
      'shippingAddress.email',
      'shippingAddress.tel',
      'shippingAddress.country',
    ],
    menus: [
      checkPermissionV2('exportOrder') && MenuExport,
      checkPermissionV2('confirmOrder') && MenuConfirm,
      checkPermissionV2('cancelOrder') && MenuCancel,
      checkPermissionV2('getPackingList') && MenuPackingList,
      checkPermissionV2('getInvoicePrints') && MenuInvoicePrint,
      checkPermissionV2('createDeliveryNote') && CreateDeliveryNote,
      checkPermissionV2('posDailyReport') && MenuPOSDailyReport,
    ].filter((_) => _),
  };

  renderPagination({ loading, data: { nextCursor, totalCount, totalAmount } = {} }) {
    const { cursor, limits, refreshable } = this.state;

    return (
      <ConnectionPagination
        loading={loading}
        totalCount={totalCount}
        cursor={cursor}
        limits={limits}
        extra={<TotalSales loading={loading} totalAmount={totalAmount} />}
        onRefreshClick={
          !!refreshable
            ? () => {
                this.refetch && this.refetch();
              }
            : undefined
        }
        onCursorChange={(cursor) => {
          this.writeQueryParams({ cursor });
        }}
      />
    );
  }

  renderContent({ loading, data = {}, refetch = (_) => _ } = {}) {
    let nodes, totalCount, nextCursor, totalAmount;
    try {
      this.refetch = refetch;
      const connection = parseConnection(this.getData(data));
      nodes = connection.nodes;
      totalCount = connection.totalCount;
      nextCursor = connection.nextCursor;
      totalAmount = this.getData(data).totalAmount;
    } catch (e) {
      nodes = [];
      totalCount = 0;
      nextCursor = null;
    }
    this.nodes = nodes;
    this.totalCount = totalCount;
    this.nextCursor = nextCursor;
    this.totalAmount = totalAmount;
    return (
      <>
        <Card square elevation={0}>
          {this.renderCardHeader({ nodes, totalCount, nextCursor, loading })}
          <ConnectionTableContainer>
            {this.renderTable({ nodes, totalCount, nextCursor, loading })}
          </ConnectionTableContainer>
          {!loading && nodes.length === 0 && this.renderNoData()}
          <Box p={2}>
            {this.renderPagination({
              loading,
              data: { nodes, totalCount, nextCursor, totalAmount: totalAmount },
              refetch,
            })}
          </Box>
        </Card>
        {this.renderModal({ nodes, totalCount, nextCursor, loading })}
      </>
    );
  }

  getData = ({ node } = {}) => {
    const { orders } = node || {};
    return orders;
  };

  parseFilter(_filter) {
    const filter = cloneObject(_filter);
    if (filter?.paymentStatus?.value?.includes('COMPLETED')) {
      filter.paymentStatus.value.push('OVERPAID');
    }
    return filter;
  }

  parseQ(_query) {
    let query = cloneObject(_query);
    if (/COMPLETED/.test(query)) {
      query = query.replace('"COMPLETED"', '"COMPLETED" "OVERPAID"');
    }
    return query;
  }

  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }

  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
