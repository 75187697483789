import React, { useEffect, useRef, useState } from 'react';
import { useTheme, Popper, ClickAwayListener, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { RichTextarea } from 'rich-textarea/lib/index.js';
import { sleep } from '../../../shared';
import { useDebounce } from 'react-use';
import { focusBorderLineColor } from '../../../theme';

const SuggestionInputField = ({
  placeholder,
  options = [],
  value,
  onChange = (v) => {},
  style,
  disabled,
  ...props
}) => {
  const handler = useRef({ cursorAt: 0 }).current;
  const [insertAt, setInsertAt] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(false);
  const theme = useTheme();
  const [inputValue, setInputValue] = useState(value);
  const [searchParts, setSearchParts] = useState('');
  const [selectIndex, setSelectIndex] = useState(0);

  const filteredOptions = options.filter((opt) => opt.label.indexOf(searchParts) > -1);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);
  useDebounce(
    () => {
      if (inputValue !== value) onChange(inputValue);
    },
    250,
    [inputValue],
  );
  useEffect(() => {
    if (insertAt >= 0) setSearchParts(inputValue?.substring(insertAt, handler.cursorAt) || '');
    else setSearchParts('');
  }, [inputValue]);

  function selectOption(option) {
    setAnchorEl(null);
    setInsertAt(-1);
    setInputValue((inputValue) => {
      return (
        (inputValue?.substring(0, insertAt) || '') +
        option.value +
        (inputValue?.substring(insertAt)?.replace(searchParts, '') || '')
      );
    });
  }

  const height = style?.height || 31.5;

  return (
    <>
      <style>{`
        textarea:focus {
          outline-color: ${focusBorderLineColor};
          border-radius: 10px;
        }
      `}</style>
      <div>
        <div
          {...props}
          style={{
            ...style,
            height,
          }}
        >
          <RichTextarea
            disabled={disabled}
            autoHeight={false}
            placeholder={placeholder}
            style={{
              fontFamily: 'monospace',
              display: 'block',
              padding: `6px 13px`,
              minHeight: 'initial',
              width: '100%',
              height: height,
              resize: style?.resize || 'none',
              verticalAlign: 'middle',
              border: 'none',
              wordBreak: 'break-all',
            }}
            value={inputValue}
            onKeyDown={(e) => {
              const el = e.target;

              handler.cursorAt = el?.selectionStart ?? -1;
              switch (e.keyCode) {
                case 38: // UP
                  if (searchParts) {
                    e.preventDefault();
                    setSelectIndex((index) => Math.max(index - 1, 0));
                  }
                  break;
                case 40: // DOWN
                  if (searchParts) {
                    e.preventDefault();
                    setSelectIndex((index) => Math.min(index + 1, filteredOptions.length - 1));
                  }
                  break;
                case 13: // ENTER
                  if (searchParts) {
                    e.preventDefault();
                    const option = filteredOptions[selectIndex];
                    if (option) selectOption(option);
                  }
                  break;
                case 8:
                  break;
                case 51:
                  if (e.shiftKey) {
                    setSelectIndex(-1);
                    setAnchorEl(el);
                    setInsertAt(el?.selectionStart ?? -1);
                    handler.cursorAt = (el?.selectionStart ?? -1) + 1;
                    sleep(0).then(() => {
                      el.focus();
                    });
                  }
                  break;
                default:
                  break;
              }
            }}
            onChange={(e) => setInputValue(e?.target?.value)}
          >
            {(str) => {
              const snippets = str?.split(/(\{\{[a-zA-Z0-9._-]+}})/g) ?? [];
              return snippets.map((snippet, i) => {
                if (/^\{\{[a-zA-Z0-9._-]+}}$/.test(snippet)) {
                  const label = options.find((opt) => opt.value === snippet)?.label;
                  if (label) {
                    const lengthDiff = Math.max(snippet.length - label.length, 0),
                      padStart = Math.ceil(lengthDiff / 2);

                    return (
                      <span
                        key={i}
                        style={{
                          color: 'white',
                          paddingTop: 2,
                          paddingBottom: 2,
                          backgroundColor: theme.palette.secondary.main,
                          borderRadius: 99,
                        }}
                      >
                        {label.padStart(padStart + label.length, ' ').padEnd(snippet.length, ' ')}
                      </span>
                    );
                  }
                }
                return snippet;
              });
            }}
          </RichTextarea>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Popper
              style={{
                zIndex: 1300,
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Paper style={{ maxHeight: '50vh', overflow: 'auto' }}>
                <List>
                  {filteredOptions.map((option, i) => {
                    const selected = selectIndex === i;
                    return (
                      <ListItem key={i} button selected={selected} onClick={() => selectOption(option)}>
                        <ListItemText>{option?.label}</ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Popper>
          </ClickAwayListener>
        </div>
      </div>
    </>
  );
};

export default SuggestionInputField;
