import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { checkPermissionV2 } from '../../components/PermissionMask';

export default (props) => {
  const { history } = props;

  if (!checkPermissionV2('viewAllPosDevices')) return null;

  return (
    <div>
      <Taber
        currentTab={1}
        tabs={[
          {
            name: translate.shop_deselected,
            onClick: () => {
              history.push('/shops');
            },
          },
          {
            name: translate.all_pos_devices,
          },
        ]}
      />
      <ListView {...props} />
    </div>
  );
};
