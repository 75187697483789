import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '../../../components/IconRender';
import moment from 'moment';
import { translate } from '../../../shared/translate';
import OverlayLoading from '../../../components/OverlayLoading';

const CustomToolBar = ({
  viewOptions = [
    { label: translate.repeat_weekly, value: 'week' },
    { label: translate.repeat_monthly, value: 'month' },
  ],
  loading,
  label,
  view,
  onView,
  onNavigate,
}) => {
  const disabled = loading;
  const [currentView, setCurrentView] = useState(view);

  const handleViewChange = (newView) => {
    setCurrentView(newView);
    onView(newView);
  };

  const handleNavigate = (action) => {
    onNavigate(action);
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <Grid container justifyContent={'flex-end'} spacing={1}>
        {viewOptions?.map((opt, i) => (
          <Grid item key={i}>
            <ToggleButton
              disabled={disabled}
              className={currentView === opt?.value ? 'active' : ''}
              active={view === opt?.value}
              onClick={() => handleViewChange(opt?.value)}
            >
              {opt?.label}
            </ToggleButton>
          </Grid>
        ))}
      </Grid>
      <div style={{ height: 16 }} />
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item>
          <IconButton disabled={disabled} onClick={() => handleNavigate('PREV')}>
            <Icon icon={'faChevronLeft'} />
          </IconButton>
        </Grid>
        <Grid item>
          <Button disabled={disabled} variant={'text'} onClick={() => handleNavigate('TODAY')}>
            {
              {
                month: (
                  <Typography className="rbc-toolbar-label" variant={'h5'}>
                    {translate[moment(label, 'MMM YYYY').format('MMMM')?.toLowerCase()]}
                    {moment(label, 'MMM YYYY').format('YYYY')}
                  </Typography>
                ),
                week: (
                  <Typography className="rbc-toolbar-label" variant={'h5'}>
                    {label}
                  </Typography>
                ),
                agenda: (
                  <Typography className="rbc-toolbar-label" variant={'h5'}>
                    {label}
                  </Typography>
                ),
              }[view]
            }
            {loading && <OverlayLoading />}
          </Button>
        </Grid>
        <Grid item>
          <IconButton disabled={disabled} onClick={() => handleNavigate('NEXT')}>
            <Icon icon={'faChevronRight'} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomToolBar;

const ToggleButton = ({ disabled, active, children, onClick }) => {
  return (
    <Button
      disabled={disabled}
      size={'small'}
      color={active ? 'secondary' : undefined}
      variant={active ? 'contained' : 'outlined'}
      onClick={onClick}
      style={{ position: 'relative', zIndex: 1, minWidth: 100 }}
    >
      {children}
    </Button>
  );
};
