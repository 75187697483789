import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../components/FormPageMaker/Fields/SwitchField';

const ENABLE_QUERY = gql`
  mutation ($id: ID!) {
    paymentMethodEnable(id: $id) {
      id
      enabled
    }
  }
`;
const DISABLE_QUERY = gql`
  mutation ($id: ID!) {
    paymentMethodDisable(id: $id) {
      id
      enabled
    }
  }
`;

export default ({ id, disabled, enabled, onCompleted }) => {
  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(enabled);
  return (
    <SwitchField
      checked={isEnabled}
      value={isEnabled}
      disabled={disabled || loading}
      onChange={async (e) => {
        try {
          setIsEnabled(!enabled);
          setLoading(true);
          await client.mutate({
            mutation: enabled ? DISABLE_QUERY : ENABLE_QUERY,
            variables: {
              id,
            },
          });
          onCompleted();
        } catch (e) {
          toast.error(errorParser(e));
          setIsEnabled(enabled);
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
