import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from 'shared/translate';
import ActiveSwitcher from './ActiveSwitcher';
import MenuActivate from './MenuActivate';
import MenuDeactivate from './MenuDeactivate';
import MenuDelete from './MenuDelete';

class ListCashVoucher extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/cash_vouchers/new',
    selectionMode: true,
    getRowLink: ({ id, name }) => ({
      pathname: `/cash_vouchers/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.image,
        width: 50,
        fieldName: 'thumbnail',
        type: 'media',
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
        filter: 'name',
        filterType: 'text',
        filterChannel: 'q',
      },
      {
        width: 50,
        title: translate.price,
        fieldName: 'unitPrice',
        type: 'price',
        sortBy: 'unitPrice',
        filter: 'unitPrice',
        filterType: 'number',
        filterChannel: 'q',
      },
      {
        title: translate.visibility,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} data={data} active={active} onCompleted={this.refetch} />;
        },
        sortBy: 'active',
        noLink: true,
      },
    ],
    qFields: ['name'],
    menus: [MenuActivate, MenuDeactivate, MenuDelete],
    hasQSearch: true,
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }

  getData = ({ node } = {}) => {
    const { cashVouchers } = node || {};
    return cashVouchers;
  };
}

export default ListCashVoucher;
