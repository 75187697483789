import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { parseConnection, parseOSSQuery } from '../../shared';
import _ from 'lodash';
import SelectCombo from './index';
import { translate } from '../../shared/translate';

export const SEARCH_QUERY = gql`
  query ($cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: UserShopsFilterInput, $query: String) {
    me {
      ... on User {
        id
        shops(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          totalCount
          nextCursor
          nodes {
            id
            name
            createdAt
          }
        }
      }
    }
  }
`;

const SelectComboCompanyShop = ({ extraNodes = [], ...props }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const companyId = localStorage.getItem('companyId');

  const { loading, data, fetchMore } = useQuery(SEARCH_QUERY, {
      variables: {
        query: search
          ? `(companyId.keyword:${companyId}) AND (${parseOSSQuery(search, ['name'])})`
          : `companyId.keyword:${companyId}`,
        sortBy: [{ field: 'createdAt', order: 'ASC' }],
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);

  function getConnection(data) {
    const { me } = data || {};
    return parseConnection((me || {}).shops);
  }

  return (
    <SelectCombo
      onOpen={setOpen}
      onSearchChange={setSearch}
      getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
      getOptionLabel={(option) => option?.name ?? ''}
      options={extraNodes.concat(nodes).map((value) => _.omit(value, '__typename'))}
      defaultValue={{ name: translate.storewide_analysis || '全店分析' }}
      loading={loading}
      onScrollBottom={async () => {
        if (!!nextCursor && !loading)
          await fetchMore({
            variables: {
              cursor: nextCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              const fetchMoreConnection = getConnection(fetchMoreResult),
                { nodes: fetchMoreNodes } = fetchMoreConnection,
                { nodes: prevNodes } = getConnection(prevResult);
              fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

              return {
                node: {
                  ...(fetchMoreResult.node || {}),
                  shops: fetchMoreConnection,
                },
              };
            },
          });
      }}
      {...props}
    />
  );
};

export default SelectComboCompanyShop;
