import React from 'react';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { useHistory, useLocation } from 'react-router-dom';
import { OrderStatus, ShippingStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';

export default ({ values: { id } = {} }) => {
  const history = useHistory(),
    location = useLocation();
  return !!id && <OrderCard id={id} history={history} location={location} />;
};

class OrderCard extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: (item) => ({ pathname: `/orders/${item.id}`, state: { title: item.referenceNo } }),
    fields: [
      {
        title: translate.date,
        align: 'left',
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
      },
      {
        title: translate.order_number,
        width: 150,
        fieldName: 'referenceNo',
        sortBy: 'referenceNo',
      },
      {
        title: translate.order_amount,
        align: 'right',
        width: 120,
        fieldName: 'total',
        type: 'price',
        filter: 'total',
        sortBy: 'total',
      },
      {
        title: translate.refund,
        align: 'right',
        width: 100,
        fieldName: 'totalRefund',
        type: 'price',
      },
      {
        title: translate.order_status,
        align: 'center',
        width: 60,
        fieldName: 'status',
        type: 'option',
        options: Object.keys(OrderStatus).map((key) => ({
          label: <StatusBadge status={key} />,
          value: key,
        })),
        filter: 'status',
        sortBy: 'status',
      },
      {
        title: translate.payment_status,
        align: 'center',
        width: 60,
        fieldName: 'paymentStatus',
        type: 'option',
        options: Object.keys(OrderStatus).map((key) => ({
          label: <StatusBadge status={key} />,
          value: key,
        })),
        filter: 'paymentStatus',
        sortBy: 'paymentStatus',
      },
      {
        title: translate.shipment_status,
        align: 'center',
        width: 60,
        fieldName: 'shippingStatus',
        type: 'option',
        options: Object.keys(ShippingStatus).map((key) => ({
          id: ShippingStatus[key]?.label,
          label: <StatusBadge statusObject={ShippingStatus} status={key} />,
          value: key,
        })),
        filter: 'shippingStatus',
        sortBy: 'shippingStatus',
      },
    ],
    hasQSearch: true,
    limits: 10,
  };

  getFilter(...args) {
    const superFilter = super.getFilter(...args);

    return {
      ...superFilter,
      active: { operator: 'IN', value: ['true'] },
    };
  }

  getData = ({ node } = {}) => {
    const { orders } = node || {};
    return orders;
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }
}
