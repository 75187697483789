import React, { useState } from 'react';
import { Menu, MenuItem, useTheme } from '@material-ui/core';
import CheckboxField from '../FormPageMaker/Fields/CheckboxField';
import { translate } from '../../shared/translate';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { NumberFormat } from '../../shared';

const AllCheckControl = ({ loading, selectionData, onChange = (_) => _, nodes = [], totalCount }) => {
  const theme = useTheme();
  const displayCount = nodes.length;
  const { mode, data } = selectionData || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const checked = mode === 'EXCLUDE' && data?.filter((_) => _).length === 0;
  const indeterminate = !checked && data.length > 0;
  const selectedCount = (mode === 'INCLUDE' ? data.length : totalCount - data.length) || 0;

  if (loading)
    return (
      <Skeleton
        width={20}
        height={20}
        variant={'rect'}
        style={{
          marginTop: 10,
          marginBottom: 9,
        }}
      />
    );

  if (displayCount === totalCount) {
    return (
      <CheckboxField
        controlled={true}
        checked={checked}
        indeterminate={indeterminate}
        onChange={() => {
          if (checked) onChange({ mode: 'INCLUDE', data: [] });
          else onChange({ mode: 'EXCLUDE', data: [''] });
        }}
      />
    );
  } else {
    return (
      <>
        <CheckboxField
          controlled={true}
          checked={checked}
          indeterminate={indeterminate}
          onChange={(e) => {
            if (checked) {
              onChange({ mode: 'INCLUDE', data: [] });
            } else setAnchorEl(e.currentTarget);
          }}
        />
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem
            onClick={() => {
              onChange({ mode: 'EXCLUDE', data: [''] });
              setAnchorEl(null);
            }}
          >
            {translate.select_all_items} ({NumberFormat(totalCount)})
          </MenuItem>
          <MenuItem
            onClick={() => {
              onChange({ mode: 'INCLUDE', data: _.uniqBy(data.concat(nodes), 'id') });
              setAnchorEl(null);
            }}
          >
            {translate.select_page_items} ({NumberFormat(nodes.length)})
          </MenuItem>
          {selectedCount > 0 && (
            <MenuItem
              style={{ color: theme.palette.error.main }}
              onClick={() => {
                onChange({ mode: 'INCLUDE', data: [] });
                setAnchorEl(null);
              }}
            >
              {translate.unselect_all} ({NumberFormat(selectedCount)})
            </MenuItem>
          )}
        </Menu>
      </>
    );
  }
};

export default AllCheckControl;
