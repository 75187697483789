import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { forwardRef } from 'react';

const useBreakPoint = () => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isSM = useMediaQuery(theme.breakpoints.up('sm'));
  const isMD = useMediaQuery(theme.breakpoints.up('md'));
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  if (isXL) return 'xl';
  else if (isLG) return 'lg';
  else if (isMD) return 'md';
  else if (isSM) return 'sm';
  else if (isXS) return 'xs';
  else return undefined;
};
export default useBreakPoint;

export const withBreakPoint = (Comp) =>
  forwardRef((props, ref) => {
    const breakpoint = useBreakPoint();
    return <Comp {...props} breakpoint={breakpoint} ref={ref} />;
  });
