import React, { memo } from 'react';
import ControlRow from './ControlRow';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import RatioBox from 'components/RatioBox';

const LoadingSkeleton = () => {
  return (
    <ControlRow simple>
      <Box
        p={1}
        display={'flex'}
        flexDirection={'row'}
        style={{
          borderLeft: '1px dashed #ccc',
          borderRight: '1px dashed #ccc',
        }}
      >
        <Box p={1} flex={1}>
          <RatioBox width={'100%'}>
            <Skeleton variant={'rect'} width={'100%'} height={'100%'} />
          </RatioBox>
        </Box>
        <Box p={1} flex={1}>
          <RatioBox width={'100%'}>
            <Skeleton variant={'rect'} width={'100%'} height={'100%'} />
          </RatioBox>
        </Box>
        <Box p={1} flex={1}>
          <RatioBox width={'100%'}>
            <Skeleton variant={'rect'} width={'100%'} height={'100%'} />
          </RatioBox>
        </Box>
        <Box p={1} flex={1}>
          <RatioBox width={'100%'}>
            <Skeleton variant={'rect'} width={'100%'} height={'100%'} />
          </RatioBox>
        </Box>
      </Box>
    </ControlRow>
  );
};

export default memo(LoadingSkeleton, () => true);
