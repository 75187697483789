import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Verify from './Verify';
import PrintOrder from './PrintOrder';
import PrintOrders from './PrintOrders';
import PrintDeliveryNote from './PrintDeliveryNote';
import Homepage from './Homepage';
import PrintTransfer from './PrintTransfer';
import PrintPackingList from './PrintPackingList';
import PrintReceivePurchase from './PrintReceivePurchase';
import PrintStockOut from './PrintStockOut';
import Initialize from './Initialize';

import Test from './Test';
import PrintSummaryPOS from './PrintSummaryPOS';
import Tailwind from './Tailwind';
import PrintProductBarcodes from './PrintProductBarcodes';
import PDFViewer from './print/external/sf_express';

export default () => (
  <Router>
    <Switch>
      <Route path={'/login'} component={Login} />
      <Route path={'/test'} component={Test} />
      <Route path={'/verify'} component={Verify} />
      <Route path={'/print_packing_list'} component={PrintPackingList} />
      <Route path={'/print_order/:id?'} component={PrintOrder} />
      <Route path={'/print_orders'} component={PrintOrders} />
      <Route path={'/print_product_barcodes'} component={PrintProductBarcodes} />
      <Route path={'/print/summaryPOS'} component={PrintSummaryPOS} />
      <Route path={'/print_delivery_notes/:id?'} component={PrintDeliveryNote} />
      <Route path={'/print_transfers/:id?'} component={PrintTransfer} />
      <Route path={'/print_receive_purchase/:id?'} component={PrintReceivePurchase} />
      <Route path={'/print_stock_out/:id?'} component={PrintStockOut} />
      <Route path={'/print/external'} component={PDFViewer} />
      <Route path={'/tailwind-test'} component={Tailwind} />
      <Route path={'/initialize'} component={Initialize} />
      <Route path={'/'} component={Homepage} />
    </Switch>
  </Router>
);
