import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class QueryParamsWrapper extends Component {
  getQueryParams = () => {
    const { location: { search = '' } = {} } = this.props;
    return queryString2Object(search);
  };

  getQueryParam = (key) => {
    return this.getQueryParams()[key];
  };

  patchQueryParams = (nextParams, replace = false) => {
    const { history, location: { pathname = '', search = '' } = {} } = this.props;
    const params = queryString2Object(search);
    if (typeof nextParams === 'function') {
      nextParams = nextParams(params);
    } else {
      nextParams = { ...params, ...nextParams };
    }
    const _search = object2QueryString(_.omitBy(nextParams, (v) => _.isNull(v) || _.isUndefined(v)));
    if (_search !== search) {
      if (history) {
        if (replace) {
          history.replace({
            pathname,
            search: _search,
          });
        } else {
          history.push({
            pathname,
            search: _search,
          });
        }
      }
    }
  };

  render() {
    const { children } = this.props;
    return children;
  }
}
QueryParamsWrapper.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
};

export function queryString2Object(search) {
  try {
    return JSON.parse(
      '{"' +
        decodeURIComponent(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
        '"}',
    );
  } catch (e) {
    return {};
  }
}

export function object2QueryString(obj) {
  try {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return '?' + str.join('&');
  } catch (e) {
    return '?';
  }
}
