import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';

export const BLOCK_CUSTOMER = gql`
  mutation ($id: ID!, $shopId: ID!) {
    customerBlock(id: $id, shopId: $shopId) {
      id
      blocked
    }
  }
`;

export const UNBLOCK_CUSTOMER = gql`
  mutation ($id: ID!, $shopId: ID!) {
    customerUnblock(id: $id, shopId: $shopId) {
      id
      blocked
    }
  }
`;

export default ({ id, blocked, data = {} }) => {
  const [loading, setLoading] = useState(false);
  return (
    <SwitchField
      checked={!blocked}
      value={blocked}
      disabled={loading}
      onChange={async (e) => {
        try {
          setLoading(true);
          await client.mutate({
            mutation: blocked ? UNBLOCK_CUSTOMER : BLOCK_CUSTOMER,
            variables: {
              id: id || undefined,
              shopId: localStorage.getItem('shopId'),
            },
          });
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
