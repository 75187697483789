import { gql } from '@apollo/client';

const FRAGMENT_CASH_VOUCHER = gql`
  fragment FRAGMENT_CASH_VOUCHER on CompanyCashVoucher {
    createdAt
    updatedAt
    name
    description
    unitPrice
    numberOfVoucher
    singleVoucherValue
    active
    thumbnail {
      src
      optimizedSrc(width: 256, height: 256)
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_CASH_VOUCHER}
  query ($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_CASH_VOUCHER
    }
  }
`;

export const SUBMIT_QUERY = gql`
  ${FRAGMENT_CASH_VOUCHER}
  mutation ($id: ID, $input: CashVoucherSetInput!) {
    result: cashVoucherSet(id: $id, input: $input) {
      id
      ...FRAGMENT_CASH_VOUCHER
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    cashVoucherUnset(id: $id) {
      id
    }
  }
`;
