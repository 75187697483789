import { gql } from '@apollo/client';

export const REMOVE_QUERY = gql`
  mutation orderCancel($id: ID!, $input: OrderCancelInput!) {
    orderCancel(id: $id, input: $input) {
      id
      status
      cancelReason
    }
  }
`;
