import { translate, translateFormat } from './translate';
import { escapeRegExp } from './index';

export default (e = '', variable) => {
  let msg = (e.message === undefined ? e : e.message) || '';

  if (!msg) return '';

  msg = msg.replace(/^GraphQL error:/g, '').trim();
  msg = msg.replace(/Error:/g, '').trim();

  const errors = translate['errors'] || {};
  const errorKey = Object.keys(errors).find((key) => {
    if (key === msg) return true;
    else if (new RegExp(escapeRegExp(key)).test(msg)) return true;
    return false;
  });
  return !!errorKey ? translateFormat(errors[errorKey], { variable }) : msg;
};
