import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import {
  GET_QUERY,
  EXPORT_QUERY,
  GET_SOLD_REPORT,
  GET_SHOPS,
  GET_WAREHOUSES_FROM_SHOP,
  GET_SOLD_REPORT_DUMMY,
} from './query';
import { cloneObject, infinityFetch, JSONParseSafely, parseConnection } from '../../../shared';
import ActivityIndicator from '../../../components/ActivityIndicator';
import _ from 'lodash';
import EllipsisTypography from '../../../components/EllipsisTypography';
import { client } from '../../../shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { Query } from '@apollo/client/react/components';
import RefreshButton from '../../../components/RefreshButton';
import WarehouseSelector from './WarehouseSelector';
import SoldSelector, { soldOptions } from './SoldSelector';
import { Box } from '@material-ui/core';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    hasQSearch: true,
    refreshable: false,
    fields: [
      {
        appendSoldColumnsStart: true,
        title: translate.product,
        sortBy: 'sku.keyword',
        type: 'text',
        filter: 'sku',
        render: (row) => {
          return (
            <>
              {row?.sku && <EllipsisTypography variant={'caption'}>{row?.sku}</EllipsisTypography>}
              {row?.productName && <EllipsisTypography>{row?.productName}</EllipsisTypography>}
              {row?.combination && <EllipsisTypography variant={'body2'}>{row?.combination}</EllipsisTypography>}
            </>
          );
        },
      },
      {
        appendSoldColumnsEnd: true,
        appendWarehousesColumnsStart: true,
        title: translate.total,
        width: 80,
        align: 'center',
        divider: true,
        type: 'number',
        value: (row) => {
          return _.sumBy(row?.stocks, 'quantity');
        },
      },
    ],
    qFields: ['sku.keyword', 'name'],
    menus: [
      {
        name: translate.export,
        onClick: async function () {
          try {
            ActivityIndicator.show();
            let shops = JSONParseSafely(this.getQueryParam('shops'), []);

            await client.mutate({
              mutation: EXPORT_QUERY,
              variables: {
                shopId: localStorage.getItem('shopId'),
                exportShopIds: shops.map((shop) => shop?.id).filter(Boolean),
              },
            });
            toast.success(translate.export_tips);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            ActivityIndicator.hide();
          }
        },
      },
    ],
    selectionMode: false,
    skip: true,
    warehouses: [],
    selectedWarehouses: [],
    soldPreference: {
      days60: false,
      days30: false,
      days7: false,
      today: false,
    },
  };

  getData = (data) => data?.node?.report?.inventory;

  getExtraFetchVariables() {
    const { selectedWarehouses } = this.state;
    return {
      companyId: localStorage.getItem('companyId'),
      shopId: localStorage.getItem('shopId'),
      warehouseIds: _.map(selectedWarehouses, 'id'),
    };
  }

  async infinityFetchWarehouses() {
    ActivityIndicator.show(translate.loading);
    let shops = JSONParseSafely(this.getQueryParam('shops'), []);
    if (!shops?.length) {
      const companyId = localStorage.getItem('companyId');
      shops =
        (
          await infinityFetch({
            query: GET_SHOPS,
            variables: {
              query: `companyId.keyword:${companyId}`,
            },
            getConnection: (data) => data?.me?.shops,
          })
        )?.nodes ?? [];
    }
    const warehouses = [];

    for (const shop of shops) {
      const { nodes } = await infinityFetch({
        fetchPolicy: 'network-only',
        query: GET_WAREHOUSES_FROM_SHOP,
        variables: {
          id: shop.id,
          filter: {
            active: {
              operator: 'IN',
              value: ['true', '1'],
            },
          },
          sortBy: [
            {
              field: 'sortIndex',
              order: 'ASC',
            },
          ],
        },
        getConnection: (data) => data?.node?.availableWarehouses,
        total: 1,
      });
      warehouses.push(...nodes.filter((node) => node?.active));
      ActivityIndicator.show(`${translate.fetching_warehouses} [${warehouses.length}]`);
    }

    ActivityIndicator.hide();
    return _.uniqBy(warehouses, 'id');
  }

  async componentDidMount() {
    const { fields } = this.state;
    const warehouses = await this.infinityFetchWarehouses();
    this.setState({
      skip: false,
      warehouses,
      selectedWarehouses: cloneObject(warehouses),
      fields: [...fields, ...this.getWarehouseColumns(warehouses)].filter((_) => _),
    });
  }

  renderWrapper({ loading, data = {}, ...args }) {
    const { soldPreference } = this.state;
    const skus = parseConnection(data?.node?.report?.inventory).nodes.map(({ sku }) => sku) || [];
    const { skip } = this.state;

    const activeSoldOptions = soldOptions.filter((opt) => soldPreference[opt.value]);
    return (
      <Query
        query={activeSoldOptions?.length ? GET_SOLD_REPORT(activeSoldOptions) : GET_SOLD_REPORT_DUMMY}
        errorPolicy={'all'}
        fetchPolicy={'cache-and-network'}
        variables={{
          query: `sku.keyword: (${skus?.map((v) => `"${v}"`)?.join(' ')})`,
          shopId: localStorage.getItem('shopId'),
          ...this.getExtraFetchVariables(),
        }}
        skip={loading || !activeSoldOptions?.length}
      >
        {({ loading: loading2, data: data2 }) => {
          const soldReport = data2?.node?.report || {};
          const soldReport2Obj = Object?.keys(soldReport)?.reduce(
            (reducer, label) =>
              parseConnection(soldReport?.[label]).nodes.reduce(
                (reducer, { sku, count }) => ({ ...reducer, [sku]: { ...reducer?.[sku], [label]: count } }),
                reducer,
              ),
            {},
          );
          const inventoryConnection = parseConnection(data?.node?.report?.inventory);
          const inventoryReport =
            inventoryConnection.nodes.map(({ sku, ...value }) => ({
              sku,
              ...value,
              ...soldReport2Obj?.[sku],
            })) || [];
          return (
            <div>
              {this.renderContent({
                ...args,
                loading: loading2 || loading || skip,
                data: { node: { report: { inventory: { ...inventoryConnection, nodes: inventoryReport } } } },
              })}
            </div>
          );
        }}
      </Query>
    );
  }

  renderFilter() {
    const { fields, soldPreference } = this.state;

    return (
      <Box mr={1}>
        <SoldSelector
          value={soldPreference}
          onChange={(soldPreference) => {
            const newFields = fields.concat();
            const startIndex = newFields.findIndex((fi) => fi.appendSoldColumnsStart) + 1;
            const endIndex = newFields.findIndex((fi) => fi.appendSoldColumnsEnd);
            newFields.splice(
              startIndex,
              endIndex - startIndex,
              ...soldOptions
                .map((item) =>
                  soldPreference[item?.value]
                    ? {
                        title: item?.label,
                        type: 'number',
                        align: 'center',
                        fieldName: item?.value,
                      }
                    : undefined,
                )
                .filter(Boolean),
            );

            this.setState({
              soldPreference,
              fields: newFields,
            });
          }}
        />
      </Box>
    );
  }

  renderExtras({ loading, data }) {
    const { warehouses, selectedWarehouses, fields } = this.state;
    return (
      <>
        <RefreshButton
          loading={loading}
          onClick={() => {
            this.refetch && this.refetch();
          }}
        />
        <WarehouseSelector
          warehouses={warehouses}
          value={selectedWarehouses}
          onChange={(selectedWarehouses) => {
            const newFields = fields.concat();
            const index = newFields.findIndex((fi) => fi.appendWarehousesColumnsStart) + 1;
            newFields.splice(index, newFields.length - index);

            this.setState({
              selectedWarehouses,
              fields: newFields.concat(this.getWarehouseColumns(selectedWarehouses)),
            });
          }}
        />
      </>
    );
  }

  getWarehouseColumns(warehouses) {
    return warehouses
      .map((warehouse) => ({
        title: warehouse?.name,
        width: 80,
        align: 'center',
        divider: true,
        type: 'number',
        value: (row) => row?.stocks?.find((stock) => stock?.warehouseId === warehouse?.id)?.quantity,
      }))
      .filter(Boolean);
  }
}
