import { gql } from '@apollo/client';

export const FRAGMENT_SHOPTABLE = gql`
  fragment ShopTable on ShopTable {
    name
    group
    status
    createdAt
    updatedAt
    sortIndex
    metadata {
      key
      value
    }
    shop {
      metadata {
        key
        value
      }
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOPTABLE}
  query ($id: ID!) {
    node(id: $id) {
      id
      ...ShopTable
    }
  }
`;

export const SUBMIT_QUERY = gql`
  ${FRAGMENT_SHOPTABLE}
  mutation ($id: ID!, $input: TableUpdateInput!) {
    result: tableUpdate(id: $id, input: $input) {
      id
      ...ShopTable
    }
  }
`;
