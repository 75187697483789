import { useEffect, useRef, useState } from 'react';
import { getRandomString, JSONParseSafely } from '../shared';
import { getBedrockImages } from './useBedrockImages';
const { REACT_APP_WEBHOOK_URL, REACT_APP_BEDROCK_MODEL_ID = 'anthropic.claude-3-5-sonnet-20240620-v1:0' } = process.env;

const useBedrockProducts = () => {
  const handler = useRef({ process: {} });
  const [output, setOutput] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const [snippet = ''] = /^([\S\s]*)},?/.exec(output) ?? [];
    const items = JSONParseSafely(snippet.replace(/,$/, '') + ']', []);
    if (items.length > 0) {
      setItems((prevItems) => {
        if (prevItems.length < items.length) {
          return items.map((item, i) => {
            const prevItem = prevItems[i] ?? {};
            return { _id: getRandomString(32), images: [], ...item, ...prevItem };
          });
        }
        return prevItems;
      });
    }
  }, [output]);

  useEffect(() => {
    items.forEach((item) => {
      if (!handler.current.process[item?._id]) {
        handler.current.process[item?._id] = true;
        const imagePrompts = item?.imagePromptsInEnglish ??
          item?.imagePrompts ?? [[item?.name, ...item?.hashtags].filter(Boolean).join('\n')];

        getBedrockImages({
          count: process.env.NODE_ENV === 'development' ? 2 : 4,
          inputs: imagePrompts,
        })
          .then((urls) => {
            setItems((items) =>
              items.map((_item) => {
                if (_item?._id === item?._id) return { ..._item, images: urls };
                return _item;
              }),
            );
          })
          .catch((e) => {});
      }
    });
  }, [items]);

  return [
    { items, loading, error },
    async ({
      count = process.env.NODE_ENV === 'development' ? 2 : 3,
      input = 'Test Product 1',
      language = '繁體',
      getPrompt = ({ language, input, count }) => {
        return `
[Personality]
You are a marketing manager

[Format]
json

[Example]
[{
name: "product name",
description:"product description",
hashtags: ["tag1", "tag2", "tag3", "tag4", "tag5", "tag6", "tag7", "tag8", "tag9", "tag10"],
imagePromptsInEnglish: ["image prompt 1", "image prompt 2", "image prompt 3", "image prompt 4"],
}]

[Overall Rules to follow]
output json data only
base on [Description], find out the key product name, if cannot, suggest one
write a description of [Description] in 150 characters
generate 10 hashtags base on [Description]
imageDescription * 4, 80 characters each
imagePromptsInEnglish * 4
image prompt 1 = imageDescription + 45 degree top down view + imageSpec
image prompt 2 = imageDescription + top down view + imageSpec
image prompt 3 = imageDescription + on table + imageSpec
image prompt 4 = imageDescription + shop view + imageSpec

[Language]
name: ${language}
description: ${language}
hashtags: english
imageDescription: english
imagePromptsInEnglish: english

[imageSpec]
Product photo, No text, No cartoon, No poster, Photography, Shot on 70mm lens, Depth of Field, Bokeh, DOF, Tilt Blur, Shutter Speed 1/1000, F/22, White Balance, 32k, Super-Resolution, Lighting, Background

[Description]
${input}

Use [Description] to generate ${count} examples`;
      },
    } = {}) => {
      try {
        /* Reset */
        handler.current.process = {};
        setLoading(true);
        setError(undefined);
        setOutput('');
        setItems([]);

        /* Fetch stream URL */
        const streamUrl = await (async () => {
          const res = await fetch(`${REACT_APP_WEBHOOK_URL}/bedrock/converse-stream/url`);
          return (await res.json())?.url;
        })();

        const token = localStorage.getItem('token');
        const res = await fetch(streamUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            modelId: REACT_APP_BEDROCK_MODEL_ID,
            messages: [
              {
                role: 'user',
                content: [
                  {
                    type: 'text',
                    text: getPrompt({ language, input, count }),
                  },
                ],
              },
            ],
          }),
        });

        if (!/^20/.test(res.status.toString())) throw new Error(res.statusText);

        /* Read stream */
        const reader = res.body.getReader();
        let end = false;

        while (!end) {
          const { value, done } = await reader.read();
          if (done) {
            end = true;
          } else {
            const chunk = new TextDecoder().decode(value);
            const modifiedChunk = '[' + chunk.replace(/}\s*{/g, '},{') + ']';
            const parsedArray = JSON.parse(modifiedChunk);
            const snippet = parsedArray
              .map((item) => {
                if (item?.type === 'content_block_delta') return item?.delta?.text;
                return '';
              })
              .filter(Boolean)
              .join('');

            setOutput((output) => output + snippet);
          }
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
  ];
};

export default useBedrockProducts;
