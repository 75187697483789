import React from 'react';
import FormPageMaker from 'components/FormPageMaker';
import { GET_QUERY, REMOVE_QUERY, SUBMIT_QUERY } from './query';
import { translate } from '../../shared/translate';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import LSkeleton from '../../components/LSkeleton';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { client } from '../../shared/apollo';
import { toInputMedia } from '../../shared';

class ItemCashVoucher extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.cash_voucher || '現金禮券',
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                name: 'name',
                maxLength: 50,
                required: true,
                md: 6,
              },
              {
                label: translate.image,
                type: 'media',
                name: 'thumbnail',
              },
              {
                label: translate.description,
                type: 'html',
                name: 'description',
              },
              {
                label: translate.price,
                name: 'unitPrice',
                type: 'number',
                required: true,
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.voucher_package_setting,
                render: ({ values, setFieldValue, isSubmitting, loading }) => {
                  if (loading) return <LSkeleton />;
                  return (
                    <Grid container spacing={1} alignItems={'center'}>
                      <Grid item>
                        <Typography>{translate.include_number_of_voucher}</Typography>
                      </Grid>
                      <Grid item>
                        <InputField
                          type={'number'}
                          // eslint-disable-next-line
                          inputProps={{
                            step: 1,
                            min: 1,
                          }}
                          required={true}
                          disabled={isSubmitting}
                          value={values.numberOfVoucher}
                          onChange={(e) => setFieldValue('numberOfVoucher', e.target.value)}
                          // eslint-disable-next-line
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={'end'}>
                                {translate.formatString(translate.n_pieces, '')}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>{translate.each_valued_at}</Typography>
                      </Grid>
                      <Grid item>
                        <InputField
                          type={'number'}
                          // eslint-disable-next-line
                          inputProps={{
                            step: 0.01,
                            min: 1,
                          }}
                          required={true}
                          disabled={isSubmitting}
                          value={values.singleVoucherValue}
                          onChange={(e) => setFieldValue('singleVoucherValue', e.target.value)}
                          // eslint-disable-next-line
                          InputProps={{
                            startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id: values.id,
        input: {
          companyId: localStorage.getItem('companyId'),
          name: values.name,
          description: values.description || '',
          unitPrice: values.unitPrice,
          numberOfVoucher: values.numberOfVoucher,
          singleVoucherValue: values.singleVoucherValue,
          active: values.active,
          thumbnail: toInputMedia(values.thumbnail),
        },
      },
    });
    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {},
      {
        id,
        updatedAt,
        name,
        description,
        unitPrice,
        media,
        numberOfVoucher = 1,
        singleVoucherValue = 10,
        active = true,
        thumbnail,
      } = node || {};
    return {
      id,
      updatedAt,
      name,
      description,
      unitPrice,
      media,
      numberOfVoucher,
      singleVoucherValue,
      active,
      thumbnail,
    };
  };
}

export default ItemCashVoucher;
