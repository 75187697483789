import React from 'react';
import { Grid, Box } from '@material-ui/core';
import StatusCard from './StatusCard';
import { useMeasure } from 'react-use';
import DoubleAddressCard from './DoubleAddressCard';

export default (props) => {
  const [ref, { height }] = useMeasure();

  return (
    <Box mb={3}>
      <Grid container spacing={2}>
        <Grid item lg={12} xl={6}>
          <Box style={{ minHeight: height }}>
            <StatusCard {...props} minHeight={height} />
          </Box>
        </Grid>
        <Grid item lg={12} xl={6} style={{ width: '100%' }}>
          <Box ref={ref}>
            <DoubleAddressCard {...props} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
