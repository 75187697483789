import React from 'react';
import ImportModal from './ImportModal';

const MenuImport = {
  // name: translate.import,
  // onClick: () => this.upload.click(),
  render: function () {
    return (
      <ImportModal
        onClick={() => {
          this.setState({ menuAnchorEl: undefined });
        }}
      />
    );
  },
};

export default MenuImport;
