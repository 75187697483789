import React from 'react';
import { Box, Grid } from '@material-ui/core';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import HashTagsArrayField from '../../FormPageMaker/Fields/HashTagsArrayField';
import SettingCommon from './SettingCommon';
import { sleep } from '../../../shared';

const SettingRADIO = (props) => {
  const { disabled, value, onChange = (_) => _ } = props;
  const { values = [] } = value ?? {};
  return (
    <>
      <SettingCommon {...props} />
      <Box p={2} mt={2} bgcolor={'#F5F5F5'}>
        <Grid container>
          <FormRow title={translate.options}>
            <HashTagsArrayField
              disabled={disabled}
              value={values}
              maxLength={250}
              onChange={async (v) => {
                await sleep();
                onChange({
                  ...value,
                  values: [...v],
                });
              }}
            />
          </FormRow>
        </Grid>
      </Box>
    </>
  );
};

export default SettingRADIO;
