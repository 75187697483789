import React from 'react';
import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { GET_MODIFER_PRODUCTS } from '../query';
import { translate } from '../../../shared/translate';
import { Box, Typography } from '@material-ui/core';
import { PriceFormat, sleep } from '../../../shared';
import RemoveFromModifierButton from './RemoveFromModifierButton';
import AddModifierProductsButton from './AddModifierProductsButton';

export default ({ loading, values: { id } = {} }) => {
  return !!id && <ProductsListView id={id} loading={loading} />;
};

class ProductsListView extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_MODIFER_PRODUCTS,
    },
    fields: [
      {
        title: translate.product_sku,
        fieldName: 'sku',
        width: 150,
        sortBy: 'sku.keyword',
      },
      {
        title: translate.image,
        fieldName: 'medias',
        type: 'media',
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: translate.shelf_time,
        fieldName: ({ publishAt }) => (publishAt ? 'publishAt' : 'createdAt'),
        type: 'datetime',
        sortBy: 'publishAt',
      },
      {
        title: translate.off_shelf_time,
        value: ({ publishThru }) => publishThru || translate.good_til_cancel,
        type: ({ publishThru }) => (!!publishThru ? 'datetime' : undefined),
        sortBy: 'publishThru',
      },
      {
        title: translate.price,
        render: ({ basePrice, maxPrice }) => {
          if (basePrice !== maxPrice)
            return <Typography>{`${PriceFormat(basePrice)} - ${PriceFormat(maxPrice)}`}</Typography>;
          return <Typography>{PriceFormat(basePrice)}</Typography>;
        },
        type: 'price',
        sortBy: 'basePrice',
      },
      {
        title: translate.quantity,
        align: 'right',
        render: ({ variations: _variations }) => {
          const variations = _variations || [];
          const { quantity, ignoreStock } = variations.reduce(
            (reducer, variation) => {
              const { quantity, ignoreStock } = variation || {};
              return {
                quantity: reducer.quantity + (+quantity || 0),
                ignoreStock: !!ignoreStock || reducer.ignoreStock,
              };
            },
            { quantity: 0, ignoreStock: false },
          );
          const isInfiniteStock = !!ignoreStock,
            hasMultipleVariations = variations.length > 1;
          return <Typography>{`${isInfiniteStock ? '∞' : quantity}${hasMultipleVariations ? '*' : ''}`}</Typography>;
        },
      },
    ],
    selectionMode: true,
    hasQSearch: true,
    qFields: ['sku', 'name', 'subtitle', 'collectionPaths', 'variationSkus', 'variationBarcodes'],
    limits: 10,
  };

  renderContent({ loading, ...args }) {
    const { loading: _loading } = this.props;
    return super.renderContent({ ...args, loading: loading || _loading });
  }
  renderExtras() {
    const { id, loading } = this.props;
    const { selectionData } = this.state;
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <AddModifierProductsButton disabled={loading} currModifierId={id} list={this} />
        <RemoveFromModifierButton
          currModifierId={id}
          list={this}
          disabled={selectionData?.mode === 'INCLUDE' && !selectionData?.data?.length}
        />
      </Box>
    );
  }

  getData = ({ node } = {}) => {
    const { products } = node || {};
    return products;
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }

  getQueryParams = () => {
    const { queryParams } = this.state;
    return queryParams ?? {};
  };
  getQueryParam = (key) => {
    const { queryParams } = this.state;
    return queryParams?.[key];
  };
  patchQueryParams = (nextParams) => {
    const { queryParams } = this.state;

    if (typeof nextParams === 'function') {
      nextParams = nextParams(queryParams);
    }
    this.setState({
      queryParams: {
        ...queryParams,
        ...nextParams,
      },
    });
    sleep(0).then(() => {
      this.setState(this.getQueryState());
    });
  };
}
