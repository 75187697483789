import { gql } from '@apollo/client';

export const FRAGMENT_shopProductModifier = gql`
  fragment FRAGMENT_shopProductModifier on ShopProductModifier {
    id
    updatedAt
    createdAt
    active
    name
    productCount
    sortIndex
    code
    modifiers {
      name
      min
      max
      options {
        name
        priceAdjustment
      }
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: ShopProductModifierFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        productModifiers(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_shopProductModifier
          }
        }
      }
    }
  }
  ${FRAGMENT_shopProductModifier}
`;
