import React from 'react';
import HashTagsArrayField from './HashTagsArrayField';
import toast from '../../../shared/toast';
import { translate } from '../../../shared/translate';
import { StyledTooltip } from './InputField';

export default ({
  loading,
  value = '',
  separator = ' ',
  onChange = (_) => _,
  maxLength,
  placeholder,
  disabled,
  ...props
}) => {
  const options = value ? value.split(separator).filter((_) => _) : [];

  const core = (
    <HashTagsArrayField
      loading={loading}
      value={options}
      onChange={(arr) => {
        const str = arr.join(separator);
        if (maxLength && str.length > maxLength) {
          toast.error(translate.meta_keywords_length_exceed);
          onChange(value);
        } else onChange(str);
      }}
      disabled={disabled}
      placeholder={disabled ? ' ' : placeholder}
      {...props}
    />
  );

  if (!maxLength) return core;
  return (
    <StyledTooltip placement={'bottom-end'} title={`${translate.length_count}: ${(value || '').length}/${maxLength}`}>
      <span>{core}</span>
    </StyledTooltip>
  );
};
