import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Box, IconButton, TextField, makeStyles, withStyles } from '@material-ui/core';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& .Mui-disabled': {
        backgroundColor: '#D9D9D9',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0',
        borderLeft: 'none',
        borderRight: 'none',
      },
      '& fieldset': {
        borderColor: '#E0E0E0',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  customIconButton: {
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 5,
    paddingLeft: 8,
    border: '1px solid #E0E0E0',
    borderRadius: 0,
  },
});

const QuantityModifier = ({ quantity, disabled, onChange }) => {
  const [count, setCount] = useState(quantity);
  const classes = useStyles();

  useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        className={classes.customIconButton}
        disabled={disabled}
        onClick={() => {
          setCount((prev) => prev - 1);
          onChange(count - 1);
        }}
      >
        <RemoveIcon />
      </IconButton>
      <CustomTextField
        type="number"
        size="small"
        variant="outlined"
        disabled={disabled}
        value={disabled ? '' : count}
        inputProps={{ style: { textAlign: 'center' } }}
        onChange={(e) => {
          const value = Number(e.target.value);
          if (isNaN(value)) return;
          setCount(value);
          onChange(value);
        }}
      />
      <IconButton
        className={classes.customIconButton}
        disabled={disabled}
        onClick={() => {
          setCount((prev) => prev + 1);
          onChange(count + 1);
        }}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default QuantityModifier;
