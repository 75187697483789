import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { getCommonColumns } from '../../../shared';
import ViewRecord from './ViewRecord';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id }) => ({ pathname: '/forms/' + id }),
    createUrl: '/forms/new',
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        filter: 'name',
        sortBy: 'name',
      },
      {
        title: translate.valid_start_date_time,
        fieldName: 'validAt',
        type: 'datetime',
        width: 180,
        filter: 'validAt',
        sortBy: 'validAt',
      },
      {
        title: translate.valid_end_date_time,
        fieldName: 'validThru',
        type: 'datetime',
        width: 180,
        filter: 'validThru',
        sortBy: 'validThru',
      },
      {
        title: translate.activation,
        fieldName: 'active',
        align: 'right',
        width: 50,
        type: 'bool',
      },
      ...getCommonColumns(),
      {
        width: 200,
        title: translate.view_history,
        render: (row) => <ViewRecord shopForm={row} />,
      },
    ],
    menus: [],
    selectionMode: false,
  };

  getData = ({ node } = {}) => {
    const { forms } = node || {};
    return forms;
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
