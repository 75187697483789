import { gql } from '@apollo/client';

export const FRAGMENT_PRODUCT = gql`
  fragment FRAGMENT_PRODUCT on ShopProduct {
    id
    name
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
    }
    active
    display
    createdAt
    updatedAt
    publishAt
    publishThru
    basePrice
    maxPrice
    sku
    variations {
      id
      sku
      unitPrice
      cost
      active
      ignoreStock
      quantity
    }
    collectionPaths
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $filter: ProductFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        products(query: $query, cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_PRODUCT
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`;
