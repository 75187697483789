import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { GET_QUERY, UPDATE_QUERY, CREATE_QUERY, REMOVE_QUERY } from './query';
import { convertMetaArray, convertMetaObject, parseAddress, toInputMedia } from '../../shared';
import SalesRecordsList from './SalesRecordsList/index';
import AttendanceRecordList from './AttendanceRecordList';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.employee_information || '員工資料',
        cards: [
          {
            fields: [
              {
                md: 6,
                label: translate.employee_name || '員工姓名',
                type: 'text',
                name: 'name',
                required: true,
              },
              {
                md: 6,
                label: translate.employee_position || '員工職位',
                type: 'select',
                name: 'role',
                required: true,
                options: [
                  { label: translate.manager, value: 'MANAGER' },
                  { label: translate.staff, value: 'STAFF' },
                ],
              },

              {
                md: 6,
                label: translate.employee_id || '員工編號',
                type: 'text',
                name: 'code',
                required: true,
              },
              {
                md: 6,
                label: translate.date_of_employment || '入職日期',
                type: 'datetime-local',
                name: 'startedAt',
                required: true,
              },

              {
                md: 6,
                label: translate.set_monthly_target || '設定每月目標',
                type: 'number',
                name: 'metadata.monthlyTarget',
              },
              { md: 6 },

              {
                md: 6,
                label: translate.phone_no || '電話號碼',
                type: 'mobile',
                name: 'phoneNumber',
                allCountries: false,
                required: true,
                hideCountries: true,
              },
              {
                md: 6,
                label: translate.login_password || '登入密碼',
                type: !!this.state.id ? 'password_update' : 'password',
                name: 'password',
                required: true,
                inputProps: {
                  pattern: '[0-9]{4,6}',
                },
                text: translate.employee_password_remark || '密碼必須是4至6個數字。',
                placeholder: !!this.state.id ? '******' : undefined,
              },
              {
                label: translate.content_address || '聯絡地址',
                md: 6,
                type: 'lines',
                name: 'address.lines',
              },
              {
                label: translate.profile_pic || '頭像',
                md: 6,
                type: 'media',
                name: 'thumbnail',
              },
            ],
          },
        ],
      },
      {
        display: !!this.state.id,
        name: translate.attendance_record,
        cards: [
          {
            render: () => {
              return <AttendanceRecordList {...this.state} {...this.props} />;
            },
          },
        ],
        noPadding: true,
      },
      {
        display: !!this.state.id,
        name: translate.sales_records,
        cards: [
          {
            render: () => {
              return <SalesRecordsList {...this.state} {...this.props} />;
            },
          },
        ],
        noPadding: true,
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;

    const input = {
      code: values.code,
      name: values.name,
      password: values.password,
      phoneNumber: values.phoneNumber,
      role: values.role,
      shopId: localStorage.getItem('shopId'),
      startedAt: values.startedAt,
      address: !!id ? { ...values.address, country: 'HKG' } : { ...values.address, country: 'HKG' },
      thumbnail: toInputMedia(values.thumbnail),
      metadata: convertMetaArray(values?.metadata),
    };

    if (id)
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input,
        },
      });
    else
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: { ...input, active: values.active },
        },
      });
    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {},
      { id, createdAt, updatedAt, active, address, code, name, phoneNumber, role, startedAt, thumbnail, metadata } =
        node || {};
    return {
      id,
      createdAt,
      updatedAt,
      active,
      address: parseAddress(address),
      code,
      name,
      phoneNumber,
      role,
      startedAt,
      thumbnail,
      metadata: convertMetaObject(metadata),
    };
  };
}
