import React from 'react';
import { Button, Typography, Box, withStyles } from '@material-ui/core';

export const wordCounts = [
  {
    label: '100',
    value: 100,
  },
  {
    label: '200',
    value: 200,
  },
  {
    label: '300',
    value: 300,
  },
];
const styles = (theme) => ({
  wordCountStepBox: {
    padding: '10px 20px',
    // height: '450px',
  },
  stepTitleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepTitle: {
    color: '#1F4C6D',
    fontSize: '18px',
    fontWeight: '500',
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    // margin: '70px 0px',
    minHeight: '270px',
  },
  wordCountBtn: {
    height: '135px',
    padding: '50px 100px',
    borderRadius: '10px',
    border: '1px solid #FFF',
    background: 'rgba(255, 255, 255, 0.40)',
    boxShadow: '2px 2px 2px 0px rgba(255, 255, 255, 0.40) inset, 2px 2px 7px 0px rgba(255, 255, 255, 0.40)',
  },
  wordCountBtnText: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#5B7D96',
  },
});

const ChatGptWordCountStep = ({ disabled, classes, onChange }) => {
  return (
    <Box className={classes.wordCountStepBox}>
      <Box className={classes.stepTitleBox}>
        <Typography className={classes.stepTitle}>請選擇字數</Typography>
      </Box>
      <Box className={classes.btnBox}>
        {wordCounts.map(({ label, value }, i) => {
          return (
            <Button
              key={i}
              className={classes.wordCountBtn}
              disabled={disabled}
              variant={'outlined'}
              type={'button'}
              onClick={() => {
                onChange(value);
              }}
            >
              <Typography className={classes.wordCountBtnText}>{label}</Typography>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ChatGptWordCountStep);
