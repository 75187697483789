import React, { useEffect, useState } from 'react';
import { GET_QUERY, GET_ORDER_QUERY } from './query';
import { Container } from '@material-ui/core';
import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { translate } from '../../shared/translate';
import PrintTable from '../../components/PrintTable';
import { infinityFetch, NumberFormat, PriceFormat } from '../../shared';
import moment from 'moment';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { client } from '../../shared/apollo';
import ActivityIndicator from '../../components/ActivityIndicator';

export default ({ history = {} }) => {
  const { search } = history.location ?? {};
  const {
      shopId,
      startedAt,
      startedThru: _startedThru,
    } = JSON.parse(
      '{"' + decodeURI(search.replace('?', '')).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    ),
    startedThru = moment(_startedThru).isAfter(moment()) ? moment().toISOString() : _startedThru;

  if (!shopId) return null;

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      ActivityIndicator.show(translate.loading);
      try {
        const { data } = await client.query({
          query: GET_QUERY,
          variables: {
            id: shopId,
            startedAt,
            startedThru,
          },
        });

        const { nodes } = await infinityFetch({
          query: GET_ORDER_QUERY,
          variables: {
            id: shopId,
            startedAt,
            startedThru,
            limits: 20,
            filter: { active: { operator: 'IN', value: ['true'] } },
          },
          getConnection: (data) => data?.node?.orders,
          onProgress: (downloaded, total) => {
            if (total > 0) ActivityIndicator.show(translate.loading + ` ${downloaded}/${total}`);
          },
        });

        setData({
          ...data,
          orders: nodes,
        });
      } catch (e) {
        toast.error(errorParser(e));
      } finally {
        setLoading(false);
        ActivityIndicator.hide();
      }
    })();
  }, []);

  const user = data?.me ?? {},
    node = data?.node ?? {},
    orders = data?.orders ?? [];

  const {
    volume = 0, //成交數
    turnover = 0, // 營業額
    otherDiscounts = [], //加一服務費
    refund = 0, //退款

    unpaidOrderAmount = 0, //未付金額

    salesByMethods = [], //付款方法

    salesByOrderItems = [], //產品銷售數據
  } = node.report ?? {};

  if (loading) return null;
  return (
    <>
      <style>{`html, body, #root { height: auto; }`}</style>
      <Container style={{ maxWidth: 1200 }}>
        <Card square>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <MyTitle>POS {translate.daily_report}</MyTitle>
              </Grid>
              {/* ----Shop ---- */}
              <Grid item xs={5}>
                <LVRow label={translate.operator} value={user?.name} />
                <LVRow label={translate.summary_date} value={moment(startedAt).format('YYYY-MM-DD HH:mm')} />
                <LVRow value={`${translate.to} ${moment(startedThru).format('YYYY-MM-DD HH:mm')}`} />
                <LVRow label={translate.print_time} value={moment().format('YYYY-MM-DD HH:mm')} />
                <LVRow label={translate.shop} value={node.title} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>
              {/* ---- Turnover ---- */}
              <Grid item>
                <MyTitle>{translate.turnover}</MyTitle>
              </Grid>

              <Grid item xs={5}>
                <LVRow label={translate.number_of_transactions} value={NumberFormat(volume)} />
                <LVRow label={translate.turnover} value={PriceFormat(turnover)} />
                {otherDiscounts?.map(({ name, amount }, i) => (
                  <LVRow key={i} label={name} value={PriceFormat(amount)} />
                ))}
                <LVRow label={translate.refund} value={PriceFormat(refund)} />
                <LVRow label={translate.unpaid} value={PriceFormat(unpaidOrderAmount)} />
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <MyTitle>{translate.payment_method}</MyTitle>
              </Grid>
              <Grid item xs={12}>
                <PrintTable
                  data={salesByMethods}
                  columns={[
                    {
                      title: translate.name,
                      value: ({ name }) => name,
                    },
                    {
                      title: translate.quantity,
                      width: 100,
                      value: ({ count }) => count ?? 0,
                    },
                    {
                      title: translate.amount,
                      align: 'right',
                      value: ({ amount }) => PriceFormat(amount),
                    },
                  ]}
                />
              </Grid>
              <Grid item>
                <MyTitle>{translate.product_sales_data}</MyTitle>
              </Grid>
              <Grid item xs={12}>
                <PrintTable
                  data={salesByOrderItems}
                  columns={[
                    {
                      title: translate.name,
                      value: ({ name }) => name,
                    },
                    {
                      title: translate.quantity,
                      width: 100,
                      value: ({ count }) => count ?? 0,
                    },
                    {
                      title: translate.amount,
                      align: 'right',
                      value: ({ amount }) => PriceFormat(amount),
                    },
                  ]}
                />
              </Grid>
              <Grid item>
                <MyTitle>{translate.order_data}</MyTitle>
              </Grid>
              <Grid item xs={12}>
                <PrintTable
                  data={orders}
                  columns={[
                    {
                      title: translate.datetime,
                      width: 180,
                      value: ({ createdAt }) => moment(createdAt).format('YYYY-MM-DD HH:mm'),
                    },
                    {
                      title: translate.order_number,
                      value: ({ referenceNo }) => referenceNo,
                    },
                    {
                      title: '',
                      value: ({ status }) =>
                        ({
                          PENDING: `【${translate.status_processing}】`,
                          PROCESSING: `【${translate.status_processing}】`,
                          CANCELLED: `【${translate.status_cancelled}】`,
                        }[status]),
                      width: 120,
                    },
                    {
                      title: translate.amount,
                      align: 'right',
                      width: 150,
                      value: ({ total }) => PriceFormat(total),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

const LVRow = ({ loading, label, value, preValue, ...props }) => {
  return (
    <Grid container spacing={1} justifyContent={'space-between'} alignItems={'center'}>
      <Grid item xs={loading ? true : undefined}>
        <Typography variant={'h5'} {...props}>
          {loading ? <Skeleton /> : label}
        </Typography>
      </Grid>
      <Grid item xs={loading ? true : undefined}>
        <Typography variant={'h5'} {...props}>
          {loading ? <Skeleton /> : value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const MyTitle = ({ loading, children, ...prop }) => {
  return (
    <Typography variant={'h4'} style={{ color: 'black' }} {...prop}>
      {loading ? <Skeleton /> : children}
    </Typography>
  );
};
