import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: OrderFilterInput, $sortBy: [SorterInput!], $query: String) {
    node(id: $id) {
      id
      ... on Customer {
        orders(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy, query: $query) {
          totalCount
          nextCursor
          nodes {
            id
            createdAt
            referenceNo
            total
            status
            paymentStatus
            shippingStatus
            totalRefund
          }
        }
      }
    }
  }
`;
