import React, { useState } from 'react';
import { translate } from '../../../../shared/translate';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper } from '../../../../shared';
import { client } from '../../../../shared/apollo';
import { DELIVERY_NOTES_COMPLETE } from './query';
import { Box, Grid, MenuItem, Tooltip, Typography } from '@material-ui/core';

const MenuComplete = {
  render: function RenderFC({ enable, i }) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    return enable ? (
      <MenuItem
        key={i}
        disabled={!enable || isSubmitting}
        onClick={async () => {
          if (
            await confirmation(
              <Box textAlign={'left'}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography
                      style={{ whiteSpace: 'pre-line', color: '#000', fontWeight: 'bold', fontSize: '1.1rem' }}
                    >
                      {translate.formatString(translate['confirm_bulk_complete'], this.getSelectionCount())}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '1rem' }}>{translate.hint}</Typography>
                    <Typography style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}>
                      {translate.hint_complete_delivery_note || '只有「處理中」狀態的訂單方可確認為完成'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>,
            )
          ) {
            setIsSubmitting(true);
            await actionWrapper(
              async () => {
                await client.mutate({
                  mutation: DELIVERY_NOTES_COMPLETE,
                  variables: {
                    shopId: localStorage.getItem('shopId'),
                    filter: this.getSelectionFilter(),
                  },
                });
              },
              {
                messages: {
                  success: translate.importing_submit,
                },
              },
            );
            setIsSubmitting(false);
          }
        }}
      >
        {translate.bulk_complete || '批量完成確認'} ({this.getSelectionCount()})
      </MenuItem>
    ) : (
      <Tooltip title={translate.max_limit_bulk_complete || '批量確認最多支援 25 張訂單，請重新選擇'}>
        <div style={{ padding: 0 }}>
          <MenuItem key={i} disabled={true}>
            {translate.bulk_complete || '批量完成確認'} ({this.getSelectionCount()})
          </MenuItem>
        </div>
      </Tooltip>
    );
  },
  enable: function () {
    const count = this.getSelectionCount();
    return count > 0 && count <= 25;
  },
};

export default MenuComplete;
