import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import CustomTimeField from '../../../components/FormPageMaker/Fields/CustomTimeField';
import moment from 'moment';

const ServiceStartTimeField = ({ disabled, value, onChange }) => {
  const timeAt = value?.[0],
    timeThru = value?.[1];

  const active = !!timeAt;

  return (
    <Grid container>
      <Grid item xs={12}>
        <CheckboxField
          size={'small'}
          label={'選擇開放時間'}
          checked={active}
          onChange={() => {
            const now = moment().startOf('day').toISOString();
            if (active) onChange([null, null]);
            else onChange([now, now]);
          }}
          disabled={disabled}
        />
      </Grid>
      {active && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CustomTimeField
              minuteStep={5}
              InputProps={{
                startAdornment: <InputAdornment position={'start'}>開始時間</InputAdornment>,
              }}
              disabled={disabled}
              value={timeAt}
              onChange={(v) => onChange([v, timeThru])}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTimeField
              minuteStep={5}
              InputProps={{
                startAdornment: <InputAdornment position={'start'}>結束時間</InputAdornment>,
              }}
              disabled={disabled}
              value={timeThru}
              onChange={(v) => onChange([timeAt, v])}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ServiceStartTimeField;
