import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query (
    $id: ID!
    $startedAt: AWSDateTime!
    $startedThru: AWSDateTime!
    $cursor: Int
    $limits: Int
    $query: String
    $sortBy: [SorterInput!]
    $shopId: ID
  ) {
    node(id: $id) {
      id
      ... on Company {
        report {
          totalCollectionSales(startedAt: $startedAt, startedThru: $startedThru, shopId: $shopId) {
            totalCount
            totalOrder
            totalAmount
            totalAvgPrice
            totalAvgCount
            totalPageView
            totalHitRate
            totalConversionRate
          }
          topCollectionSales(
            startedAt: $startedAt
            startedThru: $startedThru
            cursor: $cursor
            limits: $limits
            sortBy: $sortBy
            query: $query
            shopId: $shopId
          ) {
            nextCursor
            totalCount
            nodes {
              id
              name
              code
              count
              orderCount
              amount
              avgPrice
              avgCount
              hitRate
              pageView
              conversionRate
            }
          }
        }
      }
    }
  }
`;
