import React from 'react';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import HashTagsField from '../../../components/FormPageMaker/Fields/HashTagsField';
import { translate } from 'shared/translate';
import InputField from 'components/FormPageMaker/Fields/InputField';
import FormRow from 'components/FormRow';
import PriceAdjustmentTable from './PriceAdjustmentTable';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import Dnd from '../../../components/DND';
import { Icon } from '../../../components/IconRender';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import TrashButton from '../../../components/TrashButton';

export default ({ loading, values: { modifiers = [] }, setFieldValue, disabled }) => {
  const hasOptions = modifiers.length > 0 && !!modifiers.find((mod) => (mod.options || []).length > 0);
  return (
    <>
      <small>{translate.new_variant_remark}</small>
      <StyledConfigList>
        {modifiers.length > 0 && (
          <Grid item xs={12}>
            <Dnd
              direction={'column'}
              spacing={3}
              disabled={disabled}
              items={modifiers}
              onChange={(v) => setFieldValue('modifiers', v)}
              renderItem={(modifier, i, { nonDraggableProps }) => {
                const { name = '', options = [], max, min } = modifier || {};
                return (
                  <Box mx={1.5}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box mr={1.5}>
                          <Icon icon={IconDragVertical} type={'svg'} style={{ cursor: 'pointer', marginTop: 30 }} />
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Grid container spacing={1}>
                          <FormRow
                            {...nonDraggableProps}
                            title={translate.variant_remark}
                            required={true}
                            md={3}
                            xs={6}
                          >
                            <InputField
                              type={'text'}
                              value={name}
                              onChange={(e) => {
                                modifier.name = e.target.value;
                                setFieldValue('modifiers', modifiers);
                              }}
                              onKeyDown={(e) => {
                                if (e.keyCode === 8) {
                                  if (!modifier.name) {
                                    modifiers.splice(i, 1);
                                    setFieldValue('modifiers', modifiers);
                                  }
                                }
                              }}
                              required={true}
                              disabled={disabled}
                              maxLength={25}
                            />
                          </FormRow>
                          <FormRow
                            {...nonDraggableProps}
                            title={translate.variant_content}
                            required={true}
                            md={6}
                            xs={6}
                          >
                            <HashTagsField
                              disabled={disabled}
                              value={options
                                .map((opt) => opt.name)
                                .filter((_) => _)
                                .join('||')}
                              separator={'||'}
                              onChange={(value = '') => {
                                const newOptions = value.split('||').map((name) => ({ name, priceAdjustment: 0 }));

                                (modifier.options || []).forEach(({ name, ...opt }) => {
                                  const keep = newOptions.find((opt) => opt.name === name) || {};
                                  Object.assign(keep, opt);
                                });
                                modifier.options = newOptions;

                                setFieldValue('modifiers', modifiers);
                              }}
                            />
                          </FormRow>
                          <FormRow {...nonDraggableProps} title={translate.selection_opt || '選擇數量'} md={3} xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <InputField
                                  type={'number'}
                                  disabled={disabled}
                                  value={max}
                                  onChange={(e) => {
                                    modifier.max = +e.target.value;
                                    setFieldValue('modifiers', modifiers);
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">{translate.maximum}</InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: 1,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputField
                                  type={'number'}
                                  disabled={disabled}
                                  value={min}
                                  onChange={(e) => {
                                    modifier.min = +e.target.value;
                                    setFieldValue('modifiers', modifiers);
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">{translate.minimum}</InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: 1,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </FormRow>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <TrashButton
                          style={{ marginTop: 20 }}
                          disabled={disabled}
                          onClick={() => {
                            setFieldValue(
                              'modifiers',
                              modifiers?.filter((_, j) => j !== i),
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                );
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AddButton
            disabled={disabled}
            onClick={() => {
              modifiers.push({ name: '', options: [] });
              setFieldValue('modifiers', modifiers);
            }}
            title={translate.new_remark}
          />
        </Grid>
        {!!hasOptions && (
          <Box mx={1.5}>
            <PriceAdjustmentTable
              disabled={disabled}
              modifiers={modifiers}
              onChange={(modifiers) => {
                setFieldValue('modifiers', modifiers);
              }}
            />
          </Box>
        )}
      </StyledConfigList>
    </>
  );
};
