import React, { useState } from 'react';
import { MenuItem, Tooltip } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import { actionWrapper } from '../../../../shared';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { GET_ORDERS } from './query';
import PrintOptionsDialog from '../../../../components/PrintOptionsButton/PrintOptionsDialog';

const MenuInvoicePrint = {
  render: function RenderFC({ enable, i }) {
    const [loading, setLoading] = useState();
    const [visible, setVisible] = useState(false);
    const onClose = () => setVisible(false);

    return (
      <>
        {enable ? (
          <>
            <MenuItem key={i} disabled={!enable || loading} onClick={() => setVisible(true)}>
              {translate.invoice} ({this.getSelectionCount()})
            </MenuItem>
            {visible && (
              <PrintOptionsDialog
                open={visible}
                onClose={onClose}
                onSubmit={async ({ fontSize, sortProducts }) => {
                  setLoading(true);
                  await actionWrapper(async () => {
                    setLoading(true);
                    await actionWrapper(async () => {
                      const { nodes } = await this.infinityFetchAllSelected({
                        query: GET_ORDERS,
                        onProgress: (downloaded, total) => {
                          ActivityIndicator.show(`${translate.loading} ${downloaded}/${total}`);
                        },
                      });

                      if (!nodes?.length) {
                        throw new Error(translate.no_printable_data);
                      } else {
                        localStorage.setItem('cache_print_orders', JSON.stringify(nodes));
                        window.open(`/print_orders?fontSize=${fontSize}&sort=${sortProducts}`, '_blank');
                      }
                    });
                    ActivityIndicator.hide();
                    setLoading(false);
                  });
                  ActivityIndicator.hide();
                  setLoading(false);
                }}
              />
            )}
          </>
        ) : (
          <Tooltip title={translate.invoice_print_tip}>
            <div style={{ padding: 0 }}>
              <MenuItem key={i} disabled={true}>
                {translate.invoice} ({this.getSelectionCount()})
              </MenuItem>
            </div>
          </Tooltip>
        )}
      </>
    );
  },
  enable: function () {
    const count = this.getSelectionCount();
    return count > 0 && count <= 25;
  },
};

export default MenuInvoicePrint;
