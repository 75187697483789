import React, { useState } from 'react';
import SystemButton from '../../components/SystemButton';
import { client } from '../../shared/apollo';
import { REJECT_QUERY } from './query';
import { toast } from '../../shared/toast';
import { translate } from '../../shared/translate';
import errorParser from '../../shared/errorParser';
import { useHistory } from 'react-router-dom';
import confirmation from '../../shared/confirmation';

const RejectButton = ({ id, disabled }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    try {
      setLoading(true);
      if (!(await confirmation(translate.reject_confirmation))) return;

      await client.mutate({ mutation: REJECT_QUERY, variables: { id } });
      toast.success(translate.update_success);
      history.goBack();
    } catch (error) {
      toast.error(errorParser(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <SystemButton size="small" color="error" disabled={disabled || loading} onClick={handleClick}>
      {translate.status_reject}
    </SystemButton>
  );
};

export default RejectButton;
