import React from 'react';
import FormRow from '../../FormRow';
import SelectField from '../../FormPageMaker/Fields/SelectField';

export default ({ options = [], title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    [input] = value || [];
  return (
    <FormRow title={title}>
      <SelectField
        value={input || ''}
        onChange={(e) => {
          const value = e.target.value;
          onFilterChange({
            ...filter,
            value: value ? [value] : undefined,
            operator: 'IN',
          });
        }}
        options={options}
      />
    </FormRow>
  );
};
