import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { translate } from '../../shared/translate';
import FormRow from '../../components/FormRow';
import SelectComboDiscount from '../../components/SelectCombo/SelectComboDiscount';
import toast from '../../shared/toast';
import LSkeleton from '../../components/LSkeleton';

const ExcludedDiscountsCard = ({ values: { id, excludedDiscounts = [] }, setFieldValue, isSubmitting, loading }) => {
  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={1}>
          <FormRow title={translate.exclude_the_following_discounts}>
            {loading ? (
              <LSkeleton height={30} />
            ) : (
              <SelectComboDiscount
                copyableType={'CompanyDiscount'}
                multiple
                disabled={isSubmitting}
                value={excludedDiscounts}
                onChange={(discounts) => {
                  if (discounts.find((discount) => discount.id === id))
                    toast.error(translate.cannot_exclude_the_current_discount);
                  else setFieldValue('excludedDiscounts', discounts);
                }}
              />
            )}
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExcludedDiscountsCard;
