import React from 'react';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import { GET_ITEMS_QUERY, MODIFY_ITEM_QUERY } from '../query';
import { translate } from '../../../shared/translate';
import SystemButton from '../../../components/SystemButton';
import QuantityModifier from '../../../components/QuantityModifier';
import { Box, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { client } from 'shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import Loading from '../../../components/Loading';

export default ({ loading, values: { id } = {}, values }) => {
  return <StockTakeItemListView id={id} loading={loading} />;
};

class StockTakeItemListView extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_ITEMS_QUERY,
    },
    skip: !this.props.id,
    fields: [
      {
        title: translate.product_sku,
        fieldName: 'sku',
        width: 300,
      },
      {
        title: translate.product,
        width: 400,
        value: ({ productVariation }) => {
          const { product } = productVariation || {},
            { name, sku } = product || {};
          if (!name || !sku) return '';
          return `${name}`;
        },
      },
      {
        title: translate.product_variants,
        value: ({ productVariation }) => {
          const { combination = [] } = productVariation || {};
          return combination.map(({ name, option }) => `${name}: ${option}`).join(', ');
        },
      },
      {
        title: translate.stocktake_quantity,
        width: 150,
        align: 'center',
        value: ({ id, quantity }) => {
          const { selectedData } = this.state;
          const isEditing = selectedData?.id === id;
          const quantityDiff = selectedData?.quantity - quantity || 0;
          const isQuantityDiffPositive = quantityDiff > 0;
          return (
            <span>
              {quantity}
              {isEditing && quantityDiff !== 0 && (
                <Box component="span" sx={{ color: isQuantityDiffPositive ? '#4BB04F' : '#CC433C' }}>
                  {isQuantityDiffPositive ? ' + ' : ' - '}
                  {Math.abs(quantityDiff)}
                </Box>
              )}
            </span>
          );
        },
      },
      {
        title: translate.stock_take_modify_result,
        width: 200,
        align: 'center',
        render: ({ id, quantity }) => {
          const { selectedData } = this.state;

          return (
            <QuantityModifier
              quantity={selectedData?.id === id ? quantity : undefined}
              disabled={selectedData?.id !== id}
              onChange={(q) => this.setState({ selectedData: { ...selectedData, quantity: q } })}
            />
          );
        },
      },
      {
        width: 100,
        render: (data) => {
          const { selectedData, isSaving } = this.state;
          const isEditing = selectedData?.id === data.id;

          return isEditing ? (
            isSaving ? (
              <Loading spacing="" />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IconButton
                  size="small"
                  onClick={async () => {
                    this.setState({ isSaving: true });
                    try {
                      await client.mutate({
                        mutation: MODIFY_ITEM_QUERY,
                        variables: {
                          id: this.props.id,
                          input: [
                            {
                              currentStock: data.currentStock,
                              quantity: selectedData.quantity,
                              sku: data.sku,
                            },
                          ],
                        },
                      });
                      this.refetch();
                      toast.success(translate.stock_take_item_modify_success_msg);
                    } catch (e) {
                      toast.error(errorParser(e));
                    } finally {
                      this.setState({ selectedData: undefined, isSaving: false });
                    }
                  }}
                >
                  <CheckIcon style={{ color: '#4BB04F' }} />
                </IconButton>
                <IconButton size="small" onClick={() => this.setState({ selectedData: undefined })}>
                  <CloseIcon style={{ color: '#CC433C' }} />
                </IconButton>
              </Box>
            )
          ) : (
            <SystemButton
              size="medium"
              variant="outlined"
              className="normal-btn"
              onClick={() => {
                this.setState({
                  selectedData: {
                    ...data,
                  },
                });
              }}
            >
              {translate.edit}
            </SystemButton>
          );
        },
        align: 'center',
      },
    ],
  };

  renderContent({ loading, ...args }) {
    const { loading: _loading } = this.props;
    return super.renderContent({ ...args, loading: loading || _loading });
  }

  getData = ({ node } = {}) => {
    const { items } = node || {};
    return items;
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }

  getSkip() {
    const { id } = this.props;
    return !id;
  }
}
