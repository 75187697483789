import React, { useState } from 'react';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import FormToolbar from '../../../../components/FormToolbar';
import { Formik } from 'formik';
import FormCard from '../../../../components/FormPageMaker/Cards/FormCard';

export const MenuBarcodeGenerator = ({ scope, ...props }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <MenuItem disabled={scope.getSelectionCount() === 0} onClick={() => setVisible(true)}>
        {translate.print_barcode}
      </MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Formik
          initialValues={{ type: '54X40', quantity: 1 }}
          onSubmit={async (values) => {
            setVisible(false);
            localStorage.setItem(
              'print:barcodeItems:filter',
              JSON.stringify({
                id: {
                  operator: scope.getSelectionFilter()?.id?.operator,
                  value: scope.getSelectionFilter()?.id?.value,
                },
              }),
            );
            window.open(`/print_product_barcodes?type=${values.type}&quantity=${values.quantity}`, '_blank');
          }}
        >
          {(actions) => (
            <form onSubmit={actions.handleSubmit} style={{ minWidth: 370 }}>
              <DialogContent>
                <FormCard
                  actions={actions}
                  fields={[
                    {
                      label: translate.print_size,
                      name: 'type',
                      type: 'select',
                      options: [{ label: '54X40 Roll Code 128', value: '54X40' }],
                      required: true,
                    },
                    {
                      label: ({ values }) => translate.quantity,
                      type: 'number',
                      name: 'quantity',
                      required: true,
                      inputProps: {
                        min: 1,
                        step: 1,
                      },
                    },
                  ]}
                />
              </DialogContent>
              <FormToolbar
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
                submitBtnType={'submit'}
                confirmText={translate.confirm || '確認'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default {
  render: (args) => <MenuBarcodeGenerator {...args} />,
};
