import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $filter: ProductUnionFilterInput, $cursor: Int, $query: String) {
    node(id: $id) {
      ... on CompanyShop {
        id
        productUnions(limits: $limits, filter: $filter, cursor: $cursor, query: $query) @client
      }
    }
  }
`;

export const GET_COLLECTION_PRODUCT_UNIONS = gql`
  query ($id: ID!, $limits: Int, $filter: ProductUnionFilterInput, $cursor: Int) {
    node(id: $id) {
      ... on ShopCollection {
        id
        descendentProductUnions(limits: $limits, filter: $filter, cursor: $cursor) {
          totalCount
          nextCursor
          nodes {
            ... on ShopProduct {
              id
              name
              sku
              active
            }
            ... on ShopBundleProduct {
              id
              name
              sku
              active
            }
          }
        }
      }
    }
  }
`;
