import React, { useState } from 'react';
import { translate } from '../../../../shared/translate';
import ImportContainer from './ImportContainer';
import TabsCardDialog from '../../../../components/TabsCardDialog';
import { MenuItem } from '@material-ui/core';

const ImportModal = ({ onClick = (_) => _ }) => {
  const [visible, setVisible] = useState(false);
  const tabs = [{ name: translate.add_sub_coupons_in_bulk || '批量新增子優惠券' }];

  return (
    <>
      <MenuItem
        onClick={() => {
          onClick();
          setVisible(!visible);
        }}
        onOpen={() => setVisible(!visible)}
      >
        {translate.import}
      </MenuItem>
      <TabsCardDialog
        visible={visible}
        onOpen={() => setVisible(!visible)}
        tabs={tabs.map((tab, i) => ({
          label: tab.name,
          content: <ImportContainer tab={i} name={tabs[0].name} onClick={() => setVisible(false)} />,
        }))}
        PaperProps={{ padding: '55px 0' }}
      />
    </>
  );
};

export default ImportModal;
