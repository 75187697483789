import { gql } from '@apollo/client';

export const FRAGMENT_PRODUCT = gql`
  fragment FRAGMENT_PRODUCT on ShopProduct {
    id
    updatedAt
    createdAt
    active
    name
    sku
    basePrice
    maxPrice
    barcodes
    variations {
      id
      sku
      barcodes
      unitPrice
      suggestedRetailPrice
      active
      updatedAt
      createdAt
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: ProductFilterInput, $sortBy: [SorterInput!], $query: String) {
    node(id: $id) {
      id
      ... on CompanyShop {
        products(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_PRODUCT
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`;
