import React, { useRef, useState } from 'react';
import { client } from '../../../../shared/apollo';
import { REMOVE_QUERY } from './query';
import { toast } from '../../../../shared/toast';
import { translate } from '../../../../shared/translate';
import errorParser from '../../../../shared/errorParser';
import CancelModal from './CancelModal';
import TrashButton from '../../../../components/TrashButton';

export default ({ values: { id } }) => {
  const [loading, setLoading] = useState(false);
  const cancelModal = useRef(null);
  return (
    <>
      <TrashButton
        disabled={loading}
        loading={loading}
        onClick={async () => {
          try {
            const { reason = '' } = await cancelModal.current.execute();
            setLoading(true);
            await client.mutate({
              mutation: REMOVE_QUERY,
              variables: {
                id,
                input: {
                  cancelReason: reason,
                },
              },
            });
            toast.success(translate.order_removed_msg);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setLoading(false);
          }
        }}
      >
        {translate.cancel_order}
      </TrashButton>
      <CancelModal ref={cancelModal} />
    </>
  );
};
