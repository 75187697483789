import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import ActiveSwitcher from './ActiveSwitcher';

class SubscriptionListPage extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/subscriptions/new',
    hasQSearch: true,
    qFields: ['contact'],
    getRowLink: ({ id, contact }) => ({ pathname: `/subscriptions/${id}`, state: { title: contact } }),
    fields: [
      {
        title: translate.subscription_contact,
        fieldName: 'contact',
        sortBy: 'contact.keyword',
      },
      {
        width: 50,
        title: translate.subscription_type,
        fieldName: 'type',
        type: 'option',
        options: [
          { label: 'Push', value: 'PUSH' },
          { label: 'SMS', value: 'SMS' },
          { label: 'Email', value: 'EMAIL' },
        ],
        filter: 'type',
        sortBy: 'type.keyword',
      },
      {
        title: translate.subscribe_at,
        width: 180,
        align: 'right',
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
      },
      {
        title: translate.status,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} data={data} onCompleted={this.refetch} />;
        },
        sortBy: 'active',
        noLink: true,
      },
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    const { subscriptions } = node || {};
    return subscriptions;
  };
}

export default SubscriptionListPage;
