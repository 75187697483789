import React, { useState } from 'react';
import SystemButton from '../../components/SystemButton';
import useBedrockProducts from '../../hooks/useBedrockProducts';
import TextareaField from '../../components/FormPageMaker/Fields/TextareaField';
import AIResult from '../item_Product_AI/AIResult';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { Container } from '@material-ui/core';

const Test = () => {
  const [input, setInput] = useState('');
  const [{ items, loading }, invoke] = useBedrockProducts();
  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#48586c' }}>
      <Container>
        <TextareaField disabled={loading} value={input} onChange={setInput} />
        <SystemButton
          disabled={loading}
          onClick={async () => {
            try {
              await invoke({
                getPrompt: () => input,
              });
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
            }
          }}
        >
          Generate
        </SystemButton>
        <br />
        {items?.length > 0 && <AIResult data={items} input={{}} />}
      </Container>
    </div>
  );
};

export default Test;
