import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        marquees {
          nodes {
            id
            updatedAt
            backgroundColor
            closable
            positionTop
            positionBottom
            content
            active
          }
        }
      }
    }
  }
`;

export const SUBMIT_QUERY = gql`
  mutation($id: ID, $input: ShopMarqueeInput!) {
    shopMarqueeSet(id: $id, input: $input) {
      id
      active
      backgroundColor
      closable
      positionTop
      positionBottom
      content
      path
      shopId
    }
  }
`;
