import React from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { Box, Typography } from '@material-ui/core';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import HighlightFormRow from '../../HighlightFormRow';

export default {
  defaultHeight: 0,
  label: translate.separator,
  icon: <Icon icon={'faSlash'} style={{ color: '#97a1aa' }} />,
  preview: ({ data, setEditData = (_) => _, height }) => {
    const { content } = data || {};
    return (
      <Box
        display={'flex'}
        onClick={() => setEditData(data)}
        height={+height || 100}
        style={{ cursor: 'pointer' }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box flex={1}>
          <hr />
        </Box>
        <Box mx={1} style={{ maxWidth: '75%' }}>
          {content ? (
            <Typography variant={'h6'} noWrap={true}>
              {content}
            </Typography>
          ) : (
            <img src={require('assets/src_omniwe-logo.svg')} width={50} alt={'omniwe'} />
          )}
        </Box>
        <Box flex={1}>
          <hr />
        </Box>
      </Box>
    );
  },
  edit: ({ value, onChange }) => {
    const { content } = value || {};
    return (
      <>
        <HighlightFormRow highlight={value.content !== undefined} title={translate.title}>
          <InputField
            value={content}
            onChange={(e) =>
              onChange({
                ...value,
                content: e.target.value,
              })
            }
          />
        </HighlightFormRow>
      </>
    );
  },
};
