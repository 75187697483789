import React, { useRef, useState } from 'react';
import { AddToCategory_POST_QUERY } from '../query';
import { Dialog, Typography, Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import AddToCategoryListView from './AddToCategoryListView';
import { client } from '../../../shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { translate, translateFormat } from '../../../shared/translate';
import SystemButton from '../../../components/SystemButton';
import { sleep } from '../../../shared';

const AddToCategoryButton = ({ disabled, currCollectionId, list }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const handleSave = async () => {
    try {
      ActivityIndicator.show(translate.loading);
      const result = await ref?.current?.infinityFetchAllSelected({ includeCurrentQSearch: false });

      ActivityIndicator.show(translate.loading);

      await client.mutate({
        mutation: AddToCategory_POST_QUERY,
        variables: {
          id: currCollectionId,
          productIds: result?.nodes?.map((node) => node.id),
        },
      });
      sleep(3000).then(() => list.refetch());
      toast.success(
        translateFormat(translate.items_added_refresh_later, { num: result?.nodes?.map((node) => node.id)?.length }),
      );
    } catch (error) {
      toast.error(errorParser(error));
    } finally {
      setOpen(false);
      ActivityIndicator.hide();
      list.refetch();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCLose = () => {
    setOpen(false);
  };

  return (
    <>
      <SystemButton
        disabled={disabled}
        className={'normal-btn'}
        variant={'outlined'}
        onClick={handleOpen}
        startIcon={<Add />}
      >
        <Typography variant="caption1" color={'inherit'}>
          {translate.product_collections_add || '添加產品'}
        </Typography>
      </SystemButton>
      <Dialog fullScreen open={open} onClose={handleCLose}>
        <Box
          style={{
            paddingTop: '5rem',
          }}
        >
          <AddToCategoryListView
            handleSave={handleSave}
            handleOpen={handleOpen}
            handleCLose={handleCLose}
            id={currCollectionId}
            ref={(r) => {
              ref.current = r;
            }}
            filter={{
              collectionIds: {
                operator: 'NIN',
                value: [currCollectionId],
              },
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default AddToCategoryButton;
