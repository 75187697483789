import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_REPORT } from './query';
import moment from 'moment';
import _ from 'lodash';
import { parseConnection } from '../../../shared';
import CardContainer from '../CardContainer';

const CompanyContainer = ({ shop = {} }) => {
  const { loading: _loading, data: { me, companyNode } = {} } = useQuery(GET_REPORT, {
      fetchPolicy: 'cache-and-network',
      variables: {
        query: `companyId.keyword: "${localStorage.getItem('companyId')}"`,
        id: shop?.id || localStorage.getItem('shopId'),
        reportShopId: shop?.id || localStorage.getItem('shopId'),
        today: moment().startOf('day').toISOString(),
        last7day: moment().subtract(6, 'day').startOf('day').toISOString(),
        last30day: moment().subtract(29, 'day').startOf('day').toISOString(),
        startedThru: moment().endOf('day').toISOString(),
        companyId: localStorage.getItem('companyId'),
      },
    }),
    loading = !me && !!_loading,
    { report: companyReport = {} } = companyNode || {};

  const report = parseConnection((me || {}).shops).nodes.reduce(
    (reducer, shop) => {
      const { report } = shop || {};

      const {
        todayAmount,
        todayCount,
        last7dayAmount,
        last7dayCount,
        last30dayAmount,
        last30dayCount,
        unpaidOrderAmount,
        unpaidOrderCount,
        pendingOrderCount,
      } = report || {};
      const { id } = shop || {};
      if (id === (shop?.id || localStorage.getItem('shopId'))) {
        return {
          todayAmount: +reducer.todayAmount + +todayAmount,
          todayCount: +reducer.todayCount + +todayCount,
          last7dayAmount: +reducer.last7dayAmount + +last7dayAmount,
          last7dayCount: +reducer.last7dayCount + +last7dayCount,
          last30dayAmount: +reducer.last30dayAmount + +last30dayAmount,
          last30dayCount: +reducer.last30dayCount + +last30dayCount,
          unpaidOrderAmount: +reducer.unpaidOrderAmount + +unpaidOrderAmount,
          unpaidOrderCount: +reducer.unpaidOrderCount + +unpaidOrderCount,
          pendingOrderCount: +reducer.pendingOrderCount + +pendingOrderCount,
        };
      } else {
        return reducer;
      }
    },
    {
      todayAmount: 0,
      todayCount: 0,
      last7dayAmount: 0,
      last7dayCount: 0,
      last30dayAmount: 0,
      last30dayCount: 0,
      unpaidOrderAmount: 0,
      unpaidOrderCount: 0,
      pendingOrderCount: 0,
    },
  );

  return (
    <CardContainer
      loading={loading}
      report={report}
      companyReport={companyReport}
      unreadOrderMessageCount={null}
      unreadGeneralMessageCount={null}
    />
  );
};

export default CompanyContainer;
