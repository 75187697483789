import React from 'react';
import { Checkbox, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import _ from 'lodash';

const ProductRow = ({
  labelId,
  product,
  value, //true false
  onChange = (_) => _,
}) => {
  const theme = useTheme();
  const { active: productActive, name: productName } = product;

  return (
    <TableRow
      key={labelId}
      hover
      onClick={() => onChange(!value)}
      role="checkbox"
      aria-checked={value}
      tabIndex={-1}
      selected={value}
      style={{ backgroundColor: 'transparent' }}
    >
      <TableCell />
      <TableCell />
      <TableCell padding="checkbox" style={{ width: 40 }}>
        <Checkbox
          color="primary"
          checked={value}
          inputProps={{
            'aria-labelledby': labelId,
          }}
          style={{ padding: 0 }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none" style={{ height: 44, cursor: 'pointer' }}>
        <Typography style={{ color: productActive ? '#000' : theme.palette.error.light }}>{productName}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;
