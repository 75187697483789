import React, { useState } from 'react';
import SystemButton from '../../components/SystemButton';
import { client } from '../../shared/apollo';
import errorParser from '../../shared/errorParser';
import toast from '../../shared/toast';
import { translate } from '../../shared/translate';
import useQueryParams from '../../hooks/useQueryParams';

const ExportButton = ({ mutation, disabled, variables = {}, filter = {} }) => {
  const [query] = useQueryParams(),
    { startedAt, startedThru } = query ?? {};

  const [loading, setLoading] = useState(false);

  return (
    <SystemButton
      color="omniewRed"
      loading={loading}
      disabled={loading || disabled}
      onClick={async () => {
        try {
          setLoading(true);
          await client.mutate({
            mutation,
            variables: {
              shopId: localStorage.getItem('shopId'),
              filter: {
                createdAt: {
                  operator: 'BETWEEN',
                  value: [startedAt, startedThru],
                },
                ...filter,
              },
              ...variables,
            },
          });
          toast.success(translate.export_tips || '匯出當前篩選的數據條目，匯出完成後可於系統訊息中下載。');
        } catch (error) {
          toast.error(errorParser(error));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.export}
    </SystemButton>
  );
};

export default ExportButton;
