import { Table, withStyles } from '@material-ui/core';

const ConnectionTable = withStyles((theme) => ({
  root: {
    width: 'inherit',
    // transform: 'rotateX(180deg)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))(Table);

export default ConnectionTable;
