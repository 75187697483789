import { translate } from '../../../../shared/translate';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper } from '../../../../shared';

const STOCK_TRANSFER_EXPORT = gql`
  mutation ($shopId: ID!, $filter: StockTransferFilterInput) {
    stockTransferExport(shopId: $shopId, filter: $filter) {
      id
    }
  }
`;

const MenuExport = {
  name: translate.export,
  enable: function () {
    return this.getSelectionCount() > 0;
  },
  onClick: async function () {
    if (await confirmation(translate.formatString(translate['confirm_export_n_records'], this.getSelectionCount()))) {
      await actionWrapper(
        async () => {
          await client.mutate({
            mutation: STOCK_TRANSFER_EXPORT,
            variables: {
              shopId: localStorage.getItem('shopId'),
              filter: this.getSelectionFilter(),
            },
          });
        },
        {
          messages: {
            success: translate.export_tips,
          },
        },
      );
    }
  },
};

export default MenuExport;
