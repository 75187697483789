import { gql } from '@apollo/client';

export const FRAGMENT_STAFF = gql`
  fragment FRAGMENT_STAFF on CompanyStaff {
    id
    updatedAt
    createdAt
    active
    code
    name
    password
    phoneNumber
    role
    startedAt
    thumbnail {
      src
      optimizedSrc(width: 256, height: 256)
    }
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query (
    $id: ID!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: CompanyStaffFilterInput
    $createdAt: AWSDateTime
    $createdThru: AWSDateTime
  ) {
    node(id: $id) {
      id
      ... on Company {
        staffs(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_STAFF
            attendSecondsByDay(createdAt: $createdAt, createdThru: $createdThru) {
              createdAt
              createdThru
              seconds
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_STAFF}
`;

export const GET_REPORT_QUERY = gql`
  query ($id: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!, $query: String) {
    node(id: $id) {
      id
      ... on Company {
        report {
          topSalespersonSales(startedAt: $startedAt, startedThru: $startedThru, query: $query) {
            nextCursor
            totalCount
            nodes {
              id
              name
              amount
            }
          }
        }
      }
    }
  }
`;
