import React, { useRef, useState } from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';

export default (props) => {
  const list = useRef();
  const [tab, setTab] = useState(0);

  // const tabs = [
  //     {
  //       name: translate.all_bundle_products,
  //       link: '/bundle_products',
  //     },
  //     {
  //       name: translate.online_products,
  //       link: '/bundle_products',
  //     },
  //     {
  //       name: translate.offline_products,
  //       link: '/bundle_products',
  //     },
  //   ],
  //   tab = tabs.findIndex((tab) => tab.link === pathname);

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setTab(tab);
          list.current && list.current.resetCursor();
        }}
        tabs={[
          {
            name: translate.all_bundle_products,
          },
          {
            name: translate.online_products,
          },
          {
            name: translate.offline_products,
          },
        ]}
      />
      <Box>
        <ListView
          {...props}
          ref={list}
          filter={{
            active: {
              0: undefined,
              1: { operator: 'IN', value: ['true'] },
              2: { operator: 'IN', value: ['false'] },
            }[tab],
          }}
        />
      </Box>
    </div>
  );
};
