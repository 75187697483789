import React, { useState } from 'react';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { PROCESS_QUERY } from './query';
import SystemButton from '../../components/SystemButton';

const DeliveryNoteConfirmButton = ({ values, setFieldValue }) => {
  const [loading, setLoading] = useState(false);
  return (
    <SystemButton
      color={'info'}
      loading={loading}
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);
          const { data: { deliveryNoteProcess } = {} } = await client.mutate({
            mutation: PROCESS_QUERY,
            variables: {
              id: values.id,
            },
          });
          setFieldValue('status', deliveryNoteProcess?.status);
          setFieldValue('trackingNumber', deliveryNoteProcess?.trackingNumber);
          toast.success(translate.delivery_status_updated);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.confirm} ({translate.ship_out})
    </SystemButton>
  );
};

export default DeliveryNoteConfirmButton;
