import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { translate } from '../../shared/translate';

// 星期
export const daysOfTheWeek = [
  { label: translate.sun || '日', value: '7' },
  { label: translate.mon || '一', value: '1' },
  { label: translate.tue || '二', value: '2' },
  { label: translate.wed || '三', value: '3' },
  { label: translate.thu || '四', value: '4' },
  { label: translate.fri || '五', value: '5' },
  { label: translate.sat || '六', value: '6' },
];

const DaySelectButtonGroup = ({ value, required, disabled, onChange }) => {
  const handleButtonClick = (selectedValue) => {
    // If the selected value is already in the array, remove it, otherwise add it
    const newValue = value.includes(selectedValue)
      ? value.filter((item) => item !== selectedValue)
      : [...value, selectedValue];
    onChange(newValue);
  };

  return (
    <Grid container>
      {daysOfTheWeek.map(({ label, value: _value }, i) => (
        <Grid item key={i} style={{ marginLeft: i === 0 ? 0 : -1 }}>
          <Button
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '0',
              width: 50,
            }}
            variant="contained"
            disabled={disabled}
            color={value.includes(_value) ? 'secondary' : 'default'}
            onClick={() => {
              handleButtonClick(_value);
            }}
          >
            {label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default DaySelectButtonGroup;
