import React from 'react';
import ItemCustomPageFull from '../item_CustomPageFull';
import { translate } from '../../shared/translate';
import SeoCardSetting from '../item_Product/SeoCardSetting';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { JSONParseSafely } from '../../shared';
import fixBodyFunction from '../item_CustomPageFull/fixBodyFunction';
import FormRow from '../../components/FormRow';

export default class extends ItemCustomPageFull {
  state = {
    ...this.state,
    tabs: [
      {
        name: translate.custom_page_details,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                render: ({ values: { name } = {}, setFieldValue, isSubmitting }) => {
                  const { toggleDesignPage } = this.state;
                  return (
                    <InputField
                      disabled={isSubmitting}
                      required={!toggleDesignPage}
                      type={'text'}
                      value={name}
                      onChange={(e) => {
                        setFieldValue('name', e.target.value);
                        setFieldValue('href', (e.target.value || '').replace(/\s/g, ''));
                      }}
                      maxLength={100}
                    />
                  );
                },
                md: 6,
              },
              {
                label: translate.href,
                type: 'text',
                name: 'href',
                required: () => {
                  const { toggleDesignPage } = this.state;
                  return !toggleDesignPage;
                },
                disabled: true,
              },
              {
                label: translate.hash_tag,
                description: translate.hash_tag_description || '系統會自動在輸入內容前加上 "#" 符號，請直接輸入內容',
                name: 'hashtags',
                type: 'hashTagsArray',
                md: 9,
              },
              {
                label: translate.sort_index,
                type: 'number',
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
              },
              {
                label: translate.activation,
                type: 'switch',
                name: 'active',
              },
              {
                label: translate.visible,
                type: 'switch',
                name: 'display',
              },
            ],
          },
        ],
      },
      SeoCardSetting,
      {
        name: translate.page_design,
        cards: [],
        onClick: () => this.toggleDesignPage(),
      },
    ],
  };
  getInitialData({ node } = {}) {
    const {
      name = '',
      href = '',
      group = 'articles',
      active = true,
      display = true,
      body = '[]',
      updatedAt,
      metaTitle = '',
      metaDescription = '',
      metaKeywords = '',
      rewriteUri = '',
      hashtags,
      sortIndex,
    } = node || {};
    return {
      name,
      href,
      group,
      active,
      display,
      body: fixBodyFunction(JSONParseSafely(body, [])),
      metaTitle,
      metaDescription,
      metaKeywords,
      rewriteUri,
      updatedAt,
      hashtags: hashtags || [],
      sortIndex: sortIndex || 0,
    };
  }

  renderExtraSimpleForm = ({ values: { name } = {}, setFieldValue, isSubmitting } = {}) => {
    return (
      <>
        <FormRow title={translate.name}>
          <InputField
            required={true}
            type={'text'}
            value={name}
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue('name', e.target.value);
              setFieldValue('href', (e.target.value || '').replace(/\s/g, ''));
            }}
            maxLength={50}
          />
        </FormRow>
      </>
    );
  };
}
