import React, { useState } from 'react';
import { client } from '../../../../shared/apollo';
import { CONFIRM_QUERY } from './query';
import { toast } from '../../../../shared/toast';
import { translate } from '../../../../shared/translate';
import errorParser from '../../../../shared/errorParser';
import SystemButton from '../../../../components/SystemButton';
import { Icon } from '../../../../components/IconRender';

export default ({ values: { id } }) => {
  const [loading, setLoading] = useState(false);
  return (
    <SystemButton
      disabled={loading}
      loading={loading}
      color="success"
      onClick={async () => {
        try {
          setLoading(true);
          await client.mutate({
            mutation: CONFIRM_QUERY,
            variables: {
              id,
            },
          });
          toast.success(translate.order_confirmed_msg);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
      startIcon={<Icon icon={'faCheck'} fontSize={'inherit'} />}
    >
      {translate.confirm_order}
    </SystemButton>
  );
};
