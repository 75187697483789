const fixBodyFunction = (body) => {
  const [firstRow] = body;
  if (!Array.isArray(firstRow)) return body;

  const newBody = [];
  for (const row of body) {
    if (Array.isArray(row)) {
      const newRow = { _type: 'row', items: [] };
      for (const col of row) {
        if (col.rowConfig) {
          Object.assign(
            newRow,
            filterUndefinedKey({
              backgroundImage: col.backgroundImage,
              backgroundColor: col.backgroundColor,
              hasContainer: col.hasContainer,
              borderColor: col.borderColor,
              marginTop: col.pt ? col.pt * 8 : undefined,
              marginBottom: col.pb ? col.pb * 8 : undefined,
              spacing: col.spacing ? col.spacing * 8 : undefined,
              sm: filterEmptyObject(
                filterUndefinedKey({
                  backgroundImage: col.mobileBackgroundImage,
                  backgroundColor: col.mobileBackgroundColor,
                  hasContainer: col.mobileHasContainer,
                  borderColor: col.mobileBorderColor,
                  marginTop: col.mobilePt ? col.mobilePt * 8 : undefined,
                  marginBottom: col.mobilePb ? col.mobilePb * 8 : undefined,
                  spacing: col.mobileSpacing ? col.mobileSpacing * 8 : undefined,
                }),
              ),
              md: filterEmptyObject(
                filterUndefinedKey({
                  backgroundImage: col.tabletBackgroundImage,
                  backgroundColor: col.tabletBackgroundColor,
                  hasContainer: col.tabletHasContainer,
                  borderColor: col.tabletBorderColor,
                  marginTop: col.tabletPt ? col.tabletPt * 8 : undefined,
                  marginBottom: col.tabletPb ? col.tabletPb * 8 : undefined,
                  spacing: col.tabletSpacing ? col.tabletSpacing * 8 : undefined,
                }),
              ),
              lg: filterEmptyObject(
                filterUndefinedKey({
                  backgroundImage: col.desktopBackgroundImage,
                  backgroundColor: col.desktopBackgroundColor,
                  hasContainer: col.desktopHasContainer,
                  borderColor: col.desktopBorderColor,
                  marginTop: col.desktopPt ? col.desktopPt * 8 : undefined,
                  marginBottom: col.desktopPb ? col.desktopPb * 8 : undefined,
                  spacing: col.desktopSpacing ? col.desktopSpacing * 8 : undefined,
                }),
              ),
            }),
          );
        } else {
          const newColumn = { _type: 'column' };
          switch (col.name) {
            case 'html':
              newColumn.height = 0;
              newColumn.item = {
                _type: 'html',
                html: col.content,
              };
              break;
            case 'tableOfContents':
              newColumn.item = {
                _type: 'tableOfContents',
                title: col.title,
                headingTags: col.headingTags,
                subheadingTags: col.subheadingTags,
              };
              break;
            case 'collection':
              newColumn.item = {
                _type: 'collection',
                collectionId: col.collectionId,
                collectionName: 'unnamed collection',
              };
              break;
            case 'image':
              newColumn.item = {
                _type: 'image',
                src: col.image,
                href: col.href,
              };
              break;
            case 'product':
              newColumn.item = {
                _type: 'product',
                productId: col.productId,
                productName: 'unnamed product',
              };
              break;
            case 'separator':
              newColumn.item = {
                _type: 'separator',
                content: col.content,
              };
              break;
            case 'banner':
              newColumn.item = {
                _type: 'banner',
                images: (col.images || []).map(({ image, title, content, href }) => ({
                  src: image,
                  href,
                  title,
                  subtitle: title,
                  content,
                })),
              };
              break;
            case 'article':
              newColumn.item = {
                _type: 'article',
                pageId: col.pageId,
                pageName: 'unnamed page',
              };
              break;
            case 'feature':
              newColumn.item = {
                _type: 'feature',
                src: col.image,
                title: col.title,
                content: col.content,
              };
              break;
            case 'presentation':
              newColumn.item = {
                _type: 'image',
                src: col.image,
                title: col.title,
                content: col.content,
                href: col.href,
              };
              break;
            case 'directionCard':
              newColumn.item = {
                _type: 'image',
                src: col.image,
                title: col.title,
                href: col.href,
              };
              break;
            case 'searchBar':
              newColumn.item = {
                _type: 'searchBar',
              };
              break;
            case 'latestArticles':
              newColumn.item = {
                _type: 'latestArticles',
                title: '最新文章',
              };
              break;
            case 'newestProducts':
              newColumn.item = {
                _type: 'latestProducts',
                title: '最新產品',
              };
              break;
            default:
              break;
          }
          newRow.items.push(newColumn);
        }
      }
      newBody.push(newRow);
    }
  }
  return newBody;
};
export default fixBodyFunction;

function filterUndefinedKey(obj) {
  return Object.keys(obj).reduce((reducer, key) => {
    if (obj[key] !== undefined) reducer[key] = obj[key];
    return reducer;
  }, {});
}

function filterEmptyObject(obj) {
  return Object.keys(obj).length > 0 ? obj : undefined;
}
