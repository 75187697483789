import React, { useState } from 'react';
import { Add } from '@material-ui/icons';
import SystemButton from '../../../components/SystemButton';
import { translate } from '../../../shared/translate';
import { Dialog, DialogContent, DialogTitle, InputAdornment } from '@material-ui/core';
import { Formik } from 'formik';
import { client } from '../../../shared/apollo';
import { CHECKOUT_GET_INVOICE_QUERY, CHECKOUT_PAY_QUERY } from './query';
import errorParser from '../../../shared/errorParser';
import FormRow from '../../../components/FormRow';
import SelectComboPaymentMethod from '../../../components/SelectCombo/SelectComboPaymentMethod';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import FormToolbar from '../../../components/FormToolbar';
import toast from '../../../shared/toast';

const CreateInvoiceButton = ({ order, disabled, onInvoiceCreated = () => {} }) => {
  const [open, setOpen] = useState(false);

  const checkoutId = order?.checkout?.id,
    total = order?.total,
    totalPaid = order?.totalPaid;

  const amount = total - totalPaid;

  return (
    <>
      <SystemButton
        disabled={disabled}
        className={'normal-btn'}
        variant={'outlined'}
        onClick={() => setOpen(true)}
        startIcon={<Add />}
      >
        {translate.add}
      </SystemButton>
      <Dialog open={open}>
        <DialogTitle>{translate.new_invoice}</DialogTitle>
        <Formik
          key={amount}
          onSubmit={async ({ paymentMethod, amount }, { setSubmitting }) => {
            try {
              const { data: { node } = {} } = await client.query({
                  query: CHECKOUT_GET_INVOICE_QUERY,
                  variables: {
                    id: checkoutId,
                    input: {
                      provider: 'MANUAL',
                      // credentialId: paymentMethod?.credential?.id,
                      charge: amount,
                      paymentMethodId: paymentMethod?.id,
                    },
                  },
                  fetchPolicy: 'no-cache',
                }),
                { payment } = node || {},
                { invoiceId } = payment || {};
              await client.mutate({
                mutation: CHECKOUT_PAY_QUERY,
                variables: {
                  id: checkoutId,
                  input: {
                    invoiceId,
                    provider: 'MANUAL',
                    credentialId: paymentMethod?.credential?.id,
                  },
                },
              });
              onInvoiceCreated();
              setOpen(false);
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              setSubmitting(false);
            }
          }}
          initialValues={{ amount }}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormRow title={translate.payment_method} required={true}>
                  <SelectComboPaymentMethod
                    required={true}
                    disabled={isSubmitting}
                    value={values.paymentMethod}
                    onChange={(paymentMethod) => setFieldValue('paymentMethod', paymentMethod)}
                  />
                </FormRow>
                <FormRow title={translate.amount} required={true}>
                  <InputField
                    // eslint-disable-next-line
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    required={true}
                    disabled={true}
                    value={values.amount}
                    type={'number'}
                  />
                </FormRow>
              </DialogContent>
              <FormToolbar
                loading={isSubmitting}
                disabled={isSubmitting}
                submitBtnType={'submit'}
                onCancel={() => setOpen(false)}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateInvoiceButton;
