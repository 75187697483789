import React from 'react';
import { Divider, CardHeader, Badge, Typography, Card, CardContent } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { translate } from '../../shared/translate';

export default ({ title = 'title', loading, data: _data = [] }) => {
  return (
    <Card>
      <CardHeader
        avatar={<Badge color="secondary" variant="dot" />}
        title={
          <Typography variant={'h6'} color={'secondary'}>
            {title}
          </Typography>
        }
        disableTypography={true}
      />
      <Divider />
      <CardContent>
        <Typography>
          {translate.workstage_conversion_rate_statement ||
            '以訪客的工作階段內有產生的互動作為分析依據，一個工作階段代表一個訪客在網站內所造成的一個互動。工作階段會在網頁閒置30分鐘後逾時，在「工作階段逾時」之前，訪客的所有瀏覽及互動都視為同一個工作階段。'}
        </Typography>
        {loading ? <Skeleton variant="rect" width={'100%'} height={400} /> : <div style={{ maxWidth: '70vw' }}></div>}
      </CardContent>
    </Card>
  );
};
