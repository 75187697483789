import { gql } from '@apollo/client';

export const GET_ORDER_QUERY = gql`
  query ($id: ID!, $startedAt: String!, $startedThru: String!, $cursor: Int, $limits: Int) {
    node(id: $id) {
      id
      ... on CompanyShop {
        orders(
          cursor: $cursor
          limits: $limits
          filter: {
            createdAt: { operator: BETWEEN, value: [$startedAt, $startedThru] }
            active: { operator: IN, value: ["true"] }
          }
          sortBy: [{ field: "createdAt", order: ASC }]
        ) {
          totalCount
          nextCursor
          nodes {
            id
            referenceNo
            createdAt
            status
            total
          }
        }
      }
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
    me {
      ... on User {
        id
        name
        email
      }
    }
    node(id: $id) {
      id
      ... on CompanyShop {
        title: name
        locale
        report {
          turnover(startedAt: $startedAt, startedThru: $startedThru)
          volume(startedAt: $startedAt, startedThru: $startedThru)
          unpaidOrderAmount(startedAt: $startedAt, startedThru: $startedThru)
          refund(startedAt: $startedAt, startedThru: $startedThru)
          otherDiscounts(startedAt: $startedAt, startedThru: $startedThru) {
            name
            count
            amount
          }
          salesByMethods(startedAt: $startedAt, startedThru: $startedThru) {
            name
            count
            amount
          }
          salesByOrderItems(startedAt: $startedAt, startedThru: $startedThru) {
            name
            count
            amount
          }
        }
      }
    }
  }
`;
