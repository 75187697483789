import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on ShopVenue {
        name
        sortIndex
        updatedAt
        tables @client
        media {
          src
        }
        metadata {
          key
          value
        }
      }
    }
  }
`;

export const CREATE_TABLE = gql`
  mutation ($input: TableCreateInput!) {
    result: tableCreate(input: $input) {
      id
      name
      sortIndex
      updatedAt
    }
  }
`;

export const UPDATE_TABLE = gql`
  mutation ($id: ID!, $input: TableUpdateInput!) {
    result: tableUpdate(id: $id, input: $input) {
      id
      name
      sortIndex
      updatedAt
    }
  }
`;

export const DELETE_TABLE = gql`
  mutation ($id: ID!) {
    result: tableDelete(id: $id) {
      id
    }
  }
`;

export const CREATE_QUERY = gql`
  mutation ($input: VenueCreateInput!) {
    result: venueCreate(input: $input) {
      id
      name
      sortIndex
      updatedAt
      tables @client
    }
  }
`;

export const POST_QUERY = gql`
  mutation ($id: ID!, $input: VenueUpdateInput!) {
    result: venueUpdate(id: $id, input: $input) {
      id
      name
      sortIndex
      updatedAt
      tables @client
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    result: venueDelete(id: $id) {
      id
    }
  }
`;
