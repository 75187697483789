import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on ShopPage {
        name
        href
        body
      }
    }
  }
`;
