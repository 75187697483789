import React from 'react';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';
import ListCashFlowStatement from './ListCashFlowStatement';
import useQueryParams from '../../hooks/useQueryParams';

export default (props) => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery((query) => ({
            ...query,
            cursor: undefined,
            tab,
          }));
        }}
        tabs={[
          {
            name: translate.third_party_collection_or_payment || '所有代收/代支',
          },
          {
            name: translate.credit || '存入',
          },
          {
            name: translate.debit || '支出',
          },
        ]}
      />
      <Box>
        <ListCashFlowStatement
          key={tab}
          {...props}
          filter={
            {
              0: {},
              1: {
                direction: {
                  operator: 'IN',
                  value: ['INBOUND'],
                },
              },
              2: {
                direction: {
                  operator: 'IN',
                  value: ['OUTBOUND'],
                },
              },
            }[tab]
          }
        />
      </Box>
    </div>
  );
};
