import React, { useRef, useEffect, useState } from 'react';
import { Icon } from '../IconRender';
import s3Upload from 'shared/s3Upload';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  GET_CHANNEL_MESSAGES,
  CREATE_NEW_PLAYER,
  POST_MESSAGE,
  CHANNEL_MESSAGE_SUBSCRIPTION,
  CHANNEL_READ,
} from './query';
import { client } from '../../shared/apollo';
import Messages from './messages';
import ScrollView from 'react-inverted-scrollview';
import { Fab, Typography, Box } from '@material-ui/core';
import { parseConnection } from 'shared';
import _ from 'lodash';
import moment from 'moment';
import CoreLoadingSkeleton from './CoreLoadingSkeleton';
import { useMeasure } from 'react-use';
import InputToolbar from './InputToolbar';
import { translate } from '../../shared/translate';

export default ({ loading: preparing = false, shopId, channelId, owner = {}, disabled }) => {
  const queryVariables = { id: channelId, filter: {}, limits: 10, playerId: owner.id || '' };
  const scrollView = useRef(undefined);
  const [atTop, setAtTop] = useState(false);
  const [bottomSnap, setBottomSnap] = useState(true);
  const [pendingMessages, setPendingMessages] = useState([]);
  const [sendingMessage, setSendingMessage] = useState(false);

  const {
      loading: _loading,
      data: { node } = {},
      fetchMore,
    } = useQuery(GET_CHANNEL_MESSAGES, {
      fetchPolicy: 'cache-and-network',
      variables: queryVariables,
      skip: !channelId,
    }),
    { players = [] } = node || {},
    { nodes: messages, nextCursor } = parseConnection((node || {}).messages);

  const isJoined = !!players.find((player) => player.email === owner.email),
    player = players.find((player) => player.email === owner.email);

  const loading = (_loading || preparing) && !node,
    latestMessage = messages[0];

  const addMessagesQuery = (message) => {
    try {
      if (!message) return;
      setPendingMessages(pendingMessages.filter((msg) => msg !== message.id));
      if (!!messages.find((msg) => msg.id === message.id)) return;
      const { node } = client.readQuery({
          query: GET_CHANNEL_MESSAGES,
          variables: queryVariables,
        }),
        { nodes, totalCount, nextCursor } = parseConnection((node || {}).messages);
      client.writeQuery({
        query: GET_CHANNEL_MESSAGES,
        variables: queryVariables,
        data: {
          node: {
            ...node,
            messages: {
              ...(node || {}).messages,
              totalCount: totalCount + 1,
              nextCursor: nextCursor + 1,
              nodes: [{ ...message, allRead: true }, ...nodes],
            },
          },
        },
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (!!bottomSnap) toBottom();
  }, [messages.length, pendingMessages.length]);
  useEffect(() => {
    if (latestMessage && latestMessage.id && !disabled) {
      client
        .mutate({
          mutation: CHANNEL_READ,
          variables: { channelId, channelPlayerId: shopId },
        })
        .then((res) => {})
        .catch(console.log);
    }
  }, [(latestMessage || {}).id]);

  const toBottom = () => {
    if (!!scrollView && !!scrollView.current) {
      scrollView.current.scrollToBottom();
    }
  };

  async function onTopScroll() {
    if (!loading && !!nextCursor) {
      await fetchMore({
        variables: {
          cursor: nextCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const fetchMoreConnection = getConnection(fetchMoreResult),
            { nodes: fetchMoreNodes } = fetchMoreConnection,
            { nodes: prevNodes } = getConnection(prevResult);
          fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

          return {
            node: {
              ...(fetchMoreResult.node || {}),
              messages: fetchMoreConnection,
            },
          };

          function getConnection(data) {
            const { node } = data || {};
            return parseConnection((node || {}).messages);
          }
        },
      });
    }
  }

  const onScroll = ({ scrollTop, scrollBottom } = {}) => {
    if (scrollTop === 0 && !atTop) {
      setAtTop(true);
      onTopScroll();
    } else if (scrollTop > 0 && !!atTop) {
      setAtTop(false);
    }
    if (scrollBottom <= 5 && !bottomSnap) {
      setBottomSnap(true);
    } else if (scrollBottom > 5 && !!bottomSnap) {
      setBottomSnap(false);
    }
  };
  const [createNewPlayer] = useMutation(CREATE_NEW_PLAYER);
  const [postMessage] = useMutation(POST_MESSAGE);
  const sendMessage = async ({ input = {} } = {}) => {
    let _player = player;
    if (!isJoined) {
      const { data: { channelPlayerSet } = {} } = await createNewPlayer({
        variables: {
          input: {
            channelId,
            ...owner,
            name: owner.name || owner.email || 'Shop',
            userId: owner.id,
            id: undefined,
          },
        },
      });
      _player = channelPlayerSet;
    }
    const { data: { channelMessageSet: message } = {} } = await postMessage({
      variables: {
        input: {
          ...input,
          channelId,
          channelPlayerId: _player.id,
        },
      },
    });
    addMessagesQuery(message);
    return message;
  };

  useSubscription(CHANNEL_MESSAGE_SUBSCRIPTION, {
    variables: { channelId },
    skip: !channelId,
    onSubscriptionData: (res) => {
      const { subscriptionData: { data } = {} } = res || {};
      const { channelMessageUpdate } = data || {};
      if (!!channelMessageUpdate) {
        addMessagesQuery(channelMessageUpdate);
      }
    },
  });

  const onFileSelect = async (file) => {
    const { type } = file;
    const input = {
      id: moment().toISOString(),
      createdAt: moment().toISOString(),
      channelId,
      channelPlayerId: (player || {}).id,
      type: type.match(/^image\//) ? 'IMAGE' : type.match(/^video\//) ? 'VIDEO' : 'FILE',
      content: URL.createObjectURL(file),
    };

    try {
      setSendingMessage(true);
      setPendingMessages([input, ...pendingMessages]);
      const s3Path = await s3Upload(file);
      await sendMessage({ input: { ...input, content: s3Path, id: undefined, createdAt: undefined } });
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setPendingMessages([]);
      setSendingMessage(false);
    }
  };

  const handlePostMessage = async (message) => {
    if (!!message) {
      setSendingMessage(true);
      const input = {
        channelId,
        channelPlayerId: (player || {}).id,
        type: !!message.match(
          /(https?:\/\/[w.]?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*)/gi,
        )
          ? !!message.match(/(?<protocol>https?:\/\/(?:www\.)?|www\.)(?=.*mp4$)(?<url>.*)/gm)
            ? 'VIDEO'
            : !!message.match(/(http(s?):)([/|.\w\s-])*\.(?:jpg|gif|png|jpeg)/gi)
            ? 'IMAGE'
            : 'LINK'
          : 'TEXT',
        content: message,
      };
      try {
        setPendingMessages([input, ...pendingMessages]);
        await sendMessage({
          input,
        });
      } catch (e) {
        toast.error(errorParser(e));
      } finally {
        setPendingMessages([]);
        setSendingMessage(false);
      }
    }
  };

  const [ref, { height }] = useMeasure();

  return (
    <>
      <div ref={ref} style={{ flex: 1, position: 'relative' }}>
        {!!loading && <CoreLoadingSkeleton />}
        {!loading && (
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <ScrollView
              ref={scrollView}
              width={'100%'}
              height={height}
              onScroll={onScroll}
              style={{
                background: '#f1f1f1',
              }}
            >
              <div>
                {messages.length > 0 ? (
                  <Messages
                    player={player || {}}
                    players={players}
                    messages={messages}
                    pendingMessages={pendingMessages}
                  />
                ) : (
                  <Typography variant={'h6'} color={'textSecondary'} align={'center'}>
                    {translate.start_conversation || '開始對話'}
                  </Typography>
                )}
              </div>
            </ScrollView>
            {!bottomSnap && (
              <Fab onClick={toBottom} size={'small'} style={{ position: 'absolute', bottom: 40, right: 35 }}>
                <Icon icon={'faArrowToBottom'} />
              </Fab>
            )}
          </div>
        )}
      </div>
      {!disabled && (
        <Box py={1} px={3}>
          <InputToolbar
            disabled={!!(loading || sendingMessage || channelId === '')}
            onMessageSubmit={(message) => handlePostMessage(message)}
            onFileSubmit={(file) => onFileSelect(file)}
          />
        </Box>
      )}
    </>
  );
};
