import React, { Component } from 'react';
import { Snackbar, Slide, Fade, Grow } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}
export default class Toaster extends Component {
  defaultState = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  };
  state = {
    severity: 'success',
    message: '',
    visible: false,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  };
  success = (message, options) => {
    this.setState({ ...this.defaultState, message, visible: true, severity: 'success', ...options });
  };
  error = (message, options) => {
    this.setState({ ...this.defaultState, message, visible: true, severity: 'error', ...options });
  };
  warning = (message, options) => {
    this.setState({ ...this.defaultState, message, visible: true, severity: 'warning', ...options });
  };
  info = (message, options) => {
    this.setState({ ...this.defaultState, message, visible: true, severity: 'info', ...options });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const { visible, severity, message, anchorOrigin } = this.state;
    return (
      <Snackbar
        open={visible && !!message}
        anchorOrigin={anchorOrigin}
        autoHideDuration={5000}
        onClose={this.close}
        TransitionComponent={GrowTransition}
      >
        <Alert onClose={this.close} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    );
  }
}
