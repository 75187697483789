import React from 'react';
import { Icon } from 'components/IconRender';
import { makeStyles, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    whiteSpace: 'normal',
    flex: 1,
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  py0: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  headerActive: {
    backgroundColor: '#cc433d',
    '& *': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#cc433d',
    },
  },
  headerCommon: {
    '& *': {
      color: '#747f8e',
    },
    '&:hover': {
      '& *': {
        color: '#FFFFFF',
      },
    },
  },
  active: {
    backgroundColor: '#e67772',
    '& *': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#e67772',
    },
  },
  common: {
    '& *': {
      color: '#747f8e',
    },
    '&:hover': {
      '& *': {
        color: '#FFFFFF',
      },
    },
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default ({ shrinkMenu, name, icon, toggle, right, onClick, active, link, nested, header = false }) => {
  const classes = useStyles();
  const history = useHistory();

  const itemIcon = (
    <ListItemIcon className={classes.listItemIcon}>
      {!!icon &&
        (typeof icon === 'string' ? <Icon icon={icon} fontSize={'small'} /> : <Icon {...icon} fontSize={'small'} />)}
    </ListItemIcon>
  );

  return (
    <ListItem
      button
      component={!!link ? Link : undefined}
      href={link}
      to={link}
      className={`${
        header ? (active ? classes.headerActive : classes.headerCommon) : active ? classes.active : classes.common
      } ${nested && toggle ? classes.nested : ''}`}
      onClick={(e) => {
        if (!!onClick) {
          e.preventDefault();
          onClick(e);
        } else {
          if (!e.metaKey) history.push(link);
        }
        if (shrinkMenu) {
          window.dispatchEvent(new CustomEvent('setMenuCollapse', { detail: false }));
        }
      }}
      selected={active && name === 'homepage'}
    >
      {!toggle ? (
        <Tooltip title={name} arrow placement={'right'}>
          {itemIcon}
        </Tooltip>
      ) : (
        itemIcon
      )}
      {!!toggle && <ListItemText primary={name} className={classes.listItemText} />}
      {!!toggle && right}
    </ListItem>
  );
};
