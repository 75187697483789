import { gql } from '@apollo/client';

const FRAGMENT_SHOP_CREDENTIAL = gql`
  fragment ShopCredential on ShopCredential {
    name
    platform
    description
    createdAt
    updatedAt
    active
    type
    identity
    secret
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOP_CREDENTIAL}
  query ($shopId: ID!, $filterV2: CredentialFilterInput) {
    node(id: $shopId) {
      id
      ... on CompanyShop {
        credentials(filterV2: $filterV2) {
          ...ShopCredential
        }
      }
    }
  }
`;

export const SUBMIT_QUERY = gql`
  ${FRAGMENT_SHOP_CREDENTIAL}
  mutation ($id: ID, $input: CredentialSetInput!) {
    credentialSet(id: $id, input: $input) {
      id
      ...ShopCredential
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    credentialUnset(id: $id) {
      id
    }
  }
`;
