import { gql } from '@apollo/client';

export const GET_REPORT = gql`
  query($id: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        report {
          userAge(startedAt: $startedAt, startedThru: $startedThru) {
            name
            value
          }
          userCountry(startedAt: $startedAt, startedThru: $startedThru) {
            name
            value
          }
          userGender(startedAt: $startedAt, startedThru: $startedThru) {
            name
            value
          }
          pageViews(startedAt: $startedAt, startedThru: $startedThru) {
            name
            value
          }
          newCustomerSales(startedAt: $startedAt, startedThru: $startedThru) {
            amount
            count
          }
          nonCustomerSales(startedAt: $startedAt, startedThru: $startedThru) {
            amount
            count
          }
          oldCustomerSales(startedAt: $startedAt, startedThru: $startedThru) {
            amount
            count
          }
          customerSales(startedAt: $startedAt, startedThru: $startedThru) {
            amount
            count
          }
        }
      }
    }
  }
`;
