import { gql } from '@apollo/client';

export const FRAGMENT_SUBSCRIPTION = gql`
  fragment FRAGMENT_SUBSCRIPTION on NewsletterSubscription {
    createdAt
    updatedAt
    active
    type
    contact
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SUBSCRIPTION}
  query($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_SUBSCRIPTION
    }
  }
`;

export const SUBMIT_QUERY = gql`
  ${FRAGMENT_SUBSCRIPTION}
  mutation($id: ID, $input: NewsletterSubscriptionSetInput!) {
    newsletterSubscribe(id: $id, input: $input) {
      id
      ...FRAGMENT_SUBSCRIPTION
    }
  }
`;

export const DELETE_QUERY = gql`
  ${FRAGMENT_SUBSCRIPTION}
  mutation($id: ID!) {
    newsletterUnset(id: $id) {
      id
      ...FRAGMENT_SUBSCRIPTION
    }
  }
`;
