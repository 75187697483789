import React, { useState } from 'react';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { client } from '../../../shared/apollo';
import { ORDER_RESEND_CONFIRMATION_EMAIL } from './query';
import { toast } from '../../../shared/toast';
import { translate } from '../../../shared/translate';
import errorParser from '../../../shared/errorParser';
import SystemButton from '../../../components/SystemButton';
import { Icon } from '../../../components/IconRender';
import confirmation from '../../../shared/confirmation';

const ResendConfirmationEmailButton = ({ id, disabled, style }) => {
  const [loading, setLoading] = useState(false);

  return (
    <SystemButton
      color={'omniewRed'}
      variant="medium"
      disabled={loading || disabled}
      onClick={async () => {
        if (!(await confirmation(translate.resend_order_confirmation_email))) return;
        try {
          setLoading(true);
          ActivityIndicator.show();
          await client.mutate({
            mutation: ORDER_RESEND_CONFIRMATION_EMAIL,
            variables: {
              id,
            },
          });
          toast.success(translate.sent);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
          ActivityIndicator.hide();
        }
      }}
      startIcon={<Icon icon={'faEnvelope'} style={{ fontSize: '13px' }} />}
    >
      {translate.resend_order_confirmation_email}
    </SystemButton>
  );
};
export default ResendConfirmationEmailButton;
