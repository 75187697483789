import React from 'react';
import { translate } from 'shared/translate';
import DatetimeCardFooter from './DatetimeCardFooter';
import { Card, CardActions, makeStyles, Box } from '@material-ui/core';
import RemoveToggle from '../RemoveToggle';
import SystemButton from '../SystemButton';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#2a2b31',
    padding: theme.spacing(1),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  confirmButton: {
    backgroundColor: '#cc433c',
  },
}));

const FormToolbar = ({
  customComponent,
  extra,
  extraLeft,
  disabled,
  onCancel,
  onSave,
  updatedAt,
  submitBtnType,
  onRemoveClick,
  justify,
  loading,
  cancelText = translate.cancel,
  confirmText = translate.save,
  onEditClick,
  onSaveDisabled,
  transparentBackground,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Card
      className={transparentBackground ? undefined : classes.card}
      elevation={transparentBackground ? 0 : 1}
      style={transparentBackground ? { padding: 10 } : {}}
      square
      {...props}
    >
      <CardActions className={classes.cardActions} style={{ justifyContent: justify }}>
        {customComponent?.remove ||
          (!!onRemoveClick && (
            <RemoveToggle disabled={disabled} title={translate.confirm_to_delete} onClick={onRemoveClick} />
          ))}
        {extraLeft}
        <Box flex={1} />
        {extra}
        {customComponent?.cancel ||
          (!!onCancel && (
            <SystemButton
              disabled={disabled}
              variant={transparentBackground ? 'outlined' : undefined}
              style={
                transparentBackground
                  ? {}
                  : {
                      color: '#899199',
                    }
              }
              onClick={onCancel}
            >
              {cancelText}
            </SystemButton>
          ))}
        {customComponent?.edit ||
          (!!onEditClick && (
            <SystemButton loading={loading} color={'info'} disabled={disabled} onClick={onEditClick}>
              {translate.edit}
            </SystemButton>
          ))}
        {customComponent?.submit ||
          (!!submitBtnType && (
            <SystemButton
              loading={loading}
              color={'omniewRed'}
              // disabled={disabled}
              disabled={onSaveDisabled}
              type={submitBtnType}
              onClick={onSave}
            >
              {confirmText}
            </SystemButton>
          ))}
      </CardActions>
      <DatetimeCardFooter updatedAt={updatedAt} textColor={'#888'} />
    </Card>
  );
};

export default FormToolbar;
