import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    background: {
      default: 'rgba(0, 0, 0, 0.04)',
    },
    primary: {
      main: '#3cbe9b',
      light: '#5de0be',
      dark: '#3cac8e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#DF5241',
      light: '#e67772',
      dark: '#8a2a24',
      contrastText: '#fff',
    },
    warning: {
      contrastText: '#fff',
      dark: '#f57c00',
      light: '#ffb74d',
      main: '#ff9800',
    },
    success: {
      contrastText: '#fff',
      dark: '#388e3c',
      light: '#81c784',
      main: '#4caf50',
    },
  },
  typography: {
    allVariants: {
      color: '#7e858e',
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1.125rem',
      color: 'black',
    },
    fontFamily: [
      '"Simplified/Traditional Heiti"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Microsoft JhengHei"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiPaginationItem: {
      textSecondary: {
        color: '#7e858e',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: `3.5px !important`,
        paddingBottom: `3.5px !important`,
      },
      clearIndicatorDirty: {
        color: '#ff0000',
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: 'black',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: `0px 0px 0px 0px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 2px 5px 0px rgba(0,0,0,0.12)`,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        borderRadius: '0.5rem',
        backgroundColor: '#FFFFFF',
      },
      barColorPrimary: {
        backgroundColor: '#3cbe9b',
      },
    },
    MuiMobileStepper: {
      root: {
        backgroundColor: '#FFFFFF00',
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#cc433c',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#cc433c',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 15,
        boxShadow: '0px 1px 15px 0px #0000001A',
      },
    },
    MuiDialogContent: {
      root: {
        paddingLeft: 60,
        paddingRight: 60,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '25px',
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#00000000',
      },
    },
    MuiTable: {
      root: {
        width: 'inherit',
        '@media only screen and (max-width: 960px)': {
          width: 'max-content',
        },
      },
    },
    MuiButton: {
      root: {
        overflow: 'hidden',
        borderRadius: 7,
      },
      contained: {
        backgroundColor: 'white',
        boxShadow: 'none',
      },
      outlined: {},
      startIcon: {
        // marginRight: 15,
      },
      endIcon: {
        // marginLeft: 15,
      },
    },
    MuiSelect: {
      select: {
        '&>*': {
          background: 'transparent',
          color: 'inherit',
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
        backgroundColor: 'white',
      },
      inputMarginDense: {
        paddingBottom: 7.5,
        paddingTop: 7.5,
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: '#B7BBBF',
          opacity: 1,
        },
      },
      inputAdornedStart: {
        paddingBottom: 2,
      },
    },
    MuiInputAdornment: {
      root: {
        color: '#777777',
      },
    },
    MuiAccordionDetails: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiTabs: {
      root: {
        minHeight: undefined,
      },
    },
    MuiTab: {
      root: {
        minHeight: undefined,
        minWidth: '104px !important',
      },
    },
    MuiTooltip: {
      tooltip: {
        border: '1px solid #E1E1E1',
        borderRadius: 10,
        backgroundColor: '#fff',
        boxShadow: '0px 0px 20px 0px #00000026',
        color: '#7e858e',
      },
    },
  },
});

export const binColor = '#CC433C';
export const redTextColor = '#CC433D';
export const binDisabledColor = '#b0b0b0';
export const binHoverBackgroundColor = '#d5d5d5';
export const textColor = '#7e858e';
export const addButtonBackgroundColor = 'white';
export const fieldBackgroundColor = '#eee';
export const focusBorderLineColor = '#64BB9D';
export const optionColor = '#B7BBBF';
export const tooltipPlacement = 'right-start';
export const inactiveTabColor = '#f2f2f2';
export const activeTabColor = '#fff';

export const removeBtnStyle = {
  minWidth: 0,
  height: '100%',
  padding: '10px 10px',
  background: 'transparent',
  border: 'none',
  borderRadius: '30px',
  color: binColor,
  '&:hover': {
    backgroundColor: binHoverBackgroundColor,
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent !important',
  },
};
