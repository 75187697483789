import React from 'react';
import { translate } from '../../../shared/translate';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';
import SelectField from '../../../components/FormPageMaker/Fields/SelectField';

const SubCodeCard = (actions) => {
  const useSubCoupon = actions.values.useSubCoupon;
  if (!useSubCoupon) return null;

  return (
    <FormCard
      actions={actions}
      fields={[
        {
          md: 12,
          required: true,
          display: ({ values }) => values.useSubCoupon,
          label: translate.distribute_start_date_time,
          type: 'datetime-local',
          name: 'publishAt',
        },
        {
          md: 12,
          required: true,
          display: ({ values }) => values.useSubCoupon,
          label: translate.distribute_end_date_time,
          type: 'datetime-local',
          name: 'publishThru',
          hasPermanent: true,
        },
        {
          md: 12,
          display: ({ values }) => values.useSubCoupon,
          label: translate.distribute_limit,
          type: 'number',
          name: 'publishLimit',
          disabled: ({ values }) => values.publishLimit === null || !!values.id,
          placeholder: ({ values }) => (values.publishLimit === null ? translate.infinite : ''),
          inputProps: ({ values }) =>
            values.publishLimit === null
              ? undefined
              : {
                  step: 1,
                  min: 1,
                },
          InputProps: ({ values, setFieldValue, isSubmitting }) => ({
            endAdornment: !values.id && (
              <CheckboxField
                disabled={isSubmitting}
                checked={values.publishLimit === null}
                onChange={() => setFieldValue('publishLimit', values.publishLimit === null ? 1 : null)}
                text={translate.infinite}
                textVariant={'body2'}
              />
            ),
          }),
        },
        {
          md: 12,
          required: true,
          display: ({ values }) => values.useSubCoupon,
          label: translate.member_point_cost,
          name: 'memberPointCost',
          type: 'number',
          inputProps: {
            step: 1,
            min: 0,
          },
        },
        {
          md: 12,
          label: translate.offline_only || '門市專用',
          display: ({ values }) => values.useSubCoupon,
          name: 'isResolvable',
          type: 'switch',
        },
        {
          md: 12,
          label: translate.display_coupon_for_redemption || '在網店中顯示此優惠券（會員可自行兌換）',
          display: ({ values }) => values.useSubCoupon,
          name: 'display',
          type: 'switch',
        },
        {
          md: 12,
          required: true,
          display: ({ values }) => values.useSubCoupon,
          label: ({ values }) =>
            values.subCouponValidMode === 'RANGE' ? translate.use_valid_for : translate.use_valid_for_period,
          type: ({ values }) => (values.subCouponValidMode === 'RANGE' ? 'datetime-local' : 'period'),
          name: ({ values }) => (values.subCouponValidMode === 'RANGE' ? 'validAt' : 'validForPeriod'),
          InputProps: ({ values, setFieldValue, isSubmitting }) => ({
            startAdornment: (
              <SelectField
                style={{ width: 150 }}
                variant={'standard'}
                size={'medium'}
                // eslint-disable-next-line
                InputProps={{ disableUnderline: true }}
                disabled={isSubmitting}
                value={values.subCouponValidMode}
                onChange={(e) => setFieldValue('subCouponValidMode', e.target.value)}
                required={true}
                options={[
                  {
                    label: translate.after_redeem,
                    value: 'PERIOD',
                  },
                  {
                    label: translate.date_range,
                    value: 'RANGE',
                  },
                ]}
              />
            ),
          }),
        },
        {
          md: 12,
          required: true,
          display: ({ values }) => values.useSubCoupon && values.subCouponValidMode === 'RANGE',
          label: translate.use_valid_thru,
          type: 'datetime-local',
          name: 'validThru',
        },
      ]}
    />
  );
};

export default SubCodeCard;
