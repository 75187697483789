import { useState, useEffect } from 'react';

const useReady = (ms = 100) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true);
    }, ms);
    return () => clearTimeout(timer);
  }, [ms]);

  return ready;
};

export default useReady;
