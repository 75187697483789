import React from 'react';
import { Button, Typography, Box, withStyles } from '@material-ui/core';

export const languages = [
  {
    label: '中文繁體',
    value: 'tc',
  },
  {
    label: '中文简体',
    value: 'sc',
  },
  {
    label: 'English',
    value: 'en',
  },
];
const styles = (theme) => ({
  languageStepBox: {
    padding: '10px 20px',
    // height: '450px',
    // maxHeight: '610px',
  },
  stepTitleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepTitle: {
    color: '#1F4C6D',
    fontSize: '18px',
    fontWeight: '500',
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    // margin: '70px 0px',
    minHeight: '270px',
  },
  languageBtn: {
    height: '135px',
    padding: '50px 100px',
    borderRadius: '10px',
    border: '1px solid #FFF',
    background: 'rgba(255, 255, 255, 0.40)',
    boxShadow: '2px 2px 2px 0px rgba(255, 255, 255, 0.40) inset, 2px 2px 7px 0px rgba(255, 255, 255, 0.40)',
  },
  languageBtnText: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#5B7D96',
  },
});

const ChatGptLanguageStep = ({ disabled, classes, onChange }) => {
  return (
    <Box className={classes.languageStepBox}>
      <Box className={classes.stepTitleBox}>
        <Typography className={classes.stepTitle}>請選擇語言</Typography>
      </Box>
      <Box className={classes.btnBox}>
        {languages.map(({ label, value }, i) => {
          return (
            <Button
              key={i}
              className={classes.languageBtn}
              disabled={disabled}
              variant={'outlined'}
              type={'button'}
              onClick={() => {
                onChange(value);
              }}
            >
              <Typography className={classes.languageBtnText}>{label}</Typography>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ChatGptLanguageStep);
