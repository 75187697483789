import { gql } from '@apollo/client';

const FRAGMENT_EMAIL_TEMPLATE = gql`
  fragment FRAGMENT_EMAIL_TEMPLATE on ShopEmailTemplate {
    id
    updatedAt
    createdAt
    active
    name
    type
    configs
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $type: String) {
    node(id: $id) {
      id
      ... on CompanyShop {
        name
        locale
        logoMedia {
          src
        }
        customDomain
        hostname
        emailTemplates(limits: 1, filter: { type: { operator: IN, value: [$type] } }) {
          nodes {
            ...FRAGMENT_EMAIL_TEMPLATE
          }
        }
      }
    }
  }
  ${FRAGMENT_EMAIL_TEMPLATE}
`;

export const CREATE_QUERY = gql`
  mutation ($input: ShopEmailTemplateCreateInput!) {
    emailTemplateCreate(input: $input) {
      ...FRAGMENT_EMAIL_TEMPLATE
    }
  }
  ${FRAGMENT_EMAIL_TEMPLATE}
`;
export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ShopEmailTemplateUpdateInput!) {
    emailTemplateUpdate(id: $id, input: $input) {
      ...FRAGMENT_EMAIL_TEMPLATE
    }
  }
  ${FRAGMENT_EMAIL_TEMPLATE}
`;
