import React from 'react';
import { Box, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import { Icon } from 'components/IconRender';
import { translate } from '../../../shared/translate';

const padding = 5;

const useStyle = makeStyles((theme) => ({
  controlsWrapper: {
    '& .controls': {
      opacity: 0,
      transition: 'opacity 0.25s',
    },
    '&:hover .controls': {
      opacity: 1,
    },
  },
  controlBar: {
    width: theme.spacing(padding),
    minWidth: theme.spacing(padding),
    textAlign: 'center',
  },
  dashedBorder: {
    borderTop: '1px dashed #ccc',
  },
}));

export default ({
  children,
  style = {},
  simple,
  onRemoveClick,
  onUpClick,
  onDownClick,
  onEditClick,
  // row = [],
  // onChange = _ => _,
}) => {
  const classes = useStyle();
  // const [configModalVisible, setConfigModalVisible] = useState(false);

  if (simple)
    return (
      <Box px={padding} className={classes.dashedBorder} style={style}>
        {children}
      </Box>
    );
  return (
    <Box
      pl={padding}
      display={'flex'}
      flexDirection={'row'}
      className={`${classes.dashedBorder} ${classes.controlsWrapper}`}
      style={style}
    >
      <Box flexGrow={1}>{children}</Box>
      <Box className={`controls ${classes.controlBar}`} py={2}>
        <Tooltip title={translate.delete}>
          <IconButton color={'secondary'} size={'small'} onClick={onRemoveClick} disabled={!onRemoveClick}>
            <Icon type={'material'} icon={'Cancel'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate.move_top}>
          <IconButton color={'primary'} size={'small'} onClick={onUpClick} disabled={!onUpClick}>
            <Icon type={'material'} icon={'ArrowUpward'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate.move_down}>
          <IconButton color={'primary'} size={'small'} onClick={onDownClick} disabled={!onDownClick}>
            <Icon type={'material'} icon={'ArrowDownward'} />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate.layout_setting}>
          <IconButton color={'primary'} size={'small'} onClick={onEditClick} disabled={!onEditClick}>
            <Icon icon={'faCog'} />
          </IconButton>
        </Tooltip>
      </Box>
      {/*<ControlRowConfigModal*/}
      {/*  visible={configModalVisible}*/}
      {/*  onCloseClick={() => setConfigModalVisible(false)}*/}
      {/*  row={row}*/}
      {/*  onChange={onChange}*/}
      {/*/>*/}
    </Box>
  );
};
