import React from 'react';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import Dnd from '../../components/DND';
import { translate } from '../../shared/translate';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import { Icon } from '../../components/IconRender';
import FormRow from '../../components/FormRow';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import HashTagsArrayField from '../../components/FormPageMaker/Fields/HashTagsArrayField';
import { ReactComponent as IconDragVertical } from '../../assets/icon/drag-vertical.svg';
import TrashButton from '../../components/TrashButton';

const ModifiersField = ({ loading, disabled, onChange = (v) => {}, value = [] }) => {
  return (
    <StyledConfigList>
      <Dnd
        items={value}
        onChange={onChange}
        direction={'column'}
        disabled={disabled}
        renderItem={(modifier, i, { nonDraggableProps }) => {
          return (
            <Box mx={1.5} mt={i === 0 ? 3 : 0}>
              <Grid container spacing={2}>
                <Grid
                  item
                  style={{
                    cursor: 'pointer',
                    marginTop: i === 0 ? 30 : 5,
                  }}
                >
                  <Icon icon={IconDragVertical} type={'svg'} />
                </Grid>
                <Grid item xs={true} {...nonDraggableProps}>
                  <ModifierField
                    showHeader={i === 0}
                    value={modifier}
                    disabled={disabled}
                    onChange={(m) => {
                      onChange(value.map((v, j) => (i === j ? m : v)));
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: (i === 0 ? 30 : 5) - 2,
                  }}
                >
                  <TrashButton
                    size={'small'}
                    disabled={disabled}
                    onClick={() => {
                      onChange(value.filter((v, j) => i !== j));
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        }}
      >
        <AddButton
          disabled={disabled}
          onClick={() => {
            onChange(value.concat({ name: '', options: [], min: 0, max: 1 }));
          }}
          title={translate.create}
        />
      </Dnd>
    </StyledConfigList>
  );
};

export default ModifiersField;

const ModifierField = ({ showHeader = true, disabled, onChange = (v) => {}, value = {} }) => {
  return (
    <Grid container spacing={2}>
      <FormRow title={showHeader && translate.option_name} required={true} md={4} xs dense>
        <InputField
          required={true}
          value={value.name}
          disabled={disabled}
          onChange={(e) => onChange({ ...value, name: e?.target?.value })}
        />
      </FormRow>
      <FormRow title={showHeader && translate.options} required={true} md={4} xs dense>
        <HashTagsArrayField
          hideHash={true}
          disabled={disabled}
          value={value?.options?.map((opt) => opt.name) ?? []}
          onChange={(names) => {
            onChange({
              ...value,
              options: names.map((name) => {
                return value?.options?.find((opt) => opt?.name === name) || { name, priceAdjustment: 0, active: true };
              }),
            });
          }}
        />
      </FormRow>
      <FormRow title={showHeader && translate.selection_opt} md={4} xs required={true} dense>
        <Grid container spacing={1}>
          <Grid item xs>
            <InputField
              required={true}
              type={'number'}
              value={value?.max}
              onChange={(e) => onChange({ ...value, max: e?.target?.value })}
              inputProps={{ step: 1, min: 0 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{translate.maximum}</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs>
            <InputField
              required={true}
              type={'number'}
              value={value?.min}
              onChange={(e) => onChange({ ...value, min: e?.target?.value })}
              inputProps={{ step: 1, min: 0 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{translate.minimum}</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </FormRow>
    </Grid>
  );
};
