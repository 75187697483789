import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { extractImageFromHTML, extractTextFromHTML, getImageSize, JSONParseSafely } from 'shared';
import TextClamp from 'react-string-clamp';
import RatioRespondingMedia from '../RatioRespondingMedia';
import _ from 'lodash';

export default ({ loading, page, children, height, resizeMode = 'contain', ratio }) => {
  const [autoRatio, setAutoRatio] = useState(ratio);
  const { name, body: _body } = page || {},
    body = _.flatten(JSONParseSafely(_body, [])),
    flattenItems = body
      .reduce((reducer, row) => {
        const { items = [], ..._row } = row || {};
        return reducer.concat([_row, ...items.map((col) => (col || {}).item)]);
      }, [])
      .filter((_) => _);

  const images = extractImages(flattenItems);
  const [text] = extractText(flattenItems);

  useEffect(() => {
    if (images[0] && !height) {
      if (ratio) setAutoRatio(ratio);
      else getImageSize(images[0]).then(({ width, height }) => setAutoRatio(height / width));
    }
  }, [images[0]]);

  return (
    <Box display={'flex'} flexDirection={'column'} height={height}>
      <RatioRespondingMedia
        resizeMode={resizeMode}
        ratio={+height ? undefined : autoRatio}
        src={images[0] || require('assets/gray.jpg')}
      >
        {children}
      </RatioRespondingMedia>
      {loading ? <Skeleton /> : !!name && <Typography variant={'h5'}>{name}</Typography>}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography>
          <TextClamp text={text || ''} lines={2} />
        </Typography>
      )}
    </Box>
  );
};

export function extractImages(items) {
  return items
    .map(({ _type, src, html }) => {
      switch (_type) {
        case 'html':
          return extractImageFromHTML(html);
        case 'image':
          return src;
        default:
          return false;
      }
    })
    .filter((_) => _);
}

export function extractText(items) {
  return items
    .map(({ _type, html }) => {
      switch (_type) {
        case 'html':
          return extractTextFromHTML(html);
        default:
          return '';
      }
    })
    .filter((_) => _);
}
