import { gql } from '@apollo/client';

export const FRAGMENT_MESSAGE = gql`
  fragment FRAGMENT_MESSAGE on ChannelMessage {
    id
    type
    content
    pin
    read(playerId: $playerId)
    createdAt
    sender {
      id
      name
      email
    }
    channel {
      id
      code
      type
    }
    channelPlayerId
    channelType
    process {
      id
      updatedAt
      createdAt
      status
      output
    }
  }
`;

export const GET_QUERY = gql`
  query(
    $id: ID!
    $playerId: String!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: ChannelMessageFilterInput
  ) {
    me {
      ... on User {
        id
        email
      }
    }
    node(id: $id) {
      id
      ... on CompanyShop {
        channels(limits: 1, filter: { type: { operator: IN, value: ["SYSTEM"] } }) {
          nodes {
            id
            messages(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
              totalCount
              nextCursor
              nodes {
                ...FRAGMENT_MESSAGE
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_MESSAGE}
`;
