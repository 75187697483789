import React, { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
const fs = require('fs');

const PrintLabelPDFViewer = ({ urls }) => {
  const [mergedPdfUrl, setMergedPdfUrl] = useState();

  useEffect(() => {
    async function mergePDFs() {
      const mergedPdf = await PDFDocument.create();

      for (const url of urls) {
        const pdfBytes = await fetchPdfBytes(url);
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      const mergedPdfBytes = await mergedPdf.save();
      // Convert the merged PDF bytes to a Blob
      const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

      // Create a URL for the Blob
      const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);

      setMergedPdfUrl(mergedPdfUrl);
    }

    // Function to fetch PDF bytes from a URL
    async function fetchPdfBytes(url) {
      const response = await fetch(url);
      const pdfBytes = await response.arrayBuffer();
      return pdfBytes;
    }

    mergePDFs();
  }, [urls]);

  if (mergedPdfUrl) return <iframe height="100%" src={mergedPdfUrl} title="pdf-viewer" width="100%"></iframe>;
  return null;
};

export default PrintLabelPDFViewer;
