import React from 'react';
import MagicVariantTable from './MagicVariantTable';
import FormRow from '../../../components/FormRow';
import { translate } from '../../../shared/translate';
import { Card, Grid, Typography } from '@material-ui/core';
import { DragDropContext } from 'react-beautiful-dnd';
import { hasCombinations } from '../hasCombinations';
import MagicMediasField from '../../../components/FormPageMaker/Fields/MagicMediasField';
import TooltipContent from '../../../components/TooltipContent';

const MagicImagesCard = ({ loading, values, setFieldValue, isSubmitting }) => {
  const { variations = [], medias } = values || {};

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { source, destination } = result;
        if (!result.destination) return;

        let splice = [];
        if (source.droppableId === 'product-medias') {
          splice = medias.splice(source.index, 1);
          setFieldValue('medias', medias);
        } else {
          const variation = variations.find((v) => source.droppableId === (v || {}).sku) || {};
          const { medias = [] } = variation;
          splice = medias.splice(source.index, 1);
          variation.medias = medias;
          setFieldValue('variations', variations);
        }

        const [media] = splice || [];
        if (destination.droppableId === 'product-medias') {
          medias.splice(destination.index, 0, media);
          setFieldValue('medias', medias);
        } else {
          const variation = variations.find((v) => destination.droppableId === (v || {}).sku) || {};
          const { medias = [] } = variation;
          medias.splice(destination.index, 0, media);
          variation.medias = medias;
          setFieldValue('variations', variations);
        }
      }}
    >
      <Grid container spacing={3}>
        <FormRow
          title={translate.product_image}
          tooltip={
            <TooltipContent
              src={require('../../../assets/product_preview.png')}
              points={[
                <Typography variant="body2">
                  {translate.product_image_remark || '建議產品圖片尺寸為 1000px : 1000px ( 1:1 比例)'}
                </Typography>,
              ]}
            />
          }
        >
          <MagicMediasField
            loading={loading}
            value={medias}
            onChange={(v) => setFieldValue('medias', v)}
            disabled={isSubmitting}
          />
        </FormRow>
        <FormRow
          title={translate.variant_image}
          tooltip={
            <TooltipContent
              src={require('../../../assets/product_preview.png')}
              points={[
                <Typography variant="body2">
                  {translate.product_image_remark || '建議產品圖片尺寸為 1000px : 1000px ( 1:1 比例)'}
                </Typography>,
              ]}
            />
          }
        >
          <Card variant={'outlined'}>
            <MagicVariantTable
              loading={loading}
              variations={variations}
              disabled={isSubmitting}
              onChange={(v) => setFieldValue('variations', v)}
            />
          </Card>
        </FormRow>
      </Grid>
    </DragDropContext>
  );
};

export default MagicImagesCard;
