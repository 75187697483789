import React from 'react';
import Media from '../../../../../components/Media';
import { translate } from '../../../../../shared/translate';
import ImageField from '../../../../../components/FormPageMaker/Fields/ImageField';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../../../../components/FormPageMaker/Fields/SelectField';
import { ReactComponent as icon } from '../../../../../assets/icon/design menu bar_image.svg';
import { Icon } from '../../../../../components/IconRender';
import { Box, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import HighlightFormRow from '../../HighlightFormRow';
import RatioBox from '../../../../../components/RatioBox';
import { getImageSize } from '../../../../../shared';

export default {
  label: translate.image,
  defaultHeight: 0,
  icon: <Icon icon={icon} type={'svg'} viewBox={'0 0 40 40'} />,
  preview: ({ data, setEditData, height }) => {
    const { src, resizeMode = 'contain', href, title, subtitle, content, ratio } = data || {};
    const media = (
      <Media
        src={src}
        style={{
          height: ratio ? '100%' : height,
          width: '100%',
        }}
        onClick={() => setEditData(data)}
        resizeMode={resizeMode}
      >
        <Box
          p={2}
          width={'100%'}
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {!!title && <Typography variant={'h6'}>{title}</Typography>}
          {!!subtitle && <Typography>{subtitle}</Typography>}
          {!!content && (
            <Typography variant={'body2'} style={{ whiteSpace: 'pre-wrap' }}>
              {content}
            </Typography>
          )}
        </Box>
      </Media>
    );

    const core = ratio ? (
      <RatioBox ratio={ratio} width={!height ? '100%' : undefined} height={height ? height : undefined}>
        {media}
      </RatioBox>
    ) : (
      media
    );

    if (href) return <Tooltip title={href}>{core}</Tooltip>;
    return core;
  },
  edit: ({ value, onChange = (_) => _ }) => {
    const { src, resizeMode = 'contain', href, title, subtitle, content, ratio } = value || {};
    function update(updates = {}) {
      onChange({
        ...value,
        ...updates,
      });
    }

    return (
      <>
        <HighlightFormRow highlight={value.src !== undefined} title={translate.image}>
          <ImageField
            value={src}
            onChange={(v) => {
              getImageSize(v).then((size) => {
                const ratio = size.height / size.width;
                update({
                  ratio: +ratio.toFixed(3),
                  src: v,
                });
              });
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.resizeMode !== undefined} title={translate.image_resize_mode}>
          <SelectField
            value={resizeMode}
            onChange={(e) => update({ resizeMode: e.target.value })}
            options={[
              { label: 'Contain', value: 'contain' },
              { label: 'Cover', value: 'cover' },
            ]}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.ratio !== undefined} title={'Ratio'}>
          <InputField
            type={'number'}
            value={ratio}
            onChange={(e) => update({ ratio: e.target.value })}
            onBlur={(e) => update({ ratio: e.target.value ? +e.target.value : undefined })}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: <InputAdornment position={'start'}>1 :</InputAdornment>,
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.title !== undefined} title={translate.title}>
          <InputField value={title} onChange={(e) => update({ title: e.target.value })} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.subtitle !== undefined} title={translate.subtitle}>
          <InputField value={subtitle} onChange={(e) => update({ subtitle: e.target.value })} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.content !== undefined} title={translate.content}>
          <InputField multiline={true} rows={3} value={content} onChange={(e) => update({ content: e.target.value })} />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.href !== undefined} title={translate.href}>
          <InputField value={href} placeholder={'/pages/aboutus'} onChange={(e) => update({ href: e.target.value })} />
        </HighlightFormRow>
      </>
    );
  },
};
