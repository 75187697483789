import React, { useState } from 'react';
import { Chip, makeStyles, Box, Typography, useTheme, Grid } from '@material-ui/core';
import _ from 'lodash';
import LSkeleton from '../../LSkeleton';
import Dnd from '../../DND';
import { focusBorderLineColor } from '../../../theme';

const useStyles = makeStyles((theme) => ({
  commonWrap: {
    borderStyle: 'solid',
    borderRadius: 10,
    background: '#ffffff',
    outline: 'none',
  },
  wrapper: {
    padding: `6.5px ${theme.spacing(1.8)}px`,
    borderWidth: 1,
    borderColor: '#cbcbcb',
  },
  wrapperHover: {
    padding: `6.5px ${theme.spacing(1.8)}px`,
    borderWidth: 1,
    borderColor: '#000',
  },
  wrapperFocus: {
    padding: `5.5px ${theme.spacing(1.7)}px`,
    borderWidth: 2,
    borderColor: focusBorderLineColor,
  },
}));

export default ({
  loading,
  value = [],
  placeholder,
  onChange = (_) => _,
  disabled,
  className = '',
  maxLength = 80,
  isOneRow = false,
  hideHash = false,
}) => {
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const handleKeyPress = (e) => {
    const newValue = e.target.value;

    if (e.keyCode === 13) {
      e.preventDefault();
      if (newValue === '') return;

      let list = [newValue.trim()].filter((_) => _);
      if (_.intersection(value, list).length > 0) return;

      e.target.value = '';

      onChange(_.uniq(_.concat(value, list).filter((_) => _)));
    }
  };

  // Convert the string array into an array of individual tags
  // const array = value.flatMap((itemTag) => itemTag.split(' '));

  if (loading) return <LSkeleton height={30} />;

  return (
    <Box
      className={`${className} ${classes.commonWrap} ${focus ? classes.wrapperFocus : classes.wrapper} ${
        hover && classes.wrapperHover
      }`}
    >
      {isOneRow ? (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            {value.length > 0 && (
              <Box>
                <Dnd
                  items={value}
                  renderItem={(id, index) =>
                    /::/.test(id) ? (
                      <SplitTag
                        group={id.split('::')[0] || ''}
                        tag={id.split('::')[1] || ''}
                        onClick={() => {
                          value.splice(index, 1);
                          onChange(value.filter((_) => _));
                        }}
                        disabled={disabled}
                        hideHash={hideHash}
                      />
                    ) : (
                      <SimpleTag
                        tag={id}
                        onClick={() => {
                          value.splice(index, 1);
                          onChange(value.filter((_) => _));
                        }}
                        disabled={disabled}
                        hideHash={hideHash}
                      />
                    )
                  }
                  onChange={(value) => onChange(value.filter((_) => _))}
                  disabled={disabled}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={true}>
            <input
              disabled={disabled}
              className={`border-0 shadow-none p-0 w-100`}
              style={{ outline: 'none', fontSize: '1rem' }}
              type="text"
              maxLength={maxLength}
              onClick={() => setFocus(true)}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onBlur={(e) => {
                setFocus(false);
                const newValue = e.target.value;
                if (newValue === '') return;

                let list = _.filter(
                  _.split(newValue, ',').reduce((prev, value) => {
                    return [...prev, value.trim()];
                  }, []),
                  (value) => value !== '',
                );

                if (_.intersection(value, list).length > 0) return;

                e.target.value = '';

                onChange(_.uniq(_.concat(value, list).filter((_) => _)));
              }}
              onKeyDown={handleKeyPress}
              placeholder={placeholder}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          {value.length > 0 && (
            <Box mb={1}>
              <Dnd
                items={value}
                renderItem={(id, index) =>
                  /::/.test(id) ? (
                    <SplitTag
                      group={id.split('::')[0] || ''}
                      tag={id.split('::')[1] || ''}
                      onClick={() => {
                        // array.splice(index, 1);
                        // onChange(array.filter((_) => _));
                        value.splice(index, 1);
                        onChange(value.filter((_) => _));
                      }}
                      disabled={disabled}
                      hideHash={hideHash}
                    />
                  ) : (
                    <SimpleTag
                      tag={id}
                      onClick={() => {
                        // array.splice(index, 1);
                        // onChange(array.filter((_) => _));
                        value.splice(index, 1);
                        onChange(value.filter((_) => _));
                      }}
                      disabled={disabled}
                      hideHash={hideHash}
                    />
                  )
                }
                // onChange={(array) => onChange(array.filter((_) => _))}
                onChange={(value) => onChange(value.filter((_) => _))}
                disabled={disabled}
              />
            </Box>
          )}
          <input
            disabled={disabled}
            className={`border-0 shadow-none p-0 w-100`}
            style={{ outline: 'none', fontSize: '1rem' }}
            type="text"
            maxLength={maxLength}
            onClick={() => setFocus(true)}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onBlur={(e) => {
              setFocus(false);
              const newValue = e.target.value;
              if (newValue === '') return;

              let list = _.filter(
                _.split(newValue, ',').reduce((prev, value) => {
                  return [...prev, value.trim()];
                }, []),
                (value) => value !== '',
              );

              if (_.intersection(value, list).length > 0) return;

              e.target.value = '';

              onChange(_.uniq(_.concat(value, list).filter((_) => _)));
            }}
            onKeyDown={handleKeyPress}
            placeholder={placeholder}
          />
        </>
      )}
    </Box>
  );
};

const SimpleTag = ({ tag, onClick, disabled, hideHash }) => (
  <Chip
    size={'small'}
    color={'secondary'}
    label={hideHash ? tag : `# ${tag}`}
    onClick={!disabled && onClick}
    onDelete={!disabled && onClick}
    style={{ fontSize: '1rem' }}
  />
);

const SplitTag = ({ group, tag, onClick, disabled, hideHash }) => {
  const theme = useTheme();

  return (
    <Chip
      size={'small'}
      color={'secondary'}
      style={{ overflow: 'hidden', fontSize: '1rem' }}
      label={
        <>
          <div
            style={{
              height: 24,
              display: 'inline-block',
              padding: `2px ${theme.spacing(1)}px`,
              backgroundColor: theme.palette.secondary.light,
              verticalAlign: 'middle',
              marginRight: theme.spacing(1),
              marginLeft: -theme.spacing(1),
              color: 'white',
            }}
          >
            <Typography variant={'caption'} component={'span'} style={{ color: 'white' }}>
              {group}
            </Typography>
          </div>
          <span style={{ verticalAlign: 'text-top' }}>{hideHash ? tag : `# ${tag}`}</span>
        </>
      }
      onClick={!disabled && onClick}
      onDelete={!disabled && onClick}
    />
  );
};
