import React from 'react';
import { translate } from '../../../shared/translate';
import { Typography } from '@material-ui/core';

const InventoryRecordReference = ({ reference, referenceSourceNo }) => {
  // reference only, no referenceSourceNoType
  if (!referenceSourceNo) {
    return <Typography>{translate.product_link || '編輯產品'}</Typography>;
  }

  // reference + referenceSourceNo
  const referenceArray = reference?.split(',') ?? [];

  const referenceSourceNoArray = referenceSourceNo.split('#');
  const referenceSourceNoType = referenceSourceNoArray[0];
  const referenceSourceNoId = referenceSourceNoArray[1];

  const typeNamePath = [
    { ShopOrders: { path: 'orders', name: translate.orders || '訂單' } }, //訂單管理 >訂單總覽 //XXXXX
    { ShopReturnNotes: { path: 'returns', name: translate.return_form || '退貨' } }, //訂單管理 >退貨總覽 //RNXXXXX
    { CompanyReceivePurchases: { path: 'receive_purchase', name: translate.receive_purchase || '進貨' } }, //倉庫管理 >進貨 //RPXXXXX
    { CompanyStockTransfers: { path: 'transfers', name: translate.transfer_product || '調貨' } }, //倉庫管理 >庫存轉倉 //TFXXXXX
    { CompanyStocktakes: { path: 'stock_take', name: translate.stocktake || '盤點' } }, //倉庫管理 > 庫存盤點 //STXXXXX
    { CompanyStockAdjustments: { path: 'stock_out', name: translate.stock_out || '扣貨' } }, //倉庫管理 >扣貨 //SAXXXXX
    { OrderDeliverynotes: { path: 'delivery_notes', name: translate.product_delivery || '送貨' } }, //訂單管理 >送貨單
  ];

  for (const ref of referenceArray) {
    const [referenceType, referenceId] = ref.split('#');

    if (referenceType === referenceSourceNoType) {
      const pathObject = typeNamePath.find((typeName) => Object.keys(typeName)[0] === referenceSourceNoType);
      const pathValue = Object.values(pathObject)[0];

      return (
        <>
          {pathValue.name}：
          <a href={`/${pathValue.path}/${referenceId}`} target="_blank" rel="noreferrer">
            {referenceSourceNoId}
          </a>
        </>
      );
    }

    return <></>;
  }
};

export default InventoryRecordReference;
