import React, { useState } from 'react';
import SystemButton from '../../components/SystemButton';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import { EXPORT_QUERY } from '../item_Form/query';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { CircularProgress } from '@material-ui/core';
import { Icon } from '../../components/IconRender';

const ExportButton = ({ shopForm }) => {
  const [loading, setLoading] = useState(false);
  return (
    <SystemButton
      color={'primary'}
      startIcon={loading ? <CircularProgress color={'white'} size={15} /> : <Icon icon={'faTable'} />}
      onClick={async (e) => {
        e.preventDefault();
        if (loading) return;
        try {
          setLoading(true);
          await client.mutate({
            mutation: EXPORT_QUERY,
            variables: {
              id: shopForm?.id,
            },
          });
          toast.success(translate.export_tips);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.records_export}
    </SystemButton>
  );
};

export default ExportButton;
