import React from 'react';
import SelectCombo from './index';

const SelectComboValue = ({ options, onChange = (_) => _, value = [], multiple, ...props }) => (
  <SelectCombo
    multiple={multiple}
    value={
      multiple
        ? options?.filter((opt) => value.includes(opt.value)) ?? []
        : options?.find((opt) => opt.value === value) ?? undefined
    }
    onChange={(v) => {
      if (multiple) onChange(v?.map((v) => v.value) ?? []);
      else onChange(v?.value);
    }}
    options={options}
    {...props}
  />
);

export default SelectComboValue;
