import { gql } from '@apollo/client';

export const FRAGMENT_COUPON = gql`
  fragment FRAGMENT_COUPON on CompanyCoupon {
    id
    createdAt
    updatedAt
    validAt
    validThru
    publishAt
    publishThru
    handle
    name
    remark
    active
    display
    publishLimit
    usage
    isRedeemable
    sortIndex
    triggers {
      triggerType
      triggerId
      triggerIds
      triggerValueType
      triggerValue
    }
    actions {
      actionType
      actionId
      actionIds
      actionValueType
      actionValue
    }
  }
`;

export const GET_QUERY = gql`
  query (
    $id: ID!
    $query: String
    $cursor: Int
    $limits: Int
    $filter: CompanyCouponFilterInput
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        id
        coupons(cursor: $cursor, limits: $limits, query: $query, filter: $filter, sortBy: $sortBy) {
          totalCount
          nextCursor
          nodes {
            ...FRAGMENT_COUPON
          }
        }
      }
    }
  }
  ${FRAGMENT_COUPON}
`;

export const IMPORT_QUERY = gql`
  mutation ($id: ID!, $shopId: ID!, $url: AWSURL!) {
    userCouponsImport(id: $id, shopId: $shopId, url: $url) {
      id
      status
    }
  }
`;
