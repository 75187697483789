import React from 'react';
import ReactPlayer from 'react-player';
import { ButtonBase } from '@material-ui/core';

export default ({
  disabled,
  src = '',
  resizeMode = 'contain',
  children,
  style = {},
  placeholderColor = '#ddd',
  onClick,
  ...props
}) => {
  const { width = 200, height = 200 } = style;
  return (
    <ButtonBase
      component={'a'}
      onClick={(e) => {
        e.preventDefault();
        if (!!onClick) onClick(src);
        else if (!!src) window.open(src, '_blank');
      }}
      disabled={disabled}
      style={{
        display: 'inline-block',
        width,
        height,
        background: `url("${src}") no-repeat center center / ${resizeMode} ${placeholderColor}`,
        cursor: 'pointer',
        ...style,
      }}
      {...props}
    >
      <>
        <ReactPlayer
          url={src}
          width={width}
          height={height}
          controls={false}
          muted={true}
          playing={false}
          style={{ width, height, overflow: 'hidden', pointerEvents: 'none' }}
        />
        {!!children && (
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>{children}</div>
          </div>
        )}
      </>
    </ButtonBase>
  );
};
