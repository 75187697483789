import React from 'react';
import { TableRow } from '@material-ui/core';

const ConnectionTableRowWrapper = ({ hover, onClick, children }) => {
  return (
    <TableRow
      hover={hover}
      style={{
        cursor: onClick ? 'pointer' : undefined,
      }}
      onClick={onClick}
    >
      {children}
    </TableRow>
  );
};

export default ConnectionTableRowWrapper;
