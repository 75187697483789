import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import ConnectionCardHeader from '../../../components/ConnectionPageMaker/ConnectionCardHeader';
import { translate } from '../../../shared/translate';
import { GET_QUERY } from './query';
import { theme } from '../../../theme';
import FormHeader from '../../item_SalePerformance/FormHeader';

class SalesRecordsList extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_sales_records,
    stickyHeader: true,
    fields: [
      {
        title: translate.date,
        fieldName: 'createdAt',
        type: 'datetime',
      },
      {
        title: translate.shop_name,
        value: (row) => row.shop.name,
      },
      {
        title: translate.order_number,
        fieldName: 'referenceNo',
      },
      {
        title: translate.amount,
        fieldName: 'total',
        type: 'price',
      },
    ],
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return {
      id,
    };
  }

  getFilter(...args) {
    const { startedAt, startedThru } = this.getQueryParams();
    const superFilter = super.getFilter(...args);

    return {
      ...superFilter,
      createdAt: {
        operator: 'BETWEEN',
        value: [startedAt, startedThru],
      },
      active: { operator: 'IN', value: ['true'] },
    };
  }

  getQuery() {
    return GET_QUERY;
  }

  getData(data) {
    return data.node.salespersonOrders;
  }

  renderTable(...args) {
    return (
      <>
        <Divider style={{ height: 2, backgroundColor: '#999' }} />
        {super.renderTable(...args)}
      </>
    );
  }

  renderCardHeader(context) {
    return (
      <>
        <ConnectionCardHeader style={{ paddingBottom: 0, marginBottom: -theme.spacing(1) }}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <Typography variant={'h6'} color={'secondary'}>
                {translate.all_sales_records}
              </Typography>
            </Grid>
          </Grid>
        </ConnectionCardHeader>
        {super.renderCardHeader(context)}
      </>
    );
  }
  renderExtras() {
    return <FormHeader />;
  }
}

export default SalesRecordsList;
