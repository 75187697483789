import React from 'react';
import FormPageMaker from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { GET_QUERY, UPDATE_QUERY, CREATE_QUERY, REMOVE_QUERY } from './query';
import { convertMetaArray, convertMetaObject, JSONParseSafely } from '../../shared';
import ConfigField from './ConfigField';
import { Box, Grid } from '@material-ui/core';
import SourceDataButton from './SourceDataButton';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    isDailyAvaActive: false,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.pos_device,
        cards: [
          {
            fields: [
              {
                label: translate.device_name,
                type: 'text',
                name: 'name',
                maxLength: 50,
                required: true,
                md: 6,
              },
              {
                label: translate.device_id,
                type: 'text',
                name: 'deviceId',
                maxLength: 100,
                required: true,
                disabled: !!this.state?.id,
                md: 6,
              },
              {
                label: translate.activate_checkbox,
                type: 'switch',
                name: 'active',
              },
              {
                md: 12,
                label: translate.device_description,
                type: 'textarea',
                name: 'description',
                maxLength: 500,
              },
            ],
          },
        ],
      },
      {
        name: translate.device_config,
        cards: [
          {
            fields: [
              {
                label: ({ values, disabled, setFieldValue }) => (
                  <Grid container justifyContent={'space-between'} alignItems={'flex-end'} spacing={3}>
                    <Grid item>{translate.device_config}</Grid>
                    <Grid item>
                      <SourceDataButton
                        disabled={disabled}
                        value={values.configs}
                        onChange={(v) => {
                          setFieldValue('configs', v);
                        }}
                      />
                    </Grid>
                  </Grid>
                ),
                render: ({ values, setFieldValue, disabled, loading }) => (
                  <Box mt={2}>
                    <ConfigField
                      loading={loading}
                      disabled={disabled}
                      value={values.configs}
                      onChange={(v) => setFieldValue('configs', v)}
                    />
                  </Box>
                ),
              },
            ],
          },
        ],
      },
      {
        name: `${translate.device_config} (${translate.default})`,
        cards: [
          {
            fields: [
              {
                label: ({ values, disabled, setFieldValue }) => (
                  <Grid container justifyContent={'space-between'} alignItems={'flex-end'} spacing={3}>
                    <Grid item>
                      {translate.device_config} ({translate.default})
                    </Grid>
                    <Grid item>
                      <SourceDataButton
                        disabled={disabled}
                        value={values.defaultConfigs}
                        onChange={(v) => {
                          setFieldValue('defaultConfigs', v);
                        }}
                      />
                    </Grid>
                  </Grid>
                ),
                render: ({ values, setFieldValue, disabled, loading }) => (
                  <Box mt={2}>
                    <ConfigField
                      loading={loading}
                      disabled={disabled}
                      value={values.defaultConfigs}
                      onChange={(v) => setFieldValue('defaultConfigs', v)}
                    />
                  </Box>
                ),
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;

    if (id) {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input: {
            active: values.active,
            configs: JSON.stringify(values.configs),
            defaultConfigs: JSON.stringify(values.defaultConfigs),
            description: values.description,
            metadata: convertMetaArray(values.metadata),
            name: values.name,
          },
        },
      });
      return true;
    } else {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            active: values.active,
            configs: JSON.stringify(values.configs),
            defaultConfigs: JSON.stringify(values.defaultConfigs),
            description: values.description,
            deviceId: values.deviceId,
            metadata: convertMetaArray(values.metadata),
            name: values.name,
            shopId: localStorage.getItem('shopId'),
          },
        },
      });
      return true;
    }
  };

  getInitialData = (data) => {
    const { node } = data || {};
    return {
      name: node?.name || '',
      active: node?.active ?? true,
      configs: JSONParseSafely(node?.configs, {}),
      defaultConfigs: JSONParseSafely(node?.defaultConfigs, {}),
      description: node?.description || '',
      deviceId: node?.deviceId || '',
      metadata: convertMetaObject(node?.metadata),
    };
  };
}
