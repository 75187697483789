import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import moment from 'moment/moment';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';

const ServiceBundleItemContent = ({ item }) => {
  const { serviceBundle, remark } = item || {};
  return (
    <div>
      <Typography>{serviceBundle?.serviceBundle?.name}</Typography>
      {serviceBundle?.services?.map((checkoutService, i) => {
        const { service, appointment, location, options = [] } = checkoutService ?? {};
        return (
          <Fragment key={i}>
            <Typography variant={'body2'}>{service?.name}</Typography>
            <Box pl={2}>
              <Typography style={{ fontSize: '0.875rem' }}>
                <b>{translate.location}: </b>
                {location?.name}
              </Typography>
              <Typography style={{ fontSize: '0.875rem' }}>
                <b>{translate.time}: </b>
                {moment(appointment?.startedAt).format('DD MMM YYYY HH:mm')} -{' '}
                {moment(appointment?.startedThru).format('HH:mm A')}
              </Typography>
              <VariationCombinationDisplay
                typographyVariant={'body2'}
                remark={remark}
                direction={'column'}
                parseColorCode={false}
              />
              <hr style={{ borderStyle: 'dashed' }} />
            </Box>
          </Fragment>
        );
      })}
    </div>
  );
};

export default ServiceBundleItemContent;
