import React, { useState } from 'react';
import { Checkbox, IconButton, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import _ from 'lodash';
import { Icon } from '../../../../components/IconRender';
import { ReactComponent as IconArrowUp } from '../../../../assets/icon/arrow-up.svg';
import { ReactComponent as IconArrowDown } from '../../../../assets/icon/arrow-down.svg';
import ProductRow from './ProductRow';

const CollectionRow = ({
  index,
  item,
  defaultBoolean,
  defaultProductMode,
  value, // [true, { id: "", selectionData: { mode: "INCLUDE", data: [] } }]
  onChange = (_) => _,
}) => {
  const theme = useTheme();

  const { name, active, products, id } = item;
  const [open, setOpen] = useState(false);

  const isSelected = value?.[0] ?? defaultBoolean;

  function handleSelected() {
    onChange([
      !isSelected,
      {
        id,
        selectionData: { mode: isSelected ? 'INCLUDE' : 'EXCLUDE', data: [] },
      },
    ]);
  }

  return (
    <>
      <TableRow
        key={index}
        hover
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        selected={isSelected}
        style={{ backgroundColor: 'transparent' }}
      >
        <TableCell style={{ padding: '0 12px' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Icon type={'svg'} icon={IconArrowUp} viewBox={'0 0 16 16'} />
            ) : (
              <Icon type={'svg'} icon={IconArrowDown} viewBox={'0 0 16 16'} />
            )}
          </IconButton>
        </TableCell>
        <TableCell onClick={handleSelected} padding="checkbox" style={{ width: 40 }}>
          <Checkbox color="primary" checked={isSelected} style={{ padding: 0 }} />
        </TableCell>
        <TableCell
          colSpan={4}
          onClick={handleSelected}
          component="th"
          scope="row"
          padding="none"
          style={{ height: 44, cursor: 'pointer' }}
        >
          <Typography style={{ color: active ? '#000' : theme.palette.error.light }}>
            {name} ({products.totalCount})
          </Typography>
        </TableCell>
      </TableRow>
      {open && (
        <>
          {products.nodes?.map((product, i) => {
            // [true, { id: "", selectionData: { mode: "INCLUDE", data: [] } }]
            const selectionData = value?.[1] ?? {
              id,
              selectionData: { mode: value?.[1]?.selectionData?.mode ?? defaultProductMode, data: [] },
            };
            const { mode: productMode, data: productData } = selectionData.selectionData;

            const isProductSelected =
              productMode === 'INCLUDE' ? productData?.includes(product.id) : !productData?.includes(product.id);

            return (
              <ProductRow
                key={i}
                product={product}
                value={isProductSelected}
                onChange={(v) => {
                  if (v) {
                    if (productMode === 'INCLUDE') {
                      onChange([
                        productData?.length + 1 === products.totalCount ? true : false,
                        {
                          id,
                          selectionData: {
                            mode: productMode,
                            data: _.uniq(productData?.concat(product.id)),
                          },
                        },
                      ]);
                    } else {
                      onChange([
                        !(productData?.length - 1) ? true : false,
                        {
                          id,
                          selectionData: {
                            mode: productMode,
                            data: _.uniq(productData?.filter((id) => id !== product.id)),
                          },
                        },
                      ]);
                    }
                  } else {
                    if (productMode === 'INCLUDE') {
                      onChange([
                        false,
                        {
                          id,
                          selectionData: {
                            mode: productMode,
                            data: _.uniq(productData?.filter((id) => id !== product.id)),
                          },
                        },
                      ]);
                    } else {
                      onChange([
                        false,
                        {
                          id,
                          selectionData: {
                            mode: productData?.length + 1 === products.totalCount ? 'INCLUDE' : productMode,
                            data:
                              productData?.length + 1 === products.totalCount
                                ? []
                                : _.uniq(productData?.concat(product.id)),
                          },
                        },
                      ]);
                    }
                  }
                }}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default CollectionRow;
