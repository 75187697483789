import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';

const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      id
      ... on ShopVenue {
        tables(limits: $limits, cursor: $cursor) {
          nextCursor
          totalCount
          nodes {
            id
            name
            shapeData
            status
            metadata {
              key
              value
            }
          }
        }
      }
    }
  }
`;

export default async function (source) {
  return await infinityFetch({
    fetchPolicy: 'network-only',
    query: GET_QUERY,
    variables: {
      id: source?.id,
    },
    getConnection: (data) => data?.node?.tables,
  });
}
