import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import SelectComboShop from '../../components/SelectCombo/SelectComboShop';
import { parseConnection } from '../../shared';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.inventory_management,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                maxLength: 25,
                name: 'name',
                required: true,
                md: 6,
                block: true,
              },
              {
                label: translate.store_warehouse_mapping || '關聯店舖',
                render: ({ loading, disabled, values, setFieldValue }) => {
                  return (
                    <SelectComboShop
                      multiple
                      loading={loading}
                      disabled={disabled}
                      value={values?.shops || []}
                      onChange={(shops) => setFieldValue('shops', shops)}
                    />
                  );
                },
                md: 6,
                block: true,
              },
              {
                label: translate.sort_index,
                type: 'number',
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
                md: 6,
                block: true,
              },
              {
                label: translate.activate_checkbox,
                name: 'active',
                type: 'switch',
              },
            ],
          },
        ],
      },
    ],
    allShop: !this.state.id,
    sku: '',
  };

  submit = async ({ id, name, sortIndex, active, shops }) => {
    const {
      gql: { submit },
    } = this.state;

    const input = {
      companyId: localStorage.getItem('companyId'),
      name,
      sortIndex,
      active,
      shopIds: shops.map((shop) => shop.id),
    };

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });

    return true;
  };

  getInitialData(data) {
    const { node } = data || {},
      { id, name = '', sortIndex = 0, active = true, updatedAt, shops: _shops = {} } = node || {};

    const shops = parseConnection(_shops).nodes;

    return {
      id,
      name,
      sortIndex,
      active,
      updatedAt,
      shops,
    };
  }
}
