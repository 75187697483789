import React, { useState } from 'react';
import { Button, Chip, Grid, Typography } from '@material-ui/core';
import LSkeleton from '../../LSkeleton';
import { translate } from '../../../shared/translate';

const ColorField = ({ loading, value, onChange = (_) => _, disabled, ...props }) => {
  const [selectedColor, setSelectedColor] = useState(undefined);

  if (loading) return <LSkeleton height={30} />;
  return (
    <Chip
      label={
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item>
            <Typography style={{ width: 70 }}>{value || translate.pick_color}</Typography>
          </Grid>
          <Grid
            item
            style={{
              pointerEvents: disabled ? 'none' : undefined,
            }}
          >
            <input
              style={{ border: 'none', backgroundColor: 'transparent', width: '25px' }}
              disabled={disabled}
              type={'color'}
              value={value || '#000000'}
              onChange={(e) => setSelectedColor(e.target.value)}
              {...props}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{
                padding: 0,
                fontSize: '1rem',
                width: '50px',
              }}
              onClick={() => {
                if (!value) {
                  onChange(selectedColor);
                } else {
                  onChange(undefined);
                }
              }}
            >
              <Typography>{!value ? translate.set : translate.reset}</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ColorField;
