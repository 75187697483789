import React from 'react';
import { translate } from '../../../shared/translate';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-5" src={require('../../../assets/soepay_logo.svg')} alt="soepay" />,
    label: 'SoePay',
    value: 'SOEPAY_POS',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'API Key',
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: 'API Token',
            type: 'text',
            name: 'metadata.apiToken',
            required: true,
            maxLength: 255,
            inputProps: {
              type: 'password',
            },
            afterChanged: (v) => {
              actions.setFieldValue('secret', v);
            },
          },
        ]}
      />
    );
  },
  type: 'PAYMENT',
};
