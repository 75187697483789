import { gql } from '@apollo/client';

export const FRAGMENT_SERVICE_LOCATION_SLOT = gql`
  fragment FRAGMENT_SERVICE_LOCATION_SLOT on ServiceLocationSlot {
    id
    createdAt
    updatedAt
    active
    capacity
    startedAt
    startedThru
    price
    recurrence
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ServiceLocationSlotFilterInput) {
    serviceLocationSlots(id: $id, cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) @client {
      nextCursor
      totalCount
      nodes {
        ...FRAGMENT_SERVICE_LOCATION_SLOT
      }
    }
  }
  ${FRAGMENT_SERVICE_LOCATION_SLOT}
`;

export const SLOT_CREATE = gql`
  mutation ($input: ServiceSlotCreateInput!) {
    serviceLocationSlotCreate(input: $input) {
      ...FRAGMENT_SERVICE_LOCATION_SLOT
    }
  }
  ${FRAGMENT_SERVICE_LOCATION_SLOT}
`;

export const SLOT_UPDATE = gql`
  mutation ($id: ID!, $input: ServiceSlotUpdateInput!, $repeat: Boolean) {
    serviceLocationSlotUpdate(id: $id, input: $input, repeat: $repeat) {
      ...FRAGMENT_SERVICE_LOCATION_SLOT
    }
  }
  ${FRAGMENT_SERVICE_LOCATION_SLOT}
`;

export const SLOT_DELETE = gql`
  mutation ($id: ID!, $repeat: Boolean) {
    serviceLocationSlotDelete(id: $id, repeat: $repeat) {
      ...FRAGMENT_SERVICE_LOCATION_SLOT
    }
  }
  ${FRAGMENT_SERVICE_LOCATION_SLOT}
`;
