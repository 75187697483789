import React, { useState } from 'react';
import { Icon } from '../../../../../components/IconRender';
import { translate } from '../../../../../shared/translate';
import { Chip, makeStyles } from '@material-ui/core';
import s3Upload from '../../../../../shared/s3Upload';
import toast from '../../../../../shared/toast';
import errorParser from '../../../../../shared/errorParser';
import HighlightFormRow from '../../HighlightFormRow';
import CheckboxField from '../../../../../components/FormPageMaker/Fields/CheckboxField';

const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: 100,
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'auto',
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default {
  label: translate.pdf || 'PDF',
  defaultHeight: 500,
  icon: <Icon icon={'faFilePdf'} />,
  preview: function PreviewFC({ data, height, setEditData }) {
    const { src, showToolbar } = data || {};
    const classes = useStyles();
    return (
      <div
        className={classes.wrapper}
        style={{
          height: height || 'initial',
          overflow: 'hidden',
        }}
        onClick={() => setEditData(data)}
      >
        {!!src && (
          <iframe
            key={`${src}-${showToolbar}`}
            src={`${src}#toolbar=${showToolbar ? 1 : 0}`}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>
    );
  },
  wideEditor: true,
  edit: function EditFC({ value, onChange }) {
    const { src, showToolbar } = value || {};
    const [uploading, setUploading] = useState(false);
    const [key, setKey] = useState(0);

    return (
      <>
        <HighlightFormRow highlight={src !== undefined} title={translate.pdf || 'PDF'}>
          {src ? (
            <Chip
              label={new URL(src).pathname.split('/').pop()}
              onDelete={() => {
                onChange({
                  ...value,
                  src: undefined,
                });
              }}
            />
          ) : (
            <input
              key={key}
              type={'file'}
              accept="*"
              disabled={uploading}
              multiple={false}
              onChange={async (e) => {
                try {
                  setUploading(true);
                  const files = e.target.files;
                  const file = files[0];
                  if (file) {
                    const s3Path = await s3Upload(file);
                    onChange({ ...value, src: s3Path });
                  }
                } catch (e) {
                  toast.error(errorParser(e));
                } finally {
                  setUploading(false);
                  setKey((key) => key + 1);
                }
              }}
            />
          )}
        </HighlightFormRow>
        <HighlightFormRow highlight={showToolbar !== undefined} title={translate.show_toolbar || '顯示工具列'}>
          <CheckboxField
            checked={showToolbar}
            onChange={(e) =>
              onChange({
                ...value,
                showToolbar: e.target.checked,
              })
            }
          />
        </HighlightFormRow>
      </>
    );
  },
};
