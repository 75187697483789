import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '../../../components/IconRender';
import useBreakPoint from '../../../components/useBreakPoint';
import OmniweDrawer from './OmniweDrawer';

export const drawerWidth = 240;
export const drawerCollapsedWidth = 55;

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#2a2b31',
    color: '#fff',
  },
  rootDetached: {
    position: 'fixed',
    left: 0,
    top: 0,
    maxHeight: '100vh',
  },
  rootDetachedBackdrop: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
  extendedMenuButton: {
    borderRadius: 0,
    color: '#747f8e',
  },
}));

const LeftSideBar = (props) => {
  const classes = useStyle();
  const breakPoint = useBreakPoint(),
    isXS = breakPoint === 'xs',
    isSM = breakPoint === 'sm';
  const [toggle, setToggle] = useState(!(localStorage.getItem('menuCollapsed') - 0 || 0));
  const [openIndex, setOpenIndex] = useState(-1);

  function onToggleChange(v) {
    setToggle(v);
    localStorage.setItem('menuCollapsed', v ? '0' : '1');
  }
  function onOpenIndexChange(v) {
    setOpenIndex(v);
  }

  useEffect(() => {
    function setMenuCollapse(e) {
      onToggleChange(!!e.detail);
    }
    window.addEventListener('setMenuCollapse', setMenuCollapse);
    return () => window.removeEventListener('setMenuCollapse', setMenuCollapse);
  }, []);

  const drawer = (
    <OmniweDrawer
      {...props}
      toggle={toggle}
      onToggleChange={onToggleChange}
      openIndex={openIndex}
      onOpenIndexChange={onOpenIndexChange}
    />
  );

  if (isXS)
    return (
      <div
        className={`${classes.rootDetached}`}
        style={{
          zIndex: 1101,
        }}
      >
        <IconButton className={`${classes.extendedMenuButton}`} onClick={() => onToggleChange(!toggle)}>
          <Icon icon={'faBars'} />
        </IconButton>
        {toggle && <div className={classes.rootDetachedBackdrop} onClick={() => onToggleChange(!toggle)} />}
        {toggle && drawer}
      </div>
    );
  else if (isSM)
    return (
      <>
        {toggle && <div className={classes.rootDetachedBackdrop} onClick={() => onToggleChange(!toggle)} />}
        <div
          className={toggle ? classes.rootDetached : classes.root}
          style={{
            zIndex: 1101,
          }}
        >
          {drawer}
        </div>
      </>
    );

  return (
    <div
      className={classes.root}
      style={{
        zIndex: 1101,
      }}
    >
      {drawer}
    </div>
  );
};

export default LeftSideBar;
