import { withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent } from '@material-ui/core';

export const OrderCard = withStyles((theme) => ({
  root: {
    borderRadius: '1rem',
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
}))(Card);

export const OrderCardHeader = withStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
  },
}))(CardHeader);

export const OrderCardContent = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
}))(CardContent);
