import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { parseConnection, parseOSSQuery } from '../../shared';
import _ from 'lodash';
import SelectCombo from './index';

const SEARCH_QUERY = gql`
  query ($id: ID!, $query: String, $limits: Int, $cursor: Int) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        products(query: $query, limits: $limits, cursor: $cursor) {
          nextCursor
          nodes {
            id
            name
            sku
            subtitle
            medias {
              #id
              src
              optimizedSrc(width: 256, height: 256)
            }
            active
          }
        }
      }
    }
  }
`;

const SelectComboProduct = ({ query, idKey = 'id', showSubtitle = true, ...props }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { loading, data, fetchMore } = useQuery(query || SEARCH_QUERY, {
      variables: {
        id: localStorage.getItem('shopId'),
        query: parseOSSQuery(search, ['sku', 'name', 'subtitle', '_id']),
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);
  function getConnection(data) {
    const { node } = data || {};
    return parseConnection((node || {}).products);
  }

  return (
    <>
      <SelectCombo
        onOpen={setOpen}
        getOptionSelected={(a, b) => (a || {})[idKey] === (b || {})[idKey]}
        onSearchChange={setSearch}
        options={nodes}
        getOptionLabel={(option) => {
          return `${option.sku} ${option.name || option[idKey]} ${(showSubtitle && option.subtitle) || ''}`;
        }}
        loading={loading}
        onScrollBottom={async () => {
          if (!!nextCursor && !loading)
            await fetchMore({
              variables: {
                cursor: nextCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                const fetchMoreConnection = getConnection(fetchMoreResult),
                  { nodes: fetchMoreNodes } = fetchMoreConnection,
                  { nodes: prevNodes } = getConnection(prevResult);
                fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

                return {
                  node: {
                    ...(fetchMoreResult.node || {}),
                    products: fetchMoreConnection,
                  },
                };
              },
            });
        }}
        {...props}
      />
    </>
  );
};

export default SelectComboProduct;
