import React, { memo } from 'react';
import { Box } from '@material-ui/core';

export const ColorBlock = memo(
  ({ color }) => {
    return <Box width={10} height={10} border={1} borderColor={'#ccc'} bgcolor={color} />;
  },
  (prev, next) => prev.color === next.color,
);

export default ColorBlock;
