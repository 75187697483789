import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, REMOVE_QUERY, GET_QUERY, UPDATE_QUERY } from './query';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import { convertMetaArray, convertMetaObject, getCredential } from '../../shared';
import ActivityIndicator from '../../components/ActivityIndicator';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';

export const providerOptions = [
  {
    group: translate.recommend,
    label: translate.manual_payment,
    value: 'MANUAL',
    credentialPlatform: 'MANUAL',
  },
  {
    group: translate.recommend,
    icon: <img className="h-4" src={require('../../assets/unopay.png')} alt="unopay" />,
    label: 'UNOPay',
    value: 'STRIPE_CONNECT_CUSTOM',
    credentialPlatform: 'STRIPE_CONNECT_CUSTOM',
  },
  {
    group: translate.recommend,
    icon: <img className="h-4" src={require('../../assets/uprise.png')} alt="stripe" />,
    label: 'Uprise Payment',
    value: 'UPRISE_PAYMENT',
    credentialPlatform: 'UPRISE_PAYMENT',
  },
  {
    group: translate.octopus,
    icon: <img className="h-4" src={require('../../assets/octopus.png')} alt="OCTOPUS POS" />,
    label: `${translate.octopus + translate.offline}`,
    value: 'OCTOPUS_POS',
    credentialPlatform: 'OCTOPUS_POS',
  },
  {
    group: `KPAY ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_integrated_credit_card_payment || 'KPAY 綜合信用卡支付',
    value: 'KPAY_POS_PAYMENT',
    credentialPlatform: 'KPAY_POS',
  },
  {
    group: `KPAY ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_qr_code_scan || 'KPAY 綜合刷碼支付',
    value: 'KPAY_POS_SCAN',
    credentialPlatform: 'KPAY_POS',
  },
  {
    group: `KPAY ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_tap_nfc || 'KPAY 八達通支付',
    value: 'KPAY_POS_OCTOPUS',
    credentialPlatform: 'KPAY_POS',
  },
  {
    group: `KPAY ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: 'KPAY PayMe',
    value: 'KPAY_POS_PAYME',
    credentialPlatform: 'KPAY_POS',
  },
  {
    group: `KPAY ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_integrated_credit_card_payment || 'KPAY 綜合信用卡支付',
    value: 'KPAY_CNP_SALES_GATEWAY',
    credentialPlatform: 'KPAY',
  },
  {
    group: `KPAY ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_alipay || 'KPAY 支付寶',
    value: 'KPAY_ALIPAY_SALE_QR',
    credentialPlatform: 'KPAY',
  },
  {
    group: `KPAY ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_wechat_payment || 'KPAY 微信支付',
    value: 'KPAY_WXPAY_SALE_QR',
    credentialPlatform: 'KPAY',
  },
  {
    group: `KPAY ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_unionPay || 'KPAY 銀聯卡',
    value: 'KPAY_UNIONPAY_SALE_QR',
    credentialPlatform: 'KPAY',
  },
  {
    group: `BBMSL ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/BBMSL.png')} alt="BBMSL" />,
    label: `BBMSL ${translate.credit_card || '信用卡'}`,
    value: 'BBMSL_POS_CREDIT_CARD',
    credentialPlatform: 'BBMSL',
  },
  {
    group: `BBMSL ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/BBMSL.png')} alt="BBMSL" />,
    label: `BBMSL ${translate.alipay}`,
    value: 'BBMSL_POS_ALIPAY',
    credentialPlatform: 'BBMSL',
  },
  {
    group: `BBMSL ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/BBMSL.png')} alt="BBMSL" />,
    label: `BBMSL ${translate.octopus}`,
    value: 'BBMSL_POS_OCTOPUS',
    credentialPlatform: 'BBMSL',
  },
  {
    group: `BBMSL ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/BBMSL.png')} alt="BBMSL" />,
    label: `BBMSL ${translate.wechat_pay || '微信支付'}`,
    value: 'BBMSL_POS_WECHAT',
    credentialPlatform: 'BBMSL',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.integrated_credit_card_payment || '綜合信用卡支付',
    value: 'QFPAY_POS_CARD',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.wechat_pay || '微信支付',
    value: 'QFPAY_POS_WECHATPAY',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.alipay || '支付寶',
    value: 'QFPAY_POS_ALIPAY',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: 'Payme',
    value: 'QFPAY_POS_PAYME',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.union_pay_intl || '雲閃付',
    value: 'QFPAY_POS_UNION',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.fps_pay || '轉數快',
    value: 'QFPAY_POS_FPS',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.octopus || '八達通',
    value: 'QFPAY_POS_OCTOPUS',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.union_pay || '銀聯卡',
    value: 'QFPAY_POS_UNIONCARD',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.amex_card || '美國運通',
    value: 'QFPAY_POS_AMEX',
    credentialPlatform: 'QFPAY_POS',
  },
  {
    group: `${translate.qfpay || '錢方'} ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.qfpay_checkout_service || '錢方收銀台服務',
    value: 'QFPAY_CHECKOUT_SERVICE',
    credentialPlatform: 'QFPAY',
  },
  {
    group: `EFT ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_integrated_credit_card_payment || 'EFT 綜合信用卡支付',
    value: 'EFT_POS_CREDIT_CARD',
    credentialPlatform: 'EFT_POS',
  },
  {
    group: `EFT ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_qr_code_scan || 'EFT 綜合刷碼支付',
    value: 'EFT_POS_SCAN',
    credentialPlatform: 'EFT_POS',
  },
  {
    group: `EFT ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_octopus || 'EFT 八達通支付',
    value: 'EFT_POS_OCTOPUS',
    credentialPlatform: 'EFT_POS',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_alipay_cn || 'EFT 支付寶 CN',
    value: 'EFT_ALIPAYCN',
    credentialPlatform: 'EFT',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_alipay_hk || 'EFT 支付寶 HK',
    value: 'EFT_ALIPAYHK',
    credentialPlatform: 'EFT',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_fps || 'EFT 轉數快',
    value: 'EFT_FPS',
    credentialPlatform: 'EFT',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_octopus || 'EFT 八達通',
    value: 'EFT_OCTOPUS',
    credentialPlatform: 'EFT',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: 'EFT Payme',
    value: 'EFT_PAYME',
    credentialPlatform: 'EFT',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_union_pay || 'EFT 銀聯卡',
    value: 'EFT_UNIONPAY',
    credentialPlatform: 'EFT',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: 'EFT Visa/Master',
    value: 'EFT_VM',
    credentialPlatform: 'EFT',
  },
  {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_wechat_payment || 'EFT 微信支付',
    value: 'EFT_WECHAT',
    credentialPlatform: 'EFT',
  },
  {
    group: 'PayPal',
    icon: <img className="h-4" src={require('../../assets/paypal-logo.png')} alt="paypal" />,
    label: 'PayPal',
    value: 'PAYPAL_STANDARD',
    credentialPlatform: 'PAYPAL',
  },
  {
    group: 'SoePay',
    icon: <img className="h-4" src={require('../../assets/soepay_logo.svg')} alt="soepay" />,
    label: translate.integrated_credit_card_payment,
    value: 'SOEPAY_POS_CARD',
    credentialPlatform: 'SOEPAY_POS',
  },
  {
    group: 'SoePay',
    icon: <img className="h-4" src={require('../../assets/soepay_logo.svg')} alt="soepay" />,
    label: translate.qr_code_scan || '綜合刷碼支付',
    value: 'SOEPAY_POS_QR',
    credentialPlatform: 'SOEPAY_POS',
  },
  {
    group: 'SoePay',
    icon: <img className="h-4" src={require('../../assets/soepay_logo.svg')} alt="soepay" />,
    label: translate.cash,
    value: 'SOEPAY_POS_CASH',
    credentialPlatform: 'SOEPAY_POS',
  },
  {
    group: 'FreightAmigo',
    icon: <img className="h-4" src={require('../../assets/freightAmigo-logo.png')} alt="FreightAmigo" />,
    label: `FreightAmigo ${translate.alipay_cn}` || 'FreightAmigo 支付寶（中國）',
    value: 'FREIGHT_AMIGO_ALIPAY_CN',
    credentialPlatform: 'FREIGHT_AMIGO_PAYMENT',
  },
  {
    group: 'FreightAmigo',
    icon: <img className="h-4" src={require('../../assets/freightAmigo-logo.png')} alt="FreightAmigo" />,
    label: `FreightAmigo ${translate.alipay_hk}` || 'FreightAmigo 支付寶（香港）',
    value: 'FREIGHT_AMIGO_ALIPAY_HK',
    credentialPlatform: 'FREIGHT_AMIGO_PAYMENT',
  },
  {
    group: 'FreightAmigo',
    icon: <img className="h-4" src={require('../../assets/freightAmigo-logo.png')} alt="FreightAmigo" />,
    label: `FreightAmigo ${translate.wechat_pay_cn}` || 'FreightAmigo 微信支付（中國）',
    value: 'FREIGHT_AMIGO_WECHAT_CN',
    credentialPlatform: 'FREIGHT_AMIGO_PAYMENT',
  },
  {
    group: 'FreightAmigo',
    icon: <img className="h-4" src={require('../../assets/freightAmigo-logo.png')} alt="FreightAmigo" />,
    label: `FreightAmigo ${translate.wechat_pay_hk}` || 'FreightAmigo 微信支付（香港）',
    value: 'FREIGHT_AMIGO_WECHAT_HK',
    credentialPlatform: 'FREIGHT_AMIGO_PAYMENT',
  },
  {
    group: 'FreightAmigo',
    icon: <img className="h-4" src={require('../../assets/freightAmigo-logo.png')} alt="FreightAmigo" />,
    label: `FreightAmigo ${translate.credit_card}` || 'FreightAmigo 信用卡',
    value: 'FREIGHT_AMIGO_CREDIT_CARD',
    credentialPlatform: 'FREIGHT_AMIGO_PAYMENT',
  },
  {
    group: `OCGC ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/logo_OCGC.png')} alt="OCGC alipay" />,
    label: `OCGC ${translate.alipay}` || 'OCGC 支付寶',
    value: 'OCGC_WEB_ALIPAY',
    credentialPlatform: 'OCGC',
  },
  {
    group: `OCGC ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../assets/logo_OCGC.png')} alt="OCGC" />,
    label: `OCGC ${translate.wechat_pay}` || 'OCGC 微信支付',
    value: 'OCGC_WEB_WECHAT',
    credentialPlatform: 'OCGC',
  },
  {
    group: translate.others,
    icon: <img className="h-4" src={require('../../assets/stripe.png')} alt="stripe" />,
    label: 'Stripe',
    value: 'STRIPE_CREDIT_CARD',
    credentialPlatform: 'STRIPE_CREDIT_CARD',
  },
].filter((_) => _);

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.payment_method_info,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                name: 'name',
              },
              {
                label: translate.payment_method,
                type: 'select',
                name: 'provider',
                required: true,
                disabled: () => !!this.state.id,
                options: providerOptions,
                afterChanged: async (value, { setFieldValue }) => {
                  if (!value) return;
                  try {
                    ActivityIndicator.show(translate.check_credential);
                    const option = providerOptions.find((opt) => opt.value === value);

                    const credential = await getCredential(option.credentialPlatform);
                    if (!credential) throw new Error('No related credential');
                    setFieldValue('credential', credential);
                  } catch (e) {
                    toast.error(errorParser(e));
                    setFieldValue('provider', undefined);
                  } finally {
                    ActivityIndicator.hide();
                  }
                },
              },
              {
                type: 'number',
                label: translate.sort_index,
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
              },
              {
                label: translate.payment_instruction,
                type: 'html',
                name: 'description',
              },
              {
                label: translate.allow_qrcode_ordering_payment || '允許點餐二維碼付款',
                type: 'checkbox',
                name: 'metadata.allowQRCodeOrdering',
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;

    if (id)
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input: {
            description: values.description,
            metadata: convertMetaArray(values.metadata),
            name: values.name || '',
            sortIndex: values.sortIndex,
          },
        },
      });
    else
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            credentialId: values?.credential?.id,
            description: values?.description,
            metadata: convertMetaArray(values.metadata),
            name: values.name || providerOptions.find((opt) => opt.value === values.provider)?.label || '',
            provider: values.provider,
            sortIndex: values.sortIndex,
          },
        },
      });

    return true;
  };

  getInitialData({ node } = {}) {
    const {
      name,
      sortIndex = 0,
      provider,
      description,
      createdAt,
      updatedAt,
      active = true,
      enabled = false,
      metadata,
    } = node ?? {};
    return {
      name,
      sortIndex,
      provider,
      description,
      createdAt,
      updatedAt,
      active,
      enabled,
      metadata: {
        allowQRCodeOrdering: 'true',
        ...convertMetaObject(metadata),
      },
    };
  }
}
