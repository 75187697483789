import { gql } from '@apollo/client';

export const FRAGMENT_MEMBER_TIER = gql`
  fragment FRAGMENT_MEMBER_TIER on CompanyMemberTier {
    id
    createdAt
    updatedAt
    name
    level
    isDefault
    memberPointActive
    memberPointUnitPrice
    memberPointPerUnit
  }
`;

export const GET_QUERY = gql`
  query (
    $id: ID!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $query: String
    $filter: CompanyMemberTierFilterInput
  ) {
    node(id: $id) {
      id
      ... on Company {
        memberTiers(cursor: $cursor, limits: $limits, sortBy: $sortBy, query: $query, filter: $filter) {
          totalCount
          nextCursor
          nodes {
            ...FRAGMENT_MEMBER_TIER
          }
        }
      }
    }
  }
  ${FRAGMENT_MEMBER_TIER}
`;
