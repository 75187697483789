import { gql } from '@apollo/client';
export const FRAGMENT_WAREHOUSE = gql`
  fragment FRAGMENT_WAREHOUSE on CompanyWarehouse {
    id
    name
    sortIndex
    active
    createdAt
    updatedAt
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Company {
        id
        warehouses(query: $query, cursor: $cursor, limits: $limits, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_WAREHOUSE
          }
        }
      }
    }
  }
  ${FRAGMENT_WAREHOUSE}
`;
