import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';

export default (props) => {
  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.list_venue,
          },
        ]}
      />
      <Box>
        <ListView {...props} />
      </Box>
    </div>
  );
};
