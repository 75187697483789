import React, { Fragment } from 'react';
import { translate } from '../../shared/translate';
import { GET_QUERY as GET_SHOP } from '../Homepage/query';
import { Query } from '@apollo/client/react/components';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default {
  name: translate.seo,
  cards: [
    {
      fields: [
        {
          label: translate.heading,
          description: translate.title_description || '請輸入標題',
          type: 'text',
          name: 'metaTitle',
          maxLength: 60,
        },
        {
          label: translate.description,
          description: translate.description_description || '請輸入簡介',
          type: 'textarea',
          name: 'metaDescription',
          maxLength: 160,
        },
        {
          label: translate.seo_keyword,
          description: translate.seo_keyword_description || `系統會自動在輸入內容前加上 '#' 符號，請直接輸入內容`,
          type: 'hashtags',
          name: 'metaKeywords',
          maxLength: 255,
          placeholder: translate.seo_keyword_placeholder || 'e.g. 網上購物　本地品牌 　OnlineShopping　LocalBrands',
        },
        {
          label: translate.rewriteUri,
          render: ({ loading, values, setFieldValue, isSubmitting }) => {
            const shopId = localStorage.getItem('shopId');

            if (loading) return <Skeleton height={30} />;

            return (
              <Fragment>
                <InputField
                  disabled={isSubmitting}
                  value={values.rewriteUri}
                  onChange={(e) => setFieldValue('rewriteUri', e.target.value)}
                  // eslint-disable-next-line
                  inputProps={{
                    pattern: "[^()/<>[\\]\\\\,'|\x22]+",
                  }}
                  placeholder={translate.rewriteUri_placeholder || '自訂義網址 e.g. limited-edition-goldpremium'}
                />
                <Typography variant={'body2'}>
                  {translate.rewrite_uri_description ||
                    '請輸入描述性連結, 不能如未設定，系統將隨機產生一組編碼作為網址。建議輸入簡潔而描述明確的英文詞語，不能輸入特殊符號輸入特殊符號'}
                </Typography>
                <Query
                  query={GET_SHOP}
                  variables={{
                    shopId,
                  }}
                  skip={!shopId}
                >
                  {({ data: { shop } = {} }) => {
                    const { hostname, customDomain } = shop || {};
                    if ((!hostname && !customDomain) || !values.rewriteUri) return null;
                    const href = `https://${customDomain || hostname}/${values.rewriteUri || ''}`;
                    return (
                      <a href={href} target={'_blank'} rel={'noreferrer'}>
                        <Typography variant={'body2'}>
                          {translate.preview}: {href}
                        </Typography>
                      </a>
                    );
                  }}
                </Query>
              </Fragment>
            );
          },
        },
      ],
    },
  ],
};
