import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputField from './InputField';
import { translate } from '../../../shared/translate';
import LSkeleton from '../../LSkeleton';

export default ({
  descriptionMode = 'remark',
  loading,
  required,
  value: _value = [],
  onChange = (_) => _,
  disabled,
  className = '',
  numberOfLines = 3,
}) => {
  const value = _value || [];

  if (loading)
    return (
      <div>
        {Array(numberOfLines)
          .fill(undefined)
          .map((__, i) => (
            <LSkeleton key={i} height={30} />
          ))}
      </div>
    );

  return (
    <Grid container direction={'column'} spacing={1} className={className}>
      {numberOfLines >= 1 && (
        <Grid item>
          <InputField
            placeholder={descriptionMode === 'placeholder' && translate.address_lines_remark1}
            name={'address-line1'}
            disabled={disabled}
            value={value[0] || ''}
            required={required}
            onChange={(e) => onChange([e.target.value, value[1] || '', value[2] || ''])}
          />
          {descriptionMode === 'remark' && <Typography variant={'body2'}>{translate.address_lines_remark1}</Typography>}
        </Grid>
      )}
      {numberOfLines >= 2 && (
        <Grid item>
          <InputField
            placeholder={descriptionMode === 'placeholder' && translate.address_lines_remark2}
            name={'address-line2'}
            disabled={disabled}
            value={value[1] || ''}
            onChange={(e) => onChange([value[0] || '', e.target.value, value[2] || ''])}
          />
          {descriptionMode === 'remark' && <Typography variant={'body2'}>{translate.address_lines_remark2}</Typography>}
        </Grid>
      )}
      {numberOfLines >= 3 && (
        <Grid item>
          <InputField
            placeholder={descriptionMode === 'placeholder' && translate.address_lines_remark3}
            name={'address-line3'}
            disabled={disabled}
            value={value[2] || ''}
            onChange={(e) => onChange([value[0] || '', value[1] || '', e.target.value])}
          />
          {descriptionMode === 'remark' && <Typography variant={'body2'}>{translate.address_lines_remark3}</Typography>}
        </Grid>
      )}
    </Grid>
  );
};
