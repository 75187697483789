import React from 'react';
import { Box } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import FormToolbar from '../../../components/FormToolbar';
import SystemButton from '../../../components/SystemButton';
import { Formik } from 'formik';
import DebounceSearchInput from '../../../components/DebounceSearchInput';
import { GET_PRODUCT_QUERY } from './query';
import { useQuery } from '@apollo/client';
import useBreakPoint from '../../../components/useBreakPoint';

const ProductsSelectionForm = ({ label, currentTab, onClose, onComplete, tabContent }) => {
  const breakPoint = useBreakPoint();
  const isUnderSM = ['xs'].includes(breakPoint);

  const { data: { node: { products = {} } = {} } = {} } = useQuery(GET_PRODUCT_QUERY, {
    variables: { id: localStorage.getItem('shopId'), limits: 0 },
  });
  const { totalCount } = products;

  return (
    <Formik
      key={currentTab}
      initialValues={{
        search: '',
        selectData: { mode: 'INCLUDE', data: [] },
        selectCollectionData: { mode: 'INCLUDE', data: [] },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await onComplete(values);
          onClose();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {(actions) => {
        const { values, setFieldValue, handleSubmit, isSubmitting } = actions;
        const { mode, data } = values?.selectData;
        const { mode: collectionMode, data: collectionData } = values?.selectCollectionData;

        let isSelected = false;
        switch (mode) {
          case 'EXCLUDE':
            if (!data.length || (!!data.length && data.length !== totalCount)) isSelected = true;
            break;
          case 'INCLUDE':
            if (!!data.length) isSelected = true;
            break;
        }

        const isSelectedExclude =
          !collectionData.length || (!!collectionData.length && collectionData.length !== totalCount);
        switch (collectionMode) {
          case 'EXCLUDE':
            if (isSelectedExclude) isSelected = true;
            break;
          case 'INCLUDE':
            if (
              collectionData.some(
                ([, { selectionData }]) => selectionData.mode === 'INCLUDE' && selectionData.data.length > 0,
              ) ||
              collectionData.find(([, { selectionData }]) => selectionData.mode === 'EXCLUDE' && isSelectedExclude)
            ) {
              isSelected = true;
            }
            break;
        }

        const _loading = isSubmitting;

        return (
          <Box
            style={{
              width: '100%',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box p={isUnderSM ? '0 16px 16px 16px' : '24px 40px'} style={isUnderSM ? {} : { textAlign: 'right' }}>
                <DebounceSearchInput
                  placeholder={label}
                  style={{ width: 340 }}
                  value={values?.search}
                  onChange={(search) => setFieldValue('search', search)}
                />
              </Box>
              <Box style={{ margin: '0 40px 40px 40px', height: '100%', overflowY: 'auto', flex: 1 }}>
                {tabContent(actions)}
              </Box>

              <FormToolbar
                extraLeft={
                  <SystemButton disabled={_loading} onClick={() => onClose()}>
                    {translate.cancel}
                  </SystemButton>
                }
                extra={
                  <>
                    <SystemButton type={'submit'} color="omniewRed" disabled={_loading || !isSelected}>
                      {translate.confirm_save || '完成'}
                    </SystemButton>
                  </>
                }
              />
            </form>
          </Box>
        );
      }}
    </Formik>
  );
};

export default ProductsSelectionForm;
