import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($companyId: ID!, $shopId: ID!) {
    company: node(id: $companyId) {
      id
      ... on Company {
        defaultMemberHashtagSchedules {
          validAt
          validThru
          hashtags
        }
      }
    }
    shop: node(id: $shopId) {
      id
      ... on CompanyShop {
        name
        autoConfirmRegistration
        hasRegistrationEmail
      }
    }
  }
`;

export const UPDATE_COMPANY_QUERY = gql`
  mutation ($id: ID!, $input: CompanyUpdateInput!) {
    companyUpdate(id: $id, input: $input) {
      id
      defaultMemberHashtagSchedules {
        validAt
        validThru
        hashtags
      }
    }
  }
`;

export const UPDATE_SHOP_QUERY = gql`
  mutation ($id: ID!, $input: CompanyShopInput!) {
    companyShopSet(id: $id, input: $input) {
      id
      name
      autoConfirmRegistration
      hasRegistrationEmail
    }
  }
`;
