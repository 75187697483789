import { gql } from '@apollo/client';

export const GET_ATTRIBUTES = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on CompanyShop {
        id
        attributes(limits: 9999) {
          totalCount
          nodes {
            id
            active
            key
            name
            type
            sortIndex
            system
          }
        }
      }
    }
  }
`;
