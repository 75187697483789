import React, { useState } from 'react';
import { GET_QUERY } from './query';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { parseConnection } from '../../shared';
import GACard from './GACard';
import { translate } from '../../shared/translate';
import Taber from '../../components/Taber';
import AccordionWrapper from '../../components/FormPageMaker/AccordionWrapper';
import SkeletonCard from './SkeletonCard';
import GoogleSiteVerificationCard from './GoogleSiteVerificationCard';
import { useHistory } from 'react-router-dom';
import Sitemap from '../list_Sitemap/index';

export default () => {
  const [tab, setTab] = useState(0);
  const { loading, data: { node } = {} } = useQuery(GET_QUERY, {
      variables: { id: localStorage.getItem('shopId') },
    }),
    { nodes } = parseConnection((node || {}).analysisTools);
  const history = useHistory();
  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setTab(tab);
          if (tab === 1) history.push('/analysis_tool/sitemap');
        }}
        tabs={[
          {
            name: translate.analyst_platform,
          },
          {
            name: translate.sitemap_manager,
          },
        ]}
      />
      {
        {
          0: (
            <Grid container direction={'row'}>
              <Grid item xs={12}>
                <AccordionWrapper expanded={tab === 0} onChange={() => setTab(0)}>
                  <Grid container spacing={3}>
                    {loading ? (
                      Array(4)
                        .fill(undefined)
                        .map((__, i) => (
                          <Grid key={i} item md={6}>
                            <SkeletonCard />
                          </Grid>
                        ))
                    ) : (
                      <>
                        <Grid item md={6}>
                          <GACard data={nodes.find((node) => node.type === 'google_analytics')} />
                        </Grid>
                        <Grid item md={6}>
                          <GoogleSiteVerificationCard
                            data={nodes.find((node) => node.type === 'google_site_verification')}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </AccordionWrapper>
              </Grid>
            </Grid>
          ),
          1: <Sitemap />,
        }[tab]
      }
    </div>
  );
};
