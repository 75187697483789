import React from 'react';
import { GET_QUERY, UPDATE_QUERY } from './query';
import FormPageMaker from '../../components/FormPageMaker';
import { convertMetaArray, convertMetaObject, parseCustomerName } from '../../shared';
import CustomFormMetadataField from '../../components/CustomFormMetadataField';
import RejectButton from './FormRejectButton';
import ApproveButton from './FormApprovebutton';
import { client } from '../../shared/apollo';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

export default class ItemsFormPage extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
    },
    tabs: [
      {
        cards: [
          {
            render: ({ values, setFieldValue, disabled, loading, isSubmitting }) => {
              return (
                <>
                  {values?.customer && (
                    <a href={`/customers/${values.customer.id}`} target="_blank" rel="noopener noreferrer">
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Avatar sizes={'small'} src={values.customer.picture} />
                        </Grid>
                        <Grid item>
                          <Typography>{parseCustomerName(values.customer) || ''}</Typography>
                          <Typography variant="caption" color="textSecondary">
                            {values.customer.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </a>
                  )}
                  <CustomFormMetadataField
                    status={values?.status}
                    metadataFields={values?.form?.metadataFields}
                    disabled={disabled || isSubmitting}
                    value={values.metadata}
                    loading={loading}
                    onChange={(v) => setFieldValue('metadata', v)}
                  />
                </>
              );
            },
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;
    const convertMeta = convertMetaArray(values.metadata);

    await client.mutate({
      mutation: UPDATE_QUERY,
      variables: { id, fields: convertMeta },
    });

    return true;
  };

  onCompleted = ({ node: { status } = {} }) => {
    if (status !== 'PENDING') {
      this.setState({ formDisabled: true, gql: { ...this.state?.gql, submit: undefined } });
    }
  };

  renderExtraButtons = ({ values, isSubmitting }) => {
    const uniqueFieldKey = values?.form?.metadataFields?.find((field) => field?.unique)?.key;
    return (
      values?.status === 'PENDING' && (
        <>
          <RejectButton id={this.props.match.params.id} disabled={isSubmitting} />
          <ApproveButton
            id={this.props.match.params.id}
            formID={values?.form?.id}
            disabled={isSubmitting}
            uniqueFieldKey={uniqueFieldKey}
            uniqueFieldValue={values.metadata?.[uniqueFieldKey]}
          />
        </>
      )
    );
  };

  getInitialData = ({ node }) => ({
    form: node?.form,
    customer: node?.customer,
    metadata: convertMetaObject(node?.metadata),
    status: node?.status,
  });

  getExtraFetchVariables = () => ({ id: this.props?.match?.params?.id });
}
