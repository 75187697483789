import React, { useState, useRef } from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';

export default (props) => {
  const [tab, setTab] = useState(0);
  const list = useRef();

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setTab(tab);
          list.current && list.current.resetCursor();
        }}
        tabs={[
          {
            name: translate.all_cash_voucher,
          },
          {
            name: translate.online_vouchers,
          },
          {
            name: translate.offline_vouchers,
          },
        ]}
      />
      <Box>
        <ListView
          {...props}
          ref={list}
          filter={
            {
              0: {},
              1: {
                active: { operator: 'IN', value: ['true'] },
              },
              2: {
                active: { operator: 'IN', value: ['false'] },
              },
            }[tab]
          }
        />
      </Box>
    </div>
  );
};
