import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '../../components/IconRender';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  banner: {
    '& .slick-dots li': {
      margin: '0',
    },
    '& .slick-list': {
      borderRadius: '10px 10px 0 0',
    },
  },
}));

const AIResultPreview = ({ data, unitPrice = 0 }) => {
  const classes = useStyles();

  const [bannerIndex, setBannerIndex] = useState(0);
  const { name, description, hashtags, images } = data || {};

  return (
    <Box
      width={300}
      style={{
        position: 'relative',
        borderRadius: 12,
        border: '2px solid #FFFFFF99',
        backgroundColor: '#fff',
        zIndex: 10,
      }}
    >
      <Icon
        icon={'faChevronLeft'}
        style={{ position: 'absolute', left: 12, top: 25, zIndex: 10, color: '#000', fontSize: '1.4rem' }}
      />
      <Slider
        dots
        adaptiveHeight
        slidesToShow={1}
        slidesToScroll={1}
        arrows
        className={classes.banner}
        nextArrow={<></>}
        prevArrow={<></>}
        appendDots={(dots) => (
          <div>
            <ul style={{ margin: '-8px' }}>{dots}</ul>
          </div>
        )}
        customPaging={(i) => {
          const active = i === bannerIndex;
          return (
            <div
              style={{
                transition: 'all 0.25s',
                backgroundColor: active ? '#f5f5f5' : '#d4d4d4',
                borderRadius: 5,
                height: 5,
                width: 5,
              }}
            />
          );
        }}
        beforeChange={(i, j) => {
          setBannerIndex(j);
        }}
      >
        {images.map((image, i) => {
          return (
            <div key={i}>
              <div
                style={{
                  width: '100%',
                  aspectRatio: '1/1',
                  background: `url("${image}") no-repeat center center / contain grey`,
                }}
              />
            </div>
          );
        })}
        {!images?.length && <Skeleton variant="rect" width={'100%'} height={300} style={{ aspectRatio: '1/1' }} />}
      </Slider>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'} style={{ color: '#000' }}>
              {name}
            </Typography>
          </Grid>
          {!!hashtags?.length && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {hashtags.map((_hashtag, i) => {
                  const hashtag = _hashtag.replace(/^#/, '');
                  return (
                    <Grid item key={i}>
                      <Box style={{ backgroundColor: '#cb433c', borderRadius: 100, padding: '5px 10px' }}>
                        <Typography variant={'body2'} style={{ color: '#fff' }}>
                          #{hashtag}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={'true'}>
                <Typography style={{ color: '#000', fontWeight: 'bold', fontSize: '1.2rem' }}>${unitPrice}</Typography>
              </Grid>
              <Grid item xs={'auto'}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Icon icon={'faMinusCircle'} style={{ color: '#000', fontSize: '1.4rem' }} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#000', fontWeight: 'bold', fontSize: '1.2rem' }}>1</Typography>
                  </Grid>
                  <Grid item>
                    <Icon icon={'faPlusCircle'} style={{ color: '#000', fontSize: '1.4rem' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AIResultPreview;
