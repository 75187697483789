import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        shopReferenceNoFormats(limits: 999) {
          nodes {
            id
            active
            type
            prefix
            length
          }
        }
      }
    }
  }
`;

export const REFERENCE_NO_CREATE_QUERY = gql`
  mutation ($input: ShopReferenceNoFormatCreateInput!) {
    shopReferenceNoFormatCreate(input: $input) {
      id
      type
      prefix
      length
    }
  }
`;

export const REFERENCE_NO_UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ShopReferenceNoFormatUpdateInput!) {
    shopReferenceNoFormatUpdate(id: $id, input: $input) {
      id
      type
      prefix
      length
    }
  }
`;
