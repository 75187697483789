import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import PrintLabelRow from '../../../../components/PrintOptionsButton/PrintLabelRow';
import { theme } from '../../../../theme';
import PrintLabelPDFViewer from '../../../../components/PrintOptionsButton/PrintLabelPDFViewer';
import { translate } from '../../../../shared/translate';
import SystemButton from '../../../../components/SystemButton';

const PrintExternal = () => {
  const deliveryNotes = JSON.parse(localStorage.getItem('bulkExternalShipment'));
  const [urls, setUrls] = useState([]);
  const [visible, setVisible] = useState(true);

  const [showContinue, setShowContinue] = useState(false);
  const [continuePrint, setContinuePrint] = useState(false);

  useEffect(() => {
    if (urls.filter(Boolean).length === deliveryNotes.length || continuePrint) {
      setVisible(false);
    }
  }, [urls?.length, continuePrint]);

  return (
    <>
      <Dialog
        open={visible}
        PaperProps={{
          style: {
            maxWidth: '470px',
            maxHeight: '500px',
            minWidth: '470px',
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className="pt-2 pb-1"
                style={{ verticalAlign: 'middle', fontWeight: 'bold', fontSize: '1.2rem', color: '#000' }}
              >
                {translate.generating_waybill_labels || '正在列印運單標籤'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ position: 'relative' }}>
                <Box
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    width: '100%',
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.primary.contrastText,
                      textAlign: 'center',
                    }}
                  >
                    {urls.filter(Boolean).length} / {deliveryNotes.length}
                  </Typography>
                </Box>
                <LinearProgress
                  value={(urls.filter(Boolean).length / deliveryNotes.length) * 100}
                  variant="determinate"
                  style={{
                    height: 20,
                    backgroundColor: '#7e858e',
                    borderRadius: 6,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ maxHeight: 300, paddingBottom: 30 }}>
          <Grid container spacing={1}>
            {deliveryNotes?.map((deliveryNote, index) => {
              return (
                <PrintLabelRow
                  key={index}
                  index={index}
                  deliveryNote={deliveryNote}
                  onChange={(newUrl) => {
                    setUrls((urls) => [...urls.concat(newUrl)]);
                  }}
                  setShowContinue={(v) => setShowContinue(v)}
                  continuePrint={continuePrint}
                />
              );
            })}
          </Grid>
        </DialogContent>

        {showContinue && !!urls.filter(Boolean).length && (
          <DialogActions>
            <SystemButton
              style={{ border: `1px solid #d0d0d0`, margin: 8 }}
              disabled={urls.length !== deliveryNotes.length}
              onClick={(e) => {
                e.preventDefault();
                setContinuePrint(true);
              }}
            >
              {translate.print_valid_labels || '列印有效標籤'}
            </SystemButton>
          </DialogActions>
        )}
      </Dialog>
      {(urls.filter(Boolean).length === deliveryNotes.length || continuePrint) && (
        <PrintLabelPDFViewer urls={urls.filter(Boolean)} />
      )}
    </>
  );
};

export default PrintExternal;
