import React, { memo, Fragment, useState, useEffect } from 'react';
import { Card, Typography } from '@material-ui/core';
import LoadingSkeleton from './LoadingSkeleton';
import ControlRow from './ControlRow';
import AddClickRegion from './AddClickRegion';
import BodyComponents from './BodyComponents';
import EditModal from './EditModal';
import { useMeasure } from 'react-use';
import _ from 'lodash';
import { translate } from '../../../shared/translate';

const PageBodyCard = ({ loading, values: { body = [] } = {}, isSubmitting, setFieldValue }) => {
  const [wrapper, { width }] = useMeasure();
  const [lockWidth, setLockWidth] = useState(0);
  const [editData, setEditData] = useState(undefined);

  useEffect(() => {
    if (width > 0 && !lockWidth) setLockWidth(width);
  }, [width]);

  return (
    <>
      <Card
        ref={wrapper}
        square={true}
        style={{
          maxWidth: lockWidth || undefined,
          pointerEvents: isSubmitting ? 'none' : 'all',
        }}
      >
        <Padder>
          <Typography>
            {translate.refer_to_template_webpage_display_for_accuracy ||
              '並非所有模版都支援所有元件或參數，請以模版網頁顯示作準'}
          </Typography>
        </Padder>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            {body.map((row, i) => {
              return (
                <Fragment key={i}>
                  <ControlRow simple>
                    <AddClickRegion
                      col
                      onComponentAdd={(config, { defaultHeight }) => {
                        body.splice(i, 0, {
                          _type: 'row',
                          hasContainer: true,
                          items: [
                            {
                              _type: 'column',
                              item: config,
                              height: defaultHeight ?? undefined,
                            },
                          ],
                        });
                        setFieldValue('body', body);
                        setEditData(config);
                      }}
                    />
                  </ControlRow>
                  <BodyComponents
                    data={row || {}}
                    setEditData={setEditData}
                    onChange={(update) => {
                      body[i] = update;
                      setFieldValue('body', body);
                    }}
                    onUpClick={() => {
                      if (i > 0) {
                        const tmp = body[i - 1];
                        body[i - 1] = body[i];
                        body[i] = tmp;
                        setFieldValue('body', body);
                      }
                    }}
                    onDownClick={() => {
                      if (i < body.length - 1) {
                        const tmp = body[i + 1];
                        body[i + 1] = body[i];
                        body[i] = tmp;
                        setFieldValue('body', body);
                      }
                    }}
                    onRemoveClick={() => {
                      body.splice(i, 1);
                      setFieldValue('body', body);
                    }}
                  />
                </Fragment>
              );
            })}
            <ControlRow simple style={{ borderBottom: '1px dashed #ccc' }}>
              <AddClickRegion
                col
                expand
                onComponentAdd={(config, { defaultHeight }) => {
                  body.push({
                    _type: 'row',
                    hasContainer: true,
                    items: [
                      {
                        _type: 'column',
                        item: config,
                        height: defaultHeight ?? undefined,
                      },
                    ],
                  });
                  setFieldValue('body', body);
                  setEditData(config);
                }}
              />
            </ControlRow>
          </>
        )}
      </Card>
      <EditModal
        open={!!editData}
        onClose={() => setEditData(undefined)}
        value={editData}
        onChange={(update) => {
          Object.assign(editData, update);

          for (const [key, value] of Object.entries(editData)) {
            if (_.isNil(value)) delete editData[key];
          }

          setEditData(undefined);
          setFieldValue('body', body);
        }}
      />
    </>
  );
};

export default PageBodyCard;

const Padder = memo(
  ({ children }) => (
    <ControlRow simple style={{ border: 'none' }}>
      <div style={{ padding: 8, borderLeft: '1px dashed #ccc', borderRight: '1px dashed #ccc' }}>{children}</div>
    </ControlRow>
  ),
  () => true,
);
