import React, { useState } from 'react';
import { gql } from '@apollo/client';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { client } from '../../../shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { Icon } from '../../../components/IconRender';
import MethodItem from './MethodItem';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/unopay.png')} alt="unopay" />,
    label: 'UNOPay',
    value: 'STRIPE_CONNECT_CUSTOM',
  },
  form: ({ values }) => {
    return <UnopayCard capabilitiesStatusActive={values.metadata?.capabilitiesStatusActive} />;
  },
  type: 'PAYMENT',
};

const GET_SETTING_LINK = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        stripeConnectLink
      }
    }
  }
`;
const UnopayCard = ({ capabilitiesStatusActive }) => {
  const [requesting, setRequesting] = useState(false);

  const handleConfigClick = async (e) => {
    e.preventDefault();
    try {
      if (requesting) return;

      ActivityIndicator.show();
      setRequesting(true);
      const { data: { node: { stripeConnectLink = '' } = {} } = {} } = await client.query({
        query: GET_SETTING_LINK,
        variables: {
          id: localStorage.getItem('shopId'),
        },
        fetchPolicy: 'no-cache',
      });
      if (window && stripeConnectLink) {
        window.open(stripeConnectLink, '_self');
      }
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setRequesting(false);
      ActivityIndicator.hide();
    }
  };

  return (
    <Card style={{ height: '100%', minHeight: 180 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display={'flex'}>
              <img className="h-6" src={require('../../../assets/unopay.png')} alt="unopay" />
              <Box py={1} px={2}>
                <Divider orientation={'vertical'} />
              </Box>
              <img className="h-9" src={require('../../../assets/stripe.png')} alt="stripe" />
              <div style={{ flex: 1 }} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box px={1} py={2} border={1} borderColor={'grey.300'} borderRadius={8}>
              {!capabilitiesStatusActive ? (
                <Typography>
                  <Icon
                    type={'pro-light'}
                    icon={'faExclamationCircle'}
                    style={{
                      verticalAlign: 'text-top',
                      marginRight: 8,
                    }}
                  />
                  你可能需要提供更多資訊才能開始收取支付款項。
                  {/* eslint-disable-next-line */}
                  <a href={requesting ? '' : 'javascript:void(0);'} onClick={handleConfigClick}>
                    {/* <a href={requesting ? undefined : 'javascript:void(0);'} onClick={handleConfigClick}> */}
                    <Typography component={'span'} color={'primary'}>
                      {requesting ? '檢查設定…' : '完成Unopay設定'}
                    </Typography>
                  </a>
                </Typography>
              ) : (
                <>
                  {/* eslint-disable-next-line */}
                  <a href={requesting ? '' : 'javascript:void(0);'} onClick={handleConfigClick}>
                    {/* <a href={requesting ? undefined : 'javascript:void(0);'} onClick={handleConfigClick}> */}
                    <Typography>{requesting ? '檢查設定…' : '更新Unopay設定'}</Typography>
                  </a>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Unopay 會於每筆本地會收取訂單總額低至 <b>2.9%</b> +<b>HK$2.35</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Box mr={2}>
                  <Typography>接受付款</Typography>
                </Box>
              </Grid>
              <Grid item>
                <MethodItem source={require('../../../assets/Visa_Inc._logo.svg')} />
              </Grid>
              <Grid item>
                <MethodItem source={require('../../../assets/mastercard.jpg')} />
              </Grid>
              <Grid item>
                <MethodItem source={require('../../../assets/unionpay.png')} />
              </Grid>
              <Grid item>
                <MethodItem source={require('../../../assets/applepay.png')} />
              </Grid>
              <Grid item>
                <MethodItem source={require('../../../assets/gpay.png')} />
              </Grid>
              <Grid item>
                <MethodItem source={require('../../../assets/alipay.jpg')} />
              </Grid>
              <Grid item>
                <MethodItem source={require('../../../assets/wechatpay.png')} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
