import React from 'react';
import { translate } from '../../shared/translate';
import ActiveSwitcher from '../list_Offer/Coupons/ActiveSwitcher';

export function itemCouponCodeField(isDisplay = true) {
  return [
    {
      title: translate.name,
      sortBy: 'name.keyword',
      width: 250,
      fieldName: 'name',
    },
    {
      title: translate.details,
      width: 250,
      fieldName: 'remark',
      sortBy: 'remark.keyword',
    },
    {
      title: translate.type,
      value: () => this.type,
    },

    isDisplay
      ? {
          title: translate.offer_code,
          fieldName: 'handle',
          type: 'text',
          sortBy: 'handle.keyword',
        }
      : {},
    {
      title: translate.use_limit,
      fieldName: 'publishLimit',
      type: 'number',
      filter: 'publishLimit',
      sortBy: 'publishLimit',
    },
    {
      title: translate.use_amount,
      fieldName: 'usage',
      type: 'number',
    },
    {
      title: translate.coupon_order,
      fieldName: 'sortIndex',
      type: 'number',
      sortBy: 'sortIndex',
      filter: 'sortIndex',
      filterChannel: 'q',
    },
    {
      title: translate.start_date_time,
      width: 180,
      align: 'right',
      type: 'datetime',
      fieldName: ({ isRedeemable }) => (isRedeemable ? 'publishAt' : 'validAt'),
    },
    {
      title: translate.expire_date_time,
      width: 180,
      align: 'right',
      type: 'datetime',
      fieldName: ({ isRedeemable }) => (isRedeemable ? 'publishThru' : 'validThru'),
    },
    {
      title: translate.visibility,
      width: 100,
      render: (data) => {
        const { id, active } = data || {};
        return <ActiveSwitcher id={id} data={data} active={active} onCompleted={this.refetch} />;
      },
      filter: 'active',
      type: 'bool',
      sortBy: 'active',
      noLink: true,
    },
  ];
}
