import React from 'react';
import SelectField from '../../../components/FormPageMaker/Fields/SelectField';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_IDS } from './query';
import { parseConnection } from '../../../shared';
import { Hidden, InputAdornment, makeStyles } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { Skeleton } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import useBreakPoint from '../../../components/useBreakPoint';
import { textColor } from '../../../theme';

const useStyles = makeStyles((theme) => ({
  select: {
    margin: 'auto 0',
  },
  selectInput: {
    paddingLeft: theme.spacing(1),
    color: textColor,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
  },
}));

const AllShopSelector = ({ sku, allShop, value, onChange, hasAllOption }) => {
  const classes = useStyles();
  const { loading: _loading, data: { node } = {} } = useQuery(GET_PRODUCT_IDS, {
      fetchPolicy: 'network-only',
      variables: { id: localStorage.getItem('companyId'), sku },
      skip: !sku,
    }),
    { nodes: shops } = parseConnection((node || {}).shops);
  const breakPoint = useBreakPoint();

  if (shops.length <= 1) return null;

  const shopProductKVPair = shops.reduce((reducer, { id: shopId, products }) => {
    const {
      nodes: [{ id: productId } = {}],
    } = parseConnection(products);
    reducer[shopId] = productId;
    return reducer;
  }, {});

  const loading = _loading || (!hasAllOption && !sku);

  const updateShops = shops.filter((shop) => !!shopProductKVPair[shop.id]),
    createShops = shops.filter((shop) => !shopProductKVPair[shop.id]);

  const core = (
    <SelectField
      variant={'standard'}
      size={'medium'}
      margin={'normal'}
      className={classes.select}
      // eslint-disable-next-line
      inputProps={{
        className: classes.selectInput,
      }}
      // eslint-disable-next-line
      InputProps={{
        disableUnderline: true,
        startAdornment: !~['xs'].indexOf(breakPoint) && (
          <InputAdornment position={'start'}>{translate.update_to_shop}</InputAdornment>
        ),
      }}
      value={allShop ? 'all' : value}
      onChange={(e) => {
        const shopId = e.target.value;
        onChange(shopId, shopProductKVPair[shopId]);
      }}
      disabled={loading}
      options={[
        hasAllOption && {
          label: translate.shop_deselected,
          value: 'all',
        },
      ].concat(
        ...updateShops.map((shop) => ({
          label: shop.name,
          value: shop.id,
        })),
        createShops.length > 0 && {
          label: translate.new_product,
        },
        ...createShops.map((shop) => ({
          label: shop.name,
          value: shop.id,
        })),
      )}
    />
  );

  return (
    <>
      <Hidden smDown>
        <Box width={250} style={{ float: 'right' }}>
          {loading ? <Skeleton height={30} /> : core}
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box ml={3} width={250}>
          {loading ? <Skeleton height={30} /> : core}
        </Box>
      </Hidden>
    </>
  );
};

export default AllShopSelector;
