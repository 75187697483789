import React, { useRef, useState } from 'react';
import { MODIFIER_PRODUCT_ADD_QUERY } from '../query';
import { Dialog, Typography, Box } from '@material-ui/core';
import { client } from '../../../shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { translate } from '../../../shared/translate';
import AddToModifierListView from './AddToModifierListView';
import { sleep } from '../../../shared';
import SystemButton from '../../../components/SystemButton';

const AddToModifierButton = ({ disabled, currModifierId, list }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      setLoading(true);
      ActivityIndicator.show(translate.loading);
      const result = await ref?.current?.infinityFetchAllSelected({ includeCurrentQSearch: false });

      ActivityIndicator.show(translate.loading);
      await client.mutate({
        mutation: MODIFIER_PRODUCT_ADD_QUERY,
        variables: {
          id: currModifierId,
          productIds: (result.nodes ?? []).map((node) => node.id),
        },
      });
      await sleep(1000);
      list.refetch();
    } catch (error) {
      toast.error(errorParser(error));
    } finally {
      setLoading(false);
      setOpen(false);
      ActivityIndicator.hide();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCLose = () => {
    setOpen(false);
  };

  return (
    <>
      <SystemButton disabled={disabled} className={'normal-btn'} variant={'outlined'} onClick={handleOpen}>
        <Typography variant="caption1" color={'inherit'}>
          {translate.product_modifiers_add || '添加產品'}
        </Typography>
      </SystemButton>
      <Dialog fullScreen open={open} onClose={handleCLose}>
        <Box
          style={{
            paddingTop: 64,
          }}
        >
          <AddToModifierListView
            handleSave={handleSave}
            handleOpen={handleOpen}
            handleCLose={handleCLose}
            id={currModifierId}
            ref={(r) => {
              ref.current = r;
            }}
            filter={{
              id: list.nodes?.length
                ? {
                    operator: 'NIN',
                    value: (list.nodes ?? []).map(({ id }) => id),
                  }
                : undefined,
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default AddToModifierButton;
