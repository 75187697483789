import React from 'react';
import { translate } from '../../shared/translate';
import SaleChart from './SaleChart';
import { NumberBriefFormat } from '../../shared';

export default ({ loading, orderCount, generalCount }) => {
  return (
    <SaleChart
      loading={loading}
      title={translate[`not_read_messages`]}
      amount1={orderCount === null ? '-' : NumberBriefFormat(generalCount)}
      label1={translate['general_message']}
      amount2={generalCount === null ? '-' : NumberBriefFormat(orderCount)}
      label2={translate['order_message']}
    />
  );
};
