import React from 'react';
import PromisifyModal from '../../../../components/PromisifyModal';
import { translate } from 'shared/translate';
import TextareaField from '../../../../components/FormPageMaker/Fields/TextareaField';
import { DialogContent, DialogTitle } from '@material-ui/core';
import FormRow from '../../../../components/FormRow';

export default class CancelModal extends PromisifyModal {
  renderHeader() {
    return <DialogTitle></DialogTitle>;
  }
  renderBody({ values, setFieldValue, isSubmitting }) {
    return (
      <DialogContent>
        <FormRow title={translate.cancel_reason} required={true}>
          <TextareaField
            required={true}
            value={values.reason}
            disabled={isSubmitting}
            onChange={(v) => {
              setFieldValue('reason', v);
            }}
          />
        </FormRow>
      </DialogContent>
    );
  }
}
