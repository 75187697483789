import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { CREATE_QUERY, GET_QUERY, REMOVE_QUERY, UPDATE_QUERY } from './query';
import { parseConnection, removeTypename, cloneObject, isProduction } from 'shared';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import moment from 'moment';
import StockField from 'routes/item_Product/StockField';
import Button from '@material-ui/core/Button';
import { Query } from '@apollo/client/react/components';
import { GET_QUERY as GET_SHOP } from '../Homepage/query';
import ean13Fix from '../item_Product/ean13Fix';
import ShippingZoneCard from '../item_Product/ShippingZoneCard';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { toInputMedia } from '../../shared';
import CopyButton from '../../components/CopyButton';
import ChooseProductCard from './ChooseProductCard';
import CollectionsField from '../item_Product/CollectionsField';
import uuid from 'react-uuid';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';
import SalesChannelField from '../item_Product/SalesChannelField';
import SeoCardSetting from '../item_Product/SeoCardSetting';
import TooltipContent from '../../components/TooltipContent';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: !!this.state.id ? UPDATE_QUERY : CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.bundle_product_settings,
        cards: [
          {
            fields: [
              {
                label: translate.bundle_product_name,
                type: 'text',
                maxLength: 100,
                name: 'name',
                required: true,
                md: 6,
                placeholder: translate.bundle_product_name_placeholder,
              },
              {
                label: translate.subtitle,
                tooltip: (
                  <TooltipContent
                    src={require('../../assets/product_subtitle_preview.png')}
                    points={[<Typography variant="body2">{translate.product_subtitle_remark}</Typography>]}
                  />
                ),
                type: 'text',
                maxLength: 50,
                name: 'subtitle',
                md: 6,
              },
              {
                label: translate.menu_product_collections || '產品分類',
                render: (props) => <CollectionsField {...props} />,
              },
              {
                label: translate.bundle_product_sku,
                name: 'sku',
                type: 'text',
                required: true,
                md: 6,
                maxLength: 50,
                placeholder: translate.sku_placeholder_example || '例如：ABC1234',
                description:
                  translate.bundle_product_sku_placeholder ||
                  '產品組合編號的數值不可與其他產品編號重複，僅允許字母和數字',
              },
              {
                label: translate.barcode,
                name: 'barcode',
                type: 'text',
                md: 6,
                maxLength: 50,
              },
              {
                label: translate.price,
                name: 'unitPrice',
                type: 'number',
                required: true,
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.suggested_retail_price,
                name: 'suggestedRetailPrice',
                type: 'number',
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.cost,
                name: 'cost',
                type: 'number',
                placeholder: '0.00',
                md: 6,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                md: 3,
                label: translate.activation,
                type: 'switch',
                name: 'active',
              },
              {
                md: 3,
                label: translate.visible,
                type: 'switch',
                name: 'display',
              },
              { md: 6 },
              {
                label: translate.stock,
                render: ({ values: { ignoreStock, quantity } = {}, loading, isSubmitting, setFieldValue }) => {
                  return (
                    <StockField
                      loading={loading}
                      disabled={isSubmitting}
                      value={{
                        quantity,
                        ignoreStock,
                      }}
                      onChange={({ ignoreStock, quantity }) => {
                        setFieldValue('ignoreStock', ignoreStock);
                        setFieldValue('quantity', quantity);
                      }}
                    />
                  );
                },
                required: ({ values }) => (values?.ignoreStock ? false : true),
                md: 6,
              },
              {
                label: translate.weight,
                name: 'weight',
                type: 'number',
                md: 6,
                inputProps: {
                  min: 0,
                  step: 0.001,
                },
                InputProps: {
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                },
              },
              {
                label: translate.online_day_time,
                name: 'publishAt',
                type: 'datetime-local',
                md: 6,
                text: translate.start_time_remark,
              },
              {
                label: translate.offline_day_time,
                description: translate.available_if_no_off_shelf_date || '如不輸入下架日期，系統將視為常設產品',
                name: 'publishThru',
                type: 'datetime-local',
                hasPermanent: true,
                md: 6,
                text: translate.end_time_remark,
              },
              {
                label: translate.hash_tag,
                description: translate.hash_tag_description || '系統會自動在輸入內容前加上 "#" 符號，請直接輸入內容',
                name: 'hashtags',
                type: 'hashTagsArray',
                md: 12,
                placeholder:
                  translate.remark_hashtags_placeholder || 'e.g. 熱門優惠　新品上市　Hot Deals　New Arrivals',
              },
              {
                label: translate.sort_index,
                tooltip: (
                  <TooltipContent
                    spacing={5}
                    src={require('../../assets/display_order.png')}
                    points={[
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {translate.display_order_remark || '系統以升序排列，數字越小，產品會越靠左顯示。'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item>
                              <Typography variant="body2">{translate.for_example}：</Typography>
                            </Grid>
                            <Grid item>
                              {translate.product_sort_index_help_remark?.split('\n').map((str, i) => (
                                <Typography key={i} variant="body2">
                                  {str}
                                </Typography>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>,
                    ]}
                  />
                ),
                type: 'number',
                inputProps: {
                  step: 1,
                },
                name: 'primarySortIndex',
                md: 6,
                text: translate.sort_index_remark,
              },
              !isProduction() && {
                label: translate.sales_channel || '銷售渠道',
                render: ({ loading, disabled, values, setFieldValue }) =>
                  loading ? (
                    <Skeleton height={30} width={100} />
                  ) : (
                    <SalesChannelField
                      disabled={disabled}
                      value={values.salesChannels}
                      onChange={(v) => setFieldValue('salesChannels', v)}
                    />
                  ),
                md: 'auto',
              },
              {
                label: translate.image,
                type: 'medias',
                name: 'medias',
                tooltip: (
                  <TooltipContent
                    src={require('../../assets/product_preview.png')}
                    points={[
                      <Typography variant="body2">
                        {translate.product_image_remark || '建議產品圖片尺寸為 1000px : 1000px ( 1:1 比例)'}
                      </Typography>,
                    ]}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        name: translate.choose_product,
        cards: [
          {
            fields: [ChooseProductCard],
          },
        ],
      },
      {
        name: translate.product_descriptions,
        cards: [
          {
            fields: [
              {
                key: 'description',
                label: translate.description,
                description: translate.description_description || '請輸入產品描述',
                type: 'html',
                name: 'description',
                require: true,
              },
            ],
          },
        ],
      },
      !isProduction() && {
        name: translate.delivery_method,
        noPadding: true,
        cards: [
          {
            fields: [ShippingZoneCard],
          },
        ],
      },
      SeoCardSetting,
    ].filter(Boolean),
    sku: '',
  };

  submit = async ({
    id,
    name,
    sku,
    barcode,
    description,
    collections = [],
    sections,
    medias,
    salesChannels,
    publishAt,
    publishThru,
    primarySortIndex,
    hashtags,
    subtitle,
    weight,
    ignoreStock,
    quantity,
    cost,
    shippingZones = [],
    unitPrice,
    suggestedRetailPrice,
    active,
    display,
    metaTitle,
    metaDescription,
    metaKeywords,
    rewriteUri,
  }) => {
    const {
      action,
      gql: { submit },
    } = this.state;

    const _quantity = quantity === this.initialValues.quantity ? undefined : quantity || undefined;

    await client.mutate({
      mutation: submit,
      variables: {
        id: action === 'update' ? id : undefined,
        warehouseId: localStorage.getItem('stockWarehouseId'),
        input: {
          name,
          sku,
          salesChannels,
          barcode: ean13Fix(barcode),
          collectionCodes: collections.map((col) => col.code).filter((_) => _),
          active,
          display,
          medias: medias.map(toInputMedia).filter((_) => _),
          publishAt: moment(publishAt).toISOString(),
          publishThru: !!publishThru ? moment(publishThru).toISOString() : null,
          hashtags,
          subtitle,
          shopId: !id ? localStorage.getItem('shopId') : undefined,
          primarySortIndex,
          rewriteUri: rewriteUri || null,
          shippingZoneIds: shippingZones.map((zone) => (zone || {}).id),
          description: description || '',
          weight,
          ignoreStock,
          quantity: action === 'update' ? _quantity : quantity,
          unitPrice,
          cost,
          suggestedRetailPrice,
          metaTitle,
          metaDescription,
          metaKeywords,
          sections: sections?.map(({ options, ...value }) => ({
            ...value,
            options: options?.map(({ product, ...value }) => ({ ...value, productId: product?.id })),
          })),
        },
      },
    });

    return true;
  };

  getInitialData(data) {
    const { copyValues } = this.state || {};
    const { node } = data || {},
      {
        id,
        name = '',
        barcode = '',
        sku = '',
        description = '',
        medias = [],
        active = true,
        display = true,
        basePrice = 0,
        primarySortIndex = 100,
        updatedAt,
        publishAt,
        weight,
        publishThru,
        subtitle = '',
        hashtags = [],
        rewriteUri = '',
        shippingZones,
        sections = [{ options: [{ id: uuid(), price: 0 }] }, { options: [{ id: uuid(), price: 0 }] }],
        ignoreStock,
        quantity,
        salesChannels,
        unitPrice,
        cost,
        suggestedRetailPrice,
        metaTitle,
        metaDescription,
        metaKeywords,
      } = node || {};
    const { nodes: collections } = parseConnection((node || {}).collections);

    return {
      id,
      name,
      collections,
      barcode,
      sku,
      active,
      display: display ?? true,
      description,
      salesChannels: _.uniq(['DEFAULT'].concat(salesChannels || ['POS', 'CUTSOMERADO'])),
      publishAt,
      weight,
      publishThru,
      basePrice,
      primarySortIndex,
      medias: cloneObject(medias || []),
      hashtags: cloneObject(hashtags || []),
      subtitle,
      cost,
      updatedAt,
      rewriteUri,
      shippingZones: cloneObject(shippingZones || []),
      sections: cloneObject(sections || [])?.map(({ label, description, options }) => ({
        label,
        description,
        options: options?.map(removeTypename),
      })),
      ignoreStock,
      quantity,
      unitPrice,
      suggestedRetailPrice,
      metaTitle,
      metaDescription,
      metaKeywords,
      ...copyValues,
    };
  }

  getExtraFetchVariables() {
    return { warehouseId: localStorage.getItem('stockWarehouseId') };
  }

  onCompleted(data) {
    const { history } = this.props;
    const { action } = this.state;
    const companyId = data?.node?.shop?.company?.id;

    if (action === 'update' && (!data?.node || companyId !== localStorage.getItem('companyId'))) {
      toast.error(errorParser(new Error('Invalid Product')));
      history.replace('/products');
    } else {
      this.setState({ sku: data?.node?.sku });
    }
    return super.onCompleted(data);
  }

  getFetchResult(result) {
    const { action } = this.state;
    const { loading, data } = result || {};
    if (action === 'update') return { loading: loading && !data, data };
    return { loading, data };
  }

  renderContent(result) {
    const { className } = this.props,
      { id } = this.state;
    const { loading, data } = this.getFetchResult(result);

    this.initialValues = this.getInitialData(data || {});

    return (
      <div key={id || 'new'} className={className}>
        {this.renderForm({ ...this.initialValues }, loading)}
      </div>
    );
  }

  renderExtraButtons({ loading, values }) {
    const shopId = localStorage.getItem('shopId'),
      { id } = this.state;
    if (!id) return null;
    return (
      <Query
        query={GET_SHOP}
        variables={{
          shopId,
        }}
        skip={!shopId}
      >
        {({ data: { shop } = {} }) => {
          const { hostname, customDomain } = shop || {};
          if (!hostname && !customDomain) return null;
          return (
            <Grid container style={{ placeContent: 'flex-end' }} spacing={1}>
              <Grid item>
                <a href={`//${customDomain || hostname}/bundle_product/${id}`} target={'_blank'}>
                  <Button disabled={loading} variant={'contained'} color={'primary'}>
                    {translate.preview}
                  </Button>
                </a>
              </Grid>
              <Grid item>
                <CopyButton
                  getCopyValues={() => {
                    const clone = cloneObject(values);
                    return {
                      ...clone,
                      rewriteUri: undefined,
                      id: undefined,
                      sku: undefined,
                      sections: clone.sections?.map((section) => ({
                        ...section,
                        options: section.options?.map((option) => ({
                          ...option,
                          id: undefined,
                        })),
                      })),
                    };
                  }}
                  disabled={loading}
                  url={'/bundle_products/new'}
                />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    );
  }
}
