import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import ItemsTable from './ItemsTable';
import _ from 'lodash';

const ItemsCard = ({
  loading,
  values: { items: _items = [], inboundWarehouse, outboundWarehouse, status, __disabled } = {},
  setFieldValue,
  isSubmitting,
}) => {
  const editable = !!~['PENDING'].indexOf(status) && !__disabled;
  const disabled = isSubmitting || !editable;
  const items = _.sortBy(_items || [], (item) => item?.sku?.toUpperCase());

  return (
    <CardContent>
      <Typography variant={'h6'}>{translate.transfer_items}</Typography>
      <Card variant={'outlined'}>
        <ItemsTable
          seal={!['PENDING'].includes(status)}
          loading={loading}
          items={items}
          isSubmitting={isSubmitting}
          status={status}
          onChange={(v) => setFieldValue('items', v)}
          disabled={disabled}
          fromWarehouseId={(outboundWarehouse || {}).id}
          toWarehouseId={(inboundWarehouse || {}).id}
        />
      </Card>
    </CardContent>
  );
};

export default ItemsCard;
