import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { getCommonColumns } from 'shared';
import StackTags from '../../../components/StackTags';
import MenuHashTagsSet from './MenuHashtagsSet';
import MenuExportAll from './MenuExportAll';
import MenuImportAll from './MenuImportAll';
import MenuGiftPointsSet from './MenuGiftPointsSet';
import MenuMemberTierSet from './MenuMemberTierSet';
import { checkPermissionV2 } from '../../../components/PermissionMask';
import { omniweEmailTest } from '../../../shared';
import ActiveSwitcher from './ActiveSwitcher';
import MenuExportPointRecord from './MenuExportPointRecord';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: checkPermissionV2('createCustomer') ? '/customers/new' : undefined,
    modalExportOpen: false,
    hasQSearch: true,
    qFields: ['phoneNumber', 'email', 'metadata.value'],
    selectionMode: true,
    getRowLink: ({ id, name }) => ({ pathname: `/customers/${id}`, state: { title: name } }),
    fields: [
      {
        title: translate.avatar,
        fieldName: 'picture',
        type: 'image',
        width: 50,
      },
      {
        title: translate.phone_no,
        fieldName: 'phoneNumber',
        width: 140,
        type: 'text',
        sortBy: 'phoneNumber.keyword',
      },
      {
        title: translate.email,
        fieldName: 'email',
        value: (data) => {
          if (omniweEmailTest(data.email)) return '-';
          return data.email;
        },
        width: 180,
        sortBy: 'email.keyword',
      },
      {
        title: translate.member_points,
        fieldName: 'giftPoints',
        type: 'number',
        width: 100,
      },
      {
        title: translate.tags,
        render: ({ hashtags = [] }) => (
          <StackTags
            tags={hashtags}
            onTagClick={(tag) => {
              if (!tag) return;

              const { filter } = this.state;
              this.onFilterChange({
                ...filter,
                hashtags: {
                  operator: 'IN',
                  value: [tag],
                },
              });
            }}
          />
        ),
        filter: 'hashtags',
        filterType: 'hashTagsArray',
        filterChannel: 'q',
        noLink: true,
      },

      {
        title: translate.valid_status,
        width: 80,
        render: (data) => {
          const { id, blocked } = data || {};
          return <ActiveSwitcher id={id} blocked={blocked} data={data} />;
        },
        filter: 'blocked',
        filterType: 'reverseBool',
        filterChannel: 'q',
        sortBy: 'blocked',
        noLink: true,
      },
      {
        title: translate.last_login_at,
        width: 180,
        fieldName: 'lastLogin',
        type: 'datetime',
        align: 'right',
        sortBy: 'lastLogin',
      },
      ...getCommonColumns({
        updatedAt: { column: false, filter: undefined },
      }),
    ],
    menus: (() => {
      const hasUpdatePermission = checkPermissionV2('updateCustomer');

      return [
        MenuExportAll,
        MenuImportAll,
        MenuExportPointRecord,
        hasUpdatePermission && MenuHashTagsSet,
        hasUpdatePermission && MenuGiftPointsSet,
        hasUpdatePermission && MenuMemberTierSet,
      ].filter((_) => _);
    })(),
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
  getData = ({ node } = {}) => {
    const { customers } = node || {};
    return customers;
  };
}
