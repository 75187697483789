import React, { useEffect, useState } from 'react';
import { TextareaAutosize, makeStyles } from '@material-ui/core';
import LSkeleton from '../../LSkeleton';
import { StyledTooltip } from './InputField';
import { translate } from '../../../shared/translate';
import { useDebounce } from 'react-use';
import { focusBorderLineColor } from '../../../theme';

const useStyles = makeStyles((theme) => ({
  textarea: {
    padding: theme.spacing(1.5),
    width: '100%',
    borderWidth: 1,
    borderColor: '#cbcbcb',
    borderRadius: 10,
    outline: 'none',
    '&:hover': {
      borderColor: '#000',
    },
    '&:focus': {
      borderColor: focusBorderLineColor,
      borderWidth: 2,
    },
  },
}));

export default ({ loading, maxLength, value: _value, onChange, onKeyDown = (_) => _, ...props }) => {
  const classes = useStyles();
  const [value, setValue] = useState(_value);
  useEffect(() => {
    if (value !== _value) setValue(_value);
  }, [_value]);
  useDebounce(
    () => {
      if (value !== _value) onChange(value);
    },
    250,
    [value],
  );

  if (loading) return <LSkeleton height={90} />;

  const core = (
    <TextareaAutosize
      {...props}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          onChange(value);
        }
        onKeyDown(e);
      }}
      maxLength={maxLength}
      className={classes.textarea}
    />
  );

  if (!maxLength) return core;
  return (
    <StyledTooltip placement={'bottom-end'} title={`${translate.length_count}: ${(value || '').length}/${maxLength}`}>
      <span>{core}</span>
    </StyledTooltip>
  );
};
