import React, { useRef } from 'react';
import AIContainer from './AIContainer';
import Taber from 'components/Taber';
import { Box } from '@material-ui/core';
import { translate } from 'shared/translate';

export default (props) => {
  const { history, location: { pathname } = {} } = props;
  const list = useRef();
  const tabs = [{ name: translate.ai_generated_product, link: '/products_ai' }],
    tab = tabs.findIndex((tab) => tab.link === pathname);

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          list.current && list.current.resetCursor();
          history.push((tabs[tab] || {}).link);
        }}
        tabs={tabs}
        triangleColor={'#366984'}
      />
      <Box>
        <AIContainer
          {...props}
          ref={list}
          filter={{
            active: {
              0: undefined,
            }[tab],
          }}
        />
      </Box>
    </div>
  );
};
