import React, { Fragment } from 'react';
import ControlRow from '../../ControlRow';
import { Box, Tooltip } from '@material-ui/core';
import AddClickRegion from '../../AddClickRegion';
import BodyComponents from '../index';
import { translate } from '../../../../../shared/translate';

export default ({ data, setEditData, onRemoveClick, onUpClick, onDownClick, onChange = (_) => _ }) => {
  const {
    items = [],
    backgroundColor,
    backgroundImage,
    borderColor,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    hasContainer,
    spacing = 0,
    visible = true,
  } = data || {};
  columnsOrdering(items);

  return (
    <ControlRow
      onRemoveClick={onRemoveClick}
      onUpClick={onUpClick}
      onDownClick={onDownClick}
      onEditClick={() => setEditData(data)}
    >
      {marginTop > 0 && (
        <Tooltip title={`${translate.top_margin}: ${marginTop}`}>
          <Box bgcolor={'rgba(255,0,0,0.25)'} height={marginTop} />
        </Tooltip>
      )}
      <Box display={'flex'} flexDirection={'row'}>
        {!!hasContainer && (
          <Tooltip title={translate.use_smaller_container}>
            <Box bgcolor={'rgba(0,0,255,0.25)'} width={30} />
          </Tooltip>
        )}
        {marginLeft > 0 && (
          <Tooltip title={`${translate.left_margin}: ${marginLeft}`}>
            <Box bgcolor={'rgba(255,0,0,0.25)'} width={marginLeft} />
          </Tooltip>
        )}
        <Box
          flex={1}
          display={'flex'}
          style={{
            background: backgroundImage
              ? `url(${backgroundImage}) center center / cover no-repeat rgb(255, 255, 255)`
              : undefined,
            backgroundColor,
            opacity: visible ? 1 : 0.5,
            border: borderColor ? `2px solid ${borderColor}` : undefined,
          }}
        >
          {items.map((obj, i, arr) => (
            <Fragment key={i}>
              <AddClickRegion
                onComponentAdd={(config, { defaultHeight }) => {
                  items.splice(i, 0, {
                    _type: 'column',
                    item: config,
                    height: defaultHeight ?? undefined,
                  });
                  onChange(data);
                  setEditData(config);
                }}
              />
              {i > 0 && spacing > 0 && (
                <Tooltip title={`${translate.column_separator}: ${spacing}`}>
                  <Box bgcolor={'rgba(0,255,0,0.25)'} width={spacing / 2} />
                </Tooltip>
              )}
              <BodyComponents
                data={obj}
                setEditData={setEditData}
                onLeftClick={() => {
                  if (i > 0) {
                    const tmp = items[i - 1];
                    items[i - 1] = items[i];
                    items[i] = tmp;
                    onChange(data);
                  }
                }}
                onRightClick={() => {
                  if (i < arr.length - 1) {
                    const tmp = items[i + 1];
                    items[i + 1] = items[i];
                    items[i] = tmp;
                    onChange(data);
                  }
                }}
                onRemoveClick={() => {
                  items.splice(i, 1);
                  if (items.length > 0) onChange(data);
                  else onRemoveClick();
                }}
                onChange={(update) => {
                  items[i] = update;
                  onChange(data);
                }}
              />
              {arr.length > 1 && i < arr.length - 1 && spacing > 0 && (
                <Tooltip title={`${translate.column_separator}: ${spacing}`}>
                  <Box bgcolor={'rgba(0,255,0,0.25)'} width={spacing / 2} />
                </Tooltip>
              )}
            </Fragment>
          ))}
          <AddClickRegion
            onComponentAdd={(config, { defaultHeight }) => {
              items.push({
                _type: 'column',
                item: config,
                height: defaultHeight ?? undefined,
              });
              onChange(data);
              setEditData(config);
            }}
          />
        </Box>
        {marginRight > 0 && (
          <Tooltip title={`${translate.right_margin}: ${marginRight}`}>
            <Box bgcolor={'rgba(255,0,0,0.25)'} width={marginRight} />
          </Tooltip>
        )}
        {!!hasContainer && (
          <Tooltip title={translate.use_smaller_container}>
            <Box bgcolor={'rgba(0,0,255,0.25)'} width={30} />
          </Tooltip>
        )}
      </Box>
      {marginBottom > 0 && (
        <Tooltip title={`${translate.bottom_margin}: ${marginBottom}`}>
          <Box bgcolor={'rgba(255,0,0,0.25)'} height={marginBottom} />
        </Tooltip>
      )}
    </ControlRow>
  );
};

const columnsOrdering = (items) => {
  items.sort((a, b) => {
    if (a.sortIndex === undefined && b.sortIndex === undefined) return 0;
    else if (a.sortIndex !== undefined && b.sortIndex === undefined) return -1;
    else if (a.sortIndex === undefined && b.sortIndex !== undefined) return 1;
    else if (a.sortIndex !== undefined && b.sortIndex !== undefined) return a.sortIndex - b.sortIndex;
    else return 0;
  });
};
