import React from 'react';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/QFPay Logo-p-500.png')} alt="qfpay" />,
    label: '錢方線下支付',
    value: 'QFPAY_POS',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'POS Key (Optional)',
            type: 'text',
            name: 'metadata.POSKey',
            maxLength: 255,
            placeholder: 'f46b************************aacd',
            inputProps: {
              type: 'password',
              autocomplete: 'new-password',
            },
          },
          {
            label: 'IV (Optional)',
            type: 'text',
            name: 'metadata.IV',
            maxLength: 255,
            placeholder: 'qfpay******_****',
            inputProps: {
              type: 'password',
              autocomplete: 'new-password',
            },
          },
        ]}
      />
    );
  },
  type: 'PAYMENT',
};
