import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { getCommonColumns } from 'shared';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/venues/new',
    selectionMode: true,
    getRowLink: ({ id, name }) => ({
      pathname: `/venues/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        type: 'number',
        width: 1,
        sortBy: 'sortIndex',
      },
      ...getCommonColumns({
        updatedAt: { column: false, filter: undefined },
      }),
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    const { venues } = node || {};
    return venues;
  };
  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
