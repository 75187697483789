import React from 'react';
import { GET_QUERY } from './query';
import { Checkbox, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import CursorBaseList from '../../../../components/CursorBaseList';
import _ from 'lodash';
import CollectionRow from './CollectionRow';
import { parseOSSQuery } from '../../../../shared';

const CollectionField = ({ values, onChange = () => {} }) => {
  const { search } = values || {};
  const { data: allCollectionData = [], mode: allCollectionMode } = values?.selectCollectionData || {};

  function handleAllClick(isAll) {
    if (isAll) {
      onChange({ ...values, selectCollectionData: { mode: 'EXCLUDE', data: [] } });
    } else {
      onChange({ ...values, selectCollectionData: { mode: 'INCLUDE', data: [] } });
    }
  }

  function handleFilterClick(arrayIds) {
    const exist = allCollectionData.some((item) => arrayIds.includes(item[1].id));
    if (exist) {
      const addIds = arrayIds.filter((arrayId) => !allCollectionData.some(([_, { id }]) => id === arrayId));
      const isAll = arrayIds.every((arrayId) => {
        const selected = allCollectionData.map((v) => v[1].id);
        return selected.includes(arrayId);
      });
      onChange({
        ...values,
        selectCollectionData: {
          mode: allCollectionMode,
          data: isAll
            ? allCollectionData.filter((item) => !arrayIds.includes(item[1].id))
            : allCollectionData.concat(
                addIds.map((addId) => [true, { id: addId, selectionData: { mode: 'EXCLUDE', data: [] } }]),
              ),
        },
      });
    } else {
      const selectedCollectionIds = arrayIds.filter(
        (id) => !(allCollectionData.map((v) => v?.[1]?.id) || []).includes(id),
      );
      onChange({
        ...values,
        selectCollectionData: {
          mode: allCollectionMode,
          data: allCollectionData.concat(
            selectedCollectionIds.map((selectedCollectionId) => {
              return [true, { id: selectedCollectionId, selectionData: { mode: 'EXCLUDE', data: [] } }];
            }),
          ),
        },
      });
    }
  }

  return (
    <Table style={{ width: '99%' }}>
      <TableBody>
        <CursorBaseList
          query={GET_QUERY}
          queryProps={{
            fetchPolicy: 'no-cache',
          }}
          variables={{
            id: localStorage.getItem('shopId'),
            limits: 20,
            query: parseOSSQuery(search, ['name', 'code']),
          }}
          getConnection={(data) => data?.node?.collections}
          renderItems={(items, renderItem, totalCount) => {
            const isAllSelected =
              (allCollectionMode === 'EXCLUDE' && !allCollectionData.length) ||
              (allCollectionMode === 'EXCLUDE' && allCollectionData.every(([value]) => value === true)) ||
              (allCollectionMode === 'INCLUDE' &&
                allCollectionData.length === totalCount &&
                allCollectionData.every(([value]) => value === true));

            return (
              <>
                <TableRow>
                  <TableCell colSpan={4} style={{ padding: '12px 16px' }}>
                    <Typography style={{ fontSize: '12px' }}>
                      {translate.all_product_categories || '所有產品分類'}(<span>{totalCount}</span>)
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  key="all"
                  hover
                  onClick={() => {
                    if (!search) handleAllClick(!isAllSelected);
                    else handleFilterClick(items.map((item) => item.id));
                  }}
                  role="checkbox"
                  aria-checked={isAllSelected}
                  tabIndex={-1}
                  selected={isAllSelected}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isAllSelected}
                      inputProps={{
                        'aria-labelledby': 'all',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={'all'}
                    scope="row"
                    padding="none"
                    style={{ height: 44, cursor: 'pointer' }}
                    colSpan={999}
                  >
                    <Typography style={{ color: '#000' }}>
                      {translate.select_all || '全選'}(<span>{totalCount}</span>)
                    </Typography>
                  </TableCell>
                </TableRow>
                {items.map(renderItem)}
              </>
            );
          }}
          renderItem={(item, i) => {
            const selectedCollectionData = values?.selectCollectionData?.data;
            const productMode =
              allCollectionMode === 'INCLUDE' &&
              !!selectedCollectionData.find((selectedCollection) => selectedCollection[1].id === item.id)
                ? 'EXCLUDE'
                : 'INCLUDE';

            let defaultBoolean = false;
            switch (allCollectionMode) {
              case 'EXCLUDE':
                if (!allCollectionData) {
                  defaultBoolean = true;
                } else {
                  defaultBoolean = !allCollectionData.find((selectedCollection) => selectedCollection?.id === item.id);
                }
                break;
              case 'INCLUDE':
                if (!allCollectionData) {
                  defaultBoolean = false;
                } else {
                  defaultBoolean = !!allCollectionData.find((selectedCollection) => selectedCollection?.id === item.id);
                }
                break;
            }

            return (
              <CollectionRow
                index={i}
                item={item}
                defaultBoolean={defaultBoolean}
                defaultProductMode={
                  allCollectionMode === 'EXCLUDE' &&
                  !allCollectionData.find((selectedCollection) => selectedCollection?.id === item.id)
                    ? 'EXCLUDE'
                    : productMode
                }
                value={
                  selectedCollectionData.find((selectedCollection) => selectedCollection?.[1]?.id === item.id) || {}
                }
                onChange={(v) => {
                  const clickedCollection = v[1].selectionData;
                  const clickedCollectionId = v[1].id;
                  const exists = selectedCollectionData.find(
                    (selectedCollection) => selectedCollection?.[1]?.id === clickedCollectionId,
                  );

                  if (!!exists) {
                    const sameCollection = selectedCollectionData.find(
                      (selectedCollection) => selectedCollection?.[1]?.id === item.id,
                    );

                    const same =
                      // selected: EXCLUDE []    + clicked: EXCLUDE [] -> remove
                      (clickedCollection.mode === 'EXCLUDE' && clickedCollection.data.length === 0) ||
                      // selected: INCLUDE ["1"] + clicked: INCLUDE ["1"] -> remove
                      (clickedCollection.mode === 'INCLUDE' &&
                        sameCollection?.[1]?.selectionData?.data[0] === clickedCollection.data[0])
                        ? selectedCollectionData.filter(
                            (selectedCollection) => selectedCollection?.[1]?.id !== clickedCollectionId,
                          )
                        : // selected: EXCLUDE ["1"] + clicked: EXCLUDE ["2"] -> EXCLUDE ["1", "2"]
                          // selected: INCLUDE ["1"] + clicked: INCLUDE ["2"] -> INCLUDE ["1", "2"]
                          [
                            ...selectedCollectionData.filter(
                              (selectedCollection) => selectedCollection?.[1]?.id !== clickedCollectionId,
                            ),
                            [
                              v[0],
                              {
                                id: clickedCollectionId,
                                selectionData: {
                                  mode: clickedCollection.mode,
                                  data: _.uniq(sameCollection?.[1]?.selectionData?.data.concat(clickedCollection.data)),
                                },
                              },
                            ],
                          ];

                    const different =
                      sameCollection?.[1]?.selectionData?.mode !== clickedCollection.mode
                        ? // selected: EXCLUDE []    + clicked: INCLUDE ["1"] -> INCLUDE ["1"]
                          // selected: INCLUDE ["1"] + clicked: EXCLUDE [] -> EXCLUDE []
                          [
                            ...selectedCollectionData.filter(
                              (selectedCollection) => selectedCollection?.[1]?.id !== clickedCollectionId,
                            ),
                            v,
                          ]
                        : // selected: EXCLUDE ["1", "2"] + clicked: EXCLUDE ["3"] -> EXCLUDE ["1", "2", "3"]
                          // selected: INCLUDE ["1", "2"] + clicked: INCLUDE ["3"] -> INCLUDE ["1", "2", "3"]
                          [
                            ...selectedCollectionData.filter(
                              (selectedCollection) => selectedCollection?.[1]?.id !== clickedCollectionId,
                            ),
                            clickedCollection.mode === 'INCLUDE' &&
                            clickedCollection.data.length > sameCollection[1].selectionData.data.length
                              ? [
                                  v[0],
                                  {
                                    id: clickedCollectionId,
                                    selectionData: {
                                      mode: clickedCollection.mode,
                                      data: _.uniq(
                                        sameCollection?.[1]?.selectionData?.data.concat(clickedCollection.data),
                                      ),
                                    },
                                  },
                                ]
                              : // if ('INCLUDE' && add product) { concat } else { return clicked: v }
                                v,
                          ];

                    onChange({
                      ...values,
                      selectCollectionData: {
                        ...values?.selectCollectionData,
                        data:
                          sameCollection?.[1]?.selectionData?.mode === clickedCollection.mode &&
                          sameCollection?.[1]?.selectionData?.data.length === clickedCollection.data.length
                            ? same
                            : different,
                      },
                    });
                  } else {
                    onChange({
                      ...values,
                      selectCollectionData: {
                        ...values?.selectCollectionData,
                        data: [...selectedCollectionData, v],
                      },
                    });
                  }
                }}
              />
            );
          }}
        />
      </TableBody>
    </Table>
  );
};

export default CollectionField;
