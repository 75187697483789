import React from 'react';
import FormRow from '../../../components/FormRow';
import { useTheme } from '@material-ui/core';

const HighlightFormRow = ({ highlight, title, children }) => {
  const theme = useTheme();
  return (
    <FormRow
      title={title}
      titleStyle={
        highlight
          ? {
              color: theme.palette.error.main,
            }
          : undefined
      }
    >
      <div>{children}</div>
    </FormRow>
  );
};

export default HighlightFormRow;
