import { useEffect, useState } from 'react';

const useCountdown = (seconds = 60, { startOnMounted = true } = {}) => {
  const [countdown, setCountdown] = useState(startOnMounted ? seconds : 0);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  return [countdown, () => setCountdown(seconds)];
};

export default useCountdown;
