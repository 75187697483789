import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import useBreakPoint from '../../../components/useBreakPoint';
import SwitchField from '../../../components/FormPageMaker/Fields/SwitchField';
import { translate } from '../../../shared/translate';

const CardContentContainer = ({
  id,
  style,
  children,
  cardTitle,
  cardTitleField,
  cardTitleAfterInput,
  cardSubtitle,
  cardSubtitleField,
  cardSubtitleAfterInput,
  inputField,

  comingSoon,

  hasActive = true,

  disabled: _disabled,
  value,
  onChange = (_) => _,
}) => {
  const disabled = _disabled || comingSoon;
  const breakpoint = useBreakPoint();
  const isMobileView = !!~['xs'].indexOf(breakpoint);
  const [hover, setHover] = useState();

  return (
    <Card
      id={id}
      style={{
        padding: isMobileView ? '10px' : '0 60px',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 6px 16px 0 lightGrey',
        position: 'relative',
        ...style,
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <CardContent>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item xs={9}>
            <Grid container direction="column">
              <Grid item>
                <Grid container alignItems="center">
                  {!!cardTitle && (
                    <Grid item>
                      <Typography variant="h6">{cardTitle}</Typography>
                    </Grid>
                  )}
                  {!!cardTitleField && (
                    <Grid item>
                      <span style={{ margin: '0 10px' }}>{cardTitleField}</span>
                    </Grid>
                  )}
                  {!!cardTitleAfterInput && (
                    <Grid item>
                      <Typography variant="h6">{cardTitleAfterInput}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  {!!inputField && (
                    <Grid item xs={12}>
                      {inputField}
                    </Grid>
                  )}
                  {!!cardSubtitle && (
                    <Grid item>
                      <Typography variant="subtitle2" style={{ margin: '2px 0 0 0' }}>
                        {cardSubtitle}
                      </Typography>
                    </Grid>
                  )}
                  {!!cardSubtitleField && (
                    <Grid item>
                      <span style={{ margin: '0 10px' }}>{cardSubtitleField}</span>
                    </Grid>
                  )}
                  {!!cardSubtitleAfterInput && (
                    <Grid item>
                      <Typography variant="subtitle2" style={{ margin: '2px 0 0 0' }}>
                        {cardSubtitleAfterInput}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {children}
            </Grid>
          </Grid>
          {!!hasActive && (
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <SwitchField checked={value} disabled={disabled} onChange={() => onChange(!value)} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      {!!comingSoon && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            transition: 'all 0.25s',
            opacity: hover ? 1 : 0,
            backgroundColor: 'rgba(255,255,255,0.85)',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant={'h4'}>{translate.coming_soon}</Typography>
        </div>
      )}
    </Card>
  );
};

export default CardContentContainer;
