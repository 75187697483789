import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { GET_QUANTITY } from './query';

const CurrentStockField = ({ item, onChange, refreshStockKey, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [currentStock, setCurrentStock] = useState();
  const { fromWarehouseId } = props;
  const { variation } = item || {},
    { id } = variation || {};
  const {
      data: { node } = {},
      loading: _loading,
      refetch,
    } = useQuery(GET_QUANTITY, {
      variables: {
        id,
        warehouseId: fromWarehouseId,
      },
      skip: !fromWarehouseId || !id,
    }),
    { quantity } = node || {};

  useEffect(() => {
    if (typeof quantity === 'number') setCurrentStock(quantity);
  }, [quantity]);

  useEffect(() => {
    !!refreshStockKey &&
      (async () => {
        setLoading(true);
        const { data } = await refetch({
            id,
            warehouseId: fromWarehouseId,
          }),
          { node } = data || {},
          { quantity } = node || {};
        if (typeof quantity === 'number') setCurrentStock(quantity);
        setLoading(false);
      })();
  }, [refreshStockKey]);

  useEffect(() => {
    if (typeof currentStock === 'number') {
      onChange(currentStock);
    }
  }, [currentStock]);

  if (loading || _loading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton width={60} />
      </div>
    );
  return <Typography align="center">{currentStock ?? '-'}</Typography>;
};

export default CurrentStockField;
