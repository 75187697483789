import { infinityFetch, parseConnection } from '../../index';
import { gql } from '@apollo/client';
import { client } from '../../apollo';

const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: WarehouseFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      ... on CompanyShop {
        id
        availableWarehouses(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          totalCount
          nextCursor
          nodes {
            id
            name
            active
            type
          }
        }
      }
    }
  }
`;

const GET_FALLBACK_WAREHOUSES = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on CompanyShop {
        id
        stockWarehouse {
          id
          name
          active
          type
        }
        reservationWarehouse {
          id
          name
          active
          type
        }
        transferWarehouse {
          id
          name
          active
          type
        }
        returnWarehouse {
          id
          name
          active
          type
        }
      }
    }
  }
`;

export default async function (source) {
  try {
    return await infinityFetch({
      query: GET_QUERY,
      variables: {
        id: source?.id,
      },
      getConnection: (data) => data?.node?.availableWarehouses,
    });
  } catch (e) {
    const { data: { node } = {} } = await client.query({
      query: GET_FALLBACK_WAREHOUSES,
      variables: { id: source?.id },
    });
    return parseConnection(
      [node.stockWarehouse, node.reservationWarehouse, node.transferWarehouse, node.returnWarehouse].filter(Boolean),
    );
  } finally {
  }
}
