import React from 'react';
import { Box, Grid } from '@material-ui/core';
import OrderComments from './OrderComments';
import { translate } from '../../shared/translate';
import { gql } from '@apollo/client';

const REMARK_UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: OrderUpdateInput!) {
    orderUpdate(id: $id, input: $input) {
      id
      remark
    }
  }
`;

const INTERNAL_REMARK_UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: OrderUpdateInput!) {
    orderUpdate(id: $id, input: $input) {
      id
      internalRemark
    }
  }
`;

const RemarkCard = (props) => {
  return (
    <Box mb={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <OrderComments
            {...props}
            type={'remark'}
            title={translate.remark_customer || '客戶備註'}
            remark={props?.values?.remark}
            medias={props?.values?.remarkMedias || []}
            query={REMARK_UPDATE_QUERY}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OrderComments
            {...props}
            type={'internalRemark'}
            title={translate.remark_internal || '內部備註'}
            remark={props?.values?.internalRemark}
            medias={props?.values?.internalRemarkMedias || []}
            query={INTERNAL_REMARK_UPDATE_QUERY}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RemarkCard;
