import React, { useState } from 'react';
import { Box, CardContent, ClickAwayListener, IconButton, Typography, useTheme } from '@material-ui/core';
import 'react-sortable-tree/style.css';
import SortableTree from 'react-sortable-tree';
import { Skeleton } from '@material-ui/lab';
import { translate } from '../../../shared/translate';
import PopoverWrapper from './PopoverWrapper';
import ItemUpdateCard from './ItemUpdateCard';
import { Icon } from '../../../components/IconRender';
import { getRandomString } from '../../../shared';

export default ({ loading, disabled, items = [], onChange: _onChange = (_) => {} }) => {
  const [box, setBox] = useState(undefined);
  const [selectItem, setSelectItem] = useState(undefined);
  const theme = useTheme();

  function onChange(items) {
    const fakeItem = items?.find((item) => item?.id === 'new');
    const fakeItemChildren = fakeItem?.children ?? [];
    _onChange([...fakeItemChildren, ...(items?.filter((item) => item?.id !== 'new') ?? [])]);
  }

  return (
    <>
      <style>{`
.rst__row.selected .rst__moveHandle {
  background-color: ${theme.palette.secondary.main};
}
      `}</style>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 285px)',
          overflow: 'auto',
        }}
      >
        <Typography style={{ margin: `16px 20px` }}>{translate.sortable_remark}</Typography>
        {loading ? (
          Array(5 + ~~(10 * Math.random()))
            .fill(undefined)
            .map((__, i) => (
              <Skeleton
                key={i}
                height={60}
                width={275}
                style={{
                  marginLeft: 43 * ((i % 3) + 1),
                }}
              />
            ))
        ) : (
          <>
            <Box flex={1} position={'relative'}>
              <SortableTree
                getNodeKey={({ node }) => node?.id}
                treeData={[
                  {
                    id: 'new',
                    title: (
                      <>
                        <Icon
                          icon={'faPlus'}
                          style={{
                            verticalAlign: 'text-top',
                            marginRight: 8,
                          }}
                        />
                        {translate.create}
                      </>
                    ),
                  },
                  ...items,
                ]}
                canDrag={({ node }) => !disabled && node?.id !== 'new'}
                canDrop={({ node }) => !disabled && node?.id !== 'new'}
                onChange={onChange}
                maxDepth={3}
                generateNodeProps={({ node, path = [] }) => ({
                  className: selectItem?.id === node?.id ? 'selected' : '',
                  buttons: [
                    path.length < 3 && node?.id !== 'new' && (
                      <IconButton
                        disabled={disabled}
                        size={'small'}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newNode = {
                            id: getRandomString(),
                          };

                          node.expanded = true;
                          node.children = [newNode, ...(node.children ?? [])];
                          onChange(items);
                        }}
                      >
                        <Icon icon={'faPlus'} />
                      </IconButton>
                    ),
                  ].filter(Boolean),
                  style: {
                    cursor: 'pointer',
                  },
                  onClick: disabled
                    ? undefined
                    : node?.id === 'new'
                    ? (e) => {
                        e.stopPropagation();
                        const newNode = {
                          id: getRandomString(),
                        };
                        onChange([newNode, ...items]);
                      }
                    : (e) => {
                        e.stopPropagation();
                        setBox(e?.currentTarget?.getBoundingClientRect() ?? {});
                        setSelectItem(node);
                      },
                })}
              />
            </Box>
          </>
        )}
      </CardContent>
      <ClickAwayListener
        mouseEvent={'onMouseDown'}
        onClickAway={() => {
          const id = selectItem?.id;
          setTimeout(
            () =>
              setSelectItem((item) => {
                if (item?.id === id) return undefined;
                return item;
              }),
            100,
          );
        }}
      >
        <PopoverWrapper box={box} visible={!!selectItem}>
          <ItemUpdateCard
            disabled={disabled}
            key={selectItem?.id}
            value={selectItem}
            onChange={(item) => {
              setSelectItem(item);
              if (item) {
                onChange(updateItemsRecursive(items, item));
              } else {
                onChange(removeItemsRecursive(items, selectItem));
              }

              function removeItemsRecursive(items, item) {
                return items
                  .map((item2) => {
                    if (item2.id === item.id) {
                      return undefined;
                    }
                    if (item2.children) {
                      return {
                        ...item2,
                        children: removeItemsRecursive(item2.children, item),
                      };
                    }
                    return item2;
                  })
                  .filter(Boolean);
              }
              function updateItemsRecursive(items, item) {
                return items.map((item2) => {
                  if (item2.id === item.id) {
                    return item;
                  }
                  if (item2.children) {
                    return {
                      ...item2,
                      children: updateItemsRecursive(item2.children, item),
                    };
                  }
                  return item2;
                });
              }
            }}
          />
        </PopoverWrapper>
      </ClickAwayListener>
    </>
  );
};
