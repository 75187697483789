import React, { memo, useEffect, useState } from 'react';
import ct from 'countries-and-timezones';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { useInterval } from 'react-use';

const TimeDisplay = ({ timezone }) => {
  const [time, setTime] = useState(moment().format('HH:mm'));
  const { utcOffsetStr } = ct.getTimezone(timezone);
  useInterval(() => {
    setTime(moment().format('HH:mm'));
  }, 10000);
  useEffect(() => {
    if (!!timezone) moment.tz.setDefault(timezone);
  }, [timezone]);

  return (
    <Typography>
      UTC{utcOffsetStr} {time}
    </Typography>
  );
};

export default memo(TimeDisplay, (prev, next) => prev.timezone === next.timezone);
