import { useEffect, useState } from 'react';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
const { REACT_APP_ASSETS_URL } = process.env;

const useTpl = () => {
  const [tpl, setTpl] = useState('');
  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(REACT_APP_ASSETS_URL + '/email_templates/template.tpl');
        setTpl(await res.text());
      } catch (e) {
        toast.error(errorParser(e));
      }
    })();
  }, []);

  return tpl;
};

export default useTpl;
