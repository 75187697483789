import React, { useEffect } from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';
import { checkPermissionV2 } from '../../components/PermissionMask';

export default (props) => {
  const { history } = props || {};

  useEffect(() => {
    history.push('/shops?active=IN%5E*true');
  }, []);

  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          { name: translate.shop_deselected },
          checkPermissionV2('viewAllPosDevices') && {
            name: translate.all_pos_devices,
            onClick: () => {
              history.push('/shops/all_devices');
            },
          },
        ].filter((_) => _)}
      />
      <Box>
        <ListView {...props} />
      </Box>
    </div>
  );
};
