import React from 'react';
import { TableRow, Typography } from '@material-ui/core';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import { PriceFormat } from '../../../shared';
import StyledTableCell from './StyledTableCell';
import RowEditUnitPrice from './RowEditUnitPrice';

export const RowOrderItem = ({ item, orderStatus }) => {
  const { sku, productVariation, quantity, unitPrice, description, remark, media, id } = item || {};
  const { sku: pvSku, combination = [] } = productVariation || {};
  const image = (media || {}).src;

  return (
    <>
      <TableRow hover>
        <StyledTableCell>
          <Typography>{pvSku || sku}</Typography>
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: 'center' }}>
          <img
            src={image || require('../../../assets/gray.jpg')}
            style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: 30 }}
            alt={description}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ whiteSpace: 'pre' }}>{description}</Typography>
          <VariationCombinationDisplay combination={combination} remark={remark} />
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: 'center' }}>
          {orderStatus === 'PENDING' && <RowEditUnitPrice item={item} id={id} />}
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ textAlign: 'right' }}>{PriceFormat(unitPrice - 0)}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ textAlign: 'center' }}>{quantity}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography style={{ textAlign: 'left' }}>{PriceFormat((unitPrice - 0) * quantity)}</Typography>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default RowOrderItem;
