import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/attributes/new',
    getRowLink: ({ name, id }) => ({
      pathname: '/attributes/' + id,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.system || '參數目標',
        fieldName: 'system',
        width: 50,
        type: 'bool',
        filter: 'system',
        sortBy: 'system',
      },
      {
        title: translate.attribute_target || '參數目標',
        fieldName: 'typename',
        sortBy: 'typename.keyword',
        type: 'option',
        width: 50,
        options: [
          {
            label: translate.product,
            value: 'ShopProducts',
          },
        ],
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
        filter: 'name',
        filterType: 'text',
      },
      {
        title: translate.type,
        fieldName: 'type',
        sortBy: 'type.keyword',
        type: 'option',
        filter: 'type',
        options: [
          {
            label: translate.text,
            value: 'TEXT',
          },
          {
            label: translate.number,
            value: 'NUMBER',
          },
          {
            label: translate.color,
            value: 'COLOR',
          },
        ],
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        sortBy: 'sortIndex',
        type: 'number',
      },
      {
        title: translate.availability,
        align: 'center',
        width: 50,
        fieldName: 'active',
        type: 'bool',
        filter: 'active',
        sortBy: 'active',
      },
    ],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  getData = (data) => {
    return data?.node?.attributes;
  };
}
