import { gql } from '@apollo/client';

export const FRAGMENT_CUSTOMER = gql`
  fragment FRAGMENT_CUSTOMER on Customer {
    id
    phoneNumber
    email
    picture
    giftPoints
    createdAt
    updatedAt
    lastLogin
    hashtags
    metadata {
      key
      value
    }
    blocked
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: CustomerFilterInput) {
    node(id: $id) {
      id
      ... on Company {
        customers(query: $query, cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_CUSTOMER
          }
        }
      }
    }
  }
  ${FRAGMENT_CUSTOMER}
`;
