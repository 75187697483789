import React, { useState, useEffect } from 'react';
import { Badge, CircularProgress, IconButton } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_LATEST_MESSAGES } from './query';
import { JSONParseSafely, parseConnection } from 'shared';
import MessageMenu from './MessageMenu';
import { Icon } from '../../../../../components/IconRender';

export default () => {
  const shopId = localStorage.getItem('shopId');
  const { loading, data, refetch } = useQuery(GET_LATEST_MESSAGES, {
      skip: !shopId,
      pollInterval: process.env.NODE_ENV === 'development' ? undefined : 3000,
      variables: {
        id: shopId,
        playerId: shopId,
      },
    }),
    systemMessage = parseLatestSystemMessage(data),
    customerMessages = parseLatestCustomerMessages(data);

  const nodes = [systemMessage, ...customerMessages].filter((_) => _);

  const unread = nodes.reduce((reducer, node) => {
    return +reducer + (node?.read ? 0 : 1);
  }, 0);

  useEffect(() => {
    if (!loading && !!shopId) refetch();
  }, [shopId]);

  const [anchor, setAnchor] = useState(undefined);

  // useSubscription(CHANNEL_MESSAGE_SUBSCRIPTION, {
  //   variables: {
  //     shopId,
  //   },
  //   skip: !shopId,
  //   onSubscriptionData: (res) => {
  //     const { subscriptionData: { data } = {} } = res || {};
  //     const { channelMessageUpdate } = data || {};
  //     if (!!channelMessageUpdate) {
  //       refetch();
  //     }
  //   },
  // });

  const { status, progress } = getPossibleProcessStatus(nodes);
  return (
    <>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)} disabled={!shopId}>
        <Badge variant={'dot'} invisible={unread === 0} color="secondary">
          <Icon icon={'NotificationsNoneOutlined'} fontSize={'default'} type={'material'} />
        </Badge>
        {status === 'PENDING' && <CircularProgress size={25} color={'secondary'} style={{ position: 'absolute' }} />}
        {status === 'RUNNING' && (
          <CircularProgress
            size={25}
            color={'secondary'}
            variant={'determinate'}
            value={+progress * 100}
            style={{ position: 'absolute' }}
          />
        )}
      </IconButton>
      <MessageMenu messages={nodes} onClose={() => setAnchor(undefined)} anchor={anchor} />
    </>
  );
};

function getPossibleProcessStatus(messages) {
  const map = {};
  (messages || []).forEach(({ process }) => {
    const { status, output } = process || {},
      { progress } = JSONParseSafely(output, {});
    map[status] = true;
    map.progress = Math.max(progress || 0, 0);
  });

  if (map.PENDING) return { status: 'PENDING' };
  else if (map.RUNNING) {
    if (map.progress) return { status: 'RUNNING', progress: map.progress };
    return { status: 'PENDING' };
  } else if (map.SUCCESS) return { status: 'SUCCESS' };
  else if (map.FAILURE) return { status: 'FAILURE' };
  return '';
}

function parseLatestSystemMessage({ node } = {}) {
  try {
    const channel = parseConnection(node.systemChannels).nodes[0];
    return { ...parseConnection(channel.messages).nodes[0], channel };
  } catch (e) {
    return undefined;
  }
}
function parseLatestCustomerMessages({ node } = {}) {
  try {
    const channels = parseConnection(node.otherChannels).nodes;
    return channels.map((channel) => ({ ...parseConnection(channel.messages).nodes[0], channel }));
  } catch (e) {
    return [];
  }
}
