import React, { Component } from 'react';
import { translate } from 'shared/translate';
import OmniweLoading from '../OmniweLoading';
import { Typography, Card, CardContent } from '@material-ui/core';

let inst;
export default class ActivityIndicator extends Component {
  state = {
    show: false,
    msg: '',
  };
  componentDidMount() {
    inst = this;
  }
  componentWillUnmount() {
    inst = null;
  }
  static show(...args) {
    !!inst && inst.show(...args);
  }
  static hide() {
    !!inst && inst.hide();
  }

  show(msg = translate.loading) {
    this.setState({ msg, show: true });
  }
  hide() {
    this.setState({ msg: '', show: false });
  }
  render() {
    const { show, msg } = this.state;
    if (!show) return null;
    return (
      <Card
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          top: '50%',
          left: '50%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 99999,
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CardContent>
          <OmniweLoading size={0.75} loading={true} />
          {!!msg && (
            <Typography variant={'h6'} component={'p'} style={{ color: 'white' }} align={'center'}>
              {msg}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
}
