import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';

const SHOP_ACTIVATE = gql`
  mutation ($id: ID!) {
    companyShopActivate(id: $id) {
      id
      active
    }
  }
`;

const SHOP_DEACTIVATE = gql`
  mutation ($id: ID!) {
    companyShopDeactivate(id: $id) {
      id
      active
    }
  }
`;

export default ({ id, active }) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    if (active !== isActive) {
      setIsActive(active);
    }
  }, [active]);

  const handleMutation = async () => {
    try {
      setLoading(true);
      if (isActive) {
        await client.mutate({
          mutation: SHOP_DEACTIVATE,
          variables: { id: id || undefined },
        });
        setIsActive(false);
      } else {
        await client.mutate({
          mutation: SHOP_ACTIVATE,
          variables: { id: id || undefined },
        });
        setIsActive(true);
      }
    } catch (e) {
      handleMutationError(e);
      setIsActive(!isActive);
    } finally {
      setLoading(false);
    }
  };

  const handleMutationError = (error) => {
    const errorMessage = errorParser(error);
    toast.error(errorMessage);
  };

  return <SwitchField checked={isActive} value={isActive} disabled={loading} onChange={handleMutation} />;
};
