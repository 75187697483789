import { gql } from '@apollo/client';

export const GET_DELIVERY_NOTE_IDS = gql`
  query (
    $id: ID!
    $query: String
    $cursor: Int
    $limits: Int
    $filter: DeliveryNotesFilterInput
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        id
        deliveryNotes(query: $query, cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            id
            provider
            referenceNo
          }
        }
      }
    }
  }
`;
