import { gql } from '@apollo/client';
export const FRAGMENT_RECEIVE_PURCHASE = gql`
  fragment FRAGMENT_RECEIVE_PURCHASE on CompanyReceivePurchase {
    id
    createdAt
    updatedAt
    warehouse {
      id
      name
    }
    referenceNo
    staff {
      id
      name
    }
    status
    totalQuantity
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $query: String, $limits: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Company {
        id
        receivePurchases(query: $query, cursor: $cursor, limits: $limits, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_RECEIVE_PURCHASE
          }
        }
      }
    }
  }
  ${FRAGMENT_RECEIVE_PURCHASE}
`;
