import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { COMPLETE_QUERY, GET_QUERY, GET_TRANSFER_WAREHOUSE, SUBMIT_QUERY, UPDATE_QUERY } from './query';
import { translate } from '../../shared/translate';
import SelectComboWarehouse from '../../components/SelectCombo/SelectComboWarehouse';
import LSkeleton from '../../components/LSkeleton';
import { cloneObject, parseConnection } from '../../shared';
import { client } from '../../shared/apollo';
import ItemsCard from './ItemsCard';
import TransferApproveButton from './TransferApproveButton';
import TransferRejectButton from './TransferRejectButton';
import { ReactComponent as newPrintIcon } from '../../assets/icon/newPrint.svg';
import { StockTransferStatus } from '../../shared/omniwe-types';
import StatusBadge from '../../components/StatusBadge';
import { Skeleton } from '@material-ui/lab';
import { checkPermissionV2 } from '../../components/PermissionMask';
import TransferImportButton from './TransferImportButton';
import SystemButton from '../../components/SystemButton';
import { Icon } from '../../components/IconRender';
import _ from 'lodash';
import { CardContent, Divider, Typography } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';

class ItemTransfer extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: checkPermissionV2('updateTransfer') ? SUBMIT_QUERY : undefined,
      update: checkPermissionV2('updateTransfer') ? UPDATE_QUERY : undefined,
    },
    editMode: !!this.state.id,
    formDisabled: !checkPermissionV2('updateTransfer'),
    tabs: [
      {
        name: translate.inventory_transfer,
        cards: [
          {
            fields: [
              {
                label: translate.status,
                render: ({ loading, values: { status } }) =>
                  loading ? (
                    <Skeleton width={100} height={30} />
                  ) : (
                    <StatusBadge status={status} statusObject={StockTransferStatus} />
                  ),
                md: 6,
              },
              {
                label: translate.reference_no,
                name: 'referenceNo',
                type: 'text',
                disabled: true,
                md: 6,
              },
              {
                label: translate.outbound_date,
                md: 6,
                disabled: true,
                name: 'sentAt',
                type: 'datetime-local',
              },
              {
                label: translate.inbound_date,
                name: 'completedAt',
                disabled: true,
                type: 'datetime-local',
                md: 6,
              },
              {
                label: translate.from_warehouse,
                required: true,
                md: 6,
                render: ({ loading, values, isSubmitting, setFieldValue }) => {
                  const { status } = values || {};
                  const enable = !!~['PENDING'].indexOf(status);
                  return loading ? (
                    <LSkeleton height={30} />
                  ) : (
                    <SelectComboWarehouse
                      value={values.outboundWarehouse}
                      variables={{ query: 'active:true' }}
                      required
                      disabled={isSubmitting || !enable}
                      onChange={(v) => setFieldValue('outboundWarehouse', v)}
                    />
                  );
                },
              },
              {
                label: translate.to_warehouse,
                required: true,
                md: 6,

                render: ({ loading, values, isSubmitting, setFieldValue }) => {
                  const { status } = values || {};
                  const enable = !!~['PENDING'].indexOf(status);
                  return loading ? (
                    <LSkeleton height={30} />
                  ) : (
                    <SelectComboWarehouse
                      value={values.inboundWarehouse}
                      variables={{ query: 'active:true' }}
                      required
                      disabled={isSubmitting || !enable}
                      onChange={(v) => setFieldValue('inboundWarehouse', v)}
                    />
                  );
                },
              },
              {
                md: 12,
                render: ({ loading, values, disabled, setFieldValue }) => {
                  const allowEdit = !values?.id || (this.state.dirty && values?.status === 'PENDING');
                  return (
                    <CardContent style={{ backgroundColor: '#f6f6f6', borderRadius: 10, padding: 30 }}>
                      <Typography variant={'h6'}>{translate.remark}</Typography>
                      <Divider style={{ margin: '10px 0 15px 0' }} />
                      {loading ? (
                        <LSkeleton height={30} />
                      ) : (
                        <InputField
                          variant={allowEdit ? 'outlined' : 'standard'}
                          InputProps={{
                            disableUnderline: allowEdit ? false : true,
                          }}
                          value={values.remark}
                          disabled={disabled || values?.status !== 'PENDING'}
                          onChange={(v) => setFieldValue('remark', v?.target?.value)}
                        />
                      )}
                    </CardContent>
                  );
                },
              },
            ],
          },
          ItemsCard,
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit, update },
    } = this.state;
    const { data: _data } = await client.query({
        query: GET_TRANSFER_WAREHOUSE,
        variables: { id: localStorage.getItem('shopId') },
      }),
      { node } = _data || {},
      { transferWarehouse } = node || {};

    if (values.status === 'IN_TRANSIT') {
      if (!checkPermissionV2('completeTransfer'))
        throw new Error(translate.no_permission_to_complete_transfer || '錯誤︰僅限授權人士確認');
      await client.mutate({
        mutation: COMPLETE_QUERY,
        variables: {
          id: this.state.id,
          items: (values.items || []).map((item) => ({
            id: item?.id,
            sku: item?.sku,
            quantity: item?.receivedQuantity,
          })),
        },
      });
    } else {
      await client.mutate({
        mutation: !!this.state.id ? update : submit,
        variables: {
          id: this.state.id,
          input: _.omitBy(
            {
              shopId: !!this.state.id ? undefined : localStorage.getItem('shopId'),
              inboundWarehouseId: values.inboundWarehouse.id,
              outboundWarehouseId: values.outboundWarehouse.id,
              transferWarehouseId: transferWarehouse.id,
              remark: values.remark,
              items: (() => {
                const itemsGroup = _.groupBy(values.items, 'sku');
                return Object.entries(itemsGroup).map(([sku, items]) => ({
                  sku,
                  quantity: _.sumBy(items, (item) => +item?.quantity || 0),
                }));
              })(),
            },
            _.isNil,
          ),
        },
      });
    }
    return true;
  };
  onCompleted(data) {
    const status = data?.node?.status;
    if (status !== 'PENDING') {
      this.setState({ editMode: false });
    }
  }

  getInitialData({ node } = {}) {
    const {
      id,
      inboundWarehouse,
      outboundWarehouse,
      updatedAt,
      itemsV2,
      status = 'PENDING',
      referenceNo,
      completedAt,
      staff,
      sentAt,
      remark,
    } = node || {};
    return {
      id,
      inboundWarehouse,
      outboundWarehouse,
      updatedAt,
      items:
        status === 'IN_TRANSIT'
          ? cloneObject(
              parseConnection(itemsV2).nodes?.map(({ productVariation, receivedQuantity, ...item }) => ({
                ...item,
                variation: productVariation,
                receivedQuantity: receivedQuantity || item.quantity,
              })),
            )
          : cloneObject(
              parseConnection(itemsV2).nodes?.map(({ productVariation, ...item }) => ({
                ...item,
                variation: productVariation,
              })),
            ),
      status,
      referenceNo,
      completedAt,
      staff,
      sentAt,
      remark,
    };
  }

  allowSubmit(actions) {
    const {
      values: { status },
    } = actions;
    if (!super.allowSubmit(actions)) return false;
    if (status !== 'PENDING' && status !== 'IN_TRANSIT') return false;
    return true;
  }

  onCompleted(data) {
    if (data.node.status !== 'PENDING' && data.node.status !== 'IN_TRANSIT') {
      this.setState({
        editMode: false,
      });
    }
  }

  getConfirmText(actions) {
    const {
      values: { status },
    } = actions;
    if (status === 'IN_TRANSIT') return translate.confirm_received || '確認收貨';
  }

  renderExtraButtons(actions) {
    const { values: { id, status, __disabled } = {}, isSubmitting } = actions || {};
    return (
      <>
        {!!id && status === 'PENDING' && __disabled && <TransferImportButton id={id} />}
        {!!id && __disabled && (
          <SystemButton
            onClick={(e) => {
              e.stopPropagation();
              window && window.open(`/print_transfers/${id}`, '_blank');
            }}
          >
            <Icon type={'svg'} icon={newPrintIcon} viewBox={'0 0 21 21'} style={{ height: 20.44 }} />
          </SystemButton>
        )}
        {checkPermissionV2('approveTransfer') && !!id && status === 'PENDING' && __disabled && (
          <TransferApproveButton {...actions} />
        )}
        {checkPermissionV2('rejectTransfer') && !!id && status === 'PENDING' && __disabled && (
          <TransferRejectButton {...actions} />
        )}
        {checkPermissionV2('completeTransfer') && !!id && status === 'IN_TRANSIT' && __disabled && (
          <SystemButton color="omniewRed" type="submit" loading={isSubmitting} disabled={isSubmitting}>
            {translate.confirm_received || '確認收貨'}
          </SystemButton>
        )}
      </>
    );
  }
}

export default ItemTransfer;
