import React from 'react';
import Taber from '../../components/Taber';
import useQueryParams from '../../hooks/useQueryParams';
import { translate } from '../../shared/translate';
import EmailWelcome from './EmailWelcome';
import EmailOrderConfirm from './EmailOrderConfirm';
import { Box } from '@material-ui/core';
import EmailPasswordReset from './EmailPasswordReset';
import EmailPasswordUpdated from './EmailPasswordUpdated';
import EmailShippingNotification from './EmailShippingNotification';
import EmailOrderCancel from './EmailOrderCancel';
import EmailBlock from './EmailBlock';
import EmailAppointmentConfirmation from './EmailAppointmentConfirmation';
import EmailAppointmentUpdate from './EmailAppointmentUpdate';
import EmailAppointmentCancel from './EmailAppointmentCancel';
import { isDevelopment, isProduction } from '../../shared';

const ItemEmailTemplate = (props) => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;

  const tabs = [
    {
      name: translate.welcome_email || '歡迎電郵',
      component: EmailWelcome,
    },
    {
      name: translate.order_confirm_email || '訂單確認電郵',
      component: EmailOrderConfirm,
    },
    {
      name: translate.shipping_notification || '發貨通知',
      component: EmailShippingNotification,
    },
    !isProduction() && {
      name: translate.cancel_order_notify || '撤銷訂單通知',
      component: EmailOrderCancel,
    },
    {
      name: translate.password_reset_email || '重設密碼電郵',
      component: EmailPasswordReset,
    },
    {
      name: translate.password_updated_email || '密碼更新通知',
      component: EmailPasswordUpdated,
    },
    isDevelopment() && {
      name: translate.blocked_notification || '帳戶封鎖通知',
      component: EmailBlock,
    },
    !isProduction() && {
      name: translate.appointment_confirmation_email || '預約確認電郵',
      component: EmailAppointmentConfirmation,
    },
    !isProduction() && {
      name: translate.appointment_update_email || '預約更新電郵',
      component: EmailAppointmentUpdate,
    },
    !isProduction() && {
      name: translate.appointment_cancel_email || '預約取消電郵',
      component: EmailAppointmentCancel,
    },
  ].filter((_) => _);
  const Module = tabs?.[tab]?.component || Box;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery({
            tab,
          });
        }}
        tabs={tabs}
      />
      <Module />
    </div>
  );
};

export default ItemEmailTemplate;
