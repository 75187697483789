import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_QUERY } from './query';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment/moment';
import { dayRangeHeaderFormat, parseConnection, stringToColour } from '../../../shared';
import CustomToolBar from '../../item_ServiceLocation/ScheduleCalendar/CustomToolBar';
import Color from 'color';
import EventModal from './EventModal';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core';

const AppointmentCalendar = () => {
  const theme = useTheme();
  const history = useHistory();
  const localizer = momentLocalizer(moment);
  const [range, setRange] = useState({
    startedAt: moment().startOf('month').subtract(7, 'day').toISOString(),
    startedThru: moment().endOf('month').add(7, 'day').toISOString(),
  });
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const durationMins = 60;

  const { loading, data } = useQuery(GET_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        startedAt: {
          operator: 'BETWEEN',
          value: [range.startedAt, range.startedThru],
        },
      },
    },
  });
  const appointments = parseConnection(data?.appointments).nodes;

  const events = Object.values(
    _.groupBy(appointments, (ap) => {
      return [ap?.service?.id, ap?.startedAt, ap?.startedThru].join('-');
    }),
  ).map((value) => {
    return {
      serviceBundle: value?.[0]?.serviceBundle,
      service: value?.[0]?.service,
      startedAt: value?.[0]?.startedAt,
      startedThru: value?.[0]?.startedThru,
      appointments: value,
    };
  });

  return (
    <div style={{ height: 700, position: 'relative', marginTop: '5px' }}>
      <Calendar
        formats={{ dayRangeHeaderFormat }}
        components={{
          toolbar: (props) => <CustomToolBar {...props} loading={loading} />,
        }}
        step={15}
        timeslots={4}
        localizer={localizer}
        eventPropGetter={(event) => {
          const serviceId = event?.resource?.service?.id;
          const backgroundColor = Color(stringToColour(serviceId)).mix(Color(theme.palette.primary.main), 0.4).hex();
          const color = Color(backgroundColor).contrast(Color('black')) > 11 ? '#000' : '#ffffff';
          return {
            style: {
              backgroundColor,
              color,
              left: 0,
              width: '100%',
            },
          };
        }}
        events={events?.reduce(
          (prev, event) => [
            ...prev,
            {
              start: moment.utc(event.startedAt).toDate(),
              end: moment.utc(event.startedThru).toDate(),
              title: `${moment(event?.startedAt).format('HH:mm')}【${event?.serviceBundle?.name}】${
                event?.service?.name
              }`,
              allDay: false,
              resource: event,
            },
          ],
          [],
        )}
        startAccessor="start"
        endAccessor="end"
        selectable={true}
        onSelectSlot={() => {
          setSelectedEvent(undefined);
        }}
        onSelectEvent={({ resource }, e) => {
          if (loading) return;
          setSelectedEvent({ resource, anchor: e.target });
        }}
        onRangeChange={(args) => {
          let start, end;
          if (_.isArray(args)) {
            start = args[0];
            end = args[args?.length - 1];
          } else if (args?.start && args?.end) {
            start = args.start;
            end = args.end;
          }
          setRange({
            startedAt: moment(start).subtract(1, 'day').toISOString(),
            startedThru: moment(end).add(1, 'day').toISOString(),
          });
        }}
        popup={true}
      />
      <EventModal
        selectedEvent={selectedEvent}
        onClose={() => {
          setSelectedEvent(undefined);
        }}
        onAppointmentClick={(appointment) => {
          history.push({
            pathname: `/appointments/${appointment?.id}`,
            state: { title: appointment?.referenceNo },
          });
        }}
      />
    </div>
  );
};

export default AppointmentCalendar;
