import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { translate } from '../../shared/translate';
import FormRow from '../../components/FormRow';
import toast from '../../shared/toast';
import LSkeleton from '../../components/LSkeleton';
import SelectComboCoupon from '../../components/SelectCombo/SelectComboCoupon';

const ExcludedDiscountCodesAndCouponsCard = ({
  values: { id, excludedCoupons = [] },
  setFieldValue,
  isSubmitting,
  loading,
}) => {
  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={1}>
          <FormRow title={translate.exclude_the_following_discount_codes_and_coupons}>
            {loading ? (
              <LSkeleton height={30} />
            ) : (
              <SelectComboCoupon
                multiple
                disabled={isSubmitting}
                value={excludedCoupons}
                onChange={(coupons) => {
                  if (coupons.find((coupon) => coupon.id === id)) {
                    toast.error(translate.cannot_exclude_the_current_discount_code_and_coupon);
                  } else {
                    setFieldValue('excludedCoupons', coupons);
                  }
                }}
              />
            )}
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExcludedDiscountCodesAndCouponsCard;
