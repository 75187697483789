import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, MenuItem, Typography } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import { Formik } from 'formik';
import FormRow from '../../../../components/FormRow';
import moment from 'moment';
import toast from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { client } from '../../../../shared/apollo';
import { POINT_RECORD_EXPORT } from './query';
import SelectCombo from '../../../../components/SelectCombo';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import FilterDatetime from '../../../../components/ConnectionPageMaker/Filter/FilterDatetime';
import FormToolbar from '../../../../components/FormToolbar';
import { parseConnection } from '../../../../shared';
import _ from 'lodash';

const options = [
  {
    label: translate.orders,
    value: 'order',
  },
  {
    label: translate.point_expiry_dates,
    value: 'memberPointExpiry',
  },
  {
    label: translate.coupons,
    value: 'coupon',
  },

  {
    label: translate.manual_adjustment,
    value: 'customAdjust',
  },
];

const ImportModal = ({ customerCount, infinityFetchCustomers = async (_) => undefined, onClick = (_) => _ }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <MenuItem
        disabled={!customerCount}
        onClick={() => {
          onClick();
          setVisible(true);
        }}
      >
        {translate.export_point_record}
      </MenuItem>
      <Formik
        initialValues={{
          createdAt: [],
          memberPointResetDates: [],
          metadataValue: [],
        }}
        onSubmit={async ({ createdAt, memberPointResetDates, metadataValue }) => {
          try {
            ActivityIndicator.show();
            const metadataValueArr = metadataValue.reduce((reducer, { value }) => {
              switch (value) {
                case 'order':
                  return [...reducer, 'checkout.pay.update', 'checkout.create.offline', 'delivery_note.process'];
                case 'memberPointExpiry':
                  return [...reducer, 'customer.member_point.expiry'];
                case 'coupon':
                  return [...reducer, 'coupon.redeem'];
                case 'customAdjust':
                  return [
                    ...reducer,
                    'customers.update.member_point',
                    'customer.update.member_point',
                    'order.update.member_point',
                  ];
                default:
                  return reducer;
              }
            }, []);

            const filterCreatedAt = !!createdAt?.filter((_) => _).length
              ? {
                  createdAt: {
                    operator: 'BETWEEN',
                    value: createdAt,
                  },
                }
              : {};
            const filterMemberPointResetDates = !!memberPointResetDates?.filter((_) => _).length
              ? {
                  memberPointResetDates: {
                    operator: 'BETWEEN',
                    value: memberPointResetDates,
                  },
                }
              : {};

            const filterCustomerId = await (async () => {
              if (customerCount < 200) {
                const customers = parseConnection(await infinityFetchCustomers()).nodes;
                return {
                  customerId: {
                    operator: 'IN',
                    value: _.map(customers, 'id').filter((_) => _),
                  },
                };
              } else return undefined;
            })();

            await client.mutate({
              mutation: POINT_RECORD_EXPORT,
              variables: {
                shopId: localStorage.getItem('shopId'),
                filter: {
                  ...filterCreatedAt,
                  ...filterMemberPointResetDates,
                  ...filterCustomerId,
                  ...(!!metadataValue.length
                    ? {
                        metadataValue: {
                          operator: 'MATCH',
                          value: metadataValueArr,
                        },
                      }
                    : {}),
                },
              },
            });
          } catch (error) {
            toast.error(errorParser(error));
          } finally {
            ActivityIndicator.hide();
            setVisible(false);
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          const { createdAt, memberPointResetDates, metadataValue } = values || {};

          return (
            <Dialog open={visible} onClose={() => setVisible(false)}>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <DialogContent>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant={'h6'}>
                        {translate.formatString(translate['n_customers_selected'], customerCount)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FilterDatetime
                        title={translate.adjust_date}
                        filter={{ value: createdAt }}
                        onFilterChange={(e) => setFieldValue('createdAt', e?.value)}
                        filterInputProps={{
                          untilProps: {
                            inputProps: {
                              min: !!createdAt?.[0] && moment(createdAt?.[0]).format('YYYY-MM-DDTHH:mm'),
                              required: !!createdAt?.filter((_) => _).length,
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FilterDatetime
                        title={translate.point_effective_date}
                        filter={{ value: memberPointResetDates }}
                        onFilterChange={(e) => setFieldValue('memberPointResetDates', e?.value)}
                        filterInputProps={{
                          untilProps: {
                            inputProps: {
                              min:
                                !!memberPointResetDates?.[0] &&
                                moment(memberPointResetDates?.[0]).format('YYYY-MM-DDTHH:mm'),
                              required: !!memberPointResetDates?.filter((_) => _).length,
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormRow title={translate.remark}>
                        <SelectCombo
                          multiple
                          disablePortal={false}
                          value={metadataValue}
                          options={options}
                          onChange={(e) => {
                            setFieldValue('metadataValue', e);
                          }}
                        />
                      </FormRow>
                    </Grid>
                  </Grid>
                </DialogContent>
                <FormToolbar
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onCancel={() => setVisible(false)}
                  submitBtnType={'submit'}
                />
              </form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default ImportModal;
