import { gql } from '@apollo/client';

export const FRAGMENT_STAFF = gql`
  fragment FRAGMENT_STAFF on CompanyStaff {
    id
    updatedAt
    createdAt
    active
    address {
      lines
      city
      country
    }
    code
    name
    password
    phoneNumber
    role
    startedAt
    thumbnail {
      src
      optimizedSrc(width: 256, height: 256)
    }
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_STAFF
    }
  }
  ${FRAGMENT_STAFF}
`;

export const CREATE_QUERY = gql`
  mutation ($input: CompanyStaffCreateInput!) {
    staffCreate(input: $input) {
      ...FRAGMENT_STAFF
    }
  }
  ${FRAGMENT_STAFF}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: CompanyStaffUpdateInput!) {
    staffUpdate(id: $id, input: $input) {
      ...FRAGMENT_STAFF
    }
  }
  ${FRAGMENT_STAFF}
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    staffDelete(id: $id) {
      id
    }
  }
`;
