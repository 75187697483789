import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { convertMetaObject, getCommonColumns, parseConnection } from '../../../shared';
import { translate } from '../../../shared/translate';
import MenuExport from './MenuExport';
import { parseCustomerName } from '../../../shared';
import EllipsisTypography from '../../../components/EllipsisTypography';
import { ShopFormRecordStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    hasQSearch: true,
    fields: [
      {
        title: translate.unique_field,
        render: (row) => {
          const { metadataFields } = row?.form ?? {};
          const uniqueField = metadataFields?.find((field) => field?.unique);
          if (uniqueField)
            return (
              <EllipsisTypography>
                <small style={{ marginTop: -8 }}>{uniqueField?.name}</small>
                <br />
                {convertMetaObject(row?.metadata)[uniqueField?.key]}
              </EllipsisTypography>
            );
          return <EllipsisTypography>{row?.id}</EllipsisTypography>;
        },
      },
      {
        title: translate.customer,
        value: (row) => parseCustomerName(row?.customer) || translate.guest,
      },
      {
        title: translate.status,
        render: ({ status }) => <StatusBadge status={status} statusObject={ShopFormRecordStatus} />,
        width: 100,
        filter: 'status',
        type: 'option',
        options: Object.keys(ShopFormRecordStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={ShopFormRecordStatus} />,
          value: key,
        })),
        filterChannel: 'q',
      },
      ...getCommonColumns({
        createdAt: { filterChannel: 'q' },
        updatedAt: { filterChannel: 'q' },
      }),
    ],
    menus: [MenuExport],
    selectionMode: false,
    getRowLink: ({ id }) => {
      const { location } = this.props;
      return { pathname: `${location.pathname}/${id}` };
    },
  };

  getData = ({ node: form } = {}) => {
    const { nodes, totalCount, nextCursor } = parseConnection(form?.records);
    return {
      nodes: nodes.map((node) => ({
        ...node,
        form,
      })),
      totalCount,
      nextCursor,
    };
  };

  getExtraFetchVariables() {
    const { id } = this.props.match.params;
    return { id };
  }
}
