import { gql } from '@apollo/client';

export const SHOP_EMAIL_VERIFY_QUERY = gql`
  mutation($token: String!) {
    shopContactEmailVerify(token: $token) {
      id
    }
  }
`;

export const USER_VERIFY = gql`
  mutation($token: String!) {
    userVerify(token: $token) {
      id
    }
  }
`;
