import React from 'react';
import { Card, CardContent, Grid, Box } from '@material-ui/core';
import { translate as t } from 'shared/translate';
import FormRow from 'components/FormRow';
import getRemark from '../getRemark';
import TargetField from './TargetField';
import { Icon } from '../../../components/IconRender';
import LSkeleton from '../../../components/LSkeleton';
import { textColor } from '../../../theme';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import TrashButton from '../../../components/TrashButton';

export const defaultTrigger = { triggerType: 'ALL', triggerValueType: 'AMOUNT', triggerValue: 0 };

export default ({ loading, values, values: { triggers, repeat }, setFieldValue, isSubmitting }) => {
  const noMoreTrigger = ((triggers || [])[0] || {}).triggerType === 'ALL';
  const noExcludeProductTrigger = !!(triggers || []).find((trigger) =>
    ['PRODUCT', 'ORDER'].includes(trigger.triggerType),
  );

  return (
    <Card square elevation={0}>
      <CardContent>
        <Grid container direction={'column'} spacing={3}>
          <FormRow key={triggers.length} title={t.condition_setting}>
            <StyledConfigList>
              <Box p={2}>
                <Grid container spacing={1}>
                  {triggers.map((trigger, i) => (
                    <Grid key={i} item xs={12}>
                      <Grid container spacing={1} alignItems={'center'}>
                        <Grid item xs={true}>
                          {loading ? (
                            <LSkeleton height={30} />
                          ) : (
                            <TargetField
                              excludeModes={
                                i === 0 ? ['EXCLUDE_PRODUCT'] : [noExcludeProductTrigger && 'EXCLUDE_PRODUCT', 'ALL']
                              }
                              trigger={trigger}
                              disabled={isSubmitting}
                              onChange={(v) => {
                                setFieldValue('triggers', triggers);
                                setFieldValue('remark', getRemark(values));
                                if (
                                  values?.triggers?.some((trigger) =>
                                    ['PRODUCT', 'PRODUCT_TAG', 'COLLECTION', 'ORDER'].includes(trigger?.triggerType),
                                  )
                                ) {
                                  setFieldValue('repeat', values?.repeat);
                                } else {
                                  setFieldValue('repeat', 1);
                                }
                              }}
                            />
                          )}
                        </Grid>
                        {triggers.length > 1 && (
                          <Grid item>
                            <TrashButton
                              disabled={isSubmitting}
                              onClick={() => {
                                triggers.splice(i, 1);
                                setFieldValue('triggers', triggers);
                                setFieldValue('remark', getRemark(values));
                                if (
                                  values?.triggers?.some((trigger) =>
                                    ['PRODUCT', 'PRODUCT_TAG', 'COLLECTION', 'ORDER'].includes(trigger?.triggerType),
                                  )
                                ) {
                                  setFieldValue('repeat', values?.repeat);
                                } else {
                                  setFieldValue('repeat', 1);
                                }
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  {!noMoreTrigger && (
                    <Grid item xs={12}>
                      <AddButton
                        p={0}
                        style={{ marginTop: 20 }}
                        disabled={loading || isSubmitting}
                        variant={'contained'}
                        onClick={() => {
                          triggers.push({ ...defaultTrigger });
                          setFieldValue('triggers', triggers);
                          setFieldValue('remark', getRemark(values));
                        }}
                        title={t.add_trigger}
                      >
                        <Icon
                          style={{
                            margin: '0 10px',
                            color: textColor,
                          }}
                          icon={'faPlus'}
                        />
                        {/* <Typography style={{ fontWeight: 'bold' }}>{t.add_trigger}</Typography> */}
                      </AddButton>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </StyledConfigList>
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};
