import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { convertMetaObject } from '../../../../shared';

const useStyles = makeStyles(() => ({
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-11px',
    marginBottom: '-11px',
  },
}));

const CheckboxField = ({ disabled, value, onChange, field = {} }) => {
  const classes = useStyles();
  const checkedValues = (`${value ?? ''}`?.split(',') ?? []).filter((_) => _);

  const { metadata, name, required, values = [] } = field ?? {};
  const { remarks } = convertMetaObject(metadata);

  const boolMode = values?.length === 1;

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <FormGroup className={classes.checkboxGroup}>
        {values.map((value, index) => {
          const checked = boolMode ? !!+checkedValues?.[0] : checkedValues.includes(value);
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  required={required && !checkedValues?.length}
                  disabled={disabled}
                  checked={checked}
                  onChange={(e) => {
                    if (checked) {
                      if (boolMode) {
                        onChange('');
                      } else {
                        const newCheckedValues = checkedValues.filter((v) => v !== value);
                        onChange(newCheckedValues.join(','));
                      }
                    } else {
                      if (boolMode) {
                        onChange('1');
                      } else {
                        const newCheckedValues = [...checkedValues, value];
                        onChange(newCheckedValues.join(','));
                      }
                    }
                  }}
                />
              }
              label={value}
            />
          );
        })}
      </FormGroup>
    </CustomFieldWrapper>
  );
};

export default CheckboxField;
