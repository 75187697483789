import { gql } from '@apollo/client';

export const FRAGMENT_SHOPPAGE = gql`
  fragment ShopPage on ShopPage {
    name
    href
    group
    active
    display
    body
    metaTitle
    metaDescription
    metaKeywords
    rewriteUri
    createdAt
    updatedAt
    hashtags
    sortIndex
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOPPAGE}
  query($id: ID!) {
    node(id: $id) {
      id
      ...ShopPage
    }
  }
`;

export const POST_QUERY = gql`
  ${FRAGMENT_SHOPPAGE}
  mutation($id: ID, $input: PageInput!) {
    result: pageSet(id: $id, input: $input) {
      id
      ...ShopPage
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation($id: ID!) {
    result: pageUnset(id: $id) {
      id
    }
  }
`;
