import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboPaymentMethod from '../../SelectCombo/SelectComboPaymentMethod';
import { Typography } from '@material-ui/core';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, name] = str.match(/^(.*?)∆(.*)$/);
      return { id, name };
    });
  const [paymentMethods, setPaymentMethods] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboPaymentMethod
        multiple
        value={paymentMethods}
        onChange={(paymentMethods) => {
          setPaymentMethods(paymentMethods);
          onFilterChange({
            value: (paymentMethods || []).map((paymentMethod) => `${paymentMethod.id}∆${paymentMethod.name}`),
            operator: 'IN',
          });
        }}
      />
      <Typography variant={'caption'}></Typography>
    </FormRow>
  );
};
