import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Container, Grid, Typography, useTheme } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { SHOP_EMAIL_VERIFY_QUERY, USER_VERIFY } from './query';
import { Icon } from '../../components/IconRender';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { obj2Hash, preventRapidRepeatCall, sleep } from '../../shared';
import errorParser from '../../shared/errorParser';
import useQueryStringParams from '../../components/useQueryStringParams';
import OmniweLoading from '../../components/OmniweLoading';
import moment from 'moment';

const { REACT_APP_MAIN_SITE_URL } = process.env;

const Verify = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [remainingTime, setRemainingTime] = useState(3);
  const [loading, setLoading] = useState(true);
  const { token, action } = useQueryStringParams();

  useEffect(() => {
    if (token && action) {
      (async () => {
        if (!preventRapidRepeatCall(5000)) return;
        const verifyHash = [obj2Hash({ action, token }), new Date().toISOString()];
        const hasPreviousVerifyHash = setVerifyHash(...verifyHash);
        console.log('hasPreviousVerifyHash', hasPreviousVerifyHash);

        try {
          setLoading(true);
          switch (action) {
            case 'userVerify':
              try {
                await client.mutate({
                  mutation: USER_VERIFY,
                  variables: { token },
                });
              } catch (e) {
                if (
                  hasPreviousVerifyHash &&
                  (/\[ERR_OTP_USED]/.test(e?.message) || /\[ERR_OTP_INVALID]/.test(e?.message))
                ) {
                  if (!checkIdempotencyVerifyHash(...verifyHash)) throw e;
                } else {
                  throw e;
                }
              }
              setLoading(false);
              await redirectCountdown();
              clearVerifyHash();
              window.location.href = `${REACT_APP_MAIN_SITE_URL}/login?redirectUrl=${window.location.origin}/login`;
              break;
            case 'shopContactEmailVerify':
            default:
              try {
                await client.mutate({
                  mutation: SHOP_EMAIL_VERIFY_QUERY,
                  variables: { token },
                });
              } catch (e) {
                if (
                  hasPreviousVerifyHash &&
                  (/\[ERR_OTP_USED]/.test(e?.message) || /\[ERR_OTP_INVALID]/.test(e?.message))
                ) {
                  if (!checkIdempotencyVerifyHash(...verifyHash)) throw e;
                } else {
                  throw e;
                }
              }
              setLoading(false);
              await redirectCountdown();
              clearVerifyHash();
              history.replace('/');
              break;
          }
        } catch (e) {
          setError(e);
          clearVerifyHash();
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [token, action]);

  return (
    <Box display={'flex'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <Card style={{ minWidth: 250, textAlign: 'center', borderRadius: 15 }}>
        <CardContent>
          {loading ? (
            <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={3}>
              <Grid item>
                <Typography style={{ fontSize: '1.5rem' }}>{translate.verifying}</Typography>
              </Grid>
              <Grid item>
                <Container
                  style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <OmniweLoading loading={true} color={'grey'} />
                  <div style={{ flex: 1 }} />
                </Container>
              </Grid>
            </Grid>
          ) : !!error ? (
            <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={1}>
              <Grid item>
                <Typography style={{ fontSize: '1.5rem' }}>{translate.verify_failed}</Typography>
              </Grid>
              <Grid item>
                <Icon
                  icon={'faTimes'}
                  style={{
                    color: theme.palette.error.main,
                    fontSize: 50,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant={'h6'} style={{ color: theme.palette.error.main }}>
                  {errorParser(error)}
                </Typography>
                <Typography>
                  {translate.OTP_error_remark || '如有需要請聯絡技術支援'}:
                  <a href={'mailto:findus@omniwe.com'}>findus@omniwe.com</a>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={1}>
              <Grid item>
                <Typography style={{ fontSize: '1.5rem' }}>{translate.verified}</Typography>
              </Grid>
              <Grid item>
                <Icon
                  icon={'faCheck'}
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 50,
                  }}
                />
              </Grid>
              {remainingTime > 0 ? (
                <Typography>{remainingTime} 秒後重新定向</Typography>
              ) : (
                <Typography>重新定向中...</Typography>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );

  async function redirectCountdown() {
    setRemainingTime(3);
    await sleep(1000);
    setRemainingTime(2);
    await sleep(1000);
    setRemainingTime(1);
    await sleep(1000);
    setRemainingTime(0);
  }
};

export default Verify;

function clearVerifyHash() {
  localStorage.removeItem('verifyHash');
}
function getVerifyHash() {
  const [hash, time] = localStorage.getItem('verifyHash')?.split('∆') ?? [];
  return [hash, time];
}

function setVerifyHash(hash, time) {
  const [preHash] = getVerifyHash();
  if (hash !== preHash) {
    localStorage.setItem('verifyHash', `${hash}∆${time}`);
  }
  return !!preHash;
}

function checkIdempotencyVerifyHash(hash, time) {
  const [preHash, preTime] = getVerifyHash();
  if (hash !== preHash) return false;
  if (moment(time).diff(moment(preTime), 'seconds') > 60) return false;
  return true;
}
