import React from 'react';
import { MenuHashTagsSet } from '../../../list_Product/ListView/MenuHashTagsSet';
import { gql } from '@apollo/client';

const CUSTOMERS_HASHTAGS_SET = gql`
  mutation(
    $shopId: ID!
    $hashtags: [String!]!
    $filter: CustomerFilterInput
    $operator: BatchAssignmentOperator!
    $query: String
  ) {
    customersHashtagsSet(shopId: $shopId, hashtags: $hashtags, operator: $operator, filter: $filter, query: $query) {
      id
    }
  }
`;

export default {
  render: (args) => <MenuHashTagsSet {...args} mutation={CUSTOMERS_HASHTAGS_SET} />,
};
