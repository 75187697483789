import React from 'react';
import { CREATE_QUERY, GET_QUERY, UPDATE_QUERY } from '../query';
import FormPageMaker from '../../../components/FormPageMaker';
import { convertMetaArray, convertMetaObject, JSONParseSafely, parseConnection } from '../../../shared';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import { client } from '../../../shared/apollo';
import SplitCard from '../SplitCard';
import defaultSuggestions from '../defaultSuggestions';
const { REACT_APP_ASSETS_URL } = process.env;

export default class EmailWelcome extends FormPageMaker {
  emailType = 'WELCOME_AND_VERIFY';

  onCancelClick = null;
  async componentDidMount() {
    const [hk, cn, en] = await Promise.all([
      ...[
        `${REACT_APP_ASSETS_URL}/email_templates/default/zh-HK/${this.emailType}.json`,
        `${REACT_APP_ASSETS_URL}/email_templates/default/zh-CN/${this.emailType}.json`,
        `${REACT_APP_ASSETS_URL}/email_templates/default/en-US/${this.emailType}.json`,
      ].map(async (url) => {
        try {
          const res = await fetch(url);
          return await res.json();
        } catch (e) {}
      }),
    ]);

    this.setState({
      key: 1,
      defaultConfigs: {
        'zh-HK': hk,
        'zh-CN': cn,
        'en-US': en,
      },
    });
  }

  state = {
    ...this.state,
    defaultConfigs: {},
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
    },
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[
                ...defaultSuggestions,
                {
                  label: '#token',
                  value: '{{token}}',
                },
              ]}
              allows={['isTitle', 'isText', 'isImage', 'isDivider', 'isButton', 'isLink', 'isQRCode']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              extraData={{
                token: '00000000000000000000000000000000',
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };

  submit = async (values) => {
    if (values.id) {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id: values.id,
          input: {
            configs: JSON.stringify(values.configs),
            metadata: convertMetaArray(values?.metadata),
          },
        },
      });
    } else {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            configs: JSON.stringify(values.configs),
            metadata: convertMetaArray(values?.metadata),
            name: values.name || this.emailType,
            shopId: localStorage.getItem('shopId'),
            type: this.emailType,
          },
        },
      });
    }
    return false;
  };

  getExtraFetchVariables() {
    return {
      type: this.emailType,
    };
  }

  getInitialData(data) {
    const { emailTemplates, ...shop } = data?.node ?? {};
    const { id, updatedAt, name, configs, metadata } = parseConnection(emailTemplates).nodes?.[0] ?? {};
    return {
      id,
      updatedAt,
      name,
      shop,
      defaultConfigs: this.state.defaultConfigs?.[shop?.locale],
      configs: JSONParseSafely(configs, this.state.defaultConfigs?.[shop?.locale]),
      metadata: convertMetaObject(metadata),
    };
  }
}
