import React from 'react';

const TableColumnDefinition = () => (
  <colgroup>
    <col width="120" />
    <col width="120" />
    <col width="*" style={{ minWidth: 180 }} />
    <col width="80" />
    <col width="80" />
    <col width="80" />
  </colgroup>
);

export default TableColumnDefinition;
