import React from 'react';
import Taber from '../../components/Taber';
import ListCredentials from './ListCredentials';

export default () => {
  return (
    <div>
      <Taber
        // currentTab={tab}
        tabs={[
          {
            name: '所有憑據',
          },
        ]}
      />
      <ListCredentials />
    </div>
  );
};
