import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import TablesList from './TablesList';
import DraggableTable from './DraggableTable';
import { translate } from '../../../shared/translate';

const TableSettingCard = ({ venueName, value = [], onChange, onChangeImage, media }) => {
  const [parentHeight, setParentHeight] = useState(0);
  return (
    <Grid container spacing={1} style={{ height: '100%' }}>
      <Grid item xs={12}>
        <Typography variant="caption">{translate.use_image_ratio || '請使用16/9比例的圖片'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          style={{
            borderRadius: 9,
            borderStyle: 'solid',
            borderColor: '#999999',
            borderWidth: 1,
          }}
        >
          <Grid item md={true} xs={12}>
            <DraggableTable
              value={value}
              onChange={onChange}
              media={media}
              onChangeImage={onChangeImage}
              onChangeHeight={setParentHeight}
            />
          </Grid>
          <Grid item xs={'auto'}>
            <div style={{ height: '100%', width: 1, backgroundColor: '#999999' }} />
          </Grid>
          <Grid item md={3} xs={12}>
            {!!parentHeight && (
              <TablesList venueName={venueName} value={value} onChange={onChange} parentHeight={parentHeight} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableSettingCard;
