import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@material-ui/core';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import { addValue2Metadata, convertMetaObject, metadataDeleteKey } from '../../../shared';
import SettingCommon from './SettingCommon';
import SystemButton from '../../SystemButton';
import { Icon } from '../../IconRender';
import CheckboxField from '../../FormPageMaker/Fields/CheckboxField';

const SettingDATE = (props) => {
  const { disabled, value, onChange = (_) => _ } = props;
  const [open, setOpen] = useState(false);
  const { metadata } = value ?? {};
  const { allowFutureDate } = convertMetaObject(metadata);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SettingCommon {...props} />
      </Grid>
      <Grid item xs={12}>
        <SystemButton
          variant={'text'}
          disabled={disabled}
          onClick={() => setOpen(!open)}
          color={'secondary'}
          startIcon={<Icon icon={open ? 'faChevronUp' : 'faChevronDown'} />}
        >
          {translate.constraints}
        </SystemButton>
        <Collapse in={open}>
          <Box p={2} mt={2} bgcolor={'#F5F5F5'}>
            <Grid container spacing={2}>
              <FormRow dense title={translate.allow_future_date} md={3}>
                <CheckboxField
                  checked={!!+allowFutureDate}
                  onChange={() => {
                    onChange({
                      ...value,
                      metadata:
                        allowFutureDate === 1
                          ? metadataDeleteKey('allowFutureDate', metadata)
                          : addValue2Metadata('allowFutureDate', 1, metadata),
                    });
                  }}
                />
              </FormRow>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default SettingDATE;
