import React from 'react';
import { Box, Grid } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { Icon } from '../../components/IconRender';
import { translate } from '../../shared/translate';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import Dnd from '../../components/DND';
import { ReactComponent as IconDragVertical } from '../../assets/icon/drag-vertical.svg';
import TrashButton from '../../components/TrashButton';

const MarqueeArrayField = ({ disabled, data = [], onChange = (_) => _ }) => {
  return (
    <StyledConfigList>
      {(data || []).length > 0 && (
        <Grid item xs={12}>
          <Box mt={2}>
            <Dnd
              spacing={3}
              items={data || []}
              renderItem={(marquee, i, { nonDraggableProps }) => (
                <Box mx={1.5}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Icon type={'svg'} icon={IconDragVertical} style={{ cursor: 'pointer' }} />
                    </Grid>
                    <Grid item xs {...nonDraggableProps}>
                      <MarqueeRow
                        value={marquee}
                        disabled={disabled}
                        onChange={() => onChange(data)}
                        onRemoveClick={() => {
                          data.splice(i, 1);
                          onChange(data);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              gridItemProps={{
                xs: 12,
              }}
              onChange={onChange}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <AddButton
          disabled={disabled}
          onClick={() => {
            data.push({ content: '', href: '' });
            onChange(data);
          }}
          title={translate.new_marquee_content || '新增訊息'}
        />
      </Grid>
    </StyledConfigList>
  );
};

export const MarqueeRow = ({
  value = {},
  value: { content, href } = {},
  onChange = (_) => _,
  onRemoveClick,
  disabled,
}) => {
  return (
    <Grid container>
      <Grid item md={true}>
        <InputField
          disabled={disabled}
          value={content}
          placeholder={translate.content}
          onChange={(e) => {
            value.content = e.target.value;
            onChange(value);
          }}
          // eslint-disable-next-line
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position={'end'} disableTypography>
          //       <CommonIconButton
          //         isListViewButton={false}
          //         color={'secondary'}
          //         icon={IconTrash}
          //         disabled={disabled}
          //         onClick={onRemoveClick}
          //       />
          //     </InputAdornment>
          //   ),
          // }}
        />
        <InputField
          disabled={disabled}
          value={href}
          placeholder={`${translate.marquee_href} https://example.com`}
          onChange={(e) => {
            value.href = e.target.value;
            onChange(value);
          }}
          // eslint-disable-next-line
          InputProps={{
            style: {
              marginTop: 8,
            },
          }}
        />
      </Grid>
      <Grid item md="auto" style={{ alignSelf: 'center' }}>
        <TrashButton disabled={disabled} onClick={onRemoveClick} />
      </Grid>
    </Grid>
  );
};

export default MarqueeArrayField;
