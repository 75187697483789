import { gql } from '@apollo/client';

export const GET_REPORT = gql`
  query (
    $today: AWSDateTime!
    $last7day: AWSDateTime!
    $last30day: AWSDateTime!
    $startedThru: AWSDateTime!
    $query: String
    $companyId: ID!
    $reportShopId: ID!
  ) {
    me {
      ... on User {
        shops(query: $query) {
          nodes {
            id
            report {
              todayAmount: turnover(startedAt: $today, startedThru: $startedThru)
              todayCount: volume(startedAt: $today, startedThru: $startedThru)
              last7dayAmount: turnover(startedAt: $last7day, startedThru: $startedThru)
              last7dayCount: volume(startedAt: $last7day, startedThru: $startedThru)
              last30dayAmount: turnover(startedAt: $last30day, startedThru: $startedThru)
              last30dayCount: volume(startedAt: $last30day, startedThru: $startedThru)
              unpaidOrderAmount(startedAt: $today, startedThru: $startedThru)
              unpaidOrderCount(startedAt: $today, startedThru: $startedThru)
              pendingOrderCount(startedAt: "1970-01-01T15:59:59.999Z", startedThru: $startedThru)
            }
          }
        }
      }
    }
    companyNode: node(id: $companyId) {
      id
      ... on Company {
        report {
          topSalesProducts: topProductSales(
            sortBy: [{ field: "count", order: DESC }]
            startedAt: $last30day
            startedThru: $startedThru
            limits: 10
          ) {
            nodes {
              amount
              count
              sku
              name
              medias(shopId: $reportShopId) {
                src
              }
            }
          }
          topViewProducts: topProductSales(
            sortBy: [{ field: "pageView", order: DESC }]
            startedAt: $last30day
            startedThru: $startedThru
            limits: 10
          ) {
            nodes {
              pageView
              count
              sku
              name
              medias(shopId: $reportShopId) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
