import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { GET_QUERY, SUBMIT_QUERY } from './query';
import { client } from '../../shared/apollo';
import { cloneObject, convertMetaArray, convertMetaObject, removeTypename } from '../../shared';
import CustomFormField from '../../components/CustomFormField';
import { translate } from '../../shared/translate';
import BasicFieldsSetting, { basicFields } from './BasicFieldsSetting';

class ItemCustomerFieldsSettingForm extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
    },
    tabs: [
      {
        cards: [
          {
            fields: [
              {
                label: translate.basic_field,
                render: ({ disabled, values, setFieldValue, loading }) => {
                  return (
                    <BasicFieldsSetting
                      loading={loading}
                      disabled={disabled}
                      value={values.basicFieldsMap}
                      onChange={(v) => {
                        setFieldValue('basicFieldsMap', {
                          ...v,
                          primary: {
                            ...v?.primary,
                            required: true,
                            visible: true,
                            metadata: convertMetaArray({
                              ...convertMetaObject(v?.primary?.metadata),
                              visibleAtRegistration: true,
                            }),
                          },
                        });
                      }}
                    />
                  );
                },
              },
              {
                label: translate.other_field,
                render: ({ disabled, values, setFieldValue, loading }) => {
                  return (
                    <CustomFormField
                      onlyAllowFieldTypes={['TEXT', 'LIST_SINGLE', 'LIST_MULTIPLE', 'YEAR', 'MONTH']}
                      disabled={disabled}
                      loading={loading}
                      value={values.memberMetadataFields}
                      enableFillInWhenRegisteringOption={true}
                      onChange={(v) => {
                        setFieldValue('memberMetadataFields', v);
                      }}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async ({ name, memberMetadataFields, basicFieldsMap }) => {
    const {
      id,
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          name,
          memberMetadataFields: removeTypename(Object.values(basicFieldsMap).concat(memberMetadataFields)),
        },
      },
    });
    return false;
  };

  getInitialData({ node } = {}) {
    const { memberMetadataFields: _memberMetadataFields, name } = node || {};
    const memberMetadataFields = cloneObject(_memberMetadataFields || []);

    return {
      name,
      basicFieldsMap: basicFields.reduce((reducer, bf) => {
        reducer[bf.key] = {
          ...bf,
          ...memberMetadataFields.find((mf) => mf.key === bf.key),
        };
        return reducer;
      }, {}),
      memberMetadataFields: memberMetadataFields.filter((mf) => !basicFields.find((bf) => bf.key === mf.key)),
    };
  }

  onCancelClick = null;
}

export default ItemCustomerFieldsSettingForm;
