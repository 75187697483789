import React from 'react';
import { MenuPriceAdjustment } from '../../../list_Product/ListView/MenuPriceAdjustment';
import { gql } from '@apollo/client';

const ADDON_PRODUCTS_PRICE_ADJUSTMENT = gql`
  mutation(
    $shopId: ID!
    $filter: AddOnProductFilterInput
    $query: String
    $operator: BatchOperator!
    $value: Float!
    $safetyThreshold: Float
  ) {
    addOnProductsPriceAdjust(
      shopId: $shopId
      filter: $filter
      query: $query
      operator: $operator
      value: $value
      safetyThreshold: $safetyThreshold
    ) {
      id
    }
  }
`;

export default {
  render: (args) => <MenuPriceAdjustment {...args} mutation={ADDON_PRODUCTS_PRICE_ADJUSTMENT} />,
};
