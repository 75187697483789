import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { GET_QUERY, UPDATE_QUERY } from './query';
import AddressesCard from './AddressesCard';
import {
  cloneObject,
  convertMetaArray,
  convertMetaObject,
  NumberFormat,
  parseAddress,
  parseConnection,
  PriceFormat,
} from '../../shared';
import moment from 'moment';
import OrdersCard from './OrdersCard';
import _ from 'lodash';
import LSkeleton from '../../components/LSkeleton';
import { InputAdornment, Typography } from '@material-ui/core';
import CheckboxField from '../../components/FormPageMaker/Fields/CheckboxField';
import MemberTierModifyButton from './MemberTierModifyButton';
import { JSONParseSafely } from '../../shared';
import CustomFormMetadataField from '../../components/CustomFormMetadataField';
import PointRecordsCard from './PointRecordsCard';
import CouponsCard from './CouponsCard';
import { checkPermissionV2 } from '../../components/PermissionMask';
import { BLOCK_CUSTOMER, UNBLOCK_CUSTOMER } from '../list_Customer/ListView/ActiveSwitcher';
import PushSettingCard from './PushSettingCard';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
    },
    tabs: [
      {
        name: translate.basic_customer_information,
        cards: [
          {
            fields: [
              {
                label: translate.last_login_at,
                type: 'span',
                text: ({ values, loading }) =>
                  loading ? (
                    <LSkeleton />
                  ) : !!values.lastLogin ? (
                    moment(values.lastLogin).format('DD/MM/YYYY HH:mm A')
                  ) : (
                    '-'
                  ),
                xs: 3,
                md: 2,
              },
              {
                label: translate.member_points,
                type: 'span',
                text: ({ values, loading }) => (loading ? <LSkeleton /> : NumberFormat(values.giftPoints)),
                xs: 3,
                md: 2,
              },
              {
                label: translate.total_spent,
                type: 'span',
                text: ({ values, loading }) => (loading ? <LSkeleton /> : PriceFormat(values.turnover)),
                xs: 3,
                md: 2,
              },
              {
                label: translate.order_count,
                type: 'span',
                text: ({ values, loading }) => (loading ? <LSkeleton /> : NumberFormat(values.volume)),
                xs: 3,
                md: 2,
              },
              {
                label: translate.average_spent,
                type: 'span',
                text: ({ values, loading }) => (loading ? <LSkeleton /> : PriceFormat(values.averageConsumption)),
                xs: 3,
                md: 2,
              },
              {
                label: translate.member_tier,
                render: ({ loading, values, isSubmitting, setFieldValue }) => {
                  if (loading) return <LSkeleton height={30} />;
                  return (
                    <MemberTierModifyButton
                      memberTier={values.memberTier}
                      disabled={isSubmitting || !checkPermissionV2('updateCustomer')}
                      customerId={values.id}
                      onMemberTierChange={(memberTier) => setFieldValue('memberTier', memberTier)}
                    />
                  );
                },
                text: ({ values, loading }) =>
                  loading ? <LSkeleton /> : `${(values.memberTier || {}).level}: ${(values.memberTier || {}).name}`,
                xs: 4,
                md: 3,
              },
              {
                label: translate.last_member_tier || '上一次的分級',
                text: ({ values, loading }) =>
                  loading ? (
                    <LSkeleton />
                  ) : values.previousMemberTier ? (
                    <>
                      <Typography>
                        {moment(values.previousMemberTier.startedAt).format('YYYY-MM-DD')} {translate.available_date_to}{' '}
                        {moment(values.previousMemberTier.startedThru).format('YYYY-MM-DD')}
                      </Typography>
                      <Typography>{values.previousMemberTier.name}</Typography>
                    </>
                  ) : (
                    <Typography>{translate.empty_member_tier || '沒有會員等級'}</Typography>
                  ),
                xs: 4,
                md: 3,
              },
              {
                label: translate.hash_tag,
                type: 'hashTagsArray',
                name: 'hashtags',
                disabled: !checkPermissionV2('updateCustomer'),
              },
              {
                label: translate.phone_no,
                type: 'mobile',
                name: 'phoneNumber',
                md: 6,
                disabled: !checkPermissionV2('updateCustomer'),
                required: ({ values }) => !values.email,
                InputProps: ({ values, setFieldValue, isSubmitting }) => ({
                  endAdornment: (
                    <InputAdornment position={'end'} disableTypography>
                      <CheckboxField
                        style={{
                          display: 'contents',
                        }}
                        disabled={!values.phoneNumber || isSubmitting}
                        checked={!!values.phoneNumber && values.isPhoneNumberVerified}
                        onChange={() => {
                          setFieldValue('isPhoneNumberVerified', !values.isPhoneNumberVerified);
                        }}
                        text={translate.verify}
                      />
                    </InputAdornment>
                  ),
                }),
              },
              {
                label: translate.email,
                type: 'email',
                name: 'email',
                md: 6,
                required: ({ values }) => !values.phoneNumber,
                disabled: !checkPermissionV2('updateCustomer'),
                InputProps: ({ values, setFieldValue, isSubmitting }) => ({
                  endAdornment: (
                    <InputAdornment position={'end'} disableTypography>
                      <CheckboxField
                        style={{
                          display: 'contents',
                        }}
                        disabled={!values.email || isSubmitting}
                        checked={!!values.email && values.isEmailVerified}
                        onChange={() => {
                          setFieldValue('isEmailVerified', !values.isEmailVerified);
                        }}
                        text={translate.verify}
                      />
                    </InputAdornment>
                  ),
                }),
              },
              {
                label: translate.change_password,
                type: 'password_update',
                name: 'password',
                inputProps: {
                  pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                },
                text: translate.password_remark,
                disabled: !checkPermissionV2('updateCustomer'),
              },
              {
                label: translate.status,
                type: 'switch',
                name: 'blocked',
              },
              {
                md: 'auto',
                label: translate.image,
                type: 'image',
                name: 'picture',
                disabled: !checkPermissionV2('updateCustomer'),
              },
              {
                md: 12,
                label: translate.remark,
                type: 'textarea',
                name: 'remark',
                disabled: !checkPermissionV2('updateCustomerRemark'),
              },
            ],
          },
          {
            name: translate.omnichannel_messaging_setting || '全渠道通訊設定',
            fields: [
              {
                render: ({
                  values: { memberMetadataFields = [], metadata = {} } = {},
                  setFieldValue,
                  loading,
                  disabled,
                  isSubmitting,
                }) => {
                  if (!loading && memberMetadataFields.length === 0) return null;

                  return (
                    <PushSettingCard
                      loading={loading}
                      disabled={disabled || isSubmitting}
                      value={metadata}
                      onChange={(v) => setFieldValue('metadata', v)}
                    />
                  );
                },
              },
            ],
          },
          {
            name: translate.customize_table,
            fields: [
              {
                render: ({
                  values: { memberMetadataFields = [], metadata = {} } = {},
                  setFieldValue,
                  loading,
                  disabled,
                  isSubmitting,
                }) => {
                  if (!loading && memberMetadataFields.length === 0) return null;

                  return (
                    <CustomFormMetadataField
                      shouldNotRequired={true}
                      value={metadata}
                      metadataFields={memberMetadataFields}
                      loading={loading}
                      disabled={disabled || isSubmitting}
                      onChange={(v) => setFieldValue('metadata', v)}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      {
        name: translate.address,
        cards: [AddressesCard],
      },
      {
        name: translate.client_orders,
        noPadding: true,
        cards: [OrdersCard],
      },
      {
        name: translate.point_records,
        noPadding: true,
        cards: [PointRecordsCard],
      },
      {
        name: translate.coupons,
        noPadding: true,
        cards: [CouponsCard],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const credential = {
      mobileV2: values.phoneNumber || undefined,
      email: values.email || undefined,
      password: values.password || undefined,
    };

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        shopId: localStorage.getItem('shopId'),
        credential: Object.keys(_.omit(credential)).length === 0 ? undefined : credential,
        input: {
          // name: values.name || `${values.givenName} ${values.familyName}`,
          // familyName: values.familyName,
          metadata: convertMetaArray({
            ...values.metadata,
            remark: values.remark || '',
          }),
          // givenName: values.givenName,
          addresses: (() => {
            const hasDefault = !!(values.addresses || []).find((address) => address.isDefault);
            return (values.addresses || []).map((address, i) => ({
              ...parseAddress(address, true),
              isDefault: hasDefault ? address.isDefault : i === 0,
            }));
          })(),
          hashtags: values.hashtags,
          isEmailVerified: values.isEmailVerified,
          isPhoneNumberVerified: values.isPhoneNumberVerified,
          picture: values?.picture || null,
        },
      },
    });

    if (values.originalBlocked !== values.blocked)
      await client.mutate({
        mutation: values.blocked ? UNBLOCK_CUSTOMER : BLOCK_CUSTOMER,
        variables: {
          id: id || undefined,
          shopId: localStorage.getItem('shopId'),
        },
      });

    return true;
  };

  getExtraFetchVariables() {
    return { shopId: localStorage.getItem('shopId') };
  }

  getInitialData(data) {
    const { node, shop } = data || {},
      { memberMetadataFields } = shop || {},
      {
        id,
        createdAt,
        updatedAt,
        email,
        giftPoints,
        phoneNumber,
        picture,
        lastLogin,
        addresses,
        isEmailVerified,
        isPhoneNumberVerified,
        report,
        hashtags,
        metadata: _metadata,
        memberTier,
        blocked,
        previousMemberTier,
      } = node || {},
      { turnover = 0, volume = 0 } = report || {};
    const { remark, ...metadata } = convertMetaObject(_metadata);

    return {
      memberMetadataFields: memberMetadataFields || [],
      id,
      remark,
      // name,
      createdAt,
      updatedAt,
      phoneNumber,
      email,
      isEmailVerified,
      metadata,
      isPhoneNumberVerified,
      giftPoints,
      // familyName,
      // givenName,
      picture,
      lastLogin,
      originalAddresses: cloneObject(parseConnection(addresses).nodes),
      addresses: cloneObject(parseConnection(addresses).nodes),
      turnover,
      volume,
      averageConsumption: turnover / volume,
      hashtags: cloneObject(hashtags || []),
      memberTier,
      blocked: !blocked,
      originalBlocked: !blocked,
      previousMemberTier,
    };
  }
}
