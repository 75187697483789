import React from 'react';
import { Box, Paper } from '@material-ui/core';

export const MethodItemHeight = 15;

const MethodItem = ({ source }) => (
  <Paper variant={'outlined'}>
    <Box px={1} py={0.5}>
      <img
        src={source}
        height={MethodItemHeight}
        width={(MethodItemHeight * 16) / 9}
        style={{
          objectFit: 'contain',
        }}
        alt={''}
      />
    </Box>
  </Paper>
);

export default MethodItem;
