import React from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Icon } from './IconRender';

const RefreshButton = ({ loading, onClick, ...props }) => (
  <div>
    {loading ? (
      <IconButton>
        <CircularProgress size={17.5} />
      </IconButton>
    ) : (
      <IconButton onClick={onClick} {...props}>
        <Icon icon={'faSync'} />
      </IconButton>
    )}
  </div>
);

export default RefreshButton;
