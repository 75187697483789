import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';

const STATUS_SET = gql`
  mutation ($id: ID, $input: ProductInput!) {
    productSet(id: $id, input: $input) {
      id
      active
    }
  }
`;

export default ({ id, active, data, onCompleted }) => {
  const { images, name, basePrice, maxPrice, sku, variations } = data || {};
  const [loading, setLoading] = useState(false);
  const shopId = localStorage.getItem('shopId');
  const [isActive, setIsActive] = useState(active);
  useEffect(() => {
    if (active !== isActive) setIsActive(active);
  }, [active]);

  const handleMutation = async () => {
    try {
      setIsActive(!active);
      setLoading(true);
      await client.mutate({
        mutation: STATUS_SET,
        variables: {
          id: id || undefined,
          input: {
            images,
            active: !active,
            basePrice,
            maxPrice,
            sku,
            name,
            shopId,
            variations:
              variations?.length === 1
                ? [
                    {
                      id: variations?.[0]?.id,
                      active: !active,
                      cost: variations?.[0]?.cost,
                      sku: variations?.[0]?.sku,
                      unitPrice: variations?.[0]?.unitPrice,
                    },
                  ]
                : undefined,
          },
        },
      });
      onCompleted();
    } catch (e) {
      handleMutationError(e);
      setIsActive(active);
    } finally {
      setLoading(false);
    }
  };

  const handleMutationError = (error) => {
    const errorMessage = errorParser(error);
    toast.error(errorMessage);
  };

  return <SwitchField checked={isActive} value={isActive} disabled={loading} onChange={handleMutation} />;
};
