import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import ReportHeader from './ReportHeader';
import ReportRow from './ReportRow';

const ReportDetail = ({ cols, label, exportMutation, exportVariables, loading }) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReportHeader
              label={label}
              loading={loading}
              exportMutation={exportMutation}
              exportVariables={exportVariables}
            />
          </Grid>
          {cols.map(({ label, value }) => {
            return (
              <Grid item md={3} xs={6}>
                <ReportRow value={value} label={label} loading={loading} />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReportDetail;
