import { gql } from '@apollo/client';

export const FRAGMENT_USER = gql`
  fragment FRAGMENT_USER on User {
    id
    createdAt
    updatedAt
    email
    isEmailVerified
    phoneNumber
    isPhoneNumberVerified
    lastLogin
    name
    blocked
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: UserFilterInput, $query: String) {
    node(id: $id) {
      id
      ... on Company {
        users(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_USER
          }
        }
      }
    }
  }
  ${FRAGMENT_USER}
`;
