import React, { useState } from 'react';
import SystemButton from '../../components/SystemButton';
import { client } from '../../shared/apollo';
import errorParser from '../../shared/errorParser';
import toast from '../../shared/toast';
import { translate } from '../../shared/translate';
import useQueryParams from '../../hooks/useQueryParams';
import { JSONParseSafely } from '../../shared';

const ExportButton = ({ mutation, disabled, variables = {} }) => {
  const [query] = useQueryParams(),
    { startedAt, startedThru, shop } = query ?? {},
    shopId = JSONParseSafely(shop)?.id;

  const [loading, setLoading] = useState(false);
  return (
    <SystemButton
      color="omniewRed"
      loading={loading}
      disabled={loading || disabled}
      onClick={async () => {
        try {
          setLoading(true);
          await client.mutate({
            mutation,
            variables: {
              startedAt,
              startedThru,
              exportShopIds: !!shopId ? [shopId] : [],
              shopId: localStorage.getItem('shopId'),
              ...variables,
            },
          });
          toast.success(translate.export_tips || '匯出當前篩選的數據條目，匯出完成後可於系統訊息中下載。');
        } catch (error) {
          toast.error(errorParser(error));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.export}
    </SystemButton>
  );
};

export default ExportButton;
