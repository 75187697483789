import React from 'react';
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { translate as t } from 'shared/translate';
import FormRow from 'components/FormRow';
import getRemark from '../getRemark';
import ActionField from './ActionField';
import { Icon } from '../../../components/IconRender';
import LSkeleton from '../../../components/LSkeleton';
import { translate } from '../../../shared/translate';
import { textColor } from '../../../theme';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import TrashButton from '../../../components/TrashButton';

export const defaultAction = { actionType: 'ORDER', actionValueType: 'AMOUNT', actionValue: 10 };

export default ({ loading, values, values: { actions }, setFieldValue, isSubmitting }) => {
  return (
    <Card square elevation={0}>
      <CardContent>
        <FormRow title={t.discount_setting}>
          <StyledConfigList>
            <Box p={2}>
              <Grid container spacing={1}>
                {actions.map((action, i) => (
                  <Grid key={i} item xs={12}>
                    <Grid container spacing={1} alignItems={'center'}>
                      <Grid item xs={true}>
                        {loading ? (
                          <LSkeleton height={30} />
                        ) : (
                          <ActionField
                            action={action}
                            disabled={isSubmitting}
                            onChange={(v) => {
                              actions[i] = v;
                              setFieldValue('actions', actions);
                              setFieldValue('remark', getRemark(values));
                            }}
                          />
                        )}
                      </Grid>
                      {actions.length > 1 && (
                        <Grid item>
                          <TrashButton
                            disabled={isSubmitting}
                            onClick={() => {
                              actions.splice(i, 1);
                              setFieldValue('actions', actions);
                              setFieldValue('remark', getRemark(values));
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <AddButton
                    p={0}
                    style={{ marginTop: 20 }}
                    disabled={isSubmitting || loading}
                    onClick={() => {
                      actions.push({ ...defaultAction });
                      setFieldValue('actions', actions);
                      setFieldValue('remark', getRemark(values));
                    }}
                    title={translate.add_setting || '新增設定'}
                  >
                    <Icon
                      style={{
                        margin: '0 10px',
                        color: textColor,
                      }}
                      icon={'faPlus'}
                    />
                  </AddButton>
                </Grid>
              </Grid>
            </Box>
          </StyledConfigList>
        </FormRow>
      </CardContent>
    </Card>
  );
};
