import React from 'react';
import { translate } from '../../../shared/translate';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-5" src={require('../../../assets/paypal-logo.png')} alt="paypal" />,
    label: 'PayPal',
    value: 'PAYPAL',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'Client ID',
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: translate.stripe_secret,
            type: 'text',
            name: 'secret',
            required: true,
            maxLength: 255,
            inputProps: {
              type: 'password',
            },
          },
        ]}
      />
    );
  },
  type: 'PAYMENT',
};
