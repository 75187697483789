import { gql } from '@apollo/client';

export const PRODUCTS_EXPORT_QUERY = gql`
  mutation ($shopId: ID!, $filter: ProductFilterInput) {
    productsExport(shopId: $shopId, filter: $filter) {
      id
      status
      output
    }
  }
`;

export const PRODUCTS_EXPORT_SEO_QUERY = gql`
  mutation ($shopId: ID!, $filter: ProductFilterInput) {
    productsSeoExport(shopId: $shopId, filter: $filter) {
      id
      status
      output
    }
  }
`;
