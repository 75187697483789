import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import StatusBadge from 'components/StatusBadge';
import { StockTransferStatus } from '../../../shared/omniwe-types';
import { checkPermissionV2 } from '../../../components/PermissionMask';
import MenuExport from './MenuExport';
import PrintOptionsButton from '../../../components/PrintOptionsButton';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    createUrl: checkPermissionV2('createTransfer') ? '/transfers/new' : undefined,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id }) => ({ pathname: '/transfers/' + id }),
    fields: [
      {
        title: translate.created_at,
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
      },
      {
        title: translate.operator,
        value: ({ staff }) => (staff || {}).name,
        filter: 'staffId',
        filterType: 'staff',
      },
      {
        title: translate.reference_no,
        fieldName: 'referenceNo',
        filter: 'referenceNo',
        filterType: 'text',
      },
      {
        title: translate.from_warehouse,
        value: ({ outboundWarehouse } = {}) => (outboundWarehouse || {}).name,
        filter: 'outboundWarehouseId',
        filterType: 'warehouse',
      },
      {
        title: translate.to_warehouse,
        value: ({ inboundWarehouse } = {}) => (inboundWarehouse || {}).name,
        filter: 'inboundWarehouseId',
        filterType: 'warehouse',
      },
      {
        title: translate.transfer_quantity,
        value: ({ totalQuantity }) => totalQuantity,
        type: 'number',
        width: 100,
      },
      {
        title: translate.received_quantity,
        value: ({ totalReceivedQuantity }) => totalReceivedQuantity,
        type: 'number',
        width: 100,
      },
      {
        title: translate.status,
        align: 'center',
        width: 140,
        fieldName: 'status',
        filter: 'status',
        type: 'option',
        options: Object.keys(StockTransferStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={StockTransferStatus} />,
          value: key,
        })),
      },
      {
        title: translate.other_actions,
        fieldName: 'action',
        width: 1,
        noLink: true,
        render: ({ id }) => (
          <PrintOptionsButton
            id={id}
            partialHref={'print_transfers'}
            orderedBy={translate.by_created_time || '按建立時間順序排序'}
          />
        ),
      },
      {
        title: translate.product_sku,
        fieldName: 'sku',
        filter: 'sku',
        filterType: 'text',
        column: false,
      },
    ],
    menus: [MenuExport],
    selectionMode: true,
  };

  getData = ({ node } = {}) => {
    const { stockTransfers } = node || {};
    return stockTransfers;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
}
