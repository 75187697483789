import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { translate } from '../../shared/translate';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';

const CheckoutRoundingField = ({ value = {}, onChange, disabled }) => {
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d+$/.test(keyValue) || event.target.value.length >= 5) {
      event.preventDefault();
    }
  };

  return (
    <InputField
      value={value?.maximumFractionDigits}
      onChange={(e) => {
        onChange({
          maximumFractionDigits: e.target.value,
          strategy: value.strategy,
        });
      }}
      disabled={disabled}
      type={'number'}
      inputProps={{
        pattern: '[0-9]*', // For mobile devices
        onKeyPress: handleKeyPress,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'} disableTypography>
            <div style={{ width: 170 }}>
              <SelectField
                value={value?.strategy}
                options={[
                  {
                    label: translate.options_ceiling,
                    value: 'CEILING',
                  },
                  {
                    label: translate.options_floor,
                    value: 'FLOOR',
                  },
                  {
                    label: translate.options_round,
                    value: 'ROUND',
                  },
                ]}
                onChange={(e) => {
                  onChange({
                    ...value,
                    strategy: e.target.value,
                  });
                }}
                disabled={disabled}
              />
            </div>
            {translate.keep}
          </InputAdornment>
        ),
        endAdornment: <InputAdornment position={'end'}>{translate.decimal_digits}</InputAdornment>,
      }}
    />
  );
};

export default CheckoutRoundingField;
