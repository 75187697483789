import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ShopShippingZoneFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        shippingZones(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          totalCount
          nextCursor
          nodes {
            id
            name
            sortIndex
            provider
            active
          }
        }
      }
    }
  }
`;
