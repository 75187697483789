import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int) {
    node(id: $id) {
      id
      ... on Customer {
        giftPointsHistory(cursor: $cursor, limits: $limits) {
          nextCursor
          totalCount
          nodes {
            id
            createdAt
            points
            description
            direction
            metadata {
              key
              value
            }
          }
        }
      }
    }
  }
`;
