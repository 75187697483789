import React from 'react';
import SitemapTaber from './SitemapTaber';
import { Box } from '@material-ui/core';
import ListView from './ListView/index';

const Sitemap = (props) => {
  return (
    <div>
      <SitemapTaber currentTab={1} />
      <Box>
        <ListView {...props} />
      </Box>
    </div>
  );
};

export default Sitemap;
