import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { mergeConnections, parseOSSQuery, PriceFormat } from '../../shared';
import SelectCombo from './index';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';

const SEARCH_QUERY = gql`
  query ($id: ID!, $query: String, $filter: OrderFilterInput, $sortBy: [SorterInput!], $cursor: Int) {
    node(id: $id) {
      id
      ... on CompanyShop {
        orders(limits: 30, query: $query, filter: $filter, sortBy: $sortBy, cursor: $cursor) {
          nextCursor
          totalCount
          nodes {
            status
            id
            referenceNo
            total
          }
        }
      }
    }
  }
`;

const SelectComboOrder = ({ filterType, ...props }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [connection, setConnection] = useState({ nodes: [], totalCount: 0 });

  async function fetchMore() {
    if (connection?.nodes?.length > 0 && !connection?.nextCursor) return;

    try {
      setLoading(true);
      const { data } = await client.query({
        query: SEARCH_QUERY,
        variables: {
          id: localStorage.getItem('shopId'),
          query: [parseOSSQuery(search), !!filterType ? `status: ${filterType}` : undefined]
            .filter(Boolean)
            .join(' AND '),
          sortBy: [{ field: 'updatedAt', order: 'DESC' }],
          cursor: connection?.nextCursor,
          filter: { active: { operator: 'IN', value: ['true'] } },
        },
      });
      setConnection((connection) => mergeConnections(connection, data?.node?.orders));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open && !connection?.nextCursor) fetchMore();
  }, [open]);
  const { nodes, nextCursor } = connection;

  return (
    <>
      <SelectCombo
        onOpen={setOpen}
        getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
        onSearchChange={setSearch}
        getOptionLabel={(option) => {
          const { referenceNo, total } = option || {};
          return `${translate.reference_no}: ${referenceNo}  ${translate.amount}: ${
            total !== undefined ? ` ${PriceFormat(total)}` : ''
          }`;
        }}
        options={nodes}
        loading={loading}
        onScrollBottom={async () => {
          if (!!nextCursor && !loading) await fetchMore();
        }}
        {...props}
      />
    </>
  );
};

export default SelectComboOrder;
