import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { translate } from '../../translate';
import { FRAGMENT_STOCK_ADJUSTMENT_ITEM } from '../../../routes/item_StockOut/query';

const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      ... on CompanyStockAdjustment {
        items(limits: $limits, cursor: $cursor) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_STOCK_ADJUSTMENT_ITEM
          }
        }
      }
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT_ITEM}
`;

export default async function (source) {
  try {
    return await infinityFetch({
      query: GET_QUERY,
      variables: {
        id: source?.id,
      },
      getConnection: (data) => data?.node?.items,
      onProgress: (downloaded, total) => {
        if (total > 0) ActivityIndicator.show(translate.loading + ` ${downloaded}/${total}`);
      },
    });
  } catch (e) {
    throw e;
  } finally {
    ActivityIndicator.hide();
  }
}
