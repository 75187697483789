import React, { useRef, useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { GET_COLLECTION_PRODUCT_UNIONS, GET_QUERY } from './query';
import { infinityFetch, parseConnection } from '../../../shared';
import { useQuery } from '@apollo/client';
import LSkeleton from '../../../components/LSkeleton';
import SystemButton from '../../../components/SystemButton';
import CollectionModal from './CollectionModal';
import ActivityIndicator from '../../../components/ActivityIndicator';
import SelectComboProductUnion from '../../../components/SelectCombo/SelectComboProductUnion';

const ExcludedProductsCard = ({ values: { excludedProductIds }, setFieldValue, isSubmitting, loading }) => {
  const collectionModal = useRef();
  const [productUnions, setProductUnions] = useState([]);
  const { loading: _loading } = useQuery(GET_QUERY, {
    onCompleted: ({ node } = {}) => {
      setProductUnions(parseConnection((node || {}).productUnions).nodes);
    },
    variables: {
      id: localStorage.getItem('shopId'),
      filter: {
        sku: excludedProductIds?.length
          ? {
              operator: 'IN',
              value: excludedProductIds,
            }
          : {
              operator: 'IN',
              value: ['0'],
            },
      },
    },
  });

  const disabled = isSubmitting || _loading || loading;

  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
            <Grid item xs>
              <Typography variant={'h6'}>{translate.exclude_the_following_products}</Typography>
            </Grid>
            <Grid item>
              <SystemButton
                disabled={isSubmitting}
                onClick={async () => {
                  try {
                    const data = await collectionModal.current.execute({ collection: undefined });
                    const collectionId = data?.collection?.id;
                    if (collectionId) {
                      const descendentProductUnions = await infinityFetch({
                        query: GET_COLLECTION_PRODUCT_UNIONS,
                        variables: {
                          id: collectionId,
                        },
                        getConnection: (data) => data?.node?.descendentProductUnions,
                        onProgress: (downloaded, total) => {
                          if (total > downloaded)
                            ActivityIndicator.show(`${translate.download} ${downloaded} / ${total}`);
                          else ActivityIndicator.show(`${translate.download}`);
                        },
                      });
                      ActivityIndicator.hide();
                      setFieldValue(
                        'excludedProductIds',
                        parseConnection(descendentProductUnions).nodes.map((p) => p.sku),
                      );
                    }
                  } catch (e) {}
                }}
              >
                {translate.designated_collection}
              </SystemButton>
            </Grid>
          </Grid>
          {loading ? (
            <LSkeleton height={30} />
          ) : (
            <SelectComboProductUnion
              copyableType={'ProductUnion'}
              multiple
              idKey={'sku'}
              disabled={disabled}
              value={productUnions}
              onChange={(productUnions) => {
                setFieldValue(
                  'excludedProductIds',
                  (productUnions || []).map((p) => p.sku),
                );
              }}
            />
          )}
        </CardContent>
      </Card>
      <CollectionModal ref={collectionModal} />
    </>
  );
};

export default ExcludedProductsCard;
