import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

const useRetryEffect = (
  fn = async (retry) => {
    console.log(retry);
  },
  retryCount = 2,
  retryDelay = 250,
  onFailed = async (error) => {},
) => {
  const [retry, setRetry] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();

  useDebounce(
    () => {
      if (retry > 0 && loading) tryFn();
    },
    retryDelay,
    [retry],
  );
  useEffect(() => {
    if (retry >= retryCount) {
      setLoading(false);
      setData(undefined);
      onFailed(error);
    } else if (retry === 0) {
      tryFn();
    }
  }, [retry]);

  if (loading) return { loading };
  return { loading, data, error };

  function tryFn() {
    fn(retry)
      .then((res) => {
        setLoading(false);
        setData(res);
        setError(undefined);
      })
      .catch((e) => {
        setError(e);
        setRetry((r) => r + 1);
      });
  }
};

export default useRetryEffect;
