import { gql } from '@apollo/client';

export const GET_SHOPS = gql`
  query ($cursor: Int, $limits: Int, $filter: UserShopsFilterInput, $sortBy: [SorterInput!], $query: String) {
    me {
      ... on User {
        id
        shops(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy, query: $query) {
          totalCount
          nextCursor
          nodes {
            id
            name
            active
          }
        }
      }
    }
  }
`;

export const GET_WAREHOUSES_FROM_SHOP = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: WarehouseFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      ... on CompanyShop {
        id
        availableWarehouses(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) @client
      }
    }
  }
`;

export const GET_QUERY = gql`
  query (
    $companyId: ID!
    $shopId: ID!
    $warehouseIds: [ID!]!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $query: String
  ) {
    node(id: $companyId) {
      ... on Company {
        report {
          inventory(query: $query, warehouseIds: $warehouseIds, cursor: $cursor, limits: $limits, sortBy: $sortBy) {
            nextCursor
            totalCount
            nodes {
              sku
              productName(shopId: $shopId)
              combination
              stocks {
                quantity
                warehouseId
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SOLD_REPORT_DUMMY = gql`
  query {
    me {
      ... on User {
        id
      }
    }
  }
`;
export const GET_SOLD_REPORT = (timeRange = []) => gql`
query (
  $shopId: ID
  $query: String
  $companyId: ID!
) {
  node(id: $companyId) {
    ... on Company {
      report {
        ${timeRange
          ?.map(
            ({ value, since, until }) =>
              `${value}: topVariationSales(query:$query, startedAt:"${since}",startedThru:"${until}",shopId:$shopId){
              nodes{ 
              sku
              count
              }
              }`,
          )
          ?.join('\n')}
      }
    }
  }
}`;

export const EXPORT_QUERY = gql`
  mutation ($shopId: ID!, $exportShopIds: [ID!]) {
    inventoryReportExport(shopId: $shopId, exportShopIds: $exportShopIds) {
      id
    }
  }
`;
