import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';

export default (props) => {
  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.all_attributes || '所有自定參數',
          },
        ]}
      />
      <ListView {...props} />
    </div>
  );
};
