import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@material-ui/core';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import SettingCommon from './SettingCommon';
import { addValue2Metadata, convertMetaObject } from '../../../shared';
import SelectComboValue from '../../SelectCombo/SelectComboValue';
import _ from 'lodash';
import codes from 'country-calling-code';
import SystemButton from '../../SystemButton';
import { Icon } from '../../IconRender';
import { useDebounce } from 'react-use';

const SettingTEL = (props) => {
  const { disabled, value, onChange = (_) => _ } = props;
  const [open, setOpen] = useState(false);
  const { metadata } = value ?? {},
    { onlyAllows: _onlyAllows } = convertMetaObject(metadata ?? []);

  const [onlyAllows, setOnlyAllows] = useState(_onlyAllows?.split(',') ?? []);

  const countries = _.sortBy(codes, ({ isoCode3, countryCodes }) => {
    const countryCode = countryCodes[0] || '';
    return ['HKG', 'CHN', 'MAC'].indexOf(isoCode3) + 1 || 10 + +countryCode.replace(/[^.\d]/g, '');
  });

  useDebounce(
    () => {
      const v = onlyAllows?.join(',');
      if (v !== _onlyAllows)
        onChange({
          ...value,
          metadata: addValue2Metadata('onlyAllows', v, metadata),
        });
    },
    200,
    [onlyAllows],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SettingCommon {...props} />
      </Grid>
      <Grid item xs={12}>
        <SystemButton
          variant={'text'}
          disabled={disabled}
          onClick={() => setOpen(!open)}
          color={'secondary'}
          startIcon={<Icon icon={open ? 'faChevronUp' : 'faChevronDown'} />}
        >
          {translate.constraints}
        </SystemButton>
        <Collapse in={open}>
          <Box p={2} mt={2} bgcolor={'#F5F5F5'}>
            <Grid container spacing={2}>
              <FormRow title={'只允許國家／地區'}>
                <SelectComboValue
                  multiple={true}
                  options={countries.map((country) => ({
                    label: `+${(country.countryCodes || [])[0]} ${country?.isoCode3 ?? ''}`,
                    value: `+${(country.countryCodes || [])[0]}`,
                  }))}
                  disabled={disabled}
                  value={onlyAllows}
                  onChange={(arr) => {
                    setOnlyAllows(arr);
                  }}
                />
              </FormRow>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default SettingTEL;
