import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../SplitCard';
import EmailWelcome from '../EmailWelcome';
import defaultSuggestions from '../defaultSuggestions';

export default class EmailBlock extends EmailWelcome {
  emailType = 'CUSTOMER_BLOCK';
  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[...defaultSuggestions]}
              allows={['isTitle', 'isText', 'isImage', 'isDivider', 'isButton', 'isLink']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              extraData={{
                blocked: {
                  reason: '違反使用條款',
                },
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
