import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import TopToolbar from './TopToolbar';
import CoreLoadingSkeleton from './CoreLoadingSkeleton';
import Core from './Core';
import StartForm from './StartForm';
import { getTokenData } from '../../shared';

const ChannelMessage = ({ id: _channelId, code, type, shopId: _shopId, disabled, header }) => {
  const me = getTokenData();
  const [channelId, setChannelId] = useState(_channelId);
  const shopId = _shopId ?? localStorage.getItem('shopId') ?? '';
  return (
    <Grid
      container
      style={{
        width: '100%',
        height: '100%',
      }}
      direction={'column'}
    >
      <Grid item>
        <Box py={1} px={2}>
          <TopToolbar />
        </Box>
        {header}
      </Grid>
      <Grid item xs={true}>
        <Box height={'100%'} display={'flex'} flexDirection={'column'}>
          {!me && <CoreLoadingSkeleton />}
          {!!me && !!channelId && (
            <Core
              shopId={shopId}
              channelId={channelId}
              owner={{
                id: shopId,
                name: me?.name,
                picture: me?.picture,
                email: me?.email,
              }}
              disabled={disabled}
            />
          )}
          {!!me && !channelId && (
            <StartForm
              code={code}
              type={type}
              shopId={shopId}
              onChannelCreate={({ id }) => setChannelId(id)}
              disabled={disabled}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
export default ChannelMessage;
