import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, MenuItem, Typography } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import { Formik } from 'formik';
import FormRow from '../../../../components/FormRow';
import moment from 'moment';
import { client } from '../../../../shared/apollo';
import { RECORD_EXPORT } from './query';
import SelectCombo from '../../../../components/SelectCombo';
import FilterDatetime from '../../../../components/ConnectionPageMaker/Filter/FilterDatetime';
import { actionWrapper, parseConnection } from '../../../../shared';
import _ from 'lodash';
import FormToolbar from '../../../../components/FormToolbar';

const statuses = [
  {
    label: translate.not_used,
    value: 'ACTIVE',
  },
  {
    label: translate.used,
    value: 'USED',
  },
  {
    label: translate.expired,
    value: 'EXPIRED',
  },
];

const ImportModal = ({ couponCount, infinityFetchCoupons = async (_) => undefined, onClick = (_) => _ }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <MenuItem
        disabled={!couponCount}
        onClick={() => {
          onClick();
          setVisible(true);
        }}
      >
        {translate.export_record}
      </MenuItem>

      <Formik
        initialValues={{
          createdAt: [
            moment().subtract(1, 'years').startOf('days').toISOString(),
            moment().endOf('days').toISOString(),
          ],
          validThru: [
            moment().subtract(1, 'years').startOf('days').toISOString(),
            moment().endOf('days').toISOString(),
          ],
          status: [],
        }}
        onSubmit={async ({ createdAt, validThru, status }) => {
          await actionWrapper(
            async () => {
              const filterCreatedAt = !!createdAt?.filter((_) => _).length
                ? {
                    createdAt: {
                      operator: 'BETWEEN',
                      value: createdAt,
                    },
                  }
                : {};
              const filterValidThru = !!validThru?.filter((_) => _).length
                ? {
                    validThru: {
                      operator: 'BETWEEN',
                      value: validThru,
                    },
                  }
                : {};
              const filterStatus = !!status.length
                ? {
                    status: {
                      operator: 'IN',
                      value: status.map((v) => v.value),
                    },
                  }
                : {};
              const filterId = await (async () => {
                if (couponCount < 200) {
                  const coupons = parseConnection(await infinityFetchCoupons()).nodes;
                  return {
                    id: {
                      operator: 'IN',
                      value: _.map(coupons, 'id').filter((_) => _),
                    },
                  };
                }
              })();

              await client.mutate({
                mutation: RECORD_EXPORT,
                variables: {
                  shopId: localStorage.getItem('shopId'),
                  filter: {
                    ...filterCreatedAt,
                    ...filterValidThru,
                    ...filterStatus,
                    ...filterId,
                  },
                },
              });
            },
            {
              messages: {
                success: translate.export_tips,
              },
            },
          );
          setVisible(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          const { createdAt, validThru, status } = values || {};

          return (
            <Dialog open={visible} onClose={() => setVisible(false)}>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant={'h6'}>
                        {translate.formatString(translate['n_records_selected'], couponCount)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormRow title={translate.status}>
                        <SelectCombo
                          multiple
                          value={status}
                          options={statuses}
                          onChange={(e) => {
                            setFieldValue('status', e);
                          }}
                        />
                      </FormRow>
                    </Grid>
                    <Grid item>
                      <FilterDatetime
                        title={translate.coupon_sending_date}
                        filter={{ value: createdAt }}
                        onFilterChange={(e) => setFieldValue('createdAt', e?.value)}
                        filterInputProps={{
                          untilProps: {
                            inputProps: {
                              min: !!createdAt?.[0] && moment(createdAt?.[0]).format('YYYY-MM-DDTHH:mm'),
                              required: !!createdAt?.filter((_) => _).length,
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FilterDatetime
                        title={translate.effective_date}
                        filter={{ value: validThru }}
                        onFilterChange={(e) => setFieldValue('validThru', e?.value)}
                        filterInputProps={{
                          untilProps: {
                            inputProps: {
                              min: !!validThru?.[0] && moment(validThru?.[0]).format('YYYY-MM-DDTHH:mm'),
                              required: !!validThru?.filter((_) => _).length,
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <FormToolbar
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onCancel={() => setVisible(false)}
                  submitBtnType={'submit'}
                />
              </form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default ImportModal;
