import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { listReorder } from '../../shared';

const DraggableList = ({
  direction,
  className = '',
  style,
  items = [],
  itemRender,
  onChange = (_) => _,
  keyField = 'id',
  disabled,
  externalDroppableId,
  children,
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = listReorder(items, result.source.index, result.destination.index);
    onChange(newItems);
  };

  const core = (
    <Droppable droppableId={externalDroppableId || 'droppable'} direction={direction}>
      {(provided) => (
        <div className={className} style={style} {...provided.droppableProps} ref={provided.innerRef}>
          {items.map((item, index) => (
            <Draggable key={item[keyField]} draggableId={item[keyField]} index={index} isDragDisabled={disabled}>
              {(provided) => (
                <div key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  {itemRender({ item, index, ...provided })}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          {children}
        </div>
      )}
    </Droppable>
  );

  return !!externalDroppableId ? core : <DragDropContext onDragEnd={onDragEnd}>{core}</DragDropContext>;
};

export default DraggableList;
