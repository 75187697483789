import { gql } from '@apollo/client';

export const FRAGMENT_COMPANY_MEMBER_TIER = gql`
  fragment CompanyMemberTier on CompanyMemberTier {
    name
    level
    memberPointActive
    memberPointUnitPrice
    memberPointPerUnit
    upgradeConditions {
      type
      upgradeConditionMonths
      upgradeConditionAmount
    }
    extensionType
    extensionMonths
    extensionAmount
    isDefault
    expiryType
    validMonths
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_COMPANY_MEMBER_TIER}
  query($id: ID!) {
    node(id: $id) {
      id
      createdAt
      updatedAt
      ...CompanyMemberTier
    }
  }
`;

export const UPDATE_QUERY = gql`
  ${FRAGMENT_COMPANY_MEMBER_TIER}
  mutation($id: ID, $input: CompanyMemberTierInput!) {
    companyMemberTierSet(id: $id, input: $input) {
      id
      ...CompanyMemberTier
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation($id: ID!) {
    companyMemberTierUnset(id: $id) {
      id
    }
  }
`;
