import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import { GET_SALE_RECORD_QUERY } from './query';

class SalesRecordsList extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_products_data,
    gql: {
      get: GET_SALE_RECORD_QUERY,
    },
    fields: [
      {
        title: translate.date,
        fieldName: 'createdAt',
        type: 'datetime',
      },
      {
        title: translate.shop_name,
        fieldName: 'shopName',
      },
      {
        title: translate.order_number,
        fieldName: 'referenceNo',
      },
      {
        title: translate.quantity,
        fieldName: 'count',
      },
      {
        title: translate.amount,
        fieldName: 'amount',
        type: 'price',
      },
      {
        title: translate.discount_amount,
        fieldName: 'discountAmount',
        type: 'price',
      },
    ],
  };

  getExtraFetchVariables() {
    const { startedAt, startedThru } = this.getQueryParams();
    const { id } = this.props;

    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
      couponId: id,
    };
  }

  getData(data) {
    const { node } = data || {},
      { report } = node || {},
      { couponSales } = report || {},
      { salesRecords } = couponSales || {};

    return { ...salesRecords };
  }
}

export default SalesRecordsList;
