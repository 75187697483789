import React from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { useQuery } from '@apollo/client';
import { GET_QUERY } from './query';
import PageBox from './PageBox';
import { Box, InputAdornment, Typography } from '@material-ui/core';
import SelectComboArticle from '../../../../../components/SelectCombo/SelectComboArticle';
import HighlightFormRow from '../../HighlightFormRow';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../../../../components/FormPageMaker/Fields/SelectField';

export default {
  label: translate.article,
  defaultHeight: 0,
  icon: <Icon icon={'faNewspaper'} style={{ color: '#97a1aa' }} />,
  preview: function PreviewFC({ data, setEditData, height }) {
    const { pageId, ratio, resizeMode = 'contain' } = data || {};
    const { loading, data: { node } = {} } = useQuery(GET_QUERY, { skip: !pageId, variables: { id: pageId } });

    return (
      <div style={{ cursor: 'pointer' }} onClick={() => setEditData(data)}>
        <PageBox loading={loading} page={node} height={height} ratio={ratio} resizeMode={resizeMode}>
          {!pageId && (
            <Box py={5}>
              <Typography variant={'h4'} align={'center'}>
                {translate.click_to_add_article}
              </Typography>
            </Box>
          )}
        </PageBox>
      </div>
    );
  },
  edit: ({ value, onChange }) => {
    const { pageId, pageName, ratio, resizeMode = 'contain' } = value || {};
    const update = (k, v) => {
      onChange({
        ...value,
        [k]: v,
      });
    };
    return (
      <>
        <HighlightFormRow highlight={value.pageId !== undefined} title={translate.article}>
          <SelectComboArticle
            value={{ id: pageId, name: pageName }}
            onChange={(v) =>
              onChange({
                ...value,
                pageId: (v || {}).id,
                pageName: (v || {}).name,
              })
            }
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.resizeMode !== undefined} title={translate.image_resize_mode}>
          <SelectField
            value={resizeMode}
            onChange={(e) => update('resizeMode', e.target.value)}
            options={[
              { label: 'Contain', value: 'contain' },
              { label: 'Cover', value: 'cover' },
            ]}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.ratio !== undefined} title={'Image Ratio'}>
          <InputField
            type={'number'}
            value={ratio}
            onChange={(e) => update('ratio', e.target.value)}
            onBlur={(e) => update('ratio', e.target.value ? +e.target.value : undefined)}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: <InputAdornment position={'start'}>1 :</InputAdornment>,
            }}
          />
        </HighlightFormRow>
      </>
    );
  },
};
