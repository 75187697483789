import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LSkeleton from '../../LSkeleton';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import SelectField from './SelectField';
import { translate } from '../../../shared/translate';

const useStyle = makeStyles((theme) => ({
  fieldHandler: {
    padding: `${5}px ${theme.spacing(1)}px ${5}px ${theme.spacing(2)}px`,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#c4c4c4',
    borderStyle: 'solid',
    '&:hover': {
      borderColor: '#000',
    },
    '& .MuiSelect-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

const CustomTimeField = ({ loading, value, onChange, disabled, required, minuteStep = 1, InputProps }) => {
  const classes = useStyle();
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);

  // input
  useEffect(() => {
    if (value) {
      setHour(moment(value).format('HH'));
      setMinute(Math.ceil(moment(value).minute()).toString().padStart(2, '0'));
    }
  }, [value]);

  // output
  useEffect(() => {
    if (hour && minute) {
      onChange(moment(`${hour}:${minute}`, 'HH:mm').toISOString());
    }
  }, [hour, minute]);

  if (loading) return <LSkeleton height={30} />;
  return (
    <div className={classes.fieldHandler}>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs>
          <SelectField
            required={required}
            key={!!hour ? 1 : 0}
            variant={'standard'}
            InputProps={{
              disableUnderline: true,
              placeholder: '--',
              ...InputProps,
            }}
            value={hour}
            onChange={(e) => {
              // console.log('hour e', e.target?.value);
              setHour(e?.target?.value);
              if (minute === null) {
                setMinute('00');
              }
            }}
            disabled={disabled}
            options={Array(24)
              .fill(undefined)
              .map((__, i) => {
                const prefix = i <= 11 ? translate.am : i === 12 ? translate.noon : translate.pm;
                const str = `${i}`.padStart(2, '0');
                return {
                  label: `${prefix} ${str}`,
                  value: str,
                };
              })}
          />
        </Grid>
        <Grid item>
          <Typography>:</Typography>
        </Grid>
        <Grid item xs>
          <SelectField
            required={required}
            key={!!minute ? 1 : 0}
            variant={'standard'}
            InputProps={{
              disableUnderline: true,
              placeholder: '--',
            }}
            value={minute}
            onChange={(e) => {
              // console.log('minute e', e.target?.value);
              setMinute(e?.target?.value);
              if (hour === null) {
                setHour('00');
              }
            }}
            disabled={disabled}
            options={Array(60 / minuteStep)
              .fill(undefined)
              .map((__, i) => {
                const str = `${i * minuteStep}`.padStart(2, '0');
                return {
                  label: str,
                  value: str,
                };
              })}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default CustomTimeField;
