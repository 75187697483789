import React, { useState, useEffect } from 'react';
import { Box, Dialog } from '@material-ui/core';
import { Formik } from 'formik';
import FormToolbar from '../../../../components/FormToolbar';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { translate } from '../../../../shared/translate';
import { BodyComponentsMap } from '../BodyComponents';
import CheckboxField from '../../../../components/FormPageMaker/Fields/CheckboxField';
import _ from 'lodash';

const tabs = [
  {
    name: translate.default,
  },
  { name: `${translate.mobile} (XS)`, subset: 'xs' },
  { name: `${translate.tablet} (SM)`, subset: 'sm' },
  { name: `${translate.notebook} (MD)`, subset: 'md' },
  { name: `${translate.desktop} (LG)`, subset: 'lg' },
  { name: `${translate.television} (XL)`, subset: 'xl' },
];

const EditModal = ({ open, onClose, value, onChange = (_) => _ }) => {
  const [tab, setTab] = useState(0);
  useEffect(() => {
    if (open) setTab(0);
  }, [open]);

  const _type = (value || {})._type,
    { edit: Comp, wideEditor } = BodyComponentsMap[_type] || {};

  return (
    <Dialog open={open && !!Comp} maxWidth={wideEditor ? false : 'sm'} onClose={onClose} disableEnforceFocus={true}>
      {!!Comp && (
        <Formik
          initialValues={{ ...value }}
          onSubmit={(values) => {
            onChange(values);
            onClose();
          }}
        >
          {(actions) => {
            const { values, handleSubmit, setValues, setFieldValue } = actions;
            const useSubset = tab > 0;
            const value = useSubset ? values[tabs[tab].subset] : values;
            const useDefault = _.isNil(values[tabs[tab].subset]);

            return (
              <>
                <form
                  key={tab}
                  onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(e);
                  }}
                >
                  <Box p={3} overflow={'auto'} height={'calc(100vh - 200px)'} maxHeight={1000} minHeight={300}>
                    <Box mb={2}>
                      <ToggleButtonGroup
                        exclusive
                        value={tab}
                        onChange={(__, _tab) => setTab(_tab !== null && _tab !== undefined ? _tab : tab)}
                        size={'small'}
                      >
                        {tabs.map(({ name }, i) => (
                          <ToggleButton key={i} value={i}>
                            {name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Box>
                    {useSubset && (
                      <Box mb={2}>
                        <CheckboxField
                          checked={useDefault}
                          text={translate.use_default}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setFieldValue(tabs[tab].subset, { _type });
                            } else {
                              setFieldValue(tabs[tab].subset, null);
                            }
                          }}
                        />
                      </Box>
                    )}
                    {(!useSubset || !useDefault) && (
                      <Comp
                        value={value || {}}
                        onChange={(v) => {
                          if (tabs[tab].subset) {
                            values[tabs[tab].subset] = {
                              ...v,
                            };
                            setValues(values);
                          } else {
                            setValues(v);
                          }
                        }}
                      />
                    )}
                  </Box>
                  <FormToolbar submitBtnType={'submit'} onCancel={onClose} />
                </form>
              </>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};

export default EditModal;
