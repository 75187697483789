import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { GET_QUERY, SUBMIT_QUERY, UPDATE_QUERY } from './query';
import { translate } from '../../shared/translate';
import SelectComboWarehouse from '../../components/SelectCombo/SelectComboWarehouse';
import LSkeleton from '../../components/LSkeleton';
import { cloneObject } from '../../shared';
import { client } from '../../shared/apollo';
import ItemsCard from './ItemsCard';
import RejectButton from './RejectButton';
import CompleteButton from './CompleteButton';
import { ReactComponent as newPrintIcon } from '../../assets/icon/newPrint.svg';
import { ReceivePurchaseStatus } from '../../shared/omniwe-types';
import StatusBadge from '../../components/StatusBadge';
import { Skeleton } from '@material-ui/lab';
import { checkPermissionV2 } from '../../components/PermissionMask';
import ReceivePurchaseImportButton from './ReceivePurchaseImportButton';
import VoidButton from './VoidButton';
import SystemButton from '../../components/SystemButton';
import { Icon } from '../../components/IconRender';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { CardContent, Divider, Typography } from '@material-ui/core';

class ItemReceivePurchase extends FormPageMaker {
  state = {
    ...this.state,
    editMode: !!this.state.id,
    gql: {
      prepare: GET_QUERY,
      submit: checkPermissionV2('updateReceivePurchase') ? SUBMIT_QUERY : undefined,
      update: checkPermissionV2('updateReceivePurchase') ? UPDATE_QUERY : undefined,
    },
    formDisabled: !checkPermissionV2('updateReceivePurchase'),
    tabs: [
      {
        name: translate.receive_purchase,
        cards: [
          {
            fields: [
              {
                label: translate.status,
                render: ({ loading, values: { status } }) =>
                  loading ? (
                    <Skeleton width={100} height={30} />
                  ) : (
                    <StatusBadge status={status} statusObject={ReceivePurchaseStatus} />
                  ),
                md: 12,
              },
              {
                label: translate.inbound_date,
                name: 'completedAt',
                disabled: true,
                type: 'datetime-local',
                md: 6,
              },
              {
                label: translate.reference_no,
                name: 'referenceNo',
                type: 'text',
                disabled: true,
                md: 6,
              },
              {
                label: translate.to_warehouse,
                required: true,
                md: 6,
                render: ({ loading, values, disabled, setFieldValue }) => {
                  return loading ? (
                    <LSkeleton height={30} />
                  ) : (
                    <SelectComboWarehouse
                      value={values.warehouse}
                      required
                      disabled={disabled || values?.status !== 'PENDING'}
                      onChange={(v) => setFieldValue('warehouse', v)}
                    />
                  );
                },
              },
              {
                md: 12,
                render: ({ loading, values, disabled, setFieldValue }) => {
                  const allowEdit = !values?.id || (this.state.dirty && values?.status === 'PENDING');
                  return (
                    <CardContent style={{ backgroundColor: '#f6f6f6', borderRadius: 10, padding: 30 }}>
                      <Typography variant={'h6'}>{translate.remark}</Typography>
                      <Divider style={{ margin: '10px 0 15px 0' }} />
                      {loading ? (
                        <LSkeleton height={30} />
                      ) : (
                        <InputField
                          variant={allowEdit ? 'outlined' : 'standard'}
                          InputProps={{
                            disableUnderline: allowEdit ? false : true,
                          }}
                          value={values.remark}
                          disabled={disabled || values?.status !== 'PENDING'}
                          onChange={(v) => setFieldValue('remark', v?.target?.value)}
                        />
                      )}
                    </CardContent>
                  );
                },
              },
            ],
          },
          ItemsCard,
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit, update },
    } = this.state;

    const { data: { result } = {} } = await client.mutate({
      mutation: !!this.state.id ? update : submit,
      variables: {
        id: this.state.id,
        input: {
          warehouseId: values.warehouse.id,
          remark: values?.remark,
          items: (values.items || []).map((item) => ({
            id: !!this.state.id ? item?.id : undefined,
            sku: (item || {}).sku,
            quantity: (item || {}).quantity - 0,
            cost: +(+item?.cost || 0).toFixed(5),
          })),
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const {
      id,
      warehouse,
      updatedAt,
      items = [],
      status = 'PENDING',
      referenceNo,
      completedAt,
      staff,
      remark,
    } = node || {};
    return {
      id,
      warehouse,
      updatedAt,
      items: cloneObject(
        (items?.nodes || [])?.map(({ productVariation, ...item }) => ({
          ...item,
          variation: productVariation,
        })),
      ),
      status,
      referenceNo,
      completedAt,
      staff,
      remark,
    };
  }

  allowSubmit(actions) {
    const {
      values: { status },
    } = actions;
    if (!super.allowSubmit(actions)) return false;
    if (status !== 'PENDING') return false;
    return true;
  }

  onCompleted(data) {
    if (data.node.status !== 'PENDING') {
      this.setState({
        editMode: false,
      });
    }
  }

  renderExtraButtons(actions) {
    const { values: { id, status, __disabled } = {} } = actions || {};
    return (
      <>
        {!!id && status === 'COMPLETED' && <VoidButton {...actions} />}
        {checkPermissionV2('completeReceivePurchase') && !!id && status === 'PENDING' && __disabled && (
          <ReceivePurchaseImportButton id={id} />
        )}
        {!!id && (
          <SystemButton
            onClick={(e) => {
              e.stopPropagation();
              window && window.open(`/print_receive_purchase/${id}`, '_blank');
            }}
          >
            <Icon type={'svg'} icon={newPrintIcon} viewBox={'0 0 21 21'} style={{ height: 20.44 }} />
          </SystemButton>
        )}
        {checkPermissionV2('completeReceivePurchase') && !!id && status === 'PENDING' && __disabled && (
          <CompleteButton {...actions} />
        )}
        {checkPermissionV2('rejectReceivePurchase') && !!id && status === 'PENDING' && <RejectButton {...actions} />}
      </>
    );
  }
}

export default ItemReceivePurchase;
