import React, { useState } from 'react';
import { ACTIVATE_QUERY } from './query';
import { useMutation } from '@apollo/client';
import { translate, translateFormat } from '../../../shared/translate';
import { toast } from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import ActivityIndicator from '../../../components/ActivityIndicator';
import confirmation from '../../../shared/confirmation';
import SystemButton from '../../../components/SystemButton';
import { sleep } from '../../../shared';

const ActivateButton = ({ id, name, list, active }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [activate] = useMutation(ACTIVATE_QUERY);
  return (
    <SystemButton
      disabled={isSubmitting || active}
      size={'small'}
      variant={'contained'}
      color={'secondary'}
      onClick={async () => {
        try {
          if (
            await confirmation(
              translate.confirm_set_sitemap_active,
              translateFormat(translate.confirm_use_of, { name }),
            )
          ) {
            ActivityIndicator.show();
            setSubmitting(true);
            await activate({
              variables: { id },
            });
            sleep(3000).then(() => list.refetch());
            toast.success(translate.update_success);
          }
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          ActivityIndicator.hide();
          setSubmitting(false);
          list.refetch();
        }
      }}
    >
      {translate.use}
    </SystemButton>
  );
};

export default ActivateButton;
