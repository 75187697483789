import React from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Icon } from './IconRender';
import { textColor, fieldBackgroundColor } from '../theme';
import { translate } from '../shared/translate';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: fieldBackgroundColor,
  },
  addButton: {
    backgroundColor: '#fcfcfc',
    padding: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
  },
}));

export default ({ children, direction = 'column', spacing = 3 }) => {
  const classes = useStyles();
  return (
    <Box p={2} className={classes.box}>
      <Grid container direction={direction} spacing={spacing}>
        {children}
      </Grid>
    </Box>
  );
};

export const AddButton = ({ disabled, onClick, title, style, p = 1.5 }) => {
  const classes = useStyles();
  return (
    <Box p={p}>
      <Button
        disabled={disabled}
        className={classes.addButton}
        fullWidth
        startIcon={<Icon icon={'faPlus'} />}
        onClick={onClick}
        style={{ color: textColor, ...style }}
      >
        <Typography style={{ color: textColor, fontWeight: 'bold' }}>{title || translate.add}</Typography>
      </Button>
    </Box>
  );
};
