import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { parseConnection, unifiedReferenceNo } from '../../shared';
import ItemsTable from './ItemsTable';
import PaymentTable from './PaymentTable';
import useReceiptTemplateConfig from '../../hooks/useReceiptTemplateConfig';
import ReceiptTemplatePreviewer from '../../components/ReceiptTemplatePreviewer';
import OmniweLoading from '../../components/OmniweLoading';
import { translate } from '../../shared/translate';

const OrderPrintout = ({ loading: _loading, order: node }) => {
  const { loading: __loading, config } = useReceiptTemplateConfig('ORDER');

  const loading = _loading || __loading;
  const invoices = parseConnection(node?.invoices).nodes;
  const deliveryNotes = parseConnection(node?.deliveryNotes).nodes;

  return (
    <Card square>
      {loading ? (
        <CardContent style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block', marginTop: '5rem', marginBottom: '5rem' }}>
            <OmniweLoading loading={true} color={'grey'} />
          </div>
        </CardContent>
      ) : (
        <CardContent>
          <style>
            {`
              html, body {
                font-size: 16px;
              }
              h6 {
                font-size: 1.4rem;
              }
              hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
                border: 0;
                border-top: 1px solid rgba(0,0,0,.1)
              }
              @media print {
                html, body {
                  font-size: 18px;
                }
                h6 {
                  font-size: 1.4rem;
                }
              }
            `}
          </style>
          <ReceiptTemplatePreviewer
            variables={{
              ...node,
              billingAddress: {
                ...node?.billingAddress,
                country: translate.countries[node?.billingAddress?.country] || node?.billingAddress?.country,
              },
              shippingAddress: {
                ...node?.shippingAddress,
                country: translate.countries[node?.shippingAddress?.country] || node?.shippingAddress?.country,
              },
              items: node?.items?.map((item, i) => ({
                ...item,
                index: i,
              })),
              referenceNo: unifiedReferenceNo(node?.referenceNo),
              referenceNoLong: unifiedReferenceNo(node?.referenceNo, true),
              invoices: invoices.map((inv, i) => ({
                index: i,
                ...inv,
                paymentMethod: inv?.paymentMethod || inv?.paymentMethodV2,
              })),
              trackingNumber: deliveryNotes
                .map((dn) => dn.trackingNumber)
                .filter(Boolean)
                .join(', '),
              shop: { ...node?.shop, logo: node?.shop?.logoMedia?.src ?? node?.shop?.logoMedia?.optimizedSrc },
              ...config,
            }}
            extraRenderer={{
              isDivider: () => <hr />,
              isItems: (node) => <ItemsTable loading={loading} order={node} />,
              isPaymentData: (node) => <PaymentTable loading={loading} order={node} />,
            }}
          />
        </CardContent>
      )}
    </Card>
  );
};

export default OrderPrintout;
