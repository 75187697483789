import { Grid } from '@material-ui/core';
import React from 'react';
import SectionItem from './SectionItem';
import { translate } from '../../../shared/translate';
import { Skeleton } from '@material-ui/lab';
import AddButton from '../../../components/CustomFormField/AddButton';

const ChooseProductCard = ({ values, setFieldValue, loading, isSubmitting }) => {
  const { sections = [] } = values || {};
  const onChange = (e) => {
    setFieldValue('sections', e);
  };
  const disabled = loading || isSubmitting;

  if (loading) return <Skeleton variant={'rect'} width={'100%'} height={500} style={{ borderRadius: 10 }} />;

  return (
    <Grid container spacing={5}>
      {sections?.map((section, i) => (
        <Grid item xs={12} key={i}>
          <SectionItem
            value={section}
            productOrderNum={i + 1}
            productTotalNum={sections?.length}
            disabled={disabled}
            onChange={(updatedSection) => {
              if (!updatedSection) return onChange(sections?.filter((_, j) => i !== j));
              onChange(
                sections?.map((section, j) => {
                  if (i === j) {
                    return updatedSection;
                  }
                  return section;
                }),
              );
            }}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <AddButton
          disabled={disabled}
          onClick={() => {
            onChange(sections?.concat({ options: [] }));
          }}
          title={translate.add_bundle}
        />
      </Grid>
    </Grid>
  );
};

export default ChooseProductCard;
