import { gql } from '@apollo/client';

export const FRAGMENT_NEWSLETTER_SUBSCRIPTION = gql`
  fragment FRAGMENT_NEWSLETTER_SUBSCRIPTION on NewsletterSubscription {
    id
    createdAt
    updatedAt
    contact
    type
    active
  }
`;
export const GET_QUERY = gql`
  query(
    $id: ID!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: NewsletterSubscriptionFilterInput
    $query: String
  ) {
    node(id: $id) {
      id
      ... on CompanyShop {
        subscriptions(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_NEWSLETTER_SUBSCRIPTION
          }
        }
      }
    }
  }
  ${FRAGMENT_NEWSLETTER_SUBSCRIPTION}
`;
