import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import { translate } from '../../../shared/translate';
import { Icon } from '../../../components/IconRender';
import { Formik } from 'formik';
import FormToolbar from '../../../components/FormToolbar';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import { client } from '../../../shared/apollo';
import { gql } from '@apollo/client';

export const RowEditUnitPrice = ({ item, id }) => {
  const { sku, productVariation, unitPrice, description, remark, media, bundleProduct: _bundleProduct } = item || {},
    { bundleProduct } = _bundleProduct || {},
    { name: bundleProductName } = bundleProduct || {},
    { sku: pvSku, combination = [] } = productVariation || {};
  const image = (media || {}).src;

  const [visible, setVisible] = useState(false);

  return (
    <>
      <IconButton
        size={'small'}
        color={'primary'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setVisible(true);
        }}
      >
        <Icon icon={'faPencil'} />
      </IconButton>
      <Dialog open={visible} onClose={() => setVisible(false)} maxWidth={'xs'}>
        <DialogTitle style={{ alignSelf: 'center' }}>{translate.selling_price_adjustment || '修改售價'}</DialogTitle>
        <Divider style={{ margin: '0 30px' }} />
        <Formik
          initialValues={{ unitPrice }}
          onSubmit={async (values) => {
            try {
              await client.mutate({
                mutation: gql`
                  mutation ($id: ID!, $unitPrice: Float!) {
                    orderItemUnitPriceUpdate(id: $id, unitPrice: $unitPrice) {
                      id
                      items {
                        id
                        unitPrice
                      }
                      total
                      subtotal
                    }
                  }
                `,
                variables: {
                  id,
                  unitPrice: values.unitPrice,
                },
              });
              setVisible(false);
              toast.success(translate.update_success);
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
            }
          }}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ padding: '10px 30px' }}>
                <Box p={2} style={{ overflow: 'hidden' }}>
                  <Grid container>
                    <Grid item xs={3}>
                      <img
                        src={image || require('../../../assets/gray.jpg')}
                        style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: 30 }}
                        alt={description}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography>{pvSku || sku}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <Typography>{description || bundleProductName}</Typography>
                          <VariationCombinationDisplay combination={combination} remark={remark} />
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={1.5}>
                            <InputField
                              type={'number'}
                              // eslint-disable-next-line
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position={'start'}>
                                    <Typography>$</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              required={true}
                              value={unitPrice - 0}
                              onChange={(e) => setFieldValue('unitPrice', e.target.value)}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <FormToolbar
                submitBtnType={'submit'}
                loading={isSubmitting}
                disabled={isSubmitting}
                onCancel={() => setVisible(false)}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default RowEditUnitPrice;
