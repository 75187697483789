import React from 'react';
import { Box } from '@material-ui/core';
import ReferenceFormatForm from './form';
import MerchantTaber from './MerchantTaber';

export default (props) => {
  return (
    <div>
      <MerchantTaber currentTab={1} />
      <Box>
        <ReferenceFormatForm {...props} />
      </Box>
    </div>
  );
};
