import React from 'react';
import Linkify from 'react-linkify';
import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '../IconRender';
import RatioBox from '../RatioBox';

const MessageTextOutline = withStyles((theme) => ({
  root: ({ isMe }) => ({
    whiteSpace: 'normal',
    alignSelf: 'center',
    wordBreak: 'break-all',
    borderRadius: 12,
    borderBottomRightRadius: isMe ? 0 : undefined,
    borderBottomLeftRadius: isMe ? undefined : 0,
    padding: theme.spacing(1),
    backgroundColor: isMe ? '#F6DEDD' : 'white',
  }),
}))(Box);

const MessageFileOutline = withStyles((theme) => ({
  root: ({ isMe }) => ({
    whiteSpace: 'normal',
    alignSelf: 'center',
    wordBreak: 'break-all',
    cursor: 'pointer',
    borderRadius: 12,
    borderBottomRightRadius: isMe ? 0 : undefined,
    borderBottomLeftRadius: isMe ? undefined : 0,
    padding: theme.spacing(1),
    backgroundColor: isMe ? '#F6DEDD' : 'white',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}))(Box);

const MessageOtherOutline = withStyles((theme) => ({
  root: ({ isMe }) => ({
    whiteSpace: 'normal',
    alignSelf: 'center',
    minWidth: 250,
    wordBreak: 'break-all',
    overflow: 'hidden',
    cursor: 'pointer',
    padding: theme.spacing(1),
    borderRadius: 12,
    borderBottomRightRadius: isMe ? 0 : undefined,
    borderBottomLeftRadius: isMe ? undefined : 0,
    backgroundColor: isMe ? '#F6DEDD' : 'white',
  }),
}))(Box);

export default ({ type, content, isMe }) => {
  switch (type) {
    case 'LINK':
    case 'TEXT':
      return (
        <MessageTextOutline component="div" isMe={isMe}>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decodeURI(decoratedText)}
              </a>
            )}
          >
            {content}
          </Linkify>
        </MessageTextOutline>
      );
    case 'VIDEO':
      return (
        <MessageOtherOutline
          onClick={() => {
            window.open(content, '_blank');
          }}
          isMe={isMe}
        >
          <video width={'100%'}>
            <source src={content} type="video/mp4" />
          </video>
        </MessageOtherOutline>
      );
    case 'IMAGE':
      return (
        <MessageOtherOutline
          onClick={() => {
            window.open(content, '_blank');
          }}
          isMe={isMe}
        >
          <RatioBox ratio={3 / 4} width={'100%'}>
            <img
              alt={content}
              src={content}
              style={{ objectFit: 'contain', width: '100%', height: '100%', objectPosition: 'top' }}
            />
          </RatioBox>
        </MessageOtherOutline>
      );
    case 'FILE':
      return (
        <MessageFileOutline
          component="div"
          onClick={() => {
            window.open(content, '_blank');
          }}
          style={{ cursor: 'pointer' }}
          isMe={isMe}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                <Icon icon="faFileDownload" type="pro-regular" fontSize={'72'} />
              </Grid>
              <Grid item>{content.split('/').pop()}</Grid>
            </Grid>
          </Linkify>
        </MessageFileOutline>
      );
    default:
      return null;
  }
};
