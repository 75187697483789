import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import { sumBy } from 'lodash';

const GET_QUERY = gql`
  query (
    $id: ID!
    $warehouseIds: [ID!]!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: InventoryReportDataFilterInput
    $query: String
  ) {
    node(id: $id) {
      ... on Company {
        report {
          inventory(
            warehouseIds: $warehouseIds
            cursor: $cursor
            limits: $limits
            sortBy: $sortBy
            filter: $filter
            query: $query
          ) {
            nextCursor
            totalCount
            nodes {
              sku
              stocks {
                quantity
                warehouseId
              }
            }
          }
        }
      }
    }
  }
`;

export default async function (__, { productSku } = {}) {
  try {
    const warehouseId = localStorage.getItem('stockWarehouseId');
    const companyId = localStorage.getItem('companyId');

    if (!productSku) return [];
    if (!warehouseId) return [];
    if (!companyId) return [];

    const { nodes } = await infinityFetch({
      query: GET_QUERY,
      variables: {
        id: localStorage.getItem('companyId'),
        limits: 100,
        warehouseIds: [localStorage.getItem('stockWarehouseId')],
        query: `productSkus.keyword:"${productSku}"`,
      },
      getConnection: (data) => data?.node?.report?.inventory,
    });
    return nodes?.reduce((reducer, node) => {
      return reducer + sumBy(node?.stocks, 'quantity');
    }, 0);
  } catch (e) {
    return [];
  } finally {
  }
}
