import { infinityFetch, parseConnection } from '../../index';
import { gql } from '@apollo/client';
import { FRAGMENT_LIST_APPOINTMENT } from '../../../routes/list_Appointments/AppointmentCalendar/query';
import { client } from '../../apollo';

const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on ShopOrder {
        id
        items {
          id
          serviceBundle {
            services {
              appointment {
                ...FRAGMENT_LIST_APPOINTMENT
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_LIST_APPOINTMENT}
`;

export default async function (source, { orderId }) {
  const { data } = await client.query({
    fetchPolicy: 'network-only',
    query: GET_QUERY,
    variables: {
      id: orderId,
    },
  });
  const appointments = data?.node?.items
    ?.filter((item) => item?.serviceBundle)
    ?.reduce((reducer, item) => {
      for (const checkoutService of item?.serviceBundle?.services ?? []) {
        if (checkoutService?.appointment) reducer.push(checkoutService.appointment);
      }
      return reducer;
    }, []);
  return parseConnection(appointments);
}
