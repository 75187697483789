import { gql } from '@apollo/client';

export const FRAGMENT_CREDENTIAL = gql`
  fragment FRAGMENT_CREDENTIAL on ShopCredential {
    id
    name
    platform
    description
    createdAt
    updatedAt
    active
    type
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $filterV2: CredentialFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        credentials(filterV2: $filterV2) {
          ...FRAGMENT_CREDENTIAL
        }
      }
    }
  }
  ${FRAGMENT_CREDENTIAL}
`;
