import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboWarehouse from '../../SelectCombo/SelectComboWarehouse';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, name] = str.match(/^(.*?)∆(.*)$/);
      return { id, name };
    });
  const [warehouses, setWarehouses] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboWarehouse
        multiple
        value={warehouses}
        onChange={(warehouses) => {
          setWarehouses(warehouses);
          onFilterChange({
            value: (warehouses || []).map((warehouse) => `${warehouse.id}∆${warehouse.name}`),
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
