import React, { useState } from 'react';
import { translate } from '../../../../shared/translate';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { Icon } from '../../../../components/IconRender';
import { theme } from '../../../../theme';
import confirmation from '../../../../shared/confirmation';
import toast from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { NumberFormat } from '../../../../shared';
import { client } from '../../../../shared/apollo';
import { ORDER_MEMBER_POINTS_DEDUCT } from './query';

const MemberPointsDeductButton = ({ loading, values: { id, deductibleMemberPoints } }) => {
  const [submitting, setSubmitting] = useState(false);

  if ((deductibleMemberPoints || 0) > 0)
    return (
      <Tooltip title={translate.cancel_points}>
        <IconButton
          size={'small'}
          disabled={submitting || loading}
          onClick={async () => {
            try {
              if (
                await confirmation(
                  translate.formatString(translate.cancel_points_remark, `: ${NumberFormat(deductibleMemberPoints)}`),
                )
              ) {
                setSubmitting(true);
                await client.mutate({
                  mutation: ORDER_MEMBER_POINTS_DEDUCT,
                  variables: {
                    id,
                    points: deductibleMemberPoints,
                  },
                });
                toast.success(translate.points_cancelled);
              }
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {submitting ? (
            <CircularProgress size={15} />
          ) : (
            <Icon icon={'faTimes'} style={{ color: theme.palette.error.main }} />
          )}
        </IconButton>
      </Tooltip>
    );
  return null;
};

export default MemberPointsDeductButton;
