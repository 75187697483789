import { gql } from '@apollo/client';

export const GET_REPORT = gql`
  query (
    $id: ID!
    $shopId: String!
    $likeShopId: String!
    $today: AWSDateTime!
    $last7day: AWSDateTime!
    $last30day: AWSDateTime!
    $startedThru: AWSDateTime!
    $companyId: ID!
    $reportShopId: ID!
  ) {
    companyNode: node(id: $companyId) {
      id
      ... on Company {
        report {
          topSalesProducts: topProductSales(
            sortBy: [{ field: "count", order: DESC }]
            startedAt: $last30day
            startedThru: $startedThru
            limits: 10
            shopId: $reportShopId
          ) {
            nodes {
              amount
              count
              sku
              name(shopId: $reportShopId)
              medias(shopId: $reportShopId) {
                src
              }
            }
          }
          topViewProducts: topProductSales(
            sortBy: [{ field: "pageView", order: DESC }]
            startedAt: $last30day
            startedThru: $startedThru
            limits: 10
            shopId: $reportShopId
          ) {
            nodes {
              pageView
              count
              sku
              name(shopId: $reportShopId)
              medias(shopId: $reportShopId) {
                src
              }
            }
          }
        }
      }
    }
    node(id: $id) {
      id
      ... on CompanyShop {
        type
        name
        hostname
        unreadOrderMessages: channelMessages(
          limits: 0
          filter: {
            active: { operator: IN, value: ["true"] }
            channelPlayerId: { operator: NIN, value: [$shopId] }
            readChannelPlayerIds: { operator: NLIKE, value: [$likeShopId] }
            channelType: { operator: IN, value: ["ORDER"] }
          }
        ) {
          totalCount
        }
        unreadGeneralMessages: channelMessages(
          limits: 0
          filter: {
            active: { operator: IN, value: ["true"] }
            channelPlayerId: { operator: NIN, value: [$shopId] }
            readChannelPlayerIds: { operator: NLIKE, value: [$likeShopId] }
            channelType: { operator: IN, value: ["GENERAL"] }
          }
        ) {
          totalCount
        }
        report {
          unpaidOrderAmount(startedAt: $today, startedThru: $startedThru)
          unpaidOrderCount(startedAt: $today, startedThru: $startedThru)
          pendingOrderCount(startedAt: "1970-01-01T15:59:59.999Z", startedThru: $startedThru)
          todayAmount: turnover(startedAt: $today, startedThru: $startedThru)
          todayCount: volume(startedAt: $today, startedThru: $startedThru)
          last7dayAmount: turnover(startedAt: $last7day, startedThru: $startedThru)
          last7dayCount: volume(startedAt: $last7day, startedThru: $startedThru)
          last30dayAmount: turnover(startedAt: $last30day, startedThru: $startedThru)
          last30dayCount: volume(startedAt: $last30day, startedThru: $startedThru)
        }
      }
    }
  }
`;
