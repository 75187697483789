import React from 'react';
import { Box } from '@material-ui/core';

const ProductItemPreview = ({ width = 267, height, loadingWidth = 267, loadingHeight = 140, src }) => {
  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        width: width,
        height: 135,
      }}
    >
      <img
        src={src}
        alt={'preview'}
        width={width || 'auto'}
        height={height || 'auto'}
        style={{ position: 'absolute', top: 0, zIndex: 1 }}
      />
      <Box
        width={loadingWidth}
        height={loadingHeight}
        style={{ position: 'absolute', top: 0, backgroundColor: '#cccccc' }}
      />
    </div>
  );
};

export default ProductItemPreview;
