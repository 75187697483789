import React, { useEffect, useState } from 'react';
import PopoverWrapper from '../../item_ServiceLocation/ScheduleCalendar/PopoverWrapper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { Icon } from '../../../components/IconRender';
import FormRow from '../../../components/FormRow';
import moment from 'moment';
import EllipsisTypography from '../../../components/EllipsisTypography';
import StatusBadge from '../../../components/StatusBadge';
import { AppointmentStatus, AttendanceStatus } from '../../../shared/omniwe-types';
import InputField from '../../../components/FormPageMaker/Fields/InputField';

const EventModal = ({ selectedEvent, onClose = (_) => _, onAppointmentClick = (_) => _ }) => {
  const [box, setBox] = useState({});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (selectedEvent?.anchor) {
      const rect = selectedEvent.anchor.getBoundingClientRect();
      setBox({
        x: rect.left + rect.width / 2,
        y: rect.top,
      });
    }
  }, [selectedEvent?.anchor]);

  const { serviceBundle, service, startedAt, startedThru, appointments = [] } = selectedEvent?.resource ?? {};

  return (
    <ClickAwayListener onClickAway={() => setTimeout(onClose, 0)}>
      <PopoverWrapper
        box={box}
        visible={!!selectedEvent}
        style={{
          minWidth: 350,
          maxWidth: 800,
          width: '50vw',
        }}
      >
        <Card>
          <CardContent
            style={{
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'auto',
            }}
          >
            <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={3}>
              <Grid item>
                <Typography variant={'h5'}>{translate.service_location_info}</Typography>
              </Grid>
              <Grid item>
                <IconButton size={'small'} onClick={onClose}>
                  <Icon icon={'faTimes'} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <FormRow xs={12} title={translate.items}>
                    <Typography>
                      【{serviceBundle?.name}】{service?.name}
                    </Typography>
                  </FormRow>
                  <FormRow xs={12} title={translate.datetime || '日期及時間'}>
                    <Typography>{moment(startedAt).format('DD MMM YYYY HH:mm A')}</Typography>
                  </FormRow>
                  <FormRow xs={12}>
                    <InputField
                      value={search}
                      onChange={(e) => setSearch(e?.target?.value)}
                      placeholder={translate.please_input_search}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon={'faSearch'} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormRow>
                  <FormRow xs={12}>
                    <TableContainer component={Card} variant={'outlined'} style={{ borderRadius: 10 }}>
                      <Table size={'small'}>
                        <TableHead>
                          <TableRow>
                            <TableCell>{translate.booking_reference || '預約編號'}</TableCell>
                            <TableCell>{translate.location}</TableCell>
                            <TableCell>{translate.contact_method}</TableCell>
                            <TableCell>{translate.status}</TableCell>
                          </TableRow>
                        </TableHead>
                        {appointments
                          ?.filter((ap) => {
                            if (search) {
                              return search.split(' ').every((s) => {
                                return [
                                  ap?.order?.referenceNo,
                                  ap?.referenceNo,
                                  ap?.location?.name,
                                  ap?.contactAddress?.person,
                                  ap?.contactAddress?.tel,
                                  ap?.contactAddress?.email,
                                ].some((str) => str?.toLowerCase().includes(s.toLowerCase()));
                              });
                            } else return true;
                          })
                          ?.map((ap, i) => (
                            <TableRow
                              key={i}
                              hover
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                onAppointmentClick(ap);
                              }}
                            >
                              <TableCell>
                                <EllipsisTypography>{ap?.referenceNo}</EllipsisTypography>
                                <EllipsisTypography>{ap?.order?.referenceNo}</EllipsisTypography>
                              </TableCell>
                              <TableCell>
                                <EllipsisTypography>{ap?.location?.name}</EllipsisTypography>
                              </TableCell>
                              <TableCell style={{ width: 250 }}>
                                <EllipsisTypography>
                                  {[ap?.contactAddress?.person, ap?.contactAddress?.tel, ap?.contactAddress?.email]
                                    .filter((_) => _)
                                    .map((str, i) => (
                                      <div key={i}>{str}</div>
                                    ))}
                                </EllipsisTypography>
                              </TableCell>
                              <TableCell>
                                {ap?.status === 'CONFIRMED' ? (
                                  <StatusBadge status={ap?.attendanceStatus} statusObject={AttendanceStatus} />
                                ) : (
                                  <StatusBadge status={ap?.status} statusObject={AppointmentStatus} />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </Table>
                    </TableContainer>
                  </FormRow>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </PopoverWrapper>
    </ClickAwayListener>
  );
};

export default EventModal;
