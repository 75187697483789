import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { SUBMIT_QUERY, REMOVE_QUERY, GET_QUERY } from './query';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import { convertMetaArray, convertMetaObject } from '../../shared';
import ManualModule from './CredentialExtraForm/ManualModule';
import StripeModule from './CredentialExtraForm/StripeModule';
import UNOPayModule from './CredentialExtraForm/UNOPayModule';
import UprisePaymentModule from './CredentialExtraForm/UprisePaymentModule';
import EFTModule from './CredentialExtraForm/EFTModule';
import KPayPOSModule from './CredentialExtraForm/KPayPOSModule';
import KPayModule from './CredentialExtraForm/KPayModule';
import OpenAIModule from './CredentialExtraForm/OpenAIModule';
import KConnectModule from './CredentialExtraForm/KConnectModule';
import SFModule from './CredentialExtraForm/SFModule';
import BBMSLModule from './CredentialExtraForm/BBMSLModule';
import EFTPOSModule from './CredentialExtraForm/EFTPOSModule';
import QFPayPOSModule from './CredentialExtraForm/QFPayPOSModule';
import PayPalModule from './CredentialExtraForm/PayPalModule';
import SoePayModule from './CredentialExtraForm/SoePayModule';
import QFPayModule from './CredentialExtraForm/QFPayModule';
import ShipAnyModule from './CredentialExtraForm/ShipAnyModule';
import FreightAmigoPaymentModule from './CredentialExtraForm/FreightAmigoPaymentModule';
import FreightAmigoShipmentModule from './CredentialExtraForm/FreightAmigoShipmentModule';
import OCGCModule from './CredentialExtraForm/OCGCModule';
import OctopusPOSModule from './CredentialExtraForm/OctopusPOSModule';
import KeetaModule from './CredentialExtraForm/KeetaModule';

export const modules = [
  ManualModule,
  UNOPayModule,
  UprisePaymentModule,
  StripeModule,
  OctopusPOSModule,
  EFTPOSModule,
  EFTModule,
  KPayPOSModule,
  KPayModule,
  BBMSLModule,
  QFPayPOSModule,
  QFPayModule,
  OpenAIModule,
  KConnectModule,
  SFModule,
  ShipAnyModule,
  PayPalModule,
  SoePayModule,
  FreightAmigoPaymentModule,
  FreightAmigoShipmentModule,
  OCGCModule,
  KeetaModule,
];

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.credential_info,
        cards: [
          {
            fields: [
              {
                label: translate.credential_platform,
                type: 'select',
                name: 'platform',
                required: true,
                disabled: () => !!this.state.id,
                options: ({ values }) => {
                  const options = modules.map((module) => module?.platform);
                  if (!this.state.id) return options;
                  const option = options.find((opt) => opt.value === values.platform);
                  if (!option)
                    return [
                      {
                        label: values.platform,
                        value: values.platform,
                      },
                    ];
                  return options;
                },
              },
              {
                render: (actions) => {
                  const { values } = actions ?? {};
                  const module = modules.find((module) => module?.platform?.value === values.platform);
                  const Form = module?.form;
                  if (!Form) return null;
                  return <Form {...actions} />;
                },
              },
              {
                label: translate.activate_checkbox,
                type: 'switch',
                name: 'active',
              },
              {
                label: translate.remark,
                type: 'html',
                name: 'description',
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const { id, gql: { submit } = {} } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          shopId: localStorage.getItem('shopId'),
          name: values.platform,
          identity: values.identity || values.name || values.platform || '',
          secret: values.secret || values.name || values.platform || '',
          active: !!values.active,
          // check the platform then return PAYMENT or AUTHENTICATION, otherwise default to PAYMENT
          type: modules.find((module) => module?.platform?.value === values.platform)?.type || 'PAYMENT',
          metadata: convertMetaArray(values.metadata),
          description: values.description,
          platform: values.platform,
        },
      },
    });
    return true;
  };

  getExtraFetchVariables() {
    const { id } = this.state;
    return {
      shopId: localStorage.getItem('shopId'),
      filterV2: {
        id: {
          operator: 'IN',
          value: [id],
        },
      },
    };
  }

  getInitialData({ node } = {}) {
    const {
      platform,
      description,
      createdAt,
      updatedAt,
      active = true,
      identity = '',
      secret = '',
      metadata,
    } = node?.credentials?.[0] ?? {};
    return {
      platform,
      description,
      createdAt,
      updatedAt,
      active,
      identity,
      secret,
      metadata: convertMetaObject(metadata),
    };
  }
}
