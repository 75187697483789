import { gql } from '@apollo/client';
import { FRAGMENT_LIST_APPOINTMENT } from '../AppointmentCalendar/query';

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ShopAppointmentFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        appointments(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_LIST_APPOINTMENT
          }
        }
      }
    }
  }
  ${FRAGMENT_LIST_APPOINTMENT}
`;
