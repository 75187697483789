import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import LSkeleton from './LSkeleton';

const PrintTable = ({ loading, data = [], columns = [], children }) =>
  (loading || data.length > 0) && (
    <>
      <Table style={{ width: '100%', backgroundColor: '#f9f9f9' }}>
        <TableHead style={{ backgroundColor: '#edeef0' }}>
          <TableRow>
            {columns.map(({ title, align = 'left', width }, i) => (
              <TableCell key={i} align={align} width={width}>
                <Typography style={{ fontSize: '1.125rem' }}>{title}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? Array(3)
                .fill(undefined)
                .map((__, i) => (
                  <TableRow key={i}>
                    {columns.map(({ fieldName, value, render, align }, i) => (
                      <TableCell key={i} align={align}>
                        <LSkeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            : data.map((data, i) => (
                <TableRow key={i} style={{ backgroundColor: '#fff' }}>
                  {columns.map(({ fieldName, value, render, align }, i) => {
                    let content;
                    if (render) {
                      try {
                        content = render(data);
                      } catch (e) {}
                      return (
                        <TableCell key={i} align={align}>
                          {content}
                        </TableCell>
                      );
                    }

                    try {
                      content = value ? value(data) : data[fieldName];
                    } catch (e) {
                      content = data?.[fieldName] || '';
                    }

                    return (
                      <TableCell key={i} align={align}>
                        <Typography style={{ fontSize: '1rem' }}>{content}</Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          {children}
        </TableBody>
      </Table>
    </>
  );

export default PrintTable;
