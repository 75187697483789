import { gql } from '@apollo/client';
import { FRAGMENT_MEMBER_METADATA_FIELD } from '../item_CustomerFieldsSetting/query';
import { FRAGMENT_ProductModifier } from '../item_ProductModifier/query';

export const FRAGMENT_SHOP_PRODUCT = gql`
  fragment ShopProduct on ShopProduct {
    name
    sku
    barcode
    barcodes
    description
    salesChannels
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
      alt
    }
    active
    display
    remarkSet
    hashtags
    subtitle
    publishAt
    publishThru
    primarySortIndex
    bundleProducts {
      nodes {
        id
        name
        sku
      }
    }
    collections(limits: 99999) {
      nodes {
        id
        name
        parents {
          id
          name
        }
      }
    }
    combinations {
      name
      options {
        name
        priceAdjustment
      }
    }
    modifiers {
      name
      max
      min
      options {
        name
        priceAdjustment
      }
    }
    modifierValues {
      modifierId
      modifiers {
        name
        min
        max
        options {
          name
          priceAdjustment
        }
      }
      metadataFields {
        key
        name
        type
        required
        values
        unique
        visible
        metadata {
          key
          value
        }
      }
    }
    basePrice
    variations {
      id
      sku
      barcode
      cost
      unitPrice
      suggestedRetailPrice
      ignoreStock
      averageCost
      weight
      combination {
        name
        option
      }
      medias {
        #id
        src
        optimizedSrc(width: 256, height: 256)
        alt
      }
      quantity(warehouseId: $warehouseId)
      active
      display
      stockLocations
      barcodes
      hashtags
      lowStock {
        remind
        quantity
      }
    }
    details {
      title
      content
    }
    createdAt
    updatedAt
    metaTitle
    metaDescription
    metaKeywords
    printDescription
    rewriteUri
    shippingZones {
      id
      name
    }
    metadataFields {
      ...FRAGMENT_MEMBER_METADATA_FIELD
    }
    metadata {
      key
      value
    }
    attributes {
      id
      key
      type
      value
    }
  }
  ${FRAGMENT_MEMBER_METADATA_FIELD}
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOP_PRODUCT}
  query ($id: ID!, $warehouseId: ID) {
    node(id: $id) {
      id
      ...ShopProduct
      ... on ShopProduct {
        shop {
          id
          company {
            id
          }
        }
      }
    }
  }
`;

export const POST_QUERY = gql`
  ${FRAGMENT_SHOP_PRODUCT}
  mutation productSet($id: ID, $input: ProductInput!, $allShop: Boolean, $warehouseId: ID!) {
    result: productSet(id: $id, input: $input, allShop: $allShop) {
      id
      ...ShopProduct
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation productUnset($id: ID!) {
    result: productUnset(id: $id) {
      id
    }
  }
`;
