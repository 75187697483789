import React, { useState } from 'react';
import { translate } from '../../shared/translate';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import SystemButton from '../../components/SystemButton';

const { REACT_APP_WEBHOOK_URL } = process.env;
const SFPrintButton = ({ values: { id } = {} }) => {
  const [loading, setLoading] = useState(false);
  return (
    <SystemButton
      size={'small'}
      variant={'contained'}
      color={'white'}
      disabled={loading}
      loading={loading}
      startIcon={<img alt={'sf'} src={require('../../assets/sf.png')} style={{ width: 21, height: 21 }} />}
      onClick={async () => {
        try {
          setLoading(true);
          const templateCode = 'fm_210_international';
          const res = await fetch(`${REACT_APP_WEBHOOK_URL}/print_waybill?id=${id}&templateCode=${templateCode}`, {
            method: 'GET',
            headers: {
              authorization: localStorage.getItem('token'),
            },
          });

          if (/^2/.test(res.status) || /^3/.test(res.status)) {
            const { url } = await res.json();
            window.open(url, '_blank');
          } else if (/^4/.test(res.status)) {
            throw new Error('[ERR_RETRY]'); // 未能建立外部送貨單，請再試一次
          } else if (/^5/.test(res.status)) {
            throw new Error('[_ERR_RETRY_CONTACT_US]'); // 未能建立外部送貨單。如果問題持續，請電郵 findus@omniwe.com 或 WhatsApp +852 9611 6631 與我們聯絡
          }
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.sf_print}
    </SystemButton>
  );
};
export default SFPrintButton;
