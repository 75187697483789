import React, { useState } from 'react';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import { translate } from '../../../shared/translate';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Icon } from '../../../components/IconRender';

const EmailField = ({ onChange, helperText, ...props }) => {
  const [text, setText] = useState('');
  const [warning, setWarning] = useState(false);

  const submit = () => {
    if (/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/.test(text)) {
      setWarning(false);
      onChange(text);
      setText('');
    } else {
      setWarning(true);
    }
  };

  return (
    <InputField
      type={'email'}
      value={text}
      onChange={(e) => setText(e.target.value)}
      error={warning}
      helperText={
        <>
          {warning && (
            <>
              {translate.please_enter_valid_email_format}
              <br />
            </>
          )}
          {helperText
            ? helperText.split('\n').map((str) => (
                <>
                  {str}
                  <br />
                </>
              ))
            : undefined}
        </>
      }
      onBlur={() => {
        if (!text) setWarning(false);
        else submit();
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
          submit();
        }
      }}
      // eslint-disable-next-line
      inputProps={{
        autocomplete: 'off',
      }}
      // eslint-disable-next-line
      InputProps={{
        endAdornment: !!text && (
          <InputAdornment position={'end'} disableTypography>
            <IconButton size={'small'} color={'primary'} type={'button'} onClick={submit}>
              <Icon icon={'faPlus'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default EmailField;
