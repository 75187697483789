import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $limits: Int) {
    node(id: $id) {
      id
      ... on ShopCollection {
        name
        products(limits: $limits, filter: { active: { operator: IN, value: ["true"] } }) {
          totalCount
          nextCursor
          nodes {
            id
            name
            subtitle
            medias {
              #id
              src
              optimizedSrc(width: 256, height: 256)
            }
            basePrice
          }
        }
      }
    }
  }
`;
