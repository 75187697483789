import React, { useState, useEffect } from 'react';
import { matchesUA } from 'browserslist-useragent';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import errorParser from '../shared/errorParser';

import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    padding: theme.spacing(6),
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
  },
}));

const UserAgentChecker = () => {
  const classes = useStyles();
  const [supported, setSupported] = useState(true);
  const [warning, setWarning] = useState(false);
  const browsers = [
    '>= 5%',
    'last 1 major version',
    'not dead',
    'unreleased versions',
    'not ie <= 11',
    'not op_mini all',
    'Chrome >= 45',
    'Firefox >= 38',
    'Edge >= 12',
    'Explorer >= 10',
    'iOS >= 9',
    'Safari >= 100',
    'Android >= 4.4',
    'Opera >= 30',
  ];
  useEffect(() => {
    setSupported(
      matchesUA(navigator.userAgent, {
        ignoreMinor: true,
        ignorePatch: true,
        allowHigherVersions: true,
        browsers,
      }),
    );
  }, []);

  const onClose = () => {
    setWarning(false);
  };

  useEffect(() => {
    setWarning(!supported);
  }, [supported]);

  return (
    <>
      <Dialog onClose={onClose} open={warning}>
        <IconButton size={'small'} onClick={onClose} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className={classes.content}>
          <Typography style={{ color: 'red' }}>{errorParser(new Error('Browser Not Supported'))}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserAgentChecker;
