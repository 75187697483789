import React from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { GET_QUERY, CREATE_QUERY, UPDATE_QUERY } from '../query';
import { Card } from '@material-ui/core';
import { JSONParseSafely, parseConnection } from '../../../shared';
import { Formik } from 'formik';
import ItemsSortingCard from './ItemsSortingCard';
import FormToolbar from '../../../components/FormToolbar';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { translate } from '../../../shared/translate';
import ActivityIndicator from '../../../components/ActivityIndicator';

const NavigationTemplate = (props) => {
  const client = useApolloClient();
  const { loading, data } = useQuery(GET_QUERY, {
      fetchPolicy: 'network-only',
      variables: {
        id: localStorage.getItem('shopId'),
        type: 'HEADER',
      },
    }),
    template = parseConnection(data?.node?.navigationTemplates).nodes?.[0],
    configs = JSONParseSafely(template?.configs, {});

  return (
    <Card square>
      <Formik
        key={loading}
        initialValues={{
          configs,
        }}
        onSubmit={async (values) => {
          try {
            ActivityIndicator.show();
            if (template?.id) {
              await client.mutate({
                mutation: UPDATE_QUERY,
                variables: {
                  id: template.id,
                  input: {
                    configs: JSON.stringify(values.configs),
                  },
                },
              });
            } else {
              await client.mutate({
                mutation: CREATE_QUERY,
                variables: {
                  input: {
                    configs: JSON.stringify(values.configs),
                    name: 'HEADER',
                    type: 'HEADER',
                    shopId: localStorage.getItem('shopId'),
                  },
                },
              });
            }
            toast.success(translate.update_success_msg);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            ActivityIndicator.hide();
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          const disabled = isSubmitting || loading;
          return (
            <>
              <form onSubmit={handleSubmit}>
                <ItemsSortingCard
                  loading={loading}
                  disabled={disabled}
                  items={values?.configs?.items ?? []}
                  onChange={(items) => {
                    setFieldValue('configs', {
                      ...values?.configs,
                      items,
                    });
                  }}
                />
                <FormToolbar
                  loading={isSubmitting}
                  disabled={disabled}
                  updatedAt={template?.updatedAt}
                  submitBtnType={'submit'}
                />
              </form>
            </>
          );
        }}
      </Formik>
    </Card>
  );
};

export default NavigationTemplate;
