import React, { useEffect, useState } from 'react';
import { Dialog, Grid, Typography } from '@material-ui/core';
import SuggestionInputField from './SuggestionInputField';
import { translate } from '../../../shared/translate';
import SystemButton from '../../../components/SystemButton';

const BlobField = ({ placeholder, suggestions, value, onChange, disabled, style }) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(value);

  useEffect(() => {
    if (value !== input) setInput(value);
  }, [value]);

  return (
    <>
      <SuggestionInputField
        placeholder={placeholder}
        onClick={() => (disabled ? undefined : setOpen(true))}
        options={suggestions}
        disabled={disabled}
        value={value}
        style={{ ...style }}
      />
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            padding: 16,
          },
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          setInput(value);
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <Typography variant={'h6'}>{translate.text}</Typography>
        <SuggestionInputField
          placeholder={placeholder}
          options={suggestions}
          style={{
            border: '1px solid #ddd',
            borderRadius: 10,
            height: 200,
            resize: 'initial',
            marginBottom: 8,
          }}
          disabled={disabled}
          value={input}
          onChange={setInput}
        />
        <Grid container justifyContent={'flex-end'} spacing={1}>
          <Grid item>
            <SystemButton
              variant={'outlined'}
              onClick={() => {
                setOpen(false);
                setInput(value);
              }}
            >
              {translate.cancel}
            </SystemButton>
          </Grid>
          <Grid item>
            <SystemButton
              color={'secondary'}
              onClick={() => {
                setOpen(false);
                onChange(input);
              }}
            >
              {translate.confirm}
            </SystemButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default BlobField;
