import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import HashTagsArrayField from '../../../components/FormPageMaker/Fields/HashTagsArrayField';
import { translate } from '../../../shared/translate';
import TrashButton from '../../../components/TrashButton';

const HashtagsItem = ({ value, onChange }) => {
  const { validAt, validThru, hashtags } = value || {};
  return (
    <Grid container>
      <Grid item xs={true}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">{translate.valid_start_date_time}</Typography>
              </Grid>
              <Grid item xs={12}>
                <DateTimeField
                  type={'datetime-local'}
                  value={validAt}
                  onChange={(v) => {
                    onChange({ ...value, validAt: v });
                  }}
                  inputProps={{
                    max: moment(validThru).subtract(1, 'minutes').format('YYYY-MM-DDTHH:mm'),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">{translate.valid_end_date_time}</Typography>
              </Grid>
              <Grid item xs={12}>
                <DateTimeField
                  type={'datetime-local'}
                  value={validThru}
                  onChange={(v) => {
                    onChange({ ...value, validThru: v });
                  }}
                  inputProps={{
                    min: moment(validAt).add(1, 'minutes').format('YYYY-MM-DDTHH:mm'),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">{translate.hash_tag}</Typography>
          </Grid>
          <Grid item xs={12}>
            <HashTagsArrayField
              value={hashtags}
              onChange={(v) => {
                onChange({ ...value, hashtags: v });
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={'auto'}>
        <Box mt={2.5}>
          <TrashButton onClick={() => onChange()} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default HashtagsItem;
