import React from 'react';
import { translate } from 'shared/translate';
import { InputAdornment, Typography, makeStyles, Grid } from '@material-ui/core';
import FormRow from '../../FormRow';
import SelectField from '../../FormPageMaker/Fields/SelectField';
import InputField from '../../FormPageMaker/Fields/InputField';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
  },
  span: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  operatorSelect: {
    maxWidth: 100,
    height: 30,
    borderRight: `1px solid #c4c4c4`,
    width: 75,
    textAlign: 'center',
  },
}));

export default ({ title, filter = {}, onFilterChange = (_) => _ }) => {
  const classes = useStyles();
  const { operator, value: _value } = filter || {},
    [v1, v2] = _value || [];

  return (
    <FormRow title={title}>
      <Grid container spacing={1}>
        <Grid item xs>
          <InputField
            // eslint-disable-next-line
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>
                  <SelectField
                    className={classes.operatorSelect}
                    value={operator}
                    variant={'standard'}
                    size={'medium'}
                    // eslint-disable-next-line
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        height: '100%',
                      },
                      placeholder: translate.please_select,
                    }}
                    onChange={(e) => {
                      filter.operator = e.target.value;
                      if (filter.operator !== 'BETWEEN') filter.value = [(filter.value || [])[0]].filter((_) => _);
                      onFilterChange({
                        ...filter,
                      });
                    }}
                    options={[
                      { label: translate.eq, value: 'IN' },
                      { label: translate.range, value: 'BETWEEN' },
                    ]}
                  />
                </InputAdornment>
              ),
            }}
            type={'number'}
            value={v1}
            onChange={(e) => {
              onFilterChange({
                ...filter,
                value: [e.target.value, v2].filter((_) => _),
              });
            }}
          />
        </Grid>
        {operator === 'BETWEEN' && (
          <Grid item>
            <Typography variant={'overline'} className={classes.span}>
              {translate.to}
            </Typography>
          </Grid>
        )}
        {operator === 'BETWEEN' && (
          <Grid item xs={true}>
            <InputField
              type={'number'}
              value={v2}
              onChange={(e) => {
                onFilterChange({
                  ...filter,
                  value: [v1, e.target.value],
                });
              }}
            />
          </Grid>
        )}
      </Grid>
    </FormRow>
  );
};
