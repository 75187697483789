import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import OverlayLoading from './OverlayLoading';
import { Icon } from './IconRender';

const DefaultButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: '#7F858D',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#7F858D',
      '@media (hover: none)': {
        backgroundColor: '#7F858D',
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: '#7F858D',
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    backgroundColor: `rgba(229, 229, 229, 0.67)`,
  },
}))(Button);

const WarningButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    // backgroundColor: theme.palette.warning.main,
    backgroundColor: '#FF4731',
    color: theme.palette.warning.contrastText,
    '&:hover': {
      boxShadow: 'none',
      // backgroundColor: theme.palette.warning.dark,
      backgroundColor: '#DF3D2A',
      '@media (hover: none)': {
        backgroundColor: '#FF4731',
        boxShadow: 'none',
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    backgroundColor: `#222222aa`,
  },
}))(Button);

const ErrorButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    backgroundColor: `#222222aa`,
  },
}))(Button);

const InfoButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      '@media (hover: none)': {
        backgroundColor: theme.palette.info.main,
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    backgroundColor: `#222222aa`,
  },
}))(Button);

const SuccessButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    // backgroundColor: theme.palette.success.main,
    backgroundColor: '#4BB04F',
    color: theme.palette.success.contrastText,
    '&:hover': {
      // backgroundColor: theme.palette.success.dark,
      backgroundColor: '#41A445',
      boxShadow: 'none',
      '@media (hover: none)': {
        // backgroundColor: theme.palette.success.main,
        backgroundColor: '#4BB04F',
        boxShadow: 'none',
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    backgroundColor: `#222222aa`,
  },
}))(Button);

export const OmniewRedButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    // backgroundColor: theme.palette.success.main,
    backgroundColor: '#cc433c',
    color: theme.palette.success.contrastText,
    '&:hover': {
      // backgroundColor: theme.palette.success.dark,
      backgroundColor: '#c12c24',
      boxShadow: 'none',
      '@media (hover: none)': {
        // backgroundColor: theme.palette.success.main,
        backgroundColor: '#cc433c',
        boxShadow: 'none',
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  disabled: {
    backgroundColor: `#222222aa`,
  },
}))(Button);

const OmniweDeleteButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#F9F8FD',
      boxShadow: 'none',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
}))(Button);

const SystemButton = ({
  disabled,
  color,
  onClick,
  loading,
  children,
  size = 'medium',
  variant = 'contained',
  startIconSvg,
  ...props
}) => {
  const ButtonModule =
    {
      warning: WarningButton,
      error: ErrorButton,
      info: InfoButton,
      success: SuccessButton,
      default: DefaultButton,
      omniewRed: OmniewRedButton,
      secondary: OmniewRedButton,
      omniweDelete: OmniweDeleteButton,
    }[color] || Button;

  return (
    <ButtonModule
      variant={variant}
      disabled={disabled}
      color={color}
      onClick={onClick}
      size={size}
      startIcon={!!startIconSvg && <Icon viewBox="0 0 21 23" icon={startIconSvg} type={'svg'} />}
      {...props}
    >
      {children}
      {loading && <OverlayLoading />}
    </ButtonModule>
  );
};

SystemButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'warning', 'error', 'default']),
};
export default SystemButton;
