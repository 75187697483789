import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { Link } from 'react-router-dom';
import { GET_QUERY } from './query';
import { NumberFormat, parseConnection } from 'shared';
import ActiveSwitcher from './ActiveSwitcher';
import MenuDelete from './MenuDelete';
import MenuExport from './MenuExport';
import MenuActivate from './MenuActivate';
import MenuDeactivate from './MenuDeactivate';
import EllipsisTypography from '../../../components/EllipsisTypography';
import MenuImport from './MenuImport';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/product_collections/new',
    breadcrumb: [
      {
        name: translate.product_collections,
      },
    ],
    getRowLink: ({ name, id }) => ({
      pathname: '/product_collections/' + id,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.image,
        width: 50,
        fieldName: 'media',
        type: 'media',
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
        filter: 'name',
        filterType: 'text',
        filterChannel: 'q',
      },
      {
        title: translate.category_handle,
        fieldName: 'code',
        sortBy: 'code.keyword',
        filter: 'code',
        filterType: 'text',
        filterChannel: 'q',
      },
      {
        title: translate.hierarchy_level,
        render: ({ parents, name: _name }) => {
          if (!parents) return <EllipsisTypography>{_name}</EllipsisTypography>;

          const collections = parents.reduce((reducer, collection) => {
            const { parentId } = collection;
            const index = reducer.findIndex((col) => col.id === parentId);
            reducer.splice(index + 1, 0, collection);
            return reducer;
          }, []);

          const { name, id } = collections[collections.length - 1];
          const path = collections
            .map(({ name }) => name)
            .filter((_) => _)
            .join(' > ');

          return (
            <Link to={{ pathname: `/product_collections/${id}`, state: { title: name } }}>
              <EllipsisTypography>{path}</EllipsisTypography>
            </Link>
          );
        },
      },
      {
        title: translate.sort_index,
        width: 130,
        align: 'center',
        fieldName: 'sortIndex',
        type: 'number',
        filter: 'sortIndex',
        filterChannel: 'q',
        sortBy: 'sortIndex',
      },
      {
        title: translate.product_count,
        width: 130,
        align: 'center',
        render: ({ products, descendentProducts, bundleProducts }) => {
          return (
            <EllipsisTypography>{`${NumberFormat(
              parseConnection(products).totalCount + parseConnection(bundleProducts).totalCount,
            )} (${NumberFormat(
              parseConnection(descendentProducts).totalCount + parseConnection(bundleProducts).totalCount,
            )})`}</EllipsisTypography>
          );
        },
      },
      {
        title: translate.visibility,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} data={data} onCompleted={this.refetch} />;
        },
        sortBy: 'active',
        noLink: true,
      },
    ],
    menus: [MenuDelete, MenuImport, MenuExport, MenuActivate, MenuDeactivate],
    selectionMode: true,
    hasQSearch: true,
    qFields: ['name'],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  getData = ({ node } = {}) => {
    const { id, collections } = node || {},
      { nodes, totalCount, nextCursor } = parseConnection(collections);
    return { nodes: nodes.map((collection) => ({ ...collection, shopId: id })), totalCount, nextCursor };
  };

  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
