import React from 'react';
import { Divider, Card, Badge, Typography, CardHeader, CardContent, useTheme, Box } from '@material-ui/core';
import { ResponsiveContainer, Pie, PieChart, Tooltip, Legend, Cell } from 'recharts';
import { NumberFormat } from '../../shared';
import _ from 'lodash';
import { NoData } from '../../components/CursorBaseList';
import Skeleton from '@material-ui/lab/Skeleton';

const colors = ['#FF6384', '#36A2EB', '#FFCE56'];

export default ({ loading, title = 'title', data = [] }) => {
  const theme = useTheme();
  const total = _.sumBy(data, 'value');
  return (
    <Card>
      <CardHeader
        avatar={<Badge color="secondary" variant="dot" />}
        title={
          <Typography variant={'h6'} color={'secondary'}>
            {title}
          </Typography>
        }
        disableTypography={true}
      />
      <Divider />
      <CardContent>
        <Box height={250}>
          {!!loading && <Skeleton variant="rect" width={'100%'} height={'100%'} />}
          {!loading && data.length === 0 && (
            <Box height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <NoData />
            </Box>
          )}
          {!loading && data.length > 0 && (
            <ResponsiveContainer width={'100%'} height={'100%'}>
              <PieChart>
                <Tooltip formatter={(value) => `${value} (${NumberFormat(100 * (value / total), 0)}%)`} />
                <Legend
                  iconType={'circle'}
                  iconSize={10}
                  verticalAlign={'top'}
                  align={'center'}
                  layout={'horizontal'}
                  wrapperStyle={{ color: theme.palette.grey['500'] }}
                />
                <Pie
                  label={({ value, percent }) => `${value} (${NumberFormat(percent * 100, 0)}%)`}
                  data={data}
                  cx={'50%'}
                  cy={'50%'}
                  nameKey={'name'}
                  dataKey={'value'}
                  innerRadius={60}
                  outerRadius={80}
                >
                  {data.map((__, i) => (
                    <Cell key={i} fill={colors[i % colors.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
