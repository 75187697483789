import React from 'react';
import FormPageMaker from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { CREATE_QUERY, GET_QUERY, REMOVE_QUERY, UPDATE_QUERY } from './query';
import { cloneObject, removeTypename } from '../../shared';
import RemarkCard from './RemarkCard';
import ProductsListView from './ProductsListView';
import CustomFormField from '../../components/CustomFormField';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.product_modifiers,
        cards: [
          {
            fields: [
              {
                label: translate.product_modifier_no,
                type: 'text',
                name: 'code',
                maxLength: 80,
                required: true,
                md: 6,
              },
              {
                label: translate.name,
                type: 'text',
                name: 'name',
                md: 6,
                required: true,
              },
              {
                label: translate.product_modifier_display_order,
                type: 'number',
                name: 'sortIndex',
                md: 6,
              },
              {
                label: translate.product_count,
                type: 'text',
                name: 'productCount',
                disabled: true,
                display: !!this.state.id,
                md: 6,
              },
              {
                label: translate.customize_table,
                render: (actions) => <RemarkCard {...actions} />,
              },
              {
                label: translate.product_remarks,
                render: ({ disabled, values, setFieldValue }) => {
                  return (
                    <CustomFormField
                      onlyAllowFieldTypes={['TEXT']}
                      useFieldKey={false}
                      disabled={disabled}
                      value={values?.metadataFields}
                      onChange={(v) => {
                        setFieldValue('metadataFields', v);
                      }}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      !!this.state.id && {
        name: translate.product,
        noPadding: true,
        cards: [ProductsListView],
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;
    const shopId = localStorage.getItem('shopId');

    const input = {
      name: values.name,
      code: values.code,
      sortIndex: values.sortIndex,
      modifiers:
        values.modifiers?.map((modifier) => ({
          name: modifier?.name || '',
          max: modifier?.max || undefined,
          min: modifier?.min || undefined,
          options:
            modifier?.options.map((opt) => ({
              name: opt?.name || '',
              priceAdjustment: opt?.priceAdjustment || 0,
            })) ?? [],
        })) ?? [],
      metadataFields: values.metadataFields?.map((field) => ({ ...field, unique: field?.key === values.unique })),
    };

    if (!id) {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            ...input,
            shopId,
          },
        },
      });
    } else {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input,
        },
      });
    }

    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {},
      { id, name = '', active = true, productCount, sortIndex, code, modifiers, metadataFields } = node || {};

    return {
      id,
      active,
      name,
      code,
      sortIndex,
      productCount: productCount ?? 0,
      modifiers: removeTypename(cloneObject(modifiers || [])),
      metadataFields: removeTypename(cloneObject(metadataFields || [])),
    };
  };
}
