import React from 'react';
import GateSettingArrayField from './GateSettingArrayField';

const GateSettingCard = (props) => {
  const { values, setFieldValue, disabled } = props;

  return (
    <>
      <GateSettingArrayField
        disabled={disabled}
        value={values.gateSetting}
        onChange={(v) => {
          setFieldValue('gateSetting', v);
        }}
      />
    </>
  );
};

export default GateSettingCard;
