import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Icon } from '../IconRender';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { theme } from '../../theme';

const PrintLabelRow = ({ index, deliveryNote, onChange, setShowContinue }) => {
  const { REACT_APP_WEBHOOK_URL } = process.env;
  const { id, referenceNo } = deliveryNote || {};
  const [hasUrl, setHasUrl] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    (async () => {
      try {
        const templateCode = 'fm_210_international';
        const res = await fetch(`${REACT_APP_WEBHOOK_URL}/print_waybill?id=${id}&templateCode=${templateCode}`, {
          method: 'GET',
          headers: {
            authorization: localStorage.getItem('token'),
          },
        });

        if (/^2/.test(res.status) || /^3/.test(res.status)) {
          const { url } = await res.json();
          setHasUrl(!!url);
          onChange(url);
        } else if (/^4/.test(res.status)) {
          setHasUrl(false);
          setShowContinue(true);
          onChange('');
          const { message } = await res.json();
          throw new Error(message);
        } else if (/^5/.test(res.status)) {
          setHasUrl(false);
          setShowContinue(true);
          onChange('');
          const { message } = await res.json();
          throw new Error(message);
        }
      } catch (e) {
        setErrorMessage(errorParser(e));
        toast.error(errorParser('[ERR_INFO_INVALID]' || '部分收件人資訊有誤，請檢查後重試'));
      }
    })();
  }, []);

  return (
    <Grid item xs={12} key={index}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">{referenceNo}</Typography>
        </Grid>
        <Grid item>
          {hasUrl === undefined && <CircularProgress size={20} />}
          {hasUrl && <Icon icon={'faCheck'} fontSize={'small'} style={{ color: theme.palette.primary.main }} />}
          {hasUrl === false && <Icon icon={'faTimes'} fontSize={'small'} style={{ color: theme.palette.error.main }} />}
        </Grid>
      </Grid>
      {!!errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
    </Grid>
  );
};

export default PrintLabelRow;
