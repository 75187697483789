import React from 'react';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ImageField from '../../../../../../components/FormPageMaker/Fields/ImageField';
import { Icon } from '../../../../../../components/IconRender';
import { translate } from '../../../../../../shared/translate';

const BannerImageField = ({ value, onChange = (_) => _, height = 200, onRemove }) => (
  <Box height={height} border={1} position={'relative'}>
    <Box position={'absolute'} top={0} left={0} right={0} bottom={0}>
      <ImageField
        actionStyle={{
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: 0,
          backgroundColor: '#000000',
        }}
        mediaStyle={{
          width: '100%',
          height: '100%',
        }}
        wrapperStyle={{
          width: '100%',
          height: '100%',
        }}
        style={{
          width: '100%',
          height: '100%',
          a: '縮放選項',
        }}
        value={value}
        onChange={(v) => {
          onChange(v);
        }}
        hideRemoveButton={true}
      >
        <Typography variant={'h6'}>{translate.banner_images}:</Typography>
        <TableContainer component={Card} variant={'outlined'}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>{translate.aspect_ratio}</TableCell>
                <TableCell>
                  {translate.recommended_size} ({translate.pixels})
                </TableCell>
                <TableCell>{translate.resize_option}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>16:09</TableCell>
                <TableCell>1600x900</TableCell>
                <TableCell>{translate.maintain_ratio_while_resizing}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4:03</TableCell>
                <TableCell>1600x1200</TableCell>
                <TableCell>{translate.maintain_ratio_while_resizing}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>21:09</TableCell>
                <TableCell>2100x900</TableCell>
                <TableCell>{translate.maintain_ratio_while_resizing}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography paragraph={true} variant={'caption'}>
          {translate.banner_remark}
        </Typography>
      </ImageField>
    </Box>
    {!!onRemove && (
      <IconButton
        style={{
          position: 'absolute',
          right: 5,
          top: 5,
        }}
        color={'secondary'}
        type={'button'}
        onClick={onRemove}
      >
        <Icon icon={'faTimes'} />
      </IconButton>
    )}
  </Box>
);

export default BannerImageField;
