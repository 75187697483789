import React, { useEffect, useRef, useState } from 'react';
import LSkeleton from '../../LSkeleton';
import { useDebounce } from 'react-use';
import { Icon } from '../../IconRender';
import { IconButton } from '@material-ui/core';

const MiniColorField = ({ icon = 'faPalette', iconType, loading, value, onChange = (_) => _, disabled, children }) => {
  const [selectedColor, setSelectedColor] = useState(undefined);
  const ref = useRef();

  useDebounce(
    () => {
      if (selectedColor !== value) onChange(selectedColor);
    },
    500,
    [selectedColor],
  );
  useEffect(() => {
    if (selectedColor !== value) setSelectedColor(value);
  }, [value]);

  if (loading) return <LSkeleton height={25} width={25} />;
  return (
    <>
      <IconButton
        size={'small'}
        disabled={disabled}
        onClick={() => {
          if (ref.current?.click) ref.current.click();
        }}
        style={{ position: 'relative' }}
      >
        <Icon icon={icon} type={iconType} style={{ fontSize: '1rem' }} />
        {!!selectedColor && (
          <div
            style={{
              backgroundColor: selectedColor,
              position: 'absolute',
              width: 8,
              height: 8,
              border: '1px solid #888',
              borderRadius: 99,
              right: 0,
              top: 0,
            }}
          />
        )}
      </IconButton>
      <input
        ref={ref}
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          width: '25px',
          opacity: 0,
          position: 'absolute',
          pointerEvents: 'none',
        }}
        disabled={disabled}
        type={'color'}
        value={selectedColor}
        onChange={(e) => setSelectedColor(e.target.value)}
      />
    </>
  );
};

export default MiniColorField;
