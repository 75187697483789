import React, { useEffect } from 'react';
import { Box, ButtonGroup, Typography } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import { translate } from '../../shared/translate';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SystemButton from '../../components/SystemButton';

export const SpecialPageHref = [
  { label: translate.home_page, value: 'index' },
  { label: translate.about_us, value: 'aboutus' },
  { label: translate.others, value: '' },
];

const HrefField = ({
  useCustomHref,
  onUseCustomHrefChange,
  value,
  required,
  disabled,
  onChange,
  color,
  orientation = undefined,
}) => {
  useEffect(() => {
    if (!value) onUseCustomHrefChange(true);
  }, []);

  return (
    <>
      <ButtonGroup
        orientation={orientation}
        size={'small'}
        variant={'contained'}
        style={{
          borderRadius: 0,
          borderTopLeftRadius: 9,
          borderBottomLeftRadius: 9,
          borderTopRightRadius: 9,
          borderBottomRightRadius: 9,
          boxShadow: 'none',
        }}
      >
        {SpecialPageHref.map(({ label, value: _value }, i) => {
          const selected = (!useCustomHref && value === _value) || (_value === '' && useCustomHref);
          return (
            <>
              <SystemButton
                onClick={() => {
                  if (_value === '') {
                    // "Other" button clicked
                    onChange('');
                    onUseCustomHrefChange(true);
                  } else {
                    // other button clicked
                    onChange(_value);
                    onUseCustomHrefChange(false);
                  }
                }}
                style={
                  orientation
                    ? {
                        borderRadius: 0,
                        borderTopLeftRadius: !i ? 9 : 0,
                        borderBottomLeftRadius: i === SpecialPageHref?.length - 1 ? 9 : 0,
                        borderTopRightRadius: !i ? 9 : 0,
                        borderBottomRightRadius: i === SpecialPageHref?.length - 1 ? 9 : 0,
                        backgroundColor: !selected ? '#eee' : 'white',
                        color: !selected && '#bbb',
                        paddingLeft: 18,
                        paddingRight: 18,
                      }
                    : {
                        borderRadius: 0,
                        borderTopLeftRadius: !i ? 9 : 0,
                        borderBottomLeftRadius: !i ? 9 : 0,
                        borderTopRightRadius: i === SpecialPageHref?.length - 1 ? 9 : 0,
                        borderBottomRightRadius: i === SpecialPageHref?.length - 1 ? 9 : 0,
                        backgroundColor: !selected && '#eee',
                        color: !selected && '#bbb',
                        margin: selected ? '0' : '0 -1px',
                        paddingLeft: 18,
                        paddingRight: 18,
                      }
                }
                variant="outlined"
                disabled={disabled}
                key={i}
              >
                {label}
              </SystemButton>
              {/* <Button
                variant="contained"
                // style={{
                //   backgroundColor: !selected && '#eee',
                //   color: !selected && '#bbb',
                // }}
                key={i}
                disabled={disabled}
                color={(!useCustomHref && value === _value) || (_value === '' && useCustomHref) ? 'primary' : color}
                onClick={() => {
                  if (_value === '') {
                    // "Other" button clicked
                    onChange('');
                    onUseCustomHrefChange(true);
                  } else {
                    // other button clicked
                    onChange(_value);
                    onUseCustomHrefChange(false);
                  }
                }}
              >
                {label}
              </Button> */}
            </>
          );
        })}
      </ButtonGroup>
      {/* if the other button clicked this will display */}
      {useCustomHref && (
        <Box mt={1}>
          <InputField
            disabled={disabled}
            required={required}
            placeholder={'aboutUs, promotions, series.html... etc.'}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          <Typography variant="body2">{translate.no_special_symbols_allowed || '不能輸入特殊符號'}</Typography>
        </Box>
      )}
    </>
  );
};

export default HrefField;
