import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/octopus.png')} alt="Octopus POS" />,
    label: `${translate.octopus + translate.offline}`,
    value: 'OCTOPUS_POS',
  },
  form: (actions) => {
    return null;
  },
  type: 'PAYMENT',
};
