import _ from 'lodash';
import { cloneObject, removeTypename } from '../../shared';

/* calculate the combinations from variations reversely, for the case of incomplete import */
export function combinationsFix(_combinations, _variations) {
  const combinations = _combinations || [],
    variations = _variations || [];

  if (combinations.length > 0) return removeTypename(cloneObject(combinations));

  if (variations.length > 1) {
    const possibleCombinations = {};
    for (const { combination } of variations) {
      for (const { name, option } of combination || []) {
        possibleCombinations[name] = _.uniq([...(possibleCombinations[name] || []), option]);
      }
    }

    // eslint-disable-next-line
    fix_combination_with_sku: {
      if (Object.keys(possibleCombinations).length === 0) {
        for (const variation of variations) {
          const { sku } = variation;
          possibleCombinations['sku'] = _.uniq([...(possibleCombinations['sku'] || []), sku]);
          variation.combination = [
            {
              name: 'sku',
              option: sku,
            },
          ];
        }
      }
    }

    return Object.keys(possibleCombinations).map((name) => ({
      name,
      options: (possibleCombinations[name] || []).map((name) => ({
        name,
        priceAdjustment: 0,
      })),
    }));
  } else return [];
}
