import React, { useEffect, useMemo, useState } from 'react';
import { translate } from '../../../../../shared/translate';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import MaskedInput from 'react-text-mask';
import HighlightFormRow from '../../HighlightFormRow';
import { FormControl, Input } from '@material-ui/core';

export default ({ value, onChange = (_) => _ }) => {
  const { numberOfRows = 2, numberOfColumns = 2, spacing = 0, rowRatios = [], columnRatios = [] } = value || {};
  const update = (k, v) => {
    const obj = {
      ...value,
      [k]: v,
    };
    if (v === undefined) delete obj[k];
    onChange(obj);
  };
  return (
    <>
      <HighlightFormRow highlight={value.numberOfRows !== undefined} title={translate.row}>
        <InputField
          type={'number'}
          value={numberOfRows}
          onBlur={(e) => update('numberOfRows', +e.target.value)}
          onChange={(e) => update('numberOfRows', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
            min: 1,
          }}
        />
      </HighlightFormRow>
      <HighlightFormRow highlight={value.rowRatios !== undefined} title={`${translate.row} ${translate.ratio}`}>
        <RatioInputField
          count={numberOfRows}
          value={rowRatios}
          onChange={(v) => {
            update('rowRatios', v);
          }}
        />
      </HighlightFormRow>
      <HighlightFormRow highlight={value.numberOfColumns !== undefined} title={translate.column}>
        <InputField
          type={'number'}
          value={numberOfColumns}
          onBlur={(e) => update('numberOfColumns', +e.target.value)}
          onChange={(e) => update('numberOfColumns', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
            min: 1,
          }}
        />
      </HighlightFormRow>
      <HighlightFormRow highlight={value.columnRatios !== undefined} title={`${translate.column} ${translate.ratio}`}>
        <RatioInputField
          count={numberOfColumns}
          value={columnRatios}
          onChange={(v) => {
            update('columnRatios', v);
          }}
        />
      </HighlightFormRow>
      <HighlightFormRow highlight={value.spacing !== undefined} title={translate.column_separator}>
        <InputField
          type={'number'}
          value={spacing}
          onBlur={(e) => update('spacing', +e.target.value)}
          onChange={(e) => update('spacing', e.target.value)}
          // eslint-disable-next-line
          inputProps={{
            step: 1,
            min: 0,
          }}
        />
      </HighlightFormRow>
    </>
  );
};

function RatioInputField({ count = 2, value = [], onChange = (_) => _ }) {
  const _input = value.join('：');
  const [input, setInput] = useState(_input);

  const inputComponent = useMemo(() => {
    return TextMaskCustom(count);
  }, [count]);

  useEffect(() => {
    if (_input !== input) {
      const split = input.split('：');
      onChange(
        Array(count)
          .fill(undefined)
          .map((__, i) => {
            return split[i] || 1;
          }),
      );
    }
  }, [input]);
  useEffect(() => {
    if (_input !== input) setInput(_input);
  }, [_input]);

  return (
    <FormControl variant={'outlined'} hiddenLabel fullWidth>
      <Input
        value={input}
        onBlur={(e) => setInput(e.target.value)}
        onChange={(e) => setInput(e.target.value)}
        // eslint-disable-next-line
        inputComponent={inputComponent}
      />
    </FormControl>
  );
}

function TextMaskCustom(count = 2) {
  const mask = Array(count)
    .fill(undefined)
    .reduce((reducer, __, i) => {
      if (i === 0) return reducer.concat([/[1-9aA]/]);
      return reducer.concat(['：', /[1-9aA]/]);
    }, []);

  return function (props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={mask}
        placeholderChar={'1'}
        showMask
      />
    );
  };
}
