import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { GET_QUERY, REFERENCE_NO_UPDATE_QUERY, REFERENCE_NO_CREATE_QUERY } from './query';
import { client } from '../../shared/apollo';
import { parseConnection } from '../../shared';
import ReferenceNoSettingCard from './ReferenceNoSettingCard';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: REFERENCE_NO_UPDATE_QUERY,
    },
    cards: [
      {
        fields: [
          {
            render: ({ values, setFieldValue, loading, isSubmitting }) => (
              <ReferenceNoSettingCard
                onChange={(e) => setFieldValue('orderPrefix', e)}
                loading={loading}
                disabled={isSubmitting}
                value={values?.orderPrefix}
                title={translate.order_referenceNo_prefix}
              />
            ),
            md: 12,
          },
          {
            render: ({ values, setFieldValue, loading, isSubmitting }) => {
              return (
                <ReferenceNoSettingCard
                  onChange={(e) => setFieldValue('deliveryNotePrefix', e)}
                  disabled={isSubmitting}
                  loading={loading}
                  value={values?.deliveryNotePrefix}
                  title={translate.delivery_note_referenceNo_prefix}
                />
              );
            },
            md: 12,
          },
          {
            render: ({ values, setFieldValue, loading, isSubmitting }) => {
              return (
                <ReferenceNoSettingCard
                  onChange={(e) => setFieldValue('invoicePrefix', e)}
                  loading={loading}
                  disabled={isSubmitting}
                  value={values?.invoicePrefix}
                  title={translate.invoice_referenceNo_prefix}
                />
              );
            },
            md: 12,
          },
          {
            render: ({ values, setFieldValue, loading, isSubmitting }) => {
              return (
                <ReferenceNoSettingCard
                  onChange={(e) => setFieldValue('creditNotePrefix', e)}
                  loading={loading}
                  disabled={isSubmitting}
                  value={values?.creditNotePrefix}
                  title={translate.credit_note_referenceNo_prefix}
                />
              );
            },
            md: 12,
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    await Promise.all(
      [values.orderPrefix, values.deliveryNotePrefix, values.invoicePrefix, values.creditNotePrefix].map(
        async (value) => {
          if (!!value.id)
            await client.mutate({
              mutation: REFERENCE_NO_UPDATE_QUERY,
              variables: {
                id: value.id,
                input: {
                  prefix: value.prefix,
                  length: value.length || 7,
                },
              },
            });
          else
            await client.mutate({
              mutation: REFERENCE_NO_CREATE_QUERY,
              variables: {
                input: {
                  shopId: localStorage.getItem('shopId'),
                  type: value.type,
                  prefix: value.prefix,
                  length: value.length || 7,
                },
              },
            });
        },
      ),
    );
  };

  getInitialData({ node, me }) {
    const prefixes = parseConnection(node?.shopReferenceNoFormats)?.nodes?.reduce(
      (reducer, { type, ...value }) => ({ ...reducer, [type]: { ...value, type } }),
      {},
    );
    return {
      orderPrefix: prefixes?.['SHOP_ORDERS'] || { type: 'SHOP_ORDERS', prefix: 'PO', length: 7 },
      deliveryNotePrefix: prefixes?.['ORDER_DELIVERY_NOTES'] || {
        type: 'ORDER_DELIVERY_NOTES',
        prefix: 'DN',
        length: 7,
      },
      invoicePrefix: prefixes?.['ORDER_INVOICES'] || { type: 'ORDER_INVOICES', prefix: 'IN', length: 7 },
      creditNotePrefix: prefixes?.['INVOICE_CREDIT_NOTES'] || { type: 'INVOICE_CREDIT_NOTES', prefix: 'CN', length: 7 },
    };
  }
}
