import React from 'react';
import { toast } from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { translate } from '../../../shared/translate';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { CHECKOUT_PAY_QUERY } from './query';

export default ({
  isSubmitting,
  setSubmitting,
  values: { checkout } = {},
  invoice: { id, paymentMethodV2, credentialId } = {},
  onInvoiceCompleted = (_) => _,
}) => {
  const { id: checkoutId } = checkout || {};
  const [checkoutPay] = useMutation(CHECKOUT_PAY_QUERY);
  return (
    <Button
      disabled={isSubmitting}
      size={'small'}
      variant={'contained'}
      color={'primary'}
      onClick={async () => {
        try {
          setSubmitting(true);
          await checkoutPay({
            variables: {
              id: checkoutId,
              input: {
                provider: paymentMethodV2?.provider,
                credentialId,
                invoiceId: id,
              },
            },
          });
          onInvoiceCompleted();
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {translate.done}
    </Button>
  );
};
