import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Barcode from 'react-barcode';
import { useMeasure } from 'react-use';

const AutoHeightBarcode = ({ value }) => {
  const [fixedHeight, setFixedHeight] = useState(0);
  const [scaleX, setScaleX] = useState(1);

  const [container, { width: containerWidth, height: containerHeight }] = useMeasure();

  const [wrapper, { width: wrapperWidth }] = useMeasure();
  useEffect(() => {
    if (containerHeight && !fixedHeight) setFixedHeight(containerHeight);
  }, [containerHeight]);

  useEffect(() => {
    if (containerWidth && wrapperWidth && scaleX >= 1) setScaleX(containerWidth / wrapperWidth);
  }, [containerWidth, wrapperWidth]);

  return (
    <Box flex={1} ref={container} overflow={'hidden'}>
      <Box
        ref={wrapper}
        style={{
          display: 'inline-block',
          transform: `scaleX(${Math.min(scaleX, 1)})`,
          transformOrigin: 'top left',
        }}
      >
        <Barcode
          value={value ?? ''}
          width={1}
          height={containerHeight}
          format={'CODE128'}
          displayValue={false}
          margin={0}
        />
      </Box>
    </Box>
  );
};

export default AutoHeightBarcode;
