import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import FormRow from '../../components/FormRow';

export default () => {
  return (
    <Card elevation={2}>
      <CardContent>
        <Skeleton height={50} variant={'rect'} />
      </CardContent>
      <CardContent>
        <FormRow>
          <Skeleton height={30} />
        </FormRow>
        <Skeleton width={`${~~(20 + 70 * Math.random())}`} />
        <Skeleton width={`${~~(10 + 50 * Math.random())}`} />
      </CardContent>
    </Card>
  );
};
