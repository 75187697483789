import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const OverlayLoading = ({ children = <CircularProgress size={15} color={'inherit'} /> }) => (
  <Box
    position={'absolute'}
    width={'100%'}
    height={'100%'}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    style={{ top: 0, left: 0, color: 'white', backgroundColor: 'rgba(0,0,0,0.5)' }}
  >
    {children}
  </Box>
);

export default OverlayLoading;
