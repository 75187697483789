import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { convertMetaObject } from '../../../../shared';

const useStyles = makeStyles(() => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-11px',
    marginBottom: '-11px',
  },
}));

const RadioField = ({ disabled, value, onChange, field = {} }) => {
  const classes = useStyles();
  const { metadata, name, required, values = [] } = field ?? {};
  const { remarks } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <RadioGroup className={classes.radioGroup} value={value} onChange={(e) => onChange(e?.target?.value)}>
        {values?.map((value, index) => (
          <FormControlLabel
            key={index}
            disabled={disabled}
            value={value}
            control={<Radio required={required} />}
            label={value}
          />
        ))}
      </RadioGroup>
    </CustomFieldWrapper>
  );
};

export default RadioField;
