import React, { useState } from 'react';
import { Dialog, DialogContent, InputAdornment, MenuItem } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import FormToolbar from '../../../../components/FormToolbar';
import { Formik } from 'formik';
import FormCard from '../../../../components/FormPageMaker/Cards/FormCard';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import { actionWrapper } from '../../../../shared';

const PRODUCTS_QUANTITY_SET = gql`
  mutation ($shopId: ID!, $quantity: Int!, $operator: BatchOperator!, $filter: ProductFilterInput, $query: String) {
    productsQuantitySet(shopId: $shopId, quantity: $quantity, operator: $operator, filter: $filter, query: $query) {
      id
    }
  }
`;

export const MenuQuantitySet = ({ scope, mutation = PRODUCTS_QUANTITY_SET, ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <MenuItem disabled={scope.getSelectionCount() === 0} onClick={() => setVisible(true)}>
        {translate.setting} {translate.quantity}
      </MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Formik
          initialValues={{ operator: 'ADD', quantity: 0 }}
          onSubmit={async (values) => {
            setVisible(false);
            await actionWrapper(
              async () => {
                await client.mutate({
                  mutation,
                  variables: {
                    shopId: localStorage.getItem('shopId'),
                    filter: scope.getSelectionFilter(),
                    query: scope.getQ(),
                    operator: values.operator,
                    quantity: values.quantity,
                  },
                });
              },
              { messages: { loading: translate.update, success: translate.update_success } },
            );
          }}
        >
          {(actions) => (
            <form onSubmit={actions.handleSubmit} style={{ minWidth: 350 }}>
              <DialogContent>
                <FormCard
                  actions={actions}
                  fields={[
                    {
                      label: translate.adjust_type,
                      name: 'operator',
                      type: 'select',
                      options: [
                        { label: translate.adjust, value: 'ADD' },
                        { label: translate.assign_value, value: 'SET' },
                        { label: translate.set_infinite_stock, value: 'UNSET' },
                      ],
                      required: true,
                      text: ({ values }) => (values.operator === 'ADD' ? translate.adjust_remark : ''),
                    },
                    {
                      label: ({ values }) =>
                        values.operator === 'ADD' ? translate.quantity_change : translate.assign_value,
                      type: 'number',
                      name: 'quantity',
                      required: true,
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position={'start'}>
                            {actions?.values?.operator === 'ADD' ? translate.adjust : translate.assign}
                          </InputAdornment>
                        ),
                      },
                      display: ({ values }) => ['ADD', 'SET'].includes(values.operator),
                      inputProps: {
                        min: actions?.values?.operator === 'SET' && 0,
                        step: 1,
                      },
                    },
                  ]}
                />
              </DialogContent>
              <FormToolbar
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
                submitBtnType={'submit'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default {
  render: (args) => <MenuQuantitySet {...args} />,
};
