import React, { useState, useEffect } from 'react';
import { Box, ButtonBase, IconButton } from '@material-ui/core';
import Media from '../../Media';
import { Icon } from '../../IconRender';
import ImageFieldModal from './ImageFieldModal';
import FormRow from '../../FormRow';
import InputField from './InputField';
import Dropzone from 'react-dropzone';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import s3Upload from '../../../shared/s3Upload';
import uuid from 'react-uuid';
import OverlayLoading from '../../OverlayLoading';
import LSkeleton from '../../LSkeleton';

const MediaField = ({
  loading: _loading,
  required,
  className,
  disabled,
  /* extra field for MediasField only */
  modifyDisabled,
  value,
  hideRemoveButton,
  onChange = (_) => _,
}) => {
  const [hover, setHover] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false),
    toggleModal = () => setOpen(!open);

  const { id, alt, src, optimizedSrc } = value || {};

  useEffect(() => {
    if (!!value && !id) {
      onChange({
        ...value,
        id: uuid(),
      });
    }
  }, [value]);

  const onFileDrop = async (files) => {
    try {
      setIsDragging(false);
      setLoading(true);
      let file = files[0];
      const src = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsDataURL(file);
      });
      onChange({
        ...value,
        src,
        optimizedSrc: '',
      });
      const s3Path = await s3Upload(file);
      onChange({
        ...value,
        src: s3Path,
        optimizedSrc: '',
      });
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setLoading(false);
    }
  };

  if (_loading) return <LSkeleton variant={'rect'} width={100} height={100} />;

  return (
    <Box
      display={'inline-block'}
      className={className}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <Dropzone
        disabled={disabled || modifyDisabled || loading}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={onFileDrop}
        accept={['image/*', 'video/*']}
      >
        {({ getRootProps, getInputProps }) => (
          <ButtonBase
            type={`button`}
            style={{
              width: 100,
              height: 100,
              borderRadius: '1rem',
              overflow: 'hidden',
              border: `1px solid #665858`,
            }}
            {...getRootProps()}
            disabled={disabled || modifyDisabled || loading}
            onClick={toggleModal}
          >
            <input
              type={'file'}
              {...getInputProps()}
              accept="image/*"
              disabled={disabled || modifyDisabled || loading}
              required={required}
            />
            <Media
              disabled={true}
              resizeMode={hover ? 'cover' : 'contain'}
              style={{
                width: 100,
                height: 100,
              }}
              src={
                optimizedSrc ||
                src ||
                (disabled ? require('assets/gray.jpg') : !isDragging ? require('assets/image_placeholder.jpg') : null)
              }
            />
            {(loading || isDragging) && (
              <OverlayLoading>{isDragging ? <Icon icon={'faUpload'} fontSize={'large'} /> : undefined}</OverlayLoading>
            )}
          </ButtonBase>
        )}
      </Dropzone>
      {!disabled && !hideRemoveButton && (optimizedSrc || src) && (
        <Box textAlign={'center'} mt={1}>
          <IconButton
            type={'button'}
            color={'secondary'}
            size={'small'}
            disabled={disabled || loading}
            onClick={() => {
              onChange(undefined);
            }}
          >
            <Icon icon={'faTimesCircle'} type={'pro-solid'} />
          </IconButton>
        </Box>
      )}
      <ImageFieldModal
        open={open}
        onClose={toggleModal}
        image={src || optimizedSrc}
        onChange={(src) => {
          onChange({
            ...value,
            src,
            optimizedSrc: '',
          });
        }}
      >
        <FormRow title={'alt'} direction={'column'}>
          <InputField
            value={alt}
            onChange={(e) => {
              onChange({
                ...value,
                alt: e.target.value,
              });
            }}
          />
        </FormRow>
      </ImageFieldModal>
    </Box>
  );
};

export default MediaField;
