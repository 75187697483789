import React from 'react';
import { translate } from 'shared/translate';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import FormRow from '../../FormRow';
import InputField from '../../FormPageMaker/Fields/InputField';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
  },
  span: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  operatorSelect: {
    maxWidth: 100,
    height: 30,
    borderRight: `1px solid #c4c4c4`,
    width: 75,
    textAlign: 'center',
  },
}));

export default ({ title, filter = {}, onFilterChange = (_) => _ }) => {
  const classes = useStyles();
  const { operator = 'BETWEEN', value: _value } = filter || {},
    [v1, v2] = _value || [];

  return (
    <FormRow title={title}>
      <Grid container spacing={1}>
        <Grid item xs>
          <InputField
            // eslint-disable-next-line
            type={'number'}
            value={v1}
            onChange={(e) => {
              onFilterChange({
                ...filter,
                operator,
                value: [e.target.value, v2].filter((_) => _),
              });
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant={'overline'} className={classes.span}>
            {translate.to}
          </Typography>
        </Grid>
        <Grid item xs={true}>
          <InputField
            type={'number'}
            value={v2}
            onChange={(e) => {
              onFilterChange({
                ...filter,
                operator,
                value: [v1, e.target.value],
              });
            }}
          />
        </Grid>
      </Grid>
    </FormRow>
  );
};
