import React from 'react';
import { translate } from 'shared/translate';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';

export default ({ updatedAt, color = 'textSecondary', textColor }) => {
  if (!updatedAt) return null;
  return (
    <Box
      pb={1}
      px={1}
      display={'flex'}
      justifyContent={'flex-end'}
      style={{
        color: textColor,
      }}
    >
      <Typography variant={'caption'} color={textColor ? 'inherit' : color}>{`${translate.updated_at}: ${moment(
        updatedAt,
      ).format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
    </Box>
  );
};
