import { gql } from '@apollo/client';
export const FRAGMENT_STOCK_TAKE = gql`
  fragment FRAGMENT_STOCKTAKE on CompanyStocktake {
    id
    createdAt
    referenceNo
    updatedAt
    warehouse {
      id
      name
    }
    staff {
      id
      name
    }
    deviceId
    status
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $query: String) {
    node(id: $id) {
      id
      ... on Company {
        id
        stocktakes(cursor: $cursor, limits: $limits, sortBy: $sortBy, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_STOCKTAKE
          }
        }
      }
    }
  }
  ${FRAGMENT_STOCK_TAKE}
`;

export const EXPORT_QUERY = gql`
  mutation ($id: ID!, $shopId: ID!) {
    stocktakeExportV2(id: $id, shopId: $shopId) {
      id
      status
    }
  }
`;

export const IMPORT_QUERY = gql`
  mutation ($id: ID!, $shopId: ID!, $url: AWSURL!) {
    stocktakeImportV2(id: $id, shopId: $shopId, url: $url) {
      id
      status
    }
  }
`;
