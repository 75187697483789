import React, { useState } from 'react';
import BannerItemEditor from './BannerItemEditor';
import uuid from 'react-uuid';
import { IconButton, Avatar, Box, useTheme, Grid } from '@material-ui/core';
import { Icon } from '../../../../../../components/IconRender';
import Dnd from '../../../../../../components/DND';

export default ({ value, onChange = (_) => _, children = (props) => <BannerItemEditor {...props} /> }) => {
  const theme = useTheme();
  const { items = [] } = value || {};
  const [selectedId, setSelectedId] = useState(items && items[0] ? items[0].id : undefined);

  const addBanner = () => {
    const image = { id: uuid(), _type: 'banner' };
    items.push(image);
    onChange({
      ...value,
      items: items.map((item, i) => ({ ...item, sortIndex: i })),
    });
    setSelectedId(image.id);
  };

  const banner = items.find((obj) => obj.id === selectedId);
  const { id } = banner || {};

  return (
    <Grid container spacing={3} style={{ minWidth: 500 }}>
      <Grid item style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Dnd
          direction={'column'}
          items={items}
          onChange={(items) => {
            onChange({
              ...value,
              items: items.map((item, i) => ({ ...item, sortIndex: i })),
            });
          }}
          renderItem={(banner) => {
            const { id, src } = banner || {};
            const selected = selectedId === id;
            return (
              <Avatar
                variant={'rounded'}
                src={src || require('assets/gray.jpg')}
                disabled={selected}
                style={{
                  width: 50,
                  height: 50,
                  border: selected ? `5px solid ${theme.palette.secondary.main}` : 'none',
                }}
                onClick={() => setSelectedId(id)}
              />
            );
          }}
        >
          <IconButton
            type={'button'}
            style={{
              width: 50,
              height: 50,
            }}
            onClick={addBanner}
          >
            <Icon icon={'faPlus'} />
          </IconButton>
        </Dnd>
      </Grid>
      <Grid item xs={true} key={id}>
        {!banner ? (
          <Box height={200} bgcolor={'grey.200'} />
        ) : (
          children({
            banner,
            onChange: (update) => {
              Object.assign(banner, update);
              onChange(value);
            },
            onRemove: () => {
              const index = items.findIndex((item) => item.id === selectedId);
              if (!!~index) {
                items.splice(index, 1);
                onChange({
                  ...value,
                  items: items.map((item, i) => ({ ...item, sortIndex: i })),
                });
                setSelectedId((items[index - 1] || {}).id || (items[0] || {}).id);
              }
            },
          })
        )}
        {/*<EditorComponent*/}
        {/*  banner={banner}*/}
        {/*  onChange={update => {*/}
        {/*    Object.assign(banner, update);*/}
        {/*    onChange(value);*/}
        {/*  }}*/}
        {/*  onRemove={() => {*/}
        {/*    const index = items.findIndex(item => item.id === selectedId);*/}
        {/*    if (!!~index) {*/}
        {/*      items.splice(index, 1);*/}
        {/*      onChange({*/}
        {/*        ...value,*/}
        {/*        items: items.map((item, i) => ({ ...item, sortIndex: i })),*/}
        {/*      });*/}
        {/*      setSelectedId((items[index - 1] || {}).id);*/}
        {/*    }*/}
        {/*  }}*/}
        {/*/>*/}
      </Grid>
    </Grid>
  );
};
