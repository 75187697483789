import React from 'react';
import { Icon } from '../../../../../components/IconRender';
import BannerEditor from './BannerEditor';
import Slider from 'react-slick';
import { translate } from '../../../../../shared/translate';
import { Box } from '@material-ui/core';
import { useMeasure } from 'react-use';
import ComponentImage from '../image';

export default {
  label: translate.banner,
  defaultHeight: 0,
  icon: <Icon icon={'ViewCarousel'} type={'material'} style={{ color: '#97a1aa' }} />,
  preview: function PreviewFC({ data, height: _height, setEditData }) {
    const { items = [] } = data || {};
    const [ref, { width }] = useMeasure();

    const height = +_height || 400;

    if (items.length === 0)
      return (
        <div style={{ cursor: 'pointer' }} onClick={() => setEditData(data)}>
          <img
            src={require('assets/gray.jpg')}
            style={{
              width: '100%',
              height,
            }}
            alt={''}
          />
        </div>
      );
    return (
      <Box position={'relative'} ref={ref} height={height}>
        <Box display={'flex'} bgcolor={'grey.200'} style={{ overflow: 'hidden', position: 'absolute', width, height }}>
          <Slider dots adaptiveHeight slidesToShow={1} slidesToScroll={1} arrows>
            {items.map((item, i) => {
              return (
                <div key={i}>
                  <ComponentImage.preview
                    data={{
                      ...item,
                      resizeMode: 'cover',
                      _type: 'image',
                    }}
                    height={height}
                    setEditData={() => setEditData(data)}
                  />
                </div>
              );
            })}
          </Slider>
        </Box>
      </Box>
    );
  },
  edit: ({ value, onChange }) => {
    return <BannerEditor value={value} onChange={onChange} />;
  },
};
