import { gql } from '@apollo/client';

export const GET_OWNER_SETTING = gql`
  query {
    me {
      ... on User {
        id
        email
        picture
      }
    }
  }
`;

export const GET_CHANNEL_MESSAGES = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ChannelMessageFilterInput) {
    node(id: $id) {
      id
      ... on Channel {
        players {
          id
          name
          email
          picture
        }
        metadata {
          key
          value
        }
        messages(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            id
            channelPlayerId
            channelId
            type
            content
            createdAt
            # DO NOT get sender, because it will trigger infinity loading bug
            # sender {
            #  id
            #  name
            #  picture
            #}
          }
        }
      }
    }
  }
`;

export const CREATE_NEW_CHANNEL = gql`
  mutation ($input: ChannelSetInput!) {
    channelSet(input: $input) {
      id
      players {
        id
        name
        email
        picture
      }
    }
  }
`;

export const CREATE_NEW_PLAYER = gql`
  mutation ($input: ChannelPlayerSetInput!) {
    channelPlayerSet(input: $input) {
      id
      name
      email
      picture
      channel {
        id
        players {
          id
          name
          email
          picture
        }
      }
    }
  }
`;

export const POST_MESSAGE = gql`
  mutation ($input: ChannelMessageSetInput!) {
    channelMessageSet(input: $input) {
      id
      channelPlayerId
      channelId
      type
      content
      createdAt
    }
  }
`;

export const CHANNEL_MESSAGE_SUBSCRIPTION = gql`
  subscription ($channelId: ID) {
    channelMessageUpdate(channelId: $channelId) {
      id
      channelPlayerId
      channelId
      type
      content
      createdAt
    }
  }
`;

export const CHANNEL_READ = gql`
  mutation ($channelId: ID!, $channelPlayerId: String!) {
    channelRead(channelId: $channelId, channelPlayerId: $channelPlayerId) {
      id
    }
  }
`;
