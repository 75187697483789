import React, { useEffect, useState } from 'react';
import { translate } from '../../shared/translate';
import SystemButton from '../../components/SystemButton';
import { Box, Dialog, DialogTitle } from '@material-ui/core';
import FormToolbar from '../../components/FormToolbar';
import TextareaField from '../../components/FormPageMaker/Fields/TextareaField';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import YAML from 'yaml';

const RawDataModalButton = ({ value, onChange = (val) => {}, disabled }) => {
  const [cache, setCache] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) setCache(YAML.stringify(value));
  }, [visible]);

  return (
    <>
      <SystemButton disabled={disabled} color={'info'} onClick={() => setVisible(true)}>
        {translate.raw_data || '原始數據'}
      </SystemButton>
      <Dialog open={visible} maxWidth={'lg'} fullWidth>
        <DialogTitle>{translate.raw_data || '原始數據'}</DialogTitle>
        <Box p={3} style={{ overflowY: 'auto' }} height={'50vh'} maxHeight={1000} minHeight={300}>
          <TextareaField value={cache} onChange={(v) => setCache(v)} />
        </Box>
        <FormToolbar
          submitBtnType={'button'}
          onCancel={() => setVisible(false)}
          onSave={() => {
            try {
              onChange(YAML.parse(cache));
              setVisible(false);
            } catch (e) {
              toast.error(errorParser(e));
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default RawDataModalButton;
