import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { CREATE_QUERY, PREPARE_QUERY } from './query';
import { client } from '../../shared/apollo';
import AddressesCard from './AddressesCard';
import CustomFormMetadataField from '../../components/CustomFormMetadataField';
import { convertMetaArray, parseAddress } from '../../shared';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: PREPARE_QUERY,
      submit: CREATE_QUERY,
    },
    tabs: [
      {
        name: translate.basic_customer_information,
        cards: [
          {
            fields: [
              {
                md: 6,
                label: translate.phone_no,
                type: 'mobile',
                name: 'phoneNumber',
                allCountries: false,
                required: ({ values }) => !values.email,
              },
              {
                md: 6,
                label: translate.email,
                type: 'email',
                name: 'email',
                required: ({ values }) => !values.phoneNumber,
              },
              {
                label: translate.password,
                type: 'password',
                name: 'password',
                required: true,
                inputProps: {
                  pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                  autocomplete: 'new-password',
                },
                text: translate.password_remark,
              },
              {
                label: translate.remark,
                type: 'textarea',
                name: 'remark',
              },
            ],
          },
          {
            name: translate.customize_table,
            fields: [
              {
                render: ({
                  values: { memberMetadataFields = [], metadata = {} } = {},
                  setFieldValue,
                  loading,
                  disabled,
                  isSubmitting,
                }) => {
                  if (!loading && memberMetadataFields.length === 0) return null;

                  return (
                    <CustomFormMetadataField
                      shouldNotRequired={true}
                      value={metadata}
                      metadataFields={memberMetadataFields}
                      loading={loading}
                      disabled={disabled || isSubmitting}
                      onChange={(v) => setFieldValue('metadata', v)}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      {
        name: translate.address,
        cards: [AddressesCard],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        credential: {
          email: values.email || undefined,
          mobileV2: values.phoneNumber || undefined,
          password: values.password,
        },
        input: {
          name: values.metadata.name || undefined,
          familyName:
            values.metadata.lastName ||
            values.metadata.last_name ||
            values.metadata.lastname ||
            values.metadata.surname ||
            values.metadata.family_name ||
            values.metadata.familyName ||
            values.metadata.familyname ||
            values.metadata.name ||
            undefined,
          givenName:
            values.metadata.first_name ||
            values.metadata.firstName ||
            values.metadata.firstname ||
            values.metadata.given_name ||
            values.metadata.givenName ||
            values.metadata.givenname ||
            values.metadata.name ||
            undefined,
          nickname: values.metadata.nickname || values.metadata.nickName || values.metadata.nick_name || undefined,
          addresses: (() => {
            const hasDefault = !!(values.addresses || []).find((address) => address.isDefault);
            return (values.addresses || []).map((address, i) => ({
              ...parseAddress(address),
              isDefault: hasDefault ? address.isDefault : i === 0,
            }));
          })(),
          metadata: convertMetaArray({
            ...values.metadata,
            remark: values.remark,
          }),
        },
      },
      context: {
        headers: {
          referrer: values.referrer,
        },
      },
    });

    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {},
      { hostname, customDomain, memberMetadataFields } = node || {};
    return {
      referrer: `https://${customDomain || hostname}`,
      metadata: {},
      memberMetadataFields: memberMetadataFields || [],
    };
  };
}
