import React, { useRef } from 'react';
import ActivityIndicator from '../../ActivityIndicator';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import convertSize from 'convert-size';
import s3Upload from '../../../shared/s3Upload';
import { Box, Grid, Typography } from '@material-ui/core';
import { Icon } from '../../IconRender';
import { ReactComponent as UploadFile } from '../../../assets/icon/upload-file.svg';
import { ReactComponent as UploadFileFilled } from '../../../assets/icon/upload-file-filled.svg';
import TrashButton from '../../TrashButton';
import { translate } from '../../../shared/translate';

const FileUploadField = ({ value, onChange, onClear, loading, accept = '.xlsx, .xls, .csv' }) => {
  const importInput = useRef();
  const { fileSize, fileName } = value || {};

  return (
    <Box
      p={!!fileSize ? 2 : 4}
      textAlign={!!fileSize ? 'left' : 'center'}
      style={{
        border: `1px solid #d1d1d1`,
        borderRadius: 13,
        borderStyle: 'dashed',
        backgroundColor: '#f6f6f6',
        cursor: 'pointer',
      }}
      onClick={() => importInput.current.click()}
    >
      {!!fileSize && !!fileName ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2} sm={1}>
            <Icon
              type={'svg'}
              icon={UploadFileFilled}
              viewBox={'0 0 40 40'}
              style={{ fontSize: 40, color: 'transparent' }}
            />
          </Grid>
          <Grid item xs={8} sm={10}>
            <Typography style={{ wordBreak: 'break-all' }}>
              <span style={{ fontWeight: 'bold' }}>{fileName.split('.')[0]}</span>
              <span>.{fileName.split('.')[1]}</span>
            </Typography>
            <Typography style={{ color: '#B7BBBF' }}>{fileSize}</Typography>
          </Grid>
          <Grid item xs={2} sm={1}>
            <TrashButton
              disabled={loading}
              loading={loading}
              onClick={(e) => {
                e.stopPropagation();
                onClear();
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Icon type={'svg'} icon={UploadFile} viewBox={'0 0 40 40'} style={{ fontSize: 50, color: 'transparent' }} />
          <Typography>{translate.click_to_upload_file || '點選上傳檔案'}</Typography>
        </>
      )}

      <input
        ref={importInput}
        style={{ visibility: 'hidden', position: 'fixed' }}
        type={'file'}
        accept={accept}
        onChange={async (evt) => {
          evt.preventDefault();
          const files = evt.target.files;
          const file = files[0];
          try {
            ActivityIndicator.show();
            evt.target.value = null;

            const s3Path = await s3Upload(file);
            onChange({ s3Path, fileSize: convertSize(file.size, { accuracy: 2 }), fileName: file.name });
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            ActivityIndicator.hide();
          }
        }}
      />
    </Box>
  );
};

export default FileUploadField;
