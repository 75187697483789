import React from 'react';
import { CardContent, Grid, Tooltip, Typography } from '@material-ui/core';
import { Icon } from '../../IconRender';
import { ReactComponent as IconTips } from '../../../assets/icon/tips.svg';
import SwitchField from './SwitchField';

const SwitchGreyField = ({ label, remark, tooltip, onChange, loading, disabled, checked }) => {
  return (
    <CardContent style={{ backgroundColor: '#f6f6f6', borderRadius: 10, paddingBottom: 16 }}>
      <Grid container spacing={1} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant={'h6'}>
            {label}{' '}
            {!!tooltip && (
              <Tooltip title={tooltip} placement="right-start">
                <span>
                  <Icon
                    type={'svg'}
                    icon={IconTips}
                    viewBox="0 0 17 17"
                    style={{
                      color: 'transparent',
                      verticalAlign: 'text-bottom',
                      fontSize: '1.3rem',
                    }}
                  />
                </span>
              </Tooltip>
            )}
          </Typography>
          {!!remark && <Typography variant={'caption'}>{remark}</Typography>}
        </Grid>
        <Grid item>
          <SwitchField loading={loading} onChange={onChange} disabled={disabled} checked={checked} />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default SwitchGreyField;
