import React, { useEffect, useState } from 'react';
import useTpl from './useTpl';
import Mustache from 'mustache';
import { Skeleton } from '@material-ui/lab';
import { Typography, useTheme } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { useMeasure } from 'react-use';
import errorParser from '../../shared/errorParser';

const PreviewCard = ({ extraData = {}, values, loading }) => {
  const tpl = useTpl();
  const theme = useTheme();
  const [ref, { width }] = useMeasure();
  const [html, setHtml] = useState('');
  const [error, setError] = useState(undefined);

  const shop = values?.shop ?? {};
  const variables = {
    ...values?.configs,
    locale_zhHK: /zh[-_]?HK/.test(shop?.locale),
    locale_zhCN: /zh[-_]?CN/.test(shop?.locale),
    locale_en: /en/.test(shop?.locale),
    date: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: new Date().getDate(),
      day: new Date().getDate(),
      hour: new Date().getHours(),
      minute: new Date().getMinutes(),
      second: new Date().getSeconds(),
    },
    shop: {
      email: `${shop?.customDomain || shop?.hostname || 'info'}@omniwe.com`,
      name: shop?.name,
      logo: shop?.logoMedia?.src,
      url: `https://${shop?.customDomain || shop?.hostname || ''}`,
    },
    customer: {
      name: 'Kathy Chan',
    },
    ...extraData,
  };

  useEffect(() => {
    (async () => {
      try {
        setError(undefined);
        setHtml(Mustache.render(Mustache.render(tpl, variables), variables));
      } catch (e) {
        setError(e);
      }
    })();
  }, [tpl, JSON.stringify(variables)]);

  if (loading) return <Skeleton variant={'rect'} height={400} width={'100%'} />;

  return (
    !!tpl && (
      <>
        <Typography variant={'h6'}>{translate.preview}</Typography>
        <div
          ref={ref}
          style={{ borderRadius: 10, overflow: 'hidden', border: '1px solid #ddd', backgroundColor: '#e5e6e7' }}
        >
          {error ? (
            <div
              style={{
                width: 600,
                height: 500,
                padding: theme.spacing(3),
              }}
            >
              <Typography variant={'h6'}>Error</Typography>
              <Typography variant={'body1'}>{errorParser(error)}</Typography>
            </div>
          ) : (
            <>
              <style>
                {`
                  img {
                    display: inline;
                  }
                  a {
                    color: #007bff;
                    text-decoration: none;
                    background-color: initial;
                    -webkit-text-decoration-skip: objects
                  }
                `}
              </style>
              <div
                style={{
                  transformOrigin: 'top left',
                  transform: `scale(${Math.min(width / 600, 1)})`,
                }}
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            </>
          )}
        </div>
      </>
    )
  );
};

export default PreviewCard;
