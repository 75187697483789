import React from 'react';
import moment from 'moment';
import LSkeleton from '../../LSkeleton';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { DateRangeDelimiter, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import { Box, Grid, TextField } from '@material-ui/core';
import { translate } from '../../../shared/translate';

export default ({
  cusStyle,
  loading,
  fromValue,
  toValue,
  onFromValueChange = (_) => _,
  onToValueChange = (_) => _,
  InputProps,
  ...props
}) => {
  if (loading) return <LSkeleton height={30} />;

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <DateRangePicker
        allowKeyboardControl={false}
        allowSameDateSelection={true}
        clearable={true}
        showTodayButton={true}
        calendars={1}
        disableAutoMonthSwitching={true}
        inputFormat={'YYYY/MM/DD'}
        value={[moment(fromValue).toDate(), moment(toValue).toDate()]}
        onChange={([fromDate, toDate]) => {
          onFromValueChange(fromDate ? moment(fromDate).startOf('day').toISOString() : undefined);
          onToValueChange(toDate ? moment(toDate).endOf('day').toISOString() : undefined);
        }}
        renderInput={(startProps, endProps) => {
          return (
            <Box
              style={{
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: 10,
                padding: `0px 16px`,
                width: '100%',
              }}
            >
              <Grid container alignItems={'center'}>
                <Grid item>
                  <TextField
                    style={cusStyle}
                    {...startProps}
                    label={undefined}
                    variant={'standard'}
                    margin={'none'}
                    helperText={undefined}
                    // eslint-disable-next-line
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (InputProps || {}).startAdornment,
                    }}
                  />
                </Grid>
                <Grid item>
                  <DateRangeDelimiter>{translate.available_date_to}</DateRangeDelimiter>
                </Grid>
                <Grid item>
                  <TextField
                    style={cusStyle}
                    {...endProps}
                    label={undefined}
                    variant={'standard'}
                    margin={'none'}
                    helperText={undefined}
                    // eslint-disable-next-line
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (InputProps || {}).endAdornment,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};
