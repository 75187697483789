import { gql } from '@apollo/client';

export const FRAGMENT_ShopAddOnProduct = gql`
  fragment FRAGMENT_ShopAddOnProduct on ShopAddOnProduct {
    id
    createdAt
    updatedAt
    active
    name
    description
    amount
    purchaseLimit
    sku
    unitPrice
    quantity(warehouseId: $warehouseId)
    ignoreStock
    barcode
    cost
    weight
    publishAt
    publishThru
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
      alt
    }
    hashtags
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_ShopAddOnProduct}
  query ($id: ID!, $warehouseId: ID!) {
    node(id: $id) {
      ...FRAGMENT_ShopAddOnProduct
    }
  }
`;

export const POST_QUERY = gql`
  ${FRAGMENT_ShopAddOnProduct}
  mutation ($id: ID, $input: AddOnProductInput!, $warehouseId: ID!) {
    result: addOnProductSet(id: $id, input: $input) {
      id
      ...FRAGMENT_ShopAddOnProduct
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    result: addOnProductUnset(id: $id) {
      id
    }
  }
`;
