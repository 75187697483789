import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import QRCode from 'react-qr-code';
import { DeliveryMethodProvider } from '../../shared/omniwe-types';
import { translate } from '../../shared/translate';
import Barcode from 'react-barcode';
import PrintTable from '../../components/PrintTable';
import VariationCombinationDisplay from '../../components/VariationCombinationDisplay';

const OrderItem = ({ data }) => {
  return (
    <Grid container direction="column" spacing={3} style={{ padding: 16 }}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item xs={true}>
            <Grid container direction={'column'}>
              <Grid item>
                <Typography style={{ fontSize: '1.2rem' }}>
                  {translate.order_number + ': ' + data.order.referenceNo}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>{translate.shipping_address}</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: '1rem' }}>
                  {translate.receiver + ': ' + (((data || {}).address || {}).person || '-')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: '1rem' }}>
                  {translate.tracking_number + ': ' + ((data || {}).trackingNumber || '-')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: '1rem' }}>
                  {translate.delivery_method +
                    ': ' +
                    ((DeliveryMethodProvider[((data || {}).shippingProvider || {}).provider] || {}).label || '-')}
                </Typography>
              </Grid>
              <Grid item>
                {(() => {
                  const { name, lines, country } = (data || {}).address || {};
                  return (
                    <Typography style={{ fontSize: '1rem' }}>
                      {translate.shipping_address +
                        ': ' +
                        ([name, ...(lines || []), translate.countries[country]].filter((_) => _).join('\n') || '-')}
                    </Typography>
                  );
                })()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <QRCode size={128} value={data.id} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PrintTable
          data={
            data?.order?.checkout
              ?.map((item) => {
                if (!!item?.combination?.length) {
                  return {
                    ...(item ?? {}),
                    description: (
                      <>
                        <Typography style={{ fontSize: '1rem' }}>{item.description}</Typography>
                        <VariationCombinationDisplay
                          combination={item?.combination}
                          remark={item?.remark}
                          direction={'column'}
                          parseColorCode={false}
                        />
                      </>
                    ),
                  };
                }

                return {
                  ...(item ?? {}),
                  description: (
                    <>
                      <Typography style={{ fontSize: '1rem' }}>{item.description}</Typography>
                      {!!item?.remark && (
                        <Typography style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}>{item.remark}</Typography>
                      )}
                    </>
                  ),
                };
              })
              ?.flat() ?? []
          }
          columns={[
            {
              title: translate.product_details,
              value: (item) => item?.description,
            },
            {
              title: 'SKU',
              value: (item) => item?.sku,
            },
            {
              width: 1,
              title: 'Barcode',
              render: (item) => <Barcode width={1} height={30} displayValue={false} value={item?.barcode} />,
            },
            {
              width: 120,
              title: translate.quantity,
              align: 'right',
              value: (item) => {
                return <Typography style={{ fontSize: '1rem' }}>{item.quantity}</Typography>;
              },
            },
          ]}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>{translate.remark}</Typography>
          </Grid>
          {!!data?.order?.remark && (
            <Grid item>
              <Typography style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}>{data?.order?.remark}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderItem;
