import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($shopId: ID!) {
    me {
      ... on User {
        id
        email
        picture
        isEmailVerified
        name
      }
    }
    shop: node(id: $shopId) {
      id
      ... on CompanyShop {
        name
        locale
        hostname
        customDomain
        timezone
        type
        logoMedia {
          src
          optimizedSrc(width: 256)
        }
      }
    }
  }
`;
