import React from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { Box, InputAdornment } from '@material-ui/core';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import HighlightFormRow from '../../HighlightFormRow';
import { useMeasure } from 'react-use';

export default {
  defaultHeight: 0,
  label: translate.instagram_post,
  icon: <Icon icon={'faInstagram'} type={'pro-brand'} style={{ color: '#97a1aa' }} />,
  preview: function PreviewFC({ data, setEditData = (_) => _, height }) {
    const [wrapper, { width, height: wrapperHeight }] = useMeasure();
    const { postId, hideProfileBar, ratio: _ratio } = data || {};
    const profileBarHeight = 54;

    const ratio = !height ? _ratio || 1 : _ratio;
    const widgetHeight = !!height ? wrapperHeight : width * ratio + (hideProfileBar ? 0 : profileBarHeight);

    return (
      <Box
        display={'flex'}
        onClick={() => setEditData(data)}
        height={+height || undefined}
        style={{ cursor: 'pointer' }}
        justifyContent={'center'}
        alignItems={'center'}
        position={'relative'}
      >
        {!!postId && (
          <div
            style={{
              overflow: 'hidden',
              width: '100%',
              height: widgetHeight,
              boxShadow: 'rgb(0 0 0 / 33%) 0 0 4px 0px',
            }}
          >
            <iframe
              title={postId}
              className={'instagram-media instagram-media-rendered'}
              style={{
                background: 'white',
                width: '100%',
                height: hideProfileBar ? widgetHeight + profileBarHeight * 2 : widgetHeight,
                border: 'none',
                boxShadow: 'none',
                display: 'block',
                padding: 0,
                marginTop: hideProfileBar ? -profileBarHeight : 0,
              }}
              src={`https://www.instagram.com/${postId}/embed`}
            />
          </div>
        )}
        <div ref={wrapper} style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 1 }} />
      </Box>
    );
  },
  edit: ({ value, onChange }) => {
    const { postId } = value || {};
    const update = (key, v) => {
      onChange({
        ...value,
        [key]: v,
      });
    };
    return (
      <>
        <HighlightFormRow highlight={value.postId !== undefined} title={translate.instagram_post}>
          <InputField
            value={postId}
            placeholder={'*********'}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: <InputAdornment position={'start'}>https://www.instagram.com/</InputAdornment>,
            }}
            onChange={(e) => update('postId', e.target.value)}
          />
        </HighlightFormRow>
      </>
    );
  },
};
