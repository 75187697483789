import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import TrashButton from '../../../components/TrashButton';

const CustomChargeField = ({ disabled, value: expressions = [], onChange = (expressions) => {} }) => {
  return (
    <StyledConfigList>
      <Grid item container spacing={2}>
        <Grid item xs={5}>
          {translate.max_weight} *
        </Grid>
        <Grid item xs={6}>
          {translate.shipping_fee} *
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {expressions?.map((expression, i) => {
        const { maxWeight: previousMaxWeight = 0 } = expressions[i - 1] || {};
        const previousMaxWeightLimit =
          expressions.length === 1 || i === 0 ? previousMaxWeight : +previousMaxWeight + 0.001;
        return (
          <Grid key={`${i}-${expressions.length}`} item container spacing={2} alignItems={'center'}>
            <Grid item xs={5}>
              {i === expressions.length - 1 ? (
                <span>
                  {previousMaxWeightLimit}kg {translate.above}
                </span>
              ) : (
                <InputField
                  required={true}
                  disabled={disabled}
                  type="number"
                  value={expression.maxWeight}
                  onChange={(e) => {
                    onChange(
                      expressions.map((exp, j) =>
                        i === j ? { ...exp, maxWeight: e.target.value, unit: e.target.value - 0 } : exp,
                      ),
                    );
                  }}
                  // eslint-disable-next-line
                  inputProps={{
                    min: previousMaxWeightLimit,
                    step: 0.001,
                  }}
                  // eslint-disable-next-line
                  InputProps={{
                    startAdornment: <InputAdornment position={'start'}>{previousMaxWeightLimit}kg ~</InputAdornment>,
                    endAdornment: <InputAdornment position={'end'}>kg</InputAdornment>,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <InputField
                required={true}
                type="number"
                disabled={disabled}
                value={expression.unitPrice}
                onChange={(e) => {
                  onChange(expressions.map((exp, j) => (i === j ? { ...exp, unitPrice: e.target.value } : exp)));
                }}
                // eslint-disable-next-line
                InputProps={{
                  startAdornment: <InputAdornment position={'start'}>{translate.shipping_fee}: HKD</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <TrashButton
                disabled={disabled || expressions.length - 1 === i}
                onClick={() => {
                  onChange(expressions.filter((_, j) => i !== j));
                }}
              />
            </Grid>
          </Grid>
        );
      })}
      <AddButton
        disabled={disabled}
        title={translate.new_option}
        onClick={() => {
          const secondLast = expressions[expressions.length - 2] || {};

          onChange(
            expressions.concat({
              maxWeight: secondLast.maxWeight || 0,
            }),
          );
        }}
      />
    </StyledConfigList>
  );
};

export default CustomChargeField;
