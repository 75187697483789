import React, { useEffect, useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboStaff from '../../SelectCombo/SelectComboStaff';
import CheckboxField from '../../FormPageMaker/Fields/CheckboxField';
import { translate } from '../../../shared/translate';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, name] = str.match(/^(.*?)∆(.*)$/);
      return { id, name };
    });
  const [staffs, setStaffs] = useState(values);

  const { search } = useLocation();
  const isAdminChecked = search.split('&').some((str) => /\%5E\*admin\%E2\%88\%86admin/.test(str));
  const [checked, setChecked] = useState(isAdminChecked);
  const [mergedFilters, setMergedFilters] = useState(values);

  useEffect(() => {
    onFilterChange({
      value: _.flatten(mergedFilters || []).map((mergedFilter) => `${mergedFilter.id}∆${mergedFilter.name}`),
      operator: 'IN',
    });
  }, [checked, mergedFilters]);

  return (
    <FormRow
      title={title}
      remarks={
        <CheckboxField
          text={translate.admin_user}
          checked={checked}
          onChange={() => {
            setChecked(!checked);
            setMergedFilters((prevFilters) => {
              if (prevFilters.some((filter) => filter.id === 'admin') && checked)
                return prevFilters.filter((prevFilter) => !(prevFilter.id === 'admin'));
              return [...prevFilters, { id: 'admin', name: 'admin' }];
            });
          }}
        />
      }
    >
      <SelectComboStaff
        multiple
        disablePortal={false}
        value={staffs.filter((staff) => staff.id !== 'admin')}
        onChange={(staffs) => {
          setStaffs(staffs);
          setMergedFilters((prevFilters) => {
            if (prevFilters.some((filter) => filter.id === 'admin') || checked)
              return [
                (staffs || []).map((staff) => ({ id: staff.id, name: staff.name })),
                { id: 'admin', name: 'admin' },
              ];
            return (staffs || []).map((staff) => ({ id: staff.id, name: staff.name }));
          });
        }}
      />
    </FormRow>
  );
};
