import React, { useState } from 'react';
import { OrderCard, OrderCardContent, OrderCardHeader } from '../../../components/OrderCard';
import { translate } from '../../../shared/translate';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { Icon } from '../../../components/IconRender';
import { Formik } from 'formik';
import FormToolbar from '../../../components/FormToolbar';

const OrderComments = ({ values: { id } = {}, className, minHeight, title, remark, medias, query, type }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <OrderCard className={className} style={{ minHeight: minHeight }}>
        <OrderCardHeader title={title} />
        <OrderCardContent>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} style={{ position: 'relative' }}>
              <Box
                p={1}
                style={{
                  border: `1px solid #BDBDBD`,
                  borderRadius: 10,
                  height: 118,
                  overflowY: 'auto',
                }}
              >
                <Box style={{ position: 'absolute', right: 30, top: 48 }}>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setVisible(true);
                    }}
                  >
                    <Icon icon={'faPencil'} color={'primary'} />
                  </IconButton>
                </Box>
                <Typography style={{ whiteSpace: 'pre-line', wordBreak: 'break-word', width: 320 }}>
                  {remark}
                </Typography>
              </Box>
            </Grid>
            {!!medias.length && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {medias.map((media, i) => {
                    const { src, optimizedSrc, alt } = media;
                    return (
                      <Grid item key={i}>
                        <img
                          src={optimizedSrc || src}
                          alt={alt}
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: 'contain',
                            border: '1px solid #E4E4E4',
                            cursor: 'pointer',
                          }}
                          onClick={() => window.open(src || optimizedSrc, '_blank')}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </OrderCardContent>
      </OrderCard>
      {visible && (
        <Dialog
          open={visible}
          onClose={() => setVisible(false)}
          PaperProps={{
            style: { width: '400px' },
          }}
        >
          <DialogTitle style={{ alignSelf: 'center', padding: '23px 0 10px 0' }}>{title}</DialogTitle>
          <Formik
            onSubmit={async (values) => {
              try {
                await client.mutate({
                  mutation: query,
                  variables: {
                    id,
                    input:
                      type === 'remark'
                        ? {
                            remark: values.remark,
                          }
                        : {
                            internalRemark: values.remark,
                          },
                  },
                });

                setVisible(false);
                toast.success(translate.update_success);
              } catch (e) {
                toast.error(errorParser(e));
              } finally {
              }
            }}
            initialValues={{ remark }}
          >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent style={{ padding: '10px 35px' }}>
                  <InputField
                    multiline
                    style={{ height: 121, overflowY: 'auto' }}
                    value={values.remark}
                    disabled={isSubmitting}
                    onChange={(e) => setFieldValue('remark', e.target.value)}
                  />
                  <Box mb={2} />
                </DialogContent>
                <FormToolbar
                  submitBtnType={'submit'}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onCancel={() => setVisible(false)}
                />
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
};

export default OrderComments;
