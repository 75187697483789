import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import FormFront from './FormFront';
import FormStock from './FormStock';
import { GET_QUERY, POST_QUERY } from './query';
import { cloneObject, convertMetaArray, convertMetaObject, removeTypename } from '../../shared';
import { client } from '../../shared/apollo';
import { Grid, InputAdornment } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { Skeleton } from '@material-ui/lab';
import CheckoutRoundingField from './CheckoutRoundingField';
import OrderAutoCancelField from './OrderAutoCancelField';
import OrderAutoCompleteField from './OrderAutoCompleteField';
import ManualOrderConfirmField from './ManualOrderConfirmField';
import AllowGuestCheckoutField from './AllowGuestCheckoutField';
import UnpaidCartReminderField from './UnpaidCartReminderField';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
    },
    tabs: [
      {
        name: translate.product_order_options,
        formDisabled: true,
        cards: [
          {
            name: translate.frontend_settings || '前台顯示',
            fields: [
              { render: (actions) => <FormFront {...actions} /> },
              {
                id: '#' + translate.enable_guest_checkout,
                render: ({ disabled, setFieldValue, values }) => (
                  <AllowGuestCheckoutField
                    value={values?.allowGuestCheckout}
                    disabled={disabled}
                    onChange={(v) => setFieldValue('allowGuestCheckout', v)}
                  />
                ),
                formRowTheme: 'card',
              },
              {
                id: '#' + translate.purchaseHint,
                label: translate.purchaseHint,
                formRowTheme: 'card',
                render: ({ loading, disabled, values, setFieldValue }) => (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <InputField
                        loading={loading}
                        disabled={disabled}
                        type={'number'}
                        value={values?.metadata?.purchaseHintAmount}
                        onChange={(e) => setFieldValue('metadata.purchaseHintAmount', e.target.value)}
                        inputProps={{
                          min: 0,
                          step: 1,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position={'start'}>{translate.purchaseHintAmount}</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        loading={loading}
                        disabled={disabled}
                        value={values?.metadata?.purchaseHintMessage}
                        onChange={(e) => setFieldValue('metadata.purchaseHintMessage', e.target.value)}
                        InputProps={{
                          startAdornment: <InputAdornment position={'start'}>{translate.message}</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                ),
              },
            ],
          },
          {
            name: translate.purchase_order_settings || '訂單設定',
            fields: [
              {
                id: '#' + translate.fnb_mode,
                label: translate.fnb_mode,
                render: ({ loading, values, setFieldValue, disabled }) => {
                  return loading ? (
                    <Skeleton height={30} />
                  ) : (
                    <SelectField
                      defaultValue={'standard'}
                      value={values?.metadata?.fnBStrategy}
                      disabled={disabled}
                      options={[
                        { label: translate.pre_payment_mode, value: 'payment' },
                        { label: translate.general_mode, value: 'standard' },
                      ]}
                      required
                      onChange={(e) => setFieldValue('metadata.fnBStrategy', e.target.value)}
                    />
                  );
                },
                formRowTheme: 'card',
              },
              {
                id: '#' + translate.unpaid_cart_reminder,
                render: ({ disabled, values, setFieldValue }) => (
                  <UnpaidCartReminderField
                    disabled={disabled}
                    value={values?.metadata?.inactivityCartTimeout}
                    onChange={(v) => setFieldValue('metadata.inactivityCartTimeout', v)}
                  />
                ),
                formRowTheme: 'card',
              },
              {
                id: '#' + translate.manual_order_confirm,
                render: ({ disabled, values, setFieldValue }) => (
                  <ManualOrderConfirmField
                    disabled={disabled}
                    value={values?.autoConfirm}
                    onChange={(v) => setFieldValue('autoConfirm', v)}
                  />
                ),
                formRowTheme: 'card',
              },
              {
                id: '#' + translate.order_processing,
                label: translate.order_processing,
                render: ({ loading, values, setFieldValue, disabled }) => {
                  return loading ? (
                    <Skeleton height={30} />
                  ) : (
                    <CheckoutRoundingField
                      value={values.checkoutRounding}
                      disabled={disabled}
                      onChange={(v) => setFieldValue('checkoutRounding', v)}
                    />
                  );
                },
                formRowTheme: 'card',
              },
              {
                id: '#' + translate.auto_complete_order,
                render: ({ disabled, setFieldValue, values }) => (
                  <OrderAutoCompleteField
                    disabled={disabled}
                    value={values.deliveryNoteAutoComplete}
                    onChange={(v) => setFieldValue('deliveryNoteAutoComplete', v)}
                  />
                ),
                formRowTheme: 'card',
              },
              {
                id: '#' + translate.order_expiry_days,
                render: ({ disabled, values, setFieldValue }) => (
                  <OrderAutoCancelField
                    value={values.orderExpiry}
                    disabled={disabled}
                    onChange={(v) => setFieldValue('orderExpiry', v)}
                  />
                ),
                formRowTheme: 'card',
              },
            ],
          },
          {
            name: translate.inventory_settings || '庫存設定',
            fields: [{ render: (actions) => <FormStock {...actions} /> }],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          name: values.name,
          orderExpiry: values.orderExpiry,
          deliveryNoteAutoComplete: values.deliveryNoteAutoComplete,
          autoConfirm: values.autoConfirm,
          allowGuestCheckout: values.allowGuestCheckout,
          metadata: convertMetaArray({ ...values.metadata, fnBStrategy: values.metadata.fnBStrategy || 'standard' }),
          checkoutRounding: {
            maximumFractionDigits: values.checkoutRounding?.maximumFractionDigits ?? 2,
            strategy: values.checkoutRounding?.strategy ?? 'ROUND',
          },
          underStock: {
            ...values.underStock,
            quantity: 0,
            remind: !!values?.underStock?.remind,
          },
          lowStock: values.lowStock,
          overStock: values.overStock,
        },
      },
    });
  };

  getInitialData({ node }) {
    const {
      name,
      orderExpiry,
      deliveryNoteAutoComplete,
      autoConfirm,
      allowGuestCheckout,
      metadata,
      checkoutRounding,
      underStock,
      lowStock,
      overStock,
    } = node || {};

    return {
      name,
      autoConfirm,
      allowGuestCheckout,
      orderExpiry: removeTypename(cloneObject(orderExpiry || {})),
      deliveryNoteAutoComplete: removeTypename(cloneObject(deliveryNoteAutoComplete || {})),
      metadata: convertMetaObject(metadata),
      checkoutRounding: removeTypename(cloneObject(checkoutRounding)),
      underStock: removeTypename(cloneObject(underStock)),
      lowStock: removeTypename(cloneObject(lowStock)),
      overStock: removeTypename(cloneObject(overStock)),
    };
  }
  onCancelClick = undefined;
}
