import React, { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

const LSkeleton = ({ variant = 'text', ...props }) => {
  if (variant === 'text') return <LTextSkeleton {...props} />;
  return <Skeleton variant={variant} {...props} />;
};

export default LSkeleton;

export const LTextSkeleton = ({ width: _width = '100%', style, ...props }) => {
  const factor = 5;
  const [
    width,
    // setWidth
  ] = useState(getVarSkeletonWidth());

  // useDebounce(
  //   () => {
  //     setWidth(getVarSkeletonWidth());
  //   },
  //   1000,
  //   [width],
  // );

  return <Skeleton width={width} style={{ display: 'inline-block', ...style }} {...props} />;

  function getVarSkeletonWidth() {
    if (_.isNumber(_width)) return _width - (_width / factor) * Math.random();
    else if (/%/.test(_width)) {
      const value = _width.replace('%', '') - 0;
      return `${value - (value / factor) * Math.random()}%`;
    }
  }
};
