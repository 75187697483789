import React, { useState, useEffect } from 'react';
import DebounceSearchInput from '../../../../components/DebounceSearchInput';
import { Autocomplete } from '@material-ui/lab';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Icon } from '../../../../components/IconRender';
import { useHistory } from 'react-router-dom';
import { translate } from '../../../../shared/translate';
import _ from 'lodash';
import { getTreeParents } from './index';

export default ({ pages = [], placeholder }) => {
  const availablePages = pages.filter((page) => !!page && !!page.link && !!page.name);
  const availableFields = availablePages.reduce((reducer, { name, link, component }) => {
    if (!!component && !/\/new/.test(link)) {
      const body = component.toString();
      const matches = [...body.matchAll(/id:.*?#.*?\.([a-zA-Z0-9_]*)[,}]/g)]
        .map((arr) => {
          const fieldName = translate[arr[1]];
          return !!fieldName
            ? {
                name: `${name} > ${fieldName}`,
                link: `${link}#${fieldName}`,
              }
            : undefined;
        })
        .filter((_) => _);
      reducer.push(...matches);
    }
    return reducer;
  }, []);

  const history = useHistory();
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!!search) {
      const { link } = search;
      history.push(link);
      setSearch(undefined);
    }
  }, [search]);

  return (
    <Autocomplete
      freeSolo
      disableClearable
      value={search}
      onChange={(e, search) => setSearch(search)}
      options={availablePages.concat(availableFields)}
      getOptionLabel={(option) => {
        const breadcrumb = _.reverse(getTreeParents(option._parent).map(({ name }) => name)).filter((_) => _);
        return breadcrumb.length > 0 ? breadcrumb.join(' > ') + ` > ${option.name}` : option?.name ?? '';
      }}
      renderInput={({ InputProps = {}, ...params }) => {
        return (
          <DebounceSearchInput
            {...params}
            fullWidth={true}
            variant={'standard'}
            placeholder={placeholder || ''}
            // eslint-disable-next-line
            InputProps={{
              ...InputProps,
              endAdornment: (
                <InputAdornment position={'end'}>
                  {search ? (
                    <IconButton
                      size={'small'}
                      onClick={() => {
                        setSearch(undefined);
                      }}
                      color={'secondary'}
                    >
                      <Icon icon={'faTimes'} />
                    </IconButton>
                  ) : (
                    <Icon icon={'faSearch'} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};
