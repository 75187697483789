import React from 'react';
import { REMOVE_QUERY } from '../query';
import { Typography } from '@material-ui/core';
import { translate, translateFormat } from '../../../shared/translate';
import SystemButton from '../../../components/SystemButton';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { client } from '../../../shared/apollo';
import errorParser from '../../../shared/errorParser';
import toast from '../../../shared/toast';
import { sleep } from '../../../shared';

const RemoveFromCategoryButton = ({ currCollectionId, list, disabled }) => {
  const handleDelete = async () => {
    try {
      ActivityIndicator.show(translate.loading);
      const result = await list.infinityFetchAllSelected();
      await client.mutate({
        mutation: REMOVE_QUERY,
        variables: {
          id: currCollectionId,
          productIds: result?.nodes?.map((node) => node.id),
        },
      });

      sleep(3000).then(() => list.refetch());
      list.resetSelectionData();
      toast.success(
        translateFormat(translate.items_removed_refresh_later, { num: result?.nodes?.map((node) => node.id)?.length }),
      );
    } catch (error) {
      toast.error(errorParser(error));
    } finally {
      ActivityIndicator.hide();
    }
  };

  return (
    <SystemButton disabled={disabled} variant={'contained'} onClick={handleDelete} color={'error'}>
      <Typography variant="caption1" color={'inherit'}>
        {translate.product_collections_remove || '移除產品'}
      </Typography>
    </SystemButton>
  );
};

export default RemoveFromCategoryButton;
