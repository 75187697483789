import Preview from './Preview';
import Edit from './Edit';
import { Icon } from '../../../../../components/IconRender';
import React from 'react';

export default {
  label: 'Grid',
  defaultHeight: 500,
  icon: <Icon icon={'faColumns'} style={{ color: '#97a1aa' }} />,
  preview: Preview,
  edit: Edit,
};
