import { gql } from '@apollo/client';

export const GET_ORDER_DATA = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on ShopOrder {
        customer {
          id
          email
          phoneNumber
        }
        checkout {
          id
          pickUpAddress {
            name
            person
            tel
            email
            lines
            country
          }
        }
        shippingProvider {
          id
          name
          provider
        }
        shippingAddress {
          name
          person
          tel
          email
          lines
          city
          country
        }
        items {
          id
          quantity
          description
          remark
          sku
          deliverableQuantity
          serviceBundle {
            serviceBundle {
              id
              name
            }
          }
          bundleProduct {
            bundleProduct {
              id
              name
              sku
            }
            options {
              id
              remark
              label
              price
              variation {
                id
                sku
                combination {
                  option
                  name
                }
              }
              product {
                id
                sku
                name
              }
            }
          }
          productVariation {
            id
            sku
            combination {
              name
              option
            }
          }
        }
      }
    }
  }
`;
