import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import EmailField from './EmailField';
import FormRow from '../../../components/FormRow';
import { translate, translateFormat } from '../../../shared/translate';
import EmailList from './EmailList';
import LSkeleton from '../../../components/LSkeleton';
import toast from '../../../shared/toast';

const MerchantEmailCard = ({ loading, values, setFieldValue, isSubmitting }) => {
  const { orderEmails = [], billingEmails = [], generalEmails = [], exportEmails = [] } = values || {};

  return (
    <Grid id={'#' + translate.merchant_email} container spacing={3}>
      <Grid item xs={12}>
        <Typography>{translate.max_two_email}</Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <FormRow title={translate.order_email}>
              {loading ? (
                <LSkeleton />
              ) : (
                <EmailField
                  helperText={translate.order_email_description}
                  disabled={isSubmitting}
                  onChange={(value) => {
                    if (orderEmails?.length > 1) {
                      toast.error(translateFormat(translate.err_two_email, { variable: translate.order_email }));
                    } else {
                      setFieldValue(
                        'orderEmails',
                        orderEmails.concat({ email: value, type: 'ORDER', verified: undefined }),
                      );
                    }
                  }}
                />
              )}
            </FormRow>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <>
                <LSkeleton />
                <LSkeleton />
              </>
            ) : (
              <EmailList
                disabled={isSubmitting}
                emails={orderEmails}
                onChange={(value) => setFieldValue('orderEmails', value)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <FormRow title={translate.bill_email}>
              {loading ? (
                <LSkeleton />
              ) : (
                <EmailField
                  helperText={translate.bill_email_description}
                  disabled={isSubmitting}
                  onChange={(value) => {
                    if (billingEmails?.length > 1) {
                      toast.error(translateFormat(translate.err_two_email, { variable: translate.bill_email }));
                    } else {
                      setFieldValue(
                        'billingEmails',
                        billingEmails.concat({ email: value, type: 'BILLING', verified: undefined }),
                      );
                    }
                  }}
                />
              )}
            </FormRow>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <>
                <LSkeleton />
                <LSkeleton />
              </>
            ) : (
              <EmailList
                disabled={isSubmitting}
                emails={billingEmails}
                onChange={(value) => setFieldValue('billingEmails', value)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <FormRow title={translate.merchant_contact_email}>
              {loading ? (
                <LSkeleton />
              ) : (
                <EmailField
                  helperText={translate.merchant_contact_email_description}
                  disabled={isSubmitting}
                  onChange={(value) => {
                    if (generalEmails?.length > 1) {
                      toast.error(
                        translateFormat(translate.err_two_email, { variable: translate.merchant_contact_email }),
                      );
                    } else {
                      setFieldValue(
                        'generalEmails',
                        generalEmails.concat({ email: value, type: 'GENERAL', verified: undefined }),
                      );
                    }
                  }}
                />
              )}
            </FormRow>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <>
                <LSkeleton />
                <LSkeleton />
              </>
            ) : (
              <EmailList
                disabled={isSubmitting}
                emails={generalEmails}
                onChange={(value) => setFieldValue('generalEmails', value)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <FormRow title={translate.output_email}>
              {loading ? (
                <LSkeleton />
              ) : (
                <EmailField
                  helperText={translate.output_email_description}
                  disabled={isSubmitting}
                  onChange={(value) => {
                    if (exportEmails?.length > 1) {
                      toast.error(translateFormat(translate.err_two_email, { variable: translate.output_email }));
                    } else {
                      setFieldValue(
                        'exportEmails',
                        exportEmails.concat({
                          email: value,
                          type: 'EXPORT',
                          verified: undefined,
                        }),
                      );
                    }
                  }}
                />
              )}
            </FormRow>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <>
                <LSkeleton />
                <LSkeleton />
              </>
            ) : (
              <EmailList
                disabled={isSubmitting}
                emails={exportEmails}
                onChange={(value) => setFieldValue('exportEmails', value)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MerchantEmailCard;
