import { GET_QUERY } from './query';
import DefaultDeviceListView from '../../list_Device/ListView';
import { translate } from '../../../shared/translate';

export default class extends DefaultDeviceListView {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.shop_name,
        value: (row) => row?.shop?.name,
      },
      ...this.state.fields,
    ],
    createUrl: undefined,
    getRowLink: undefined,
  };
}
