import React from 'react';
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { translate } from '../../shared/translate';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import CheckboxField from '../../components/FormPageMaker/Fields/CheckboxField';
import _ from 'lodash';

const ModifiersExtendField = ({ loading, disabled, value = [], onChange = (v) => {} }) => {
  if (!_.flatten(_.map(value, 'options'))?.length) return <></>;
  return (
    <Box mt={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'caption'}>{translate.variant_content}</Typography>
              </TableCell>
              <TableCell width={150}>
                <Typography variant={'caption'}>{translate.extra_minutes || '額外分鐘'}</Typography>
              </TableCell>
              <TableCell width={150}>
                <Typography variant={'caption'}>{translate.additional_fee} *</Typography>
              </TableCell>
              <TableCell width={50}>
                <Typography variant={'caption'}>{translate.visibility}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value?.map((modifier, i) => {
              const { name, options } = modifier ?? {};
              return options?.map((option, j) => (
                <TableRow key={`${name}-${i}-${j}`}>
                  <TableCell>
                    {name}: {option.name}
                  </TableCell>
                  <TableCell>
                    <InputField
                      type={'number'}
                      inputProps={{ step: 5, min: 0 }}
                      value={option?.minutesAdjustment}
                      onChange={(e) => {
                        option.minutesAdjustment = e.target.value;
                        onChange(value);
                      }}
                      onBlur={(e) => {
                        const v = +e?.target?.value;
                        if (v % 5 !== 0) {
                          option.minutesAdjustment = Math.ceil(v / 5) * 5;
                          onChange(value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          e.target.blur();
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position={'end'}>{translate.minutes}</InputAdornment>,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputField
                      type={'number'}
                      placeholder={'0.00'}
                      required={true}
                      disabled={disabled}
                      value={option?.priceAdjustment}
                      onChange={(e) => {
                        option.priceAdjustment = e.target.value;
                        onChange(value);
                      }}
                      inputProps={{
                        min: 0,
                        step: 0.01,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <CheckboxField
                      disabled={disabled}
                      checked={option?.active}
                      onChange={() => {
                        option.active = !option.active;
                        onChange(value);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ));
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ModifiersExtendField;
