import { gql } from '@apollo/client';

export const FRAGMENT_PAGE = gql`
  fragment FRAGMENT_PAGE on ShopPage {
    id
    name
    href
    group
    display
    active
    createdAt
    updatedAt
    hashtags
    rewriteUri
    sortIndex
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: PageFilterInput, $sortBy: [SorterInput!], $query: String) {
    node(id: $id) {
      id
      ... on CompanyShop {
        customDomain
        hostname
        pages(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_PAGE
          }
        }
      }
    }
  }
  ${FRAGMENT_PAGE}
`;

export const BULK_ACTION_QUERY = gql`
  mutation ($shopId: ID!, $action: PageBulkAction!, $filter: PageFilterInput!) {
    pageBulkAction(shopId: $shopId, action: $action, filter: $filter)
  }
`;
