import React, { useState } from 'react';
import { translate } from '../../../../shared/translate';
import { Box, ButtonGroup, Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import FormCard from '../../../../components/FormPageMaker/Cards/FormCard';
import FormToolbar from '../../../../components/FormToolbar';
import SelectComboShop from '../../../../components/SelectCombo/SelectComboShop';
import LSkeleton from '../../../../components/LSkeleton';
import moment from 'moment';
import Button from '@material-ui/core/Button';

export const MenuPOSDailyReport = ({ scope, ...props }) => {
  const [visible, setVisible] = useState(false);
  const { history } = scope.props;
  return (
    <>
      <MenuItem onClick={() => setVisible(true)}>{translate.pos_daily_report}</MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Formik
          initialValues={{
            shop: undefined,
            startedAt: moment().startOf('day').toISOString(),
            startedThru: moment().endOf('day').toISOString(),
          }}
          onSubmit={async ({ shop, startedAt, startedThru }) => {
            history.push(
              `/print/summaryPOS?shopId=${shop.id}&startedAt=${moment(startedAt)
                .startOf('day')
                .toISOString()}&startedThru=${moment(startedThru).endOf('day').toISOString()}`,
            );
            setVisible(false);
          }}
        >
          {(actions) => (
            <form onSubmit={actions.handleSubmit}>
              <DialogContent>
                <FormCard
                  actions={actions}
                  fields={[
                    {
                      label: translate.select_shop,
                      md: 12,
                      render: ({ loading, values, isSubmitting, setFieldValue }) => {
                        return loading ? (
                          <LSkeleton height={30} />
                        ) : (
                          <SelectComboShop
                            value={values.shop}
                            required
                            disabled={isSubmitting}
                            onChange={(v) => setFieldValue('shop', v)}
                          />
                        );
                      },
                      required: true,
                    },
                    {
                      label: translate.start_date,
                      type: 'date',
                      name: 'startedAt',
                      required: true,
                      xs: 12,
                      md: 6,
                    },
                    {
                      label: translate.end_date,
                      type: 'date',
                      name: 'startedThru',
                      required: true,
                      xs: 12,
                      md: 6,
                    },
                    {
                      render: ({ setFieldValue }) => (
                        <Box mt={-3}>
                          <ButtonGroup>
                            <Button
                              onClick={() => {
                                setFieldValue('startedAt', moment().startOf('day').toISOString());
                                setFieldValue('startedThru', moment().endOf('day').toISOString());
                              }}
                            >
                              {translate.today}
                            </Button>
                            <Button
                              onClick={() => {
                                setFieldValue('startedAt', moment().startOf('week').toISOString());
                                setFieldValue('startedThru', moment().endOf('day').toISOString());
                              }}
                            >
                              {translate.this_week}
                            </Button>
                            <Button
                              onClick={() => {
                                setFieldValue('startedAt', moment().startOf('month').toISOString());
                                setFieldValue('startedThru', moment().endOf('day').toISOString());
                              }}
                            >
                              {translate.this_month}
                            </Button>
                          </ButtonGroup>
                        </Box>
                      ),
                    },
                  ]}
                />
              </DialogContent>
              <FormToolbar
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
                submitBtnType={'submit'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default {
  render: (args) => <MenuPOSDailyReport {...args} />,
};
