import React, { useRef } from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';
import AIButton from '../../components/AIButton';
import { checkPermissionV2 } from '../../components/PermissionMask';

export default (props) => {
  const { history, location: { pathname } = {} } = props;
  const list = useRef();
  const tabs = [
      { name: translate.all_products, link: '/products' },
      { name: translate.online_products, link: '/products/listed' },
      { name: translate.offline_products, link: '/products/unlisted' },
    ],
    tab = tabs.findIndex((tab) => tab.link === pathname);

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          list.current && list.current.resetCursor();
          history.push((tabs[tab] || {}).link);
        }}
        tabs={tabs}
        extraButton={
          checkPermissionV2('autoGeneration') && (
            <AIButton height={28} fontSize={'0.85rem'} onClick={() => history.push('/products_ai')}>
              {translate.ai_generated_product}
            </AIButton>
          )
        }
      />
      <Box>
        <ListView
          {...props}
          ref={list}
          filter={{
            active: {
              0: undefined,
              1: { operator: 'IN', value: ['true'] },
              2: { operator: 'IN', value: ['false'] },
            }[tab],
          }}
        />
      </Box>
    </div>
  );
};
