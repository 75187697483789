import React from 'react';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { JSONParseSafely, NumberFormat, PriceFormat } from '../../../shared';
import EllipsisTypography from '../../../components/EllipsisTypography';
import ConnectionCardHeader from '../../../components/ConnectionPageMaker/ConnectionCardHeader';
import { Divider, Grid, Typography } from '@material-ui/core';
import { theme } from '../../../theme';
import ExportButton from '../ExportButton';
import FormHeader from '../FormHeader';
import { gql } from '@apollo/client';

class CollectionAnalyst extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_collections_analyst,
    stickyHeader: true,
    getRowLink: ({ code: id, name }) => ({
      pathname: `/sale_performance_and_data/collection_sales/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        label: translate.collection,
        title: () => (
          <Typography variant={'h6'} color={'secondary'}>
            {translate.all_categories}
          </Typography>
        ),
        render: (row) => (
          <EllipsisTypography>
            {row.name} <span style={{ fontSize: '0.9rem' }}>({row.code})</span>
          </EllipsisTypography>
        ),
      },
      {
        label: translate.sold_count,
        title: () => {
          const { totalCount } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalCount)}
            </Typography>
          );
        },
        fieldName: 'count',
        sortBy: 'count',
        type: 'number',
        align: 'right',
        width: 120,
      },
      {
        label: translate.orders,
        title: () => {
          const { totalOrder } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalOrder)}
            </Typography>
          );
        },
        fieldName: 'orderCount',
        sortBy: 'orderCount',
        type: 'number',
        align: 'right',
        width: 100,
      },
      {
        label: translate.sale_amount,
        title: () => {
          const { totalAmount } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {PriceFormat(totalAmount)}
            </Typography>
          );
        },
        fieldName: 'amount',
        sortBy: 'amount',
        type: 'price',
        align: 'right',
        width: 100,
      },
      {
        label: translate.average_price,
        title: () => {
          const { totalAvgPrice } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {PriceFormat(totalAvgPrice)}
            </Typography>
          );
        },
        fieldName: 'avgPrice',
        type: 'price',
        align: 'right',
        width: 100,
      },
      {
        label: translate.average_sold_count,
        title: () => {
          const { totalAvgCount } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalAvgCount)}
            </Typography>
          );
        },
        fieldName: 'avgCount',
        type: 'number',
        align: 'right',
        width: 120,
      },
      {
        label: translate.page_views,
        title: () => {
          const { totalPageView } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalPageView)}
            </Typography>
          );
        },
        fieldName: 'pageView',
        sortBy: 'pageView',
        type: 'number',
        align: 'right',
        width: 100,
      },
      {
        label: translate.click_rate,
        title: () => {
          const { totalHitRate } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalHitRate)}
            </Typography>
          );
        },
        fieldName: 'hitRate',
        sortBy: 'hitRate',
        type: 'number',
        align: 'right',
        width: 100,
      },
      {
        label: translate.conversion_rate,
        title: () => {
          const { totalConversionRate } = this.totalCollectionSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalConversionRate)}%
            </Typography>
          );
        },
        value: (row) => `${NumberFormat(row.conversionRate) * 100}%`,
        align: 'right',
        width: 100,
      },
    ],
    hasQSearch: true,
    qFields: ['name', 'sku'],
  };

  onCompleted(data) {
    const { node } = data || {},
      { report } = node || {},
      { totalCollectionSales } = report || {};
    this.totalCollectionSales = totalCollectionSales;
    this.setState({ ...this.state });
  }
  getSortBy() {
    const { sortBy } = this.state;
    return sortBy ? [sortBy] : [];
  }
  getSkip() {
    const { startedAt, startedThru } = this.getQueryParams();
    if (!startedAt || !startedThru) return true;
    return super.getSkip();
  }

  getQuery() {
    return GET_QUERY;
  }

  getExtraFetchVariables() {
    const { startedAt, startedThru, shop } = this.getQueryParams();
    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
      shopId: JSONParseSafely(shop)?.id,
    };
  }

  getData({ node } = {}) {
    const { report } = node || {},
      { topCollectionSales } = report || {};
    return topCollectionSales;
  }

  renderTable(...args) {
    return (
      <>
        <Divider style={{ height: 2, backgroundColor: '#999' }} />
        {super.renderTable(...args)}
      </>
    );
  }

  renderCardHeader(context) {
    return (
      <>
        <ConnectionCardHeader style={{ paddingBottom: 0, marginBottom: -theme.spacing(1) }}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <Typography variant={'h6'} color={'secondary'}>
                {translate.all_collections_data}
              </Typography>
            </Grid>
            <Grid item>
              <ExportButton
                mutation={gql`
                  mutation ($exportShopIds: [ID!], $shopId: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
                    collectionsReportExport(
                      exportShopIds: $exportShopIds
                      shopId: $shopId
                      startedAt: $startedAt
                      startedThru: $startedThru
                    ) {
                      id
                      updatedAt
                      createdAt
                      status
                      output
                    }
                  }
                `}
              />
            </Grid>
          </Grid>
        </ConnectionCardHeader>
        {super.renderCardHeader(context)}
      </>
    );
  }
  renderExtras() {
    return <FormHeader />;
  }
}

export default CollectionAnalyst;
