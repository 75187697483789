import React from 'react';
import { TableRow, makeStyles, Avatar } from '@material-ui/core';
import ConnectionTableCell from './ConnectionTableCell';
import LSkeleton from '../LSkeleton';

const useStyles = makeStyles((theme) => ({
  cell: {
    border: 'none',
  },
}));

export default ({ selectionMode, columns = [], count }) => {
  const classes = useStyles();
  return Array(count || ~~(2 + Math.random() * 5))
    .fill(undefined)
    .map((__, i) => (
      <TableRow key={i}>
        {selectionMode && (
          <ConnectionTableCell className={classes.cell} width={50}>
            <LSkeleton width={20} height={20} variant={'rect'} />
          </ConnectionTableCell>
        )}
        {columns.map(({ width, type, align = 'left', column = true } = {}, i) => {
          if (!column) return null;
          let skeleton = <LSkeleton width={`${50 + ~~(Math.random() * 50)}%`} />;
          switch (type) {
            case 'image':
            case 'images':
              skeleton = (
                <LSkeleton width={40} height={40} variant={'rect'} style={{ borderRadius: 4 }}>
                  <Avatar variant={'rounded'} />
                </LSkeleton>
              );
              break;
            default:
              break;
          }
          return (
            <ConnectionTableCell key={i} width={width} className={classes.cell}>
              {skeleton}
            </ConnectionTableCell>
          );
        })}
      </TableRow>
    ));
};
