import { gql } from '@apollo/client';

export const FRAGMENT_DELIVERY_NOTE = gql`
  fragment FRAGMENT_DELIVERY_NOTE on OrderDeliveryNote {
    id
    status
    meta
    shippingProvider {
      id
      name
      provider
    }
    order {
      id
      referenceNo
    }
    items {
      id
      quantity
      orderItem {
        addOnProduct {
          id
          sku
          unitPrice
        }
      }
    }
    address {
      person
      tel
      email
      lines
      city
      country
    }
    trackingNumber
    createdAt
    updatedAt
  }
`;

export const GET_QUERY = gql`
  query (
    $id: ID!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: DeliveryNotesFilterInput
    $query: String
  ) {
    node(id: $id) {
      id
      ... on Company {
        id
        deliveryNotes(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_DELIVERY_NOTE
          }
        }
      }
    }
  }
  ${FRAGMENT_DELIVERY_NOTE}
`;
