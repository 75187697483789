import React from 'react';
import VariantTable from './VariantTable';
import { hasCombinations } from '../hasCombinations';

export default ({ isSubmitting, setFieldValue, values: { combinations = [], variations = [] } = {} }) => {
  const hasMultipleCombinations = hasCombinations(combinations);
  if (!hasMultipleCombinations) return null;

  return (
    <VariantTable
      isSubmitting={isSubmitting}
      variations={variations}
      onChange={(variations) => setFieldValue('variations', variations)}
    />
  );
};
