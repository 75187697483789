import React from 'react';
import { escapeRegExp } from '../../../shared';
import MenuGroup from './_menuGroup';

const OmniweMenu = ({ menu = [], location, history, toggle, openIndex, onOpenIndexChange = (_) => _ }) => {
  return menu.map((group, i) => {
    const { children, name } = group || {},
      { pathname } = location || {};
    const renderChildren = children.filter(({ name, link }) => !!name && !!link);

    function matchPathname(search, pathname = '') {
      if (search === '/') {
        return pathname === '/';
      }
      const isExact = !!pathname.match(new RegExp(`^${escapeRegExp(search)}$`));
      const isChildPage = !!pathname.match(new RegExp(`^${escapeRegExp(search)}/`));
      return isExact || isChildPage;
    }

    function childSelected() {
      return (
        renderChildren.filter(({ link, querystring }) => {
          return matchPathname(link, pathname);
        }).length > 0
      );
    }

    return (
      <MenuGroup
        key={i}
        onClick={() => {
          if (!!name && renderChildren.length > 0) {
            openIndex === i ? onOpenIndexChange(-1) : onOpenIndexChange(i);
          } else {
            openIndex === i ? onOpenIndexChange(-1) : onOpenIndexChange(i);
            history.push(
              `${
                !!((renderChildren || [])[0] || {}).querystring
                  ? ((renderChildren || [])[0] || {}).link + ((renderChildren || [])[0] || {}).querystring
                  : ((renderChildren || [])[0] || {}).link
              }`,
            );
          }
        }}
        open={typeof openIndex === 'number' ? openIndex === i : childSelected()}
        {...group}
        toggle={toggle}
        location={location}
      />
    );
  });
};

export default OmniweMenu;
