import React, { useRef, useState } from 'react';
import { translate } from '../../../shared/translate';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { IMPORT_QUERY } from './query';
import s3Upload from '../../../shared/s3Upload';
import { ReactComponent as ImportIcon } from '../../../assets/icon/import.svg';
import CommonIconButton from '../../../components/CommonIconButton';

const ImportButton = ({ id }) => {
  const importInput = useRef();
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <CommonIconButton
        icon={ImportIcon}
        loading={loading}
        title={translate.import_stock_take_sheet}
        onClick={async (e) => {
          e.preventDefault();
          importInput.current.click();
        }}
      />
      <input
        ref={importInput}
        style={{ visibility: 'hidden', position: 'fixed' }}
        type={'file'}
        onChange={async (evt) => {
          setLoading(true);
          const files = evt.target.files;
          const file = files[0];
          try {
            evt.target.value = null;

            const s3Path = await s3Upload(file);
            await client.mutate({
              mutation: IMPORT_QUERY,
              variables: {
                id,
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
              },
            });
            toast.success(translate.importing_submit);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setLoading(false);
          }
        }}
      />
    </div>
  );
};

export default ImportButton;
