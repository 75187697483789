import React, { useRef, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { convertMetaObject } from '../../../../shared';
import s3Upload from '../../../../shared/s3Upload';
import { toast } from 'shared/toast';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { v4 as uuidv4 } from 'uuid';
import errorParser from '../../../../shared/errorParser';
import { Icon } from '../../../IconRender';
import { translate, translateFormat } from '../../../../shared/translate';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'inline-grid',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    minHeight: '200px',
    width: '200px',
    borderRadius: 15,
  },
  removeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  label: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      opacity: ({ disabled }) => (disabled ? 1 : 0.2),
      cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'cursor'),
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    borderRadius: 15,
  },
  content: {
    transition: 'transform 0.3s ease-in-out',
  },
  uploadIcon: {
    fontSize: '4rem',
    color: ({ disabled }) => (disabled ? '#EBEBE4' : theme.palette.primary.main),
  },
  uploadContainer: {
    width: '200px',
  },
  textAlign: {
    textAlign: 'center',
  },
  hiddenInput: {
    pointerEvents: 'none',
    opacity: '0',
    position: 'absolute',
    transform: 'translateY(-100%)',
  },
}));

const ImageField = ({ disabled, value, onChange, field = {} }) => {
  const input = useRef();
  const classes = useStyles({ disabled });
  const { metadata, name, required } = field ?? {};
  const { remarks, accept, maxFileSize } = convertMetaObject(metadata);

  const handler = useRef({ id: uuidv4() });
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (e) => {
    try {
      const selectedFile = e.target.files[0];
      if (!selectedFile) throw new Error(translateFormat(translate.err_file_size_limit, { limit: 3 }));

      if (maxFileSize && selectedFile.size > maxFileSize * 1024 * 1024)
        throw new Error(translateFormat(translate.err_file_size_limit, { limit: 3 }));

      setUploading(true);
      const url = await s3Upload(selectedFile);
      onChange(url);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = () => {
    onChange('');
    if (input.current) {
      input.current.value = null;
    }
  };

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      {!!value && (
        <Card classes={{ root: classes.card }}>
          <CardActionArea href={value} target={'_blank'}>
            <img style={{ height: 150, width: '100%', objectFit: 'contain' }} src={value} alt="Uploaded Image" />
          </CardActionArea>
          <Box classes={{ root: classes.removeBtn }}>
            <IconButton variant="contained" size={'small'} type={'button'} disabled={disabled} onClick={handleRemove}>
              <Icon icon={'Close'} type={'material'} />
            </IconButton>
          </Box>
        </Card>
      )}
      {!value && (
        <Grid container class={classes.uploadContainer}>
          <Grid item xs={12}>
            <label className={classes.label} htmlFor={handler.current.id}>
              <Card classes={{ root: classes.card }}>
                <CardContent className={classes.content}>
                  {uploading ? (
                    <CircularProgress />
                  ) : (
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={12} className={classes.textAlign}>
                        <Icon className={classes.uploadIcon} icon={'BackupRounded'} type={'material'} />
                      </Grid>
                      {!!accept && (
                        <Grid item xs={12} className={classes.textAlign}>
                          <Typography variant="caption">{accept}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </CardContent>
                {!disabled && <div className={classes.overlay} />} {/* Conditionally render overlay */}
              </Card>
            </label>
          </Grid>
          <input
            id={handler.current.id}
            type="file"
            ref={input}
            onChange={handleFileChange}
            accept={accept}
            className={classes.hiddenInput}
            required={required}
            disabled={disabled || uploading}
          />
        </Grid>
      )}
    </CustomFieldWrapper>
  );
};

export default ImageField;
