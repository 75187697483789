import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $query: String) {
    node(id: $id) {
      ... on ShopForm {
        id
        updatedAt
        createdAt
        metadata {
          key
          value
        }
        active
        metadataFields {
          key
          name
          type
          required
          values
          unique
          visible
          metadata {
            key
            value
          }
        }
        records(cursor: $cursor, limits: $limits, sortBy: $sortBy, query: $query) {
          nextCursor
          totalCount
          nodes {
            id
            updatedAt
            createdAt
            status
            customer {
              id
              email
              phoneNumber
              metadata {
                key
                value
              }
            }
            metadata {
              key
              value
            }
          }
        }
      }
    }
  }
`;
