import React from 'react';
import SaleChart from './SaleChart';
import { translate } from '../../shared/translate';
import { NumberBriefFormat } from '../../shared';

export default ({ loading, count }) => {
  return (
    <SaleChart
      loading={loading}
      title={translate[`pending_delivery_orders`]}
      amount1={NumberBriefFormat(count)}
      label1={translate['quantity']}
    />
  );
};
