import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@material-ui/core';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../FormPageMaker/Fields/InputField';
import { addValue2Metadata, convertMetaObject, metadataDeleteKey } from '../../../shared';
import SettingCommon from './SettingCommon';
import SystemButton from '../../SystemButton';
import { Icon } from '../../IconRender';
import CheckboxField from '../../FormPageMaker/Fields/CheckboxField';

const SettingNUMBER = (props) => {
  const { disabled, value, onChange = (_) => _ } = props;
  const [open, setOpen] = useState(false);
  const { metadata } = value ?? {};
  const { step, min, max } = convertMetaObject(metadata);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SettingCommon {...props} />
      </Grid>
      <Grid item xs={12}>
        <SystemButton
          variant={'text'}
          disabled={disabled}
          onClick={() => setOpen(!open)}
          color={'secondary'}
          startIcon={<Icon icon={open ? 'faChevronUp' : 'faChevronDown'} />}
        >
          {translate.constraints}
        </SystemButton>
        <Collapse in={open}>
          <Box p={2} mt={2} bgcolor={'#F5F5F5'}>
            <Grid container spacing={2}>
              <FormRow dense title={translate.minimum} md={3}>
                <InputField
                  type={'number'}
                  inputProps={{
                    step: 1,
                  }}
                  value={min}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      metadata: addValue2Metadata('min', e.target.value, metadata),
                    })
                  }
                />
              </FormRow>
              <FormRow dense title={translate.maximum} md={3}>
                <InputField
                  type={'number'}
                  inputProps={{
                    step: 1,
                  }}
                  value={max}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      metadata: addValue2Metadata('max', e.target.value, metadata),
                    })
                  }
                />
              </FormRow>
              <FormRow dense title={''} md={12}>
                <CheckboxField
                  checked={step === 1}
                  onChange={() =>
                    onChange({
                      ...value,
                      metadata:
                        step === 1 ? metadataDeleteKey('step', metadata) : addValue2Metadata('step', 1, metadata),
                    })
                  }
                  text={translate.use_integer}
                />
              </FormRow>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default SettingNUMBER;
