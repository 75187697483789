import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { translate } from '../../shared/translate';
import FormCard from '../../components/FormPageMaker/Cards/FormCard';

const ExtendField = (actions) => {
  return (
    <FormCard
      actions={actions}
      name={translate.member_extend_condition}
      fields={[
        {
          md: 6,
          label: translate.member_extend_type,
          type: 'select',
          options: [
            {
              label: translate.single_shopping,
              value: 'SINGLE_PURCHASE',
            },
            {
              label: translate.shopping_within_the_specified_period,
              value: 'SPECIFIC_PERIOD',
            },
          ],
          name: 'extensionType',
          required: true,
        },
        {
          md: 6,
          label: translate.amount,
          type: 'number',
          inputProps: {
            step: 0.001,
            min: 0,
          },
          name: 'extensionAmount',
          required: true,
          display: ({ values }) => values.extensionType === 'SINGLE_PURCHASE',
          InputProps: {
            startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
          },
        },
        {
          md: 6,
          display: ({ values }) => values.extensionType === 'SPECIFIC_PERIOD',
        },
        {
          md: 6,
          display: ({ values }) => values.extensionType === 'SPECIFIC_PERIOD',
          label: translate.valid_for,
          name: 'extensionMonths',
          type: 'number',
          required: true,
          inputProps: {
            step: 1,
            min: 1,
          },
          InputProps: {
            startAdornment: (
              <InputAdornment position={'start'}>{translate.within_x_month.split('{0}')[0]}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position={'end'}>{translate.within_x_month.split('{0}').pop()}</InputAdornment>
            ),
          },
        },
        {
          md: 6,
          label: translate.total_consumption,
          type: 'number',
          inputProps: {
            step: 0.001,
            min: 0,
          },
          name: 'extensionAmount',
          required: true,
          display: ({ values }) => values.extensionType === 'SPECIFIC_PERIOD',
          InputProps: {
            startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
          },
        },
      ]}
    />
  );
};

export default ExtendField;
