import React from 'react';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import CommonIconButton from '../../../components/CommonIconButton';
import { ReactComponent as IconTrash } from '../../../assets/icon/trash.svg';
import { withStyles } from '@material-ui/core';
import SelectComboVariation from '../../../components/SelectCombo/SelectComboVariation';
import { ItemsTable as ReceivePurchaseItemsTable } from '../../item_ReceivePurchase/ItemsCard/ItemsTable';
import { removeBtnStyle } from '../../../theme';
import PropTypes from 'prop-types';

const useStyles = (theme) => ({
  removeBtn: removeBtnStyle,
});
class ItemsTable extends ReceivePurchaseItemsTable {
  state = {
    ...this.state,
    fields: [
      {
        title: `${translate.product} *`,
        width: 350,
        value: (item) => {
          const { productVariation, add, sku, __row } = item;
          const { cursor = 0 } = this.state;
          const { items, onChange, disabled } = this.props;
          return (
            <SelectComboVariation
              disablePortal={false}
              id={!!add && 'add'}
              value={!add ? productVariation : null}
              placeholder={sku}
              showSubtitle={false}
              key={items?.length}
              onChange={(variation) => {
                if (!!add) {
                  const addField = document.getElementById('add');
                  onChange(
                    (items || [])?.concat({
                      productVariation: variation,
                      sku: variation?.sku,
                      quantity: 1,
                    }),
                  );
                  return addField.blur();
                }
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          productVariation: variation ? variation : undefined,
                          sku: variation ? variation?.sku : undefined,
                        }
                      : item,
                  ),
                );
              }}
              disabled={disabled}
            />
          );
        },
      },
      {
        title: `${translate.quantity}`,
        width: 120,
        align: 'center',
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, disabled } = this.props;
          const { sku, quantity, __row } = item || {};
          return (
            <InputField
              type={'number'}
              // eslint-disable-next-line
              inputProps={{
                min: 1,
                step: 1,
                style: { textAlign: 'center' },
              }}
              disabled={!sku || disabled}
              value={quantity}
              onChange={(e) => {
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          quantity: e.target.value,
                        }
                      : item,
                  ),
                );
              }}
              required={!item?.add}
            />
          );
        },
      },
      {
        title: '',
        width: 76,
        align: 'center',
        render: ({ add, __row }) => {
          const { cursor = 0 } = this.state;
          const { disabled, items, onChange } = this.props;
          if (!!add) {
            return <></>;
          } else {
            return (
              <CommonIconButton
                isListViewButton={false}
                color={'secondary'}
                icon={IconTrash}
                disabled={disabled}
                onClick={() => onChange(items.filter((__, i) => i !== cursor + __row))}
              />
            );
          }
        },
      },
    ],
  };
}

ItemsTable.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
ItemsTable.defaultProps = {
  items: [],
  onChange: (_) => _,
};

export default withStyles(useStyles)(ItemsTable);
