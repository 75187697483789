import React from 'react';
import AddButton from './AddButton';
import Dnd from '../DND';
import FieldSettingField, { types } from './FieldSettingField';
import { Box, Card, Grid } from '@material-ui/core';
import { Icon } from '../IconRender';
import { ReactComponent as IconDragVertical } from '../../assets/icon/drag-vertical.svg';

const CustomFormField = ({
  onlyAllowFieldTypes,
  useFieldKey = true,
  disabled,
  value: fields = [],
  enableFillInWhenRegisteringOption = false,
  onChange = (_) => _,
  loading,
  header,
}) => {
  return (
    <>
      <Dnd
        direction={'column'}
        items={fields ?? []}
        spacing={3}
        disabled={disabled}
        onChange={onChange}
        renderItem={(item, i, { nonDraggableProps }) => {
          return (
            <Card>
              <Grid container>
                <Grid item>
                  <Box
                    height={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    p={2}
                    style={{
                      cursor: 'pointer',
                      borderRight: `1px solid #e1e1e1`,
                    }}
                  >
                    <Icon icon={IconDragVertical} type={'svg'} />
                  </Box>
                </Grid>
                <Grid item xs {...nonDraggableProps}>
                  <FieldSettingField
                    onlyAllowFieldTypes={onlyAllowFieldTypes}
                    useFieldKey={useFieldKey}
                    value={item}
                    disabled={disabled}
                    enableFillInWhenRegisteringOption={enableFillInWhenRegisteringOption}
                    onChange={(item) => {
                      if (item) onChange(fields.map((field, j) => (j === i ? item : field)));
                      else onChange(fields.filter((__, j) => j !== i));
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          );
        }}
        header={header}
      >
        <AddButton
          disabled={disabled || loading}
          onClick={() => {
            const [defaultType] = onlyAllowFieldTypes?.length
              ? types.filter((opt) => onlyAllowFieldTypes.includes(opt.value))
              : types;

            onChange(
              (fields ?? []).concat({
                metadata: [],
                key: '',
                name: '',
                visible: true,
                unique: false,
                type: defaultType?.value,
                required: false,
                values: [],
              }),
            );
          }}
        />
      </Dnd>
    </>
  );
};

export default CustomFormField;
