import { gql } from '@apollo/client';

export const FRAGMENT_RETURN_NOTE = gql`
  fragment FRAGMENT_RETURN_NOTE on ShopReturnNote {
    id
    updatedAt
    createdAt
    active
    metadata {
      key
      value
    }
    shop {
      id
      name
    }
    referenceNo
    order {
      id
      referenceNo
      shop {
        id
        name
      }
    }
    status
    remark
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: ReturnNoteFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Company {
        returnNotes(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_RETURN_NOTE
          }
        }
      }
    }
  }
  ${FRAGMENT_RETURN_NOTE}
`;
