import React, { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import ColorBlock from './ColorBlock';
import _ from 'lodash';

const VariationCombinationDisplay = ({
  typographyVariant,
  combination = [],
  remark: remarkStr = '',
  parseColorCode = true,
  direction = 'row',
  style,
  typographyStyle,
}) => {
  const remarks =
    remarkStr
      ?.split('\n')
      .map((str) => {
        const tmp = str.split(/:{1,}/).map((s) => s.trim());
        return { name: tmp[0], option: tmp[1] };
      })
      ?.filter((c) => c?.name && c?.option) ?? [];

  return (
    <Grid
      container
      alignItems={
        {
          row: 'center',
          column: 'flex-start',
        }[direction]
      }
      spacing={
        {
          row: 1,
          column: 0,
        }[direction]
      }
      direction={direction}
    >
      {_.uniqBy(combination.concat(remarks), (c) => `${c.name}: ${c.option}`).map(({ name, option }, i) => {
        if (parseColorCode && /^#[a-f0-9]{3,6}$/i.test(option))
          return (
            <Fragment key={i}>
              <Grid item>
                <Box style={{ ...style }}>
                  <Typography variant={typographyVariant} style={{ ...typographyStyle }}>
                    {name}:
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box style={{ ...style }}>
                  <ColorBlock color={option} />
                </Box>
              </Grid>
            </Fragment>
          );
        return (
          <Grid key={i} item>
            <Box style={{ ...style }}>
              <Typography variant={typographyVariant} style={{ ...typographyStyle }}>
                {name}: {option}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VariationCombinationDisplay;
