import { Box, Dialog, DialogContent, Grid, InputAdornment, Typography, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import FormRow from '../../components/FormRow';
import SelectCombo from '../../components/SelectCombo';
import { CompanyShopType } from '../../shared/enum';
import { translate } from '../../shared/translate';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import useBreakPoint from '../../components/useBreakPoint';
import { gql, useQuery } from '@apollo/client';
import { client } from '../../shared/apollo';
import SystemButton from '../../components/SystemButton';
import { useHistory } from 'react-router-dom';

const UPDATE = gql`
  mutation ($id: ID!, $input: CompanyShopInput!) {
    companyShopSet(id: $id, input: $input) {
      id
      name
      type
      hostname
    }
  }
`;

const GET_STATUS = gql`
  query ($shopId: ID!) {
    node(id: $shopId) {
      id
      ... on CompanyShop {
        name
        type
        hostname
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  paper: { paddingLeft: 20, paddingRight: 20 },
}));

const Initialize = () => {
  const classes = useStyles();
  const breakpoint = useBreakPoint();
  const isMobileView = !!~['xs'].indexOf(breakpoint);
  const history = useHistory();

  const { loading, data: { node } = {} } = useQuery(GET_STATUS, {
    variables: { shopId: localStorage.getItem('shopId') },
    fetchPolicy: 'no-cache',
  });

  const { id, name, hostname, type } = node || {};

  return (
    <Dialog open={true} PaperProps={{ style: { maxWidth: 500, minWidth: 300 } }}>
      <DialogContent className={isMobileView ? classes.paper : undefined}>
        <Box my={4}>
          <Grid container spacing={2} style={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <img src={require('../../assets/OmniweLogo.png')} alt={'Logo'} height={60} />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: '#000', fontSize: '1.5rem', fontWeight: 'bold' }}>
                {translate.registration_successful || '註冊成功！'}
              </Typography>
              <Typography style={{ color: '#000', fontSize: '1.5rem', fontWeight: 'bold' }}>
                {translate.fill_in_information || '填寫基本資訊建立商店'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Formik
                key={`f-${loading}`}
                enableReinitialize={false}
                initialValues={{ name, type, hostname: (hostname || '').replace(/\.omniwe\.site$/, '') }}
                onSubmit={async ({ name, type, hostname }, { setSubmitting }) => {
                  try {
                    await client.mutate({
                      mutation: UPDATE,
                      variables: {
                        id,
                        input: { name, type, domain: hostname },
                      },
                    });
                    history.push('/');
                  } catch (e) {
                    toast.error(errorParser(e));
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit} style={{ maxWidth: 500 }}>
                      <Grid container spacing={1}>
                        <FormRow
                          title={translate.shop_name || '商店名稱'}
                          required
                          style={{ textAlign: 'left' }}
                          titleStyle={{ color: '#000' }}
                        >
                          <InputField
                            required
                            disabled={isSubmitting}
                            value={values.name}
                            placeholder={translate.please_input_shop_name || '請輸入您的商店名稱'}
                            onChange={(e) => {
                              setFieldValue('name', e.target.value);
                            }}
                          />
                        </FormRow>
                        <FormRow
                          title={translate.shop_type_new || '商店類型'}
                          required
                          style={{ textAlign: 'left' }}
                          titleStyle={{ color: '#000' }}
                        >
                          <SelectCombo
                            options={CompanyShopType}
                            value={CompanyShopType.find((opt) => opt.value === values.type)}
                            onChange={(opt) => {
                              setFieldValue('type', opt?.value);
                            }}
                            disabled={isSubmitting}
                            required={true}
                            placeholder={translate.select_shop_type || '請選擇商店類型'}
                          />
                        </FormRow>
                        <FormRow
                          title={translate.shop_domain || '商店網址'}
                          remarks={translate.not_modifiable_after_setup || '設定後不可更改'}
                          required
                          style={{ textAlign: 'left' }}
                          titleStyle={{ color: '#000' }}
                        >
                          <InputField
                            fullWidth
                            required
                            disabled={isSubmitting}
                            value={values.hostname}
                            onChange={(e) => {
                              setFieldValue('hostname', e.target.value);
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              minLength: 5,
                              maxLength: 32,
                              pattern: '^[a-zA-Z0-9\\-_]{5,32}$',
                            }}
                            // eslint-disable-next-line
                            InputProps={{
                              startAdornment: <InputAdornment position={'start'}>https://</InputAdornment>,
                              endAdornment: <InputAdornment position={'end'}>.omniwe.site</InputAdornment>,
                            }}
                          />
                          <small className="text-muted">
                            {translate.name_restriction || '網址必須為英文或數字，且不能少於5個字或超過25個字'}
                          </small>
                        </FormRow>
                        <Grid item xs={12}>
                          <Box my={2}>
                            <SystemButton
                              fullWidth
                              disabled={isSubmitting}
                              loading={isSubmitting}
                              type={'submit'}
                              color={'secondary'}
                              style={{ borderRadius: 9999 }}
                            >
                              {translate.create_shop || '建立商店'}
                            </SystemButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Initialize;
