import { gql } from '@apollo/client';

export const FRAGMENT_ProductModifier = gql`
  fragment FRAGMENT_ProductModifier on ShopProductModifier {
    id
    createdAt
    updatedAt
    active
    name
    productCount
    sortIndex
    code
    metadata {
      key
      value
    }
    metadataFields {
      key
      name
      type
      required
      values
      unique
      visible
      metadata {
        key
        value
      }
    }
    modifiers {
      name
      min
      max
      options {
        name
        priceAdjustment
      }
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_ProductModifier}
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_ProductModifier
    }
  }
`;

export const CREATE_QUERY = gql`
  ${FRAGMENT_ProductModifier}
  mutation ($input: ShopProductModifierCreateInput!) {
    result: productModifierCreate(input: $input) {
      id
      ...FRAGMENT_ProductModifier
    }
  }
`;

export const UPDATE_QUERY = gql`
  ${FRAGMENT_ProductModifier}
  mutation ($id: ID!, $input: ShopProductModifierUpdateInput!) {
    result: productModifierUpdate(id: $id, input: $input) {
      id
      ...FRAGMENT_ProductModifier
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    result: productModifierDelete(id: $id) {
      id
    }
  }
`;

// For AddToCategoryListView
export const FRAGMENT_PRODUCT = gql`
  fragment FRAGMENT_PRODUCT on ShopProduct {
    id
    name
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
    }
    active
    createdAt
    updatedAt
    basePrice
    maxPrice
    sku
    variations {
      id
      ignoreStock
      quantity
    }
    collections {
      nodes {
        id
      }
    }
  }
`;

export const GET_MODIFER_PRODUCTS = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $query: String, $filter: ProductFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      ... on ShopProductModifier {
        id
        products(cursor: $cursor, limits: $limits, query: $query, sortBy: $sortBy, filter: $filter) {
          totalCount
          nextCursor
          nodes {
            id
            sku
            name
            publishAt
            publishThru
            basePrice
            maxPrice
            medias {
              src
              optimizedSrc(width: 256, height: 256)
            }
            sku
            variations {
              id
              ignoreStock
              quantity
            }
            collections {
              nodes {
                id
                active
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const AddToModifier_GET_QUERY = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ProductFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        products(query: $query, cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_PRODUCT
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`;

export const MODIFIER_PRODUCT_ADD_QUERY = gql`
  mutation modifierProductsAdd($id: ID!, $productIds: [ID!]!) {
    modifierProductsAdd(id: $id, productIds: $productIds) {
      id
    }
  }
`;

export const MODIFIER_PRODUCT_REMOVE_QUERY = gql`
  mutation modifierProductsRemove($id: ID!, $productIds: [ID!]!) {
    modifierProductsRemove(id: $id, productIds: $productIds) {
      id
    }
  }
`;
