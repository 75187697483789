import React from 'react';
import s3Upload from 'shared/s3Upload';
import { Editor } from '@tinymce/tinymce-react';
import { translate } from '../shared/translate';

export default ({
  id,
  innerRef,
  value,
  onChange,
  plugins = 'paste code image textcolor colorpicker media link',
  toolbar = 'code | undo redo | blocks fontsize lineheight removeformat | bold italic strikethrough underline | forecolor backcolor | alignleft aligncenter alignright | link customLinkButton image media',
  disabled = false,
  height,
  ...props
}) => {
  return (
    <Editor
      id={id}
      apiKey={'untv8v314y3vgpl2f1gb80psa9kuwlgp3bfovux8laauc132'}
      ref={innerRef}
      value={value}
      disabled={disabled}
      init={{
        ui_mode: 'split',
        selector: 'textarea',
        content_style: `body { font-size: 14px; }`,
        height: height || 500,
        menubar: false,
        branding: false,
        readonly: disabled,
        plugins,
        convert_urls: false,
        images_upload_handler: async (blobInfo) => {
          return await s3Upload(blobInfo.blob());
        },
        font_size_formats: '12px 14px 16px 18px 20px 24px 36px 42px 64px 72px',
        toolbar,
        setup: (editor) => {
          editor.ui.registry.addButton('customLinkButton', {
            text: translate.add_button,
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: translate.add_button,
                body: {
                  type: 'panel',
                  items: [
                    {
                      type: 'input',
                      name: 'button_label',
                      label: translate.label,
                      flex: true,
                    },
                    {
                      type: 'input',
                      name: 'button_href',
                      label: translate.href,
                      flex: true,
                    },
                    {
                      type: 'selectbox',
                      name: 'button_target',
                      label: translate.target,
                      items: [
                        { text: translate.new_window, value: '_blank' },
                        { text: translate.self, value: '_self' },
                      ],
                      flex: true,
                    },
                    {
                      type: 'selectbox',
                      name: 'button_text_font_size',
                      label: translate.font_size,
                      items: [
                        { text: '12px', value: '12px' },
                        { text: '14px', value: '14px' },
                        { text: '16px', value: '16px' },
                        { text: '18px', value: '18px' },
                        { text: '20px', value: '20px' },
                        { text: '24px', value: '24px' },
                        { text: '36px', value: '36px' },
                        { text: '42px', value: '42px' },
                        { text: '64px', value: '64px' },
                        { text: '72px', value: '72px' },
                      ],
                    },
                    {
                      type: 'colorinput',
                      name: 'button_background_color',
                      label: translate.background_color,
                      flex: true,
                    },
                    {
                      type: 'colorinput',
                      name: 'button_border_color',
                      label: translate.border_color,
                      flex: true,
                    },
                    {
                      type: 'colorinput',
                      name: 'button_text_color',
                      label: translate.text_color,
                    },
                  ],
                },
                onSubmit: function (api) {
                  const buttonBorderColor = api.getData().button_border_color;
                  const buttonBackgroundColor = api.getData().button_background_color;
                  const buttonTextColor = api.getData().button_text_color;
                  const buttonHref = api.getData().button_href;

                  const buttonStyle = `border-radius: 5px; border: 1px solid ${
                    buttonBorderColor === 'none()' ? 'transparent' : buttonBorderColor
                  }; background-color: ${buttonBackgroundColor === 'none()' ? 'transparent' : buttonBackgroundColor};`;

                  const textStyle = `text-decoration: none; color: ${
                    buttonTextColor === 'none()' ? 'transparent' : buttonTextColor
                  }; font-size: ${api.getData().button_text_font_size}`;

                  let href = buttonHref;
                  switch (/^\/(?!\/)/.test(buttonHref)) {
                    case true:
                      href = buttonHref;
                      break;
                    case false:
                      href = /^https?:\/\//.test(buttonHref) ? buttonHref : 'https://' + buttonHref;
                      break;
                  }

                  const html =
                    '<button style="' +
                    buttonStyle +
                    '"><a style="' +
                    textStyle +
                    '" href="' +
                    href +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    '&nbsp;' +
                    api.getData().button_label +
                    '&nbsp;' +
                    '</a></button>';

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: translate.close_popup,
                    type: 'cancel',
                    onclick: 'close',
                  },
                  {
                    text: translate.insert,
                    type: 'submit',
                    primary: true,
                  },
                ],
              });
            },
          });
        },
        ...props,
      }}
      onEditorChange={onChange}
      {...props}
    />
  );
};
