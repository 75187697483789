import React from 'react';
import StyledConfigList from '../../components/StyledConfigList';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import FormRow from '../../components/FormRow';
import { translate } from '../../shared/translate';

const RemarkCard = ({ values: { remark } = {} }) => {
  return (
    <Card elevation={0}>
      <CardContent>
        <FormRow title={`${translate.remark} (${translate.auto_generated})`}>
          <StyledConfigList>
            <Grid item xs={12}>
              <Typography variant={'h4'} align={'center'} style={{ fontWeight: 'bold' }}>
                {remark || ''}
              </Typography>
            </Grid>
          </StyledConfigList>
        </FormRow>
      </CardContent>
    </Card>
  );
};

export default RemarkCard;
