import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { client } from '../../../../shared/apollo';
import toast from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { gql } from '@apollo/client';

const ORDER_EXPORT = gql`
  mutation ($id: ID!) {
    shopFormRecordsExport(id: $id) {
      id
    }
  }
`;

const MenuExport = {
  name: translate.export_all,

  onClick: async function () {
    try {
      ActivityIndicator.show();
      await client.mutate({
        mutation: ORDER_EXPORT,
        variables: {
          id: this.props?.match?.params?.id,
        },
      });
      toast.success(translate.export_tips);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  },
};

export default MenuExport;
