import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query($id: ID!, $limits: Int) {
    node(id: $id) {
      id
      ... on CompanyShop {
        pages(
          limits: $limits
          filter: {
            display: { operator: IN, value: ["true"] }
            active: { operator: IN, value: ["true"] }
            group: { operator: IN, value: ["articles"] }
          }
          sortBy: [{ field: "createdAt", order: DESC }]
        ) {
          nodes {
            id
            name
            body
          }
        }
      }
    }
  }
`;
