import React from 'react';
import { translate } from '../shared/translate';
import { NumberFormat, PriceFormat } from '../shared';
import Mustache from 'mustache';
import moment from 'moment/moment';

const ReceiptTemplatePreviewer = ({ variables, extraRenderer = {} }) => {
  const paperWidth =
    {
      '58mm': 300,
      '80mm': 350,
      a5: 500,
      a4: 700,
    }[variables?.paper] || 350;

  return (
    <div
      style={{
        width: paperWidth,
        background: 'white',
        margin: 'auto',
        padding: 16,
      }}
    >
      {variables?.bodyItems?.map((item) => {
        const type = Object.keys(item).find((key) => /^is[A-Z]/.test(key));
        if (extraRenderer[type]) {
          return extraRenderer[type](variables, item) || <></>;
        }

        switch (type) {
          case 'isItems':
            return <></>;
          case 'isLogo':
            return !!variables?.shop?.rasterLogoMedia?.src ? (
              <div style={{ textAlign: 'center', padding: 16 }}>
                <img
                  style={{ display: 'inline', width: '50%', aspectRatio: 2 }}
                  src={variables?.shop?.rasterLogoMedia?.src}
                  alt={variables?.shop?.name}
                />
              </div>
            ) : (
              <div style={{ textAlign: 'center', padding: 16 }}>
                <span style={{ fontSize: '1.5em' }}>No Logo</span>
              </div>
            );
          case 'isVoidedMark':
            return (
              <>
                <PHR text={'-'} />
                <div style={{ textAlign: 'center', fontSize: '1.5em' }}>{translate.void}</div>
                <PHR text={'-'} />
              </>
            );
          case 'isReprintMark':
            return (
              <>
                <PHR text={'-'} />
                <div style={{ textAlign: 'center', fontSize: '1.5em' }}>{translate.reprint || '重印'}</div>
                <PHR text={'-'} />
              </>
            );
          case 'isTakeawayMark':
            return (
              <div style={{ fontSize: '1.5em', textAlign: 'center' }}>-----{translate.takeaway || '外賣'}-----</div>
            );
          case 'isDivider':
            return <PHR text={item?.text ?? '-'} />;
          case 'isText':
            return (
              <div
                style={{
                  whiteSpace: 'pre-line',
                  fontSize: { 1: '1em', 2: '2em', 3: '3em' }[item?.size] || '1em',
                  textAlign: item?.align || 'left',
                  fontWeight: item?.bold ? 'bold' : undefined,
                }}
              >
                {MustacheRender(item?.text || '', variables)}
              </div>
            );
          case 'isTitle':
            return (
              <div
                style={{
                  whiteSpace: 'pre-line',
                  fontSize: { 1: '1em', 2: '2em', 3: '3em' }[item?.size] || '1.5em',
                  textAlign: item?.align || 'center',
                  fontWeight: item?.bold ? 'bold' : undefined,
                }}
              >
                {MustacheRender(item?.text || '', variables)}
              </div>
            );
          case 'isLV':
            return (
              <LV
                bold={item?.bold}
                size={item?.size}
                label={MustacheRender(item?.label || '', variables)}
                value={MustacheRender(item?.value || '', variables)}
              />
            );
          case 'isOrderData':
            return (
              <div>
                <LV label={translate.sub_total} size={item?.size} value={PriceFormat(variables.subtotal)} />
                {variables.totalAdjustments?.map((adj, i) => (
                  <LV key={i} label={adj.description} size={item?.size} value={PriceFormat(adj.amount)} />
                ))}
                <LV label={translate.total} size={item?.size} value={PriceFormat(variables.total)} />
              </div>
            );
          case 'isCut':
            return <div className={'page-break'} style={{ margin: '8px -16px', borderTop: '1px dashed #000' }} />;
          case 'isQRCode':
            const qrcode = MustacheRender(item?.text || '', variables);
            return (
              <div style={{ textAlign: 'center', paddingTop: 8, paddingBottom: 8 }}>
                <img
                  alt={qrcode}
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrcode}`}
                  style={{ display: 'inline' }}
                />
              </div>
            );
          case 'isHTML': {
            const html = MustacheRender(item?.text || '', variables);
            return <div dangerouslySetInnerHTML={{ __html: html }} className={`html-wrapper`} />;
          }
          default:
            return <></>;
        }
      })}
    </div>
  );
};

function MustacheRender(text, variables) {
  try {
    return Mustache.render(text || '', {
      ...variables,
      Indexing: function () {
        return function (text, render) {
          return `${render(text) - 0 + 1}`.padStart(2, '0');
        };
      },
      DateFormat: function () {
        return function (text, render) {
          return moment(render(text)).format('YYYY-MM-DD HH:mm:ss');
        };
      },
      NumberFormat: function () {
        return function (text, render) {
          return NumberFormat(render(text));
        };
      },
      PriceFormat: function () {
        return function (text, render) {
          return PriceFormat(render(text), variables?.currency);
        };
      },
    });
  } catch (e) {
    return text;
  }
}

export default ReceiptTemplatePreviewer;

export const PHR = ({ text }) => (
  <div style={{ textWrap: 'nowrap', overflow: 'hidden' }}>
    {Array(200)
      .fill(text ?? '-')
      .join('')}
  </div>
);

export const LV = ({ size, label, value, bold }) => (
  <div
    style={{
      fontWeight: bold ? 'bold' : undefined,
      fontSize: { 1: '1em', 2: '2em', 3: '3em' }[size] || '1em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
  >
    <div>{label}</div>
    <div>{value}</div>
  </div>
);
