import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { GET_QUERY, POST_QUERY } from './query';
import { JSONParseSafely, convertMetaArray, convertMetaObject } from '../../shared';
import { client } from '../../shared/apollo';
import GateSettingCard from './GateSettingCard';
import { Typography } from '@material-ui/core';
import { translate } from '../../shared/translate';

// const checkOverlap = (arr) => {
//   const overlappingObjects = [];
//   for (let i = 0; i < arr.length; i++) {
//     for (let j = i + 1; j < arr.length; j++) {
//       // Check if the two objects have the same 'day' value
//       if (arr[i].day === arr[j].day) {
//         // Convert the 'startedAt' and 'startedThru' strings to Date objects
//         const startedAt1 = new Date(arr[i].startedAt);
//         const startedThru1 = new Date(arr[i].startedThru);
//         const startedAt2 = new Date(arr[j].startedAt);
//         const startedThru2 = new Date(arr[j].startedThru);

//         // Check if the two time ranges overlap
//         if (startedAt1 < startedThru2 && startedThru1 > startedAt2) {
//           overlappingObjects.push({ obj1: arr[i], obj2: arr[j], index1: i + 1, index2: j + 1 }); // Overlapping time ranges found!
//         }
//       }
//     }
//   }
//   return overlappingObjects;
// };

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
    },
    tabs: [
      {
        name: translate.terminal_setting || '閘機設定',
        formDisabled: true,
        cards: [
          {
            fields: [
              {
                label: translate.points_setting || '積分設定',
                render: () => (
                  <Typography>
                    {translate.prioritized_in_order_listed ||
                      '請注意，規則的優先級是按順序排列的，愈前面的規則會先生效。'}
                  </Typography>
                ),
              },
              { render: (actions) => <GateSettingCard {...actions} /> },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    // const overlapArr = checkOverlap(values.gateSetting);

    const overlapArr = values.gateSetting;

    console.log('overlapArr', overlapArr);

    // if (overlapArr.length > 0) {
    //   const overlappingIndex = overlapArr.map((obj) => {
    //     return `Rule ${obj.index1} and Rule ${obj.index2}`;
    //   });
    //   const errorMsg = `Found overlapping time ranges: ${overlappingIndex}`;
    //   throw new Error(errorMsg);
    // }

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          name: values.name,
          metadata: convertMetaArray({
            ...values.metadata,
            gateSetting: JSON.stringify(values.gateSetting),
          }),
        },
      },
    });
  };

  getInitialData({ node }) {
    const { name, metadata } = node || {};

    const { gateSetting, ..._metadata } = convertMetaObject(metadata);
    return {
      name,
      metadata: _metadata,
      gateSetting: JSONParseSafely(gateSetting, []),
    };
  }
  onCancelClick = undefined;
}
