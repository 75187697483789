import React from 'react';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/eft-logo.png')} alt="eft" />,
    label: 'EFT 線下支付',
    value: 'EFT_POS',
  },
  form: (actions) => {
    return null;
  },
  type: 'PAYMENT',
};
