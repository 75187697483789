import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import ActivityItem from './ActivityItem';
import Connector from './Connector';

const ActivityChain = ({ loading, items = [] }) => {
  if (loading)
    return (
      <Grid container direction={'column'}>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <ActivityItem loading />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 0 }}>
          <Connector />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <ActivityItem loading />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 0 }}>
          <Connector />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <ActivityItem loading />
        </Grid>
      </Grid>
    );
  return (
    <Grid container direction={'column'}>
      {(items || []).map((item, i) => (
        <Fragment key={i}>
          {i > 0 && (
            <Grid item xs={12} style={{ zIndex: 0 }}>
              <Connector />
            </Grid>
          )}
          <Grid item xs={12} style={{ zIndex: 1 }}>
            <ActivityItem item={item} />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

export default ActivityChain;
