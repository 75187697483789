import React, { useState } from 'react';
import { translate } from '../../../../shared/translate';
import ImportContainer from './ImportContainer';
import TabsCardDialog from '../../../../components/TabsCardDialog';
import { MenuItem } from '@material-ui/core';

const ImportModal = ({ onClick = (_) => _ }) => {
  const [visible, setVisible] = useState(false);

  const tabs = [
    { name: translate.bulk_create_or_update_product_data },
    { name: translate.bulk_adjust_product_data },
    { name: translate.import_product_SEO_related_fields },
  ];

  return (
    <>
      <MenuItem
        onClick={() => {
          onClick();
          setVisible(!visible);
        }}
        onOpen={() => setVisible(!visible)}
      >
        {translate.import}
      </MenuItem>
      <TabsCardDialog
        visible={visible}
        onOpen={() => setVisible(!visible)}
        tabs={tabs.map((tab, i) => ({
          label: tab.name,
          content: <ImportContainer tab={i} onClick={() => setVisible(false)} />,
        }))}
        PaperProps={{ padding: '60px 0' }}
      />
    </>
  );
};

export default ImportModal;
