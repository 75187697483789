import { useEffect, useRef, useState } from 'react';

const DragListener = ({ onDelta = (_) => _, onEnd = (_) => _ }) => {
  const handler = useRef({ x: 0, y: 0 });
  const [delta, setDelta] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (delta.x || delta.y) {
      onDelta(delta);
      setDelta({ x: 0, y: 0 });
    }
  }, [delta.x, delta.y]);

  useEffect(() => {
    function onMouseMove(e) {
      setDelta({
        x: e.clientX - (handler.current.x || e.clientX),
        y: e.clientY - (handler.current.y || e.clientY),
      });
      handler.current.x = e.clientX;
      handler.current.y = e.clientY;
    }
    function onMouseUp(e) {
      handler.current.x = 0;
      handler.current.y = 0;
      onEnd();
    }
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, []);
  return null;
};

export default DragListener;
