import { sortBy } from 'lodash';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import { GET_SALE_RECORD_QUERY } from './query';

class SalesRecordsList extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_products_data,
    gql: {
      get: GET_SALE_RECORD_QUERY,
    },
    fields: [
      {
        title: translate.date,
        fieldName: 'createdAt',
        type: 'datetime',
        sortBy: 'createdAt',
      },
      {
        title: translate.shop_name,
        fieldName: 'shopName',
      },

      {
        title: translate.order_number,
        fieldName: 'referenceNo',
      },
      {
        title: translate.quantity,
        align: 'center',
        fieldName: 'count',
      },
      {
        title: translate.amount,
        align: 'right',
        fieldName: 'amount',
        type: 'price',
      },
    ],
  };

  getExtraFetchVariables() {
    const { startedAt, startedThru } = this.getQueryParams();
    const { id } = this.props;

    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
      memberTierId: id,
    };
  }

  getData(data) {
    return data.node.report.memberTierSales.salesRecords;
  }
}

export default SalesRecordsList;
