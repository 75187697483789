import { useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { JSONParseSafely } from '../shared';
const { SSE } = require('./sse');

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      # id
      ... on CompanyShop {
        credentials(filterV2: { platforms: [OPENAI] }) {
          id
          metadata {
            key
            value
          }
          active
        }
      }
    }
  }
`;

const OPENAI_API = 'https://api.openai.com';
const apiKey = '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';

const useAI = ({ onDelta } = {}) => {
  const [messages, setMessages] = useState();
  const source = useRef(undefined);
  useEffect(() => {
    if (messages?.length) {
      source.current = new SSE(`${OPENAI_API}/v1/chat/completions`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + apiKey,
        },
        method: 'POST',
        payload: JSON.stringify({
          stream: true,
          model: 'gpt-3.5-turbo',
          messages: messages.map((content) => ({
            role: 'system',
            content,
            name: 'omniwe',
          })),
        }),
      });
      source.current.addEventListener('message', onMessage);
      source.current.stream();

      return () => {
        source.current.removeEventListener('message', onMessage);
        source.current = undefined;
      };

      function onMessage(e) {
        const payload = JSONParseSafely(e.data);

        const delta = payload?.choices?.[0]?.delta?.content;
        const stop = payload?.choices?.[0]?.finish_reason === 'stop';
        if (onDelta) {
          onDelta(delta || '', stop);
        }
      }
    }
  }, [messages]);

  return {
    available: !!apiKey,
    request: async (messages = []) => {
      setMessages(messages);
      await fetch(`${OPENAI_API}/v1/chat/completions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stream: true,
          model: 'gpt-3.5-turbo',
          messages: messages.map((content) => ({
            role: 'system',
            content,
            name: 'omniwe',
          })),
        }),
      });
    },
  };
};

export default useAI;
