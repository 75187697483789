import { gql } from '@apollo/client';

export const FRAGMENT_SHOP_SHIPPING_ZONE = gql`
  fragment ShopShippingZone on ShopShippingZone {
    id
    active
    name
    provider
    countries
    sortIndex
    expressions {
      maxWeight
      unit
      unitPrice
    }
    createdAt
    updatedAt
    addresses {
      id
      name
      person
      tel
      email
      lines
      city
      country
      sortIndex
    }
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOP_SHIPPING_ZONE}
  query ($id: ID!) {
    node(id: $id) {
      ...ShopShippingZone
    }
  }
`;

export const POST_QUERY = gql`
  ${FRAGMENT_SHOP_SHIPPING_ZONE}
  mutation ($id: ID, $input: ShopShippingZoneInput!) {
    result: shopShippingZoneSet(id: $id, input: $input) {
      id
      ...ShopShippingZone
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    result: shopShippingZoneUnset(id: $id) {
      id
    }
  }
`;
