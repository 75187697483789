import { gql } from '@apollo/client';

export const FRAGMENT_SITEMAP = gql`
  fragment FRAGMENT_SITEMAP on ShopSitemap {
    id
    createdAt
    updatedAt
    active
    name
    url
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on Company {
        sitemaps {
          nodes {
            ...FRAGMENT_SITEMAP
          }
        }
      }
    }
  }
  ${FRAGMENT_SITEMAP}
`;

export const CREATE_QUERY = gql`
  mutation ($input: ShopSitemapInput!) {
    sitemapCreate(input: $input) {
      ...FRAGMENT_SITEMAP
    }
  }
  ${FRAGMENT_SITEMAP}
`;

export const ACTIVATE_QUERY = gql`
  mutation ($id: ID!) {
    sitemapActivate(id: $id) {
      ...FRAGMENT_SITEMAP
    }
  }
  ${FRAGMENT_SITEMAP}
`;

export const DELETE_QUERY = gql`
  mutation ($id: ID!) {
    sitemapDelete(id: $id) {
      id
    }
  }
`;
