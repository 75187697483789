import { gql } from '@apollo/client';

export const FRAGMENT_NAVIGATION_TEMPLATE = gql`
  fragment FRAGMENT_NAVIGATION_TEMPLATE on ShopNavigationTemplate {
    id
    updatedAt
    createdAt
    active
    name
    type
    configs
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $type: String) {
    node(id: $id) {
      ... on CompanyShop {
        navigationTemplates(filter: { type: { operator: IN, value: [$type] } }) {
          nodes {
            ...FRAGMENT_NAVIGATION_TEMPLATE
          }
        }
      }
    }
  }
  ${FRAGMENT_NAVIGATION_TEMPLATE}
`;

export const CREATE_QUERY = gql`
  mutation ($input: NavigationTemplateCreateInput!) {
    navigationTemplateCreate(input: $input) {
      ...FRAGMENT_NAVIGATION_TEMPLATE
    }
  }
  ${FRAGMENT_NAVIGATION_TEMPLATE}
`;
export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: NavigationTemplateUpdateInput!) {
    navigationTemplateUpdate(id: $id, input: $input) {
      ...FRAGMENT_NAVIGATION_TEMPLATE
    }
  }
  ${FRAGMENT_NAVIGATION_TEMPLATE}
`;
