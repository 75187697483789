import { gql } from '@apollo/client';
export const FRAGMENT_STOCK_ADJUSTMENT = gql`
  fragment FRAGMENT_STOCK_ADJUSTMENT on CompanyStockAdjustment {
    id
    createdAt
    updatedAt
    metadata {
      key
      value
    }
    completedAt
    device {
      id
      name
    }
    staff {
      id
      name
    }
    warehouse {
      id
      name
      type
    }
    referenceNo
    remark
    status
    totalQuantity
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: StockAdjustmentFilterInput) {
    node(id: $id) {
      id
      ... on Company {
        id
        stockAdjustments(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_STOCK_ADJUSTMENT
          }
        }
      }
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT}
`;
