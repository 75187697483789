import React, { useState } from 'react';
import { Box, Dialog, Hidden, Tab, Tabs, Typography, makeStyles } from '@material-ui/core';
import { activeTabColor, inactiveTabColor, optionColor, textColor } from '../theme';
import SelectField from './FormPageMaker/Fields/SelectField';
import useBreakPoint from './useBreakPoint';

const useStyles = makeStyles((theme) => ({
  tabBorderRadius: {
    borderRadius: '13px 13px 0 0',
  },
}));

const TabsCardDialog = ({
  tabs = [],
  onOpen,
  visible,
  PaperProps = { width: 750, padding: '60px 0' },
  contentStyle = {},
}) => {
  const classes = useStyles();
  const breakPoint = useBreakPoint();
  const isUnderSM = ['xs'].includes(breakPoint);

  const [currentTab, setCurrentTab] = useState(0);

  if (!tabs.length) return null;

  return (
    <>
      <Dialog
        open={visible}
        onClose={() => onOpen()}
        PaperProps={{
          style: {
            borderRadius: 13,
            boxShadow: 'none',
            maxWidth: '750px',
            maxHeight: '700px',
            backgroundColor: isUnderSM ? undefined : 'transparent',
            margin: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            ...PaperProps,
            padding: isUnderSM ? 0 : PaperProps.padding,
          },
        }}
      >
        {!!tabs?.length && tabs?.length > 1 && (
          <Hidden xsDown>
            <Tabs>
              {tabs.map(({ label }, i) => (
                <Tab
                  key={i}
                  label={
                    <Typography
                      style={{
                        color: currentTab === i ? textColor : optionColor,
                        fontWeight: currentTab === i ? 'bold' : undefined,
                      }}
                    >
                      {label}
                    </Typography>
                  }
                  className={classes.tabBorderRadius}
                  style={{
                    backgroundColor: currentTab === i ? activeTabColor : inactiveTabColor,
                    boxShadow: currentTab === i ? '0px 1px 15px 0px #0000001A' : undefined,
                    borderLeft: currentTab === i ? `1px solid ${activeTabColor}` : inactiveTabColor,
                    borderTop: currentTab === i ? `1px solid ${activeTabColor}` : inactiveTabColor,
                    borderRight: currentTab === i ? `1px solid ${activeTabColor}` : inactiveTabColor,
                    padding: '8px 20px',
                    minWidth: '190px !important',
                    opacity: 1,
                    textTransform: 'none',
                  }}
                  onClick={() => setCurrentTab(i)}
                />
              ))}
            </Tabs>
          </Hidden>
        )}
        {!!tabs?.length && tabs?.length > 1 && (
          <Hidden smUp>
            <SelectField
              style={{ backgroundColor: '#fff', padding: '5px 32px', borderRadius: '13px 13px 0 0' }}
              value={currentTab}
              variant={'standard'}
              size={'medium'}
              margin={'normal'}
              // eslint-disable-next-line
              InputProps={{
                disableUnderline: true,
                style: { color: textColor, fontWeight: 'bold' },
              }}
              onChange={(e) => {
                const value = e.target.value;
                setCurrentTab(value);
              }}
              options={tabs.map(({ label }, i) => ({ label: label, value: i }))}
            />
          </Hidden>
        )}
        <Box
          style={{
            backgroundColor: '#fff',
            width: '100%',
            flex: 1,
            borderRadius: 13,
            borderTopLeftRadius: tabs?.length > 1 ? 0 : 13,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            ...contentStyle,
          }}
        >
          {tabs[currentTab]?.content}
        </Box>
      </Dialog>
    </>
  );
};

export default TabsCardDialog;
