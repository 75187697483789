import React, { useState } from 'react';
import { Button, ButtonGroup, withStyles } from '@material-ui/core';
import { Icon } from 'components/IconRender';
import { translate } from '../shared/translate';
import { ReactComponent as IconTrash } from '../assets/icon/trash.svg';
import { OmniewRedButton } from './SystemButton';

export default ({ onClick, disabled, title = translate.delete, children, reverse }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const confirmButton = open && (
    <OmniewRedButton type={'button'} onClick={onClick} disabled={disabled} style={{ minWidth: 90 }}>
      {title}
    </OmniewRedButton>
  );

  return (
    <ButtonGroup variant={'contained'} color={'secondary'}>
      {reverse && confirmButton}
      <OmniewRedButton type={'button'} disabled={disabled} onClick={toggle}>
        {children || <Icon type={'svg'} icon={IconTrash} viewBox="0 0 20 25" />}
      </OmniewRedButton>
      {!reverse && confirmButton}
    </ButtonGroup>
  );
};
