import { Box, Grid } from '@material-ui/core';
import React from 'react';
import ProductItemPreview from './ProductItemPreview';

const TooltipContent = ({ points = [], spacing = 1, src }) => {
  return (
    <Box p={1}>
      <Grid container spacing={spacing}>
        {!!src && (
          <Grid item xs={12}>
            <ProductItemPreview box={[123, 5, 50, 17]} src={src} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ul>
            {points.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TooltipContent;
