import { gql } from '@apollo/client';

export const CHECKOUT_QUERY = gql`
  mutation ($id: ID, $input: CheckoutInput!) {
    checkoutSet(id: $id, input: $input) {
      id
      shop {
        id
        hostname
        customDomain
      }
    }
  }
`;

export const CHECKOUT_ITEM_CREATE = gql`
  mutation ($id: ID!, $input: [CheckoutItemCreateInput!]!) {
    checkoutItemsCreate(id: $id, input: $input) {
      id
      updatedAt
      createdAt
      status
    }
  }
`;
