import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import SelectCombo from './index';
import _ from 'lodash';
import { parseConnection, parseOSSQuery } from '../../shared';

const SEARCH_QUERY = gql`
  query ($id: ID!, $query: String) {
    node(id: $id) {
      id
      ... on CompanyShop {
        forms(query: $query) {
          nextCursor
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

const SelectComboForm = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { loading, data, fetchMore } = useQuery(SEARCH_QUERY, {
      variables: {
        id: localStorage.getItem('shopId'),
        query: parseOSSQuery(search),
        sortBy: [{ field: 'updatedAt', order: 'DESC' }],
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);
  function getConnection(data) {
    const { node } = data || {};
    return parseConnection((node || {}).forms);
  }

  return (
    <SelectCombo
      onOpen={setOpen}
      getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
      onSearchChange={setSearch}
      getOptionLabel={(option) => option?.name ?? ''}
      options={nodes || []}
      loading={loading}
      onScrollBottom={async () => {
        if (!!nextCursor && !loading)
          await fetchMore({
            variables: {
              cursor: nextCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              const fetchMoreConnection = getConnection(fetchMoreResult),
                { nodes: fetchMoreNodes } = fetchMoreConnection,
                { nodes: prevNodes } = getConnection(prevResult);
              fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

              return {
                node: {
                  ...(fetchMoreResult.node || {}),
                  forms: fetchMoreConnection,
                },
              };
            },
          });
      }}
      {...props}
    />
  );
};

export default SelectComboForm;
