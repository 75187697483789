import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';

const ActivityIcon = ({ createdAt }) => {
  const theme = useTheme();
  return (
    <Box
      bgcolor={theme.palette.secondary.main}
      color={theme.palette.secondary.contrastText}
      borderRadius={99}
      width={50}
      height={50}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography color={'inherit'}>{moment(createdAt).format('MMM')}</Typography>
      <Typography color={'inherit'} variant={'body2'}>
        {moment(createdAt).format('DD')}
      </Typography>
    </Box>
  );
};

export default ActivityIcon;
