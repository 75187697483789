import React from 'react';
import { IMPORT_QUERY } from './query';
import CommonImportButton from '../../components/CommonImportButton';
import { translate } from '../../shared/translate';

const TransferImportButton = ({ id }) => {
  return (
    <CommonImportButton
      id={id}
      query={IMPORT_QUERY}
      name={translate.bulk_inventory_transfer}
      tips={[translate.bulk_import_product_data_tips_one, translate.bulk_import_product_data_tips_two]}
      demoFile={'https://omniwe-assets-pro.s3.ap-east-1.amazonaws.com/TXeXuaEUjLaixrDNxOEI6.csv'}
    />
  );
};

export default TransferImportButton;
