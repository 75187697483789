import { gql } from '@apollo/client';

export const FRAGMENT_MEMBER_METADATA_FIELD = gql`
  fragment FRAGMENT_MEMBER_METADATA_FIELD on CompanyMemberMetadataField {
    key
    name
    type
    required
    values
    unique
    visible
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        name
        memberMetadataFields {
          ...FRAGMENT_MEMBER_METADATA_FIELD
        }
      }
    }
  }
  ${FRAGMENT_MEMBER_METADATA_FIELD}
`;

export const SUBMIT_QUERY = gql`
  mutation ($id: ID!, $input: CompanyShopInput!) {
    companyShopSet(id: $id, input: $input) {
      id
      memberMetadataFields {
        ...FRAGMENT_MEMBER_METADATA_FIELD
      }
    }
  }
  ${FRAGMENT_MEMBER_METADATA_FIELD}
`;
