import React, { Fragment, useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { Icon } from 'components/IconRender';
import ComponentDrawer from './ComponentDrawer';

const useStyles = makeStyles((theme) => ({
  buttonRegion: {
    position: 'relative',
    borderLeft: '1px dashed #ccc',
    width: 1,
    transition: 'all 0.25s',
    transitionTimingFunction: 'cubic-bezier(0.49,0, 0.45, 1.51)',
    '&:hover, &.active': {
      width: 100,
      borderRight: '1px dashed #ccc',
    },
    '&:hover .addButton, &.active .addButton': {
      opacity: 1,
    },
    '&:hover .hoverHelper': {
      left: -10,
      top: -10,
      right: -10,
      bottom: -10,
    },
  },
  buttonRegionCol: {
    position: 'relative',
    height: 1,
    marginTop: -1,
    marginBottom: -1,
    transition: 'all 0.25s',
    transitionTimingFunction: 'cubic-bezier(0.49,0, 0.45, 1.51)',
    '&:hover, &.active': {
      height: 100,
      borderLeft: '1px dashed #ccc',
      borderRight: '1px dashed #ccc',
    },
    '&:hover .addButton, &.active .addButton': {
      opacity: 1,
    },
    '&:hover .hoverHelper': {
      left: -10,
      top: -10,
      right: -10,
      bottom: -10,
    },
  },
  buttonWrapper: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.25s',
    transitionTimingFunction: 'cubic-bezier(0.49,0, 0.45, 1.51)',
    padding: 0,
  },
  hoverHelper: {
    // backgroundColor: 'rgba(255,0,0,0.5)',
    position: 'absolute',
    left: -5,
    top: -5,
    right: -5,
    bottom: -5,
  },
  button: {
    width: '100%',
    height: '100%',
    backgroundColor: '#deeef9',
  },
}));

export default ({ style, col, expand, onComponentAdd = (_) => _ }) => {
  const [open, setOpen] = useState(false),
    toggle = () => setOpen(!open);
  const classes = useStyles();
  return (
    <Fragment>
      <div
        style={style}
        className={`${col ? classes.buttonRegionCol : classes.buttonRegion} ${expand || open ? 'active' : ''}`}
      >
        <div className={`hoverHelper ${classes.hoverHelper}`} />
        <div className={`${classes.buttonWrapper} addButton`}>
          <ButtonBase className={classes.button} onClick={toggle}>
            <Icon icon={'Add'} type={'material'} />
          </ButtonBase>
        </div>
      </div>
      <ComponentDrawer open={open} toggle={toggle} onComponentAdd={onComponentAdd} />
    </Fragment>
  );
};
