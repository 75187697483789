import React from 'react';
import Taber from '../../components/Taber';
import ListShippingZone from './ListShippingZone';
import { translate } from '../../shared/translate';

export default (props) => {
  return (
    <div>
      <Taber
        // currentTab={tab}
        tabs={[
          {
            name: translate.all_delivery_settings || '所有送貨設定',
          },
        ]}
      />
      <ListShippingZone {...props} />
    </div>
  );
};
