import React from 'react';
import FormPageMaker from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import { cloneObject, removeTypename } from '../../shared';
import uuid from 'react-uuid';
import { InputAdornment, Typography } from '@material-ui/core';
import StockField from '../item_Product/StockField';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.addon_products || '加購產品',
        cards: [
          {
            fields: [
              {
                label: translate.product_name,
                type: 'text',
                name: 'name',
                maxLength: 50,
                required: true,
              },
              {
                label: translate.product_sku,
                type: 'text',
                name: 'sku',
                maxLength: 50,
                required: true,
                md: 6,
              },
              {
                label: translate.barcode,
                type: 'text',
                name: 'barcode',
                maxLength: 50,
                md: 6,
              },
              {
                label: translate.stock,
                md: 6,
                required: true,
                render: ({ values, loading, isSubmitting, setFieldValue }) => {
                  return (
                    <StockField
                      loading={loading}
                      disabled={isSubmitting}
                      value={{
                        quantity: values.quantity,
                        ignoreStock: values.ignoreStock,
                      }}
                      onChange={(update) => {
                        setFieldValue('quantity', update.quantity);
                        setFieldValue('ignoreStock', update.ignoreStock);
                      }}
                    />
                  );
                },
              },
              {
                label: translate.weight,
                name: 'weight',
                type: 'number',
                placeholder: '0',
                md: 6,
                inputProps: {
                  min: 0,
                  step: 0.001,
                },
                InputProps: {
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                },
              },
              {
                label: translate.cost,
                name: 'cost',
                type: 'number',
                placeholder: '0',
                md: 12,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
              },
              {
                label: translate.valid_online_date_time,
                md: 6,
                type: 'datetime-local',
                name: 'publishAt',
                description: translate.shelf_time_description,
              },
              {
                label: translate.valid_offline_date_time,
                md: 6,
                type: 'datetime-local',
                name: 'publishThru',
                description: translate.shelf_time_description,
                hasPermanent: true,
              },
              {
                label: translate.description,
                type: 'html',
                name: 'description',
                maxLength: 255,
              },
              {
                label: translate.cross_selling_condition,
                type: 'number',
                name: 'amount',
                required: true,
                md: 4,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position={'start'}>{translate.discount_condition_subtotal} $</InputAdornment>
                  ),
                },
              },
              {
                label: translate.cross_selling_price,
                description: translate.price_description,
                name: 'unitPrice',
                type: 'number',
                required: true,
                placeholder: '0.00',
                md: 4,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.purchaseLimit,
                type: 'number',
                name: 'purchaseLimit',
                required: true,
                md: 4,
                inputProps: {
                  min: 1,
                  step: 1,
                },
              },
              {
                md: 12,
                render: () => {
                  return <Typography>{translate.addon_remind || '*加購品不適用於折扣範圍內'}</Typography>;
                },
              },
              {
                label: translate.hash_tag,
                description: translate.hash_tag_description || '系統會自動在輸入內容前加上 "#" 符號，請直接輸入內容',
                name: 'hashtags',
                type: 'hashTagsArray',
                md: 9,
              },
              {
                label: translate.image,
                type: 'medias',
                name: 'medias',
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const input = {
      active: !!values.active,
      shopId: localStorage.getItem('shopId'),

      /* hardcode AMOUNT */
      type: 'AMOUNT',
      name: values.name,
      description: values.description,
      amount: values.amount,
      sku: values.sku,
      unitPrice: values.unitPrice,
      ignoreStock: !!values.ignoreStock,
      quantity: values.quantity,
      purchaseLimit: values.purchaseLimit,
      barcode: values.barcode,
      cost: values.cost,
      weight: values.weight,
      publishAt: values.publishAt,
      publishThru: values.publishThru,
      hashtags: values.hashtags,
      medias: values.medias.map((_media) => {
        const media = _media || {};
        return {
          id: media.id,
          src: media.src,
          alt: media.alt,
        };
      }),
    };

    await client.mutate({
      mutation: submit,
      variables: { id, input, warehouseId: localStorage.getItem('stockWarehouseId') },
    });

    return true;
  };

  getExtraFetchVariables() {
    return {
      warehouseId: localStorage.getItem('stockWarehouseId'),
    };
  }

  getInitialData = (data) => {
    const { node } = data || {},
      {
        name = '',
        description = '',
        active = true,
        amount = 0,
        sku = '',
        unitPrice = 0,
        quantity = 0,
        purchaseLimit = 1,
        ignoreStock,
        barcode = '',
        cost = 0,
        weight = 0,
        publishAt,
        publishThru,
        medias,
        updatedAt,
        hashtags = [],
      } = node || {};

    return {
      name,
      description,
      active,
      amount,
      sku,
      unitPrice,
      quantity,
      purchaseLimit,
      ignoreStock,
      barcode,
      cost,
      weight,
      publishAt,
      publishThru,
      medias: removeTypename(cloneObject(medias || [])).map((media) => ({
        ...media,
        alt: media.alt || '',
        id: media.id || uuid(),
      })),
      updatedAt,
      hashtags: cloneObject(hashtags || []),
    };
  };
}
