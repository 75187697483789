import React from 'react';
import DeliveryNoteListView from '../../list_DeliveryNote/ListView';
import { GET_QUERY } from './query';
import DeliveryNoteButton from '../StatusCard/DeliveryNoteButton';

class DeliveryNoteCard extends DeliveryNoteListView {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    selectionMode: false,
    refreshable: false,
    menus: [],
    hasQSearch: false,
    hasLegacyFilter: false,
    qFields: [],
  };

  getExtraQueryOptions() {
    const { values: { orderId } = {} } = this.props;
    return {
      skip: !orderId,
    };
  }

  getExtraFetchVariables() {
    const { values: { orderId } = {} } = this.props;
    return {
      id: orderId,
    };
  }

  getData({ node } = {}) {
    const { deliveryNotes } = node || {};
    return deliveryNotes;
  }

  renderContent({ loading, ...props }) {
    const { values: { orderId } = {} } = this.props;
    return super.renderContent({ ...props, loading: loading || !orderId });
  }

  renderCreateButton({ nodes, totalCount, nextCursor }) {
    const { values, loading, isSubmitting } = this.props;
    return <DeliveryNoteButton values={values} loading={loading} isSubmitting={isSubmitting} />;
  }
}

export default DeliveryNoteCard;
