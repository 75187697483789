import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export default () => {
  return (
    <Box p={2} overflow={'hidden'}>
      <Grid container>
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <Grid key={i} item xs={12}>
              <Grid container justify={i % 2 === 0 ? 'flex-start' : 'flex-end'}>
                <Grid item>
                  <Skeleton width={250} height={50} />
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
