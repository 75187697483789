import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { translate } from 'shared/translate';
import { Formik } from 'formik';
import FormToolbar from './FormToolbar';

export default class PromisifyModal extends Component {
  state = {
    show: false,
    resolve: undefined,
    reject: undefined,
  };

  /* You Need to overwrite this function */
  getInitialData = (args) => {
    return { ...args };
  };

  /* You Need to overwrite this function */
  onSubmit = async (values) => {
    return values;
  };

  /* You Need to overwrite this function */
  onCancel = async () => {
    return new Error('');
  };

  /* You Need to overwrite this function */
  renderHeader({ values }) {
    return <DialogTitle></DialogTitle>;
  }

  /* You Need to overwrite this function */
  renderBody({ values }) {
    return <DialogContent></DialogContent>;
  }

  execute = (...args) => {
    return new Promise((resolve, reject) => {
      const init = this.getInitialData(...args);
      this.setState({ resolve, reject, show: true, init });
    });
  };

  onCancelClick = async () => {
    const { reject } = this.state;
    reject && reject(await this.onCancel());
    this.setState({ show: false, reject: undefined, resolve: undefined });
  };

  onFormSubmit = async (values, actions) => {
    const { reject, resolve } = this.state;
    const { setSubmitting } = actions;
    try {
      resolve && resolve(await this.onSubmit(values, actions));
    } catch (e) {
      reject && reject(e);
    } finally {
      setSubmitting(false);
      this.setState({ show: false, resolve: undefined, reject: undefined });
    }
  };

  getDialogProps() {
    return {};
  }

  render() {
    const { show } = this.state;
    return (
      <Dialog open={show} {...this.getDialogProps()} PaperProps={{ square: true }}>
        {this.renderFormik()}
      </Dialog>
    );
  }
  renderFormik() {
    const { init } = this.state;
    return (
      <Formik initialValues={init} enableReinitialize={true} onSubmit={this.onFormSubmit}>
        {this.renderForm.bind(this)}
      </Formik>
    );
  }
  renderForm(actions) {
    const { handleSubmit } = actions;
    return (
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(e);
        }}
      >
        {this.renderHeader(actions)}
        {this.renderBody(actions)}
        {this.renderFooter(actions)}
      </form>
    );
  }

  renderFooter({ isSubmitting }) {
    return (
      <FormToolbar
        disabled={isSubmitting}
        submitBtnType={'submit'}
        onCancel={this.onCancelClick}
        cancelText={this.renderCancelText()}
        confirmText={this.renderConfirmText()}
      />
    );
  }
  renderCancelText() {
    return translate.cancel;
  }
  renderConfirmText() {
    return translate.submit;
  }
}
