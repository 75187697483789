import { gql } from '@apollo/client';

export const FRAGMENT_LIST_APPOINTMENT = gql`
  fragment FRAGMENT_LIST_APPOINTMENT on ShopAppointment {
    id
    createdAt
    updatedAt
    active
    referenceNo
    remark
    startedAt
    startedThru
    status
    attendanceStatus
    orderItem {
      id
      description
      unitPrice
      remark
    }
    location {
      id
      name
    }
    metadata {
      key
      value
    }
    service {
      id
      name
    }
    serviceBundle {
      id
      name
    }
    contactAddress {
      person
      tel
      email
    }
    order {
      id
      referenceNo
      createdAt
    }
  }
`;

export const GET_QUERY = gql`
  query ($cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: ShopAppointmentFilterInput) {
    appointments(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) @client {
      nextCursor
      totalCount
      nodes {
        ...FRAGMENT_LIST_APPOINTMENT
      }
    }
  }
  ${FRAGMENT_LIST_APPOINTMENT}
`;

export const UPDATE_REMARK_QUERY = gql`
  mutation ($id: ID!, $input: AppointmentUpdateInput!) {
    appointmentUpdate(id: $id, input: $input) {
      id
      remark
      status
    }
  }
`;

export const CANCEL_QUERY = gql`
  mutation ($id: ID!) {
    appointmentCancel(id: $id) {
      id
      status
    }
  }
`;

export const ATTEND_QUERY = gql`
  mutation ($id: ID!) {
    appointmentAttend(id: $id) {
      id
      attendanceStatus
    }
  }
`;

export const NO_SHOW_QUERY = gql`
  mutation ($id: ID!) {
    appointmentNoShow(id: $id) {
      id
      attendanceStatus
    }
  }
`;
