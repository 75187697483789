import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { parseConnection } from '../../shared';
import _ from 'lodash';
import SelectCombo from './index';
import { Skeleton } from '@material-ui/lab';

export const SEARCH_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      id
      ... on Company {
        staffs(limits: $limits, cursor: $cursor) {
          nextCursor
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

const SelectComboStaff = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const { loading, data, fetchMore } = useQuery(SEARCH_QUERY, {
      variables: {
        id: localStorage.getItem('companyId'),
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);

  function getConnection(data) {
    const { node } = data || {};
    return parseConnection((node || {}).staffs);
  }

  return (
    <>
      {props.loading ? (
        <Skeleton height="30px" />
      ) : (
        <SelectCombo
          onOpen={setOpen}
          getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
          getOptionLabel={(option) => option?.name ?? ''}
          options={nodes}
          loading={loading}
          onScrollBottom={async () => {
            if (!!nextCursor && !loading)
              await fetchMore({
                variables: {
                  cursor: nextCursor,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const fetchMoreConnection = getConnection(fetchMoreResult),
                    { nodes: fetchMoreNodes } = fetchMoreConnection,
                    { nodes: prevNodes } = getConnection(prevResult);
                  fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

                  return {
                    node: {
                      ...(fetchMoreResult.node || {}),
                      staffs: fetchMoreConnection,
                    },
                  };
                },
              });
          }}
          {...props}
        />
      )}
    </>
  );
};

export default SelectComboStaff;
