import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { SUBMIT_QUERY, GET_QUERY, DELETE_QUERY } from './query';
import { client } from '../../shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
      remove: DELETE_QUERY,
    },
    tabs: [
      {
        name: translate.subscriptions_list || '訂閱列表',
        cards: [
          {
            fields: [
              {
                label: translate.subscription_contact,
                type: 'text',
                name: 'contact',
                required: true,
                maxLength: 255,
              },
              {
                label: translate.subscription_type,
                type: 'select',
                options: [
                  { label: translate.push_notification, value: 'PUSH' },
                  { label: translate.send_sms, value: 'SMS' },
                  { label: translate.send_email, value: 'EMAIL' },
                ],
                name: 'type',
                required: true,
                description: ({ values }) =>
                  ({
                    PUSH: translate.push_notification_remark,
                    SMS: translate.send_sms_remark,
                  }[values.actionType] || undefined),
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          contact: values.contact,
          shopId: localStorage.getItem('shopId'),
          type: values.type,
        },
      },
    });
    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {},
      { contact = '', type } = node || {};
    return { contact, type };
  };
}
