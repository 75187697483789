import React from 'react';
import { Grid } from '@material-ui/core';
import FormPageMaker from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import ReportDetail from '../ReportDetail';
import { PriceFormat } from '../../../shared';
import LineChartCard from '../LineChartCard';
import SalesRecordsList from './SalesRecordsList';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: { prepare: GET_QUERY },
    hasQSearch: true,
    disableToolBar: true,
    tabs: [
      {
        name: translate.salesperson_analyst,
        disabledBackground: true,
        noPadding: true,
        cards: [
          {
            render: ({ values, loading, ...props }) => {
              const { name, amount, count, orderCount, avgPrice, avgCount, avgAmount, salesTrends = [] } = values || {};
              const cols = [
                { label: translate.sale_amount, value: PriceFormat(amount) || 0 },
                { label: translate.sold_count, value: count || 0 },
                { label: translate.average_price, value: PriceFormat(avgPrice || 0) },
                { label: translate.average_sale_amount || '平均銷售額', value: avgCount || 0 },
                { label: translate.average_sale_sold_amount || '平均賣出數量', value: PriceFormat(avgAmount || 0) },
                { label: translate.order_count, value: orderCount || 0 },
              ];
              const datasets = salesTrends.map(({ name, data = [] }) => ({
                label: name,
                data: data.map(({ value, name: _name }) => ({ x: _name, y: value })),
              }));

              return (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReportDetail cols={cols} label={name} loading={loading} />
                  </Grid>
                  <Grid item md={12}>
                    <LineChartCard loading={loading} label={translate.sale_trend} datasets={datasets} />
                  </Grid>
                </Grid>
              );
            },
          },
        ],
      },
      {
        name: translate.sales_records,
        noPadding: true,
        cards: [
          {
            disabledBackground: true,
            render: () => {
              return <SalesRecordsList {...this.state} {...this.props} />;
            },
          },
        ],
      },
    ],
  };
  disableToolBar() {
    return false;
  }

  getExtraFetchVariables() {
    const { startedAt, startedThru } = this.getQueryParams();
    const { id } = this.state;

    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
      salespersonId: id,
    };
  }

  getInitialData = ({ node }) => {
    const { name, amount, count, orderCount, avgPrice, avgCount, avgAmount, salesTrends } =
      ((node || {}).report || {}).salespersonSales || {};

    return {
      name,
      amount,
      count,
      orderCount,
      avgPrice,
      avgCount,
      avgAmount,
      salesTrends,
    };
  };
}
