import React from 'react';
import SystemButton from '../../components/SystemButton';

const SnapButton = ({ active, children, onClick }) => {
  return (
    <SystemButton
      variant={active ? 'contained' : 'outlined'}
      color={active ? 'secondary' : undefined}
      onClick={onClick}
      style={{ minWidth: 'auto' }}
    >
      {children}
    </SystemButton>
  );
};

export default SnapButton;
