import React from 'react';
import { Typography, Box, withStyles } from '@material-ui/core';
import TextareaField from '../../../components/FormPageMaker/Fields/TextareaField';

const styles = (theme) => ({
  RequirementStepBox: {
    padding: '10px 20px',
    // height: '450px',
  },
  stepTitleBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },
  stepTitle: {
    color: '#1F4C6D',
    fontSize: '18px',
    fontWeight: '500',
  },
  textareaBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    // margin: '70px 0px',
    // minHeight: '270px',
  },
  textarea: {
    padding: theme.spacing(1.5),
    width: '100%',
    height: '100%',
    borderColor: '#fff',
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.40)',
    outline: 'none',
    '&:hover': {
      borderColor: '#000',
    },
    '&:focus': {
      borderColor: '#245fcc',
      borderWidth: 2,
    },
  },
});

const ChatGptRequirementStep = ({ classes, value, onChange }) => {
  return (
    <Box className={classes.RequirementStepBox}>
      <Box className={classes.stepTitleBox}>
        <Typography className={classes.stepTitle}>請輸入其他條件</Typography>
      </Box>
      <Box className={classes.textareaBox}>
        <TextareaField value={value} onChange={(v) => onChange(v)} />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ChatGptRequirementStep);
