import React from 'react';
import {
  Avatar,
  Badge,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { translate } from '../../../../../shared/translate';
import friendlyTime from 'friendly-time';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const ContainAvatar = withStyles(() => ({
  img: {
    objectFit: 'contain',
  },
}))(Avatar);

export const SystemAvatar = () => (
  <ContainAvatar alt={'System'} src={require('../../../../../assets/src_omniwe-logo.svg')} />
);

const MessageItem = ({ message, onClick }) => {
  const history = useHistory();
  const { type, content, createdAt, channel, read, customer } = message || {},
    { type: channelType, id: channelId } = channel || {},
    { name, picture } = customer || {};
  const theme = useTheme();

  const textStyle = {
    fontWeight: read ? undefined : 'bold',
    color: read ? undefined : theme.palette.secondary.main,
  };

  return (
    <MenuItem
      alignItems={'flex-start'}
      onClick={() => {
        history.push(
          {
            ORDER: `/messages/${channelId}`,
            GENERAL: `/messages/${channelId}`,
            SYSTEM: `/system_messages`,
          }[channelType],
        );
        if (onClick) onClick();
      }}
    >
      <ListItemAvatar>
        <Badge
          overlap={'circular'}
          variant={'dot'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          invisible={read}
          color="secondary"
        >
          {channelType !== 'SYSTEM' ? (
            <Avatar alt={name} src={picture}>
              {name}
            </Avatar>
          ) : (
            <SystemAvatar />
          )}
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant={'caption'} style={textStyle}>
              {channelType === 'SYSTEM' ? translate.system_message : name}
            </Typography>
            <Tooltip title={content}>
              <Typography className={'two-lines-clamped'} style={{ maxWidth: 425, ...textStyle }}>
                {{
                  IMAGE: `[image]`,
                  VIDEO: `[video]`,
                  FILE: `[file]`,
                }[type] || content}
              </Typography>
            </Tooltip>
          </>
        }
        secondary={
          <Typography variant={'caption'} style={textStyle}>
            {friendlyTime(moment(createdAt).toDate())}
          </Typography>
        }
      />
    </MenuItem>
  );
};

export default MessageItem;
