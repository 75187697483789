import { gql } from '@apollo/client';

export const FRAGMENT_COUPON = gql`
  fragment CompanyCoupon on CompanyCoupon {
    name
    handle
    resolveCode
    sortIndex
    description
    repeat
    remark
    enabled
    metadata {
      key
      value
    }
    media {
      src
      optimizedSrc(width: 256, height: 256)
    }
    triggers {
      triggerType
      triggerId
      triggerIds
      triggerValueType
      triggerValue
    }
    actions {
      actionType
      actionId
      actionIds
      actionValueType
      actionValue
    }
    publishAt
    publishThru
    publishLimit
    memberLimit
    active
    display
    membersOnly
    validAt
    validThru
    validForPeriod
    discardSubsequent
    applyCode
    isRedeemable
    memberPointCost
    excludedProductIds
    excludedCoupons(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) @client
    excludedDiscounts(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) @client
    userCoupons {
      totalCount
      nodes {
        id
        handle
        status
        createdAt
      }
    }
  }
`;
export const GET_QUERY = gql`
  ${FRAGMENT_COUPON}
  query ($id: ID!) {
    node(id: $id) {
      id
      updatedAt
      createdAt
      ...CompanyCoupon
    }
  }
`;

export const COUPON_SUBMIT = gql`
  ${FRAGMENT_COUPON}
  mutation ($id: ID, $input: CouponInput!, $subCouponInput: SubCouponInput) {
    companyCouponSet(id: $id, input: $input, subCouponInput: $subCouponInput) {
      id
      ...CompanyCoupon
    }
  }
`;

export const COUPON_REMOVE = gql`
  mutation ($id: ID!) {
    companyCouponUnset(id: $id) {
      id
    }
  }
`;
