import { translate } from '../../../../shared/translate';
import BlobField from '../../../item_EmailTemplate/ComponentField/BlobField';
import React from 'react';
import InnerSelectField from '../../InnerSelectField';
import { Box, Grid, Typography } from '@material-ui/core';
import { Icon } from '../../../../components/IconRender';

export default {
  label: translate.text,
  value: 'isText',
  render: ({ suggestions, disabled, value, onChange = (_) => _ }) => (
    <Box px={1}>
      <Box display={'flex'} style={{ alignItems: 'center', flexDirection: 'row' }}>
        <InnerSelectField
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRightWidth: 0,
          }}
          placeholder={
            <>
              <Icon icon={'fa1'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
              <Icon icon={'faText'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
            </>
          }
          options={[
            {
              label: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
              value: 1,
              icon: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
            },
            {
              label: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
              value: 2,
              icon: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
            },
            {
              label: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
              value: 3,
              icon: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
            },
          ]}
          value={value?.size}
          onChange={(size) =>
            onChange({
              ...value,
              size,
            })
          }
        />
        <InnerSelectField
          style={{
            borderRadius: 0,
            borderRightWidth: 0,
          }}
          placeholder={<Icon icon={'faAlignLeft'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />}
          options={[
            {
              label: <AlignIconWithText iconOne="faAlignLeft" text={translate.alignLeft || '靠左'} />,
              value: 'left',
              icon: <Icon icon={'faAlignLeft'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
            },
            {
              label: <AlignIconWithText iconOne="faAlignCenter" text={translate.alignCenter || '置中'} />,
              value: 'center',
              icon: <Icon icon={'faAlignCenter'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
            },
            {
              label: <AlignIconWithText iconOne="faAlignRight" text={translate.alignRight || '靠右'} />,
              value: 'right',
              icon: <Icon icon={'faAlignRight'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
            },
          ]}
          value={value?.align}
          onChange={(align) =>
            onChange({
              ...value,
              align,
            })
          }
        />
        <InnerSelectField
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          placeholder={<Icon icon={'faN'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />}
          options={[
            {
              label: <AlignIconWithText iconOne="faBold" text={translate.font_bold || '粗體'} />,
              value: true,
              icon: <Icon icon={'faBold'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
            },
            {
              label: <AlignIconWithText iconOne="faN" text={translate.font_normal || '正常'} />,
              value: false,
              icon: <Icon icon={'faN'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />,
            },
          ]}
          value={value?.bold}
          onChange={(bold) =>
            onChange({
              ...value,
              bold,
            })
          }
        />
        <Box flex={1}>
          <BlobField
            suggestions={suggestions}
            disabled={disabled}
            value={value?.text}
            onChange={(text) => {
              onChange({
                ...value,
                text,
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  ),
};

export const AlignIconWithText = ({ iconOne, iconTwo = undefined, text = undefined }) => {
  return (
    <Grid container spacing={1} justifyContent={!!iconTwo ? 'center' : undefined}>
      <Grid item>
        {!!iconTwo ? (
          <Box mb={0.5}>
            <Grid container>
              <Grid item>
                <Icon icon={iconOne} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
              </Grid>

              <Grid item>
                <Icon icon={iconTwo} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Icon icon={iconOne} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
        )}
      </Grid>
      {!!text && (
        <Grid item>
          <Typography>{text}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
