import React from 'react';
import SelectComboCountry from '../../SelectCombo/SelectComboCountry';
import LSkeleton from '../../LSkeleton';

export default ({
  loading,
  name,
  onChange = (_) => _,
  isSubmitting,
  disabled,
  setFieldValue,
  value = [],
  ...props
}) => {
  if (loading) return <LSkeleton height={30} />;
  return (
    <SelectComboCountry
      multiple={true}
      name={name}
      disabled={isSubmitting || disabled}
      value={value}
      onChange={(v) => onChange(v)}
      {...props}
    />
  );
};
