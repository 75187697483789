import React from 'react';
import { translate } from '../../../../../shared/translate';
import { Icon } from '../../../../../components/IconRender';
import { ReactComponent as icon } from '../../../../../assets/icon/design menu bar_product.svg';
import { useQuery } from '@apollo/client';
import { GET_QUERY } from './query';
import ProductBox from './ProductBox';
import { Box, InputAdornment, Typography } from '@material-ui/core';
import SelectComboProduct from '../../../../../components/SelectCombo/SelectComboProduct';
import HighlightFormRow from '../../HighlightFormRow';
import InputField from '../../../../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../../../../components/FormPageMaker/Fields/SelectField';
import { getImageSize, parseProductImages } from '../../../../../shared';

export default {
  defaultHeight: 0,
  label: translate.product,
  icon: <Icon icon={icon} type={'svg'} viewBox={'0 0 40.423 43.635'} />,
  preview: function PreviewFC({ data, height, setEditData }) {
    const { productId, ratio, resizeMode = 'contain' } = data || {};
    const { loading, data: { node } = {} } = useQuery(GET_QUERY, { skip: !productId, variables: { id: productId } });

    return (
      <div style={{ cursor: 'pointer' }} onClick={() => setEditData(data)}>
        <ProductBox product={node} loading={loading} height={height} resizeMode={resizeMode} ratio={ratio}>
          {!productId && (
            <Box py={5}>
              <Typography variant={'h4'} align={'center'}>
                {translate.click_to_add_product}
              </Typography>
            </Box>
          )}
        </ProductBox>
      </div>
    );
  },

  edit: ({ value, onChange }) => {
    const { productId, productSku, productName, ratio, resizeMode = 'contain' } = value || {};
    const update = (k, v) => {
      onChange({
        ...value,
        [k]: v,
      });
    };
    return (
      <>
        <HighlightFormRow highlight={value.productId !== undefined} title={translate.product}>
          <SelectComboProduct
            value={
              productId
                ? {
                    id: productId,
                    sku: productSku,
                    name: productName,
                  }
                : undefined
            }
            onChange={async (product) => {
              const image = parseProductImages(product, false)?.[0];
              const { width, height } = await getImageSize(image);

              onChange({
                ...value,
                ratio: +(height / width)?.toFixed(3) || undefined,
                productId: (product || {}).id,
                productSku: (product || {}).sku,
                productName: (product || {}).name,
              });
            }}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.resizeMode !== undefined} title={translate.image_resize_mode}>
          <SelectField
            value={resizeMode}
            onChange={(e) => update('resizeMode', e.target.value)}
            options={[
              { label: 'Contain', value: 'contain' },
              { label: 'Cover', value: 'cover' },
            ]}
          />
        </HighlightFormRow>
        <HighlightFormRow highlight={value.ratio !== undefined} title={'Image Ratio'}>
          <InputField
            type={'number'}
            value={ratio}
            onChange={(e) => update('ratio', e.target.value)}
            onBlur={(e) => update('ratio', e.target.value ? +e.target.value : undefined)}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: <InputAdornment position={'start'}>1 :</InputAdornment>,
            }}
          />
        </HighlightFormRow>
      </>
    );
  },
};
