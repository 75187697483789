import { gql } from '@apollo/client';
export const FRAGMENT_VENUE = gql`
  fragment FRAGMENT_VENUE on ShopVenue {
    id
    createdAt
    updatedAt
    name
    sortIndex
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $query: String, $filter: VenueFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        venues(cursor: $cursor, limits: $limits, sortBy: $sortBy, query: $query, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_VENUE
          }
        }
      }
    }
  }
  ${FRAGMENT_VENUE}
`;
