import React from 'react';
import InputField from '../../../FormPageMaker/Fields/InputField';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { convertMetaObject } from '../../../../shared';

const EmailField = ({ disabled, value, onChange, field = {} }) => {
  const { metadata, name, required } = field ?? {};
  const { remarks, length, placeholder } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <InputField
        value={value}
        onChange={(e) => onChange(e?.target?.value)}
        name="email"
        type={'email'}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        inputProps={{
          maxLength: length,
        }}
      />
    </CustomFieldWrapper>
  );
};

export default EmailField;
