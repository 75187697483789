import React from 'react';
import CustomFieldWrapper from '../CustomFieldWrapper';
import SelectField from '../../../FormPageMaker/Fields/SelectField';
import { convertMetaObject } from '../../../../shared';

const ListSingleField = ({ disabled, value, onChange, field = {} }) => {
  const { metadata, name, required, values = [] } = field ?? {};
  const { remarks, placeholder } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <SelectField
        value={value || ''}
        onChange={(e) => onChange(e?.target?.value)}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        options={values.map((v) => ({ label: v, value: v }))}
      />
    </CustomFieldWrapper>
  );
};

export default ListSingleField;
