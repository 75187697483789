import React from 'react';
import { translate } from 'shared/translate';
import { TextField, MenuItem } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import LSkeleton from '../../LSkeleton';

export default ({ loading, name, value, required, onChange, disabled, options = [], style = {}, ...props }) => {
  if (loading) return <LSkeleton height={30} />;
  return (
    <TextField
      select
      fullWidth
      margin={'none'}
      size={'small'}
      placeholder={translate.please_select}
      name={name}
      value={value}
      required={required}
      disabled={disabled}
      onChange={onChange}
      variant={'outlined'}
      style={style}
      {...props}
    >
      {options
        .filter((_) => _)
        .map(({ label, value, disabled }) => {
          return value !== undefined ? (
            <MenuItem key={value} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ) : (
            <ListSubheader style={{ pointerEvents: 'none' }}>{label}</ListSubheader>
          );
        })}
    </TextField>
  );
};
