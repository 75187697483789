import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Icon } from '../IconRender';
import { ReactComponent as IconOmniwe } from '../../assets/icon/omniwe.svg';
import { ReactComponent as IconWhatsappO } from '../../assets/icon/whatsapp-o.svg';
import { ReactComponent as IconMessagerO } from '../../assets/icon/messager-o.svg';
import { ReactComponent as IconTelegramO } from '../../assets/icon/telegram-o.svg';
import { ReactComponent as IconFacebookO } from '../../assets/icon/facebook-o.svg';
import { ReactComponent as IconWechatO } from '../../assets/icon/webchat-o.svg';

const TopToolbar = ({}) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <IconButton size={'small'}>
          <Icon icon={IconOmniwe} type={'svg'} viewBox={'0 0 29 25'} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size={'small'} disabled={true}>
          <Icon icon={IconWhatsappO} type={'svg'} viewBox={'0 0 26 25'} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size={'small'} disabled={true}>
          <Icon icon={IconMessagerO} type={'svg'} viewBox={'0 0 26 25'} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size={'small'} disabled={true}>
          <Icon icon={IconTelegramO} type={'svg'} viewBox={'0 0 26 25'} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size={'small'} disabled={true}>
          <Icon icon={IconFacebookO} type={'svg'} viewBox={'0 0 26 25'} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size={'small'} disabled={true}>
          <Icon icon={IconWechatO} type={'svg'} viewBox={'0 0 32 25'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default TopToolbar;
