import React from 'react';
import { Button, Typography, Box, Step, withStyles, Stepper } from '@material-ui/core';
import ChatGptLanguageStep from './ChatGptLanguageStep';
import ChatGptWordCountStep from './ChatGptWordCountStep';
import ChatGptTonalityStep from './ChatGptTonalityStep';
import ChatpGptRequirementStep from './ChatGptRequirementStep';
import ChatGptReadyStep from './ChatGptReadyStep';
import { useMeasure } from 'react-use';

const styles = (theme) => ({
  stepperBox: {
    width: '100%',
    padding: '1rem',
    marginTop: -30,
    flex: 1,
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  stepperBtnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  stepperPrimaryBtn: {
    background: '#5B7D96',
    boxShadow: '2px 2px 2px 0px rgb(135 135 135 / 40%) inset',
    backdropFilter: 'blur(5px)',
    // -webkit-backdrop-filter: blur(5px);
    border: '1px solid rgba(255, 255, 255, 0.20)',
    //   border: 'none',
    borderRadius: '30px',
    color: '#fff',
    padding: '7px 14px',
    fontSize: '16px',
    width: '160px',
  },
  stepperSecondaryBtn: {
    background: '#ddd',
    // -webkit-backdrop-filter: blur(5px);
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '2px 2px 2px 0px rgb(227 227 227 / 40%) inset',
    //   border: 'none',
    borderRadius: '30px',
    color: '#fff',
    padding: '7px 14px',
    fontSize: '16px',
    width: '160px',
  },
  stepperDot: {
    height: '10px',
    width: '10px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
  },
  stepperDotActive: {
    height: '10px',
    width: '10px',
    backgroundColor: '#1F4C6D',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    boxShadow: '0 0 0 5px rgb(31 76 109 / 60%)',
  },
});

/*
value = {
  language,
  wordCount,
  ....
}
*/

const steps = [
  { step: '01', chi: '語言', eng: 'Language' },
  { step: '02', chi: '字數', eng: 'Word count' },
  { step: '03', chi: '語調', eng: 'Tonality' },
  { step: '04', chi: '其他條件', eng: 'Requirement' },
  { step: '05', chi: '已為您準備好產品描述！', eng: 'Requirement' },
];

const ChatGptSteperSetting = ({ value = {}, onChange = (_) => _, onFinish = (_) => _, product, classes }) => {
  const [ref, { height }] = useMeasure();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box ref={ref} className={classes.stepperBox}>
      <div style={{ height, display: 'flex', flexDirection: 'column' }}>
        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          <Step key={activeStep}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              {activeStep === 4 ? (
                <Box
                  style={{
                    //   textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '10px',
                  }}
                >
                  <Typography
                    style={{
                      color: '#1F4C6D',
                      fontSize: '18px',
                      fontWeight: '400',
                    }}
                  >
                    已為您準備好產品描述！
                  </Typography>
                  <Typography
                    style={{
                      color: '#5B7D96',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                  >
                    您可以按右下角按鍵重新生成產品描述
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography
                        style={{
                          color: '#1F4C6D',
                          fontSize: '38px',
                          fontWeight: '400',
                        }}
                      >
                        {steps[activeStep].step}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'Left',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#1F4C6D',
                          fontSize: '18px',
                          lineHeight: 1.2,
                        }}
                      >
                        {steps[activeStep].chi}
                      </Typography>
                      <Typography
                        style={{
                          color: '#1F4C6D',
                          fontSize: '18px',
                          lineHeight: 1.2,
                        }}
                      >
                        {steps[activeStep].eng}
                      </Typography>
                    </Box>
                  </div>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '25px',
                      marginTop: '5px',
                    }}
                  >
                    {steps.map((_, index) => (
                      <span className={index === activeStep ? classes.stepperDotActive : classes.stepperDot} />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Step>
        </Stepper>
        {
          {
            0: (
              <>
                <div style={{ flex: 1, overflowY: 'auto' }}>
                  <ChatGptLanguageStep
                    value={value?.language}
                    onChange={(v) => {
                      onChange({
                        ...value,
                        language: v,
                      });
                      handleNext();
                    }}
                  />
                </div>
                <div className={classes.stepperBtnRow}>
                  <Button variant="contained" onClick={handleNext} className={classes.stepperSecondaryBtn}>
                    跳過
                  </Button>
                </div>
              </>
            ),
            1: (
              <>
                <div style={{ flex: 1, overflowY: 'auto' }}>
                  <ChatGptWordCountStep
                    value={value?.wordCount}
                    onChange={(v) => {
                      onChange({
                        ...value,
                        wordCount: v,
                      });
                      handleNext();
                    }}
                  />
                </div>
                <div className={classes.stepperBtnRow}>
                  <Button variant="contained" onClick={handleNext} className={classes.stepperSecondaryBtn}>
                    跳過
                  </Button>
                </div>
              </>
            ),
            2: (
              <>
                <div style={{ flex: 1, overflowY: 'auto' }}>
                  <ChatGptTonalityStep
                    value={value?.tonality}
                    onChange={(v) => {
                      onChange({
                        ...value,
                        tonality: v,
                      });
                      handleNext();
                    }}
                  />
                </div>
                <div className={classes.stepperBtnRow}>
                  <Button variant="contained" onClick={handleNext} className={classes.stepperSecondaryBtn}>
                    跳過
                  </Button>
                </div>
              </>
            ),
            3: (
              <>
                <div style={{ flex: 1, overflowY: 'auto' }}>
                  <ChatpGptRequirementStep
                    value={value?.requirement}
                    onChange={(v) =>
                      onChange({
                        ...value,
                        requirement: v,
                      })
                    }
                  />
                </div>
                <div className={classes.stepperBtnRow}>
                  <Button variant="contained" onClick={handleNext} className={classes.stepperPrimaryBtn}>
                    確定
                  </Button>
                </div>
              </>
            ),
            4: (
              <>
                <div style={{ flex: 1, overflowY: 'auto', marginBottom: 16 }}>
                  <ChatGptReadyStep
                    option={{ ...value, product }}
                    value={value?.result}
                    onChange={(v) => {
                      onChange({
                        ...value,
                        result: v,
                      });
                    }}
                  />
                </div>
                <div className={classes.stepperBtnRow}>
                  <Button variant="contained" onClick={handleReset} className={classes.stepperSecondaryBtn}>
                    重設
                  </Button>
                  <Button variant="contained" onClick={onFinish} className={classes.stepperPrimaryBtn}>
                    確定使用
                  </Button>
                </div>
              </>
            ),
          }[activeStep]
        }
      </div>
    </Box>
  );
};

export default withStyles(styles)(ChatGptSteperSetting);
