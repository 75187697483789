import React from 'react';
import InputField from '../../../FormPageMaker/Fields/InputField';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { convertMetaObject } from '../../../../shared';

const DateField = ({ disabled, value, onChange, field = {} }) => {
  const { metadata, name, required } = field ?? {};
  const { remarks } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <InputField
        id="date"
        type="date"
        value={value}
        onChange={(e) => onChange(e?.target?.value)}
        required={required}
        disabled={disabled}
      />
    </CustomFieldWrapper>
  );
};

export default DateField;
