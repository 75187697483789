import React, { useRef, useState } from 'react';
import { Dialog, IconButton, Button, Typography, Box, withStyles, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { translate } from '../../../shared/translate';
import { Icon } from '../../../components/IconRender';
import { ReactComponent as chatgpt } from '../../../assets/chatgpt-gen.svg';
import { ReactComponent as chatgptSetting } from '../../../assets/chatgpt-setting.svg';
import ChatGptSteperSetting from './ChatGptSteperSetting';
import useAI from '../../../components/useAI';
import { getProductDescriptionPrompt } from './index';
import { languages } from './ChatGptLanguageStep';
import { wordCounts } from './ChatGptWordCountStep';
import { tonalities } from './ChatGptTonalityStep';

const styles = (theme) => ({
  buttonGrpBox: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  generatorBtnBox: {
    cursor: 'pointer',
    marginLeft: '50px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  chatgptIcon: {
    position: 'absolute',
    top: -7,
    left: -33,
    zIndex: 1,
  },
  chatgptIconSvg: {
    width: 50,
    height: 50,
  },
  generatorBtn: {
    background: 'rgb(91, 125, 150)',
    backgroundImage: 'linear-gradient(90deg, rgba(91, 125, 150, 1) 0%, rgba(0, 224, 202, 1) 80%)',
    border: 'none',
    color: '#fff',
    padding: '7px 12px',
    paddingLeft: '25px',
    // '&:hover': {
    //   backgroundImage: 'linear-gradient(90deg, rgb(32 60 81) 0%, rgb(14 184 168) 80%)',
    // },
  },
  settingBtn: {
    padding: '10px 10px',
    color: '#fff',
    borderRadius: '20%',
    background: 'rgb(91, 125, 150)',
    backgroundImage: 'linear-gradient(90deg, rgba(91, 125, 150, 1) 0%, rgba(0, 224, 202, 1) 80%)',
    // '&:hover': {
    //   backgroundImage: 'linear-gradient(90deg, rgb(32 60 81) 0%, rgb(14 184 168) 80%)',
    // },
  },
  settingBtnSvg: {
    width: 16,
    height: 15,
  },
  dialogBox: {
    borderRadius: '10px',
    background: 'rgba(255, 255, 255, 0.75)',
    boxShadow: '0px 0px 38px 20px rgba(255, 255, 255, 0.70) inset',
    backdropFilter: 'blur(3px)',
    height: 650,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTitleRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogHeroBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -16,
  },
  dialogHero: {
    padding: '0.8rem 2rem',
    width: '80%',
    borderRadius: '55px',
    border: 'solid #fff 1px',
    background: 'rgb(91, 125, 150)',
    backgroundImage: 'linear-gradient(90deg, rgba(91, 125, 150, 1) 0%, rgba(0, 224, 202, 1) 80%)',
    textAlign: 'center',
  },
  dialogHeroTitle: {
    color: '#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    margin: 0,
    lineHeight: 1.2,
  },
  dialogHeroSubTitle: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
    margin: 0,
    lineHeight: 1.2,
  },
});

export function getPrompts(data, product) {
  const lang = {
    tc: 'traditional chinese',
    sc: 'simplified chinese',
    en: 'english',
  }[data?.language];
  switch (data?.tonality) {
    case 'casual':
      return [
        `Act as a copywriter writing an article on ‘${
          product?.name || 'unknown'
        }’ for its product landing page for a shop aiming to convert visitors into buyers. Complete the article with about ${
          data?.wordCount
        } words in ${lang}, using an elegant and superior tone, utilizing sophisticated vocabularies. Structure the article with the cues underneath:`,
        ...[
          `Provide vivid description of product on aesthetics and practical functionalities`,
          `Utilize a nonchalant tone`,
          `Emphasis and slightly exaggerate on utility`,
          `Write the article in a leisurely and enthusiastic tone`,
          `Instructions for product usage included at the end`,
          `Partition article with subheadings, however many you deem fit`,
          data?.requirement,
          `MUST START WITH <html>`,
        ]
          .filter((_) => _)
          .map((str, i) => `${i + 1}. ${str}`),
        `use the following information as reference: ${getProductDescriptionPrompt(product)}`,
      ].join('\n');
    case 'professional':
      return [
        `Act as a copywriter writing an article on ‘${
          product?.name || 'unknown'
        }’ for its product landing page for a shop aiming to convert visitors into buyers. Complete the article with about ${
          data?.wordCount
        } words in ${lang}, using an elegant and superior tone, utilizing sophisticated vocabularies. Structure the article with the cues underneath:`,
        ...[
          `Provide vivid description of product, preferably on aesthetics, current prevalence, practical functionalities`,
          `Informative (educational if applicable) description of product on aesthetics and practical functionalities`,
          `Elaborate on the benefits of its functionalities with`,
          `Emphasis and slightly exaggerate on utility and beneficial qualities of the product`,
          `Write the article in a formal and professional tone, utilization of business vocabularies are preferable`,
          `Instructions for product usage included at the end`,
          `Partition article with subheadings, however many you deem fit`,
          data?.requirement,
          `MUST START WITH <html>`,
          `use the following information as reference: ${getProductDescriptionPrompt(product)}`,
        ]
          .filter((_) => _)
          .map((str, i) => `${i + 1}. ${str}`),
      ].join('\n');
    case 'elegant':
      return [
        `Act as a copywriter writing an article on ‘${
          product?.name || 'unknown'
        }’ for its product landing page for a shop aiming to convert visitors into buyers. Complete the article with about ${
          data?.wordCount
        } words in ${lang}, using an elegant and superior tone, utilizing sophisticated vocabularies. Structure the article with the cues underneath`,
        ...[
          `Provide vivid description of product, preferably on aesthetics, current prevalence, practical functionalities`,
          `Construct sentences`,
          `Emphasis and slightly exaggerate on its utilities and effects`,
          `Brief instructions for product usage included at the end`,
          `Partition article with subheadings, however many you deem fit but avoid using the same words written on this prompt`,
          `Include a relatable experience utilizing the product, further highlighting its beneficial qualities`,
          data?.requirement,
          `MUST START WITH <html>`,
          `use the following information as reference: ${getProductDescriptionPrompt(product)}`,
        ]
          .filter((_) => _)
          .map((str, i) => `${i + 1}. ${str}`),
      ].join('\n');
    case 'straightforward':
      return [
        `Act as a copywriter writing an article on ‘${
          product?.name || 'unknown'
        }’ for its product landing page for an online shop aiming to convert visitors into buyers. Complete the article with about ${
          data?.wordCount
        } words in ${lang}, using a confident and authoritative tone to persuade readers, utilizing assertive vocabularies. Structure the article with the cues underneath`,
        ...[
          `Provide vivid description of product, preferably on aesthetics, prevalence, practical functionalities`,
          `Emphasis and slightly exaggerate on its utilities and effects`,
          `Brief instructions for product usage included at the end`,
          `Partition article with subheadings, however many you deem fit but avoid using the same words written on this prompt`,
          `Include a relatable experience utilizing the product, further highlighting its beneficial qualities`,
          data?.requirement,
          `MUST START WITH <html>`,
          `use the following information as reference: ${getProductDescriptionPrompt(product)}`,
        ]
          .filter((_) => _)
          .map((str, i) => `${i + 1}. ${str}`),
      ].join('\n');
    case 'eager':
      return [
        `Act as a copywriter writing an article on ‘${
          product?.name || 'unknown'
        }’ for its product landing page for an online shop aiming to convert visitors into buyers. Complete the article with about ${
          data?.wordCount
        } words in ${lang}, using a confident and authoritative tone to persuade and inform readers on progressively limited supply of aforementioned items, utilizing assertive vocabularies. Structure the article with the cues underneath`,
        ...[
          `Provide vivid description of product, preferably on aesthetics, prevalence, practical functionalities`,
          `Emphasize on its scarce supply and high demand`,
          `Emphasis and slightly exaggerate on its utilities and effects`,
          `Brief instructions for product usage included at the end`,
          `Partition article with subheadings, however many you deem fit but avoid using the same words written on this prompt`,
          `Include a relatable experience utilizing the product, further highlighting its beneficial qualities`,
          data?.requirement,
          `MUST START WITH <html>`,
          `use the following information as reference: ${getProductDescriptionPrompt(product)}`,
        ]
          .filter((_) => _)
          .map((str, i) => `${i + 1}. ${str}`),
      ].join('\n');
    case 'humorous':
      return [
        `Act as a copywriter writing an article on ‘${
          product?.name || 'unknown'
        }’ for its product landing page for an online shop aiming to convert visitors into buyers, compose the article with about ${
          data?.wordCount
        } words in ${lang}, and with the following cues`,
        ...[
          `Provide vivid description of product on aesthetics and practical functionalities`,
          `Emphasis and slightly exaggerate on utility`,
          `Write the article a pleasant, playful and light-hearted tone, complemented with self-deprecating humor and jokes`,
          `Include light-hearted humor`,
          `Instructions for product usage included at the end`,
          `Partition article with subheadings, however many you deem fit`,
          `Fabricate a relatable experience utilizing the product, further highlighting its beneficial qualities`,
          data?.requirement,
          `MUST START WITH <html>`,
          `use the following information as reference: ${getProductDescriptionPrompt(product)}`,
        ]
          .filter((_) => _)
          .map((str, i) => `${i + 1}. ${str}`),
      ].join('\n');

    default:
      return [
        `Act as a copywriter writing an article on ‘${
          product?.name || 'unknown'
        }’ for its product landing page for an online shop aiming to convert visitors into buyers, compose the article with about ${
          data?.wordCount
        } words in ${lang}`,
        `MUST START WITH <html>`,
        `use the following information as reference: ${getProductDescriptionPrompt(product)}`,
      ].join('\n');
  }
}

const ChatGptButtonGroup = ({ classes, onChange, product }) => {
  const handler = useRef({ output: false, content: '' });
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState({
    language: languages[0].value,
    wordCount: wordCounts[0].value,
    tonality: tonalities[0].value,
    requirement: '',
    result: '',
  });

  const { request } = useAI({
    onDelta: (delta, stop) => {
      handler.current.content = handler.current.content + delta;

      if (handler.current.content.length % 10 === 0 || stop) {
        if (handler.current.output) onChange(handler.current.content);
        setData(handler.current.content);
      }

      if (stop) setProcessing(false);
    },
  });

  async function startAIRequest(output = false) {
    setProcessing(true);
    handler.current.content = '';
    handler.current.output = output;
    await request([getPrompts(data, product)]);
  }

  return (
    <>
      <Box className={classes.buttonGrpBox}>
        <div className={classes.generatorBtnBox}>
          <div className={classes.chatgptIcon}>
            <Icon className={classes.chatgptIconSvg} type={'svg'} icon={chatgpt} viewBox={'0 0 120 120'} />
          </div>
          <Button
            className={classes.generatorBtn}
            disabled={processing}
            variant={'outlined'}
            type={'button'}
            onClick={() => startAIRequest(true)}
          >
            <Typography color={'inherit'}>{translate.ai_generator || 'AI Generator'}</Typography>
          </Button>
        </div>
        <IconButton className={classes.settingBtn} component="span" disabled={processing} onClick={() => setOpen(true)}>
          <Icon type={'svg'} icon={chatgptSetting} viewBox={'0 0 16 15'} />
        </IconButton>
      </Box>
      <Dialog
        PaperProps={{
          style: {
            background: 'none',
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={'md'}
        maxWidth={'md'}
      >
        <Box className={classes.dialogBox}>
          <DialogTitle id="customized-dialog-title">
            <Box className={classes.dialogTitleRow}>
              <IconButton component="span" onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Box>
            <Box className={classes.dialogHeroBox}>
              <Box className={classes.dialogHero}>
                <Typography className={classes.dialogHeroTitle}> AI Generated </Typography>
                <Typography className={classes.dialogHeroSubTitle}> 產品描述 </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <ChatGptSteperSetting
            product={product}
            value={data}
            onChange={setData}
            startAIRequest={startAIRequest}
            onFinish={() => {
              onChange(data.result);
              setOpen(false);
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ChatGptButtonGroup);
