import React from 'react';
import { Drawer, IconButton, withStyles, Tooltip } from '@material-ui/core';
import { BodyComponentsMap } from '../BodyComponents';

const StyledDrawer = withStyles((theme) => ({
  paper: {
    background: '#000',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}))(Drawer);
const StyledIconButton = withStyles({
  root: {
    color: '#fff',
  },
})(IconButton);

const items = Object.keys(BodyComponentsMap)
  .map((key) => {
    const obj = BodyComponentsMap[key];
    return (
      !!obj.icon && {
        _type: key,
        ...obj,
      }
    );
  })
  .filter((_) => _);

export default ({ open, toggle = (_) => _, onComponentAdd = (_) => _ }) => {
  return (
    <StyledDrawer anchor={'left'} open={open} onClose={toggle}>
      {items.map(({ icon, label, _type, defaultHeight }) => {
        return (
          <Tooltip key={_type} title={label} placement={'right'}>
            <StyledIconButton
              onClick={() => {
                onComponentAdd(
                  {
                    _type,
                  },
                  { defaultHeight },
                );
                toggle();
              }}
            >
              {icon}
            </StyledIconButton>
          </Tooltip>
        );
      })}
    </StyledDrawer>
  );
};
