import React, { useRef, useState } from 'react';
import { Button, Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import s3Upload from '../../../../shared/s3Upload';
import { client } from '../../../../shared/apollo';
import { toast } from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { CUSTOMERS_IMPORT_QUERY, CUSTOMERS_UPDATE_IMPORT_QUERY } from './query';

const ImportModal = ({ onClick = (_) => _ }) => {
  const customersImportInput = useRef();
  const customersUpdateImportInput = useRef();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          onClick();
          setVisible(true);
        }}
      >
        {translate.import}
      </MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogContent>
          <div>
            <Button fullWidth variant={'outlined'} size={'large'} onClick={() => customersImportInput.current.click()}>
              {translate.bulk_update_customer_data || '批量更新客人資料'}
            </Button>
            <input
              ref={customersImportInput}
              style={{ visibility: 'hidden', position: 'fixed' }}
              type={'file'}
              onChange={async (evt) => {
                evt.preventDefault();
                setVisible(false);
                const files = evt.target.files;
                const file = files[0];
                try {
                  ActivityIndicator.show();

                  evt.target.value = null;

                  const s3Path = await s3Upload(file);
                  await client.mutate({
                    mutation: CUSTOMERS_IMPORT_QUERY,
                    variables: {
                      shopId: localStorage.getItem('shopId'),
                      url: s3Path,
                    },
                  });
                  toast.success(translate.importing_submit);
                } catch (e) {
                  toast.error(errorParser(e));
                } finally {
                  ActivityIndicator.hide();
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogContent>
          <div>
            <Button
              fullWidth
              variant={'outlined'}
              size={'large'}
              onClick={() => customersUpdateImportInput.current.click()}
            >
              {translate.bulk_increase_or_decrease_customer_data}
            </Button>
            <input
              ref={customersUpdateImportInput}
              style={{ visibility: 'hidden', position: 'fixed' }}
              type={'file'}
              onChange={async (evt) => {
                evt.preventDefault();
                setVisible(false);
                const files = evt.target.files;
                const file = files[0];
                try {
                  ActivityIndicator.show();

                  evt.target.value = null;

                  const s3Path = await s3Upload(file);
                  await client.mutate({
                    mutation: CUSTOMERS_UPDATE_IMPORT_QUERY,
                    variables: {
                      shopId: localStorage.getItem('shopId'),
                      url: s3Path,
                    },
                  });
                  toast.success(translate.importing_submit);
                } catch (e) {
                  toast.error(errorParser(e));
                } finally {
                  ActivityIndicator.hide();
                }
              }}
            />
          </div>
        </DialogContent>
        <div style={{ height: 8 }} />
      </Dialog>
    </>
  );
};

export default ImportModal;
