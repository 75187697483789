import React, { useState } from 'react';
import CommonIconButton from '../../components/CommonIconButton';
import { translate } from '../../shared/translate';
import { ReactComponent as print } from '../../assets/icon/print.svg';
import ActivityIndicator from '../ActivityIndicator';
import PrintOptionsDialog from './PrintOptionsDialog';

const PrintOptionsButton = ({ id, partialHref, orderedBy = undefined }) => {
  const [visible, setVisible] = useState(false);
  const onClose = () => setVisible(false);

  return (
    <>
      <CommonIconButton
        icon={print}
        title={translate.button_print}
        onClick={(e) => {
          e.stopPropagation();
          setVisible(true);
        }}
      />
      {visible && (
        <PrintOptionsDialog
          open={visible}
          onClose={onClose}
          onSubmit={({ fontSize, sortProducts }) => {
            window.open(`/${partialHref}/${id}?fontSize=${fontSize}&sort=${sortProducts}`, '_blank');
            ActivityIndicator.hide();
          }}
          orderedBy={orderedBy}
        />
      )}
    </>
  );
};

export default PrintOptionsButton;
