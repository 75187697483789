import React, { useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { fuzzyFilterOptions, removePhoneNumberRegion } from '../../../shared';
import codes from 'country-calling-code';
import { sortBy } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import InputField from './InputField';
import { translate } from '../../../shared/translate';
import LSkeleton from '../../LSkeleton';

const MobileField = ({
  loading,
  className,
  allCountries = true,
  required,
  value = '',
  onChange,
  disabled,
  InputProps = {},
}) => {
  const { clear, region } = removePhoneNumberRegion(value);

  if (loading) return <LSkeleton height={30} />;

  return (
    <InputField
      className={className}
      required={required}
      type={'tel'}
      name={'tel'}
      value={clear}
      disabled={disabled || !region}
      onChange={(e) => {
        const newValue = (e.target.value + '').replace(/[^0-9]/g, '');
        onChange(
          `${region || ''}${
            (newValue.toString().length > 16 ? newValue.toString().substring(0, 16) - 0 : newValue - 0) || ''
          }`,
        );
      }}
      placeholder={translate.phone_no}
      // eslint-disable-next-line
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position={'start'} disableTypography>
            {InputProps?.startAdornment}
            <div style={{ width: 170 }}>
              {allCountries ? (
                <AllCountriesSelect
                  required={required}
                  key={region}
                  value={region}
                  onChange={(e, v) => {
                    if (v) onChange(`${v}${clear || ''}`);
                    else onChange(``);
                  }}
                  disabled={disabled}
                />
              ) : (
                <SupportedRegionSelect
                  required={required}
                  key={region}
                  value={region}
                  onChange={(e, v) => {
                    if (v) onChange(`${v}${clear}`);
                    else onChange(``);
                  }}
                  disabled={disabled}
                />
              )}
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MobileField;

export const SupportedRegionSelect = ({
  variant = 'outlined',
  placeholder = translate.region_code,
  onChange,
  value,
  required,
  ...props
}) => {
  const options = ['+852', '+86', '+853', '+1', '+44', '+61', '+63', '+886', '+971'];
  const [input, setInput] = useState('');

  const getOptionLabel = (code) => {
    if (!code) return '';
    const _code = `${code || ''}`.replace(/\+/g, '');
    const item = codes.find((item) => item.countryCodes[0] === _code) || {};
    return `${code} ${item.isoCode3 || ''}`;
  };

  return (
    <Autocomplete
      options={options}
      fullWidth
      value={value}
      size={'small'}
      getOptionLabel={getOptionLabel}
      onInputChange={(e, v) => setInput(v)}
      filterOptions={fuzzyFilterOptions}
      onBlur={() => {
        const matchOptions = fuzzyFilterOptions(options, { inputValue: input, getOptionLabel });
        if (matchOptions?.length === 1) onChange(null, matchOptions?.[0]);
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} margin={'none'} placeholder={placeholder} variant={variant} required={required} />
      )}
      {...props}
    />
  );
};

export const AllCountriesSelect = ({
  variant = 'outlined',
  placeholder = translate.region_code,
  onChange,
  required,
  ...props
}) => {
  const [input, setInput] = useState('');
  const countries = sortBy(codes, ({ isoCode3, countryCodes }) => {
    const countryCode = countryCodes[0] || '';
    return ['HKG', 'CHN', 'MAC'].indexOf(isoCode3) + 1 || 10 + +countryCode.replace(/[^.\d]/g, '');
  });

  const groupedData = countries.reduce((acc, { country, countryCodes, isoCode2, isoCode3 }) => {
    const existing = acc.find((item) => item.countryCodes[0] === countryCodes[0]);
    if (existing) {
      existing.country = `${existing.country} ${country}`;
      existing.isoCode2 = `${existing.isoCode2} ${isoCode2}`;
      existing.isoCode3 = `${existing.isoCode3} ${isoCode3}`;
    } else {
      acc.push({
        country,
        countryCodes,
        isoCode2,
        isoCode3,
      });
    }
    return acc;
  }, []);

  // Move objects with isoCode3 values of "HKG", "CHN", and "MAC" to the beginning of the array
  const sortedData = groupedData.sort((a, b) => {
    if (a.isoCode3 === 'HKG' || a.isoCode3 === 'CHN' || a.isoCode3 === 'MAC') {
      return -1;
    }
    if (b.isoCode3 === 'HKG' || b.isoCode3 === 'CHN' || b.isoCode3 === 'MAC') {
      return 1;
    }
    return 0;
  });

  const options = sortedData.map((country) => `+${(country.countryCodes || [])[0]}`);

  const getOptionLabel = (code) => {
    if (!code) return '';
    const _code = `${code || ''}`.replace(/\+/g, '');
    const item = codes.find((item) => item.countryCodes[0] === _code) || {};
    return `${code} ${item.isoCode3 || ''}`;
  };

  return (
    <Autocomplete
      options={options}
      fullWidth
      size={'small'}
      getOptionLabel={getOptionLabel}
      filterOptions={fuzzyFilterOptions}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          // eslint-disable-next-line
          inputProps={{ ...inputProps, value: ((inputProps || {}).value || '').replace(/[' 'a-zA-Z]/g, '') }}
          margin={'none'}
          placeholder={placeholder}
          variant={variant}
          required={required}
        />
      )}
      onInputChange={(e, v) => setInput(v)}
      onBlur={() => {
        const matchOptions = fuzzyFilterOptions(options, { inputValue: input, getOptionLabel });
        if (matchOptions?.length === 1) onChange(null, matchOptions[0]);
      }}
      onChange={onChange}
      {...props}
    />
  );
};
