import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { NumberFormat } from '../../shared';
import AutoHeightBarcode from './AutoHeightBarcode';
import { translate } from '../../shared/translate';
import EllipsisTypography from '../EllipsisTypography';

const Code54X40 = ({ name, barcode: _barcode, unitPrice, sku }) => {
  const barcode = encodeURIComponent(_barcode);

  return (
    <>
      <style>{`
    .print-item {
      page-break-inside: avoid;
      page-break-after: always;
      background-color: white;
      display: flex;
      flex-direction: column;
    }
    .print-item:last-child {
      page-break-after: avoid;
    }
    `}</style>
      <Box
        pl={0.5}
        className={'print-item'}
        style={{
          width: '54mm',
          height: '40mm',
        }}
      >
        <EllipsisTypography style={{ color: '#000' }}>{name}</EllipsisTypography>
        <EllipsisTypography style={{ color: '#000' }}>{sku}</EllipsisTypography>
        <Typography style={{ color: '#000' }}>
          {translate.unit_price} $ {NumberFormat(unitPrice)}
        </Typography>
        <AutoHeightBarcode value={barcode} />
      </Box>
    </>
  );
};

export default Code54X40;
