import { translate } from '../../../../shared/translate';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper } from '../../../../shared';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';

const DISCOUNTS_ACTIVATE = gql`
  mutation ($shopId: ID!, $filter: DiscountFilterInput, $query: String) {
    discountsActivate(shopId: $shopId, filter: $filter, query: $query) {
      id
    }
  }
`;

const MenuActivate = {
  name: `${translate.setting} ${translate.listed}`,
  enable: function () {
    return this.getSelectionCount() > 0;
  },
  onClick: async function () {
    if (await confirmation(translate.formatString(translate['confirm_update_n_records'], this.getSelectionCount()))) {
      await actionWrapper(
        async () => {
          await client.mutate({
            mutation: DISCOUNTS_ACTIVATE,
            variables: {
              shopId: localStorage.getItem('shopId'),
              filter: this.getSelectionFilter(),
              query: this.getQ(),
            },
          });
        },
        {
          messages: {
            loading: translate.update,
            success: translate.update_success,
          },
        },
      );
    }
  },
};

export default MenuActivate;
