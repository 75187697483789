import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { addValue2Metadata, convertMetaObject } from '../../../shared';
import HTMLField from '../../FormPageMaker/Fields/HTMLField';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../FormPageMaker/Fields/InputField';
import { useDebounce } from 'react-use';

const SettingHTML = (props) => {
  const { disabled, value, onChange = (_) => _ } = props;
  const { metadata } = value ?? {};
  const { html } = convertMetaObject(metadata);

  const [input, setInput] = useState(html);

  useEffect(() => {
    if (input !== html) {
      setInput(html);
    }
  }, [html]);

  useDebounce(
    () => {
      if (input !== html) {
        onChange({
          ...value,
          metadata: addValue2Metadata('html', input, metadata),
        });
      }
    },
    250,
    [input],
  );

  return (
    <Box p={2} bgcolor={'#F5F5F5'}>
      <Grid container spacing={2}>
        <FormRow dense title={translate.title}>
          <InputField
            disabled={disabled}
            value={value?.name}
            onChange={(e) =>
              onChange({
                ...value,
                key: e.target.value,
                name: e.target.value,
              })
            }
          />
        </FormRow>
        <FormRow dense title={translate.detail_content}>
          <HTMLField value={input} disabled={disabled} onChange={setInput} />
        </FormRow>
      </Grid>
    </Box>
  );
};

export default SettingHTML;
