import React, { useState } from 'react';
import CardContentContainer from '../CardContentContainer';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import { translate } from '../../../shared/translate';
import { Grid } from '@material-ui/core';

const FormStock = ({ values, setFieldValue, loading, disabled }) => {
  const { underStock, overStock, lowStock } = values || {};
  const { quantity: underStockQuantity, remind: underStockRemind } = underStock || {};
  const { quantity: lowStockQuantity, remind: lowStockRemind } = lowStock || {};
  const { quantity: overStockQuantity, remind: overStockRemind } = overStock || {};
  const [underStockRemindToggle, setState_underStockRemindToggle] = useState(underStockRemind);
  const [lowStockRemindToggle, setState_lowStockRemindToggle] = useState(lowStockRemind);
  const [overStockRemindToggle, setState_overStockRemindToggle] = useState(overStockRemind);

  const handleStockRemind = (v, stockType) => {
    switch (stockType) {
      case 'underStock':
        setState_underStockRemindToggle(v);
        setFieldValue('underStock', {
          ...underStock,
          remind: v,
        });
        break;
      case 'overStock':
        setState_overStockRemindToggle(v);
        setFieldValue('overStock', {
          ...overStock,
          remind: v,
        });
        break;
      case 'lowStock':
        setState_lowStockRemindToggle(v);
        setFieldValue('lowStock', {
          ...lowStock,
          remind: v,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CardContentContainer
          value={underStockRemindToggle}
          onChange={(v) => {
            handleStockRemind(v, 'underStock');
          }}
          cardTitle={translate.out_of_stock_reminder || '產品缺貨提醒'}
          cardSubtitle={translate.email_notification_out_of_stock || '當產品數量售罄時，系統會透過通訊電郵通知商戶'}
          cardSubtitleField={
            <InputField
              disabled={!underStockRemindToggle}
              style={{ width: '100px', display: 'none' }}
              value={underStockQuantity}
              type={'number'}
              onChange={(e) => {
                setFieldValue('underStock', {
                  ...underStock,
                  quantity: e.target.value,
                });
              }}
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <CardContentContainer
          value={lowStockRemindToggle}
          onChange={(v) => {
            handleStockRemind(v, 'lowStock');
          }}
          cardTitle={translate.low_inventory_alert || '低庫存提醒'}
          inputField={
            <InputField
              disabled={!lowStockRemindToggle}
              value={lowStockQuantity}
              type={'number'}
              onChange={(e) => {
                setFieldValue('lowStock', {
                  ...lowStock,
                  quantity: e.target.value,
                });
              }}
            />
          }
          cardSubtitle={
            translate.low_inventory_alert_tips ||
            '產品庫存若低於設定值，系統將於隔日上午8點自動發送電子郵件至指定通知地址。若未設定低庫存通知數量，則以統一低庫存設定為準。'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <CardContentContainer
          value={overStockRemindToggle}
          onChange={(v) => {
            handleStockRemind(v, 'overStock');
          }}
          cardTitle={translate.deadstock_alert || '滯銷提醒'}
          cardSubtitle={translate.product_unsold || '當產品多於'}
          cardSubtitleField={
            <InputField
              disabled={!overStockRemindToggle}
              style={{ width: '100px' }}
              value={overStockQuantity}
              type={'number'}
              inputProps={{
                min: 1,
                step: 1,
              }}
              onChange={(e) => {
                setFieldValue('overStock', {
                  ...overStock,
                  quantity: e.target.value,
                });
              }}
            />
          }
          cardSubtitleAfterInput={
            translate.system_send_email_over_stock || '天沒有銷售紀錄時，系統會於早上8時透過通訊電郵通知商戶'
          }
        />
      </Grid>
    </Grid>
  );
};

export default FormStock;
