import React from 'react';
import ImportModal from './ImportModal';
import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';

const MenuExportPointRecord = {
  render: function () {
    return (
      <ImportModal
        customerCount={this.getSelectionCount()}
        infinityFetchCustomers={() =>
          this.infinityFetchAllSelected({
            onProgress: (downloaded, total) => {
              if (downloaded < total) ActivityIndicator.show(`${translate.download} ${downloaded} / ${total}`);
              else ActivityIndicator.hide();
            },
          })
        }
        onClick={() => {
          this.setState({ menuAnchorEl: undefined });
        }}
      />
    );
  },
};

export default MenuExportPointRecord;
