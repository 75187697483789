import React, { useState } from 'react';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { COMPLETE_QUERY } from './query';
import SystemButton from '../../components/SystemButton';
import { gql } from '@apollo/client';

const DeliveryNoteCompleteButton = ({ values, setFieldValue }) => {
  const [loading, setLoading] = useState(false);
  return (
    <SystemButton
      variant={'contained'}
      color={'success'}
      disabled={loading}
      loading={loading}
      onClick={async () => {
        try {
          setLoading(true);
          const { data: { deliveryNoteComplete } = {} } = await client.mutate({
            mutation: COMPLETE_QUERY,
            variables: {
              id: values.id,
            },
          });
          setFieldValue('status', (deliveryNoteComplete || {}).status);

          /* temporary customer update, for fixing the campaign */
          {
            const { data } = await client.query({
                query: gql`
                  query ($id: ID!) {
                    node(id: $id) {
                      id
                      ... on OrderDeliveryNote {
                        referenceNo
                        order {
                          id
                          customer {
                            id
                          }
                        }
                      }
                    }
                  }
                `,
                variables: { id: values.id },
              }),
              { order } = (data || {}).node || {},
              { customer } = order || {},
              { id: userId } = customer || {};
            if (!!userId) {
              await client.mutate({
                mutation: gql`
                  mutation ($id: ID!, $shopId: ID!) {
                    customerUpdate(id: $id, shopId: $shopId, input: {}) {
                      id
                    }
                  }
                `,
                variables: { id: userId, shopId: localStorage.getItem('shopId') },
              });
            }
          }
          /* temporary customer update, for fixing the campaign */

          toast.success(translate.delivery_status_updated);
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.completed} ({translate.dn_received})
    </SystemButton>
  );
};
export default DeliveryNoteCompleteButton;
