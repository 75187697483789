import React, { useState } from 'react';
import {
  Avatar,
  Card,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import ChannelList from './ChannelList';
import { theme } from '../../../theme';
import ChannelCustomerDetail from '../../../components/ChannelMessage/ChannelCustomerDetail';
import ChannelMessage from '../../../components/ChannelMessage';
import { Icon } from '../../../components/IconRender';
import { parseCustomerName } from '../../../shared';
import { Skeleton } from '@material-ui/lab';

const StyledCard = withStyles({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
})(Card);

const MobileView = ({ match: { params = {} } = {}, history }) => {
  const channelId = params.id;
  const [selectedChannelPlayer, setSelectedChannelPlayer] = useState(undefined);
  const [customerDetailVisible, setCustomerDetailVisible] = useState(false);

  return (
    <div style={{ position: 'relative', margin: -24, height: `calc(100% + 48px)` }}>
      <StyledCard elevation={0}>
        <ChannelList
          channel={{ id: channelId }}
          onChannelClick={async (channel) => {
            const { id } = channel || {};
            history.push(`/messages/${id}`);
          }}
        />
      </StyledCard>
      <PopoverWrapper visible={!!channelId}>
        {!!channelId && (
          <StyledCard>
            <ChannelMessage
              key={channelId}
              id={channelId}
              header={
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <IconButton color={'secondary'} onClick={() => history.push(`/messages`)}>
                      <Icon icon={'faAngleLeft'} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <ListItem onClick={() => setCustomerDetailVisible(true)}>
                      <ListItemAvatar>
                        {!selectedChannelPlayer ? (
                          <Skeleton variant={'circle'} width={40} height={40} />
                        ) : (
                          <Avatar src={selectedChannelPlayer?.picture} />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography style={{ fontWeight: 'bold' }}>
                            {!selectedChannelPlayer ? (
                              <Skeleton width={100} />
                            ) : (
                              parseCustomerName(selectedChannelPlayer)
                            )}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              }
            />
          </StyledCard>
        )}
      </PopoverWrapper>
      <PopoverWrapper visible={customerDetailVisible}>
        {!!channelId && (
          <StyledCard>
            <ChannelCustomerDetail
              channelId={channelId}
              onCustomerData={setSelectedChannelPlayer}
              header={
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <IconButton color={'secondary'} onClick={() => setCustomerDetailVisible(false)}>
                      <Icon icon={'faAngleLeft'} />
                    </IconButton>
                  </Grid>
                </Grid>
              }
            />
          </StyledCard>
        )}
      </PopoverWrapper>
    </div>
  );
};

export default MobileView;

const PopoverWrapper = ({ children, visible }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: visible ? 0 : '100vw',
      width: '100vw',
      height: '100vh',
      transition: 'left 0.5s',
      background: 'white',
      padding: theme.spacing(2),
      paddingTop: 41.5 + theme.spacing(2),
    }}
  >
    {children}
  </div>
);
