import { translate } from 'shared/translate';
import { getCommonColumns } from 'shared';
import ListCustomPage from '../list_CustomPage/ListCustomPage';
import StackTags from '../../components/StackTags';
import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Icon } from '../../components/IconRender';

export default class extends ListCustomPage {
  state = {
    ...this.state,
    createUrl: '/articles/new',
    getRowLink: ({ id, name }) => ({ pathname: `/articles/${id}`, state: { title: name } }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        sortBy: 'name.keyword',
        width: 250,
      },
      {
        title: translate.hash_tag,
        render: ({ hashtags = [] }) =>
          hashtags?.length > 0 ? (
            <StackTags
              tags={hashtags}
              onTagClick={(tag) => {
                if (!tag) return;

                const { filter } = this.state;
                this.onFilterChange({
                  ...filter,
                  hashtags: {
                    operator: 'IN',
                    value: [tag],
                  },
                });
              }}
            />
          ) : (
            <></>
          ),
        type: 'text',
        filter: 'hashtags',
        filterType: 'hashTagsArray',
      },
      {
        title: translate.preview,
        noLink: true,
        width: 50,
        align: 'center',
        render: ({ group, href, rewriteUri, shop }) => {
          const pathname = (rewriteUri || (href === 'index' ? '/' : `/c/${group}/${href}`)).replace(/^\//, '');
          const link = `https://${shop?.customDomain || shop?.hostname}/${pathname}`;

          return (
            <Tooltip title={link}>
              <a href={link} target={'_blank'}>
                <IconButton>
                  <Icon icon={'faExternalLink'} />
                </IconButton>
              </a>
            </Tooltip>
          );
        },
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        type: 'number',
        width: 1,
        sortBy: 'sortIndex',
      },
      {
        title: translate.visible,
        align: 'center',
        width: 50,
        fieldName: 'display',
        type: 'bool',
        filter: 'display',
        sortBy: 'display',
      },
      {
        title: translate.availability,
        align: 'center',
        width: 50,
        fieldName: 'active',
        type: 'bool',
        filter: 'active',
        sortBy: 'active',
      },
      ...getCommonColumns(),
    ],
    qFields: ['name', 'href', 'hashtags.keyword'],
  };

  getFilter(...args) {
    return {
      ...super.getFilter(...args),
      group: {
        operator: 'IN',
        value: ['articles'],
      },
    };
  }
}
