import React from 'react';
import FormRow from '../../FormRow';
import InputField from '../../FormPageMaker/Fields/InputField';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    [input] = value || [];
  return (
    <FormRow title={title}>
      <InputField
        value={input || ''}
        onChange={(e) => {
          const value = e.target.value;
          onFilterChange({
            value: value ? [value] : undefined,
            operator: 'LIKE',
          });
        }}
      />
    </FormRow>
  );
};
