import React from 'react';
import { translate } from '../../../shared/translate';
import SelectField from './SelectField';
import LSkeleton from '../../LSkeleton';
import { isNil } from 'lodash';

const FiveMinutesField = ({ allowZero, loading, value, onChange = (_) => {}, style, ...props }) => {
  if (loading) return <LSkeleton height={30} />;
  return (
    <SelectField
      value={value}
      style={{
        minWidth: 100,
        ...style,
      }}
      options={[allowZero ? 0 : undefined, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
        .filter((n) => !isNil(n))
        .map((n) => ({
          label: `${n}${translate.minutes}`,
          value: n,
        }))}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      {...props}
    />
  );
};

export default FiveMinutesField;
