import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../../item_EmailTemplate/SplitCard';
import { defaultSuggestions } from '../defaultSuggestion';
import LayoutFront from '../LayoutFront';

export default class LayoutLabel extends LayoutFront {
  type = 'LABEL';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[
                { label: '#index', value: '{{index}}' },
                { label: '#count', value: '{{count}}' },
                { label: '#subtitle', value: '{{subtitle}}' },
                { label: '#description', value: '{{description}}' },
                ...defaultSuggestions,
              ]}
              allows={['isTitle', 'isText', 'isLV', 'isDivider', 'isCut']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              standardWidth={300}
              extraData={{
                index: 1,
                subtitle: 'Iced only',
                description: 'Oolong Tea with Guava Juice and Konjac Jelly',
                remark: '- less ice\n- less sugar',
              }}
              extraRenderer={{}}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
