import React from 'react';
import { MenuQuantitySet } from '../../../list_Product/ListView/MenuQuantitySet';
import { gql } from '@apollo/client';

const ADDON_PRODUCTS_QUANTITY_SET = gql`
  mutation($shopId: ID!, $filter: AddOnProductFilterInput, $query: String, $operator: BatchOperator!, $quantity: Int!) {
    addOnProductsQuantitySet(
      shopId: $shopId
      quantity: $quantity
      operator: $operator
      filter: $filter
      query: $query
    ) {
      id
    }
  }
`;

export default {
  render: (args) => <MenuQuantitySet {...args} mutation={ADDON_PRODUCTS_QUANTITY_SET} />,
};
