import React from 'react';
import { useMeasure } from 'react-use';

const FlexGrid = ({ dynamic, numRows = 2, numColumns = 2, items, renderItem = (item) => null, spacing, style }) => {
  const [ref, { height, width }] = useMeasure();

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: dynamic ? 0 : 1,
        width: '100%',
        overflow: dynamic ? 'auto' : 'hidden',
        ...style,
      }}
    >
      {Array(dynamic ? Math.ceil(((items || {}).length || 0) / numColumns) || numRows : numRows)
        .fill(undefined)
        .map((__, i) => (
          <div key={i} style={{ flex: 1, display: 'flex', marginTop: i > 0 ? spacing : 0 }}>
            {Array(numColumns)
              .fill(undefined)
              .map((__, j) => {
                const _height = (height - (numRows - 1) * spacing) / numRows,
                  _width = width / numColumns - (numColumns - 1) * spacing;

                return (
                  <div
                    key={j}
                    style={{
                      flex: 1,
                      height: dynamic ? 'initial' : _height,
                      width: _width,
                      marginLeft: j > 0 ? spacing : 0,
                    }}
                  >
                    {renderItem((items || {})[i * numColumns + j], { width: _width, height: dynamic ? 0 : _height })}
                  </div>
                );
              })}
          </div>
        ))}
    </div>
  );
};

export default FlexGrid;
