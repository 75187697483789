import { Box, Dialog, Divider, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useBreakPoint from './useBreakPoint';
import { Formik } from 'formik';
import ActivityIndicator from './ActivityIndicator';
import { client } from '../shared/apollo';
import toast from '../shared/toast';
import { translate } from '../shared/translate';
import errorParser from '../shared/errorParser';
import FileUploadField from './FormPageMaker/Fields/FileUploadField';
import FormToolbar from './FormToolbar';
import SystemButton from './SystemButton';
import { textColor } from '../theme';

const CommonImportModal = ({ visible, onOpen, query, id, name, tips, demoFile }) => {
  const breakPoint = useBreakPoint();
  const isUnderSM = ['xs'].includes(breakPoint);

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={visible} onClose={onOpen}>
      <Formik
        initialValues={{ id }}
        onSubmit={async ({ id, file }, { setSubmitting }) => {
          const { s3Path } = file || {};
          try {
            setLoading(true);
            ActivityIndicator.show();
            await client.mutate({
              mutation: query,
              variables: {
                id,
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
              },
            });
            onOpen();
            toast.success(translate.importing_submit);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setLoading(false);
            setSubmitting(false);
            ActivityIndicator.hide();
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
          const { file } = values || {};
          const { fileName, fileSize, s3Path } = file || {};
          const _loading = isSubmitting || loading;
          const onClear = () => setFieldValue('file', null);

          return (
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box
                p={4}
                style={{
                  backgroundColor: '#fff',
                  borderRadius: isUnderSM ? 0 : '0 13px 0 0',
                  boxShadow: '0px 1px 15px 0px #0000001A',
                  flex: 1,
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h6" style={{ color: '#000' }}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 'bold', marginBottom: 1 }}>{translate.upload_file}</Typography>

                    <FileUploadField
                      value={{
                        fileSize,
                        fileName,
                        s3Path,
                      }}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('file', value);
                          setFieldValue('fileName', value.fileName);
                        } else {
                          setFieldValue('file', null);
                        }
                      }}
                      onClear={onClear}
                      loading={_loading}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box p={3} style={{ border: `1px solid #f6f6f6`, borderRadius: 13 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography style={{ fontWeight: 'bold' }}>{translate.tips || '提示'}</Typography>
                            </Grid>
                            {!!tips?.length && (
                              <Grid item xs={12}>
                                <ul style={{ listStyle: 'decimal', color: textColor, paddingLeft: '14px' }}>
                                  {tips.map((tip, index) => (
                                    <li key={index}>
                                      <Typography>{tip}</Typography>
                                    </li>
                                  ))}
                                </ul>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <FormToolbar
                extraLeft={
                  <SystemButton
                    disabled={_loading}
                    onClick={() => {
                      onOpen();
                      onClear();
                    }}
                  >
                    {translate.cancel}
                  </SystemButton>
                }
                extra={
                  <>
                    <SystemButton
                      color="info"
                      disabled={_loading}
                      onClick={async () => {
                        try {
                          setLoading(true);
                          window.open(demoFile);
                        } catch (error) {
                          toast.error(errorParser(error));
                        } finally {
                          setLoading(false);
                        }
                      }}
                    >
                      {translate.download_csv_file || '下載CSV檔案'}
                    </SystemButton>

                    <SystemButton
                      color={'omniewRed'}
                      loading={_loading}
                      disabled={_loading || !fileSize}
                      type={'submit'}
                    >
                      {translate.upload_csv_file || '上傳CSV檔案'}
                    </SystemButton>
                  </>
                }
              />
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default CommonImportModal;
