import React, { useEffect, useState, useRef } from 'react';
import { Card, CardContent } from '@material-ui/core';
import 'react-sortable-tree/style.css';
import SortableTree from 'react-sortable-tree';
import FormToolbar from '../../../components/FormToolbar';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import { client } from 'shared/apollo';
import { SAVE_QUERY } from './query';
import { translate } from 'shared/translate';
import ActivityIndicator from 'components/ActivityIndicator';
import { Skeleton } from '@material-ui/lab';

export default ({ loading, collections: _collections = [], updatedAt }) => {
  const [collections, setCollections] = useState(_collections);
  const [isSubmitting, setSubmitting] = useState(false);
  const tree = useRef(undefined);

  useEffect(() => {
    if (!loading) setCollections(_collections);
  }, [loading]);

  return (
    <>
      <Card>
        <CardContent
          style={{
            height: 'calc(100vh - 285px)',
            overflow: 'auto',
          }}
        >
          {loading ? (
            Array(5 + ~~(10 * Math.random()))
              .fill(undefined)
              .map((__, i) => (
                <Skeleton
                  key={i}
                  height={60}
                  width={275}
                  style={{
                    marginLeft: 43 * ((i % 3) + 1),
                  }}
                />
              ))
          ) : (
            <SortableTree
              ref={tree}
              onMoveNode={({ node, nextPath }) => {
                const find = collections.find((collection) => collection.id === node.id);
                if (!!find && node.id === nextPath[0]) {
                  find.parentId = null;
                  setCollections(collections);
                }
              }}
              treeData={collections}
              onChange={(collections) => {
                setCollections(collections);
              }}
              getNodeKey={({ node }) => node.id}
            />
          )}
        </CardContent>
      </Card>
      <FormToolbar
        disabled={loading || isSubmitting}
        updatedAt={updatedAt}
        submitBtnType={'button'}
        onSave={async () => {
          try {
            setSubmitting(true);
            ActivityIndicator.show();
            await client.mutate({
              mutation: SAVE_QUERY,
              variables: {
                input: flatCollections(collections).map(({ id, parentId }, i) => ({ id, parentId, sortIndex: i })),
              },
            });
            toast.success(translate.update_success);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setSubmitting(false);
            ActivityIndicator.hide();
          }
        }}
      />
    </>
  );
};

function flatCollections(collections) {
  return (collections || []).reduce((reducer, collection) => {
    return reducer.concat(
      collection,
      ...flatCollections((collection.children || []).map((child) => ({ ...child, parentId: collection.id }))),
    );
  }, []);
}
