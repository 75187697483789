import { useLocation } from 'react-router-dom';
import { isJWTValid } from '../../shared';
import { useInterval } from 'react-use';
import { useEffect } from 'react';

const { REACT_APP_MAIN_SITE_URL } = process.env;

export default () => {
  const { pathname } = useLocation();

  function onInterval() {
    localStorage.removeItem('xss_token');
    try {
      //@ts-ignore
      window.html2canvas = null;
    } catch (e) {}

    const token = localStorage.getItem('token');
    if (!isJWTValid(token)) {
      if (pathname !== '/login') {
        window.location.href =
          `${REACT_APP_MAIN_SITE_URL}/login?redirectUrl=${window.location.origin}/login` || '/login';
      }
    }
  }

  useInterval(onInterval, 2000);

  useEffect(() => {
    onInterval();
    localStorage.setItem('latestPathname', pathname);
  }, [pathname]);

  return null;
};
