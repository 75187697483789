import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import CustomTimeField from '../../../components/FormPageMaker/Fields/CustomTimeField';

const TriggerFieldTimeRange = ({ disabled, trigger = {}, onChange = (_) => _ }) => {
  const { triggerIds = [] } = trigger ?? {};

  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item>
        <Typography>{translate.available_date_from}</Typography>
      </Grid>
      <Grid item xs={true}>
        <CustomTimeField
          disabled={disabled}
          value={triggerIds?.[0]}
          onChange={(v) => {
            trigger.triggerIds = [v, trigger.triggerIds?.[1]];
            onChange(trigger);
          }}
          required={true}
        />
      </Grid>
      <Grid item>
        <Typography>{translate.available_date_to}</Typography>
      </Grid>
      <Grid item xs={true}>
        <CustomTimeField
          disabled={disabled}
          value={triggerIds?.[1]}
          onChange={(v) => {
            trigger.triggerIds = [trigger.triggerIds?.[0], v];
            onChange(trigger);
          }}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export default TriggerFieldTimeRange;
