import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../SplitCard';
import EmailWelcome from '../EmailWelcome';
import defaultSuggestions from '../defaultSuggestions';

export default class EmailPasswordReset extends EmailWelcome {
  emailType = 'PASSWORD_RESET';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[
                ...defaultSuggestions,
                {
                  label: '#token',
                  value: '{{token}}',
                },
              ]}
              allows={['isTitle', 'isText', 'isImage', 'isDivider', 'isButton', 'isLink', 'isQRCode']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              extraData={{
                token: '00000000000000000000000000000000',
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
