import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        popups {
          nodes {
            id
            updatedAt
            href
            content
            active
            validAt
            validThru
          }
        }
      }
    }
  }
`;

export const CREATE_QUERY = gql`
  mutation ($input: ShopPopupCreateInput!) {
    shopPopupCreate(input: $input) {
      id
      active
      content
      href
      shopId
      validAt
      validThru
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ShopPopupUpdateInput!) {
    shopPopupUpdate(id: $id, input: $input) {
      id
      active
      content
      href
      shopId
      validAt
      validThru
    }
  }
`;
