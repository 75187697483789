import React from 'react';
import { INVOICE_REFUND } from './query';
import { useMutation } from '@apollo/client';
import { translate } from '../../../shared/translate';
import { toast } from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import ActivityIndicator from '../../../components/ActivityIndicator';
import confirmation from '../../../shared/confirmation';
import { PriceFormat } from '../../../shared';
import SystemButton from '../../../components/SystemButton';

export default ({ isSubmitting, setSubmitting, invoice: { id, total, paymentMethodV2 } = {} }) => {
  const { name } = paymentMethodV2 || {};
  const [invoiceRefund] = useMutation(INVOICE_REFUND);
  return (
    <SystemButton
      disabled={isSubmitting}
      size={'small'}
      variant={'contained'}
      color={'secondary'}
      onClick={async () => {
        try {
          if (await confirmation(translate.formatString(translate.refund_remark, `\n${name}\n${PriceFormat(total)}`))) {
            ActivityIndicator.show();
            setSubmitting(true);
            await invoiceRefund({
              variables: { id },
            });
            toast.success(translate.refunded);
          }
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          ActivityIndicator.hide();
          setSubmitting(false);
        }
      }}
    >
      {translate.refund}
    </SystemButton>
  );
};
