import { gql } from '@apollo/client';

export const FRAGMENT_SHOP_DEVICE = gql`
  fragment FRAGMENT_SHOP_DEVICE on ShopDevice {
    id
    updatedAt
    createdAt
    active
    configs
    defaultConfigs
    description
    deviceId
    lastLogin
    metadata {
      key
      value
    }
    name
    status
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOP_DEVICE}
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_SHOP_DEVICE
    }
  }
`;

export const CREATE_QUERY = gql`
  mutation ($input: ShopDeviceCreateInput!) {
    shopDeviceCreate(input: $input) {
      ...FRAGMENT_SHOP_DEVICE
    }
  }
  ${FRAGMENT_SHOP_DEVICE}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID, $input: ShopDeviceUpdateInput!) {
    shopDeviceUpdate(id: $id, input: $input) {
      ...FRAGMENT_SHOP_DEVICE
    }
  }
  ${FRAGMENT_SHOP_DEVICE}
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    shopDeviceDelete(id: $id) {
      id
    }
  }
`;
