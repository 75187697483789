import { gql } from '@apollo/client';

export const FRAGMENT_ORDER = gql`
  fragment FRAGMENT_ORDER on ShopOrder {
    id
    status
    referenceNo
    shop {
      id
      name
      customDomain
      hostname
    }
    total
    totalRefund
    shippingAddress {
      name
      person
      email
      tel
      country
    }
    customer {
      id
      email
      metadata {
        key
        value
      }
    }
    paymentStatus
    shippingStatus
    createdAt
    updatedAt
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $query: String, $cursor: Int, $limits: Int, $filter: OrderFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Company {
        id
        orders(query: $query, cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          totalAmount
          nodes {
            ...FRAGMENT_ORDER
          }
        }
      }
    }
  }
  ${FRAGMENT_ORDER}
`;
