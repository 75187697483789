import { gql } from '@apollo/client';

export const CONFIRM_QUERY = gql`
  mutation($id: ID!) {
    orderConfirm(id: $id) {
      id
      status
    }
  }
`;
