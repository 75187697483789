import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY, GET_REPORT_QUERY } from './query';
import ActiveSwitcher from '../ListView/ActiveSwitcher';
import EllipsisTypography from '../../../components/EllipsisTypography';
import MenuExportAll from '../../list_Staff/ListView/MenuExportAll';
import moment from 'moment';
import { convertMetaObject, parseSecondDuration, PriceFormat } from '../../../shared';
import _ from 'lodash';
import MonthlyTargetProgress from './MonthlyTargetProgress';
import { Query } from '@apollo/client/react/components';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/staffs/new',
    modalExportOpen: false,
    selectionMode: true,
    getRowLink: ({ id, name }) => ({ pathname: `/staffs/${id}`, state: { title: name } }),
    fields: [
      {
        title: translate.profile_pic || '頭像',
        fieldName: 'thumbnail',
        type: 'media',
        width: 50,
      },
      {
        title: translate.employee_name || '員工姓名',
        fieldName: 'name',
        type: 'text',
        filter: 'name',
      },
      {
        title: translate.today_attendance,
        value: ({ attendSecondsByDay }) => {
          const attendSeconds = attendSecondsByDay?.seconds ?? 0;
          if (!attendSeconds) return '-';
          const { hours, minutes, seconds } = parseSecondDuration(attendSeconds);

          return [
            hours > 0 && `${hours} ${translate.hours}`,
            minutes > 0 && `${minutes} ${translate.minutes}`,
            seconds > 0 && hours <= 0 && minutes <= 0 && translate.less_then_1_min,
          ]
            .filter(Boolean)
            .join(' ');
        },
      },
      {
        title: translate.employee_position || '員工職位',
        width: 100,
        render: ({ role }) => <EllipsisTypography noWrap>{translate[`${role.toLowerCase()}`]}</EllipsisTypography>,
        type: 'option',
        filter: 'role',
        options: [
          { label: translate.manager, value: 'MANAGER' },
          { label: translate.staff, value: 'STAFF' },
        ],
        sortBy: 'role',
      },
      {
        title: translate.employee_id || '員工編號',
        fieldName: 'code',
        type: 'text',
        width: 100,
        filter: 'code',
        sortBy: 'code',
      },
      {
        title: translate.monthly_target || '每月目標',
        width: 500,
        render: (row) => {
          if (!!convertMetaObject(row.metadata ?? []).monthlyTarget) {
            const percentage = Math.round(
              +row?.amount >= +convertMetaObject(row.metadata ?? []).monthlyTarget
                ? 100
                : (+row?.amount / +convertMetaObject(row.metadata ?? []).monthlyTarget) * 100,
            );

            return (
              <MonthlyTargetProgress
                percentage={percentage}
                amount={+row?.amount}
                monthlyTarget={+convertMetaObject(row.metadata ?? []).monthlyTarget}
              />
            );
          }
          return '-';
        },
      },
      {
        title: translate.date_of_employment || '入職日期',
        fieldName: 'startedAt',
        align: 'right',
        type: 'datetime',
        width: 220,
        sortBy: 'startedAt',
        filter: 'startedAt',
      },
      {
        title: translate.phone_no || '電話號碼',
        fieldName: 'phoneNumber',
        width: 140,
        type: 'text',
      },
      {
        title: translate.employment_status || '在職狀態 ',
        width: 80,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} data={data} onCompleted={this.refetch} />;
        },
        type: 'bool',
        sortBy: 'active',
        noLink: true,
      },
    ],
    menus: (() => {
      return [MenuExportAll].filter((_) => _);
      // return [MenuExportAll, MenuImportAll].filter((_) => _);
    })(),
  };

  getExtraFetchVariables() {
    return {
      id: localStorage.getItem('companyId'),
      createdAt: moment().startOf('day').toISOString(),
      createdThru: moment().endOf('day').toISOString(),
    };
  }

  getData = (data) => {
    return data?.node?.staffs;
  };

  renderTable(connection) {
    const staffIds = connection?.nodes?.map(({ id }) => id) ?? [];
    if (!!connection?.nodes?.length)
      return (
        <Query
          query={GET_REPORT_QUERY}
          variables={{
            query: `id: ${staffIds.join(' ')}`,
            id: localStorage.getItem('companyId'),
            startedAt: moment().startOf('month').startOf('day').toISOString(),
            startedThru: moment().endOf('day').toISOString(),
          }}
        >
          {({ loading, data }) => {
            return super.renderTable({
              ...connection,
              nodes: connection.nodes.map((node) => {
                const matchedData = data?.node?.report?.topSalespersonSales?.nodes.find((item) => item.id === node.id);
                return {
                  ...node,
                  amount: matchedData?.amount ?? 0,
                };
              }),
            });
          }}
        </Query>
      );
  }
}
