import React from 'react';
import { TableHead, TableRow, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import StyledTableCell from './StyledTableCell';

const TableHeader = (props) => (
  <TableHead>
    <TableRow>
      <StyledTableCell>
        <Typography variant={'h6'}>{translate.product_sku}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant={'h6'} align={'center'}>
          {translate.image}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant={'h6'}>{translate.name}</Typography>
      </StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell>
        <Typography variant={'h6'} style={{ textAlign: 'right' }}>
          {translate.price}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant={'h6'} style={{ textAlign: 'center' }}>
          {translate.quantity}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant={'h6'} style={{ textAlign: 'left' }}>
          {translate.total}
        </Typography>
      </StyledTableCell>
    </TableRow>
  </TableHead>
);

export default TableHeader;
