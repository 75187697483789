import React from 'react';
import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { sleep } from '../../../shared';
import InventoryRecordReference from './InventoryRecordReference';

class StockMovementsCard extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.created_at,
        fieldName: 'createdAt',
        type: 'datetime',
        width: 180,
      },
      {
        title: translate.product_sku,
        fieldName: 'sku',
      },
      {
        title: translate.product_options,
        fieldName: 'combination',
      },
      {
        title: translate.inventory_record,
        width: 100,
        align: 'center',
        type: 'number',
        value: (row) => (row?.direction === 'INBOUND' ? row?.quantity : -row?.quantity),
      },
      {
        title: translate.reference_no,
        width: 200,
        value: (row) => {
          if (!!row?.reference)
            return <InventoryRecordReference reference={row?.reference} referenceSourceNo={row?.referenceSourceNo} />;
          return null;
        },
      },
    ],
    limits: 20,
  };

  getData({ node } = {}) {
    const { stockMovements } = node || {};
    return stockMovements;
  }

  getExtraFetchVariables() {
    const { values } = this.props;
    return { id: values?.id };
  }

  getFetchPolicy() {
    return 'cache-first';
  }

  getSkip() {
    const { values } = this.props;
    return !values?.id;
  }

  renderWrapper(...args) {
    return <div style={{ width: '100%' }}>{this.renderContent(...args)}</div>;
  }

  getQueryParams = () => {
    const { queryParams } = this.state;
    return queryParams ?? {};
  };
  getQueryParam = (key) => {
    const { queryParams } = this.state;
    return queryParams?.[key];
  };
  patchQueryParams = (nextParams) => {
    const { queryParams } = this.state;

    if (typeof nextParams === 'function') {
      nextParams = nextParams(queryParams);
    }
    this.setState({
      queryParams: {
        ...queryParams,
        ...nextParams,
      },
    });
    sleep(0).then(() => {
      this.setState(this.getQueryState());
    });
  };
}

export default StockMovementsCard;
