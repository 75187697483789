import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query($id: ID!, $skus: [String!]!) {
    node(id: $id) {
      ... on CompanyShop {
        products(filter: { sku: { operator: IN, value: $skus } }) {
          nodes {
            id
            name
            sku
          }
        }
      }
    }
  }
`;

export const GET_SHIPPING_ZONE = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on ShopShippingZone {
        id
        name
      }
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query($id: ID!, $codes: [String!]!) {
    node(id: $id) {
      ... on CompanyShop {
        collections(filter: { code: { operator: IN, value: $codes } }) {
          nodes {
            id
            name
            code
            parents {
              id
              name
            }
          }
        }
        collections2: collections(filter: { id: { operator: IN, value: $codes } }) {
          nodes {
            id
            name
            code
            parents {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_TIER = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyMemberTier {
        name
      }
    }
  }
`;
