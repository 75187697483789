import React from 'react';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';
import { translate } from '../../../shared/translate';
import DetailsCard from '../DetailsCard';
import { getCollectionLabel } from '../../../components/SelectCombo/SelectComboCollection';
import _ from 'lodash';
import { hasCombinations } from '../hasCombinations';
import { extractTextFromHTML } from '../../../shared';
import ChatGptCard from './ChatGptCard';

const DescriptionCard = (actions) => {
  return (
    <>
      <FormCard
        actions={actions}
        fields={[
          {
            label: translate.description,
            render: (actions) => <ChatGptCard {...actions} />,
          },
          {
            label: translate.product_details,
            render: (actions) => <DetailsCard {...actions} />,
          },
          {
            label: translate.print_description,
            type: 'textarea',
            name: 'printDescription',
          },
        ]}
      />
    </>
  );
};

export default DescriptionCard;

export const getProductDescriptionPrompt = (values) => {
  const hasComb = hasCombinations(values.combinations);
  return [
    values.name && `${translate.product_name}: ${values.name}`,
    values.subtitle && `${translate.title}: ${values.subtitle}`,
    values.collections?.length &&
      `${translate.product_collections}: ${values.collections?.map(getCollectionLabel).join(', ')}`,
    values.hashTagsArray?.length && `${translate.hash_tag}: ${values.hashTagsArray.join(', ')}`,
  ]
    .concat(
      !hasComb
        ? [
            !_.isNil(values?.variations?.[0]?.suggestedRetailPrice) &&
              `${translate.suggested_retail_price}: ${values?.variations?.[0]?.suggestedRetailPrice}`,
            !_.isNil(values?.variations?.[0]?.unitPrice) && `${translate.price}: ${values?.variations?.[0]?.unitPrice}`,
          ]
        : [
            `\n可選組合`,
            ...values?.variations?.map((variation) => {
              const { combination } = variation ?? {};
              return `- ${combination?.map((comb) => `${comb?.name}:${comb?.option}`).join(' + ')} [${
                translate.price
              }: ${variation?.unitPrice}]`;
            }),
          ],
    )
    .concat([`\n${extractTextFromHTML(values.description ?? '')}`])
    .filter((_) => _)
    .join('\n');
};
