import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import SelectCombo from './index';
import _ from 'lodash';
import { parseConnection } from '../../shared';

const SEARCH_QUERY = gql`
  query ($id: ID!, $filter: PaymentMethodFilterInput, $cursor: Int, $limits: Int, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        paymentMethods(filter: $filter, cursor: $cursor, limits: $limits, sortBy: $sortBy) {
          nextCursor
          nodes {
            id
            name
            provider
            active
            credential {
              id
            }
          }
        }
      }
    }
  }
`;

const SelectComboPaymentMethod = ({ filter, ...props }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { loading, data, fetchMore } = useQuery(SEARCH_QUERY, {
      variables: {
        filter: !!search
          ? {
              ...filter,
              name: {
                operator: 'LIKE',
                value: [`%${search}%`],
              },
            }
          : { ...filter },
        id: localStorage.getItem('shopId'),
      },
      skip: !open,
    }),
    { nodes, nextCursor } = getConnection(data);
  function getConnection(data) {
    const { node } = data || {};
    return parseConnection((node || {}).paymentMethods);
  }

  return (
    <SelectCombo
      onOpen={setOpen}
      getOptionSelected={(a, b) => (a || {}).id === (b || {}).id}
      onSearchChange={setSearch}
      getOptionLabel={(option) => option?.name ?? ''}
      options={nodes || []}
      loading={loading}
      onScrollBottom={async () => {
        if (!!nextCursor && !loading)
          await fetchMore({
            variables: {
              cursor: nextCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              const fetchMoreConnection = getConnection(fetchMoreResult),
                { nodes: fetchMoreNodes } = fetchMoreConnection,
                { nodes: prevNodes } = getConnection(prevResult);
              fetchMoreConnection.nodes = _.uniqBy([...prevNodes, ...fetchMoreNodes], 'id');

              return {
                node: {
                  ...(fetchMoreResult.node || {}),
                  customers: fetchMoreConnection,
                },
              };
            },
          });
      }}
      {...props}
    />
  );
};

export default SelectComboPaymentMethod;
