import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { infinityFetch } from '../shared';
import ActivityIndicator from './ActivityIndicator';
import { translate } from '../shared/translate';

export const GET_COLLECTIONS_BATCH = gql`
  query ($id: ID!, $limits: Int, $cursor: Int, $filter: CollectionFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        collections(limits: $limits, cursor: $cursor, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            id
            title: name
            sortIndex
            name
            parentId
            active
            updatedAt
          }
        }
      }
    }
  }
`;

export const fetchCollectionsAll = async (options = {}) => {
  return await infinityFetch({
    query: GET_COLLECTIONS_BATCH,
    variables: {
      id: localStorage.getItem('shopId'),
      limits: 50,
      filter: { active: { operator: 'IN', value: ['true'] } },
      sortBy: [{ field: 'sortIndex', order: 'ASC' }],
    },
    getConnection: (data) => {
      return ((data || {}).node || {}).collections;
    },
    onProgress: (downloaded, total) => {
      if (downloaded < total) ActivityIndicator.show(`${translate.download} ${downloaded} / ${total}`);
      else ActivityIndicator.hide();
    },
    ...options,
  });
};

export const useFullCollections = () => {
  const [loading, setLoading] = useState(true);
  const [connection, setConnection] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setConnection(await fetchCollectionsAll());
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, collections: (connection || {}).nodes };
};
export default useFullCollections;
