import React from 'react';
import StyledTableCell from './StyledTableCell';
import { TableRow, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import moment from 'moment';
import { PriceFormat } from '../../../shared';

const RowServiceBundleOrderItem = ({ item }) => {
  const { id, unitPrice, quantity, media, serviceBundle: _serviceBundle } = item ?? {},
    { serviceBundle, services = [] } = _serviceBundle ?? {};
  const image = media?.src;

  return (
    <TableRow hover>
      <StyledTableCell>{serviceBundle?.sku}</StyledTableCell>
      <StyledTableCell style={{ textAlign: 'center' }}>
        <img
          src={image || require('../../../assets/gray.jpg')}
          style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: 30 }}
          alt={''}
        />
      </StyledTableCell>
      <StyledTableCell>
        {services?.map((checkoutService, i) => {
          const { location, slots = [], options = [] } = checkoutService ?? {};
          return (
            <div key={i}>
              <Typography variant={'body2'}>
                <b>{translate.location}: </b>
                {location?.name}
              </Typography>
              <Typography variant={'body2'}>
                <b>{translate.time}: </b>
                {moment(slots?.[0]?.startedAt).format('DD MMM YYYY HH:mm')} -{' '}
                {moment(slots[slots?.length - 1]?.startedThru).format('HH:mm A')}
              </Typography>
              <hr style={{ borderStyle: 'dashed' }} />
              {options.map((option, i) => {
                return (
                  <Typography key={i} variant={'body2'}>
                    <b>{option?.label}: </b>
                    {option?.product?.name}
                  </Typography>
                );
              })}
            </div>
          );
        })}
      </StyledTableCell>
      <StyledTableCell>
        <Typography style={{ textAlign: 'right' }}>{PriceFormat(unitPrice - 0)}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography style={{ textAlign: 'center' }}>{quantity}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography style={{ textAlign: 'left' }}>{PriceFormat((unitPrice - 0) * quantity)}</Typography>
      </StyledTableCell>
    </TableRow>
  );
};

export default RowServiceBundleOrderItem;
