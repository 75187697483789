import React from 'react';
import PromisifyModal from '../../../../components/PromisifyModal';
import { translate } from '../../../../shared/translate';
import FormRow from '../../../../components/FormRow';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';
import { DialogContent, FormHelperText, Grid, Typography, withTheme } from '@material-ui/core';
import FormToolbar from '../../../../components/FormToolbar';

export default withTheme(
  class SkuGenerator extends PromisifyModal {
    onSubmit = async (values) => {
      return values;
    };
    getInitialData = () => {
      return {
        prefix: '',
        suffix: '',
        start: 0,
        length: 5,
      };
    };
    renderHeader() {
      return null;
    }
    renderBody({ values, setFieldValue }) {
      const { theme } = this.props;
      return (
        <>
          <DialogContent>
            <FormRow title={translate.prefix}>
              <InputField
                value={values.prefix}
                onChange={(e) => setFieldValue('prefix', e.target.value)}
                // eslint-disable-next-line
                inputProps={{
                  pattern: '[a-zA-Z0-9]*',
                }}
              />
              <FormHelperText>{translate.only_allow_char_and_number}</FormHelperText>
            </FormRow>
            <Grid container spacing={2}>
              <FormRow title={translate.length_count} required={true} md={4}>
                <InputField
                  required={true}
                  type={'number'}
                  // eslint-disable-next-line
                  inputProps={{
                    min: 0,
                    step: 1,
                  }}
                  value={values.length}
                  onChange={(e) => setFieldValue('length', e.target.value)}
                />
              </FormRow>
              <FormRow title={translate.start_number} required={true} md={8}>
                <InputField
                  required={true}
                  type={'number'}
                  // eslint-disable-next-line
                  inputProps={{
                    min: 0,
                    step: 1,
                  }}
                  value={values.start}
                  onChange={(e) => setFieldValue('start', e.target.value)}
                />
              </FormRow>
            </Grid>
            <FormRow title={translate.suffix}>
              <InputField
                value={values.suffix}
                onChange={(e) => setFieldValue('suffix', e.target.value)}
                // eslint-disable-next-line
                inputProps={{
                  pattern: '[a-zA-Z0-9]*',
                }}
              />
              <FormHelperText>{translate.only_allow_char_and_number}</FormHelperText>
            </FormRow>
          </DialogContent>
          <DialogContent style={{ backgroundColor: theme.palette.background.default }}>
            <FormRow style={{ marginBottom: 0 }} direction={'row'} title={translate.preview} alignItems={'center'}>
              <Typography variant={'h6'} align={'center'} style={{ fontWeight: 'bold' }}>
                {values.prefix} z{`${values.start || 0}`.padStart(values.length, '0')}
                {values.suffix}
              </Typography>
            </FormRow>
          </DialogContent>
        </>
      );
    }
    renderFooter({ isSubmitting }) {
      return (
        <>
          <FormToolbar
            disabled={isSubmitting}
            justify={'center'}
            submitBtnType={'submit'}
            onCancel={this.onCancelClick}
          />
        </>
      );
    }
  },
);
