import { gql } from '@apollo/client';
import { FRAGMENT_ORDER_ITEM } from '../item_Order/query';

export const FRAGMENT_RETURN_NOTE_ITEM = gql`
  fragment FRAGMENT_RETURN_NOTE_ITEM on ReturnNoteItem {
    id
    createdAt
    updatedAt
    active
    metadata {
      key
      value
    }
    bundleProductOptionId
    description
    orderId
    orderItemId
    quantity
    sortIndex
    remark
    reason
    resalable
    sku
    unitPrice
  }
`;
export const FRAGMENT_RETURN_FIND_ORDER = gql`
  fragment FRAGMENT_RETURN_FIND_ORDER on ShopOrder {
    id
    referenceNo
    total
    customer {
      id
      email
      metadata {
        key
        value
      }
    }
    shop {
      id
      name
    }
    items {
      ...FRAGMENT_ORDER_ITEM
    }
    returnNoteItems(limits: 999) {
      nodes {
        ...FRAGMENT_RETURN_NOTE_ITEM
      }
    }
  }
  ${FRAGMENT_ORDER_ITEM}
  ${FRAGMENT_RETURN_NOTE_ITEM}
`;

export const FRAGMENT_RETURN_NOTE = gql`
  fragment FRAGMENT_RETURN_NOTE on ShopReturnNote {
    id
    updatedAt
    createdAt
    active
    metadata {
      key
      value
    }
    referenceNo
    shop {
      id
      name
    }
    order {
      ...FRAGMENT_RETURN_FIND_ORDER
    }
    status
    remark
    items(limits: 999) {
      nodes {
        ...FRAGMENT_RETURN_NOTE_ITEM
      }
    }
  }
  ${FRAGMENT_RETURN_FIND_ORDER}
  ${FRAGMENT_RETURN_NOTE_ITEM}
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_RETURN_NOTE
    }
  }
  ${FRAGMENT_RETURN_NOTE}
`;

export const CREATE_QUERY = gql`
  mutation ($input: ReturnNoteCreateInput!) {
    returnNoteCreate(input: $input) {
      ...FRAGMENT_RETURN_NOTE
    }
  }
  ${FRAGMENT_RETURN_NOTE}
`;
export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: ReturnNoteUpdateInput!) {
    returnNoteUpdate(id: $id, input: $input) {
      ...FRAGMENT_RETURN_NOTE
    }
  }
  ${FRAGMENT_RETURN_NOTE}
`;

export const APPROVE_QUERY = gql`
  mutation ($id: ID!) {
    returnNoteApprove(id: $id) {
      id
      status
    }
  }
`;
export const REJECT_QUERY = gql`
  mutation ($id: ID!) {
    returnNoteReject(id: $id) {
      id
      status
    }
  }
`;

export const CANCEL_QUERY = gql`
  mutation ($id: ID!) {
    returnNoteCancel(id: $id) {
      id
      status
    }
  }
`;

export const COMPLETE_QUERY = gql`
  mutation ($id: ID!) {
    returnNoteComplete(id: $id) {
      id
      status
    }
  }
`;
