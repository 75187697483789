import React from 'react';
import { Skeleton } from '@material-ui/lab';
import LSkeleton from '../../components/LSkeleton';
import SelectComboWarehouse from '../../components/SelectCombo/SelectComboWarehouse';
import StatusBadge from '../../components/StatusBadge';
import { convertMetaArray, convertMetaObject, parseConnection } from '../../shared';
import { StockAdjustmentStatus } from '../../shared/omniwe-types';
import { translate } from '../../shared/translate';
import ItemsCard from './ItemsCard';
import { GET_QUERY, CREATE_QUERY, UPDATE_QUERY } from './query';
import StockOutCompleteButton from './StockOutCompleteButton';
import { checkPermissionV2 } from '../../components/PermissionMask';
import { client } from '../../shared/apollo';
import StockOutCancelButton from './StockOutCancelButton';
import StockOutVoidButton from './StockOutVoidButton';
import ItemReceivePurchase from '../item_ReceivePurchase';
import { CardContent, Divider, Typography } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SystemButton from '../../components/SystemButton';
import { ReactComponent as newPrintIcon } from '../../assets/icon/newPrint.svg';
import { Icon } from '../../components/IconRender';
import StockOutImportButton from './StockOutImportButton';

class ItemStockAdjustment extends ItemReceivePurchase {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: true,
    },
    tabs: [
      {
        name: translate.stock_adjustment || '庫存調整',
        cards: [
          {
            fields: [
              {
                label: translate.status,
                render: ({ loading, values: { status } }) =>
                  loading ? (
                    <Skeleton width={100} height={30} />
                  ) : (
                    <StatusBadge status={status} statusObject={StockAdjustmentStatus} />
                  ),
                md: 12,
              },
              {
                label: translate.outbound_date,
                name: 'completedAt',
                disabled: true,
                type: 'datetime-local',
                md: 6,
              },
              {
                label: translate.reference_no,
                disabled: true,
                name: 'referenceNo',
                type: 'text',
                md: 6,
              },
              {
                label: translate.from_warehouse,
                required: true,
                md: 6,
                render: ({ loading, values, setFieldValue, disabled }) =>
                  loading ? (
                    <LSkeleton height={30} />
                  ) : (
                    <SelectComboWarehouse
                      value={values.warehouse}
                      required={true}
                      onChange={(v) => setFieldValue('warehouse', v)}
                      disabled={disabled || values.status !== 'PENDING'}
                    />
                  ),
              },
              {
                md: 12,
                render: ({ loading, values, disabled, setFieldValue }) => {
                  const allowEdit = !values?.id || (this.state.dirty && values?.status === 'PENDING');
                  return (
                    <CardContent style={{ backgroundColor: '#f6f6f6', borderRadius: 10, padding: 30 }}>
                      <Typography variant={'h6'}>{translate.remark}</Typography>
                      <Divider style={{ margin: '10px 0 15px 0' }} />
                      {loading ? (
                        <LSkeleton height={30} />
                      ) : (
                        <InputField
                          variant={allowEdit ? 'outlined' : 'standard'}
                          InputProps={{
                            disableUnderline: allowEdit ? false : true,
                          }}
                          value={values.remark}
                          disabled={disabled || values?.status !== 'PENDING'}
                          onChange={(v) => setFieldValue('remark', v?.target?.value)}
                        />
                      )}
                    </CardContent>
                  );
                },
              },
            ],
          },
          ItemsCard,
        ],
      },
    ],
  };

  submit = async (values) => {
    const items =
      values.items
        ?.map((item, i) => ({
          id: item?.id,
          cost: item?.cost,
          metadata: convertMetaArray(item?.metadata),
          quantity: -1 * Math.abs(item?.quantity || 0),
          remark: item?.remark,
          sku: item?.sku,
          sortIndex: i,
        }))
        ?.filter((item) => !!item?.sku) ?? [];

    if (!items?.length) throw new Error('Must have items');

    await client.mutate({
      mutation: values.id ? UPDATE_QUERY : CREATE_QUERY,
      variables: {
        id: values.id,
        input: {
          items,
          metadata: convertMetaArray(values.metadata),
          remark: values.remark,
          shopId: values.id ? undefined : localStorage.getItem('shopId'),
          warehouseId: values.warehouse?.id,
        },
      },
    });
    return true;
  };
  getInitialData({ node } = {}) {
    const {
      id,
      staff,
      remark,
      warehouse,
      updatedAt,
      completedAt,
      items,
      status = 'PENDING',
      referenceNo,
      metadata,
    } = node || {};
    return {
      id,
      referenceNo,
      staff,
      remark,
      warehouse,
      updatedAt,
      completedAt,
      status,
      items: parseConnection(items).nodes.map((node) => ({
        ...node,
        quantity: Math.abs(node?.quantity || 1),
      })),
      metadata: convertMetaObject(metadata),
    };
  }

  renderExtraButtons(actions) {
    const { values: { id, status, __disabled } = {} } = actions || {};
    return (
      <>
        {!!id && (
          <>
            {checkPermissionV2('completeStockOut') && status === 'PENDING' && __disabled && (
              <StockOutImportButton id={id} />
            )}
            {!!id && (
              <SystemButton
                onClick={(e) => {
                  e.stopPropagation();
                  window && window.open(`/print_stock_out/${id}`, '_blank');
                }}
              >
                <Icon type="svg" icon={newPrintIcon} viewBox={'0 0 21 21'} style={{ height: 20.44 }} />
              </SystemButton>
            )}
            {checkPermissionV2('completeStockOut') && status === 'PENDING' && __disabled && (
              <StockOutCompleteButton {...actions} />
            )}
            {checkPermissionV2('cancelStockOut') && status === 'PENDING' && <StockOutCancelButton {...actions} />}
            {checkPermissionV2('voidStockOut') && status === 'COMPLETED' && <StockOutVoidButton {...actions} />}
          </>
        )}
      </>
    );
  }
}

export default ItemStockAdjustment;
