import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { CREATE_QUERY, GET_QUERY, REMOVE_QUERY } from './query';
import { client } from '../../shared/apollo';
import { cloneObject } from '../../shared';
import LSkeleton from '../../components/LSkeleton';
import CouponsField from './CouponsField';
import CampaignActionSwitcher from './CampaignActionSwitcher';
import { Card, Typography } from '@material-ui/core';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.broadcast_settings || '廣播設定',
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                name: 'name',
                required: true,
                maxLength: 25,
              },
              {
                label: translate.targetHashtags,
                type: 'hashTagsArray',
                name: 'targetHashtags',
                required: true,
                placeholder: translate.target_hash_tag_description,
              },
              {
                label: translate.match_all_hashTags_remark,
                type: 'checkbox',
                name: 'isTargetMatchModeAll',
                block: true,
                style: { marginTop: '-50px' },
              },
              {
                label: translate.excludeHashtags,
                type: 'hashTagsArray',
                name: 'excludedHashtags',
                placeholder: translate.excluded_hash_tag_description,
              },
              {
                label: translate.match_all_exclude_hashTags_remark,
                type: 'checkbox',
                name: 'isExcludedMatchModeAll',
                block: true,
                style: { marginTop: '-50px' },
              },
              // {
              //   md: 'auto',
              //   label: translate.actionType,
              //   required: true,
              //   type: 'select',
              //   render: ({ loading, values: { actionTypes }, setFieldValue, isSubmitting }) => {
              //     if (loading) return <LSkeleton height={50} width={300} />;
              //     return (
              //       <ActionTypesField
              //         disabled={isSubmitting}
              //         value={actionTypes}
              //         onChange={(v) => {
              //           console.log('actionTypes: ', v);
              //           setFieldValue('actionTypes', v);
              //         }}
              //       />
              //     );
              //   },
              //   description: ({ values }) =>
              //     ({
              //       PUSH: translate.push_notification_remark,
              //       SMS: translate.send_sms_remark,
              //     }[values.actionType] || undefined),
              // },
              {
                md: 'auto',
                label: translate.actionType,
                required: true,
                type: 'select',
                render: ({ loading, values: { actionTypes }, setFieldValue, isSubmitting }) => {
                  if (loading) return <LSkeleton height={50} width={300} />;
                  return (
                    <Card
                      variant="outlined"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 30px',
                        width: '180px',
                        height: '120px',
                        borderRadius: '15px',
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 'Bold',
                          marginTop: '10px',
                          textAlign: 'center',
                          width: '100%',
                        }}
                      >
                        {translate.mass_messaging || '站內推送'}
                      </Typography>
                      <CampaignActionSwitcher
                        disabled={isSubmitting}
                        value={actionTypes}
                        onChange={(v) => {
                          console.log('actionTypes: ', v);
                          setFieldValue('actionTypes', v);
                        }}
                      />
                    </Card>
                  );
                },
                description: ({ values }) =>
                  ({
                    PUSH: translate.push_notification_remark,
                    SMS: translate.send_sms_remark,
                  }[values.actionType] || undefined),
              },
              // {
              //   md: '12',
              //   label: translate.actionType,
              //   required: true,
              //   type: 'switch',
              //   render: ({ loading, values: { actionTypes }, setFieldValue, isSubmitting }) => {
              //     if (loading) return <LSkeleton height={50} width={300} />;
              //     return (
              //       <Grid
              //         container
              //         style={{
              //           marginTop: '10px',
              //           gap: '30px',
              //         }}
              //       >
              //         {actionTypes.map((actionType, index) => (
              //           <Card
              //             variant="outlined"
              //             style={{
              //               display: 'flex',
              //               flexDirection: 'column',
              //               justifyContent: 'center',
              //               alignItems: 'center',
              //               padding: '10px 30px',
              //               width: '140px',
              //               height: '100px',
              //               borderRadius: '15px',
              //             }}
              //           >
              //             <Typography
              //               style={{
              //                 fontWeight: 'Bold',
              //                 marginTop: '10px',
              //                 textAlign: 'center',
              //                 width: '100%',
              //               }}
              //             >
              //               {actionType === 'PUSH'
              //                 ? 'APP 推送'
              //                 : actionType === 'EMAIL'
              //                 ? '電郵'
              //                 : actionType === 'SMS'
              //                 ? 'SMS 短訊'
              //                 : null}
              //             </Typography>
              //             <CampaignActionSwitcher actionType={actionType} />
              //           </Card>
              //         ))}
              //       </Grid>
              //     );
              //   },
              // },
              {
                label: translate.content,
                type: 'textarea',
                name: 'content',
                required: true,
              },
              // {
              //   label: translate.email_content,
              //   type: 'html',
              //   name: 'richContent',
              //   required: true,
              //   display: ({ values: { actionTypes = [] } }) => !!~actionTypes.indexOf('EMAIL'),
              // },
              {
                label: translate.attached_coupon,
                render: ({ loading, values: { coupons }, setFieldValue, isSubmitting }) =>
                  loading ? (
                    <LSkeleton height={30} />
                  ) : (
                    <CouponsField
                      disabled={isSubmitting}
                      value={coupons}
                      onChange={(coupons) => setFieldValue('coupons', coupons)}
                      query={'applyCode.keyword:"SUB_CODE"'}
                    />
                  ),
              },
              {
                label: translate.valid_start_date_time,
                md: 6,
                type: 'datetime-local',
                name: 'validAt',
              },
              {
                label: translate.valid_end_date_time,
                md: 6,
                type: 'datetime-local',
                name: 'validThru',
                hasPermanent: true,
              },
            ],
          },
        ],
      },
      // {
      //   name: translate.app_push || 'APP 推送',
      //   cards: [AppPushCard],
      // },
      // {
      //   name: translate.sms_messages || 'SMS 短訊',
      //   cards: [SmsMessageCard],
      // },
      // {
      //   name: translate._email || '電郵',
      //   cards: [EmailCard],
      // },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          name: values.name,
          targetHashtags: values.targetHashtags,
          excludedHashtags: values.excludedHashtags,
          actionTypes: values.actionTypes,
          targetMatchMode: values.isTargetMatchModeAll ? 'ALL' : 'ANY',
          excludedMatchMode: values.isExcludedMatchModeAll ? 'ALL' : 'ANY',
          couponIds: (values.coupons || []).map((coupon) => (coupon || {}).id).filter((_) => _),
          content: values.content,
          richContent: values.richContent,
          shopId: localStorage.getItem('shopId'),
          validAt: values.validAt,
          validThru: values.validThru,
        },
      },
    });
    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {},
      {
        name = '',
        targetMatchMode,
        targetHashtags,
        excludedMatchMode,
        excludedHashtags,
        actionTypes,
        content = '',
        richContent = '',
        coupons,
        validAt,
        validThru,
      } = node || {};
    return {
      name,
      targetHashtags: cloneObject(targetHashtags || []),
      isTargetMatchModeAll: (targetMatchMode || 'ALL') === 'ALL',
      excludedHashtags: cloneObject(excludedHashtags || []),
      isExcludedMatchModeAll: (excludedMatchMode || 'ALL') === 'ALL',
      actionTypes: actionTypes || ['PUSH'],
      content,
      richContent,
      coupons: coupons || [],
      validAt,
      validThru,
    };
  };
}
