import React from 'react';
import errorParser from '../shared/errorParser';
import { Box, Typography } from '@material-ui/core';

export default class ErrorBoundary extends React.Component {
  state = {
    error: undefined,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <Box mt={5}>
          <Typography align={'center'}>{errorParser(error)}</Typography>
        </Box>
      );
    }
    return this.props.children;
  }
}
