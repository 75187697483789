import React, { useEffect, useRef } from 'react';
import { useOnScreen } from './useOnScreen';
import { Box } from '@material-ui/core';

const OnScreenTriggerer = ({ onTrigger = (_) => _, children }) => {
  const ref = useRef(undefined);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) onTrigger();
  }, [onScreen]);
  return (
    <Box ref={ref} height={1} width={1} bgcolor={'rgba(0,0,0,0.001)'}>
      {children}
    </Box>
  );
};

export default OnScreenTriggerer;
