import { cloneObject, removeTypename } from '../../shared';
import { defaultTrigger } from './TargetCard';
import { defaultAction } from './ActionCard';
import getRemark from './getRemark';
import _ from 'lodash';

export function parseTriggersAndActions(offer) {
  const { repeat = 1, remark = '', triggers = [defaultTrigger], actions = [defaultAction] } = offer || {};
  const _triggers = removeTypename(cloneObject(triggers)).map((trigger) => ({
    ...trigger,
    triggerValueType:
      {
        AMOUNT:
          repeat > 1 && ['PRODUCT', 'PRODUCT_TAG', 'COLLECTION', 'ORDER'].includes(trigger?.triggerType)
            ? 'EVERY_AMOUNT'
            : 'AMOUNT',
        QUANTITY: repeat > 1 ? 'EVERY_QUANTITY' : 'QUANTITY',
      }[trigger.triggerValueType] || trigger.triggerValueType,
  }));
  let _actions = removeTypename(cloneObject(actions)).map((action) => ({
    ...action,
    actionValue:
      action.actionType === 'MEMBER_POINTS'
        ? action.actionValue
        : action.actionValueType === 'PERCENTAGE'
        ? 100 - action.actionValue
        : action.actionValue,
  }));

  /*******************************************************************/
  //  restructure SINGLE_PRODUCT to make edit more reasonable
  /*******************************************************************/
  const singleProductActions = _.filter(_actions, { actionType: 'SINGLE_PRODUCT' });
  if (singleProductActions.length > 0) {
    const grouped = _.groupBy(singleProductActions, getActionIdempotencyKey);
    Object.entries(grouped).forEach(([, actions]) => {
      actions.forEach((action, i) => {
        if (i === 0) {
          action.actionType = 'PRODUCT';
          action._repeat = actions.length || '';
        } else action._repeated = true;
      });
    });
    _actions = _actions.filter((action) => !action._repeated);
  }

  /*********************************************************************/
  //  restructure SINGLE_COLLECTION to make edit more reasonable
  /*********************************************************************/
  const singleCollectionActions = _.filter(_actions, { actionType: 'SINGLE_COLLECTION' });
  if (singleCollectionActions.length > 0) {
    const grouped = _.groupBy(singleCollectionActions, getActionIdempotencyKey);
    Object.entries(grouped).forEach(([, actions]) => {
      actions.forEach((action, i) => {
        if (i === 0) {
          action.actionType = 'COLLECTION';
          action._repeat = actions.length || '';
        } else action._repeated = true;
      });
    });
    _actions = _actions.filter((action) => !action._repeated);
  }

  /*********************************************************************/
  //  restructure PRODUCT_TAG repeat to make edit more reasonable
  /*********************************************************************/
  const productTagActions = _.filter(_actions, { actionType: 'SINGLE_PRODUCT_TAG' });
  if (productTagActions.length > 0) {
    const grouped = _.groupBy(productTagActions, getActionIdempotencyKey);
    Object.entries(grouped).forEach(([, actions]) => {
      actions.forEach((action, i) => {
        if (i === 0) {
          action.actionType = 'PRODUCT_TAG';
          action._repeat = actions.length || '';
        } else action._repeated = true;
      });
    });
    _actions = _actions.filter((action) => !action._repeated);
  }

  //*************************************************
  //  Remove GIFT, use PERCENTAGE 100% OFF instead
  //*************************************************
  _actions.forEach((action) => {
    if (action.actionValueType === 'GIFT') {
      action.actionValueType = 'PERCENTAGE';
      action.actionValue = 100;
    }
  });

  //*******************************************************
  //  Fix actionId to actionIds / triggerId to triggerIds
  //*******************************************************
  _actions.forEach((action) => {
    if (action.actionId) {
      action.actionIds = [...(action.actionIds || []), action.actionId];
    }
  });
  _triggers.forEach((trigger) => {
    if (trigger.triggerId) {
      trigger.triggerIds = [...(trigger.triggerIds || []), trigger.triggerId];
    }
  });

  return {
    remark: remark || getRemark({ actions: _actions, triggers: _triggers }),
    triggers: _triggers,
    actions: _actions,
  };
}

export function getSubmitTriggers(triggers = []) {
  const _triggers = removeTypename(cloneObject(triggers));
  return _triggers.map((_trigger) => ({
    ..._trigger,
    triggerIds: _.uniq(_trigger.triggerIds || []),
    triggerValueType:
      {
        EVERY_AMOUNT: 'AMOUNT',
        EVERY_QUANTITY: 'QUANTITY',
      }[_trigger.triggerValueType] || _trigger.triggerValueType,
    triggerIdName: undefined,
  }));
}

export function getSubmitActions(actions = []) {
  let _actions = removeTypename(cloneObject(actions));

  return _actions.reduce((reducer, action) => {
    const fixedAction = {
      actionType: action.actionType,
      actionId: action.actionId,
      actionIds: _.uniq(action.actionIds || []),
      actionValueType: action.actionValueType,
      actionValue:
        action.actionType === 'MEMBER_POINTS'
          ? action.actionValue
          : action.actionValueType === 'PERCENTAGE'
          ? 100 - action.actionValue
          : action.actionValue,
    };

    if (action.actionType === 'PRODUCT' && +action._repeat) {
      //*******************************
      //  restructure SINGLE_PRODUCT
      //*******************************
      reducer.push(
        ...Array(+action._repeat).fill({
          ...fixedAction,
          actionType: 'SINGLE_PRODUCT',
        }),
      );
    } else if (action.actionType === 'COLLECTION' && +action._repeat) {
      //*********************************
      //  restructure SINGLE_COLLECTION
      //*********************************
      reducer.push(
        ...Array(+action._repeat).fill({
          ...fixedAction,
          actionType: 'SINGLE_COLLECTION',
        }),
      );
    } else if (action.actionType === 'PRODUCT_TAG' && +action._repeat) {
      //*********************************
      //  restructure PRODUCT_TAG
      //*********************************
      reducer.push(
        ...Array(+action._repeat).fill({
          ...fixedAction,
          actionType: 'SINGLE_PRODUCT_TAG',
        }),
      );
    } else reducer.push(fixedAction);

    return reducer;
  }, []);
}

function getActionIdempotencyKey(action) {
  const { actionIds, actionId, actionValueType, actionValue } = action || {};
  return (actionIds || []).concat([actionId, actionValueType, actionValue]).join(',');
}
