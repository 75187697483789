import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    createUrl: '/product_modifiers/new',
    gql: {
      get: GET_QUERY,
    },
    selectionMode: true,
    getRowLink: ({ id, name }) => ({
      pathname: '/product_modifiers/' + id,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.product_modifier_no,
        fieldName: 'code',
        width: 150,
        type: 'text',
        filter: 'code',
        sortBy: 'code.keyword',
      },
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        filter: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: translate.product_modifier_display_order,
        fieldName: 'sortIndex',
        type: 'number',
        filter: 'sortIndex',
        sortBy: 'sortIndex',
        width: 100,
      },
      {
        title: translate.product_count,
        value: ({ productCount }) => `${productCount || 0}`,
        width: 100,
      },
    ],
    menus: [],
  };

  getData = ({ node } = {}) => {
    const { productModifiers } = node || {};
    return productModifiers;
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
