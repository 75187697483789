import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { translate } from '../../translate';

const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      ... on ShopCollection {
        products(limits: $limits, cursor: $cursor) {
          nextCursor
          totalCount
          nodes {
            id
            active
            sku
            name
          }
        }
      }
    }
  }
`;

export default async function (source) {
  try {
    return await infinityFetch({
      query: GET_QUERY,
      variables: {
        id: source?.id,
      },
      getConnection: (data) => data?.node?.products,
      onProgress: (downloaded, total) => {
        if (total > 0) ActivityIndicator.show(translate.loading + ` ${downloaded}/${total}`);
      },
    });
  } catch (e) {
    throw e;
  } finally {
    ActivityIndicator.hide();
  }
}
