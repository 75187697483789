import React, { useState, useEffect } from 'react';
import { Box, ButtonBase, Typography, makeStyles } from '@material-ui/core';
import { Icon } from '../../IconRender';
import ImageFieldModal from './ImageFieldModal';
import FormRow from '../../FormRow';
import InputField from './InputField';
import Dropzone from 'react-dropzone';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import s3Upload from '../../../shared/s3Upload';
import uuid from 'react-uuid';
import OverlayLoading from '../../OverlayLoading';
import LSkeleton from '../../LSkeleton';
import MagicMedia from '../../MagicMedia';
import { translate } from '../../../shared/translate';
import MagicMediaDialogButton from '../../MagicMediaButton/MagicMediaDialogButton';
import useBreakPoint from '../../useBreakPoint';
import { checkPermissionV2 } from '../../PermissionMask';
import TrashButton from '../../TrashButton';

const useStyles = makeStyles((theme) => ({
  magicMediaFieldContainer: {
    position: 'relative',
  },
  btnGroupBox: {
    width: '100%',
    position: 'absolute',
    top: 37,
    left: 0,
    display: 'flex',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  magicBtn: {
    padding: '5px 10px',
    background: 'linear-gradient(144deg, #355F7E 0%, #00E0CA 100%)',
    border: 'none',
    borderRadius: '30px',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(144deg, #2b3e4d 0%, #00E0CA 100%)',
    },
  },
  removeBtn: {
    padding: '5px 10px',
    background: '#FFF',
    border: 'none',
    borderRadius: '30px',
    color: '#FF4731',
    '&:hover': {
      backgroundColor: '#FFF0EE',
    },
  },
  btnIcon: {
    height: '16px',
    width: '16px',
  },
}));

const MagicMediaField = ({
  loading: _loading,
  required,
  className,
  disabled,
  modifyDisabled,
  value,
  hideRemoveButton,
  onChange = (_) => _,
}) => {
  const [hover, setHover] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false),
    toggleModal = () => setOpen(!open);
  const classes = useStyles();
  const breakPoint = useBreakPoint();

  const { id, alt, src, optimizedSrc } = value || {};

  useEffect(() => {
    if (!!useEffect && !id) {
      onChange({
        ...value,
        id: uuid(),
      });
    }
  }, [value]);

  const onFileDrop = async (files) => {
    try {
      setIsDragging(false);
      setLoading(true);
      let file = files[0];
      const src = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsDataURL(file);
      });
      onChange({
        ...value,
        src,
        optimizedSrc: '',
      });
      const s3Path = await s3Upload(file);
      onChange({
        ...value,
        src: s3Path,
        optimizedSrc: '',
      });
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setLoading(false);
    }
  };

  if (_loading) return <LSkeleton variant={'rect'} width={100} height={100} />;

  return (
    <Box
      display={'inline-block'}
      onMouseEnter={(e) => {
        setHover(true);
      }}
      onMouseLeave={(e) => {
        setHover(false);
      }}
      className={classes.magicMediaFieldContainer}
    >
      <Dropzone
        disabled={disabled || modifyDisabled || loading}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={onFileDrop}
        accept={['image/*', 'video/*']}
      >
        {({ getRootProps, getInputProps }) => (
          <ButtonBase
            type={`button`}
            style={{
              width: 100,
              height: 100,
              borderRadius: '1rem',
              overflow: 'hidden',
              border: `1px solid #665858`,
            }}
            {...getRootProps()}
            disabled={disabled || modifyDisabled || loading}
            onClick={toggleModal}
          >
            <input
              type={'file'}
              {...getInputProps()}
              accept="image/*"
              disabled={disabled || modifyDisabled || loading}
              required={required}
            />
            <MagicMedia
              loading={loading}
              disabled={true}
              resizeMode={hover ? 'cover' : 'contain'}
              isPlaceholder={!optimizedSrc && !src}
              src={optimizedSrc || src}
            />
            {(loading || isDragging) && (
              <OverlayLoading>{isDragging ? <Icon icon={'faUpload'} fontSize={'large'} /> : undefined}</OverlayLoading>
            )}
          </ButtonBase>
        )}
      </Dropzone>
      {!disabled && !hideRemoveButton && (optimizedSrc || src) && (
        <Box
          className={classes.btnGroupBox}
          style={{
            opacity: hover ? 1 : 0,
            transition: 'opacity 0.25s',
          }}
        >
          {checkPermissionV2('photoroom') && ['md', 'lg', 'xl'].includes(breakPoint) && (
            <MagicMediaDialogButton
              value={value}
              onChange={onChange}
              setBtnHover={() => {
                setHover(false);
              }}
            />
          )}
          <TrashButton
            className={classes.removeBtn}
            onClick={() => {
              onChange(undefined);
            }}
          >
            <Typography
              style={{
                fontSize: '9px',
                fontWeight: '600',
              }}
              color={'inherit'}
            >
              {translate.add_image || '刪除圖片'}
            </Typography>
          </TrashButton>
        </Box>
      )}
      <ImageFieldModal
        open={open}
        onClose={toggleModal}
        image={src || optimizedSrc}
        onChange={(src) => {
          onChange({
            ...value,
            src,
            optimizedSrc: '',
          });
        }}
      >
        <FormRow title={'alt'} direction={'column'}>
          <InputField
            value={alt}
            onChange={(e) => {
              onChange({
                ...value,
                alt: e.target.value,
              });
            }}
          />
        </FormRow>
      </ImageFieldModal>
    </Box>
  );
};

export default MagicMediaField;
