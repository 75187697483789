import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { toast } from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { CREATE_QUERY } from './query';
import FormToolbar from '../../../components/FormToolbar';
import SystemButton from '../../../components/SystemButton';
import { Formik } from 'formik';
import useBreakPoint from '../../../components/useBreakPoint';
import FileUploadField from '../../../components/FormPageMaker/Fields/FileUploadField';
import { sleep } from '../../../shared';

const ImportContainer = ({ name, onClick, list }) => {
  const breakPoint = useBreakPoint();
  const isUnderSM = ['xs'].includes(breakPoint);

  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{}}
      onSubmit={async ({ file, fileName }, { setSubmitting }) => {
        const { s3Path } = file || {};
        try {
          setLoading(true);
          ActivityIndicator.show();
          await client.mutate({
            mutation: CREATE_QUERY,
            variables: {
              input: {
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
                name: fileName,
              },
            },
          });
          sleep(3000).then(() => list.refetch());
          toast.success(translate.create_success);
          onClick();
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
          setSubmitting(false);
          list.refetch();
          ActivityIndicator.hide();
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
        const { file } = values || {};
        const { fileName, fileSize, s3Path } = file || {};
        const _loading = isSubmitting || loading;
        const onClear = () => setFieldValue('file', null);

        return (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box
              p={4}
              style={{
                backgroundColor: '#fff',
                borderRadius: isUnderSM ? 0 : '0 13px 0 0',
                boxShadow: '0px 1px 15px 0px #0000001A',
                flex: 1,
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="h6" style={{ color: '#000' }}>
                    {translate.add_sitemap || '上傳網站地圖'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 'bold', marginBottom: 1 }}>{translate.upload_file}</Typography>

                  <FileUploadField
                    accept=".xml"
                    value={{
                      fileSize,
                      fileName,
                      s3Path,
                    }}
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('file', value);
                        setFieldValue('fileName', value.fileName);
                      } else {
                        setFieldValue('file', null);
                      }
                    }}
                    onClear={onClear}
                    loading={_loading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box p={3} style={{ border: `1px solid #f6f6f6`, borderRadius: 13 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography style={{ fontWeight: 'bold' }}>{translate.tips || '提示'}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>{translate.only_keep_two_sitemaps || '系統只能保留 2 個網站地圖'}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <FormToolbar
              extra={
                <>
                  <SystemButton
                    disabled={_loading}
                    onClick={() => {
                      onClick();
                      onClear();
                    }}
                  >
                    {translate.cancel}
                  </SystemButton>
                  <SystemButton
                    color={'omniewRed'}
                    loading={_loading}
                    disabled={_loading || !fileSize}
                    type={'submit'}
                    onClick={onClick}
                  >
                    {translate.confirm || '確認'}
                  </SystemButton>
                </>
              }
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ImportContainer;
