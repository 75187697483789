import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import AIButton from '../../components/AIButton';
import { translate } from 'shared/translate';
import useBreakPoint from '../../components/useBreakPoint';
import { Icon } from '../../components/IconRender';
import SystemButton from '../../components/SystemButton';
import AIResultPreview from './AIResultPreview';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

const AIResult = ({ data, input }) => {
  const lang = localStorage.getItem('lang');
  const breakPoint = useBreakPoint();
  const isUnderMD = ['xs', 'sm'].includes(breakPoint);
  const history = useHistory();

  const [clickedIndex, setClickedIndex] = useState(0);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={'true'}>
          <Box
            style={{
              maxHeight: 615,
              minHeight: 615,
              overflowX: 'hidden',
              overflowY: 'scroll',
              scrollbarColor: '#FFFFFFCC transparent',
              scrollbarWidth: 'thin',
              paddingRight: 5,
            }}
          >
            <Grid container spacing={4}>
              {data?.map((content, i) => {
                const { name, description, hashtags: _hashtags, images } = content || {};
                const hashtags = (_hashtags || []).map((hashtag) => hashtag.replace(/^#/, ''));
                return (
                  <Grid item lg={12} xl={6} key={i}>
                    <Box p={2} style={{ background: '#FFFFFF14', borderRadius: 4 }}>
                      <Grid container spacing={2} key={i}>
                        <Grid item xs="12">
                          <Grid container spacing={1}>
                            <Grid item xs="12">
                              <Typography variant="h6" style={{ color: '#fff' }}>
                                {name}
                              </Typography>
                            </Grid>
                            <Grid item xs="12">
                              {!!hashtags?.length && (
                                <Grid container spacing={1}>
                                  {hashtags.map((hashtag, i) => {
                                    return (
                                      <Grid item key={i}>
                                        <Box style={{ borderRadius: 100, border: '1px solid #60EBF0' }}>
                                          <Typography variant="body2" style={{ color: '#60EBF0', padding: '3px 10px' }}>
                                            #{hashtag}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs="12">
                          <Typography style={{ color: '#fff' }}>{description}</Typography>
                        </Grid>
                        <Grid item xs="12">
                          <Grid container spacing={2}>
                            <Grid item xs="true">
                              {!!images?.length ? (
                                <Grid container spacing={1}>
                                  {images.map((image, i) => {
                                    return (
                                      <Grid item key={i} alignContent="center" style={{ zIndex: 10 }}>
                                        <img
                                          src={image}
                                          alt={'product photo'}
                                          style={{
                                            background: `url("${image}") no-repeat center center / contain grey`,
                                            minWidth: 90,
                                            minHeight: 90,
                                            maxWidth: 90,
                                            maxHeight: 90,
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',
                                          }}
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              ) : (
                                <Grid container spacing={1}>
                                  {Array(4)
                                    .fill(undefined)
                                    .map((__, i) => (
                                      <Grid item key={i} alignContent="center">
                                        <Skeleton variant="rect" width={90} height={90} />
                                      </Grid>
                                    ))}
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs="auto" style={{ textAlign: 'right', alignSelf: 'end' }}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <SystemButton
                                    variant="outlined"
                                    width={isUnderMD ? '100%' : 120}
                                    heigh={32}
                                    style={{ borderColor: '#fff' }}
                                    startIcon={<Icon icon={'faEye'} style={{ color: '#fff', fontSize: '1rem' }} />}
                                    onClick={() => setClickedIndex(i)}
                                  >
                                    <Typography style={{ color: '#fff', fontSize: '0.85rem' }}>
                                      {translate.preview}
                                    </Typography>
                                  </SystemButton>
                                </Grid>
                                <Grid item>
                                  <AIButton
                                    style={{ zIndex: 10 }}
                                    width={isUnderMD ? '100%' : lang === 'zh-hk' ? 100 : 150}
                                    height={30}
                                    startIcon={<Icon icon={'faPlus'} style={{ color: '#fff', fontSize: '1rem' }} />}
                                    onClick={() => {
                                      history.push({
                                        pathname: '/products/new',
                                        state: {
                                          copyValues: {
                                            name,
                                            sku: input?.sku || Date.now(),
                                            description,
                                            hashtags,
                                            medias: images.map((image) => {
                                              return { src: image };
                                            }),
                                            variations: [
                                              !!input?.quantity
                                                ? {
                                                    unitPrice: +input?.unitPrice || 0,
                                                    quantity: +input?.quantity,
                                                    cost: 0,
                                                    active: true,
                                                  }
                                                : {
                                                    unitPrice: input?.unitPrice || 0,
                                                    ignoreStock: true,
                                                    cost: 0,
                                                    active: true,
                                                  },
                                            ],
                                          },
                                          submittedBehavior: 'id',
                                        },
                                      });
                                    }}
                                  >
                                    <Typography style={{ color: '#fff', fontSize: '0.85rem' }}>
                                      {translate.add_new_product}
                                    </Typography>
                                  </AIButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <AIResultPreview key={clickedIndex} data={data[clickedIndex]} unitPrice={input?.unitPrice} />
        </Grid>
      </Grid>
    </>
  );
};

export default AIResult;
