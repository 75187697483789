import React from 'react';
import { convertMetaObject } from '../../../../shared';
import CustomFieldWrapper from '../CustomFieldWrapper';
import SelectComboValue from '../../../SelectCombo/SelectComboValue';

const ListMultipleField = ({ disabled, value, onChange, field = {} }) => {
  const { metadata, name, required, values = [] } = field ?? {};
  const { remarks, placeholder } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <SelectComboValue
        disabled={disabled}
        multiple={true}
        value={value?.split(',')?.filter(Boolean) ?? []}
        options={values.map((v) => ({
          label: v,
          value: v,
        }))}
        onChange={(values) => {
          onChange(values?.join(',') ?? '');
        }}
        placeholder={placeholder}
      />
    </CustomFieldWrapper>
  );
};

export default ListMultipleField;
