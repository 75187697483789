import { gql } from '@apollo/client';

const FRAGMENT_CAMPAIGN = gql`
  fragment FRAGMENT_CAMPAIGN on ShopCampaign {
    id
    createdAt
    updatedAt
    validAt
    validThru
    active
    name
    actionTypes
    targetHashtags
    targetMatchMode
    excludedHashtags
    excludedMatchMode
    result {
      pendingCount
      successCount
      failureCount
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: CampaignFilterInput, $query: String) {
    node(id: $id) {
      id
      ... on CompanyShop {
        campaigns(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_CAMPAIGN
          }
        }
      }
    }
  }
  ${FRAGMENT_CAMPAIGN}
`;

export const EXPORT_QUERY = gql`
  mutation ($id: ID!) {
    campaignDeliveryReportExport(id: $id) {
      id
    }
  }
`;
