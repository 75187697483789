import React, { useState } from 'react';
import { Formik } from 'formik';
import { translate } from '../../../shared/translate';
import { Dialog, DialogContent, Link, Typography } from '@material-ui/core';
import moment from 'moment';
import FormRow from '../../../components/FormRow';
import SelectComboMemberTier from '../../../components/SelectCombo/SelectComboMemberTier';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import FormToolbar from '../../../components/FormToolbar';
import { USER_CHANGE_TIER_QUERY } from './query';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { Icon } from '../../../components/IconRender';
import { checkPermissionV2 } from '../../../components/PermissionMask';

const MemberTierModifyButton = ({ memberTier, customerId, disabled, onMemberTierChange = (_) => _ }) => {
  const [submittingLock, setSubmittingLock] = useState(false);
  const [open, setOpen] = useState(false);
  const { level, name, startedAt, startedThru } = memberTier || {};

  const content = (
    <>
      {!!memberTier ? (
        <Typography>
          Level {level}. {name} <Icon style={{ fontSize: '1rem' }} icon={'faPencil'} />
        </Typography>
      ) : (
        <Typography>
          {translate.unknown_member_tier} <Icon style={{ fontSize: '1rem' }} icon={'faPencil'} />
        </Typography>
      )}
    </>
  );

  return (
    <>
      {checkPermissionV2('updateCustomer') ? (
        <Link
          // eslint-disable-next-line
          href={'javascript:void(0);'}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault();
              setOpen(true);
            }
          }}
        >
          {content}
        </Link>
      ) : (
        content
      )}
      {!!startedAt && !!startedThru && (
        <Typography>
          {translate.available_date} {translate.available_date_from}
          {moment(startedAt).format('YYYY-MM-DD')} {translate.available_date_to}{' '}
          {moment(startedThru).format('YYYY-MM-DD')}
        </Typography>
      )}
      <Dialog open={open} onClose={() => !submittingLock && setOpen(false)}>
        <Formik
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);
              setSubmittingLock(true);
              const { data: { result } = {} } = await client.mutate({
                mutation: USER_CHANGE_TIER_QUERY,
                variables: {
                  customerId,
                  input: {
                    memberTierId: values.memberTier.id,
                    startedThru: values.startedThru,
                  },
                },
              });
              toast.success(translate.update_success);
              onMemberTierChange(result);
              setOpen(false);
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              setSubmitting(false);
              setSubmittingLock(false);
            }
            console.log(values);
          }}
          initialValues={{
            memberTier: undefined,
            startedThru: moment().add(1, 'year').toISOString(),
          }}
        >
          {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                handleSubmit(e);
              }}
            >
              <DialogContent>
                <FormRow title={translate.new_member_tier} required>
                  <SelectComboMemberTier
                    required
                    disabled={isSubmitting}
                    value={values.memberTier}
                    onChange={(v) => setFieldValue('memberTier', v)}
                  />
                </FormRow>
                <FormRow title={translate.expiry_date} required>
                  <DateTimeField
                    required
                    disabled={isSubmitting}
                    value={values.startedThru}
                    onChange={(v) => setFieldValue('startedThru', v)}
                  />
                </FormRow>
              </DialogContent>
              <FormToolbar
                disabled={isSubmitting}
                loading={isSubmitting}
                onCancel={() => !submittingLock && setOpen(false)}
                submitBtnType={'submit'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default MemberTierModifyButton;
