import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { translate } from '../shared/translate';
import { toast } from '../shared/toast';

const CopyButton = ({ disabled, getCopyValues = () => {}, url, ...props }) => {
  const history = useHistory();
  return (
    <Button
      disabled={disabled}
      variant={'contained'}
      style={{ backgroundColor: '#3386ff', color: 'white' }}
      onClick={async () => {
        toast.success(translate.copy_success);
        history.push({
          pathname: url,
          state: {
            copyValues: getCopyValues(),
            submittedBehavior: 'new',
          },
        });
      }}
      {...props}
    >
      {translate.copy}
    </Button>
  );
};

export default CopyButton;
