import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { getCommonColumns } from '../../../shared';
export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/service_bundles/new',
    selectionMode: false,
    getRowLink: ({ id, name }) => ({
      pathname: `/service_bundles/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        width: 20,
        title: translate.image,
        fieldName: 'medias',
        type: 'medias',
      },
      {
        title: translate.items,
        fieldName: 'name',
        sortBy: 'name',
        filter: 'name',
        filterType: 'text',
      },
      ...getCommonColumns(),
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = (data) => {
    return data?.node?.serviceBundles;
  };
}
