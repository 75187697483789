import React from 'react';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/logo_OCGC.png')} alt="OCGC" />,
    label: 'OCGC 線上支付',
    value: 'OCGC',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'Client ID',
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: 'Client Private',
            type: 'textarea',
            name: 'secret',
            required: true,
            inputProps: {
              type: 'password',
            },
            placeholder: `-----BEGIN RSA PRIVATE KEY-----
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
-----END RSA PRIVATE KEY-----
              `,
          },
        ]}
      />
    );
  },
  type: 'PAYMENT',
};
