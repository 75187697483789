import React from 'react';
import { translate } from 'shared/translate';
import { FormRow } from 'components/FormRow';
import { Box } from '@material-ui/core';
import InputField from '../../../../../../components/FormPageMaker/Fields/InputField';
import BannerImageField from './BannerImageField';

export default ({ banner = {}, onChange = (_) => _, onRemove = (_) => _ }) => {
  const { title, subtitle, content, src, href } = banner || {};
  return (
    <div>
      <Box mb={3}>
        <BannerImageField
          value={src}
          onChange={(v) => {
            if (!!v) {
              banner.src = v;
              onChange(banner);
            }
          }}
          onRemove={onRemove}
        />
      </Box>
      <FormRow title={translate.title}>
        <InputField
          value={title}
          onChange={(e) => {
            banner.title = e.target.value;
            onChange(banner);
          }}
        />
      </FormRow>
      <FormRow title={translate.subtitle}>
        <InputField
          value={subtitle}
          onChange={(e) => {
            banner.subtitle = e.target.value;
            onChange(banner);
          }}
        />
      </FormRow>
      <FormRow title={translate.content}>
        <InputField
          value={content}
          multiline={true}
          rows={3}
          onChange={(e) => {
            banner.content = e.target.value;
            onChange(banner);
          }}
        />
      </FormRow>
      <FormRow title={translate.href}>
        <InputField
          value={href}
          placeholder={'/pages/aboutus'}
          onChange={(e) => {
            banner.href = e.target.value;
            onChange(banner);
          }}
        />
      </FormRow>
    </div>
  );
};
