import React from 'react';
import { Grid, Divider, Typography, Card, Badge, CardHeader, CardContent } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { translate } from '../../shared/translate';
import { NumberFormat } from '../../shared';

export default ({ title = 'title', loading, data }) => {
  const { amount = 0, count = 0 } = data || {};
  return (
    <Card>
      <CardHeader
        avatar={<Badge color="secondary" variant="dot" />}
        title={
          <Typography variant={'h6'} color={'secondary'}>
            {title}
          </Typography>
        }
        disableTypography={true}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography variant={'h3'} paragraph={true} align={'center'}>
              {loading ? <Skeleton width={'50%'} style={{ margin: 'auto' }} /> : NumberFormat(amount)}
            </Typography>
            <Typography align={'center'}>{translate.amount}(HKD)</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant={'h3'} paragraph={true} align={'center'}>
              {loading ? <Skeleton width={'50%'} style={{ margin: 'auto' }} /> : NumberFormat(count)}
            </Typography>
            <Typography align={'center'}>{translate.orders}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
