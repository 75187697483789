import { gql } from '@apollo/client';

export const CREATE_QUERY = gql`
  mutation($companyId: ID!, $input: UserCreateInput!, $credential: UserCredentialInput!) {
    userCreate(companyId: $companyId, input: $input, credential: $credential) {
      id
    }
  }
`;
export const UPDATE_QUERY = gql`
  mutation($id: ID!, $companyId: ID!, $input: UserUpdateInput!, $credential: UserCredentialUpdateInput!) {
    userUpdate(id: $id, companyId: $companyId, input: $input, credential: $credential) {
      id
    }
  }
`;

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on User {
        id
        email
        isEmailVerified
        isPhoneNumberVerified
        picture
        name
        phoneNumber
        permissions {
          key
          value
        }
      }
    }
  }
`;
