import React, { useState } from 'react';
import { Button, Chip, Dialog, Grid, List, ListItem, Typography } from '@material-ui/core';
import { translate } from '../shared/translate';
import useBreakPoint from './useBreakPoint';

const StackTags = ({ tags = [], onTagClick = (_) => _ }) => {
  const [open, setOpen] = useState(false);
  const breakPoint = useBreakPoint();
  const max =
    {
      xs: 2,
      sm: 2,
      md: 3,
    }[breakPoint] || 4;

  if (tags?.length > 0) {
    return (
      <>
        <Grid container spacing={1}>
          {tags.slice(0, max).map((tag, i) => (
            <Grid key={i} item>
              <Chip
                label={tag}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onTagClick(tag);
                }}
              />
            </Grid>
          ))}
          {tags.length > max && (
            <Grid item>
              <Button
                variant={'text'}
                type={'button'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setOpen(true);
                }}
              >
                {tags.length - max} More
              </Button>
            </Grid>
          )}
        </Grid>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <List>
            {tags.map((tag, i) => (
              <ListItem
                key={i}
                button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onTagClick(tag);
                }}
              >
                {tag}
              </ListItem>
            ))}
          </List>
        </Dialog>
      </>
    );
  }
  return <Typography color={'textSecondary'}>{translate.no_data}</Typography>;
};

export default StackTags;
