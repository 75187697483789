import React from 'react';
import { InputAdornment, TableCell, TableRow, Typography } from '@material-ui/core';
import { NumberFormat } from '../../../shared';
import InputField from '../../../components/FormPageMaker/Fields/InputField';

const ServiceBundleOrderItem = ({ editable, orderItem, disabled, shippedQuantity = 0, value, onChange = (_) => _ }) => {
  const { description, remark, productVariation, quantity, serviceBundle } = orderItem || {};
  const max = quantity - shippedQuantity;

  return (
    <TableRow hover>
      <TableCell>
        <Typography>{serviceBundle?.serviceBundle?.name}</Typography>
      </TableCell>
      {/* editable */}
      {editable && (
        <>
          <TableCell align={'right'}>
            <Typography align={'center'}>{NumberFormat(quantity)}</Typography>
          </TableCell>
          <TableCell align={'right'}>
            <Typography align={'center'}>{NumberFormat(shippedQuantity)}</Typography>
          </TableCell>
        </>
      )}
      {/* "送運數量" */}
      <TableCell>
        {!editable ? (
          <Typography align={'center'}>{NumberFormat(value)}</Typography>
        ) : (
          <>
            {+max > 0 && (
              <InputField
                disabled={disabled || !editable}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                type={'number'}
                // eslint-disable-next-line
                inputProps={{
                  step: 1,
                  max: +max,
                  style: {
                    textAlign: 'center',
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position={'end'}>/ {max}</InputAdornment>,
                }}
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ServiceBundleOrderItem;
