import React from 'react';
import { FormControlLabel, Switch, withStyles, makeStyles } from '@material-ui/core';
import LSkeleton from '../../LSkeleton';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 20,
    padding: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 12,
  },
  disabled: {
    width: 50,
    height: 20,
    padding: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 12,
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(30px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#3bbd9c',
        opacity: 1,
        position: 'relative',
        '&::before': {
          content: '"ON"',
          color: 'white',
          position: 'absolute',
          top: '50%',
          left: '20%',
          transform: 'translateY(-50%)',
        },
      },
    },
    '& + $track': {
      opacity: 1,
      border: 'none',
      boxShadow: 'inset 0px 1px 4px 0px #000000ba',
      backgroundColor: '#d4d4d4',
      position: 'relative',
      '&::before': {
        content: '"OFF"',
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '42%',
        transform: 'translateY(-50%)',
      },
    },
    '&$focusVisible $thumb': {
      color: '#3bbd9c',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 20 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    transition: theme.transitions.create(['background-color', 'border']),
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
}))(({ classes, onClick = (_) => _, disabled, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: disabled ? classes.disabled : classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      disabled={disabled}
      {...props}
    />
  );
});
const useStyles = makeStyles({
  marginAuto: {
    margin: 'auto',
    textAlign: 'left',
  },
});

export default ({ loading, text, ...props }) => {
  const classes = useStyles();
  if (loading) return <LSkeleton height={30} />;
  return <FormControlLabel className={classes.marginAuto} control={<IOSSwitch {...props} />} label={text} />;
};
