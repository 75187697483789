import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import LSkeleton from '../../LSkeleton';
import { translate } from '../../../shared/translate';
import { InvoiceStatus, ShippingStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../StatusBadge';

const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on ShopOrder {
        id
        referenceNo
        total
        paymentStatus
        shippingStatus
        status
      }
    }
  }
`;

const OrderPreview = ({ orderId }) => {
  const { loading, data: { node } = {} } = useQuery(GET_QUERY, {
    variables: { id: orderId },
    skip: !orderId,
  });

  return (
    <Box px={2} py={1} style={{ backgroundColor: '#F9F9F9' }}>
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item xs={12}>
          <Typography variant={'h6'} component={'a'} href={`/orders/${orderId}`} target={'_blank'}>
            {loading ? <LSkeleton /> : `${translate.orders}: ${node?.referenceNo}`}
          </Typography>
        </Grid>
        <Grid item>
          <StatusBadge statusObject={InvoiceStatus} status={node?.paymentStatus} />
        </Grid>
        <Grid item>
          <StatusBadge statusObject={ShippingStatus} status={node?.shippingStatus} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderPreview;
