import { gql } from '@apollo/client';

export const FRAGMENT_ORDER_ITEM = gql`
  fragment FRAGMENT_ORDER_ITEM on OrderItem {
    id
    description
    sku
    remark
    media {
      src
    }
    bundleProduct {
      bundleProduct {
        id
        name
        sku
      }
      options {
        id
        label
        price
        remark
        variation {
          id
          medias {
            src
          }
          sku
          combination {
            name
            option
          }
        }
        product {
          id
          name
          sku
          medias {
            src
          }
        }
      }
    }
    addOnProduct {
      id
      sku
      unitPrice
    }
    productVariation {
      id
      sku
      combination {
        name
        option
      }
    }
    quantity
    unitPrice
  }
`;

export const FRAGMENT_ORDER = gql`
  fragment FRAGMENT_ORDER on ShopOrder {
    shop {
      id
      name
      autoConfirm
      address {
        person
        tel
        email
        lines
        country
      }
    }
    staff {
      id
      name
    }
    comments {
      id
      createdAt
      content
    }
    checkout {
      id
    }
    referenceNo
    customer {
      id
      email
      phoneNumber
      metadata {
        key
        value
      }
    }
    channel {
      id
    }
    checkout {
      id
      status
      salespersonId
      pickUpAddress {
        name
        person
        tel
        email
        lines
        country
      }
    }
    shippingProvider {
      id
      name
      provider
    }
    billingAddress {
      person
      tel
      email
      lines
      country
    }
    shippingAddress {
      name
      person
      tel
      email
      lines
      country
    }
    items {
      ...FRAGMENT_ORDER_ITEM
    }
    totalAdjustments {
      id
      sortIndex
      amount
      description
    }

    status
    subtotal
    total
    remark
    totalPaid
    totalRefund
    shopDiscount
    couponDiscount
    createdAt
    updatedAt
    paymentStatus
    shippingStatus
    cancelReason
    deductibleMemberPoints
    remark
    internalRemark
    remarkMedias {
      id
      alt
      src
      optimizedSrc(width: 256)
    }
    internalRemarkMedias {
      id
      alt
      src
      optimizedSrc(width: 256)
    }
  }
  ${FRAGMENT_ORDER_ITEM}
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on ShopOrder {
        ...FRAGMENT_ORDER
        deliveryNotes {
          nodes {
            id
            trackingNumber
          }
        }
      }
    }
  }
  ${FRAGMENT_ORDER}
`;

export const ORDER_UPDATE = gql`
  mutation ($id: ID!, $input: OrderUpdateInput!) {
    orderUpdate(id: $id, input: $input) {
      id
      ...FRAGMENT_ORDER
    }
  }
  ${FRAGMENT_ORDER}
`;
