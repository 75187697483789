import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { translate } from '../../shared/translate';
import { GET_QUERY, UPDATE_QUERY, REMOVE_QUERY } from './query';
import { client } from '../../shared/apollo';
import CheckboxField from '../../components/FormPageMaker/Fields/CheckboxField';
import { Skeleton } from '@material-ui/lab';
import { cloneObject, removeTypename } from '../../shared';
import ConditionField from './ConditionField';
import ExtendField from './ExtendField';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.tier_info,
        cards: [
          {
            fields: [
              {
                label: translate.tier_name,
                type: 'text',
                name: 'name',
                maxLength: 25,
                required: true,
                md: 6,
              },
              {
                label: translate.level,
                required: true,
                md: 6,
                render: ({ isSubmitting, loading, values: { level, isDefault } = {}, setFieldValue }) =>
                  loading ? (
                    <Skeleton height={30} />
                  ) : (
                    <InputField
                      type={'number'}
                      required={true}
                      value={level}
                      disabled={isSubmitting}
                      onChange={(e) => setFieldValue('level', e.target.value)}
                      // eslint-disable-next-line
                      inputProps={{
                        min: 1,
                        step: 1,
                      }}
                      // eslint-disable-next-line
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position={'end'} disableTypography={true}>
                            <CheckboxField
                              style={{ margin: 0 }}
                              disabled={isSubmitting}
                              checked={isDefault}
                              text={translate.default}
                              onChange={(e) => setFieldValue('isDefault', e.target.checked)}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ),
              },
            ],
          },
          {
            name: translate.member_upgrade_condition,
            fields: [
              {
                text: translate.upgrade_remark,
                type: 'span',
              },
              {
                render: (props) => <ConditionField {...props} />,
              },
              {
                text: translate.upgrade_remark2,
                type: 'span',
              },
            ],
          },
          {
            render: (actions) => <ExtendField {...actions} />,
          },
          {
            name: translate.member_validity_period,
            fields: [
              {
                label: translate.validity_period,
                type: 'select',
                name: 'valid_type',
                options: [
                  {
                    label: translate.permanent_membership,
                    value: 0,
                  },
                  {
                    label: translate.set_expiration_date,
                    value: 1,
                  },
                ],
                required: true,
                md: 6,
              },
              { type: 'span', md: 6 },
              {
                label: translate.valid_for,
                type: 'number',
                name: 'validMonths',
                display: ({ values: { valid_type } }) => valid_type === 1,
                InputProps: {
                  endAdornment: <InputAdornment position={'end'}>{translate.month}</InputAdornment>,
                },
                inputProps: {
                  min: 0,
                  step: 1,
                },
                required: true,
                md: 6,
              },
              {
                label: translate.expire_date,
                type: 'select',
                name: 'expiryType',
                display: ({ values: { valid_type } }) => valid_type === 1,
                options: [
                  { label: translate.start_of_month, value: 'START_OF_MONTH' },
                  { label: translate.end_of_month, value: 'END_OF_MONTH' },
                  { label: translate.same_day_of_the_month, value: 'DATE' },
                ],
                required: true,
                md: 6,
              },
            ],
          },
          {
            name: translate.point_setting,
            fields: [
              {
                label: translate.point_conversion,
                render: ({ setFieldValue, values: { memberPointUnitPrice, memberPointPerUnit } }) => (
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                      <Typography>{translate.every}</Typography>
                    </Grid>
                    <Grid item>
                      <InputField
                        type={'number'}
                        // eslint-disable-next-line
                        inputProps={{
                          min: 0,
                        }}
                        value={memberPointUnitPrice}
                        onChange={(e) => setFieldValue('memberPointUnitPrice', e.target.value)}
                        // eslint-disable-next-line
                        InputProps={{
                          startAdornment: <InputAdornment position={'start'}>HK$</InputAdornment>,
                        }}
                        required={true}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>{translate.gain}</Typography>
                    </Grid>
                    <Grid item>
                      <InputField
                        type={'number'}
                        // eslint-disable-next-line
                        inputProps={{
                          min: 0,
                        }}
                        value={memberPointPerUnit}
                        onChange={(e) => setFieldValue('memberPointPerUnit', e.target.value)}
                        // eslint-disable-next-line
                        InputProps={{
                          endAdornment: <InputAdornment position={'end'}>{translate.point}</InputAdornment>,
                        }}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                ),
                required: true,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          companyId: localStorage.getItem('companyId'),
          name: values.name,
          level: values.level,
          upgradeConditions:
            values.upgradeConditions
              ?.map((con) => {
                switch (con?.type) {
                  case 'SINGLE_PURCHASE':
                    return {
                      ...con,
                      upgradeConditionAmount: con?.upgradeConditionAmount || 1,
                    };
                  case 'SPECIFIC_PERIOD':
                    return {
                      ...con,
                      upgradeConditionMonths: con?.upgradeConditionMonths || 1,
                      upgradeConditionAmount: con?.upgradeConditionAmount || 1,
                    };
                  default:
                    return null;
                }
              })
              ?.filter((_) => _) ?? [],
          validMonths: values.valid_type === 0 ? 99999 : values.validMonths,
          memberPointActive: values.memberPointActive,
          memberPointUnitPrice: values.memberPointUnitPrice,
          memberPointPerUnit: values.memberPointPerUnit,
          isDefault: values.isDefault || false,
          extensionType: values.extensionType,
          extensionMonths: values.extensionMonths,
          extensionAmount: values.extensionAmount,
          expiryType: values.expiryType,
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const {
      updatedAt,
      name,
      level = 1,
      upgradeConditions,
      validMonths = 12,
      memberPointActive = true,
      memberPointUnitPrice = 1,
      memberPointPerUnit = 1,
      isDefault = false,
      extensionType = 'SINGLE_PURCHASE',
      extensionMonths = 12,
      extensionAmount = 1000,
      expiryType = 'DATE',
    } = node || {};
    return {
      updatedAt,
      valid_type: validMonths === 99999 ? 0 : 1,
      name,
      level,
      upgradeConditions: removeTypename(
        cloneObject(
          upgradeConditions || [
            { type: 'SINGLE_PURCHASE', upgradeConditionAmount: 1000 },
            { type: 'SPECIFIC_PERIOD', upgradeConditionMonths: 12, upgradeConditionAmount: 1000 },
          ],
        ),
      ),
      validMonths,
      memberPointActive,
      memberPointUnitPrice,
      memberPointPerUnit,
      isDefault,
      extensionType,
      extensionMonths,
      extensionAmount,
      expiryType,
    };
  }
}
