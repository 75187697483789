import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { colorCodeWithOpacity } from '../../../../shared';
import DragListener from '../../../../components/DragListener';

const boxStyle = {
  position: 'absolute',
  width: 12,
  height: 12,
  borderColor: 'white',
  borderStyle: 'solid',
  borderWidth: 2,
};
const DraggableTableItem2 = ({ value, onChange, containerBox, allCoordinate }) => {
  const [moving, setMoving] = useState(false);
  const [scaling, setScaling] = useState(false);
  const [rotating, setRotating] = useState(false);

  const theme = useTheme();
  const { name, shapeData, status } = value || {},
    [x = 0, y = 0, width = 100, height = 100, rotate = 0] = shapeData || [];

  const ratio = containerBox.clientWidth / 1920;
  const backgroundColor =
    {
      PENDING: colorCodeWithOpacity(theme.palette.success.main, 0.5),
      PROCESSING: colorCodeWithOpacity(theme.palette.error.main, 0.5),
      COMPLETED: colorCodeWithOpacity('#ffeb3b', 0.5),
      CANCELED: colorCodeWithOpacity('#00000', 0.5),
    }[status] || colorCodeWithOpacity(theme.palette.success.main, 0.5);
  function inputRatio(num) {
    return num * ratio;
  }
  function outputRatio(num) {
    return num / ratio;
  }

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          cursor: 'grab',
          width: inputRatio(width),
          height: inputRatio(height),
          top: inputRatio(y),
          left: inputRatio(x),
          borderRadius: 9,
          backgroundColor,
          transform: `rotate(${rotate}deg)`,
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setMoving(true);
        }}
      >
        <Box style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography component={'span'} align="center" style={{ color: 'white' }}>
            {name}
          </Typography>
        </Box>
        <Box
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setRotating(true);
          }}
          style={{
            top: -6,
            right: -6,
            cursor: 'grab',
            ...boxStyle,
          }}
        />
        <Box
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setScaling(true);
          }}
          style={{
            bottom: -6,
            right: -6,
            cursor: 'crosshair',
            ...boxStyle,
          }}
        />
      </Box>
      {moving && (
        <DragListener
          onEnd={() => setMoving(false)}
          onDelta={(delta) => {
            onChange({
              ...value,
              shapeData: [
                outputRatio(inputRatio(x) + delta.x),
                outputRatio(inputRatio(y) + delta.y),
                width,
                height,
                rotate,
              ],
            });
          }}
        />
      )}
      {scaling && (
        <DragListener
          onEnd={() => setScaling(false)}
          onDelta={(delta) => {
            onChange({
              ...value,
              shapeData: [
                x,
                y,
                outputRatio(inputRatio(width) + delta.x),
                outputRatio(inputRatio(height) + delta.y),
                rotate,
              ],
            });
          }}
        />
      )}
      {rotating && (
        <DragListener
          onEnd={() => setRotating(false)}
          onDelta={(delta) => {
            onChange({
              ...value,
              shapeData: [x, y, width, height, rotate + delta.x],
            });
          }}
        />
      )}
    </>
  );
};

export default DraggableTableItem2;
