import { gql } from '@apollo/client';

export const FRAGMENT_CAMPAIGN = gql`
  fragment FRAGMENT_CAMPAIGN on ShopCampaign {
    createdAt
    updatedAt
    name
    actionTypes
    targetHashtags
    targetMatchMode
    excludedHashtags
    excludedMatchMode
    content
    richContent
    validAt
    validThru
    coupons {
      id
      name
      active
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_CAMPAIGN}
  query ($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_CAMPAIGN
    }
  }
`;

export const CREATE_QUERY = gql`
  ${FRAGMENT_CAMPAIGN}
  mutation ($id: ID, $input: CampaignSetInput!) {
    campaignSet(id: $id, input: $input) {
      id
      ...FRAGMENT_CAMPAIGN
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation ($id: ID!) {
    campaignUnset(id: $id) {
      id
      active
    }
  }
`;
