import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';

const BundleProductOrderItemContent = ({ value }) => {
  const active = value?.active;
  const fontWeight = active ? 'bold' : undefined;

  const option = value?.orderItem?.bundleProduct?.options?.find((opt) => opt?.id === value?.bundleProductOptionId);
  const remark = option?.remark;

  return (
    <>
      <TableCell width={'*'}>
        <Typography style={{ fontWeight }}>{value?.orderItem?.description}</Typography>
        <Typography color={'secondary'} component={'span'} style={{ fontWeight }}>
          {option?.label + ': '}
        </Typography>
        <Typography component={'span'} style={{ fontWeight }}>
          {option?.product?.name}
        </Typography>
        <VariationCombinationDisplay combination={option?.variation?.combination} remark={remark} />
      </TableCell>
      <TableCell width={100}>
        <Typography style={{ fontWeight }}>{option?.variation?.sku}</Typography>
      </TableCell>
    </>
  );
};

export default BundleProductOrderItemContent;
