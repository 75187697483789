import { gql } from '@apollo/client';

export const FRAGMENT_DEVICE = gql`
  fragment FRAGMENT_DEVICE on ShopDevice {
    id
    createdAt
    updatedAt
    active
    deviceId
    name
    status
    metrics(limits: 1) {
      nodes {
        id
        createdAt
        metadata {
          key
          value
        }
      }
    }
  }
`;

export const GET_QUERY = gql`
  query ($cursor: Int, $limits: Int, $sortBy: [SorterInput!], $query: String) {
    node: me {
      ... on User {
        id
        devices(cursor: $cursor, limits: $limits, sortBy: $sortBy, query: $query) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_DEVICE
            shop {
              name
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_DEVICE}
`;
