import React, { useEffect } from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import _ from 'lodash';
import { JSONParseSafely, parseConnection } from '../../shared';
import GridContainerWrapper from '../../components/GridContainerWrapper';
import * as Sentry from '@sentry/browser';
import CompanyShopSelector from '../item_SalePerformance/CompanyShopSelector';
import useQueryParams from '../../hooks/useQueryParams';
import ShopContainer from './ShopContainer';
import CompanyContainer from './CompanyContainer';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { SEARCH_QUERY as GET_SHOP_QUERY } from '../../components/SelectCombo/SelectComboCompanyShop';
import { Autocomplete } from '@material-ui/lab';

export const GET_SHOP = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on CompanyShop {
        type
        name
        hostname
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  labelStyle: {
    '& .MuiInputLabel-marginDense': {
      transform: 'translateY(10px)',
      paddingLeft: '12px',
    },
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = useQueryParams(),
    shop = JSONParseSafely(query?.shop);

  // start: check is it more than one shop
  const companyId = localStorage.getItem('companyId');
  const { data } = useQuery(GET_SHOP_QUERY, {
      variables: {
        query: `companyId.keyword:${companyId}`,
      },
    }),
    { nodes } = getConnection(data);
  function getConnection(data) {
    const { me } = data || {};
    return parseConnection((me || {}).shops);
  }
  const isMultiShop = nodes?.length > 1;
  // end: check is it more than one shop

  // start: check if shop is initialized
  const { loading, data: { node } = {} } = useQuery(GET_SHOP, {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: localStorage.getItem('shopId'),
      },
    }),
    { name, type, hostname } = node || {};

  useEffect(() => {
    if (!loading && !!node && (!name || !type || !hostname)) history.push('/initialize');
  }, [loading]);
  // end: check if shop is initialized

  useEffect(() => {
    Sentry.setTag('shopId', shop?.id || localStorage.getItem('shopId'));
    // experimentalLambdaWarmUp();
  }, []);

  return (
    <GridContainerWrapper>
      {/* <ProgressSteps /> */}
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12}>
          {isMultiShop ? (
            <CompanyShopSelector
              value={shop}
              onChange={(shop) => {
                setQuery((query) => ({
                  ...query,
                  shop: shop
                    ? JSON.stringify({
                        id: shop?.id,
                        name: shop?.name,
                      })
                    : undefined,
                }));
              }}
            />
          ) : (
            <div className={'flex w-full justify-end'}>
              <Autocomplete
                className={'w-[200px]'}
                disablePortal={false}
                size={'small'}
                disabled={true}
                renderInput={(params) => (
                  <TextField variant={'outlined'} {...params} label={nodes?.[0]?.name} className={classes.labelStyle} />
                )}
              />
            </div>
          )}
        </Grid>
        {!isMultiShop && <ShopContainer shop={nodes?.[0]} />}
        {!!shop?.id && isMultiShop && <ShopContainer shop={shop} />}
        {!shop?.id && isMultiShop && <CompanyContainer shop={shop} />}
      </Grid>
    </GridContainerWrapper>
  );
};
