import React from 'react';
import ImportModal from './ImportModal';

export const MenuExport = {
  render: function (props) {
    return (
      <ImportModal
        state={this}
        selectionData={props.scope.state.selectionData}
        onClick={() => {
          this.setState({ menuAnchorEl: undefined });
        }}
      />
    );
  },
};

export default MenuExport;
