import React from 'react';
import { Box, Grid, Paper, useTheme } from '@material-ui/core';
import { translate } from '../../shared/translate';
import Taber from '../../components/Taber';
import OrderAmountCard from './OrderAmountCard';
import PieCard from './PieCard';
import ConversionCard from './ConversionCard';
import { useQuery } from '@apollo/client';
import { GET_REPORT } from './query';
import FormHeader from '../item_SalePerformance/FormHeader';
import useQueryParams from '../../hooks/useQueryParams';
import { JSONParseSafely } from '../../shared';
import CompanyShopSelector from '../item_SalePerformance/CompanyShopSelector';

export default () => {
  const theme = useTheme();
  const [query, setQuery] = useQueryParams(),
    shop = JSONParseSafely(query?.shop),
    shopId = shop?.id || localStorage.getItem('shopId'),
    startedAt = query?.startedAt,
    startedThru = query?.startedThru;

  const { loading, data: { node } = {} } = useQuery(GET_REPORT, {
      fetchPolicy: 'network-only',
      skip: !shopId || !startedAt || !startedThru,
      variables: { id: shopId, startedAt, startedThru },
    }),
    { report: _report } = node || {},
    report = _report || {};

  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.customer_analysis,
          },
        ]}
        extra={
          <Box style={{ float: 'right' }}>
            <CompanyShopSelector
              value={shop}
              onChange={(value) => {
                setQuery((query) => ({
                  ...query,
                  shop: value
                    ? JSON.stringify({
                        id: value?.id,
                        name: value?.name,
                      })
                    : undefined,
                }));
              }}
            />
          </Box>
        }
      />
      <Paper square style={{ padding: theme.spacing(3) }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box textAlign={'right'}>
              <Grid container>
                <Grid item xs={true}></Grid>
                <Grid item>
                  <FormHeader />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <OrderAmountCard
              loading={loading}
              title={translate.total_spending_of_new_customers || '新客總消費'}
              data={report.newCustomerSales}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <OrderAmountCard
              loading={loading}
              title={translate.total_spending_of_returning_customers || '舊客總消費'}
              data={report.oldCustomerSales}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <OrderAmountCard
              loading={loading}
              title={translate.total_spending_of_members || '會員總消費'}
              data={report.customerSales}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <OrderAmountCard
              loading={loading}
              title={translate.total_spending_of_non_members || '非會員總消費'}
              data={report.nonCustomerSales}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PieCard loading={loading} title={translate.gender_analysis || '性別分析'} data={report.userGender} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PieCard loading={loading} title={translate.age_analysis || '年齡分析'} data={report.userAge} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PieCard loading={loading} title={translate.geographic_analysis || '地域分析'} data={report.userCountry} />
          </Grid>
          <Grid item xs={12}>
            <ConversionCard
              loading={loading}
              title={translate.workstage_conversion_rate || '工作階段轉換率'}
              data={report.pageViews}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
