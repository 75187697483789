import React from 'react';
import { Typography } from '@material-ui/core';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';

const CommonItemContent = ({ item: { sku, productVariation, description, remark } = {} }) => {
  const { combination } = productVariation || {},
    printDescription = productVariation?.product?.printDescription;

  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: '0.94rem' }}>{`${description}`}</Typography>
      <Typography style={{ fontSize: '0.81rem' }}>{`SKU： ${sku ?? productVariation?.sku}`}</Typography>
      <VariationCombinationDisplay
        combination={combination}
        remark={remark}
        direction={'column'}
        parseColorCode={false}
      />
      {!!printDescription && <Typography style={{ whiteSpace: 'pre-line' }}>{printDescription}</Typography>}
    </>
  );
};

export default CommonItemContent;
