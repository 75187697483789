import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ACTIVITIES_QUERY } from './query';
import { parseConnection } from '../../../shared';
import ActivityChain from '../../../components/ActivityChain';

const OrderActivitiesCard = ({ values: { id } = {} }) => {
  const { loading, data } = useQuery(GET_ACTIVITIES_QUERY, {
      variables: { id },
      skip: !id,
    }),
    { nodes } = getConnection(data);

  return <ActivityChain loading={loading} items={nodes} />;
  function getConnection({ node } = {}) {
    return parseConnection((node || {}).activities);
  }
};

export default OrderActivitiesCard;
