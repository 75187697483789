import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY, GET_BASIC_QUERY } from './query';
import { CompanyShopType } from '../../../shared/enum';
import { Chip } from '@material-ui/core';
import ActiveSwitcher from './ActiveSwitcher';
import { checkPermissionV2 } from '../../../components/PermissionMask';
import { PriceFormat, convertMetaObject } from '../../../shared';
import moment from 'moment';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    // createUrl: '/shops/new',
    gql: {
      get: checkPermissionV2('manageShops') ? GET_QUERY : GET_BASIC_QUERY,
    },
    // getRowLink: ({ id, name }) => ({ pathname: '/products/' + id, state: { title: name } }),
    onRowClick: (e, shop) => {
      const { history } = this.props;

      e.preventDefault();
      localStorage.setItem('shopId', shop?.id);
      localStorage.setItem('shopType', shop?.type);
      localStorage.setItem('companyId', shop?.company?.id);
      localStorage.setItem('stockWarehouseId', shop?.stockWarehouse?.id);
      history.replace('/');
    },
    fields: [
      {
        width: 250,
        title: translate.shop_name,
        fieldName: 'name',
        sortBy: 'name.keyword',
      },
      {
        width: 250,
        title: translate.store_type,
        fieldName: 'branchType',
        type: 'option',
        options: [
          {
            label: translate.main_store,
            value: 'MAIN',
          },
          {
            label: translate.branch_stores,
            value: 'BRANCH',
          },
        ],
      },
      {
        title: translate.store_language,
        width: 100,
        type: 'option',
        fieldName: 'locale',
        options: [
          {
            label: translate.zh_hk,
            value: 'zh-HK',
          },
          {
            label: translate.zh_cn,
            value: 'zh-CN',
          },
          {
            label: 'English',
            value: 'en-US',
          },
        ],
      },
      {
        width: 100,
        align: 'center',
        title: translate.shop_type,
        fieldName: 'type',
        type: 'option',
        options: CompanyShopType,
      },
      {
        width: 100,
        title: translate.image,
        fieldName: 'logoMedia',
        type: 'medias',
        resizeMode: 'contain',
      },
      {
        title: translate.contact_person,
        value: (row) => row?.address?.person,
      },
      {
        title: translate.contact_number,
        value: (row) => row?.address?.tel,
      },
      {
        width: 180,
        title: translate.join_date,
        fieldName: 'createdAt',
        type: 'datetime',
        sortBy: 'createdAt',
      },
      {
        width: 180,
        title: translate.sales_representative,
        fieldName: 'metadata.salesRepresentative',
        value: (row) => {
          return convertMetaObject(row.metadata ?? []).salesRepresentative;
        },
      },
      {
        width: 100,
        title: translate.status,
        render: ({ active }) => {
          return (
            <Chip
              size={'small'}
              color={active ? 'primary' : 'secondary'}
              label={active ? translate.in_operation : translate.deactivate || '停用中'}
            />
          );
        },
      },
      {
        title: translate.domain,
        value: (row) => {
          const domain = row?.customDomain || row?.hostname;
          if (domain) return `https://${row?.customDomain || row?.hostname}`;
          return '';
        },
      },
      checkPermissionV2('manageShops') && {
        title: translate.turnover,
        value: (row) => `${PriceFormat(row.report.turnover) ?? 0}`,
      },
      checkPermissionV2('manageShops') && {
        title: translate.operation || '有效',
        filter: 'active',
        filterType: 'bool',
        render: (row) => {
          const { id, active } = row || {};
          return <ActiveSwitcher id={id} active={active} />;
        },
        noLink: true,
      },
    ].filter((_) => _),
    qFields: ['name', 'customDomain', 'hostname'],
    // selectionMode: true,
    hasQSearch: true,
  };

  toggleModal = (modalName) => {
    const name = `modal${modalName}Open`;
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  getData = (data) => {
    return data?.me?.shops;
  };

  renderWrapper(...args) {
    return this.renderContent(...args);
  }

  getExtraFetchVariables = () => {
    return {
      startedAt: moment().startOf('day').toISOString(),
      startedThru: moment().endOf('day').toISOString(),
    };
  };
}
