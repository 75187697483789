import React from 'react';
import { GET_COLLECTIONS } from './query';
import { parseConnection } from '../../../shared';
import SelectComboCollection from '../../../components/SelectCombo/SelectComboCollection';
import { Query } from '@apollo/client/react/components';
import _ from 'lodash';

const CollectionCodesActionField = ({ disabled, action = {}, onChange = (_) => _ }) => {
  const { actionIds = [] } = action || {};

  return (
    <Query
      query={GET_COLLECTIONS}
      variables={{ id: localStorage.getItem('shopId'), codes: actionIds }}
      skip={(actionIds || []).length === 0}
      onCompleted={(data) => {
        const collections = parseCollections(data);
        onChange({
          ...action,
          actionIdName: _.uniq(collections.map((col) => col.name)).join('/'),
        });
      }}
    >
      {({ loading, data }) => {
        const _collections = parseCollections(data);
        const collections = _collections.concat(
          (actionIds || [])
            .filter((actionId) => !_collections.find((col) => col.id === actionId || col.code === actionId))
            .map((actionId) => ({
              id: actionId,
            })),
        );
        // const { name } = node || {};
        return (
          <SelectComboCollection
            copyableType={'ShopCollection'}
            idKey={'code'}
            multiple={true}
            required={true}
            disabled={disabled}
            value={collections}
            onChange={(collections) => {
              action.actionIds = collections.map((col) => col.code ?? col.id);
              action.actionIdName = collections.map((col) => col.name).join('/');
              onChange(action);
            }}
          />
        );
      }}
    </Query>
  );

  function parseCollections({ node } = {}) {
    const collections = parseConnection((node || {}).collections).nodes;
    const collections2 = parseConnection((node || {}).collections2).nodes;

    if (collections2.length > 0) return _.unionBy(collections, collections2, 'id');
    return collections;
  }
};

export default CollectionCodesActionField;
