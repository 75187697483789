import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import FormRow from '../../../components/FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import TrashButton from '../../../components/TrashButton';

const ItemUpdateCard = ({ value, onChange = (_) => {}, disabled }) => {
  return (
    <Card>
      <CardContent
        style={{
          maxHeight: 'calc(100vh - 80px)',
          overflowY: 'auto',
        }}
      >
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <TrashButton edge={'end'} disabled={disabled} onClick={() => onChange(undefined)} />
          </Grid>
          <FormRow title={translate.title}>
            <InputField
              disabled={disabled}
              value={value?.title}
              onChange={(e) =>
                onChange({
                  ...value,
                  title: e.target.value,
                })
              }
            />
          </FormRow>
          <FormRow title={translate.href}>
            <InputField
              disabled={disabled}
              value={value?.href}
              onChange={(e) =>
                onChange({
                  ...value,
                  href: e.target.value,
                })
              }
            />
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ItemUpdateCard;
