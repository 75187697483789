import React from 'react';
import { Add } from '@material-ui/icons';
import { translate } from '../../shared/translate';
import { useHistory } from 'react-router-dom';
import SystemButton from '../SystemButton';

const CreateButton = ({ to, onClick, disabled, children = translate.add }) => {
  const history = useHistory();
  return (
    <SystemButton
      disabled={disabled}
      className={'normal-btn'}
      variant={'outlined'}
      onClick={!!onClick ? onClick : () => history.push(to)}
      startIcon={<Add />}
    >
      {children}
    </SystemButton>
  );
};

export default CreateButton;
