import React from 'react';
import InputField from '../../../FormPageMaker/Fields/InputField';
import { makeStyles } from '@material-ui/core/styles';
import { convertMetaObject } from '../../../../shared';
import CustomFieldWrapper from '../CustomFieldWrapper';

const useStyles = makeStyles(() => ({
  resizable: {
    resize: 'vertical',
  },
  multilineInput: {
    minHeight: 60,
  },
}));

const TextareaField = ({ disabled, value, onChange, field = {} }) => {
  const classes = useStyles();
  const { metadata, name, required } = field ?? {};
  const { remarks, length, placeholder } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <InputField
        value={value}
        onChange={(e) => onChange(e?.target?.value)}
        multiline
        rows={4}
        rowsMax={4}
        variant={'outlined'}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        InputProps={{
          classes: {
            root: classes.multilineInput,
            input: classes.resizable,
          },
        }}
        inputProps={{
          maxLength: length,
        }}
      />
    </CustomFieldWrapper>
  );
};

export default TextareaField;
