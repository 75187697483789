import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ReactComponent as IconNotEnoughData } from '../../../assets/icon/not enough data.svg';
import { textColor } from '../../../theme';

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
    height: '100%',
  },
  table: {
    minWidth: '100%',
  },
  tableHead: {
    background: '#f7f7f7',
  },
});

export default ({ rows = [], columns = [], maxInt = 10, tableRowStyle }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell key={column.id || i} className={classes.tableHead} align={column.align} width={column.width}>
                <Typography
                  variation={'h5'}
                  style={{
                    fontWeight: '700',
                    color: textColor,
                  }}
                >
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={99} style={{ border: 'none' }}>
                <Grid container justify={'center'} alignItems={'center'}>
                  <Grid item>
                    <Box my={4}>
                      <IconNotEnoughData />
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}
          {rows.map((row, i) => {
            if (i < maxInt) {
              return (
                <TableRow
                  className="whereisrow"
                  key={i}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  style={{
                    height: '100px',
                  }}
                >
                  {row.map((val, j) => (
                    <TableCell
                      style={{ height: '100px', textOverflow: 'ellipsis' }}
                      align={columns?.[j]?.align}
                      key={j}
                    >
                      {{
                        image: (
                          <div
                            style={{
                              display: 'flex',
                              background: 'rgb(238, 238, 238)',
                              width: '100%',
                              height: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={val || require('../../../assets/OmniweLogo.png')}
                              style={{
                                width: 70,
                                height: 70,
                                objectFit: 'contain',
                                // objectFit: 'fill',
                              }}
                              alt="row img"
                              onError={(e) => {
                                e.target.src = require('../../../assets/OmniweLogo.png');
                              }}
                            />
                          </div>
                        ),
                      }[columns?.[j]?.type] ?? val}
                    </TableCell>
                  ))}
                </TableRow>
              );
            }
            return null;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
