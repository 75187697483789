import { gql } from '@apollo/client';

export const GET_ACTIVITIES_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on ShopOrder {
        activities {
          nextCursor
          totalCount
          nodes {
            id
            createdAt
            createdBy {
              id
              name
            }
            event
          }
        }
      }
    }
  }
`;
