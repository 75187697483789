import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import * as Sentry from '@sentry/browser';
import resolvers from './resolvers';
import _ from 'lodash';

const { REACT_APP_API_URL, REACT_APP_MAIN_SITE_URL, REACT_APP_X_API_KEY } = process.env;

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      ...(token
        ? {
            authorization: token,
          }
        : !!REACT_APP_X_API_KEY
        ? {
            'x-api-key': REACT_APP_X_API_KEY,
          }
        : {}),
    },
  };
});

const link = ApolloLink.from([
  onError(({ networkError, graphQLErrors /*response*/, operation }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      try {
        console.log(
          JSON.stringify({
            errors: graphQLErrors.map((error) => error.message),
            query: operation.query.loc.source.body,
            variables: operation.variables,
          }),
        );
        Sentry.captureMessage('*' + graphQLErrors[0].message);
      } catch (e) {
        console.log(e);
      }

      let msg = graphQLErrors[0].message || '';
      if (
        // /token is invalid/i.test(msg) ||
        /token has expired/i.test(msg) ||
        /session/i.test(msg) ||
        /authorization header/i.test(msg) ||
        /UnauthorizedException/i.test(msg) ||
        /Not Authorized/i.test(msg) ||
        /You are not authorized to make this call/i.test(msg)
      ) {
        localStorage.clear();
        window.location.href =
          `${REACT_APP_MAIN_SITE_URL}/login?redirectUrl=${window.location.origin}/login` || '/login';
      }
    }
  }),
  authLink,
  createSubscriptionHandshakeLink({
    url: REACT_APP_API_URL,
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: () => localStorage.getItem('token'),
    },
  }),
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
  },
  query: {
    fetchPolicy: 'cache-first',
  },
};

export const client = new ApolloClient({
  link: link,
  resolvers,
  cache: new InMemoryCache({
    typePolicies: {
      CheckoutBundleProductOption: {
        keyFields: (obj) => {
          return [obj?.id, obj?.remark, obj?.variation?.id, obj?.variation?.__ref].filter(Boolean).join();
        },
      },
      CompanyShop: { merge },
      Media: {
        keyFields: (obj) => {
          return obj?.id || obj?.src || obj?.optimizedSrc || '_';
        },
      },
    },
  }),
  defaultOptions,
});

function merge(existing, incoming) {
  return _.merge(existing ?? {}, incoming ?? {});
}
