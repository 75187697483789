import { Collapse, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useState } from 'react';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import { NumberFormat } from '../../../shared';
import { textColor } from '../../../theme';

const BundleProductOrderItem = ({ editable, disabled, orderItem, shippedQuantity, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const { bundleProduct: _bundleProduct, quantity } = orderItem || {},
    { bundleProduct, options } = _bundleProduct || {},
    { name } = bundleProduct || {};
  const max = quantity - shippedQuantity;

  return (
    <>
      <TableRow hover style={{ cursor: 'pointer', backgroundColor: open && '#eee' }} onClick={() => setOpen(!open)}>
        <TableCell>
          <Grid container alignItems="center" spacing={1}>
            <Grid item style={{ display: 'flex' }}>
              {open ? (
                <ExpandMore
                  style={{
                    color: textColor,
                  }}
                />
              ) : (
                <ExpandLess
                  style={{
                    color: textColor,
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
        </TableCell>
        {!!editable && (
          <>
            <TableCell align={'right'}>
              <Typography align={'center'}>{NumberFormat(quantity)}</Typography>
            </TableCell>
            <TableCell align={'right'}>
              <Typography align={'center'}>{NumberFormat(shippedQuantity)}</Typography>
            </TableCell>
          </>
        )}
        <TableCell>
          <InputField
            disabled={disabled || !editable}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            type={'number'}
            inputProps={{
              step: 1,
              max: +max,
              style: {
                textAlign: 'center',
              },
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} style={{ padding: 0 }}>
          <Collapse in={open}>
            <Table>
              <TableBody>
                {(options || []).map(({ label, variation, product, sku }, i) => {
                  const { name } = product || {};
                  const { combination } = variation;
                  return (
                    <TableRow hover>
                      <TableCell style={{ paddingLeft: 45 }}>
                        <Typography color={'secondary'} component={'span'}>
                          {label + ': '}
                        </Typography>
                        <Typography component={'span'}>{name}</Typography>
                        <Typography>{product.sku}</Typography>
                        <VariationCombinationDisplay combination={combination} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default BundleProductOrderItem;
