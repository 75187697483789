import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { Grid, Typography } from '@material-ui/core';
import StackTags from '../../../components/StackTags';
import ExportButton from './ExportButton';
import StatusBadge from '../../../components/StatusBadge';
import { theme } from '../../../theme';
import Activation from '../../../components/Activation';
import MenuActivate from './MenuActivate';
import MenuDeactivate from './MenuDeactivate';
import MenuDelete from './MenuDelete';
import { NumberFormat } from '../../../shared';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/campaigns/new',
    selectionMode: true,
    getRowLink: ({ id, name }) => ({
      pathname: `/campaigns/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.heading,
        fieldName: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: translate.broadcast_channel,
        width: 250,
        render: ({ actionTypes = [] }) => {
          return (
            <Grid container spacing={1}>
              <Grid item>
                <StatusBadge status={'PUSH'} statusObject={getStatusObject(!!~actionTypes.indexOf('PUSH') && 'PUSH')} />
              </Grid>
              <Grid item>
                <StatusBadge status={'SMS'} statusObject={getStatusObject(!!~actionTypes.indexOf('SMS') && 'SMS')} />
              </Grid>
              <Grid item>
                <StatusBadge
                  status={'EMAIL'}
                  statusObject={getStatusObject(!!~actionTypes.indexOf('EMAIL') && 'EMAIL')}
                />
              </Grid>
            </Grid>
          );
          function getStatusObject(status) {
            return {
              PUSH: { label: 'Push', bgColor: status === 'PUSH' ? theme.palette.primary.main : '#ddd' },
              SMS: { label: 'SMS', bgColor: status === 'SMS' ? theme.palette.primary.main : '#ddd' },
              EMAIL: { label: 'Email', bgColor: status === 'EMAIL' ? theme.palette.primary.main : '#ddd' },
            };
          }
        },
      },
      {
        title: translate.targetHashtags,
        render: ({ targetHashtags = [] }) => (
          <StackTags
            tags={targetHashtags}
            onTagClick={(tag) => {
              const { filter } = this.state;
              this.onFilterChange({
                ...filter,
                targetHashtags: { operator: 'LIKE', value: [tag] },
              });
            }}
          />
        ),
        type: 'text',
        filter: 'targetHashtags',
      },
      {
        width: 100,
        title: translate.match_all,
        render: ({ targetMatchMode }) => <Activation active={targetMatchMode === 'ALL'} />,
        align: 'center',
        sortBy: 'targetMatchMode.keyword',
      },
      {
        title: translate.excludeHashtags,
        render: ({ excludedHashtags = [] }) => (
          <StackTags
            tags={excludedHashtags || []}
            onTagClick={(tag) => {
              const { filter } = this.state;
              this.onFilterChange({
                ...filter,
                excludedHashtags: { operator: 'LIKE', value: [tag] },
              });
            }}
          />
        ),
        type: 'text',
        filter: 'excludedHashtags',
      },
      {
        width: 100,
        title: translate.excluded_match_all,
        render: ({ excludedMatchMode }) => <Activation active={excludedMatchMode === 'ALL'} />,
        align: 'center',
        sortBy: 'excludedMatchMode.keyword',
      },
      {
        title: translate.release_status,
        render: ({ result }) => <Typography>{NumberFormat(result?.successCount ?? 0)}</Typography>,
        type: 'text',
      },
      {
        title: translate.action,
        render: ({ id }) => {
          return <ExportButton id={id} />;
        },
        noLink: true,
      },
      {
        title: translate.valid_start_date_time,
        width: 180,
        align: 'right',
        fieldName: 'validAt',
        type: 'datetime',
        sortBy: 'validAt',
      },
      {
        title: translate.valid_end_date_time,
        width: 180,
        align: 'right',
        fieldName: 'validThru',
        type: 'datetime',
        sortBy: 'validThru',
      },
      {
        title: translate.status,
        align: 'center',
        fieldName: 'active',
        type: 'bool',
        sortBy: 'active',
      },
    ],
    menus: [MenuActivate, MenuDeactivate, MenuDelete],
    hasQSearch: true,
    qFields: ['name', 'targetHashtags'],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    const { campaigns } = node || {};
    return campaigns;
  };
  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
