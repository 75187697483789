import { gql } from '@apollo/client';

export const FRAGMENT_PAYMENT_METHOD = gql`
  fragment FRAGMENT_PAYMENT_METHOD on PaymentMethod {
    id
    name
    description
    createdAt
    updatedAt
    active
    enabled
    provider
    sortIndex
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: PaymentMethodFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        paymentMethods(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_PAYMENT_METHOD
          }
        }
      }
    }
  }
  ${FRAGMENT_PAYMENT_METHOD}
`;
