import React, { useState } from 'react';
import { translate } from '../../../../shared/translate';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import { actionWrapper } from '../../../../shared';
import { Dialog, DialogContent, MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import FormCard from '../../../../components/FormPageMaker/Cards/FormCard';
import FormToolbar from '../../../../components/FormToolbar';

const ORDERS_CANCEL = gql`
  mutation ($shopId: ID!, $filter: OrderFilterInput, $query: String, $cancelReason: String!) {
    ordersCancel(shopId: $shopId, filter: $filter, query: $query, cancelReason: $cancelReason) {
      id
    }
  }
`;

export const ManuCancel = ({ scope, mutation = ORDERS_CANCEL, ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <MenuItem disabled={scope.getSelectionCount() === 0} onClick={() => setVisible(true)}>
        {translate.cancel_order}
      </MenuItem>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Formik
          initialValues={{ cancelReason: '' }}
          onSubmit={async (values) => {
            setVisible(false);
            const shopId = localStorage.getItem('shopId');
            await actionWrapper(
              async () => {
                if (values.quantity !== 0)
                  await client.mutate({
                    mutation,
                    variables: {
                      shopId,
                      filter: {
                        ...scope.getSelectionFilter(),
                        shopId: {
                          operator: 'IN',
                          value: [shopId],
                        },
                      },
                      query: scope.getQ(),
                      cancelReason: values.cancelReason,
                    },
                  });
              },
              { messages: { success: translate.order_removed_msg } },
            );
          }}
        >
          {(actions) => (
            <form onSubmit={actions.handleSubmit}>
              <DialogContent>
                <FormCard
                  actions={actions}
                  fields={[
                    {
                      label: translate.cancel_reason,
                      type: 'textarea',
                      name: 'cancelReason',
                      required: true,
                    },
                  ]}
                />
              </DialogContent>
              <FormToolbar
                loading={actions.isSubmitting}
                disabled={actions.isSubmitting}
                onCancel={() => setVisible(false)}
                submitBtnType={'submit'}
              />
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default {
  render: (args) => <ManuCancel {...args} />,
};
