import React from 'react';
import { GET_QUERY } from './query';
import { Container } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import OrderPrintout from './OrderPrintout';
import _ from 'lodash';

export default ({ match: { params: { id } = {} } = {} }) => {
  const { loading, data: { node: _node } = {} } = useQuery(GET_QUERY, {
    errorPolicy: 'all',
    variables: { id },
    skip: !id,
  });

  if (!id) return null;

  const { fontSize = '', sort = '' } = !!window.location.search
    ? // for list page - print button: choose font size and sort by
      JSON.parse(
        '{"' +
          window.location.search.substring(1).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
          '"}',
      )
    : // for item page - print button: no option
      {};

  const sortedNode = {
    ..._node,
    items: _.sortBy(_node?.items, (item) => {
      function getProductItem(item) {
        return item?.productVariation || item?.bundleProduct?.bundleProduct || item?.addOnProduct;
      }

      if (sort === 'sku') {
        return getProductItem(item)?.sku?.toUpperCase();
      } else {
        return _node?.items?.find((_item) => getProductItem(_item)?.id === getProductItem(item)?.id)?.createdAt;
      }
    }),
  };

  const _loading = loading || !sortedNode;

  return (
    <>
      <style>
        {`
          html, body, #root {
            height: auto; 
            font-size: ${fontSize} !important;
          }
          @media print {
            html, body {
              font-size: ${fontSize} !important;
            }
          }
        `}
      </style>
      <Container style={{ maxWidth: 1200 }}>
        <OrderPrintout loading={_loading} order={sortedNode} />
      </Container>
    </>
  );
};
