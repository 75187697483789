import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import PasswordField from './PasswordField';
import InputField from './InputField';
import { Icon } from '../../IconRender';
import LSkeleton from '../../LSkeleton';

export default ({ loading, disabled, onChange, placeholder = '********', ...props }) => {
  const [show, setShow] = useState(false);

  if (loading) return <LSkeleton height={30} />;
  if (!show)
    return (
      <InputField
        disabled
        value={placeholder}
        // eslint-disable-next-line
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'} disableTypography>
              <IconButton size={'small'} color={'primary'} disabled={disabled} onClick={() => setShow(true)}>
                <Icon icon={'faPencil'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  return (
    <PasswordField
      autoFocus
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      // eslint-disable-next-line
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'} disableTypography>
            <IconButton size={'small'} color={'secondary'} disabled={disabled} onClick={() => setShow(false)}>
              <Icon icon={'faTimes'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
