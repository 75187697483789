import React from 'react';
import { Button, Typography, Box, withStyles } from '@material-ui/core';

export const tonalities = [
  {
    label: '休閒',
    value: 'casual',
  },
  {
    label: '專業',
    value: 'professional',
  },
  {
    label: '優雅',
    value: 'elegant',
  },
  {
    label: '直率',
    value: 'straightforward',
  },
  {
    label: '急切',
    value: 'eager',
  },
  {
    label: '幽默',
    value: 'humorous',
  },
];
const styles = (theme) => ({
  tonalityStepBox: {
    padding: '10px 20px',
    // height: '450px',
  },
  stepTitleBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },
  stepTitle: {
    color: '#1F4C6D',
    fontSize: '18px',
    fontWeight: '500',
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    // margin: '70px 0px',
    // minHeight: '270px',
  },
  tonalityBtn: {
    height: 120,
    padding: '50px 100px',
    borderRadius: '10px',
    border: '1px solid #FFF',
    background: 'rgba(255, 255, 255, 0.40)',
    boxShadow: '2px 2px 2px 0px rgba(255, 255, 255, 0.40) inset, 2px 2px 7px 0px rgba(255, 255, 255, 0.40)',
  },
  tonalityBtnText: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#5B7D96',
  },
});

const ChatGptTonalityStep = ({ disabled, classes, onChange }) => {
  return (
    <Box className={classes.tonalityStepBox}>
      <Box className={classes.stepTitleBox}>
        <Typography className={classes.stepTitle}>請選擇語調</Typography>
      </Box>
      <Box className={classes.btnBox}>
        {tonalities.map(({ label, value }, i) => {
          return (
            <Button
              key={i}
              className={classes.tonalityBtn}
              disabled={disabled}
              variant={'outlined'}
              type={'button'}
              onClick={() => {
                onChange(value);
              }}
            >
              <Typography className={classes.tonalityBtnText}>{label}</Typography>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ChatGptTonalityStep);
