import React, { useRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import s3Upload from '../../../../shared/s3Upload';
import { client } from '../../../../shared/apollo';
import { toast } from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { COLLECTIONS_IMPORT_QUERY } from './query';

const ImportModal = () => {
  const productsImportInput = useRef();

  return (
    <MenuItem
      onClick={() => {
        productsImportInput.current.click();
      }}
    >
      {translate.import}
      <input
        ref={productsImportInput}
        style={{ visibility: 'hidden', position: 'fixed' }}
        type={'file'}
        onChange={async (evt) => {
          evt.preventDefault();
          const files = evt.target.files;
          const file = files[0];
          try {
            ActivityIndicator.show();

            evt.target.value = null;

            const s3Path = await s3Upload(file);
            await client.mutate({
              mutation: COLLECTIONS_IMPORT_QUERY,
              variables: {
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
              },
            });
            toast.success(translate.importing_submit);
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            ActivityIndicator.hide();
          }
        }}
      />
    </MenuItem>
  );
};

export default ImportModal;
