import { Divider, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const ReportRow = ({ label, value, loading }) => {
  return (
    <Grid
      container
      justifyContent="center"
      style={{ backgroundColor: '#efefef', borderRadius: 9, padding: '0px 16px' }}
    >
      <Grid item xs={12} style={{ padding: '8px 0px' }}>
        <Typography variant="h6" align="center">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{ padding: '16px 0px' }}>
        {loading ? (
          <Skeleton variant="text" />
        ) : (
          <Typography
            variant="h5"
            align="center"
            style={{
              fontWeight: 600,
              color: 'rgba(0,0,0,0.5)',
            }}
          >
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ReportRow;
