import React from 'react';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import { Typography } from '@material-ui/core';
import MagicMediasField from '../../../components/FormPageMaker/Fields/MagicMediasField';

class VariantTable extends ConnectionPageMarker {
  state = {
    ...this.state,
    skip: true,
    refreshable: false,
    limits: 9999,
    fields: [
      {
        title: translate.product_variants,
        render: ({ sku, combination }) => (
          <div>
            <Typography>SKU: {sku}</Typography>
            <VariationCombinationDisplay combination={combination} />
          </div>
        ),
        width: 250,
      },
      {
        title: translate.image,
        render: ({ medias, __row }) => {
          const { cursor = 0 } = this.state;
          const { onChange, variations, disabled } = this.props;
          return (
            <MagicMediasField
              disabled={disabled}
              value={medias}
              onChange={(medias) => {
                onChange(variations.map((v, j) => (j === cursor + __row ? { ...v, medias } : v)));
              }}
            />
          );
        },
      },
    ],
  };

  renderWrapper(res) {
    const { loading } = this.props;
    return this.renderContent({ ...res, loading });
  }

  getData(data = {}) {
    const { variations = [] } = this.props;
    return { nodes: variations, totalCount: variations.length, nextCursor: null };
  }
}

export default VariantTable;
