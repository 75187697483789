import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { CREATE_NEW_CHANNEL } from './query';
import { client } from '../../shared/apollo';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FF4731',
    '&:hover': {
      backgroundColor: '#DF3D2A',
      boxShadow: 'none',
    },
  },
});

export default withStyles(styles)(
  ({ classes, code, type, shopId, onChannelCreate = (_) => _, height = 444, disabled }) => {
    const [loading, setLoading] = useState(false);
    return (
      <Paper square elevation={0} style={{ height }}>
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          justify={'center'}
          spacing={2}
          style={{ height: '100%' }}
        >
          <Grid item>
            <Typography variant={'h6'} color={'textSecondary'}>
              {translate.chatroom_not_established || '頻道尚未建立'}
            </Typography>
          </Grid>
          {!!code && !!type && (
            <Grid item>
              <Button
                disabled={loading || disabled}
                variant={'contained'}
                type={'button'}
                className="omniew-red-btn"
                onClick={async () => {
                  try {
                    setLoading(true);
                    const { data: { channelSet } = {} } = await client.mutate({
                      mutation: CREATE_NEW_CHANNEL,
                      variables: {
                        input: {
                          shopId,
                          code,
                          type,
                        },
                      },
                    });
                    onChannelCreate(channelSet);
                  } catch (e) {
                    toast.error(errorParser(e));
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <Box color={'white'}>{translate.add}</Box>
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  },
);
