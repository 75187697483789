import React, { useState, useEffect, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { listReorder } from '../shared';
import { useDebounce } from 'react-use';
import { Icon } from './IconRender';
import { ReactComponent as IconDragVertical } from '../assets/icon/drag-vertical.svg';

const Dnd = ({
  direction,
  items: _items = [],
  renderItem = (item, i, { setDraggable, draggable, nonDraggableProps }) => {
    console.log(item, i);
    return null;
  },
  onChange = (_) => _,
  onOrderUpdate = (_) => _,
  disabled: _disabled,
  header,
  children,
  spacing = 1,
  gridContainerProps = {},
  gridItemProps = {},
  style = {},
  getKey = (item, i) => i,
}) => {
  const [draggable, setDraggable] = useState(false);

  const [items, setItems] = useState(_items);
  const [originalDraggingIndex, setOriginalDraggingIndex] = useState(undefined);
  const [draggingIndex, setDraggingIndex] = useState(-1);

  useEffect(() => {
    setItems(_items);
  }, [_items]);

  useDebounce(
    () => {
      if (!draggable) setDraggable(true);
    },
    500,
    [draggable],
  );

  const disabled = _disabled || !draggable;

  return (
    <Grid
      container
      direction={direction}
      spacing={spacing}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      style={{ position: 'relative' }}
      {...gridContainerProps}
    >
      {!!header && (
        <Grid
          item
          onDragEnter={
            !disabled
              ? () => {
                  if (draggingIndex !== -1) {
                    setItems(listReorder(items, draggingIndex, 0));
                    setDraggingIndex(0);
                  }
                }
              : undefined
          }
        >
          {header}
        </Grid>
      )}
      {items.map((item, i) => {
        const isDragging = draggingIndex === i;
        return (
          <Fragment key={getKey(item, i)}>
            <Grid
              item
              draggable={draggable}
              style={{
                opacity: isDragging ? 0.1 : 'initial',
              }}
              onDragStart={() => {
                if (disabled) return;
                setOriginalDraggingIndex(i);
                setDraggingIndex(i);
              }}
              onDragEnd={() => {
                if (disabled) return;
                setDraggingIndex(-1);
                onChange(items);
                onOrderUpdate(originalDraggingIndex, draggingIndex);
              }}
              onDragEnter={() => {
                if (disabled) return;
                if (draggingIndex !== -1 && draggingIndex !== i) {
                  setItems(listReorder(items, draggingIndex, i));
                  setDraggingIndex(i);
                }
              }}
              {...gridItemProps}
            >
              {renderItem(item, i, {
                setDraggable,
                draggable,
                nonDraggableProps: {
                  onMouseDown: (e) => {
                    setDraggable(false);
                  },
                  onTouchStart: (e) => {
                    setDraggable(false);
                  },
                },
              })}
            </Grid>
          </Fragment>
        );
      })}
      {!!children && (
        <Grid
          item
          onDragEnter={
            !disabled
              ? () => {
                  if (draggingIndex !== -1) {
                    setItems(listReorder(items, draggingIndex, items.length - 1));
                    setDraggingIndex(items.length - 1);
                  }
                }
              : undefined
          }
        >
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default Dnd;

export const DragHandleIcon = () => <Icon icon={IconDragVertical} type={'svg'} />;
