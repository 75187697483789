import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { translate } from '../../shared/translate';
import FormToolbar from '../FormToolbar';
import SystemButton from '../SystemButton';

const PrintLabelsDialog = ({ open, onClose, groupByProviders }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: '400px' },
      }}
    >
      <DialogTitle style={{ alignSelf: 'center' }}>{translate.select_shipping_company || '選擇物流公司'}</DialogTitle>
      <Divider style={{ margin: '0 30px' }} />
      <DialogContent style={{ padding: '50px 30px' }}>
        <Box style={{ minWidth: 250 }}>
          <Grid container spacing={3}>
            {groupByProviders?.map((deliveryNotes, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <SystemButton
                    onClick={() => {
                      onClose();
                      window.open(`/print/external/${Object.keys(deliveryNotes)[0].toLowerCase()}`, '_blank');
                      localStorage.setItem('bulkExternalShipment', JSON.stringify(Object.values(deliveryNotes)[0]));
                    }}
                    startIcon={
                      Object.keys(deliveryNotes)[0] === 'SF_EXPRESS' ? (
                        <img style={{ width: '1.4rem' }} src={require('../../assets/sf.png')} alt="sf" />
                      ) : (
                        <img style={{ width: '1.2rem' }} src={require('../../assets/shipany.png')} alt="shipany" />
                      )
                    }
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      border: '1px solid #d0d0d0',
                    }}
                  >
                    <Grid container style={{ justifyContent: 'space-between' }}>
                      <Grid item xs={10}>
                        <Typography style={{ fontSize: '1.2rem', textAlign: 'left' }}>
                          {Object.keys(deliveryNotes)[0] === 'SF_EXPRESS' ? translate.sf_express || '順豐' : 'ShipAny'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: '1.2rem', textAlign: 'right' }}>
                          × {Object.values(deliveryNotes)[0].length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </SystemButton>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </DialogContent>
      <FormToolbar onCancel={onClose} />
    </Dialog>
  );
};

export default PrintLabelsDialog;
