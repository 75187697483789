export default [
  {
    name: 'menu_text_agent',
    children: [
      // {
      //   name: 'agent_service_list',
      //   icon: 'faHandshake',
      //   link: '/agent_service',
      //   component: require('../list_AgentService').default,
      //   permission: 'agent_service.read'
      // },
      // {
      //   link: '/agent_service/:id',
      //   component: require('../item_AgentService').default
      // },
      // {
      //   link: '/agent_service_application/:id/:appId?',
      //   component: require('../item_AgentApplication').default
      // },
      // {
      //   name: 'user_list',
      //   icon: 'faUser',
      //   link: '/users',
      //   component: require('../list_User').default,
      //   permission: 'user.read'
      // },
      // {
      //   link: '/users/:id',
      //   component: require('../item_User').default,
      //   exact: false
      // }
    ],
  },
];
