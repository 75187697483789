import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import ItemsTable from './ItemsTable';
import _ from 'lodash';

const ItemsCard = ({
  loading,
  values: { id, items: _items = [], status, __disabled } = {},
  setFieldValue,
  isSubmitting,
}) => {
  const editable = !!~['PENDING'].indexOf(status) && !__disabled;
  const disabled = isSubmitting || !editable;
  const items = _.sortBy(_items || [], (item) => item?.sku?.toUpperCase());

  return (
    <CardContent style={{ width: '100%' }}>
      <Typography variant={'h6'}>{translate.stock_out_items}</Typography>
      <Card variant={'outlined'}>
        <ItemsTable loading={loading} disabled={disabled} items={items} onChange={(v) => setFieldValue('items', v)} />
      </Card>
    </CardContent>
  );
};

export default ItemsCard;
