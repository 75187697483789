import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import ActiveSwitcher from './ActiveSwitcher';
import EllipsisTypography from '../../../components/EllipsisTypography';
import DisplaySwitcher from './DisplaySwitcher';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    createUrl: '/bundle_products/new',
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id, name }) => ({ pathname: '/bundle_products/' + id, state: { title: name } }),
    fields: [
      {
        title: translate.bundle_product_sku,
        fieldName: 'sku',
        width: 150,
        sortBy: 'sku.keyword',
      },
      {
        title: translate.image,
        fieldName: 'medias',
        type: 'medias',
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: translate.menu_product_collections,
        filter: 'collectionIds',
        filterType: 'collection',
        filterChannel: 'q',
        render: ({ collections = {} }) => {
          const path = collections?.nodes?.map(({ name }) => name).join(', ');
          return (
            <>
              <EllipsisTypography>{path}</EllipsisTypography>
            </>
          );
        },
      },
      {
        title: translate.online_day_time,
        fieldName: ({ publishAt }) => (publishAt ? 'publishAt' : 'createdAt'),
        type: 'datetime',
        filter: 'publishAt',
        filterChannel: 'q',
        sortBy: 'publishAt',
      },
      {
        title: translate.offline_day_time,
        value: ({ publishThru }) => publishThru || translate.good_til_cancel,
        type: ({ publishThru }) => (!!publishThru ? 'datetime' : undefined),
        sortBy: 'publishThru',
      },
      {
        title: translate.price,
        value: ({ unitPrice }) => unitPrice,
        type: 'price',
        filter: 'unitPrice',
        filterChannel: 'q',
        sortBy: 'unitPrice',
      },
      {
        title: translate.activation,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} data={data} onCompleted={this.refetch} />;
        },
        noLink: true,
        filter: 'active',
        type: 'bool',
        filterChannel: 'q',
      },
      {
        title: translate.visible,
        width: 100,
        render: (data) => {
          const { id, display } = data || {};
          return <DisplaySwitcher id={id} display={display ?? true} data={data} onCompleted={this.refetch} />;
        },
        noLink: true,
        filter: 'display',
        type: 'bool',
        filterChannel: 'q',
      },
    ],
    qFields: ['sku', 'name', 'subtitle', 'collectionPaths'],
    selectionMode: true,
    hasQSearch: true,
  };

  getData = ({ node } = {}) => {
    const { bundleProducts } = node || {};
    return bundleProducts;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
