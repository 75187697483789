import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import Sorting from '../item_CollectionSorting/Sorting';
import { Box } from '@material-ui/core';
import useQueryParams from '../../hooks/useQueryParams';

export default (props) => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery((query) => ({
            ...query,
            tab,
            cursor: undefined,
          }));
        }}
        tabs={[
          { name: translate.all_categories },
          { name: translate.online_products },
          { name: translate.offline_products },
          {
            name: translate.category_hierarchy,
          },
        ]}
      />
      <Box>
        {
          {
            0: <ListView key={tab} {...props} filter={{}} />,
            1: <ListView key={tab} {...props} filter={{ active: { operator: 'IN', value: ['true'] } }} />,
            2: <ListView key={tab} {...props} filter={{ active: { operator: 'IN', value: ['false'] } }} />,
            3: <Sorting key={tab} {...props} />,
          }[tab]
        }
      </Box>
    </div>
  );
};
