import React from 'react';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import { GET_COLLECTION_PRODUCTS } from '../query';
import { translate } from '../../../shared/translate';
import { Box, Typography } from '@material-ui/core';
import { PriceFormat, sleep } from '../../../shared';
import AddToCategoryButton from './AddToCategoryButton';
import RemoveFromCategoryButton from './RemoveFromCategoryButton';

export default ({ loading, values: { id } = {}, values }) => {
  return <ProductsListView id={id} loading={loading} />;
};

class ProductsListView extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_COLLECTION_PRODUCTS,
    },
    getRowLink: ({ id, name, __typename }) => ({
      pathname: (__typename === 'ShopProduct' ? '/products/' : '/bundle_products/') + id,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.product_sku,
        fieldName: 'sku',
        width: 150,
        sortBy: 'sku.keyword',
      },
      {
        title: translate.image,
        fieldName: 'medias',
        type: 'media',
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
      },
      {
        title: translate.shelf_time,
        fieldName: ({ publishAt }) => (publishAt ? 'publishAt' : 'createdAt'),
        type: 'datetime',
        sortBy: 'publishAt',
      },
      {
        title: translate.off_shelf_time,
        value: ({ publishThru }) => publishThru || translate.good_til_cancel,
        type: ({ publishThru }) => (!!publishThru ? 'datetime' : undefined),
        sortBy: 'publishThru',
      },
      {
        title: translate.price,
        render: ({ basePrice, maxPrice, __typename, unitPrice }) => {
          if (__typename === 'ShopBundleProduct') {
            return <Typography>{PriceFormat(unitPrice)}</Typography>;
          }
          if (basePrice !== maxPrice)
            return <Typography>{`${PriceFormat(basePrice)} - ${PriceFormat(maxPrice)}`}</Typography>;
          return <Typography>{PriceFormat(basePrice)}</Typography>;
        },
        type: 'price',
        sortBy: 'basePrice',
      },
      {
        title: translate.quantity,
        align: 'right',
        render: ({
          variations: _variations,
          __typename,
          ignoreStock: bundleProductIgnoreStock,
          quantity: bundleProductQuantity,
        }) => {
          if (__typename === 'ShopBundleProduct') {
            return <Typography>{`${!!bundleProductIgnoreStock ? '∞' : bundleProductQuantity}`}</Typography>;
          }
          const variations = _variations || [];
          const { quantity, ignoreStock } = variations.reduce(
            (reducer, variation) => {
              const { quantity, ignoreStock } = variation || {};
              return {
                quantity: reducer.quantity + (+quantity || 0),
                ignoreStock: !!ignoreStock || reducer.ignoreStock,
              };
            },
            { quantity: 0, ignoreStock: false },
          );
          const isInfiniteStock = !!ignoreStock,
            hasMultipleVariations = variations.length > 1;
          return <Typography>{`${isInfiniteStock ? '∞' : quantity}${hasMultipleVariations ? '*' : ''}`}</Typography>;
        },
      },
    ],
    selectionMode: true,
    hasQSearch: true,
    qFields: ['sku', 'name', 'subtitle', 'collectionPaths', 'variationSkus', 'variationBarcodes'],
    limits: 10,
  };

  renderContent({ loading, ...args }) {
    const { loading: _loading } = this.props;
    return super.renderContent({ ...args, loading: loading || _loading });
  }

  renderExtras() {
    const { id, loading } = this.props;
    const { selectionData } = this.state;
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <AddToCategoryButton disabled={loading} currCollectionId={id} list={this} />
        <RemoveFromCategoryButton
          currCollectionId={id}
          list={this}
          disabled={selectionData?.mode === 'INCLUDE' && !selectionData?.data?.length}
        />
      </Box>
    );
  }

  getData = ({ node } = {}) => {
    const { productUnions } = node || {};
    return productUnions;
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }

  getSkip() {
    const { id } = this.props;
    return !id;
  }
}
