import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { translate } from '../../shared/translate';
import SelectComboValue from '../../components/SelectCombo/SelectComboValue';
import CustomTimeField from '../../components/FormPageMaker/Fields/CustomTimeField';

const DailyAvailabilityField = ({ disabled, value, onChange }) => {
  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography>{translate.week_day_availability}</Typography>
        </Grid>
        <Grid item xs={true}>
          <SelectComboValue
            disabled={disabled}
            multiple={true}
            options={[
              { label: translate.sunday, value: '0' },
              { label: translate.monday, value: '1' },
              { label: translate.tuesday, value: '2' },
              { label: translate.wednesday, value: '3' },
              { label: translate.thursday, value: '4' },
              { label: translate.friday, value: '5' },
              { label: translate.saturday, value: '6' },
            ]}
            value={value?.daysOfWeek || []}
            onChange={(v) =>
              onChange({
                ...value,
                daysOfWeek: v,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography>{translate.start_time}</Typography>
        </Grid>
        <Grid item>
          <CustomTimeField
            value={value?.startedAt}
            disabled={disabled}
            onChange={(v) => {
              onChange({
                ...value,
                startedAt: v,
              });
            }}
          />
        </Grid>
        <Grid item>
          <Typography>{translate.end_time}</Typography>
        </Grid>
        <Grid item>
          <CustomTimeField
            value={value?.startedThru}
            disabled={disabled}
            onChange={(v) => {
              onChange({
                ...value,
                startedThru: v,
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DailyAvailabilityField;
