import { translate } from '../../../shared/translate';

export default {
  platform: {
    label: `Omniwe ${translate.credential}`,
    value: 'MANUAL',
  },
  form: (actions) => {
    return null;
  },
  type: 'PAYMENT',
};
