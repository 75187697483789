import { gql } from '@apollo/client';
import { FRAGMENT_RETURN_FIND_ORDER } from '../query';

export const GET_ORDER_DATA = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_RETURN_FIND_ORDER
    }
  }
  ${FRAGMENT_RETURN_FIND_ORDER}
`;
