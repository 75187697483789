import React from 'react';
import Mustache from 'mustache';
import { Skeleton } from '@material-ui/lab';
import { Typography, Box } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { useMeasure } from 'react-use';
import { convertMetaObject, NumberFormat, PriceFormat } from '../../shared';
import moment from 'moment';
import ReceiptTemplatePreviewer from '../../components/ReceiptTemplatePreviewer';

const PreviewCard = ({ extraRenderer = {}, extraData = {}, values, loading }) => {
  const [containerRef, { width: containerWidth }] = useMeasure();

  const shop = values?.shop ?? {};
  const variables = {
    ...values?.configs,
    id: '65ea265c-39f6-400c-9c74-e6e1cb19c090',
    locale_zhHK: /zh[-_]?HK/.test(shop?.locale),
    locale_zhCN: /zh[-_]?CN/.test(shop?.locale),
    locale_en: /en/.test(shop?.locale),
    currency: 'HKD',
    billingAddress: {
      person: 'Kathy Wong',
      tel: '+85290000000',
      lines: ['Flat 1, 1/F, Block A', 'Happy Garden', 'Tsuen Wan'],
      country: 'Hong Kong',
    },
    shippingAddress: {
      person: 'Kathy Wong',
      tel: '+85290000000',
      lines: ['Flat 1, 1/F, Block A', 'Happy Garden', 'Tsuen Wan'],
      country: 'Hong Kong',
    },
    shippingProvider: {
      name: 'SF Express',
    },
    date: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: new Date().getDate(),
      day: new Date().getDate(),
      hour: new Date().getHours(),
      minute: new Date().getMinutes(),
      second: new Date().getSeconds(),
    },
    shop: {
      ...shop,
      logo: shop?.logoMedia?.src,
      domain: `https://${shop?.customDomain || shop?.hostname}`,
      name: shop?.name,
      metadata: convertMetaObject(shop?.metadata),
    },
    customer: {
      name: 'Kathy Wong',
      points: 100,
      phoneNumber: '+85290000000',
      email: 'kath.wong@test.com',
    },
    gainMemberPoints: 2,
    pendingMemberPoints: 10,
    resultMemberPoints: 100 + 2 - 10,
    referenceNo: '012',
    referenceNoLong: 'PO00000012',
    count: 3,
    remark: '- remark.....',
    operator: 'Staff Larry',
    salesman: 'Peter Chan',
    createdAt: new Date().toISOString(),
    table: {
      name: 'Table 1A',
      customers: 4,
      previous: 'Table 2C',
    },
    invoices: [
      {
        index: 0,
        status: 'COMPLETED',
        createdAt: new Date().toISOString(),
        paymentMethod: {
          name: 'Visa',
        },
        total: 5,
        totalPaid: 5,
        metadata: {
          ref: '00000242800023',
        },
      },
      {
        index: 1,
        status: 'COMPLETED',
        createdAt: new Date().toISOString(),
        paymentMethod: {
          name: 'Cash',
        },
        total: 100,
        totalPaid: 100,
        metadata: {
          ref: '00000242800024',
        },
      },
    ],
    items: [
      {
        index: 0,
        description: 'Margherita Pizza 9 inch',
        sku: 'M-P-90000',
        quantity: 1,
        unitPrice: 50,
        remark: '',
        total: 50,
      },
      {
        index: 1,
        description: 'Daily coffee: Mocha',
        sku: 'D-C-M-0910',
        quantity: 2,
        unitPrice: 25,
        remark: 'less sugar, hot',
        total: 50,
      },
    ],
    subtotal: 100,
    totalAdjustments: [
      { description: 'Discount 1', amount: -5 },
      { description: 'Service Charge', amount: 10 },
    ],
    deviceId: 'A0123456789',
    total: 105,
    totalPaid: 105,
    laterPaid: 0,
    ...extraData,
  };

  const paperWidth =
    {
      '58mm': 300,
      '80mm': 350,
      a5: 500,
      a4: 700,
    }[variables?.paper] || 350;

  if (loading) return <Skeleton variant={'rect'} height={400} width={'100%'} />;

  return (
    <>
      <Typography variant={'h6'}>{translate.preview}</Typography>
      <div
        ref={containerRef}
        style={{ borderRadius: 10, overflow: 'hidden', border: '1px solid #ddd', backgroundColor: '#e5e6e7' }}
      >
        <div
          style={{
            transformOrigin: 'top left',
            transform: `scale(${Math.min(containerWidth / paperWidth, 1)})`,
          }}
        >
          {variables?.bodyItems?.length ? (
            <ReceiptTemplatePreviewer variables={variables} extraRenderer={extraRenderer} />
          ) : (
            <Box py={5} bgcolor={'white'}>
              <Typography variant={'h6'} align={'center'}>
                {translate.template_empty_remark || '點擊左方新增內容'}
              </Typography>
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default PreviewCard;
