import React from 'react';
import { GET_CHANNEL_MESSAGES } from '../../query';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import Media from '../../../Media';
import CursorBaseList from '../../../CursorBaseList';
import { Icon } from '../../../IconRender';

export default ({ channelId, shopId }) => {
  return (
    <Box p={2}>
      <Grid container spacing={1}>
        <CursorBaseList
          query={GET_CHANNEL_MESSAGES}
          variables={{
            id: channelId,
            playerId: shopId ?? localStorage.getItem('shopId'),
            filter: { type: { operator: 'IN', value: ['IMAGE', 'VIDEO', 'FILE'] } },
            limits: 20,
          }}
          queryProps={{
            skip: !channelId,
          }}
          getConnection={(data) => data?.node?.messages}
          renderItems={(messages) => (
            <>
              <Grid item xs={12}>
                <Typography component={'div'} variant={'caption'}>
                  {moment(messages[0]?.createdAt).format('DD/MM YYYY')}
                </Typography>
              </Grid>
              {messages.map(({ id, type, channelPlayerId, content, createdAt }, i) => {
                return (
                  <>
                    {i > 0 &&
                    moment(messages[i - 1].createdAt).format('yyyyMM') - 0 > moment(createdAt).format('yyyyMM') - 0 ? (
                      <Grid item xs={12}>
                        <Typography component={'div'} variant={'caption'}>
                          {moment(createdAt).format('DD/MM YYYY')}
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid item>
                      {(() => {
                        switch (type) {
                          case 'FILE':
                            return (
                              <Tooltip title={content.split('/').pop()}>
                                <Box
                                  width={50}
                                  height={50}
                                  position={'relative'}
                                  bgcolor={'rgb(221, 221, 221)'}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => window.open(content, '_blank')}
                                >
                                  <i
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                    }}
                                  >
                                    <Icon icon="faFileDownload" fontSize={'72'} />
                                  </i>
                                </Box>
                              </Tooltip>
                            );
                          default:
                            return <Media src={content} style={{ width: 50, height: 50 }} />;
                        }
                      })()}
                    </Grid>
                  </>
                );
              })}
            </>
          )}
        />
      </Grid>
    </Box>
  );
};
