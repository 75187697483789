import React from 'react';
import { Grid } from '@material-ui/core';
import SystemButton from '../../../../components/SystemButton';
import { Icon } from '../../../../components/IconRender';
import { ReactComponent as IconMessageS } from '../../../../assets/icon/message-s.svg';
import { translate } from '../../../../shared/translate';
import { ReactComponent as IconUnread } from '../../../../assets/icon/unread.svg';
import { ReactComponent as IconMyMessage } from '../../../../assets/icon/my-message.svg';

const TypesButtons = ({ type, onChange = (_) => _ }) => (
  <Grid container>
    {[
      {
        value: 'all',
        icon: <Icon type={'svg'} icon={IconMessageS} viewBox={'0 0 18 20'} />,
        label: translate.all_message,
      },
      {
        value: 'unread',
        icon: <Icon type={'svg'} icon={IconUnread} viewBox={'0 0 18 20'} />,
        label: translate.unread,
      },
      {
        value: 'me',
        icon: <Icon type={'svg'} icon={IconMyMessage} viewBox={'0 0 18 20'} />,
        label: translate.my_message,
      },
    ].map(({ value, icon, label }, i) => (
      <Grid key={i} item style={{ opacity: type === value ? 1 : 0.25 }}>
        <SystemButton
          variant={'text'}
          color={type === value ? 'secondary' : undefined}
          startIcon={icon}
          onClick={() => {
            onChange(value);
          }}
        >
          {label}
        </SystemButton>
      </Grid>
    ))}
  </Grid>
);

export default TypesButtons;
