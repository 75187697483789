import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { convertMetaObject, JSONParseSafely, parseConnection } from 'shared';
import moment from 'moment';
import SignalDisplay from './SignalDisplay';
import { Tooltip } from '@material-ui/core';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/devices/new',
    getRowLink: ({ name, id }) => ({
      pathname: '/devices/' + id,
      state: { title: name },
    }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name.keyword',
        filter: 'name',
        filterType: 'text',
        filterChannel: 'q',
      },
      {
        title: translate.device_id,
        fieldName: 'deviceId',
        sortBy: 'deviceId.keyword',
        filter: 'deviceId',
        filterType: 'text',
        filterChannel: 'q',
      },
      {
        align: 'center',
        title: translate.baseVersion || '核心版本',
        value: (row) => row?.metric?.baseVersion,
      },
      {
        width: 50,
        align: 'center',
        title: translate.codePushVersion || '推送版本',
        value: (row) => row?.metric?.codePushVersion,
      },
      {
        width: 50,
        align: 'center',
        title: translate.device_signal,
        noLink: true,
        render: (row) => {
          const lastTime = row?.metric?.time;
          return (
            <Tooltip
              title={`${translate.last_signal} ${lastTime ? moment(lastTime).format('D MMM YYYY LT') : 'never'}`}
            >
              <span>
                <SignalDisplay active={row?.metric?._signal} />
              </span>
            </Tooltip>
          );
        },
      },
      {
        width: 50,
        align: 'center',
        title: translate.device_network_signal,
        noLink: true,
        render: (row) => {
          const network = row?.metric?.network;
          return (
            <span style={{ opacity: row?.metric?._signal ? 1 : 0.25 }}>
              <SignalDisplay active={+network} />
            </span>
          );
        },
      },
      {
        width: 50,
        align: 'center',
        title: translate.device_printer_signal,
        noLink: true,
        render: (row) => {
          const printers = Object.entries(JSONParseSafely(row?.metric?.printers));

          if (!printers?.length) return null;
          const active = printers.reduce((reducer, [, status]) => {
            if (!reducer) return false;
            return !!+status;
          }, true);
          return (
            <Tooltip title={`${printers.map(([name, status]) => `${name}: ${+status ? '✓' : '❌'}`).join('\n')}`}>
              <span style={{ opacity: row?.metric?._signal ? 1 : 0.25 }}>
                <SignalDisplay active={active} />
              </span>
            </Tooltip>
          );
        },
      },
    ],
    hasQSearch: true,
    qFields: ['name', 'deviceId'],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  getData = (data) => {
    const { nodes, nextCursor, totalCount } = parseConnection(data?.node?.devices);
    return {
      nextCursor,
      totalCount,
      nodes: nodes.map((node) => {
        return {
          ...node,
          metric: (() => {
            const metric = convertMetaObject(parseConnection(node?.metrics).nodes?.[0]?.metadata);
            const lastTime = metric?.time;
            const _signal = !!lastTime && moment().subtract(10, 'minutes').isBefore(lastTime);

            return {
              ...metric,
              _signal,
            };
          })(),
        };
      }),
    };
  };

  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
