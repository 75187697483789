import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, DELETE_QUERY, GET_QUERY, UPDATE_QUERY } from './query';
import { client } from '../../shared/apollo';
import { cloneObject, removeTypename } from '../../shared';
import CustomFormField from '../../components/CustomFormField';
import { translate } from '../../shared/translate';
import ExportButton from './ExportButton';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';
import moment from 'moment';

class ItemForm extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: DELETE_QUERY,
    },
    tabs: [
      {
        name: translate.form_setting,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                name: 'name',
                type: 'text',
                required: true,
              },
              {
                label: translate.valid_start_date_time,
                md: 6,
                type: 'datetime-local',
                name: 'validAt',
                required: true,
              },
              {
                label: translate.valid_end_date_time,
                md: 6,
                type: 'datetime-local',
                name: 'validThru',
                required: true,
              },
              {
                label: translate.activation,
                type: 'switch',
                name: 'active',
              },
              {
                type: 'checkbox',
                label: translate.auto_approve,
                name: 'autoApprove',
              },
              {
                label: translate.customize_table,
                render: ({ disabled, values, setFieldValue, loading }) => {
                  return (
                    <CustomFormField
                      disabled={disabled}
                      loading={loading}
                      value={values.metadataFields}
                      onChange={(v) => {
                        setFieldValue('metadataFields', v);
                      }}
                    />
                  );
                },
              },
              {
                type: 'select',
                label: translate.unique_field,
                name: 'unique',
                render: ({ disabled, values, setFieldValue, loading }) => {
                  return (
                    <SelectField
                      value={values?.unique}
                      disabled={disabled}
                      onChange={(e) => setFieldValue('unique', e.target.value)}
                      options={values?.metadataFields
                        ?.map((field) => ({
                          label: field.name?.trim(),
                          value: field.key?.trim(),
                        }))
                        ?.filter((opt) => !!opt?.label && !!opt?.value)}
                      loading={loading}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    if (moment(values.validAt).isAfter(values.validThru)) {
      throw new Error(translate.invalid_start_date_time);
    }
    const { id } = this.state;
    if (id) {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input: {
            name: values.name,
            active: values.active,
            validAt: values.validAt,
            validThru: values.validThru,
            autoApprove: values.autoApprove,
            metadataFields: values.metadataFields?.map((field) => ({ ...field, unique: field?.key === values.unique })),
          },
        },
      });
    } else
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            shopId: localStorage.getItem('shopId'),
            active: values.active,
            validAt: values.validAt,
            validThru: values.validThru,
            name: values.name,
            autoApprove: values.autoApprove,
            metadataFields: values.metadataFields?.map((field) => ({ ...field, unique: field?.key === values.unique })),
          },
        },
      });
    return true;
  };

  getInitialData({ node } = {}) {
    const { id, name, metadataFields, autoApprove, active = true, validAt, validThru } = node || {};

    return {
      id,
      name,
      autoApprove,
      active,
      validAt,
      validThru,
      metadataFields: removeTypename(cloneObject(metadataFields || [])),
      unique: metadataFields?.find((field) => field.unique)?.key || '',
    };
  }

  renderExtraButtons({ values } = {}) {
    return <>{!!values.id && <ExportButton shopForm={values} />}</>;
  }
}

export default ItemForm;
