import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { GET_QUERY, POST_QUERY, PROFILE_UPDATE } from '../item_Account/query';
import { client } from '../../shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
    },
    tabs: [
      {
        name: translate.account_and_password,
        cards: [
          {
            fields: [
              {
                id: '#' + translate.login_email,
                label: translate.login_email,
                type: 'email',
                name: 'email',
                disabled: true,
              },
              {
                id: '#' + translate.password,
                label: translate.password,
                type: 'password_update',
                name: 'password',
                inputProps: {
                  pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                },
                text: translate.password_remark,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    if (values.password) {
      await client.mutate({
        mutation: PROFILE_UPDATE,
        variables: {
          input: {},
          credential: {
            email: values.email,
            password: values.password,
          },
        },
      });
    }
    return true;
  };

  getInitialData({ me }) {
    const { email } = me || {};

    return {
      email,
    };
  }
}
