import { gql } from '@apollo/client';

export const GET_QUANTITY = gql`
  query($id: ID!, $warehouseId: ID!) {
    node(id: $id) {
      id
      ... on ProductVariation {
        quantity(warehouseId: $warehouseId)
      }
    }
  }
`;
