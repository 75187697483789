import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { translate } from '../../shared/translate';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';

const AllowGuestCheckoutField = ({ value, disabled, onChange }) => {
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={true} md={9}>
        <Grid conatiner>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{translate.enable_guest_checkout}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              {translate.enable_guest_purchase_statement ||
                '您可以在設定中啟用「訪客購買」功能，這將允許未註冊用戶在您的網站上購買商品。啟用此功能可以擴大您的客戶基礎，增加銷售機會。'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SwitchField
          checked={value}
          disabled={disabled}
          onChange={() => {
            onChange(!value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AllowGuestCheckoutField;
