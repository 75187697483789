import React from 'react';
import { Typography } from '@material-ui/core';
import VariationCombinationDisplay from '../../components/VariationCombinationDisplay';

const CommonItemRow = ({ item }) => {
  const { productVariation, description, remark, addOnProduct } = item || {},
    printDescription = productVariation?.product?.printDescription;
  const { sku, combination } = productVariation || {},
    { sku: addOnSku } = addOnProduct || {};

  console.log(item);

  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>{`${description}`}</Typography>
      <Typography style={{ fontSize: '1rem' }}>{`SKU： ${sku || addOnSku}`}</Typography>
      <VariationCombinationDisplay
        combination={combination}
        remark={remark}
        direction={'column'}
        parseColorCode={false}
      />
      {!!printDescription && (
        <Typography style={{ whiteSpace: 'pre-line', fontSize: '1rem' }}>{printDescription}</Typography>
      )}
    </>
  );
};

export default CommonItemRow;
