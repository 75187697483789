import React from 'react';
import { Skeleton } from '@material-ui/lab';
import SelectComboCollection from '../../components/SelectCombo/SelectComboCollection';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';

export default ({ loading, isSubmitting, values: { collections } = {}, setFieldValue }) => {
  if (loading) return <Skeleton height={30} />;

  return (
    <SelectComboCollection
      multiple={true}
      value={collections || []}
      disabled={isSubmitting}
      onChange={(collections) => {
        if (collections.length <= 6) setFieldValue('collections', collections);
        else toast.error(errorParser(new Error('Can only allow 6 collections')));
      }}
      // onChange={(collections) => {
      //   if (collections.length <= 3) setFieldValue('collections', collections);
      //   else toast.error(errorParser(new Error('Can only allow 3 collections')));
      // }}
    />
  );
};
