import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@material-ui/core';
import * as MaterialIcons from '@material-ui/icons';
import PropTypes from 'prop-types';
import * as ProBrand from '@fortawesome/free-brands-svg-icons';
import * as ProLight from '@fortawesome/pro-light-svg-icons';
import * as ProSolid from '@fortawesome/pro-solid-svg-icons';
import * as ProRegular from '@fortawesome/pro-regular-svg-icons';

const Type = {
  'pro-brand': { faInstagram: ProBrand.faInstagram, faAmilia: ProBrand.faAmilia },
  'pro-regular': ProRegular,
  'pro-light': { faTrash: ProLight.faTrash, faExclamationCircle: ProLight.faExclamationCircle },
  'pro-solid': { faTimesCircle: ProSolid.faTimesCircle, faCircle: ProSolid.faCircle },
};

export const FontAwesomeIconWrapper = ({ icon, fixedWidth = true, type = 'pro-regular', ...props }) => {
  if (typeof icon === 'object') {
    return <FontAwesomeIcon icon={icon} fixedWidth={fixedWidth} {...props} />;
  }
  return <FontAwesomeIcon icon={Type[type][icon]} fixedWidth={fixedWidth} {...props} />;
};
export const MaterialIconWrapper = ({ icon, ...props }) => {
  const MaterialIcon = MaterialIcons[icon];
  if (!!MaterialIcon) return <MaterialIcon {...props} />;
  return null;
};

export const Icon = ({ icon, type = 'pro-regular', fontSize = 'small', viewBox, ...props }) => {
  if (type === 'material') return <MaterialIconWrapper icon={icon} fontSize={fontSize} {...props} />;
  else if (type === 'svg') {
    return <SvgIcon fontSize={fontSize} viewBox={viewBox} {...props} component={icon} />;
  }
  return (
    <SvgIcon fontSize={fontSize} {...props}>
      <FontAwesomeIconWrapper icon={icon} type={type} />
    </SvgIcon>
  );
};
Icon.propTypes = {
  type: PropTypes.oneOf(['material', 'svg', 'pro-regular', 'pro-light', 'pro-solid']),
  fontSize: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
};
