import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboServiceBundle from '../../SelectCombo/SelectComboServiceBundle';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, name] = str.match(/^(.*?)∆(.*)$/);
      return { id, name };
    });
  const [serviceBundles, setServiceBundles] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboServiceBundle
        multiple
        value={serviceBundles}
        onChange={(serviceBundles) => {
          setServiceBundles(serviceBundles);
          onFilterChange({
            value: (serviceBundles || []).map((serviceBundle) => `${serviceBundle.id}∆${serviceBundle.name}`),
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
