import React, { useState, useEffect, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { translate } from '../../shared/translate';

const DirtyConflictChecker = ({ initialData, dirty, conflictReloaded, onConflictReload = (_) => _ }) => {
  const [open, setOpen] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(undefined);

  useEffect(() => {
    if (!conflictReloaded) {
      const _updateAt = initialData?.updatedAt;
      if (!!_updateAt && !!updatedAt && _updateAt !== updatedAt) {
        if (dirty) setOpen(true);
        else onConflictReload();
      }
      setUpdatedAt(_updateAt);
    }
  }, [initialData]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{translate.content_updated}</DialogTitle>
      <DialogContent>
        <DialogContentText>{translate.content_updated_msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{translate.no}</Button>
        <Button
          onClick={() => {
            setOpen(false);
            onConflictReload();
          }}
          color="primary"
          autoFocus
        >
          {translate.yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DirtyConflictChecker;

export const DirtyListener = ({ dirty, onDirty = (_) => _ }) => {
  const handler = useRef({ dirty: false });
  useEffect(() => {
    if (dirty && !handler.current.dirty) {
      handler.current.dirty = true;
      onDirty();
    }
  }, [dirty]);
  return null;
};
