import React, { useEffect, useState } from 'react';
import { GET_QUERY } from '../Homepage/query';
import { useQuery } from '@apollo/client';
import { Dialog, IconButton, useTheme, Grid } from '@material-ui/core';
import { Icon } from '../../components/IconRender';
import { translate } from '../../shared/translate';
import SystemButton from '../../components/SystemButton';

const PreviewButton = ({ previewData }) => {
  const theme = useTheme();

  const shopId = localStorage.getItem('shopId');
  const { data } = useQuery(GET_QUERY, {
    variables: { shopId },
    skip: !shopId,
  });
  const { shop } = data || {},
    domain = shop?.customDomain || shop?.hostname;
  const locale = shop?.locale;

  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState('desktop');
  const viewWidth = {
    desktop: 'calc(100vw - 200px)',
    tablet: '1024px',
    mobile: '420px',
  }[focus];

  return (
    <>
      <SystemButton
        fullWidth
        style={{ marginTop: '20px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {translate.preview}
      </SystemButton>
      <Dialog
        PaperProps={{
          style: { borderRadius: 15, overflowY: 'hidden' },
        }}
        maxWidth={'xl'}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Grid
          container
          style={{
            width: viewWidth,
          }}
        >
          <Grid item>
            <IconButton
              onClick={() => {
                setFocus('desktop');
              }}
            >
              <Icon icon={'faDesktop'} style={{ color: focus === 'desktop' ? theme.palette.primary.main : '' }} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setFocus('tablet');
              }}
            >
              <Icon icon={'faTabletAlt'} style={{ color: focus === 'tablet' ? theme.palette.primary.main : '' }} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setFocus('mobile');
              }}
            >
              <Icon icon={'faMobileAlt'} style={{ color: focus === 'mobile' ? theme.palette.primary.main : '' }} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {!!domain && open && (
              <PreviewIFrame
                key={viewWidth}
                viewWidth={viewWidth}
                previewData={previewData}
                src={`${/^http/.test(domain) ? domain : `https://${domain}`}/c/preview?lang=${locale}`}
              />
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default PreviewButton;

const PreviewIFrame = ({ src, previewData, viewWidth }) => {
  useEffect(() => {
    if (previewData) {
      const iFrame = document.getElementById('frame-preview');
      iFrame.addEventListener('load', (e) => {
        iFrame.contentWindow.postMessage(previewData, '*');
      });
    }
  }, []);

  return (
    <iframe
      id="frame-preview"
      style={{ width: viewWidth, height: `calc(100vh - 200px)`, border: 'none', pointerEvent: 'none' }}
      src={src}
    />
  );
};
