import { translate } from '../../../../shared/translate';
import BlobField from '../BlobField';
import { Box, Grid, Typography } from '@material-ui/core';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';
import React from 'react';
import MiniColorField from '../../../../components/FormPageMaker/Fields/MiniColorField';

const rightFieldStyle = { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 };

export default [
  {
    label: translate.title,
    value: 'isTitle',
    render: ({ suggestions, disabled, value, onChange = (_) => _ }) => (
      <Grid container alignItems={'center'}>
        <Grid item xs>
          <BlobField
            suggestions={suggestions}
            disabled={disabled}
            value={value?.text}
            style={{
              ...rightFieldStyle,
            }}
            onChange={(text) => {
              onChange({
                ...value,
                text,
              });
            }}
          />
        </Grid>
        <Grid item>
          <MiniColorField
            icon={'faAmilia'}
            iconType={'pro-brand'}
            disabled={disabled}
            value={value?.color}
            onChange={(v) =>
              onChange({
                ...value,
                color: v,
              })
            }
          />
        </Grid>
        <Grid item>
          <MiniColorField
            icon={'faPalette'}
            disabled={disabled}
            value={value?.backgroundColor}
            onChange={(v) =>
              onChange({
                ...value,
                backgroundColor: v,
              })
            }
          />
        </Grid>
      </Grid>
    ),
  },
  {
    label: translate.text,
    value: 'isText',
    render: ({ onChange, value, suggestions, disabled }) => (
      <Grid container alignItems={'center'}>
        <Grid item xs>
          <BlobField
            suggestions={suggestions}
            value={value?.text}
            style={{
              ...rightFieldStyle,
            }}
            onChange={(text) =>
              onChange({
                ...value,
                text,
              })
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <MiniColorField
            icon={'faAmilia'}
            iconType={'pro-brand'}
            disabled={disabled}
            value={value?.color}
            onChange={(v) =>
              onChange({
                ...value,
                color: v,
              })
            }
          />
        </Grid>
        <Grid item>
          <MiniColorField
            icon={'faPalette'}
            disabled={disabled}
            value={value?.backgroundColor}
            onChange={(v) =>
              onChange({
                ...value,
                backgroundColor: v,
              })
            }
          />
        </Grid>
      </Grid>
    ),
  },
  {
    label: translate.image,
    value: 'isImage',
    render: ({ suggestions, disabled, value, onChange }) => (
      <BlobField
        disabled={disabled}
        suggestions={suggestions}
        value={value?.src}
        style={{
          ...rightFieldStyle,
        }}
        onChange={(src) => {
          onChange({
            ...value,
            src,
          });
        }}
      />
    ),
  },
  {
    label: translate.button || '按鈕',
    value: 'isButton',
    render: ({ suggestions, disabled, value, onChange }) => (
      <>
        <Grid container alignItems={'center'}>
          <Grid item xs>
            <BlobField
              placeholder={translate.please_enter_display_text || '請輸入顯示文字'}
              suggestions={suggestions}
              disabled={disabled}
              value={value?.text}
              onChange={(text) => {
                onChange({
                  ...value,
                  text,
                });
              }}
            />
          </Grid>
          <Grid item>
            <MiniColorField
              icon={'faAmilia'}
              iconType={'pro-brand'}
              disabled={disabled}
              value={value?.color}
              onChange={(v) =>
                onChange({
                  ...value,
                  color: v,
                })
              }
            />
          </Grid>
          <Grid item>
            <MiniColorField
              icon={'faPalette'}
              disabled={disabled}
              value={value?.backgroundColor}
              onChange={(v) =>
                onChange({
                  ...value,
                  backgroundColor: v,
                })
              }
            />
          </Grid>
        </Grid>
        <BlobField
          placeholder={translate.please_enter_link || '請輸入連結'}
          suggestions={suggestions}
          disabled={disabled}
          value={value?.href}
          onChange={(href) => {
            onChange({
              ...value,
              href,
            });
          }}
        />
      </>
    ),
  },
  {
    label: translate.href,
    value: 'isLink',
    render: ({ suggestions, disabled, value, onChange }) => (
      <BlobField
        suggestions={suggestions}
        disabled={disabled}
        value={value?.href}
        style={{
          ...rightFieldStyle,
        }}
        onChange={(href) => {
          onChange({
            ...value,
            href,
          });
        }}
      />
    ),
  },
  {
    label: translate.divider || '分隔',
    value: 'isDivider',
  },
  {
    label: 'QR Code',
    value: 'isQRCode',
    render: ({ suggestions, disabled, value, onChange }) => (
      <BlobField
        suggestions={suggestions}
        disabled={disabled}
        value={value?.data}
        style={{
          ...rightFieldStyle,
        }}
        onChange={(data) => {
          onChange({
            ...value,
            data,
          });
        }}
      />
    ),
  },
  {
    label: translate.product,
    value: 'isItems',
    render: ({ value, onChange, disabled }) => {
      return (
        <Box px={1}>
          <Grid container alignItems={'center'}>
            <Grid item xs>
              <Typography>{translate.hide_price || '隱藏價錢'}</Typography>
            </Grid>
            <Grid item>
              <SwitchField
                disabled={disabled}
                checked={!value?.showPrice}
                onChange={() => {
                  onChange({
                    ...value,
                    showPrice: !value?.showPrice,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      );
    },
  },
  {
    label: translate.order_data,
    value: 'isOrderData',
  },
];
