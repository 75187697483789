import { gql } from '@apollo/client';

export const FRAGMENT_STOCK_ADJUSTMENT_ITEM = gql`
  fragment FRAGMENT_STOCK_ADJUSTMENT_ITEM on StockAdjustmentItem {
    id
    updatedAt
    createdAt
    sku
    productVariation {
      id
      sku
      combination {
        option
        name
      }
      product {
        id
        name
        sku
      }
    }
    quantity
    cost
  }
`;
export const FRAGMENT_STOCK_ADJUSTMENT = gql`
  fragment FRAGMENT_STOCK_ADJUSTMENT on CompanyStockAdjustment {
    id
    createdAt
    updatedAt
    active
    metadata {
      key
      value
    }
    completedAt
    device {
      id
      name
    }
    staff {
      id
      name
    }
    warehouse {
      id
      name
      type
    }
    referenceNo
    remark
    status
    items @client
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_STOCK_ADJUSTMENT
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT}
`;

export const CREATE_QUERY = gql`
  mutation ($input: StockAdjustmentCreateInput!) {
    result: stockAdjustmentCreate(input: $input) {
      ...FRAGMENT_STOCK_ADJUSTMENT
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT}
`;
export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: StockAdjustmentUpdateInput!) {
    result: stockAdjustmentUpdate(id: $id, input: $input) {
      ...FRAGMENT_STOCK_ADJUSTMENT
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT}
`;

export const COMPLETE_QUERY = gql`
  mutation ($id: ID!) {
    stockAdjustmentComplete(id: $id) {
      ...FRAGMENT_STOCK_ADJUSTMENT
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT}
`;

export const CANCEL_QUERY = gql`
  mutation ($id: ID!) {
    stockAdjustmentCancel(id: $id) {
      ...FRAGMENT_STOCK_ADJUSTMENT
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT}
`;

export const VOID_QUERY = gql`
  mutation ($id: ID!) {
    stockAdjustmentVoid(id: $id) {
      ...FRAGMENT_STOCK_ADJUSTMENT
    }
  }
  ${FRAGMENT_STOCK_ADJUSTMENT}
`;

export const IMPORT_QUERY = gql`
  mutation ($id: ID!, $shopId: ID!, $url: AWSURL!) {
    stockAdjustmentImport(id: $id, shopId: $shopId, url: $url) {
      id
      status
    }
  }
`;
