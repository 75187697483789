import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { TableRow } from '@material-ui/core';
import StyledTableCell from './StyledTableCell';

const RowSkeleton = (props) => (
  <TableRow>
    <StyledTableCell>
      <Skeleton />
    </StyledTableCell>
    <StyledTableCell>
      <Skeleton variant={'circle'} width={60} height={60} />
    </StyledTableCell>
    <StyledTableCell>
      <Skeleton />
    </StyledTableCell>
    <StyledTableCell>
      <Skeleton />
    </StyledTableCell>
    <StyledTableCell>
      <Skeleton />
    </StyledTableCell>
    <StyledTableCell>
      <Skeleton />
    </StyledTableCell>
  </TableRow>
);

export default RowSkeleton;
