import React from 'react';
import ConnectionPageMarker from 'components/ConnectionPageMaker';
import { JSONParseSafely, parseConnection } from 'shared';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { Avatar, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import { Icon } from '../../../components/IconRender';
import SystemChannelReadTrigger from './SystemChannelReadTrigger';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export default class SystemMessageListView extends ConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    selectionMode: true,
    fields: [
      {
        title: translate.date,
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
      },
      {
        title: translate.system_message,
        render: function RenderFC({ channel, content, type, read, process }) {
          const { status, output } = process || {},
            { href, progress, message, errors } = JSONParseSafely(output, {});
          const display =
            message ||
            {
              IMAGE: `[${translate.image}]`,
              VIDEO: `[${translate.video}]`,
              FILE: `[${translate.file}]`,
            }[type] ||
            content ||
            '';
          const [open, setOpen] = useState(false);

          const messageContent = (
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography
                  style={{
                    width: '100%',
                    fontWeight: read ? undefined : 'bold',
                  }}
                >
                  <SystemChannelReadTrigger read={read} id={(channel || {}).id} />
                  {href ? (
                    <a target={'blank'} href={href}>
                      {display}
                    </a>
                  ) : (
                    display
                  )}
                </Typography>
              </Grid>
              {!!errors && errors.length > 0 && (
                <Grid item>
                  <Link
                    href="#"
                    color="error"
                    onClick={() => {
                      setOpen(!open);
                    }}
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item>{!open ? '顯示錯誤信息' : '隱藏錯誤信息'}</Grid>
                      <Grid item>{open ? <ExpandLess color="error" /> : <ExpandMore color="error" />}</Grid>
                    </Grid>
                  </Link>
                </Grid>
              )}
            </Grid>
          );

          if (!!process)
            return (
              <Grid container spacing={2}>
                <Grid item>
                  {
                    {
                      PENDING: (
                        <Avatar style={{ backgroundColor: 'transparent' }}>
                          <CircularProgress />
                        </Avatar>
                      ),
                      RUNNING: (
                        <Avatar style={{ backgroundColor: 'transparent' }}>
                          {progress ? (
                            <CircularProgress variant="determinate" value={+progress * 100} />
                          ) : (
                            <CircularProgress />
                          )}
                        </Avatar>
                      ),
                      SUCCESS: (
                        <Avatar style={{ backgroundColor: 'transparent' }}>
                          <Icon icon={'faCheck'} fontSize={'large'} color={'primary'} />
                        </Avatar>
                      ),
                      FAILURE: (
                        <Avatar style={{ backgroundColor: 'transparent' }}>
                          <Icon icon={'faTimes'} fontSize={'large'} color={'error'} />
                        </Avatar>
                      ),
                    }[status]
                  }
                </Grid>
                <Grid item xs={true} style={{ alignSelf: 'center' }}>
                  <Grid container>
                    <Grid item xs={12}>
                      {messageContent}
                    </Grid>
                    {!!open && (
                      <Grid item xs={12}>
                        <Grid container direction={'column'}>
                          {errors.map((error) => (
                            <Grid item>
                              <Typography>{error}</Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );

          return messageContent;
        },
      },
    ],
  };

  getExtraFetchVariables() {
    const shopId = localStorage.getItem('shopId');
    return {
      id: shopId,
      playerId: shopId,
    };
  }
  getData({ node, me } = {}) {
    const channel = parseConnection((node || {}).channels).nodes[0] || {};
    const { nodes, totalCount, nextCursor } = parseConnection(channel.messages);

    return {
      nodes: nodes.map((node) => ({
        ...node,
        me,
      })),
      totalCount,
      nextCursor,
    };
  }
  getSortBy() {
    const { sortBy } = this.state;
    return sortBy
      ? [sortBy]
      : [
          {
            field: 'createdAt',
            order: 'DESC',
          },
        ];
  }

  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
