import React, { useState } from 'react';
import { translate } from '../../../shared/translate';
import ImportContainer from './ImportContainer';
import TabsCardDialog from '../../../components/TabsCardDialog';
import CreateButton from '../../../components/ConnectionPageMaker/CreateButton';
import toast from '../../../shared/toast';

const ImportModal = ({ onClick = (_) => _, list, dataLength, loading }) => {
  const [visible, setVisible] = useState(false);
  const tabs = [{ name: translate.add_sitemap || '上傳網站地圖' }];

  return (
    <>
      <CreateButton
        disabled={loading}
        onClick={() => {
          if (dataLength === 2) {
            toast.error(translate.only_keep_two_sitemaps_pls_delete_one || '系統只能保留 2 個網站地圖，請先刪除 1 個');
          } else {
            onClick();
            setVisible(!visible);
          }
        }}
        onOpen={() => {
          if (dataLength !== 2) setVisible(!visible);
        }}
      >
        {translate.add_sitemap}
      </CreateButton>

      <TabsCardDialog
        visible={visible}
        onOpen={() => setVisible(!visible)}
        tabs={tabs.map((tab, i) => ({
          label: tab.name,
          content: <ImportContainer tab={i} name={tabs[0].name} onClick={() => setVisible(false)} list={list} />,
        }))}
        PaperProps={{ padding: '90px 0' }}
      />
    </>
  );
};

export default ImportModal;
