import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboOrder from '../../SelectCombo/SelectComboOrder';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, referenceNo] = str.match(/^(.*?)∆(.*)$/);
      return { id, referenceNo };
    });
  const [orders, setOrders] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboOrder
        multiple
        value={orders}
        onChange={(orders) => {
          setOrders(orders);

          const values = (orders || []).map((order) => {
            const { id, referenceNo } = order || {};
            return `${id}∆${referenceNo}`;
          });

          onFilterChange({
            value: values,
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
