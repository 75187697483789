import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../../item_EmailTemplate/SplitCard';
import { defaultSuggestions } from '../defaultSuggestion';
import LayoutFront from '../LayoutFront';

export default class LayoutReturn extends LayoutFront {
  type = 'RETURN';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[
                { label: '#index', value: '{{index}}' },
                { label: '#count', value: '{{count}}' },
                { label: '#subtitle', value: '{{subtitle}}' },
                { label: '#description', value: '{{description}}' },
                { label: '#returnReferenceNo', value: '{{returnReferenceNo}}' },
                ...defaultSuggestions,
              ]}
              allows={['isTitle', 'isText', 'isLV', 'isDivider', 'isLogo', 'isQRCode', 'isCut']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              standardWidth={300}
              extraData={{
                returnReferenceNo: 'R00000000001',
                remark: '約 09/12/2023 前換 HONOR 90 / HONOR 90 Lite (Midnight Black / 8GB+256GB) 給客人',
                items: [
                  {
                    index: 0,
                    description: 'HONOR 90 / HONOR 90 Lite (Midnight Black / 8GB+256GB)',
                    sku: 'M-P-90000',
                    quantity: 1,
                    unitPrice: 50,
                    remark: '',
                    total: 50,
                    reason: '買錯色',
                  },
                  {
                    index: 1,
                    description: 'Honor 智能體脂磅3',
                    sku: 'SKU00000088',
                    quantity: 1,
                    unitPrice: 249,
                    remark: '',
                    total: 249,
                    reason: '無法感應重量',
                  },
                ],
              }}
              extraRenderer={{}}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
