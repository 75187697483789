import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDebounce } from 'react-use';
import SystemButton from '../../../../components/SystemButton';
import s3Upload from '../../../../shared/s3Upload';
import { translate } from '../../../../shared/translate';
import DraggableTableItem2 from './DraggableTableItem2';

const DraggableTable = ({ value, onChange, onChangeImage, media, onChangeHeight }) => {
  const [clientContainer, setClientContainer] = useState();
  const ref = useRef();
  const draggableTableRef = useRef();
  const [height, setHeight] = useState(0);
  const isReady = !!(clientContainer || {}).clientWidth && !!(clientContainer || {}).clientHeight;
  const allCoordinate = value.map((table) => {
    const [x, y] = table.shapeData || [];
    return { x, y };
  });

  useEffect(() => {
    if (!!draggableTableRef.current) setClientContainer(draggableTableRef.current);
  }, [draggableTableRef.current]);

  useEffect(() => {
    if (!!height) onChangeHeight(height);
  }, [height]);

  useDebounce(
    () => {
      if (!!clientContainer.clientWidth) setHeight(draggableTableRef.current.clientWidth / (16 / 9));
    },
    500,
    [(clientContainer || {}).clientWidth],
  );

  return (
    <Box
      ref={draggableTableRef}
      key={height}
      id={'draggableBox'}
      style={{
        height,
        width: '100%',
        backgroundImage: `url(${media})`,
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        backgroundColor: '#ddd',
        backgroundSize: 'cover',
        overflow: 'hidden',
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      }}
    >
      {!!isReady &&
        value.map((item, i) => {
          if (!!+item.metadata?.active)
            return (
              <DraggableTableItem2
                value={item}
                containerBox={clientContainer}
                allCoordinate={allCoordinate.filter((_, j) => i !== j)}
                onChange={(result) => {
                  onChange(
                    value.map((item, j) => {
                      if (i === j) return { ...result, shapeData: result.shapeData };
                      return { ...item };
                    }),
                  );
                }}
              />
            );
          return null;
        })}
      <SystemButton
        startIcon={<Add />}
        onClick={() => ref.current.click()}
        style={{
          color: 'rgba(0,0,0,0.7)',
          position: 'absolute',
          bottom: 16,
          right: 10,
          borderRadius: 9,
        }}
        variant={'outlined'}
      >
        {translate.import_seat_map || '載入座位圖'}
        <input
          onChange={async (e) => {
            e.preventDefault();
            const files = e.target.files;
            const file = files[0];
            const url = await s3Upload(file);
            onChangeImage(url);
          }}
          ref={ref}
          style={{ display: 'none' }}
          type={'file'}
          accept="image/*"
        />
      </SystemButton>
    </Box>
  );
};

export default DraggableTable;
