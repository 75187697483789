import React from 'react';
import { translate } from '../../../shared/translate';
import { FormRow } from '../../../components/FormRow';
import { Grid, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import FixedChargeField from './FixedChargeField';
import CustomChargeField from './CustomChargeField';
import SelectCombo from '../../../components/SelectCombo';

const ShippingFeeTypeOptions = [
  { label: translate.discount_for_free_ship, value: 'FREE' },
  { label: translate.weight_calculation, value: 'CUSTOM' },
  { label: translate.fixed_shipping_fee, value: 'FIXED' },
];

export default ({ loading, values: { mode, expressions = [] }, setFieldValue, isSubmitting }) => {
  return (
    <Box px={2}>
      <Grid container spacing={3}>
        <FormRow required={true} title={translate.shipping_fee_setting}>
          {loading ? (
            <Skeleton height={30} />
          ) : (
            <SelectCombo
              required={true}
              disabled={isSubmitting}
              value={ShippingFeeTypeOptions.find((opt) => opt.value === mode)}
              onChange={(opt) => {
                console.log('opt', opt);
                setFieldValue('mode', opt?.value);
              }}
              options={ShippingFeeTypeOptions}
            />
          )}
        </FormRow>
        {
          {
            FREE: null,
            FIXED: (
              <FormRow required={true} title={translate.fixed_shipping_fee}>
                {loading ? (
                  <Skeleton height={30} />
                ) : (
                  <FixedChargeField
                    value={expressions}
                    onChange={(expressions) => setFieldValue('expressions', expressions)}
                    disabled={isSubmitting}
                  />
                )}
              </FormRow>
            ),
            CUSTOM: (
              <FormRow>
                <CustomChargeField
                  disabled={isSubmitting}
                  value={expressions}
                  onChange={(expressions) => setFieldValue('expressions', expressions)}
                />
                {/*<StyledConfigList>*/}
                {/*  <Grid item container spacing={2}>*/}
                {/*    <Grid item xs={5}>*/}
                {/*      {translate.max_weight} **/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6}>*/}
                {/*      {translate.shipping_fee} **/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1} />*/}
                {/*  </Grid>*/}
                {/*  {expressions.map((expression, i) => {*/}
                {/*    const { maxWeight: previousMaxWeight = 0 } = expressions[i - 1] || {};*/}
                {/*    const previousMaxWeightLimit =*/}
                {/*      expressions.length === 1 || i === 0 ? previousMaxWeight : +previousMaxWeight + 0.001;*/}
                {/*    return (*/}
                {/*      <Grid key={`${i}-${expressions.length}`} item container spacing={2} alignItems={'center'}>*/}
                {/*        <Grid item xs={5}>*/}
                {/*          {i === expressions.length - 1 ? (*/}
                {/*            <span>*/}
                {/*              {previousMaxWeightLimit}kg {translate.above}*/}
                {/*            </span>*/}
                {/*          ) : (*/}
                {/*            <InputField*/}
                {/*              required={true}*/}
                {/*              disabled={isSubmitting}*/}
                {/*              type="number"*/}
                {/*              value={expression.maxWeight}*/}
                {/*              onChange={(e) => {*/}
                {/*                expression.maxWeight = e.target.value;*/}
                {/*                expression.unit = e.target.value - 0;*/}
                {/*                setFieldValue('expressions', expressions);*/}
                {/*              }}*/}
                {/*              // eslint-disable-next-line*/}
                {/*              inputProps={{*/}
                {/*                min: previousMaxWeightLimit,*/}
                {/*                step: 0.001,*/}
                {/*              }}*/}
                {/*              // eslint-disable-next-line*/}
                {/*              InputProps={{*/}
                {/*                startAdornment: (*/}
                {/*                  <InputAdornment position={'start'}>{previousMaxWeightLimit}kg ~</InputAdornment>*/}
                {/*                ),*/}
                {/*                endAdornment: <InputAdornment position={'end'}>kg</InputAdornment>,*/}
                {/*              }}*/}
                {/*            />*/}
                {/*          )}*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={6}>*/}
                {/*          <InputField*/}
                {/*            required={true}*/}
                {/*            type="number"*/}
                {/*            disabled={isSubmitting}*/}
                {/*            value={expression.unitPrice}*/}
                {/*            onChange={(e) => {*/}
                {/*              expression.unitPrice = e.target.value;*/}
                {/*              setFieldValue('expressions', expressions);*/}
                {/*            }}*/}
                {/*            // eslint-disable-next-line*/}
                {/*            InputProps={{*/}
                {/*              startAdornment: (*/}
                {/*                <InputAdornment position={'start'}>{translate.shipping_fee}: HKD</InputAdornment>*/}
                {/*              ),*/}
                {/*            }}*/}
                {/*          />*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={1}>*/}
                {/*          <IconButton*/}
                {/*            color={'secondary'}*/}
                {/*            disabled={isSubmitting || expressions.length - 1 === i}*/}
                {/*            onClick={() => {*/}
                {/*              expressions.splice(i, 1);*/}
                {/*              setFieldValue('expressions', expressions);*/}
                {/*            }}*/}
                {/*          >*/}
                {/*            <Icon icon={'faTimes'} />*/}
                {/*          </IconButton>*/}
                {/*        </Grid>*/}
                {/*      </Grid>*/}
                {/*    );*/}
                {/*  })}*/}
                {/*  <AddButton*/}
                {/*    disabled={isSubmitting}*/}
                {/*    title={translate.new_option}*/}
                {/*    onClick={() => {*/}
                {/*      const secondLast = expressions[expressions.length - 2] || {};*/}
                {/*      expressions.splice(expressions.length - 1, 0, { maxWeight: secondLast.maxWeight || 0 });*/}
                {/*      setFieldValue('expressions', expressions);*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</StyledConfigList>*/}
              </FormRow>
            ),
          }[mode]
        }
      </Grid>
    </Box>
  );
};
