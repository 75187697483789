import React from 'react';
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { translate } from 'shared/translate';
import InputField from 'components/FormPageMaker/Fields/InputField';
import { PriceFormat } from '../../../shared';

export default ({ preModifiers = [], modifiers = [], onChange = (_) => _, disabled }) => {
  return (
    <Box mt={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'caption'}>{translate.variant_content}</Typography>
              </TableCell>
              <TableCell width={150}>
                <Typography variant={'caption'}>{translate.additional_fee}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preModifiers?.map((mod, i) => {
              const { name, options = [] } = mod || {};
              return options.map((option, j) => (
                <TableRow key={`pre-${i}-${j}`}>
                  <TableCell>
                    {name} {option.name}
                  </TableCell>
                  <TableCell>{PriceFormat(option.priceAdjustment)}</TableCell>
                </TableRow>
              ));
            })}
            {modifiers?.map((mod, i) => {
              const { name, options = [] } = mod || {};
              return options.map((option, j) => (
                <TableRow key={`${i}-${j}`}>
                  <TableCell>
                    {name} {option.name}
                  </TableCell>
                  <TableCell>
                    <InputField
                      type="number"
                      // eslint-disable-next-line
                      inputProps={{
                        step: 0.01,
                      }}
                      value={option.priceAdjustment}
                      onChange={(e) => {
                        onChange(
                          modifiers.map((m, k) =>
                            k === i
                              ? {
                                  ...m,
                                  options: m.options.map((o, l) =>
                                    l === j ? { ...o, priceAdjustment: e.target.value } : o,
                                  ),
                                }
                              : m,
                          ),
                        );
                        // option.priceAdjustment = e.target.value;
                        // onChange(modifiers);
                      }}
                      disabled={disabled}
                      // eslint-disable-next-line
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ));
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
