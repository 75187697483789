import React, { useState } from 'react';
import { Icon } from '../../IconRender';
import { ReactComponent as IconEmoji } from '../../../assets/icon/emoji.svg';
import IconButton from '@material-ui/core/IconButton';
import { ClickAwayListener, Popper } from '@material-ui/core';
import EmojiPicker from 'emoji-picker-react';

const EmojiButton = ({ disabled, onEmoji = (_) => _ }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  return (
    <>
      <IconButton disabled={disabled} onClick={(e) => setAnchorEl(e.target)}>
        <Icon type={'svg'} icon={IconEmoji} viewBox={'0 0 24 24'} />
      </IconButton>
      {!!anchorEl && (
        <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
          <Popper id={'popper-emoji'} open={!!anchorEl} anchorEl={anchorEl}>
            <EmojiPicker
              onEmojiClick={(emoji) => {
                setAnchorEl(undefined);
                onEmoji(emoji);
              }}
            />
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default EmojiButton;
