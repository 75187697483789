import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export default (props) => {
  const { name: formName } = useLocation().state || {};

  return (
    <div>
      <Taber currentTab={0} tabs={[{ name: formName }]} />
      <Box>
        <ListView {...props} />
      </Box>
    </div>
  );
};
