import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboCountry from '../../SelectCombo/SelectComboCountry';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = value || [];
  const [countries, setCountries] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboCountry
        multiple
        value={countries}
        onChange={(countries) => {
          setCountries(countries);
          onFilterChange({
            value: countries || [],
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
