import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ATTRIBUTES } from './query';
import { parseConnection } from '../../../shared';
import { Box, Grid, Typography } from '@material-ui/core';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import _ from 'lodash';
import ColorField from '../../../components/FormPageMaker/Fields/ColorField';
import { translate } from '../../../shared/translate';
import { Link } from 'react-router-dom';
import SelectComboValue from '../../../components/SelectCombo/SelectComboValue';
import Dnd from '../../../components/DND';
import { Icon } from '../../../components/IconRender';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import TrashButton from '../../../components/TrashButton';

const AttributesField = ({ disabled, value: attributes = [], onChange = (_) => _ }) => {
  const { loading, data: { node } = {} } = useQuery(GET_ATTRIBUTES, {
      skip: disabled,
      variables: {
        id: localStorage.getItem('shopId'),
      },
    }),
    attributeSettings = parseConnection(node?.attributes).nodes,
    availableSettings = attributeSettings?.filter((setting) => !setting?.system);

  return (
    <Fragment>
      <StyledConfigList spacing={0}>
        {!loading && !availableSettings?.length ? (
          <Link to={`/attributes`}>
            <Typography>{translate.no_attribute_remark || '沒有可用參數，請先建立自定參數'}</Typography>
          </Link>
        ) : (
          <>
            <Grid item xs={12}>
              <Box mb={1}>
                <Dnd
                  direction={'column'}
                  items={attributes}
                  disabled={disabled}
                  onChange={onChange}
                  spacing={2}
                  renderItem={(attr, i, { nonDraggableProps }) => {
                    const setting = attributeSettings.find((setting) => setting?.key === attr?.key);
                    const system = !!setting?.system;
                    return (
                      <Box mx={1.5}>
                        <Grid container spacing={1} alignItems={'center'}>
                          <Grid item>
                            <Icon icon={IconDragVertical} type={'svg'} style={{ cursor: 'pointer' }} />
                          </Grid>
                          <Grid item xs={true} {...nonDraggableProps}>
                            {system ? (
                              <InputField value={setting?.name} disabled={true} />
                            ) : (
                              <SelectComboValue
                                disabled={disabled || system}
                                value={attr?.key}
                                options={availableSettings?.map((setting) => ({
                                  label: setting?.name,
                                  value: setting?.key,
                                }))}
                                onChange={(key) => {
                                  onChange(attributes.map((attr, j) => (i === j ? { ...attr, key, value: '' } : attr)));
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={true} {...nonDraggableProps}>
                            {
                              {
                                TEXT: (
                                  <InputField
                                    disabled={disabled || system}
                                    value={attr?.value}
                                    onChange={(e) => {
                                      onChange(
                                        attributes.map((attr, j) =>
                                          i === j
                                            ? {
                                                ...attr,
                                                value: e?.target?.value?.replace(
                                                  /[~!@#$%^&*()_+`{}|[\]\\:";'<>?,.\/]/g,
                                                  '',
                                                ),
                                              }
                                            : attr,
                                        ),
                                      );
                                    }}
                                  />
                                ),
                                COLOR: (
                                  <ColorField
                                    disabled={disabled || system}
                                    value={attr?.value}
                                    onChange={(v) => {
                                      onChange(
                                        attributes?.map((attr, j) =>
                                          i === j
                                            ? {
                                                ...attr,
                                                value: v,
                                              }
                                            : attr,
                                        ),
                                      );
                                    }}
                                  />
                                ),
                                NUMBER: (
                                  <InputField
                                    type={'number'}
                                    disabled={disabled || system}
                                    value={attr?.value}
                                    onChange={(e) => {
                                      onChange(
                                        attributes.map((attr, j) =>
                                          i === j
                                            ? {
                                                ...attr,
                                                value: e?.target?.value,
                                              }
                                            : attr,
                                        ),
                                      );
                                    }}
                                  />
                                ),
                              }[setting?.type]
                            }
                          </Grid>
                          <Grid item>
                            <TrashButton
                              disabled={disabled || system}
                              onClick={() => {
                                onChange(_.filter(attributes, (attr, j) => i !== j));
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <AddButton disabled={disabled} backgroundColor={'white'} onClick={() => onChange([...attributes, {}])} />
            </Grid>
          </>
        )}
      </StyledConfigList>
    </Fragment>
  );
};

export default AttributesField;
