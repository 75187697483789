import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import DateRangeField from '../../../components/FormPageMaker/Fields/DateRangeField';
import { translate } from '../../../shared/translate';
import ExportButton from '../../item_SalePerformance/ExportButton';
import SnapButton from '../SnapButton';
import useQueryParams from '../../../hooks/useQueryParams';

const ReportHeader = ({ exportMutation, exportVariables, loading, label }) => {
  const [query, setQuery] = useQueryParams(),
    dayMode = query?.dayMode,
    startedAt = query?.startedAt,
    startedThru = query?.startedThru;

  useEffect(() => {
    if (!startedAt && !startedThru && !dayMode) {
      setQuery((query) => ({
        ...query,
        startedAt: moment().subtract(7, 'days').startOf('day').toISOString(),
        startedThru: moment().endOf('day').toISOString(),
        dayMode: '1',
      }));
    }
  }, [startedAt, startedThru, dayMode]);

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
      {!!exportMutation && (
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <ExportButton
              disabled={loading}
              mutation={exportMutation}
              variables={exportVariables}
              startedAt={startedAt}
              startedThru={startedThru}
            />
          </Box>
        </Grid>
      )}
      <Grid item>
        {!!label && (
          <Typography variant={'h6'} color={'secondary'}>
            {label}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <SnapButton
              active={dayMode === '0'}
              onClick={() => {
                setQuery((query) => ({
                  ...query,
                  dayMode: '0',
                  startedAt: moment().startOf('day').toISOString(),
                  startedThru: moment().endOf('day').toISOString(),
                }));
              }}
            >
              {translate.today}
            </SnapButton>
          </Grid>
          <Grid item>
            <SnapButton
              active={dayMode === '1'}
              onClick={() => {
                setQuery((query) => ({
                  ...query,
                  dayMode: '1',
                  startedAt: moment().subtract(7, 'days').startOf('day').toISOString(),
                  startedThru: moment().endOf('day').toISOString(),
                }));
              }}
            >
              {translate.last_7_days}
            </SnapButton>
          </Grid>
          <Grid item>
            <SnapButton
              active={dayMode === '2'}
              onClick={() => {
                setQuery((query) => ({
                  ...query,
                  dayMode: '2',
                  startedAt: moment().startOf('month').startOf('day').toISOString(),
                  startedThru: moment().endOf('day').toISOString(),
                }));
              }}
            >
              {translate.this_month}
            </SnapButton>
          </Grid>
          <Grid item>
            <DateRangeField
              fromValue={startedAt}
              toValue={startedThru}
              onFromValueChange={(startedAt) => {
                setQuery((query) => ({
                  ...query,
                  dayMode: '3',
                  startedAt,
                }));
              }}
              onToValueChange={(startedThru) => {
                setQuery((query) => ({
                  ...query,
                  dayMode: '3',
                  startedThru,
                }));
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportHeader;
