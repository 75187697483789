import React from 'react';
import { JSONParseSafely } from 'shared';
import _ from 'lodash';
import CollectionSortingCard from './CollectionSortingCard';
import moment from 'moment';
import useFullCollections from '../../../components/useFullCollections';

export default () => {
  const { loading, collections: nodes } = useFullCollections();

  const collections = _.sortBy(JSONParseSafely(JSON.stringify(nodes), []), 'sortIndex');
  const collectionsKV = collections.reduce((reducer, collection) => {
    collection.expanded = true;
    reducer[collection.id] = collection;
    return reducer;
  }, {});
  const formattedCollections = collections.filter((collection) => {
    if (!collection.parentId) return true;
    const parent = collectionsKV[collection.parentId] || {};
    collection.parent = parent;
    if (!parent.children) parent.children = [];
    parent.children.push(collection);
    return false;
  });

  return (
    <CollectionSortingCard
      loading={loading}
      collections={formattedCollections}
      updatedAt={collections.reduce((reducer, { updatedAt }) => {
        if (moment(reducer).isBefore(updatedAt)) return updatedAt;
        return reducer;
      }, 0)}
    />
  );
};
