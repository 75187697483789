import React, { useState } from 'react';
import FormRow from '../../FormRow';
import SelectComboShippingZone from '../../SelectCombo/SelectComboShippingZone';

export default ({ title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {},
    values = (value || []).map((str) => {
      const [, id, name] = str.match(/^(.*?)∆(.*)$/);
      return { id, name };
    });
  const [shoppingZones, setShoppingZones] = useState(values);
  return (
    <FormRow title={title}>
      <SelectComboShippingZone
        multiple
        value={shoppingZones}
        onChange={(shoppingZones) => {
          setShoppingZones(shoppingZones);
          onFilterChange({
            value: (shoppingZones || []).map((shoppingZone) => `${shoppingZone.id}∆${shoppingZone.name}`),
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
