import React from 'react';

const Tailwind = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <p className="text-2xl text-center text-red-600">Tailwind Test</p>
    </div>
  );
};

export default Tailwind;
