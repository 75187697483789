import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($shopId: ID!, $id: ID!) {
    shop: node(id: $shopId) {
      id
      ... on CompanyShop {
        logoMedia {
          src
          optimizedSrc(width: 256, height: 256)
        }
      }
    }
    node(id: $id) {
      id
      ... on CompanyReceivePurchase {
        createdAt
        updatedAt
        status
        remark
        warehouse {
          id
          name
        }
        items(limits: 99999) {
          nodes {
            sku
            quantity
            productVariation {
              id
              sku
              combination {
                name
                option
              }
              product {
                id
                name
                medias {
                  #id
                  src
                  optimizedSrc(width: 256, height: 256)
                }
              }
            }
          }
        }
      }
    }
  }
`;
