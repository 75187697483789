import { gql } from '@apollo/client';

export const USER_CHANGE_TIER_QUERY = gql`
  mutation($customerId: ID!, $input: UserMemberTierSetInput!) {
    result: userMemberTierSet(customerId: $customerId, input: $input) {
      id
      name
      level
      startedAt
      startedThru
    }
  }
`;
