import React from 'react';
import Media from '../../../../components/Media';
import RatioBox from '../../../../components/RatioBox';

const RatioRespondingMedia = ({ ratio, style, ...props }) => {
  if (ratio !== undefined)
    return (
      <RatioBox
        ratio={ratio}
        width={'100%'}
        style={{
          ...style,
        }}
      >
        <Media
          disabled={true}
          placeholderColor={'transparent'}
          style={{
            width: '100%',
            height: '100%',
          }}
          {...props}
        />
      </RatioBox>
    );
  return (
    <Media
      disabled={true}
      placeholderColor={'transparent'}
      style={{
        width: '100%',
        height: 0,
        flex: 1,
        ...style,
      }}
      {...props}
    />
  );
};

export default RatioRespondingMedia;
