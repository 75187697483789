import React, { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { client } from '../../../shared/apollo';
import { GET_ORDER_DATA } from './query';
import { CircularProgress, Grid } from '@material-ui/core';
import SelectComboOrder from '../../../components/SelectCombo/SelectComboOrder';
import ActivityIndicator from '../../../components/ActivityIndicator';
import errorParser from '../../../shared/errorParser';
import { translate } from '../../../shared/translate';
import toast from '../../../shared/toast';

const OrderSelectField = ({ values: { id, order, address } = {}, setFieldValue, loading, isSubmitting }) => {
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (!id) {
      if (!!order) {
        (async () => {
          try {
            setFetching(true);
            ActivityIndicator.show(translate.load_order_default_data);
            const { data: { node } = {} } = await client.query({
              fetchPolicy: 'no-cache',
              query: GET_ORDER_DATA,
              variables: { id: order.id },
            });
            const { items, shippingProvider, shippingAddress, customer, checkout } = node || {};
            if (!!shippingProvider) setFieldValue('shippingZone', { ...shippingProvider });

            if (/PICKUP/.test(shippingProvider?.provider)) {
              setFieldValue('address', {
                ...checkout?.pickUpAddress,
                email: (shippingAddress || {}).email || (customer || {}).email,
                tel: (shippingAddress || {}).tel || (customer || {}).phoneNumber,
              });
            } else {
              setFieldValue('address', {
                ...address,
                ...shippingAddress,
                email: (shippingAddress || {}).email || (customer || {}).email,
                tel: (shippingAddress || {}).tel || (customer || {}).phoneNumber,
              });
            }
            if (!id)
              setFieldValue(
                'items',
                (items || []).map((_orderItem) => {
                  const orderItem = _orderItem || {};
                  const shippedQuantity = orderItem.deliverableQuantity || 0;

                  return {
                    orderItem,
                    shippedQuantity: (orderItem.quantity || 0) - shippedQuantity,
                    quantity: shippedQuantity,
                  };
                }),
              );
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setFetching(false);
            ActivityIndicator.hide();
          }
        })();
      } else {
        setFieldValue('shippingZone', undefined);
        setFieldValue('address', {});
        setFieldValue('items', []);
      }
    }
  }, [order]);

  if (loading) return <Skeleton height={30} />;

  return (
    <Grid container spacing={3} alignItems={'center'}>
      <Grid item xs>
        <SelectComboOrder
          filterType={'PROCESSING'}
          required={true}
          disabled={!!id || isSubmitting}
          value={order}
          onChange={(order) => {
            setFieldValue('order', order);
          }}
        />
      </Grid>
      {fetching && (
        <Grid item>
          <CircularProgress size={15} />
        </Grid>
      )}
    </Grid>
  );
};

export default OrderSelectField;
