import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import Taber from '../../components/Taber';
import { JSONParseSafely, removeTypename } from '../../shared';
import { client } from '../../shared/apollo';
import { translate } from '../../shared/translate';
import MemberHashTagsCard from './MemberHashTagsCard';
import { GET_QUERY, UPDATE_COMPANY_QUERY, UPDATE_SHOP_QUERY } from './query';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('companyId'),
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_COMPANY_QUERY,
    },
    tabs: [
      {
        cards: [
          {
            fields: [
              {
                id: '#' + translate.auto_verify_after_registration,
                label: translate.auto_verify_after_registration,
                type: 'checkbox',
                name: 'autoConfirmRegistration',
                dense: true,
                block: true,
              },
              {
                render: (props) => <MemberHashTagsCard {...props} />,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    await client.mutate({
      mutation: UPDATE_COMPANY_QUERY,
      variables: {
        id: localStorage.getItem('companyId'),
        input: {
          defaultMemberHashtagSchedules: values.defaultMemberHashtagSchedules,
        },
      },
    });
    await client.mutate({
      mutation: UPDATE_SHOP_QUERY,
      variables: {
        id: localStorage.getItem('shopId'),
        input: {
          name: values.name,
          hasRegistrationEmail: !values.autoConfirmRegistration,
          /* temp solution to remove hasRegistrationEmail */
          autoConfirmRegistration: values.autoConfirmRegistration,
        },
      },
    });
    return true;
  };

  getExtraFetchVariables() {
    return {
      companyId: localStorage.getItem('companyId'),
      shopId: localStorage.getItem('shopId'),
    };
  }

  getInitialData({ company, shop } = {}) {
    const { defaultMemberHashtagSchedules = [] } = company || {};
    const { autoConfirmRegistration, name } = shop || {};
    return {
      name,
      autoConfirmRegistration,
      defaultMemberHashtagSchedules: !!(defaultMemberHashtagSchedules || []).length
        ? removeTypename(JSONParseSafely(JSON.stringify(defaultMemberHashtagSchedules)))
        : undefined,
    };
  }

  renderContent(result) {
    const { history } = this.props;
    const { loading, data } = this.getFetchResult(result);
    this.initialValues = this.getInitialData(data || {});
    return (
      <>
        <Taber
          currentTab={2}
          tabs={[
            {
              name: translate.membership_level,
              onClick: () => history.push('/member_tiers'),
            },
            {
              name: translate.point_setting,
              onClick: () => history.push('/member_tiers/setting'),
            },
            {
              name: translate.member_default_settings,
            },
          ]}
        />
        {this.renderForm({ ...this.initialValues }, loading)}
      </>
    );
  }
}
