import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';
import useQueryParams from '../../hooks/useQueryParams';

export default (props) => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery((query) => ({
            ...query,
            tab,
            cursor: undefined,
          }));
        }}
        tabs={[
          {
            name: translate.all_delivery_notes,
          },
          {
            name: translate.status_completed,
          },
          {
            name: translate.status_processing,
          },
          {
            name: translate.status_pending,
          },
        ]}
      />
      <Box>
        <ListView
          {...props}
          key={tab}
          allowStatusFilter={tab === 0}
          filter={
            {
              0: {
                active: { operator: 'IN', value: ['true'] },
              },
              1: {
                active: { operator: 'IN', value: ['true'] },
                status: {
                  operator: 'IN',
                  value: ['COMPLETED'],
                },
              },
              2: {
                active: { operator: 'IN', value: ['true'] },
                status: {
                  operator: 'IN',
                  value: ['PROCESSING'],
                },
              },
              3: {
                active: { operator: 'IN', value: ['true'] },
                status: {
                  operator: 'IN',
                  value: ['PENDING'],
                },
              },
            }[tab]
          }
        />
      </Box>
    </div>
  );
};
