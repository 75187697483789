import { gql } from '@apollo/client';
import { FRAGMENT_DELIVERY_NOTE } from '../../list_DeliveryNote/ListView/query';

export const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int) {
    node(id: $id) {
      id
      ... on ShopOrder {
        deliveryNotes(limits: $limits, cursor: $cursor) {
          totalCount
          nextCursor
          nodes {
            ...FRAGMENT_DELIVERY_NOTE
          }
        }
      }
    }
  }
  ${FRAGMENT_DELIVERY_NOTE}
`;
