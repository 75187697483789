import React, { useState } from 'react';
import { Icon } from '../../../components/IconRender';
import { Box, Button, Divider, Grid, Menu, Typography, useTheme } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import moment from 'moment';

export const soldOptions = [
  {
    label: translate.last_60_days_sold,
    value: 'days60',
    since: moment().subtract(60, 'days').startOf('day').toISOString(),
    until: moment().endOf('day').toISOString(),
  },
  {
    label: translate.last_30_days_sold,
    value: 'days30',
    since: moment().subtract(30, 'days').startOf('day').toISOString(),
    until: moment().endOf('day').toISOString(),
  },
  {
    label: translate.last_7_days_sold,
    value: 'days7',
    since: moment().subtract(7, 'days').startOf('day').toISOString(),
    until: moment().endOf('day').toISOString(),
  },
  {
    label: translate.today_sold,
    value: 'today',
    since: moment().startOf('day').toISOString(),
    until: moment().endOf('day').toISOString(),
  },
];

const SoldSelector = ({ value, onChange }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(false);

  const { days60, days30, days7, today } = value ?? {},
    hasFilter = days60 || days30 || days7 || today;

  return (
    <>
      <Button
        variant={'outlined'}
        onClick={(e) => setAnchorEl(e.target)}
        startIcon={
          <Icon
            icon={'faEmpty'}
            style={{
              fontSize: 14,
            }}
          />
        }
        endIcon={
          <Icon
            icon={'faFilter'}
            style={{
              fontSize: 14,
              color: hasFilter ? theme.palette.secondary.main : '#c5c5c5',
            }}
          />
        }
      >
        <Typography color={'textSecondary'} style={{ textTransform: 'none' }}>
          {translate.filter_condition}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} variant={'menu'} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <Box p={2}>
          <Grid container direction={'column'} spacing={1}>
            <Grid item>
              <Typography color={'secondary'} variant={'h6'}>
                {translate.filter}
              </Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Typography color={'textSecondary'}>
                <small>{translate.display_items || '顯示項目'}</small>
              </Typography>
            </Grid>
            <Grid item>
              {soldOptions.map((item, i) => (
                <div key={i}>
                  <CheckboxField
                    checked={!!value?.[item.value]}
                    label={item.label}
                    onChange={() => {
                      onChange({
                        ...value,
                        [item.value]: !value?.[item.value],
                      });
                    }}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
};

export default SoldSelector;
