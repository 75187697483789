import React from 'react';
import SystemButton from '../../components/SystemButton';
import { translate } from '../../shared/translate';
import ActivityIndicator from '../../components/ActivityIndicator';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { COMPLETE_QUERY } from './query';
import { client } from '../../shared/apollo';
import confirmation from '../../shared/confirmation';

const StockOutCompleteButton = ({ values: { id } = {}, setFieldValue, isSubmitting, setSubmitting }) => {
  return (
    <SystemButton
      color={'success'}
      disabled={isSubmitting}
      onClick={async () => {
        try {
          if (await confirmation(translate.stock_out_complete_confirmation_message)) {
            ActivityIndicator.show();
            setSubmitting(true);
            await client.mutate({
              mutation: COMPLETE_QUERY,
              variables: {
                id,
              },
            });
            setFieldValue('status', 'COMPLETED');
            setFieldValue('__disabled', true);
            toast.success(translate.update_success);
          }
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          ActivityIndicator.hide();
          setSubmitting(false);
        }
      }}
    >
      {translate.status_completed}
    </SystemButton>
  );
};

export default StockOutCompleteButton;
