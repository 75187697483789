import React from 'react';
import { OrderStatus } from '../shared/omniwe-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: `${theme.spacing(0.25)}px ${theme.spacing(2)}px`,
    color: 'white',
    display: 'inline',
    borderRadius: 50,
    whiteSpace: 'nowrap',
  },
}));

export default ({ loading, status, statusObject = OrderStatus }) => {
  const classes = useStyles();
  if (loading)
    return (
      <span
        className={classes.badge}
        style={{
          backgroundColor: '#ddd',
        }}
      >
        {'　　'}
      </span>
    );
  return (
    <span
      className={classes.badge}
      style={{
        backgroundColor: (statusObject[status] || {}).bgColor || '#ddd',
      }}
    >
      {status ? (statusObject[status] || {}).label : ' - '}
    </span>
  );
};
