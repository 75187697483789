import React from 'react';
import Taber from '../../components/Taber';
import ListPayment from './ListPayment';
import { translate } from '../../shared/translate';

export default (props) => {
  return (
    <div>
      <Taber
        tabs={[
          {
            name: translate.all_payment_methods || '所有支付方式',
          },
        ]}
      />
      <ListPayment {...props} />
    </div>
  );
};
