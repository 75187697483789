import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { translate } from '../../../shared/translate';
import HashtagsItem from './HashtagsItem';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import Dnd from '../../../components/DND';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import { Icon } from '../../../components/IconRender';

const MemberHashTagsCard = ({ isSubmitting, values, loading, disabled, setFieldValue, ...props }) => {
  const defaultMemberHashtagSchedules = values?.defaultMemberHashtagSchedules ?? [];
  return (
    <>
      <Typography variant={'h6'}>{translate.default_member_hashtags}</Typography>
      <StyledConfigList spacing={0}>
        <Grid item>
          <Dnd
            direction={'column'}
            spacing={6}
            disabled={disabled}
            items={defaultMemberHashtagSchedules}
            onChange={(v) => setFieldValue('defaultMemberHashtagSchedules', v)}
            renderItem={(defaultMemberHashtagSchedule, i, { nonDraggableProps }) => {
              return (
                <Box mx={1.5}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Icon type={'svg'} icon={IconDragVertical} style={{ cursor: 'pointer', marginTop: 31 }} />
                    </Grid>
                    <Grid item xs {...nonDraggableProps}>
                      <HashtagsItem
                        key={i}
                        value={defaultMemberHashtagSchedule}
                        onChange={(v) => {
                          if (!v) {
                            return setFieldValue(
                              'defaultMemberHashtagSchedules',
                              defaultMemberHashtagSchedules.filter((_, j) => i !== j),
                            );
                          }
                          setFieldValue(
                            'defaultMemberHashtagSchedules',
                            defaultMemberHashtagSchedules.map((item, j) => {
                              if (i === j) {
                                return v;
                              }
                              return item;
                            }),
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          >
            <AddButton
              disabled={loading || isSubmitting}
              onClick={() => {
                setFieldValue(
                  'defaultMemberHashtagSchedules',
                  (defaultMemberHashtagSchedules || []).concat({
                    hashtags: [],
                    validAt: moment().toISOString(),
                  }),
                );
              }}
            />
          </Dnd>
        </Grid>
      </StyledConfigList>
    </>
  );
};

export default MemberHashTagsCard;
