export default [
  { label: '#shop.logo', value: '{{shop.logo}}' },
  { label: '#shop.name', value: '{{shop.name}}' },
  { label: '#shop.url', value: '{{shop.url}}' },
  { label: '#shop.email', value: '{{shop.email}}' },
  {
    label: '#customer.name',
    value: '{{customer.name}}',
  },
  {
    label: '#customer.email',
    value: '{{customer.email}}',
  },
  {
    label: '#date.year',
    value: '{{date.year}}',
  },
  {
    label: '#date.month',
    value: '{{date.month}}',
  },
  {
    label: '#date.day',
    value: '{{date.day}}',
  },
  {
    label: '#date.date',
    value: '{{date.date}}',
  },
  {
    label: '#date.hour',
    value: '{{date.hour}}',
  },
  {
    label: '#date.minute',
    value: '{{date.minute}}',
  },
  {
    label: '#date.second',
    value: '{{date.second}}',
  },
];
