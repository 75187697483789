import React, { useRef } from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { useHistory } from 'react-router-dom';

export default (props) => {
  const history = useHistory();
  const list = useRef();

  return (
    <>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.membership_level,
          },
          {
            name: translate.point_setting,
            onClick: () => history.push('/member_tiers/setting'),
          },
          {
            name: translate.member_default_settings,
            onClick: () => history.push('/member_tiers/member_default_settings'),
          },
        ]}
      />
      <ListView {...props} ref={list} />
    </>
  );
};
