import LocalizedStrings from 'react-localization';
import langJSON from '../lang.json';

const translate = new LocalizedStrings(formatLangs(langJSON));
translate.setLanguage(localStorage.getItem('lang') || 'zh-hk');

function formatLangs(langs = {}, langOptions = ['en', 'zh-hk']) {
  return langOptions.reduce((prev, option) => {
    prev[option] = Object.entries(langs).reduce((prev, [key, value]) => {
      prev[key] = value[option];
      return prev;
    }, {});

    return prev;
  }, {});
}

export { translate };

export const translateFormat = (text = '', variables) => {
  const snippets = text.split(/(\{\{.*?\}\})/g) || [];
  return snippets
    .map((str) => {
      const match = str.match(/\{\{(.*?)\}\}/);
      if (!match) return str;
      const [, key] = match;
      const value = (variables || {})[key];
      return value !== undefined ? value : '';
    })
    .join('');
};
