import React, { Fragment, useState } from 'react';
import CursorBaseList from '../../../../components/CursorBaseList';
import { GET_QUERY } from './query';
import { Box, InputAdornment, List } from '@material-ui/core';
import { getTokenData, parseConnection } from '../../../../shared';
import TypesButtons from './TypesButtons';
import {
  parseChannelCustomer,
  parseChannelPreviewContent,
  parseChannelStaff,
} from '../../../../components/ChannelMessage/ChannelCustomerDetail';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';
import { Icon } from '../../../../components/IconRender';
import ChannelItem from './ChannelItem';
import _ from 'lodash';

const ChannelList = ({ channel: _channel, onChannelClick = (_) => _ }) => {
  const shopId = localStorage.getItem('shopId');
  const [filterType, setFilterType] = useState('all');
  const [search, setSearch] = useState('');
  const me = getTokenData();

  return (
    <Box display={'flex'} flexDirection={'column'} style={{ height: '100%' }}>
      <Box pt={2} px={2}>
        <TypesButtons type={filterType} onChange={setFilterType} />
        <InputField
          style={{ marginTop: 8 }}
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
          variant={'standard'}
          disable
          InputProps={{
            disableUnderline: true,
            style: {
              border: 'none',
              background: '#f0f0f0',
              padding: '3px 15px',
              borderRadius: '10px',
            },
            endAdornment: (
              <InputAdornment position={'end'}>
                <Icon icon={'faSearch'} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <div style={{ height: 'calc(100vh - 350px)' }}>
          <List>
            <CursorBaseList
              query={GET_QUERY}
              autoCalculateIncrementalNextCursor={true}
              queryProps={{
                skip: !shopId,
              }}
              variables={{
                id: shopId,
                playerId: shopId,
                sortBy: [{ field: 'updatedAt', order: 'DESC' }],
                filter: { type: { operator: 'NIN', value: ['SYSTEM'] } },
              }}
              renderItems={(items, renderItem) => {
                const channels = _.unionBy(
                  items.filter((channel) => parseConnection(channel?.messages).nodes.length > 0),
                  'id',
                );
                return channels.map(renderItem);
              }}
              renderItem={(channel, i) => {
                const { id, messages } = channel || {};

                const customer = parseChannelCustomer(channel) ?? {
                    name: 'Guest',
                    picture: '',
                  },
                  staff = parseChannelStaff(channel);

                const {
                  nodes: [{ read } = {}],
                } = parseConnection(messages);

                if (filterType === 'unread' && read) return <Fragment key={id || i} />;
                else if (filterType === 'me' && staff?.email !== me?.email) return <Fragment key={id || i} />;

                const display = parseChannelPreviewContent(channel);
                if (!!search && !~display.indexOf(search) && !~customer.name.indexOf(search))
                  return <Fragment key={id || i} />;
                return (
                  <ChannelItem
                    key={id || i}
                    selected={(_channel || {}).id === id}
                    onClick={() => onChannelClick({ ...channel, customer })}
                    channel={channel}
                  />
                );
              }}
              getConnection={({ node } = {}) => (node || {}).channels}
            />
          </List>
        </div>
      </div>
    </Box>
  );
};

export default ChannelList;
