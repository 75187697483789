import React from 'react';
import { GET_QUERY } from './query';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { ReturnNoteStatus } from 'shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';
import moment from 'moment';
import { checkPermissionV2 } from '../../../components/PermissionMask';
import MenuExport from './MenuExport';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/returns/new',
    getRowLink: ({ id, referenceNo }) => {
      return { pathname: '/returns/' + id, state: { title: referenceNo } };
    },
    fields: [
      {
        title: translate.date,
        align: 'left',
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        sortBy: 'createdAt',
        filterInputProps: ({ value = [] } = {}) => {
          const [since, until] = value || [];
          return {
            sinceProps: {
              inputProps: {
                min: !!until && moment(until).subtract(31, 'days').format('YYYY-MM-DDTHH:mm'),
                max: !!until && moment(until).format('YYYY-MM-DDTHH:mm'),
              },
            },
            untilProps: {
              inputProps: {
                min: !!since && moment(since).format('YYYY-MM-DDTHH:mm'),
                max: !!since && moment(since).add(31, 'days').format('YYYY-MM-DDTHH:mm'),
              },
            },
          };
        },
      },
      {
        title: translate.return_number || '退貨單號',
        value: (row) => row?.referenceNo,
        filter: 'referenceNo',
        filterType: 'text',
      },
      {
        title: translate.order_number,
        value: (row) => row?.order?.referenceNo,
        filter: 'orderReferenceNo',
        filterType: 'text',
      },
      {
        title: translate.product_name || '產品',
        column: false,
        filter: 'productId',
        filterType: 'products',
      },
      {
        title: translate.shop_of_sale || '銷售店鋪',
        align: 'left',
        width: 150,
        value: (row) => {
          return row?.order?.shop?.name ?? '-';
        },
        filter: 'orderShopId',
        filterType: 'shop',
      },
      {
        title: translate.shop_of_return || '退貨地點',
        align: 'left',
        width: 150,
        value: (row) => {
          return row?.shop?.name ?? '-';
        },
        filter: 'shopId',
        filterType: 'shop',
      },
      {
        title: translate.return_status || '退貨狀態',
        align: 'center',
        width: 150,
        fieldName: 'status',
        type: 'options',
        options: Object.keys(ReturnNoteStatus).map((key) => ({
          id: ReturnNoteStatus[key]?.label,
          label: <StatusBadge status={key} statusObject={ReturnNoteStatus} />,
          value: key,
        })),
        filter: 'status',
      },
    ],
    selectionMode: true,
    hasQSearch: false,
    menus: [checkPermissionV2('exportReturn') && MenuExport],
  };

  getData = ({ node } = {}) => {
    const { returnNotes } = node || {};
    return returnNotes;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
}
