import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../shared/translate';
import SystemButton from '../../../components/SystemButton';
import { Icon } from '../../../components/IconRender';
import { Skeleton } from '@material-ui/lab';
import confirmation from '../../../shared/confirmation';

const QuickGenerateButton = ({ loading, onChange }) => {
  return (
    <>
      {loading ? (
        <Skeleton width={80} height={25} />
      ) : (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            if (await confirmation(translate.is_quick_generate_barcode || '確定要快速生成條碼嗎？'))
              onChange(`${Date.now()}`);
          }}
        >
          <SystemButton
            size={'small'}
            color={'default'}
            variant={'text'}
            style={{
              pointerEvents: 'none',
            }}
            startIcon={<Icon icon={'faPlus'} style={{ paddingLeft: 8 }} />}
          >
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {translate.quick_generate}
            </Typography>
          </SystemButton>
        </Box>
      )}
    </>
  );
};

export default QuickGenerateButton;
