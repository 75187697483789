import React from 'react';
import { GET_QUERY } from '../../../list_Product/ListView/query';
import { Checkbox, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import CursorBaseList, { LoadMoreButton } from '../../../../components/CursorBaseList';
import _ from 'lodash';
import { parseOSSQuery } from '../../../../shared';

const ProductField = ({ values, onChange = () => {} }) => {
  const theme = useTheme();

  const { search } = values || {};
  const { data = [], mode } = values?.selectData || {};

  function handleAllClick(isAll) {
    if (isAll) {
      onChange({ ...values, selectData: { mode: 'EXCLUDE', data: [] } });
    } else {
      onChange({ ...values, selectData: { mode: 'INCLUDE', data: [] } });
    }
  }

  function handleClick(id) {
    const exists = data.includes(id);
    if (exists) onChange({ ...values, selectData: { ...values.selectData, data: data.filter((_id) => _id !== id) } });
    else onChange({ ...values, selectData: { ...values.selectData, data: data.concat(id) } });
  }

  function handleFilteredClick(arrayIds) {
    const exist = data.some((item) => arrayIds.includes(item));
    if (exist)
      onChange({
        ...values,
        selectData: { ...values.selectData, data: data.filter((item) => !arrayIds.includes(item)) },
      });
    else
      onChange({
        ...values,
        selectData: { ...values.selectData, data: data.concat(arrayIds.filter((id) => !data.includes(id))) },
      });
  }

  return (
    <Table style={{ width: '99%' }}>
      <TableBody>
        <CursorBaseList
          query={GET_QUERY}
          queryProps={{
            fetchPolicy: 'no-cache',
          }}
          variables={{
            id: localStorage.getItem('shopId'),
            limits: 20,
            query: parseOSSQuery(search, ['name', 'hashtags', 'sku', 'subtitle']),
          }}
          getConnection={(data) => data?.node?.products}
          renderItems={(items, renderItem, totalCount) => {
            let isAllFilteredSelected = false;
            switch (mode === 'EXCLUDE' && !data.length) {
              case true:
                isAllFilteredSelected = true;
                break;
              case false:
                const arrayIds = items.map((item) => item.id);
                isAllFilteredSelected = arrayIds.every((arrayId) => {
                  if (mode === 'INCLUDE') return data.includes(arrayId);
                  else return !data.includes(arrayId);
                });
                break;
            }
            const isAllSelected = !!search ? isAllFilteredSelected : mode === 'EXCLUDE' && !data.length;

            return (
              <>
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: '12px 16px' }}>
                    <Typography style={{ fontSize: '12px' }}>
                      {translate.all_products || '所有產品'}(<span>{totalCount}</span>)
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  key="all"
                  hover
                  onClick={() => {
                    if (!search) handleAllClick(!isAllSelected);
                    else handleFilteredClick(items.map((item) => item.id));
                  }}
                  role="checkbox"
                  aria-checked={isAllSelected}
                  tabIndex={-1}
                  selected={isAllSelected}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isAllSelected}
                      inputProps={{
                        'aria-labelledby': 'all',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={'all'}
                    scope="row"
                    padding="none"
                    style={{ height: 44, cursor: 'pointer' }}
                  >
                    <Typography style={{ color: '#000' }}>
                      {translate.select_all || '全選'}(<span>{totalCount}</span>)
                    </Typography>
                  </TableCell>
                </TableRow>
                {items.map(renderItem)}
              </>
            );
          }}
          renderItem={(item, i) => {
            const { id, name, active } = item;
            const isItemSelected = mode === 'INCLUDE' ? data.includes(item.id) : !data.includes(item.id);
            const labelId = `table-checkbox-${i}`;

            const isAllSelected = mode === 'EXCLUDE' && !data.length;

            return (
              <TableRow
                key={i}
                hover
                onClick={() => handleClick(id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
                style={{ backgroundColor: 'transparent' }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isAllSelected ? isAllSelected : isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{ height: 44, cursor: 'pointer' }}
                >
                  <Typography style={{ color: active ? '#000' : theme.palette.error.light }}>{name}</Typography>
                </TableCell>
              </TableRow>
            );
          }}
          renderLoadMoreButton={({ onClick }) => (
            <TableCell component="th" id={'loading'} scope="row" padding="none" colSpan={999}>
              <LoadMoreButton onClick={onClick} />
            </TableCell>
          )}
        />
      </TableBody>
    </Table>
  );
};

export default ProductField;
