import React, { useState } from 'react';
import { translate } from 'shared/translate';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Icon } from 'components/IconRender';
import InputField from 'components/FormPageMaker/Fields/InputField';
import { useDebounce } from 'react-use';

export default ({ value, onChange = (_) => _, ...props }) => {
  const [input, setInput] = useState(value);
  useDebounce(() => !!input && onChange(input), 2000, [input]);

  return (
    <InputField
      fullWidth={false}
      margin={'none'}
      value={input}
      onChange={(e) => {
        setInput(e.target.value);
      }}
      onBlur={(e) => {
        if (input !== value) onChange(input);
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
          onChange(e.target.value);
        }
      }}
      placeholder={translate.please_input_search}
      // eslint-disable-next-line
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'}>
            {input ? (
              <IconButton
                size={'small'}
                onClick={() => {
                  onChange(undefined);
                  setInput('');
                }}
                color={'secondary'}
              >
                <Icon icon={'faTimes'} />
              </IconButton>
            ) : (
              <Icon icon={'faSearch'} />
            )}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
