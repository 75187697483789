import { translate } from '../../../../shared/translate';
import { client } from '../../../../shared/apollo';
import { gql } from '@apollo/client';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper } from '../../../../shared';

const EXPORT_QUERY = gql`
  mutation ($shopId: ID!, $filter: AttendanceFilterInput) {
    attendancesExport(shopId: $shopId, filter: $filter) {
      id
      updatedAt
      createdAt
      status
      output
    }
  }
`;

const MenuExportAll = {
  name: translate.export,
  enable: function () {
    return !!this.getSelectionCount();
  },
  onClick: async function () {
    if (await confirmation(translate.formatString(translate['confirm_export_n_records'], this.getSelectionCount()))) {
      const res = await this.infinityFetchAllSelected();
      const ids = res.nodes.map((node) => node.id);

      await actionWrapper(
        async () => {
          await client.mutate({
            mutation: EXPORT_QUERY,
            variables: {
              shopId: localStorage.getItem('shopId'),
              filter: {
                staffId: {
                  operator: 'IN',
                  value: ids,
                },
              },
            },
          });
        },
        {
          messages: {
            success: translate.export_tips,
          },
        },
      );
    }
  },
};

export default MenuExportAll;
