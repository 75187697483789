import React from 'react';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    label: 'KConnect',
    value: 'KCONNECT',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'Company ID',
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: 'Private Key',
            type: 'textarea',
            name: 'secret',
            required: true,
            inputProps: {
              type: 'password',
            },
            placeholder: `-----BEGIN RSA PRIVATE KEY-----
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
-----END RSA PRIVATE KEY-----
              `,
          },
          {
            label: 'APPID',
            type: 'text',
            name: 'metadata.loginId',
            required: true,
            maxLength: 255,
          },
          {
            label: 'APP Secret',
            type: 'text',
            name: 'metadata.loginPWD',
            required: true,
            maxLength: 255,
            inputProps: {
              type: 'password',
            },
          },
        ]}
      />
    );
  },
  type: 'AUTHENTICATION',
};
