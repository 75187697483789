import React from 'react';
import { Box, Button, Grid, Hidden, Typography } from '@material-ui/core';
import LangPicker from './LangPicker';
import MessageNotification from './MessageNotification';
import Account from './Account';
import _ from 'lodash';
import Searcher from './Searcher';
import GridContainerWrapper from '../../../../components/GridContainerWrapper';
import TimeDisplay from './TimeDisplay';
import useBreakPoint from '../../../../components/useBreakPoint';
import { checkPermissionV2 } from '../../../../components/PermissionMask';
import { translate } from '../../../../shared/translate';

const UrlPattern = require('url-pattern');

export default ({
  pages = [],
  loading,
  location: { pathname, state: { title } = {} } = {},
  shop: { id: shopId, customDomain, hostname, timezone } = {},
  me,
}) => {
  const matchPage =
    pages.find(({ link }) => {
      if (!link) return false;
      const pattern = new UrlPattern(link.replace(/:(.*)\?/g, '(:$1)'), { segmentValueCharset: `a-zA-Z0-9_\\-|` });
      return !!link && pattern.match(pathname);
    }) || {};
  const breakpoint = useBreakPoint();
  const breadcrumb = [..._.reverse(getTreeParents(matchPage._parent).filter(({ name }) => name))];
  const isXS = !!~['xs'].indexOf(breakpoint);
  if (title) breadcrumb.push({ name: title });
  else if (matchPage.name) breadcrumb.push({ name: matchPage.name });

  return (
    <Box py={!isXS ? 1 : 0}>
      <GridContainerWrapper>
        <Grid container spacing={1} alignItems={'center'}>
          <Hidden smUp>
            <Grid item>
              <Box width={30} />
            </Grid>
          </Hidden>
          <Grid item>
            <LangPicker />
          </Grid>
          {!!shopId && !!checkPermissionV2('readNotification') && (
            <Grid item>
              <MessageNotification />
            </Grid>
          )}
          <Grid item xs={true}>
            <Hidden xsDown>
              {!!shopId && (
                <Searcher
                  pages={pages}
                  placeholder={breadcrumb
                    .map(({ name, label, title }) => {
                      return name || label || title;
                    })
                    .join(' > ')}
                />
              )}
            </Hidden>
          </Grid>
          {(!!customDomain || !!hostname) && (
            <Hidden xsDown>
              <Grid item>
                <a href={`https://${customDomain || hostname}`} target={'_blank'}>
                  <Button>
                    <Typography variant="h5">{translate.shop_preview || '店鋪預覽'}</Typography>
                  </Button>
                </a>
              </Grid>
            </Hidden>
          )}
          {!!timezone && (
            <Hidden xsDown>
              <Grid item>
                <TimeDisplay timezone={timezone} />
              </Grid>
            </Hidden>
          )}
          <Grid item>
            <Account loading={loading} user={me || {}} />
          </Grid>
        </Grid>
      </GridContainerWrapper>
    </Box>
  );
};

export function getTreeParents(item, key = '_parent') {
  if (!item) return [];
  return [item, ...getTreeParents(item[key])];
}
