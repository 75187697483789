import { gql } from '@apollo/client';

export const FRAGMENT_CASH_FLOW = gql`
  fragment FRAGMENT_CASH_FLOW on ShopCashFlow {
    id
    updatedAt
    createdAt
    deviceId
    direction
    amount
    remark
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: CashFlowFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      id
      ... on CompanyShop {
        cashFlows(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_CASH_FLOW
          }
        }
      }
    }
  }
  ${FRAGMENT_CASH_FLOW}
`;
