import React from 'react';
import { OrderCard } from '../../../components/OrderCard';
import { Grid } from '@material-ui/core';
import { getDuration } from '../../../components/FormPageMaker/Fields/DurationField';
import Dnd from '../../../components/DND';
import ServiceField from './ServiceField';
import { AddButton } from '../../../components/StyledConfigList';
import { Icon } from '../../../components/IconRender';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import TrashButton from '../../../components/TrashButton';

const ServicesField = ({ validationStrategy, value = [], disabled, loading, onChange = (v) => {} }) => {
  return (
    <Dnd
      items={value}
      onChange={onChange}
      disabled={disabled}
      direction={'column'}
      renderItem={(item, i, { nonDraggableProps }) => {
        return (
          <OrderCard style={{ position: 'relative' }}>
            <Grid container alignItems={'center'} spacing={3}>
              <Grid item style={{ cursor: 'pointer' }}>
                <Icon icon={IconDragVertical} type={'svg'} />
              </Grid>
              <Grid item xs style={{ paddingTop: 24 }} {...nonDraggableProps}>
                <ServiceField
                  isFirst={validationStrategy === 'PARALLEL' || i === 0}
                  disabled={disabled}
                  value={item}
                  onChange={(service) => onChange(value.map((v, j) => (j === i ? service : v)))}
                />
              </Grid>
            </Grid>
            <TrashButton
              color={'error'}
              style={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              disabled={disabled}
              onClick={() => {
                onChange(value.filter((__, j) => j !== i));
              }}
            />
          </OrderCard>
        );
      }}
    >
      <AddButton
        disabled={disabled}
        onClick={() => {
          onChange(
            value.concat({
              reserveMins: 0,
              metadata: {},
              availableUntil: getDuration({ hours: 1 }),
              validSince: getDuration({ minutes: 1 }),
              validUntil: getDuration({ months: 3 }),
            }),
          );
        }}
        title={'新增預約節目'}
      />
    </Dnd>
  );
};

export default ServicesField;
