import React, { useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';
import { translate } from '../../shared/translate';
import InputField from '../../components/FormPageMaker/Fields/InputField';

const UnpaidCartReminderField = ({ value, onChange, disabled }) => {
  const [cartTimeoutToggle, setCartTimeoutToggle] = useState(!!value);

  useEffect(() => {
    if (cartTimeoutToggle === false) {
      onChange(undefined);
    }
  }, [cartTimeoutToggle]);

  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={true} md={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{translate.unpaid_cart_reminder || '購物車未結帳提醒'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              {translate.automatically_push_based_on_carts || '依據顧客購物車內容物，自動推播店內訊息給會員'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>{translate.trigger_after || '指定時間'}</Typography>
                </Grid>
                <Grid item>
                  <InputField
                    disabled={!cartTimeoutToggle}
                    style={{
                      width: 'auto',
                    }}
                    value={value}
                    type={'number'}
                    inputProps={{
                      min: 0,
                      step: 1,
                    }}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position={'end'}>{translate.minutes || '分鐘'}</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SwitchField
          checked={value}
          disabled={disabled}
          onChange={() => {
            setCartTimeoutToggle(!cartTimeoutToggle);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UnpaidCartReminderField;
