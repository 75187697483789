import React from 'react';
import FormRow from '../../FormRow';
import SelectComboValue from '../../SelectCombo/SelectComboValue';

export default ({ options = [], title, filter, onFilterChange = (_) => _ }) => {
  const { value } = filter || {};
  return (
    <FormRow title={title}>
      <SelectComboValue
        disablePortal={false}
        options={options}
        multiple={true}
        value={value}
        onChange={(v) => {
          onFilterChange({
            ...filter,
            value: v,
            operator: 'IN',
          });
        }}
      />
    </FormRow>
  );
};
