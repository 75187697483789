import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { toast } from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { client } from '../../../../shared/apollo';
import FormToolbar from '../../../../components/FormToolbar';
import SystemButton from '../../../../components/SystemButton';
import { Formik } from 'formik';
import useBreakPoint from '../../../../components/useBreakPoint';
import { IMPORT_QUERY } from '../query';
import { useParams } from 'react-router-dom';
import FileUploadField from '../../../../components/FormPageMaker/Fields/FileUploadField';

const ImportContainer = ({ tab = 0, name, onClick }) => {
  const breakPoint = useBreakPoint();
  const isUnderSM = ['xs'].includes(breakPoint);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  return (
    <Formik
      key={tab}
      initialValues={{}}
      onSubmit={async ({ file }, { setSubmitting }) => {
        const { s3Path } = file || {};
        try {
          setLoading(true);
          await client.mutate({
            mutation: IMPORT_QUERY,
            variables: {
              id,
              shopId: localStorage.getItem('shopId'),
              url: s3Path,
            },
          });

          toast.success(translate.importing_submit);
          onClick();
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
          setSubmitting(false);
          ActivityIndicator.hide();
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
        const { file } = values || {};
        const { fileName, fileSize, s3Path } = file || {};
        const _loading = isSubmitting || loading;
        const onClear = () => setFieldValue('file', null);

        return (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box
              p={4}
              style={{
                backgroundColor: '#fff',
                borderRadius: isUnderSM ? 0 : '0 13px 0 0',
                boxShadow: '0px 1px 15px 0px #0000001A',
                flex: 1,
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="h6" style={{ color: '#000' }}>
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 'bold', marginBottom: 1 }}>{translate.upload_file}</Typography>
                  <FileUploadField
                    value={{
                      fileSize,
                      fileName,
                      s3Path,
                    }}
                    onChange={(value) => {
                      if (value) setFieldValue('file', value);
                      else {
                        setFieldValue('file', null);
                      }
                    }}
                    onClear={onClear}
                    loading={_loading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box p={3} style={{ border: `1px solid #f6f6f6`, borderRadius: 13 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography>
                              {translate.if_not_exist_will_create_new_coupon ||
                                '如果系統中未存在子優惠券編號，上傳的資料將建立一個新的子優惠券'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography style={{ fontWeight: 'bold' }}>{translate.tips || '提示'}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>1. {translate.pls_dl_template || '請先下載CSV樣本'}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              2.{' '}
                              {translate.sub_coupon_IDs_must_unique ||
                                '子優惠券編號不可重複，僅允許字母和數字，不區分大小寫'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              3. {translate.sub_coupon_IDs_must_no_longer_than_thirty || '子優惠券編號上限為30字元'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              4. {translate.generated_sub_coupons_added_cumulatively || '生成以疊加形式新增子優惠券'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <FormToolbar
              extraLeft={
                <SystemButton
                  disabled={_loading}
                  onClick={() => {
                    onClick();
                    onClear();
                  }}
                >
                  {translate.cancel}
                </SystemButton>
              }
              extra={
                <>
                  <SystemButton
                    color="info"
                    disabled={_loading}
                    onClick={async () => {
                      try {
                        setLoading(true);
                        window.open('https://assets.omniwe.com/o0ssUuqd_pwgDG5YSbAx9.csv');
                      } catch (error) {
                        toast.error(errorParser(error));
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    {translate.download_csv_file || '下載CSV檔案'}
                  </SystemButton>

                  <SystemButton color={'omniewRed'} loading={_loading} disabled={_loading || !fileSize} type={'submit'}>
                    {translate.upload_csv_file || '上傳CSV檔案'}
                  </SystemButton>
                </>
              }
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ImportContainer;
