import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import SystemButton from './SystemButton';
import { Icon } from './IconRender';

const MenuSystemButton = ({ items = [], disabled, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <SystemButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        disabled={disabled}
        endIcon={<Icon icon={'faChevronDown'} style={{ fontSize: '0.75rem' }} />}
        {...props}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        {items.map((item, i) => (
          <MenuItem
            key={i}
            disabled={disabled}
            onClick={() => {
              setAnchorEl(null);
              item.onClick();
            }}
          >
            {item?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuSystemButton;
