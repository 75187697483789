import { gql } from '@apollo/client';

export const CUSTOMERS_UPDATE_IMPORT_QUERY = gql`
  mutation($shopId: ID!, $url: AWSURL!) {
    customersUpdateImport(shopId: $shopId, url: $url) {
      id
      status
      output
    }
  }
`;

export const CUSTOMERS_IMPORT_QUERY = gql`
  mutation($shopId: ID!, $url: AWSURL!) {
    customersImport(shopId: $shopId, url: $url) {
      id
      status
      output
    }
  }
`;
