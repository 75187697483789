import React, { Fragment } from 'react';
import { JSONParseSafely } from '../../shared';
import { Container } from '@material-ui/core';
import OrderPrintout from '../PrintOrder/OrderPrintout';
import _ from 'lodash';

export default () => {
  const nodes = JSONParseSafely(localStorage.getItem('cache_print_orders'), []);

  const { fontSize = '', sort = '' } = JSON.parse(
    '{"' + window.location.search.substring(1).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
  );

  const sortedNodes = nodes?.map((node) => {
    return {
      ...node,
      items: _.sortBy(node?.items, (item) => {
        function getProductItem(item) {
          return item?.productVariation || item?.bundleProduct?.bundleProduct || item?.addOnProduct;
        }

        if (sort === 'sku') {
          return getProductItem(item)?.sku?.toUpperCase();
        } else {
          return node?.items?.find((_item) => getProductItem(_item)?.id === getProductItem(item)?.id)?.createdAt;
        }
      }),
    };
  });

  return (
    <>
      <style>{`html, body, #root { 
        height: auto; 
        font-size: ${fontSize} !important;
      }
      @media print {
        html,
        body {
          font-size: ${fontSize} !important;
        }
      `}</style>
      <Container style={{ maxWidth: 1200 }}>
        {sortedNodes.map((node, i) => (
          <Fragment key={i}>
            <OrderPrintout order={node} />
            <div style={{ pageBreakAfter: 'always' }} />
          </Fragment>
        ))}
      </Container>
    </>
  );
};
