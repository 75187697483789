import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Avatar, Grid, Typography } from '@material-ui/core';
import SystemButton from '../../SystemButton';
import { translate } from '../../../shared/translate';
import LSkeleton from '../../LSkeleton';
import { parseCustomerName } from '../../../shared';
import { Link } from 'react-router-dom';
import { Icon } from '../../IconRender';
import { ReactComponent as IconCustomers } from '../../../assets/icon/customers.svg';
import useBreakPoint from '../../../../src/components/useBreakPoint';

const CustomerInfo = ({ loading, customer }) => {
  const breakPoint = useBreakPoint();
  const isMobile = ['xs', 'sm'].includes(breakPoint);

  return (
    <Grid container direction={'column'} spacing={2} alignItems={'center'}>
      <Grid item>
        {loading ? (
          <Skeleton variant={'circle'} style={{ width: 50, height: 50 }} />
        ) : (
          <Avatar style={{ width: isMobile ? 150 : 50, height: isMobile ? 150 : 50 }} src={customer?.picture} />
        )}
      </Grid>
      <Grid item>
        <Typography align={'center'} variant={'caption'} component={'div'}>
          {loading ? <LSkeleton width={150} /> : customer?.phoneNumber}
        </Typography>
        <Typography align={'center'} variant={'caption'} component={'div'}>
          {loading ? <LSkeleton width={100} /> : customer?.email}
        </Typography>
        <Typography align={'center'} variant={'caption'} component={'div'}>
          {loading ? <LSkeleton width={130} /> : parseCustomerName(customer) ?? 'Guest'}
        </Typography>
      </Grid>
      <Grid item>
        {customer?.id && (
          <Link to={`/customers/${customer?.id}`} target={'_blank'}>
            <SystemButton
              style={{ backgroundColor: '#F9F9F9', color: '#CC433C', border: 'none' }}
              variant={'outlined'}
              disabled={loading}
              startIcon={
                <Icon
                  type={'svg'}
                  icon={IconCustomers}
                  viewBox={'0 0 16 14'}
                  style={{
                    fontSize: 12,
                  }}
                />
              }
            >
              <span style={{ textTransform: 'none' }}>{translate.customer_details}</span>
            </SystemButton>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};
export default CustomerInfo;
