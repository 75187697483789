import React, { Fragment } from 'react';
import {
  Box,
  Card,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Query } from '@apollo/client/react/components';
import { translate } from 'shared/translate';
import ItemRenderer from './ItemRenderer';
import { gql } from '@apollo/client';
import SkeletonRows from './SkeletonRows';
import Filter from './Filter';
import QueryParamsWrapper from 'components/QueryParamsWrapper';
import ConnectionPagination from './ConnectionPagination';
import { infinityFetch, obj2Hash, parseConnection, parseOSSQuery, sleep } from 'shared';
import { Icon } from 'components/IconRender';
import CheckboxField from 'components/FormPageMaker/Fields/CheckboxField';
import Collapse from '@material-ui/core/Collapse';
import CreateButton from './CreateButton';
import ConnectionCardHeader from './ConnectionCardHeader';
import ConnectionTableCell from './ConnectionTableCell';
import ConnectionTableContainer from './ConnectionTableContainer';
import ConnectionTable from './ConnectionTable';
import AllCheckControl from './AllCheckControl';
import RefreshButton from '../RefreshButton';
import SortableHeadAlignCenterPlaceholder from './SortableHeadAlignCenterPlaceholder';
import DebounceSearchInput from '../DebounceSearchInput';
import ConnectionTableRowWrapper from './ConnectionTableRowWrapper';
import { theme } from '../../theme';
import _ from 'lodash';

export default class ConnectionPageMarker extends QueryParamsWrapper {
  constructor(props) {
    super(props);

    /* @overwrite */
    this.state = {
      title: '',
      gql: {
        get: gql`
          query {
            me {
              ... on User {
                id
              }
            }
          }
        `,
      },
      createUrl: '',
      fields: [
        {
          title: 'image',
          fieldName: 'thumbnail',
          type: 'image',
        },
        {
          title: 'name',
          fieldName: 'name',
          render: (item) => <div>{item.id}</div>,
        },
        {
          title: 'active',
          fieldName: 'active',
          align: 'right',
          width: 180,
          type: 'bool',
        },
        {
          title: 'createdAt',
          fieldName: 'createdAt',
          align: 'right',
          width: 180,
          type: 'datetime',
        },
      ],
      menus: [
        // {
        //   name: 'Import',
        //   onClick: () => {},
        // },
        // {
        //   name: 'Export',
        //   onClick: () => {},
        // },
      ],
      getRowLink: (item) => undefined,
      ...this.getQueryState(),
      limits: 25,
      skip: false,
      verticalHighlightIndex: undefined,
      menuAnchorEl: undefined,
      selectionMode: false,
      selectionData: { mode: 'INCLUDE', data: [] },
      collapsable: false,
      collapseOpenRow: undefined,
      refreshable: true,
      hasQSearch: false,
      hasLegacyFilter: true,
      qFields: [],
    };
  }
  searchSeparator = '^*';

  /* @overwrite */
  getData(data = {}) {
    return { nodes: [], totalCount: 0, nextCursor: null };
  }

  /* @overwrite */
  getExtraFetchVariables() {
    return {};
  }

  /* @overwrite */
  getExtraQueryOptions() {
    return {};
  }

  componentDidUpdate(prevProps) {
    const { location: { search = '' } = {} } = this.props,
      { location: { search: _search = '' } = {} } = prevProps;
    if (search !== _search) {
      this.setState(this.getQueryState());
    }
  }

  getQueryState = () => {
    const { cursor, sort, q, ..._filter } = this.getQueryParams();
    return {
      q,
      cursor,
      filter: Object.keys(_filter).reduce((reducer, key) => {
        const filter = _filter[key];
        const [operator, ...value] = filter.split(this.searchSeparator) || [];
        if (!!operator && value.length > 0) {
          reducer[key] = {
            operator,
            value: value.map((v) => {
              if (v === 'admin∆admin') return null;
              return v;
            }),
          };
        }
        return reducer;
      }, {}),
      sortBy: (() => {
        if (!sort) return undefined;
        const [field, order] = sort.split(this.searchSeparator);
        return { field, order };
      })(),
    };
  };

  writeQueryParams = (update) => {
    const { q, cursor, filter, sortBy, fields } = { ...this.state, ...update };
    const fieldKeyWithFilter =
      fields
        ?.filter((fi) => fi?.filter)
        ?.map((fi) => {
          if (typeof fi?.filter === 'function') return fi?.fieldName;
          return fi?.filter;
        }) ?? [];

    this.patchQueryParams((params) => {
      return _.omitBy(
        {
          ...params,
          ...fieldKeyWithFilter?.reduce((reducer, key) => ({ ...reducer, [key]: undefined }), {}),
          q,
          cursor,
          sort: !!sortBy ? `${sortBy.field}${this.searchSeparator}${sortBy.order}` : undefined,
          ...Object.keys(filter).reduce((reducer, key) => {
            const { operator, value = [] } = filter[key];
            if (!!operator && !!value && value.length > 0) {
              reducer[key] = `${operator}${this.searchSeparator}${value.join(this.searchSeparator)}`;
            }
            return reducer;
          }, {}),
        },
        _.isNil,
      );
    });
    // this.setState({ selectionData: { mode: 'INCLUDE', data: [] } });
  };

  onFilterChange = (filter) => {
    this.setState({ filter });
    this.writeQueryParams({ filter, cursor: undefined });
  };

  goToLastPage = () => {
    const { limits } = this.state;

    if (limits) {
      this.writeQueryParams({
        cursor: limits * (Math.ceil(this.totalCount / limits) - 1),
      });
    }
  };

  resetCursor = () => {
    this.writeQueryParams({ cursor: undefined, filter: {}, q: '', sortBy: undefined });
  };

  onSortingClick = (sortField) => {
    let { sortBy } = this.state,
      { field, order } = sortBy || {};
    if (!!sortField) {
      if (field !== sortField) {
        sortBy = { field: sortField, order: 'ASC' };
      } else if (order === 'ASC') {
        sortBy = { field: sortField, order: 'DESC' };
      } else if (order === 'DESC') {
        sortBy = undefined;
      }
      this.writeQueryParams({ sortBy });
    } else {
      this.writeQueryParams({ sortBy: undefined });
    }
  };

  parseFilter(filter) {
    return filter;
  }

  getFilter(channel = 'filter') {
    const { filter: propFilter } = this.props;
    const { filter, fields = [] } = this.state;

    return {
      ...Object.keys(filter).reduce((reducer, key) => {
        const { filterChannel = 'filter' } = fields.find((col) => col.filter === key) || {};

        if (filterChannel === channel) {
          let { operator, value = [] } = filter[key] || {};

          if (!!operator && !!value && value.length > 0) {
            if (operator === 'LIKE') {
              value = [`%${value[0]}%`];
            }
            reducer[key] = {
              operator,
              value: value.map((val) => {
                if (val) {
                  if (val === '∆') return null;
                  if (/^(.*?)∆.*$/.test(val)) return val.replace(/^(.*?)∆.*$/, '$1');
                  return val;
                }
                return null;
              }),
            };
          }
        }
        return reducer;
      }, {}),
      ...propFilter,
    };
  }

  resetSelectionData() {
    this.setState({
      selectionData: {
        mode: 'INCLUDE',
        data: [],
      },
    });
  }

  parseQ(query) {
    return query;
  }

  getQ() {
    const { q, qFields } = this.state;
    const filter = this.getFilter('q');

    return (
      Object.entries(filter)
        .map(([key, { operator, value = [] } = {}]) => {
          let v;
          switch (operator) {
            case 'EQ':
              return `${key}:"${value[0]}"`;
            case 'LIKE':
              v = (value[0] || '').replace(/%/g, '*');
              if (v.match(/[\u3400-\u9FBF]/)) return `${key}:"${v}"`;
              return `(${key}:"${v}")`;
            case 'NLIKE':
              v = (value[0] || '').replace(/%/g, '*');
              if (v.match(/[\u3400-\u9FBF]/)) return `(-${key}:"${v}")`;
              return `-${key}:"${v}"`;
            case 'IN':
              return `${key}:(${value.map((v) => `"${v}"`).join(' ')})`;
            case 'NIN':
              return `-${key}:(${value.map((v) => `"${v}"`).join(' ')})`;
            case 'BETWEEN':
              return `${key}:[${value[0] || '*'} TO ${value[1] || '*'}]`;
            default:
              return undefined;
          }
        })
        .concat(parseOSSQuery(q, qFields || []))
        .filter((_) => _)
        .join(' AND ') || undefined
    );
  }

  getSortBy() {
    const { sortBy } = this.state;
    return sortBy
      ? [sortBy]
      : [
          {
            field: 'createdAt',
            order: 'DESC',
          },
        ];
  }

  getSelectionQ() {
    const { selectionMode, selectionData } = this.state;
    const Qs = [this.getQ()];
    if (!!selectionMode) {
      const { mode, data = [] } = selectionData || {};
      const ids = data.map((datum) => `"${datum.id}"`);
      if (mode === 'INCLUDE') {
        if (ids.length > 0) Qs.push(`_id:(${ids.join(' ')})`);
      } else if (mode === 'EXCLUDE') {
        if (ids.length > 0) Qs.push(`-_id:(${ids.join(' ')})`);
      }
    }
    return Qs.filter((_) => _).join(' AND ') || undefined;
  }

  async infinityFetchAllSelected({ onProgress, includeCurrentQSearch = true, ...options } = {}) {
    const { gql: { get } = {}, cursor, limits, hasQSearch, selectionData } = this.state;

    if (selectionData?.mode === 'INCLUDE' && !selectionData?.data?.length) return parseConnection([]);
    const query = hasQSearch && includeCurrentQSearch ? this.getQ() : undefined;

    return await infinityFetch({
      query: get,
      variables: {
        query,

        /****** filter is deprecated if QSearch is activated ******/
        filter: this.getSelectionFilter(),
        /****** filter is deprecated if QSearch is activated ******/

        sortBy: this.getSortBy(),
        cursor: cursor ? cursor - 0 : undefined,
        limits: limits - 0,
        ...this.getExtraFetchVariables(),
      },
      getConnection: (...args) => parseConnection(this.getData(...args)),
      onProgress,
      ...options,
    });
  }

  getSelectionFilter() {
    const { selectionMode, selectionData } = this.state;
    const filter = this.getFilter();
    if (!!selectionMode) {
      const { mode, data = [] } = selectionData || {};
      const ids = data.map((datum) => datum?.id || '');
      if (mode === 'INCLUDE') {
        if (ids.length > 0)
          filter.id = {
            operator: 'IN',
            value: ids,
          };
      } else if (mode === 'EXCLUDE') {
        if ((this.nodes ?? []).length === this.totalCount) {
          filter.id = {
            operator: 'IN',
            value: this.nodes.map((node) => node.id).filter((id) => !ids.includes(id)),
          };
        } else {
          if (ids.length === 0) {
            filter.id = {
              operator: 'NIN',
              value: ['dummy'],
            };
          } else
            filter.id = {
              operator: 'NIN',
              value: ids,
            };
        }
      }
    }
    return filter;
  }

  // getSelectionCount() {
  //   const { selectionData } = this.state;
  //   const { mode, data = [] } = selectionData || {};
  //   return (mode === 'INCLUDE' ? data.length : (this.totalCount || 0) - data.length) || 0;
  // }

  getSelectionCount() {
    const { selectionData } = this.state;
    const { mode, data } = selectionData || {};
    if (mode === 'INCLUDE') {
      if (data && data.length > 0) {
        return data.length;
      } else {
        return 0;
      }
    } else {
      const filteredCount = data?.filter(Boolean)?.length ?? 0;
      const unselectedCount = this.totalCount - filteredCount;
      if (unselectedCount > 0) {
        return unselectedCount;
      } else {
        return 0;
      }
    }
  }

  getFetchPolicy() {
    return 'cache-and-network';
  }

  getSkip() {
    const { skip } = this.state;
    return !!skip;
  }
  getQuery() {
    const { gql: { get } = {} } = this.state;
    return get;
  }

  onCompleted(data) {}

  render() {
    const { gql: { get } = {}, cursor, limits, hasQSearch } = this.state;
    const query = hasQSearch ? this.parseQ(this.getQ()) : undefined;

    return (
      <Query
        skip={this.getSkip()}
        query={this.getQuery()}
        errorPolicy={'all'}
        fetchPolicy={this.getFetchPolicy()}
        variables={{
          query,

          /****** filter is deprecated if QSearch is activated ******/
          filter: this.parseFilter(this.getFilter()),
          /****** filter is deprecated if QSearch is activated ******/

          sortBy: this.getSortBy(),
          cursor: cursor ? cursor - 0 : undefined,
          limits: limits - 0,
          ...this.getExtraFetchVariables(),
        }}
        onCompleted={this.onCompleted.bind(this)}
        {...this.getExtraQueryOptions.bind(this)()}
      >
        {(...args) => this.renderWrapper(...args)}
      </Query>
    );
  }
  renderWrapper(...args) {
    const { className } = this.props;
    return <div className={className}>{this.renderContent(...args)}</div>;
  }
  renderContent({ loading, data = {}, refetch = (_) => _ } = {}) {
    let nodes, totalCount, nextCursor;
    try {
      this.refetch = refetch;
      const connection = parseConnection(this.getData(data));
      nodes = connection.nodes;
      totalCount = connection.totalCount;
      nextCursor = connection.nextCursor;
    } catch (e) {
      nodes = [];
      totalCount = 0;
      nextCursor = null;
    }

    this.nodes = nodes;
    this.totalCount = totalCount;
    this.nextCursor = nextCursor;
    return (
      <>
        <Card square elevation={0}>
          {this.renderCardHeader({ nodes, totalCount, nextCursor, loading })}
          {this.renderTableContainer({ nodes, totalCount, nextCursor, loading })}
          {!loading && nodes.length === 0 && this.renderNoData()}
          <Box p={2}>{this.renderPagination({ loading, data: { nodes, totalCount, nextCursor }, refetch })}</Box>
        </Card>
        {this.renderModal({ nodes, totalCount, nextCursor, loading })}
      </>
    );
  }

  renderTableContainer({ nodes, totalCount, nextCursor, loading }) {
    return (
      <ConnectionTableContainer>
        {this.renderTable({ nodes, totalCount, nextCursor, loading })}
      </ConnectionTableContainer>
    );
  }

  renderTable(connection) {
    const { stickyHeader } = this.state;
    const { nodes, loading } = connection;
    return (
      <ConnectionTable stickyHeader={stickyHeader}>
        {this.renderHeader(connection, stickyHeader)}
        <TableBody>{loading && nodes.length === 0 ? this.renderSkeletons(nodes) : this.renderList(nodes)}</TableBody>
      </ConnectionTable>
    );
  }

  renderCardHeader(context) {
    const { createUrl, fields = [], menus = [], hasQSearch, hasLegacyFilter } = this.state;
    const extra = this.renderExtras(context);
    const hasFilter = hasLegacyFilter && !!fields.find((field) => !!field.filter);
    if (hasQSearch || hasFilter || createUrl || extra || menus.length > 0)
      return (
        <ConnectionCardHeader>
          <Grid container direction={'row'} alignItems={'center'}>
            <Box flex={1} display={'flex'} alignItems={'center'}>
              {hasFilter && this.renderFilter()}
              {hasQSearch && this.renderQSearcher(context)}
              <div style={{ flex: 1 }} />
              {extra}
            </Box>
            {this.renderCreateButton(context)}
            {this.renderRefreshButton(context)}
            {this.renderMenu(context)}
            {hasQSearch && this.renderMobileQSearcher(context)}
          </Grid>
        </ConnectionCardHeader>
      );
  }
  renderFilter() {
    const { fields = [], filter } = this.state;
    const orderedFilter = _.sortBy(fields, 'filterIndex');
    return (
      <Filter
        key={obj2Hash(filter)}
        fields={orderedFilter.filter((field) => field && field.filter)}
        filter={filter}
        onFilterChange={this.onFilterChange}
      />
    );
  }
  renderModal({ nodes, totalCount, nextCursor }) {
    return null;
  }
  renderExtras({ nodes, totalCount, nextCursor }) {
    return null;
  }
  renderQSearcher({ nodes, totalCount, nextCursor }) {
    const { q } = this.state;
    return (
      <Hidden xsDown>
        <Box flex={1} pr={2}>
          <DebounceSearchInput
            fullWidth
            value={q}
            onChange={(q) => {
              this.writeQueryParams({
                q,
                cursor: undefined,
              });
            }}
          />
        </Box>
        <Hidden mdDown>
          <Box flex={1} />
        </Hidden>
      </Hidden>
    );
  }
  renderMobileQSearcher({ nodes, totalCount, nextCursor }) {
    const { q } = this.state;
    return (
      <Hidden smUp>
        <Box px={1} width={'100%'}>
          <DebounceSearchInput
            fullWidth
            value={q}
            onChange={(q) => {
              this.writeQueryParams({
                q,
                cursor: undefined,
              });
            }}
          />
        </Box>
      </Hidden>
    );
  }

  renderPagination({ loading, data: { nextCursor, totalCount } = {} }) {
    const { cursor, limits, refreshable } = this.state;
    return (
      <ConnectionPagination
        loading={loading}
        totalCount={totalCount}
        cursor={cursor}
        limits={limits}
        onRefreshClick={
          !!refreshable
            ? () => {
                this.refetch && this.refetch();
              }
            : undefined
        }
        onCursorChange={(cursor) => {
          this.writeQueryParams({ cursor });
        }}
      />
    );
  }
  renderNoData() {
    return (
      <ConnectionCardHeader>
        <Typography variant={'body1'} color={'textSecondary'} align={'center'}>
          {translate.no_data}
        </Typography>
      </ConnectionCardHeader>
    );
  }
  renderSkeletons(list = []) {
    const { fields, selectionMode } = this.state;
    return <SkeletonRows selectionMode={selectionMode} columns={fields} count={list.length} />;
  }
  renderRowChecker(item) {
    const { selectionData } = this.state,
      { mode, data } = selectionData;
    const inArrayIndex = data.findIndex((datum) => {
      if (datum === item) return true;
      else if (datum.id === item.id) return true;
      return false;
    });
    const checked = mode === 'EXCLUDE' ? !~inArrayIndex : !!~inArrayIndex;

    return (
      <CheckboxField
        controlled={true}
        checked={checked}
        onChange={() => {
          if (mode === 'EXCLUDE') {
            if (checked) data.push(item);
            else data.splice(inArrayIndex, 1);
          } else {
            if (checked) data.splice(inArrayIndex, 1);
            else data.push(item);
          }
          this.setState({ selectionData: { ...selectionData, data: [...data] } });
        }}
      />
    );
  }
  renderList(list) {
    const { verticalHighlightIndex, fields, selectionMode, collapsable, collapseOpenRow, getRowLink, onRowClick } =
      this.state;
    return list.map((item, i) => {
      const rowLink = getRowLink ? getRowLink(item) : undefined;
      return (
        <Fragment key={item?.id || i}>
          <ConnectionTableRowWrapper
            hover
            style={{
              cursor: collapsable ? 'pointer' : undefined,
            }}
            onClick={
              collapsable ? () => this.setState({ collapseOpenRow: collapseOpenRow === i ? undefined : i }) : undefined
            }
          >
            {selectionMode && (
              <ConnectionTableCell style={{ border: 'none' }} width={50}>
                {this.renderRowChecker(item)}
              </ConnectionTableCell>
            )}
            {fields.map(({ column = true, ...field }, j) => {
              if (column)
                return (
                  <ItemRenderer
                    key={j}
                    link={rowLink}
                    onClick={onRowClick}
                    item={item ? { ...item, __col: j, __row: i } : undefined}
                    field={field}
                    width={this._getColumnWidth(field)}
                    tableCellProps={{
                      style: {
                        backgroundColor: verticalHighlightIndex === j ? theme.palette.background.default : undefined,
                      },
                      onMouseOver: () => {
                        if (verticalHighlightIndex !== j) this.setState({ verticalHighlightIndex: j });
                      },
                    }}
                  />
                );
              return null;
            })}
          </ConnectionTableRowWrapper>
          {collapsable && (
            <TableRow>
              <ConnectionTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={fields.length}>
                <Collapse in={collapseOpenRow === i} timeout="auto" unmountOnExit>
                  {this.renderCollapse(item)}
                </Collapse>
              </ConnectionTableCell>
            </TableRow>
          )}
        </Fragment>
      );
    });
  }
  renderCollapse(item) {
    return null;
  }
  renderAllChecker({ nodes, totalCount, loading }) {
    const { selectionData } = this.state;

    return (
      <AllCheckControl
        loading={loading}
        nodes={nodes}
        totalCount={totalCount}
        selectionData={selectionData}
        onChange={(selectionData) =>
          this.setState({
            selectionData,
          })
        }
      />
    );

    // const checked = mode === 'EXCLUDE' && data.length === 0;
    //
    // return (
    //   <CheckboxField
    //     checked={checked}
    //     onChange={() => {
    //       if (checked) this.setState({ selectionData: { mode: 'INCLUDE', data: [] } });
    //       else this.setState({ selectionData: { mode: 'EXCLUDE', data: [] } });
    //     }}
    //   />
    // );
  }
  renderHeader(connection, stickyHeader) {
    const { nodes } = connection;
    const { fields, sortBy: { field: sortField, order } = {}, selectionMode } = this.state;

    return (
      <TableHead style={{ position: stickyHeader ? 'sticky' : 'static', top: 0, zIndex: 0, backgroundColor: 'white' }}>
        {this.renderTableHeadLabel(connection)}
        <TableRow>
          {selectionMode && (
            <ConnectionTableCell
              width={50}
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                backgroundColor: '#f6f6f6',
              }}
            >
              {this.renderAllChecker(connection)}
            </ConnectionTableCell>
          )}
          {fields.map((field, i) => {
            const { column = true, title, align = 'left', sortBy } = field;
            if (!column) return null;
            const cellContent = (
              <>
                {!!sortBy && align === 'center' && <SortableHeadAlignCenterPlaceholder />}
                <Box flex={1}>
                  {typeof title === 'function' ? (
                    title(field, nodes)
                  ) : (
                    <Typography variant={'h6'} noWrap align={align}>
                      {title}
                    </Typography>
                  )}
                </Box>
              </>
            );

            return (
              <ConnectionTableCell
                key={i}
                style={{
                  textAlign: align,
                  verticalAlign: 'middle',
                  width: this._getColumnWidth(field),
                  paddingRight: !!sortBy && align === 'right' ? 0 : undefined,
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: '#f6f6f6',
                }}
              >
                {!!sortBy ? (
                  <TableSortLabel
                    active={!!sortBy && sortField === sortBy}
                    direction={order ? `${order}`.toLowerCase() : undefined}
                    onClick={() => this.onSortingClick(sortBy)}
                  >
                    {cellContent}
                  </TableSortLabel>
                ) : (
                  cellContent
                )}
              </ConnectionTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
  renderTableHeadLabel(connection) {
    const { fields, selectionMode } = this.state;
    const hasLabel = !!fields.find((field) => field.label);

    if (!hasLabel) return null;
    return (
      <TableRow>
        {selectionMode && (
          <ConnectionTableCell
            width={50}
            style={{
              backgroundColor: 'transparent',
              borderBottom: '2px solid #999',
              position: 'relative',
            }}
          />
        )}
        {fields.map(({ label, width, align }, i) => {
          return (
            <ConnectionTableCell
              key={i}
              size={'small'}
              width={width}
              style={{
                backgroundColor: 'transparent',
                borderBottom: '2px solid #999',
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                textAlign: align,
                position: 'relative',
              }}
            >
              {label}
            </ConnectionTableCell>
          );
        })}
      </TableRow>
    );
  }

  _getColumnWidth({ type, width }) {
    if (!width && !!~['s3', 'image', 'images'].indexOf(type)) return 75;
    else if (!width && type === 'bool') return 50;
    return width;
  }
  renderColGroup() {
    const { fields } = this.state;
    return (
      <colgroup>
        {fields.map(({ column = true, width = '*', type }, i) => {
          if (!column) return null;
          if (type === 's3' && width === '*') width = 50;
          else if (type === 'bool' && width === '*') width = 50;
          return <col key={i} width={width} />;
        })}
      </colgroup>
    );
  }
  renderCreateButton({ nodes, totalCount, nextCursor }) {
    const { createUrl, createLabel } = this.state;
    return !!createUrl && <CreateButton to={{ pathname: createUrl }}>{createLabel}</CreateButton>;
  }
  renderRefreshButton({ loading }) {
    const { refreshable } = this.state;
    return (
      !!refreshable && (
        <Box ml={2}>
          <RefreshButton
            loading={loading}
            onClick={() => {
              this.refetch && this.refetch();
            }}
          />
        </Box>
      )
    );
  }
  renderMenu({ nodes, totalCount, nextCursor }) {
    const { menus = [], menuAnchorEl } = this.state;

    if (menus.length === 0) return null;

    return (
      <Box ml={2}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => this.setState({ menuAnchorEl: e.currentTarget })}
        >
          <Icon type={'material'} icon={'MoreVert'} />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          keepMounted
          onClose={() => this.setState({ menuAnchorEl: undefined })}
        >
          {menus.map(({ name, enable = (_) => true, onClick = (_) => _, renderName, render }, i) => {
            const enabled = enable.bind(this)({ nodes, totalCount, nextCursor });
            return !!render ? (
              render.bind(this)({
                scope: this,
                name,
                enable: enabled,
                disabled: !enable,
                onClick: onClick.bind(this),
                renderName,
                render,
                nodes,
                totalCount,
                nextCursor,
                i,
              })
            ) : (
              <MenuItem
                key={i}
                disabled={!enabled}
                onClick={() => {
                  this.setState({ menuAnchorEl: undefined });
                  onClick.bind(this)({ nodes, totalCount, nextCursor });
                }}
              >
                {!!renderName ? renderName({ totalCount, enable: enabled, disabled: !enabled }) : name}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export class StateParamsConnectionPageMarker extends ConnectionPageMarker {
  getQueryParams = () => {
    const { queryParams } = this.state;
    return queryParams ?? {};
  };
  getQueryParam = (key) => {
    const { queryParams } = this.state;
    return queryParams?.[key];
  };
  patchQueryParams = (nextParams) => {
    const { queryParams } = this.state;

    if (typeof nextParams === 'function') {
      nextParams = nextParams(queryParams);
    } else {
      nextParams = { ...queryParams, ...nextParams };
    }

    this.setState({
      queryParams: nextParams,
    });
    sleep(0).then(() => {
      this.setState(this.getQueryState());
    });
  };
}
