import { withStyles, CardContent } from '@material-ui/core';

const ConnectionCardHeader = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
}))(CardContent);

export default ConnectionCardHeader;
