import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useMeasure } from 'react-use';

const useStyles = makeStyles(() => ({
  root: { position: 'relative', display: 'inline-block' },
}));

const RatioBox = ({ children, ratio = 1, width = 'initial', height = 'initial', style = {} }) => {
  const classes = useStyles();
  const [ref, { width: _width, height: _height }] = useMeasure();

  return (
    <div className={classes.root} style={{ width, height, ...style }} ref={ref}>
      {height !== 'initial' ? (
        <div style={{ width: _height / ratio, height: _height }}>{children}</div>
      ) : (
        <div style={{ width: _width, height: _width * ratio }}>{children}</div>
      )}
    </div>
  );
};

export default RatioBox;
