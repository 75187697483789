import React from 'react';
import Taber from '../../components/Taber';
import SubscriptionListPage from './SubscriptionListPage';
import { translate } from '../../shared/translate';

export default (props) => {
  return (
    <div>
      <Taber
        currentTab={0}
        tabs={[
          {
            name: translate.all_subscribers || '所有訂閱列表',
          },
        ]}
      />
      <SubscriptionListPage {...props} />
    </div>
  );
};
