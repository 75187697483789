import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import SelectCombo from '../../SelectCombo';
import CheckboxField from '../../FormPageMaker/Fields/CheckboxField';
import SettingTEXT from './SettingTEXT';
import SettingRADIO from './SettingRADIO';
import SettingIMAGE from './SettingIMAGE';
import SettingHTML from './SettingHTML';
import SettingCommon from './SettingCommon';
import SettingNUMBER from './SettingNUMBER';
import SettingTEL from './SettingTEL';
import SettingDATE from './SettingDATE';
import TrashButton from '../../TrashButton';
import { convertMetaArray, convertMetaObject } from '../../../shared';

export const types = [
  {
    label: translate.single_line_text,
    value: 'TEXT',
    icon: <img src={require('../../../assets/icon/lines.svg')} alt={''} height={10} />,
    SettingModule: SettingTEXT,
  },
  {
    label: translate.multi_line_text,
    value: 'TEXT_MULTIPLE',
    icon: <img src={require('../../../assets/icon/mlines.svg')} alt={''} height={12} />,
    SettingModule: SettingTEXT,
  },
  {
    label: translate.email,
    value: 'EMAIL',
    SettingModule: SettingCommon,
  },
  {
    label: translate.number,
    value: 'NUMBER',
    SettingModule: SettingNUMBER,
  },
  {
    label: translate.radio_box,
    value: 'RADIO',
    icon: <img src={require('../../../assets/icon/radio.svg')} alt={''} height={12} />,
    SettingModule: SettingRADIO,
  },
  {
    label: translate.check_box,
    value: 'CHECKBOX',
    icon: <img src={require('../../../assets/icon/checkbox.svg')} alt={''} height={14} />,
    SettingModule: SettingRADIO,
  },
  {
    label: translate.list_single,
    value: 'LIST_SINGLE',
    icon: <img src={require('../../../assets/icon/dropdown.svg')} alt={''} height={12} />,
    SettingModule: SettingRADIO,
  },
  {
    label: translate.list_multiple,
    value: 'LIST_MULTIPLE',
    icon: <img src={require('../../../assets/icon/dropdown.svg')} alt={''} height={12} />,
    SettingModule: SettingRADIO,
  },
  {
    label: translate.image,
    value: 'IMAGE',
    icon: <img src={require('../../../assets/icon/image.svg')} alt={''} height={12} />,
    SettingModule: SettingIMAGE,
  },
  {
    label: translate.year,
    value: 'YEAR',
    SettingModule: SettingCommon,
  },
  {
    label: translate.month,
    value: 'MONTH',
    SettingModule: SettingCommon,
  },
  {
    label: translate.date,
    value: 'DATE',
    SettingModule: SettingDATE,
  },
  {
    label: translate.time,
    value: 'TIME',
    SettingModule: SettingCommon,
  },
  {
    label: translate.display_content,
    value: 'HTML',
    SettingModule: SettingHTML,
  },
  {
    label: translate.phone,
    value: 'TEL',
    SettingModule: SettingTEL,
  },
];

const FieldSettingField = ({
  onlyAllowFieldTypes,
  useFieldKey,
  disabled,
  value,
  enableFillInWhenRegisteringOption,
  onChange = (_) => _,
}) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={12} style={{ borderBottom: `1px solid #e1e1e1` }}>
        <Grid container alignItems={'center'}>
          <Grid item xs={6} md={4} lg={3} style={{ borderRight: `1px solid #e1e1e1` }}>
            <Box px={2} py={1}>
              <SelectCombo
                required
                disabled={disabled}
                value={types.find((opt) => opt.value === value?.type) || undefined}
                onChange={(v) =>
                  onChange({
                    ...value,
                    type: v?.value,
                  })
                }
                variant={'standard'}
                InputProps={{
                  disableUnderline: true,
                }}
                options={
                  onlyAllowFieldTypes?.length ? types.filter((opt) => onlyAllowFieldTypes.includes(opt.value)) : types
                }
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box px={2}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <CheckboxField
                    disabled={disabled}
                    text={translate.required_s}
                    checked={value?.required}
                    onChange={() => {
                      onChange({
                        ...value,
                        required: !value?.required,
                      });
                    }}
                  />
                </Grid>
                {enableFillInWhenRegisteringOption && (
                  <Grid item>
                    {(() => {
                      const checked = convertMetaObject(value?.metadata).visibleAtRegistration;
                      return (
                        <CheckboxField
                          text={translate.visible_at_registration || '註冊時填寫'}
                          checked={checked || disabled}
                          onChange={() => {
                            onChange({
                              ...value,
                              metadata: convertMetaArray({
                                ...convertMetaObject(value?.metadata),
                                visibleAtRegistration: !checked,
                              }),
                            });
                          }}
                        />
                      );
                    })()}
                  </Grid>
                )}
                <Grid item>
                  <CheckboxField
                    disabled={disabled}
                    text={translate.visible}
                    checked={value.visible}
                    onChange={() => {
                      onChange({
                        ...value,
                        visible: !value.visible,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={true} />
                {!disabled && (
                  <Grid item>
                    <TrashButton
                      color={'omniweDelete'}
                      style={{
                        color: theme.palette.secondary.main,
                      }}
                      disabled={disabled}
                      onClick={() => {
                        onChange();
                      }}
                    >
                      <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>
                        {translate.delete}
                      </Typography>
                    </TrashButton>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box p={2}>
          {(() => {
            const Module = types.find((type) => type?.value === value?.type)?.SettingModule;
            if (Module)
              return <Module useFieldKey={useFieldKey} disabled={disabled} value={value} onChange={onChange} />;
            return null;
          })()}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FieldSettingField;
