import React from 'react';
import Taber from '../../components/Taber';
import useQueryParams from '../../hooks/useQueryParams';
import LayoutFront from './LayoutFront';
import LayoutKitchen from './LayoutKitchen';
import { Box } from '@material-ui/core';
import { translate } from '../../shared/translate';
import LayoutLabel from './LayoutLabel';
import LayoutReceipt from './LayoutReceipt';
import LayoutOrder from './LayoutOrder';
import LayoutReturn from './LayoutReturn';
import LayoutQRCodeOrdering from './LayoutQRCodeOrdering';

const ItemReceiptTemplate = () => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;
  const shopType = localStorage.getItem('shopType');

  const tabs = [
    {
      name: translate.receipt_print || '收據單',
      component: LayoutReceipt,
    },
    shopType === 'FOOD_BEVERAGES' && {
      name: translate.front_print || '樓面單',
      component: LayoutFront,
    },
    {
      name: translate.kitchen_print || '廚房單',
      component: LayoutKitchen,
    },
    shopType === 'FOOD_BEVERAGES' && {
      name: translate.label_print || '貼紙單',
      component: LayoutLabel,
    },
    {
      name: translate.purchase_order || '訂單',
      component: LayoutOrder,
    },
    {
      name: translate.qr_code_ordering_print || 'QR Code 下單',
      component: LayoutQRCodeOrdering,
    },
    {
      name: translate.return_print || '退貨單',
      component: LayoutReturn,
    },
  ].filter((_) => _);
  const Module = tabs?.[tab]?.component || Box;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery({
            tab,
          });
        }}
        tabs={tabs}
      />
      <Module />
    </div>
  );
};

export default ItemReceiptTemplate;
