import React from 'react';
import { Grid, InputAdornment, Button, Typography, IconButton, withStyles } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { Icon } from '../../components/IconRender';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import TextareaField from '../../components/FormPageMaker/Fields/TextareaField';
import CheckoutItemField from './CheckoutItemField';
import SelectComboCustomer from '../../components/SelectCombo/SelectComboCustomer';

const styles = (theme) => ({
  createButton: {
    backgroundColor: '#FF4731',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#DF3D2A',
      boxShadow: 'none',
    },
  },
});

export default withStyles(styles)(
  ({ classes, isSubmitting, setFieldValue, resetForm, values: { customer, items, remarks, checkoutURL } = {} }) => {
    return (
      <>
        <Grid container direction={'column'} spacing={3}>
          <Grid item>
            <Typography variant={'h6'}>{translate.customer}</Typography>
            <SelectComboCustomer
              disabled={isSubmitting}
              value={customer}
              helperText={translate.guest_checkout_message}
              onChange={(customer) => {
                setFieldValue('customer', customer);
              }}
            />
          </Grid>
          <Grid item>
            <CheckoutItemField
              value={items}
              onChange={(items) => setFieldValue('items', items)}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <Typography variant={'h6'}>{translate.remark}</Typography>
            <TextareaField
              disabled={isSubmitting}
              value={remarks}
              onChange={(v) => {
                setFieldValue('remarks', v);
              }}
              className={classes.inputField}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <Button
                  variant={'contained'}
                  disabled={isSubmitting}
                  onClick={() => resetForm()}
                  className="normal-btn"
                >
                  {translate.reset}
                </Button>
              </Grid>
              <Grid item>
                <Button variant={'contained'} disabled={isSubmitting} className="omniew-red-btn" type="submit">
                  {translate.create_checkout}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item md={8} xs={12}>
                <InputField
                  disabled
                  value={checkoutURL || ''}
                  className={classes.inputField}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        <Typography>{translate.payment_url}</Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <IconButton
                          disabled={!checkoutURL}
                          onClick={() => {
                            navigator.clipboard.writeText(checkoutURL);
                          }}
                        >
                          <Icon icon={'faCopy'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  },
);
