import React, { useState } from 'react';
import HTMLField from '../../components/FormPageMaker/Fields/HTMLField';
import { Box, Tabs, Tab, Typography } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { textColor } from '../../theme';

const CouponDescription = ({ descriptionHK, descriptionENG, disabled, onChangeHK, onChangeENG }) => {
  const [tab, setTab] = useState('hk');

  const tabBackgroundColor = '#f2f2f2';
  const tabBorderColor = '#c4c4c4';

  function style(lang) {
    return {
      backgroundColor: tab === lang ? '#fff' : tabBackgroundColor,
      borderLeft: tab === lang ? `1px solid ${tabBorderColor}` : undefined,
      borderTop: tab === lang ? `1px solid ${tabBorderColor}` : undefined,
      borderRight: tab === lang ? `1px solid ${tabBorderColor}` : undefined,
      borderRadius: tab === lang ? '12px 12px 0 0' : undefined,
    };
  }

  return (
    <>
      <Typography variant={'h6'}>{translate.coupons_language_description}</Typography>
      <Box>
        <Tabs
          style={{
            backgroundColor: tabBackgroundColor,
            borderRadius: '12px 12px 0 0',
          }}
        >
          <Tab
            label={
              <span style={{ color: tab === 'hk' ? undefined : textColor }}>
                {translate.traditional_chinese || '繁體中文'}
              </span>
            }
            style={style('hk')}
            onClick={() => setTab('hk')}
          />
          <Tab
            label={<span style={{ color: tab === 'eng' ? undefined : textColor }}>{translate.eng || '英文'}</span>}
            style={style('eng')}
            onClick={() => setTab('eng')}
          />
        </Tabs>
      </Box>

      {tab === 'hk' ? (
        <HTMLField
          height={150}
          style={{ borderRadius: '0 0 10px 10px' }}
          disabled={disabled}
          value={descriptionHK}
          onChange={(v) => onChangeHK(v)}
        />
      ) : (
        <HTMLField
          height={150}
          style={{ borderRadius: '0 0 10px 10px' }}
          disabled={disabled}
          value={descriptionENG}
          onChange={(v) => onChangeENG(v)}
        />
      )}
    </>
  );
};

export default CouponDescription;
