import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import StatusBadge from 'components/StatusBadge';
import { Grid, Typography } from '@material-ui/core';
import ExportButton from './ExportButton';
import ImportButton from './ImportButton';
import { StockTakeStatus } from '../../../shared/omniwe-types';
import { convertMetaObject } from '../../../shared';
import { checkPermissionV2 } from '../../../components/PermissionMask';

export default class extends ConnectionPageMaker {
  upload = undefined;
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    getRowLink: ({ id }) => ({ pathname: '/stock_take/' + id }),
    hasQSearch: true,
    qFields: ['referenceNo'],
    fields: [
      {
        title: translate.created_at,
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
        filterChannel: 'q',
        sortBy: 'createdAt',
      },
      {
        title: translate.reference_no,
        fieldName: 'referenceNo',
      },
      {
        title: translate.operator,
        value: (row) => row?.staff?.name,
      },
      {
        title: translate.warehouse,
        value: ({ warehouse }) => (warehouse || {}).name,
      },
      {
        title: translate.stocktake_type,
        width: 200,
        align: 'center',
        render: ({ metadata }) => {
          const replace = !!+convertMetaObject(metadata)?.replace;
          return <Typography>{replace ? translate.stocktake_replace : translate.stocktake_partial}</Typography>;
        },
      },
      {
        width: 120,
        title: translate.status,
        align: 'center',
        fieldName: 'status',
        type: 'option',
        options: Object.keys(StockTakeStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={StockTakeStatus} />,
          value: key,
        })),
        filterChannel: 'q',
        filter: 'status',
      },
      {
        title: translate.other_actions,
        noLink: true,
        width: 150,
        align: 'center',
        render: ({ id }) => (
          <Grid container wrap={false} spacing={1} justifyContent={'center'}>
            {checkPermissionV2('exportStockTake') && (
              <Grid item>
                <ExportButton id={id} />
              </Grid>
            )}
            {checkPermissionV2('importStockTake') && (
              <Grid item>
                <ImportButton id={id} />
              </Grid>
            )}
          </Grid>
        ),
      },
    ],
    menus: [],
    selectionMode: false,
  };

  getData = ({ node } = {}) => {
    const { stocktakes } = node || {};
    return stocktakes;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
}
