import { Box, Card, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import uuid from 'react-uuid';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import BundleProductItem from './BundleProductItem';
import { translate, translateFormat } from '../../../shared/translate';
import HTMLField from '../../../components/FormPageMaker/Fields/HTMLField';
import FormRow from '../../../components/FormRow';
import Dnd from '../../../components/DND';
import { Icon } from '../../../components/IconRender';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import TrashButton from '../../../components/TrashButton';
import { binColor } from '../../../theme';
import ProductSelection from './ProductSelection';
import CollectionSelection from './CollectionSelection';
import TabsCardDialog from '../../../components/TabsCardDialog';
import _ from 'lodash';
import SystemButton from '../../../components/SystemButton';

const useStyles = makeStyles((theme) => ({
  removeBtn: {
    '&.Mui-disabled': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const SectionItem = ({ value, disabled, onChange, productOrderNum, productTotalNum }) => {
  const classes = useStyles();
  const { label, description, options = [] } = value || {};
  const [visible, setVisible] = useState(false);

  return (
    <Card key={productTotalNum} style={{ width: '100%', borderRadius: 9 }}>
      <Box px={4} py={1}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={'auto'} md={'auto'} style={{ alignSelf: 'center' }}>
            <Typography variant="h6" color={'secondary'}>
              {translate.single_product} {productOrderNum}
            </Typography>
          </Grid>
          <Grid item>
            <TrashButton
              color={'omniweDelete'}
              style={{
                color: disabled ? '#ddd' : '#707070',
              }}
              className={classes.removeBtn}
              disabled={disabled}
              onClick={() => onChange()}
            >
              <Typography variant="h6" style={{ color: disabled ? '#ddd' : '#707070' }}>
                {translate.delete}
              </Typography>
            </TrashButton>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box px={4} py={2}>
        <Grid container justifyContent={'space-between'}>
          <FormRow
            xs={12}
            md={12}
            title={
              <Typography variant="subtitle">
                {translate.bundle_section_title} <span style={{ color: binColor }}>{' *'}</span>
              </Typography>
            }
            titleStyle={{ marginTop: 5 }}
            variant={'subtitle1'}
          >
            <InputField
              required={true}
              value={label}
              disabled={disabled}
              placeholder={translateFormat(translate.option_name_placeholder, {
                index: productOrderNum,
                total: productTotalNum,
              })}
              onChange={(e) => {
                onChange({ ...value, label: e?.target?.value });
              }}
            />
          </FormRow>

          <FormRow xs={12} md={12} title={translate.description} variant={undefined}>
            <HTMLField
              height={150}
              disabled={disabled}
              value={description}
              onChange={(v) => {
                onChange({ ...value, description: v });
              }}
            />
          </FormRow>
        </Grid>
      </Box>
      <Divider />

      <Box pl={1} pr={4} pt={4}>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="h6" style={{ paddingLeft: 25 }}>
              {translate.product_options || '產品選項'}
            </Typography>
          </Grid>
          <Grid item>
            <SystemButton color={'omniewRed'} onClick={() => setVisible(!visible)}>
              {translate.add_product_options || '添加產品選項'}
            </SystemButton>
            <TabsCardDialog
              visible={visible}
              onOpen={() => setVisible(!visible)}
              tabs={[
                {
                  label: translate.product_name || '產品名稱',
                  content: (
                    <ProductSelection
                      onClose={() => setVisible(false)}
                      onComplete={(products) => {
                        const filteredProducts = _.filter(products, (product) => {
                          return !_.some(options, { product: product.product });
                        });

                        const mergedOptions = _.concat(options, filteredProducts);

                        onChange({
                          ...value,
                          options: mergedOptions,
                        });
                      }}
                    />
                  ),
                },
                {
                  label: translate.product_collections || '產品分類',
                  content: (
                    <CollectionSelection
                      onClose={() => setVisible(false)}
                      onComplete={(products) => {
                        const filteredProducts = _.filter(products, (product) => {
                          return !_.some(options, { product: product.product });
                        });

                        const mergedOptions = _.concat(options, filteredProducts);

                        onChange({
                          ...value,
                          options: mergedOptions,
                        });
                      }}
                    />
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>

      <Box p={4} pl={1}>
        <Dnd
          disabled={disabled}
          items={options}
          direction={'column'}
          spacing={2}
          onChange={(options) => {
            onChange({ ...value, options });
          }}
          renderItem={(option, i, { nonDraggableProps }) => {
            return (
              <>
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item>
                    <Icon icon={IconDragVertical} type={'svg'} style={{ cursor: 'pointer' }} />
                  </Grid>
                  <Grid item xs={true} {...nonDraggableProps}>
                    <BundleProductItem
                      value={option}
                      disabled={disabled}
                      onChange={(e) => {
                        if (!e) return onChange({ ...value, options: options?.filter((_, j) => j !== i) });
                        onChange({
                          ...value,
                          options: options?.map((option, j) => {
                            if (j === i) {
                              return { id: uuid(), ...e };
                            }
                            return option;
                          }),
                        });
                      }}
                    />
                  </Grid>
                  {i !== options?.length - 1 && (
                    <Grid item xs={12}>
                      <Typography align="center">{translate.or}</Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            );
          }}
        />
        {!value?.options?.length && (
          <Typography style={{ textAlign: 'center' }}>
            {translate.options_not_available || '尚未添加產品選項'}
          </Typography>
        )}
      </Box>
    </Card>
  );
};

export default SectionItem;
