import { hasCombinations } from './hasCombinations';
import { fixVariationOrder } from './fixVariationOrder';
import _ from 'lodash';
import { toInputMedia } from '../../shared';
import ean13Fix from './ean13Fix';

export function getSubmitVariations({ sku, variations, combinations, barcodes }) {
  const submitVariations = variations.map(({ id, ...variation } = {}) =>
    _.omit(
      {
        combination: [],
        unitPrice: 0,
        ignoreStock: false,
        ...variation,
        lowStock: {
          remind: !!variation?.lowStock?.remind,
          quantity: variation?.lowStock?.quantity || null,
        },
        sku: hasCombinations(combinations) ? variation.sku || sku : sku,
        suggestedRetailPrice: +(variation || {}).suggestedRetailPrice || 0,
        cost: !!(variation || {}).cost ? (variation || {}).cost : 0,
        id: id || undefined,
        medias: (variation.medias || []).map(toInputMedia).filter((_) => _),
        quantity: variation.quantity ? variation.quantity - 0 : undefined,
        stockLocations: (variation || {}).stockLocations || [],
        barcode: null,
        barcodes: (hasCombinations(combinations) ? variation?.barcodes : barcodes)?.map(ean13Fix) ?? [],
        hashtags: (variation || {}).hashtags || [],
      },
      ['averageCost'],
    ),
  );

  if (hasCombinations(combinations)) return fixVariationOrder(submitVariations, combinations);
  return submitVariations;
}
