import React from 'react';
import { translate } from '../../../shared/translate';
import { useHistory } from 'react-router-dom';
import SystemButton from '../../../components/SystemButton';
import { Add } from '@material-ui/icons';

export default ({
  isSubmitting,
  loading,
  values: { id, referenceNo, total, items, shippingProvider, shippingAddress, status } = {},
}) => {
  const history = useHistory();
  return (
    <SystemButton
      disabled={status === 'PENDING' || isSubmitting || loading}
      size={'medium'}
      variant="contained"
      className="normal-btn"
      onClick={() => {
        history.push({
          pathname: '/delivery_notes/new',
          state: {
            order: {
              id,
              referenceNo,
              total,
              shippingAddress,
              shippingProvider,
              items: (items || []).map((item) => ({
                ...item,
                orderItem: items,
              })),
            },
          },
        });
      }}
      startIcon={<Add />}
    >
      {translate.new_delivery_note}
    </SystemButton>
  );
};
