import React from 'react';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import { Box, Grid, Typography } from '@material-ui/core';
import { translate } from '../../shared/translate';
import FormRow from '../../components/FormRow';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import HTMLField from '../../components/FormPageMaker/Fields/HTMLField';
import TrashButton from '../../components/TrashButton';

const DetailsCard = ({ loading, values, values: { details = [] } = {}, setFieldValue, isSubmitting }) => {
  const update = () => {
    setFieldValue('details', details);
  };

  return (
    <StyledConfigList>
      {details.map((detail, i) => (
        <Grid key={`${i}`} item xs={12}>
          <Box mx={1.5}>
            <Grid container>
              <FormRow title={translate.title}>
                <Grid container spacing={1}>
                  <Grid item xs="true">
                    <InputField
                      type={'text'}
                      value={detail.title}
                      onChange={(e) => {
                        detail.title = e.target.value;
                        update();
                      }}
                      required={true}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <TrashButton
                      size="small"
                      onClick={() => {
                        details.splice(i, 1);
                        update();
                      }}
                    />
                  </Grid>
                </Grid>
              </FormRow>
              <FormRow
                title={
                  <Grid container spacing={1} alignItems={'center'}>
                    <Grid item>
                      <Typography variant={'h6'}>{translate.content}</Typography>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*  <AutoGenerateButton*/}
                    {/*    disabled={isSubmitting || !detail?.title}*/}
                    {/*    prefix={'用以下內容生成產品簡介：'}*/}
                    {/*    getPrompt={() => {*/}
                    {/*      const prompt = getProductDescriptionPrompt(values);*/}
                    {/*      return [*/}
                    {/*        `以"${detail?.title}"為重點設計文本`,*/}
                    {/*        !!detail.content && `現有文本:\n\`\`\`\n${extractTextFromHTML(detail.content)}\n\`\`\``,*/}
                    {/*        `其他產品資料:\n${prompt}`,*/}
                    {/*      ]*/}
                    {/*        .filter((_) => _)*/}
                    {/*        .join('\n\n');*/}
                    {/*    }}*/}
                    {/*    suffix={'請以HTML格式輸出簡介，整理好一個方便顧客閱讀的格式。'}*/}
                    {/*    onChange={(content) => {*/}
                    {/*      detail.content = content;*/}
                    {/*      update();*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</Grid>*/}
                  </Grid>
                }
              >
                <HTMLField
                  disabled={isSubmitting}
                  value={detail.content}
                  onChange={(content) => {
                    detail.content = content;
                    update();
                  }}
                />
              </FormRow>
            </Grid>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12}>
        <AddButton
          disabled={loading || isSubmitting}
          onClick={() => {
            details.push({ title: '', content: '' });
            update();
          }}
          title={translate.new_content}
        />
      </Grid>
    </StyledConfigList>
  );
};

export default DetailsCard;
