import React, { useState } from 'react';
import SystemButton from '../../../components/SystemButton';
import { translate } from '../../../shared/translate';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { USER_COUPON_APPLY } from './query';
import { client } from '../../../shared/apollo';
import confirmation from '../../../shared/confirmation';

const UseButton = ({ id, disabled, onCompleted = (_) => _ }) => {
  const [loading, setLoading] = useState(false);

  return (
    <SystemButton
      className="normal-btn"
      variant="contained"
      loading={loading}
      disabled={loading || disabled}
      onClick={async () => {
        try {
          if (await confirmation(translate.use_customer_coupon_confirmation)) {
            setLoading(true);
            await client.mutate({
              mutation: USER_COUPON_APPLY,
              variables: {
                couponId: id,
              },
            });
            onCompleted();
          }
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
        }
      }}
    >
      {translate.use}
    </SystemButton>
  );
};

export default UseButton;
