import React, { memo } from 'react';
import { Box, Button, Grid, TableCell, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import TooltipContent from '../../../../components/TooltipContent';
import { tooltipPlacement } from '../../../../theme';
import { Icon } from '../../../../components/IconRender';
import { ReactComponent as IconTips } from '../../../../assets/icon/tips.svg';

const StyledTableHeaderCell = withStyles((theme) => ({
  // TODO: ShopProduct.display
  // head: { minWidth: 60 }
  head: { minWidth: 100 },
  sizeSmall: {
    padding: 6,
  },
}))(TableCell);

const InertTableHead = ({ disabled, onSKUClick = (_) => _, onBarcodeClick = (_) => _ }) => (
  <>
    <colgroup>
      <col width={200} />
      <col width={400} />
      <col width={'*'} />
      <col width={140} />
      <col width={140} />
      <col width={140} />
      <col width={103} />
      <col width={103} />
      {/* TODO: ShopProduct.display
      <col width={60} />
      <col width={88} /> */}
    </colgroup>
    <TableHead>
      <TableRow>
        <StyledTableHeaderCell>
          <Grid container direction={'row'} spacing={2} alignItems={'center'}>
            <Grid item>
              <Typography1Title>{translate.product_sku} *</Typography1Title>
            </Grid>
            <Grid item>
              <Button
                variant={'contained'}
                className="omniew-red-btn"
                type={'button'}
                size={'small'}
                disabled={disabled}
                onClick={onSKUClick}
              >
                {translate.quick_generate}
              </Button>
            </Grid>
          </Grid>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Grid container direction={'row'} spacing={2} alignItems={'center'}>
            <Grid item>
              <Typography1Title>{translate.barcode}</Typography1Title>
            </Grid>
            <Grid item>
              <Button
                variant={'contained'}
                className="omniew-red-btn"
                type={'button'}
                size={'small'}
                disabled={disabled}
                onClick={onBarcodeClick}
              >
                {translate.quick_generate}
              </Button>
            </Grid>
          </Grid>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Typography1Title>{translate.variant}</Typography1Title>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Typography1Title>{translate.suggested_retail_price}</Typography1Title>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Typography1Title>{translate.price}*</Typography1Title>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Typography1Title>{translate.stock}*</Typography1Title>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Box style={{ display: 'flex' }}>
            <Typography1Title>{translate.low_inventory_alert}</Typography1Title>
            <Tooltip
              title={
                <TooltipContent
                  points={[<Typography variant="body2">{translate.low_inventory_alert_tips}</Typography>]}
                />
              }
              placement={tooltipPlacement}
            >
              <span>
                <Icon
                  type={'svg'}
                  icon={IconTips}
                  viewBox="0 0 17 17"
                  style={{
                    color: 'transparent',
                    verticalAlign: 'text-bottom',
                    fontSize: '1.3rem',
                  }}
                />
              </span>
            </Tooltip>
          </Box>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Typography1Title>{translate.visibility}</Typography1Title>
        </StyledTableHeaderCell>
        {/* TODO: ShopProduct.display
        <StyledTableHeaderCell>
          <Typography1Title>{translate.activation}</Typography1Title>
        </StyledTableHeaderCell>
        <StyledTableHeaderCell>
          <Typography1Title>{translate.visible}</Typography1Title>
        </StyledTableHeaderCell> */}
      </TableRow>
    </TableHead>
  </>
);

export default memo(InertTableHead, (prev, next) => prev.disabled === next.disabled);

const Typography1Title = ({ children }) => {
  return <Typography style={{ fontSize: '0.98em' }}>{children}</Typography>;
};
