import React from 'react';
import PromisifyModal from '../../../components/PromisifyModal';
import { translate } from 'shared/translate';
import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import SelectComboCollection from '../../../components/SelectCombo/SelectComboCollection';

export default class CollectionModal extends PromisifyModal {
  renderHeader() {
    return <DialogTitle></DialogTitle>;
  }
  renderBody({ values, setFieldValue, isSubmitting }) {
    return (
      <DialogContent style={{ width: '80vw', maxWidth: 500, paddingBottom: 24 }}>
        <Typography variant={'h6'}>{translate.designated_collection}</Typography>
        <SelectComboCollection
          disablePortal={false}
          value={values.collection}
          onChange={(v) => {
            setFieldValue('collection', v);
          }}
        />
      </DialogContent>
    );
  }
}
