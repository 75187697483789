import React from 'react';
import moment from 'moment';
import SelectField from '../../../FormPageMaker/Fields/SelectField';
import { translate } from '../../../../shared/translate';
import CustomFieldWrapper from '../CustomFieldWrapper';
import { convertMetaObject } from '../../../../shared';

const MonthField = ({ disabled, value, onChange, field = {} }) => {
  const { metadata, name, required } = field ?? {};
  const { remarks } = convertMetaObject(metadata);

  return (
    <CustomFieldWrapper name={name} remarks={remarks} required={required}>
      <SelectField
        required={required}
        disabled={disabled}
        value={value || ''}
        onChange={(e) => onChange(e?.target?.value)}
        options={Array(12)
          .fill(undefined)
          .map((__, i) => ({
            label:
              translate[
                moment(i + 1, 'MM')
                  .format('MMMM')
                  .toLowerCase()
              ],
            value: moment(i + 1, 'MM')
              .locale('en')
              .format('MMM')
              .toUpperCase(),
          }))}
      />
    </CustomFieldWrapper>
  );
};

export default MonthField;
