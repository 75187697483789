import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { translate } from '../../../../shared/translate';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '../../../../components/IconRender';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';

const StockField = ({
  disabled,
  ignoreStock: _ignoreStock,
  onIgnoreStockChange = (_) => _,
  quantity: _quantity,
  onQuantityChange = (_) => _,
}) => {
  const [ignoreStock, setIgnoreStock] = useState(_ignoreStock);
  const [quantity, setQuantity] = useState(_quantity);

  useEffect(() => {
    if (!!ignoreStock !== !!_ignoreStock) {
      onIgnoreStockChange(ignoreStock);
    }
  }, [ignoreStock]);

  useEffect(() => {
    if (quantity !== _quantity) {
      onQuantityChange(quantity);
    }
  }, [quantity]);

  useEffect(() => {
    if (quantity !== _quantity) {
      setQuantity(_quantity);
    }
  }, [_quantity]);

  useEffect(() => {
    if (!!ignoreStock !== !!_ignoreStock) {
      setIgnoreStock(_ignoreStock);
    }
  }, [_ignoreStock]);

  return (
    <InputField
      disabled={disabled || !!ignoreStock}
      type={'number'}
      value={quantity}
      onChange={(e) => {
        setQuantity(e.target.value);
      }}
      required={true}
      // eslint-disable-next-line
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'} disableTypography>
            <Tooltip title={translate.infinite_stock}>
              <IconButton
                disabled={disabled}
                size={'small'}
                color={ignoreStock ? 'primary' : undefined}
                onClick={() => {
                  setIgnoreStock(!ignoreStock);
                }}
              >
                <Icon icon={'faInfinity'} />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default StockField;
