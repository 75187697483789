import React from 'react';
import LSkeleton from '../../LSkeleton';
import { groupBy } from 'lodash';
import SelectCombo from '../../SelectCombo';
import HongKongDistrictData from 'hong-kong-district-data';
import { translate } from '../../../shared/translate';
import { Typography } from '@material-ui/core';

export default ({ loading, value, onChange = (_) => {}, ...props }) => {
  if (loading) return <LSkeleton height={30} />;
  const options = Object.entries(groupBy(HongKongDistrictData, 'city')).reduce((reducer, [city, data]) => {
    reducer.push({
      label: (
        <Typography
          variant={'h6'}
          align={'center'}
          style={{
            color: '#000000',
          }}
        >
          {translate.districts[city] || city}
        </Typography>
      ),
      disabled: true,
    });
    data.forEach((datum) => {
      reducer.push({
        label: translate.districts[datum?.district] || datum?.name,
        value: datum?.district,
        group: translate.districts[datum?.state] || datum?.state,
      });
    });
    return reducer;
  }, []);

  return (
    <SelectCombo
      options={options}
      value={options.find((opt) => opt?.value === value)}
      onChange={(v) => onChange(v?.value)}
      loading={loading}
      {...props}
    />
  );
};
