import React from 'react';

export const PopoverWrapper = ({ children, box, visible, style }) => {
  const windowWidth = window?.innerWidth ?? 1000;

  const boxX = box?.x ?? 0;
  const isRight = boxX < windowWidth / 2;

  return (
    <div
      style={{
        position: 'fixed',
        minWidth: 250,
        maxWidth: 350,
        width: '30vw',
        transition: 'all 0.25s',
        zIndex: 1101,
        transform: 'translateX(0)',
        top: '50%',
        ...(visible
          ? {
              left: boxX,
              transform: `translate(${isRight ? '0' : '-100%'}, -50%)`,
            }
          : {
              left: '100vw',
            }),
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default PopoverWrapper;
