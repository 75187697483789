import React from 'react';
import Taber from '../../components/Taber';
import useQueryParams from '../../hooks/useQueryParams';
import { translate } from '../../shared/translate';
import { Box } from '@material-ui/core';
import NavigationTemplate from './NavigationTemplate';

const ItemNavigationTemplate = () => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;

  const tabs = [
    {
      name: translate.navigation_template,
      component: NavigationTemplate,
    },
  ].filter((_) => _);
  const Module = tabs?.[tab]?.component || Box;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery({
            tab,
          });
        }}
        tabs={tabs}
      />
      <Module />
    </div>
  );
};

export default ItemNavigationTemplate;
