import React from 'react';
import ListView from './ListView';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';
import useQueryParams from '../../hooks/useQueryParams';

export default (props) => {
  const [query, setQuery] = useQueryParams(),
    tab = +query?.tab || 0;

  const tabs = [
    {
      name: translate.all_returns || '所有退貨',
      filter: {},
    },
    {
      name: translate.status_return_pending || '等待退貨',
      filter: {
        status: {
          operator: 'IN',
          value: ['PENDING'],
        },
      },
    },
    {
      name: translate.awaiting_confirmation,
      filter: {
        status: {
          operator: 'IN',
          value: ['APPROVED'],
        },
      },
    },
    {
      name: translate.completed,
      filter: {
        status: {
          operator: 'IN',
          value: ['COMPLETED'],
        },
      },
    },
    {
      name: translate.cancel,
      filter: {
        status: {
          operator: 'IN',
          value: ['CANCELLED', 'REJECTED'],
        },
      },
    },
  ];

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          setQuery({
            tab,
          });
        }}
        tabs={tabs}
      />
      <Box>
        <ListView {...props} filter={tabs[tab]?.filter ?? {}} />
      </Box>
    </div>
  );
};
