import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import BundleProductOrderItemContent from './BundleProductOrderItemContent';
import OrderItemContent from './OrderItemContent';
import StyledConfigList from '../../../components/StyledConfigList';
import FormRow from '../../../components/FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';

const ItemRowField = ({ value, onChange = (val) => {}, disabled }) => {
  return (
    <>
      <TableRow>
        <TableCell width={1}>
          <CheckboxField
            disabled={disabled}
            checked={value?.active}
            onClick={() =>
              onChange({
                ...value,
                active: !value?.active,
              })
            }
          />
        </TableCell>
        {(() => {
          if (value?.orderItem?.bundleProduct) {
            return <BundleProductOrderItemContent value={value} />;
          } else {
            return <OrderItemContent value={value} />;
          }
        })()}
        <TableCell width={1} align={'center'}>
          <Typography>1</Typography>
        </TableCell>
        <TableCell width={1} align={'center'}>
          <CheckboxField
            disabled={disabled || !value?.active}
            checked={value?.resalable}
            onClick={() =>
              onChange({
                ...value,
                resalable: !value?.resalable,
              })
            }
          />
        </TableCell>
      </TableRow>
      {value?.active && (
        <TableRow>
          <TableCell />
          <TableCell colSpan={99}>
            <StyledConfigList>
              <FormRow title={translate.return_reason || '退貨原因'} dense>
                <InputField
                  disabled={disabled}
                  value={value?.reason}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      reason: e?.target?.value,
                    })
                  }
                />
              </FormRow>
            </StyledConfigList>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ItemRowField;
