import React from 'react';
import { Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { Skeleton } from '@material-ui/lab';
import { PriceFormat } from '../../../shared';

const TotalSales = ({ loading, totalAmount }) => {
  if (loading) return <Skeleton />;
  if (typeof totalAmount === 'number')
    return (
      <div style={{ paddingRight: 8 }}>
        <Typography variant={'caption'}>
          {translate.total_amount}: {PriceFormat(totalAmount)}
        </Typography>
      </div>
    );

  return <></>;
};

export default TotalSales;
