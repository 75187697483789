import React, { useState } from 'react';
import InputField from './InputField';
import SelectField from './SelectField';
import { translate } from '../../../shared/translate';
import LSkeleton from '../../LSkeleton';
import _ from 'lodash';

const options = [
  { label: translate.years, value: 'years' },
  { label: translate.months, value: 'months' },
  { label: translate.weeks, value: 'weeks' },
  { label: translate.days, value: 'days' },
  { label: translate.hours, value: 'hours' },
  { label: translate.minutes, value: 'minutes' },
];

export const getDuration = (preset) => {
  return options.reduce(
    (reducer, { value }) => {
      reducer[value] = preset[value] ?? 0;
      return reducer;
    },
    {
      seconds: 0,
    },
  );
};

const DurationField = ({
  loading,
  className = '',
  style = {},
  value = {},
  onChange,
  inputProps,
  InputProps,
  disabled,
  disabledInfinity,
  onlyAllows = [],
  ...props
}) => {
  const [type, setType] = useState(
    (Object.entries(value).find(([key, value]) => options.find((opt) => opt.value === key) && !!value) || {})[0] ||
      'days',
  );

  const isInfinity = _.isNil(value?.seconds);
  const val = isInfinity ? '∞' : value[type];

  if (loading) return <LSkeleton height={30} />;

  const selectFieldProps = {
    value: isInfinity ? '∞' : type,
    disabled,
    onChange: (e) => {
      const newType = e.target.value;
      setType(newType);

      if (newType === '∞')
        onChange(
          options.reduce(
            (reducer, { value }) => ({
              ...reducer,
              [value]: 0,
            }),
            { seconds: null },
          ),
        );
      else
        onChange(
          options.reduce(
            (reducer, { value }) => {
              if (newType === value) reducer[value] = val;
              else reducer[value] = 0;
              return reducer;
            },
            { seconds: 0 },
          ),
        );
    },
    options: [...options, !disabledInfinity && { label: translate.indefinitely, value: '∞' }].filter((opt) => {
      if (!opt) return false;
      if (onlyAllows?.length) {
        return onlyAllows.includes(opt.value);
      } else return true;
    }),
  };

  if (isInfinity) return <SelectField {...selectFieldProps} {...props} />;
  return (
    <InputField
      type={'number'}
      // eslint-disable-next-line
      inputProps={{
        ...inputProps,
        step: 1,
        min: 0,
      }}
      value={val}
      disabled={disabled}
      onChange={(e) => {
        const val = e.target.value;
        onChange(
          options.reduce(
            (reducer, { value }) => {
              if (type === value) reducer[value] = val;
              else reducer[value] = 0;
              return reducer;
            },
            { seconds: 0 },
          ),
        );
      }}
      // eslint-disable-next-line
      InputProps={{
        ...InputProps,
        endAdornment: (
          <SelectField
            style={{ width: 150 }}
            {...selectFieldProps}
            variant={'standard'}
            // eslint-disable-next-line
            InputProps={{
              style: { border: 'none' },
            }}
          />
        ),
      }}
      {...props}
    />
  );
};

export default DurationField;
