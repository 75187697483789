import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Icon } from '../../IconRender';
import { ReactComponent as IconTag } from '../../../assets/icon/tag.svg';
import { ReactComponent as IconSearchMessage } from '../../../assets/icon/search-message.svg';
import { ReactComponent as IconPicture } from '../../../assets/icon/picture.svg';
import { ReactComponent as IconLink } from '../../../assets/icon/link.svg';
import { ReactComponent as IconInfo } from '../../../assets/icon/info.svg';
import useBreakPoint from '../../../../src/components/useBreakPoint';

const TabButtons = ({ tab, onChange = (_) => _, disabled: _disabled }) => {
  const breakPoint = useBreakPoint();
  const isMobile = ['xs', 'sm'].includes(breakPoint);

  return (
    <Grid container justify={'space-between'}>
      {[
        {
          value: 'tag',
          icon: (
            <Icon
              type={'svg'}
              icon={IconTag}
              viewBox={'0 0 27 27'}
              style={{ fontSize: isMobile ? '30px' : undefined }}
            />
          ),
        },
        {
          value: 'order',
          icon: (
            <Icon
              type={'svg'}
              icon={IconSearchMessage}
              viewBox={'0 0 27 27'}
              style={{ fontSize: isMobile ? '30px' : undefined }}
            />
          ),
        },
        {
          value: 'file',
          icon: (
            <Icon
              type={'svg'}
              icon={IconPicture}
              viewBox={'0 0 30 27'}
              style={{ fontSize: isMobile ? '30px' : undefined }}
            />
          ),
        },
        // {
        //   value: 'link',
        //   icon: (
        //     <Icon
        //       type={'svg'}
        //       icon={IconLink}
        //       viewBox={'0 0 28 27'}
        //       style={{ fontSize: isMobile ? '30px' : undefined }}
        //     />
        //   ),
        // },
        {
          value: 'info',
          icon: (
            <Icon
              type={'svg'}
              icon={IconInfo}
              viewBox={'0 0 27 27'}
              style={{ fontSize: isMobile ? '30px' : undefined }}
            />
          ),
          disabled: true,
        },
      ].map(({ value, icon, disabled }, i) => (
        <Grid item key={i}>
          <IconButton
            color={'secondary'}
            disabled={_disabled || disabled}
            onClick={() => {
              if (value !== tab) onChange(value);
            }}
          >
            {icon}
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default TabButtons;
